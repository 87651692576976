import math from 'mathjs';
import katex from 'katex';

function div(a, b){
  return a/b;
}

div.toTex = function (node, options) {
  return node.args[0].toTex(options) + '\\div' + node.args[1].toTex(options)
}

math.import({div});

function mult(a, b){
  return a*b;
}

mult.toTex = function (node, options) {
  return node.args[0].toTex(options) + '\\times' + node.args[1].toTex(options)
}

math.import({mult});

export default class trick {
  constructor(title = '', image_description = '', text_description = '', type = 'classic', formula = {formulas: [''], variables: [{name: 'a', min: 0, max: 10, step: 1}]}, options = {}){
    this.title = title;
    this.image_description = image_description;
    this.text_description = text_description;
    this.type = type;
    this.formula = formula;
    this.options = options;
    if(!this.options.decimalsLimit){
      this.options.decimalsLimit = 6;
    }
  }

  generate(nb = 10){
    let self = this;
    let variableValues = [];
    if(variableValues.length == 0){
      let retries = 0;
      while(variableValues.length < nb){
        let valueSet = {};
        self.formula.variables.forEach(function(variable){
          if(variable.type == 'grad'){
            let nbSteps = math.floor(math.max((variable.max-variable.min)/variable.step,0)) + 1;
            let possibleValues = [];
            for(let i = 0 ; i < nbSteps ; i++){
              if(possibleValues.length < 10000){
                possibleValues.push(math.round(Number(variable.min) + i*variable.step, 4));
              }
            }
            let excluded = variable.exclude ? variable.exclude.split(',') : []
            excluded.forEach(excludedValue => {
              const excludeIndex = possibleValues.indexOf(parseInt(excludedValue));
              if(excludeIndex > -1){
                possibleValues.splice(excludeIndex, 1);
              }
            });
            valueSet[variable.name] = possibleValues.length > 0 ? possibleValues[Math.floor(Math.random()*possibleValues.length)] : 0;
          } else if(variable.type == 'enum') {
            let values = variable.values ? variable.values.split(',') : [0];
            let nbSteps = values.length;
            valueSet[variable.name] = values[math.randomInt(nbSteps)];
          } else {
            let formula = variable.formula || '';
            Object.keys(valueSet).forEach(function(item) {
              formula =  formula.split(item).join(valueSet[item]);
            });
            try {
              let decimalsLimit = self.options.decimalsLimit;
              if(decimalsLimit){
                let limit = Math.pow(10, decimalsLimit);
                valueSet[variable.name] = Math.round(math.eval(formula)*limit)/limit;
              } else {
                valueSet[variable.name] = math.eval(formula);
              }
            } catch(e) {
              console.log(e)
            }
          }
        });
        let duplicate = false;
        let valueSetString = JSON.stringify(valueSet);
        variableValues.forEach( v => {
          if(valueSetString == JSON.stringify(v)){
            duplicate = true;
          }
        });
        if(duplicate && retries < 50){
          retries++;
        } else {
          variableValues.push(valueSet);
        }
      }
    }
    let tricks = [];
    variableValues.forEach(function(valueSet){
      let randomIndex = Math.floor(Math.random()*self.formula.formulas.length);
      let rawExpr = self.formula.formulas[randomIndex] ? self.formula.formulas[randomIndex] : '';
      if (self.type == 'algebra'){
        let coeffs = [...self.formula.answers[randomIndex]];
        let nbOfSolutions = self.formula.nbOfSolutions ? self.formula.nbOfSolutions[randomIndex] : 1;
        Object.keys(valueSet).forEach(function(item) {
          let itemVal = valueSet[item];
          if(itemVal == 1 ){
            itemVal = 'z'
          } else if(itemVal == -1){
            itemVal = '-z'
          } else if(itemVal == 0){
            itemVal = 'y'
          }
          rawExpr = rawExpr.split('div').join('$%^&').split(item).join(itemVal).split('$%^&').join('div');
          coeffs.forEach((rawCoeff, index, coeffs) => {
            coeffs[index] = rawCoeff.split(item).join(valueSet[item]);
          });
        });
        let charSwap = [
          ['+-', '-'], ['--', '+'], ['zx', 'x'], ['z(', '('], ['z', '1'],
          ['+yx', ''], ['-yx', ''], ['+y', ''], ['-y', ''], ['yx', ''], ['y', '0']
        ]
        charSwap.forEach(corr => {
          rawExpr = rawExpr.split(corr[0]).join(corr[1]);
        });
        if(rawExpr[0] == '+'){
          rawExpr = rawExpr.substr(1);
        }
        if(rawExpr == ''){
          rawExpr = '0';
        }
        coeffs.forEach((rawCoeff, index, coeffs) => {
          coeffs[index] = math.eval(rawCoeff);
        });
        tricks.push({expr: rawExpr, answerCoeffs: coeffs, nbOfSolutions: nbOfSolutions});
      } else if (self.type == 'power') {
        let answer = {...self.formula.answers[randomIndex]};
        Object.keys(valueSet).forEach(function(item) {
          rawExpr =  rawExpr.split('div').join('$%^&').split(item).join(valueSet[item]).split('$%^&').join('div');
          answer.base = answer.base.split('div').join('$%^&').split(item).join(valueSet[item]).split('$%^&').join('div');
          answer.power = answer.power.split('div').join('$%^&').split(item).join(valueSet[item]).split('$%^&').join('div');
        });
        try {
          answer.base = math.eval(answer.base);
          answer.power = math.eval(answer.power);
        } catch {
          answer.base = '--Error--';
          answer.power = '';
        }
        tricks.push({expr: rawExpr, answer});
      } else {
        Object.keys(valueSet).forEach(function(item) {
          rawExpr = rawExpr.split('div').join('$%^&');
          let value = valueSet[item] < 0 && self.options.relative == 'standard' ? `(${valueSet[item]})` : valueSet[item];
          rawExpr = rawExpr.split(item).join(value);
          rawExpr = rawExpr.split('$%^&').join('div');
        });
        if(rawExpr[0] == '(' && self.options.relative == 'standard'){
          rawExpr = rawExpr.substring(1).replace(')', '');
        }
        tricks.push({expr: rawExpr});
      }
    });
    return tricks
  }

  displayExamples(nb = 6){
    let self = this;
    let sourceTricks = this.generate(nb);
    let examples = [];
    let decimalsLimit = this.options.decimalsLimit;
    sourceTricks.forEach(function(sourceTrick){
      let trick = sourceTrick.expr;
      try{
        let trickResult = 0;
        switch (self.type) {
          case 'algebra':
          let equalChar = self.formula.command == 'equation' ? ' → ' : ' = '
            try{
              trickResult = katex.renderToString(equalChar + self.displayAlgebraAnswer(sourceTrick.answerCoeffs, self.formula.command, sourceTrick.nbOfSolutions), {
                throwOnError: false
              });
            } catch(err){
              console.log(err);
              trickResult = '--error--'
            }
          break;
          case 'fraction':
            let fractionParts = math.fraction(math.eval(trick));
            let sign = fractionParts.s > 0 ? '' : '-';
            if(fractionParts.d == 1){
              trickResult = katex.renderToString(` =${sign}${fractionParts.n}`, {throwOnError: false});
            } else {
              trickResult = katex.renderToString(` =${sign}\\frac{${fractionParts.n}}{${fractionParts.d}}`, {throwOnError: false});
            }
          break;
          case 'power':
            let answerArray = self.getAnswer(sourceTrick.expr, sourceTrick.answer);
            switch(self.formula.command){
              case 'decimal':
                trickResult = katex.renderToString(" =" + String(answerArray[0]), { throwOnError: false });
              break;
              case 'power':
                trickResult = katex.renderToString(` =${sourceTrick.answer.base}^{${sourceTrick.answer.power}}`, { throwOnError: false });
              break;
              case 'power - give base':
                trickResult = katex.renderToString(` =${sourceTrick.answer.base}^{${sourceTrick.answer.power}}`, { throwOnError: false });
              break;
              case 'scientific':
                trickResult = katex.renderToString(` =${answerArray[0]} \\times 10^{${answerArray[1]}}`, { throwOnError: false });
              break;
            }
          break;
          case 'classic':
            if(decimalsLimit){
              let limit = Math.pow(10, decimalsLimit);
              let value = Math.round(math.eval(trick)*limit)/limit;
              trickResult = katex.renderToString(" =" + String(value), {
                throwOnError: false
              });
            } else {
              trickResult = katex.renderToString(" =" + String(math.eval(trick)), {
                throwOnError: false
              });
            }
          break;
        }
        let trickExpr;
        if(self.type == 'algebra' && self.formula.command == 'equation'){
          let trickParts = trick.split('=');
          if(trickParts.length == 2){
            let leftHand = _.replace(math.parse(trickParts[0]).toTex().split('\\mathrm').join(''), /cdot/g, 'times');
            let righttHand = _.replace(math.parse(trickParts[1]).toTex().split('\\mathrm').join(''), /cdot/g, 'times');
            trickExpr = katex.renderToString(leftHand + '=' + righttHand, {
              throwOnError: false
            });
          } else {
            trickExpr = '--error--';
          }
        } else {
          trickExpr = katex.renderToString(_.replace(math.parse(trick).toTex().split('\\mathrm').join(''), /cdot/g, 'times'), {
            throwOnError: false
          });
        }
        // console.log(trick);
        // console.log(math.parse(trick).toTex());
        trickResult = '<span style="white-space:nowrap">' + trickResult + "</span>"
        examples.push({computation: trickExpr, result: trickResult});
      } catch(err){
        console.log(err)
      }
    });

    return examples
  }

  displayQuestions(nb = 10, sourceQuestions = null){
    let sourceTricks = sourceQuestions ? sourceQuestions : this.generate(nb);
    let questions = [];
    let self = this;
    sourceTricks.forEach(function(sourceTrick){
      let trick = sourceTrick.expr;
      let trickExpr;
      let trickForTex
      if(self.type == 'algebra' && self.formula.command == 'equation'){
        let trickParts = trick.split('=');
        if(trickParts.length == 2){
          let leftHand = _.replace(math.parse(trickParts[0]).toTex().split('\\mathrm').join(''), /cdot/g, 'times');
          let righttHand = _.replace(math.parse(trickParts[1]).toTex().split('\\mathrm').join(''), /cdot/g, 'times');
          trickExpr = katex.renderToString(leftHand + '=' + righttHand, {
            throwOnError: false
          });
        } else {
          trickExpr = '--error--';
        }
      } else {
        trickForTex = _.replace(math.parse(trick).toTex().split('\\mathrm').join('').split('~').join(''), /cdot/g, 'times');
        trickExpr = katex.renderToString(trickForTex, {
          throwOnError: false
        });
      }
      if (self.type == 'algebra'){
        questions.push({raw: trick, forTex: trickForTex, formated: trickExpr, coeffs: sourceTrick.answerCoeffs, nbOfSolutions: sourceTrick.nbOfSolutions});
      } else if (self.type == 'power') {
        questions.push({raw: trick, forTex: trickForTex, formated: trickExpr, answer: sourceTrick.answer});
      } else {
        questions.push({raw: trick, forTex: trickForTex, formated: trickExpr});
      }
    });
    return questions;
  }

  displayFormula(){
    let self = this;
    let allFormulasDisplays = [];
    this.formula.formulas.forEach( formula => {
      if(formula != ''){
        if(self.type == 'algebra' && self.formula.command == 'equation'){
          let trickParts = formula.split('=');
          if(trickParts.length == 2){
            let leftHand = _.replace(math.parse(trickParts[0]).toTex().split('\\mathrm').join(''), /cdot/g, 'times');
            let righttHand = _.replace(math.parse(trickParts[1]).toTex().split('\\mathrm').join(''), /cdot/g, 'times');
            allFormulasDisplays.push(katex.renderToString(leftHand + '=' + righttHand, {throwOnError: false}));
          } else {
            allFormulasDisplays.push('--error--');
          }
        } else {
          allFormulasDisplays.push(katex.renderToString(_.replace(math.parse(formula).toTex().split('\\mathrm').join(''), /cdot/g, 'times'), {
            throwOnError: false
          }));
        }
      }
    });
    let formulasDisplay = allFormulasDisplays.join('<br><span class="font-weight-bold mr-2">OR</span>');

    let allVariablesDisplay = [];
    this.formula.variables.forEach( variable => {
      if(variable.type == 'grad') {
        allVariablesDisplay.push(`<span style='font-family:KaTeX_Math;font-style:italic;font-weight:bold;'>${variable.name} :</span> min=${variable.min}, max=${variable.max}, step=${variable.step}`);
      } else if(variable.type == 'enum') {
        allVariablesDisplay.push(`<span style='font-family:KaTeX_Math;font-style:italic;font-weight:bold;'>${variable.name} :</span> values=[${variable.values}]`);
      } else {
        allVariablesDisplay.push(`<span style='font-family:KaTeX_Math;font-style:italic;font-weight:bold;'>${variable.name} :</span> formula=[${variable.formula}]`);
      }
    });
    let variablesDisplay = allVariablesDisplay.join('<br>');

    return {formulas : formulasDisplay, variables: variablesDisplay};
  }

  checkAnswer(raw, answer, nbOfSolutions){
    if(this.type == 'classic'){
      if(this.options.decimalsLimit){
        let limit = Math.pow(10, this.options.decimalsLimit);
        return math.equal(Math.round(math.eval(raw)*limit)/limit, answer);
      } else {
        return math.equal(math.eval(raw), answer);
      }
    }
    if(this.type == 'power'){
      switch(this.formula.command){
        case 'decimal':
          return math.equal(math.eval(raw), answer[0]);
        break;
        case 'power':
          return math.equal(math.eval(raw), math.eval(answer[0]**answer[1]));
        break;
        case 'power - give base':
          return math.equal(math.eval(raw), math.eval(answer[0]**answer[1]));
        break;
        case 'scientific':
          return (answer[0] >=1 && answer[0]<10 && math.equal(math.eval(raw), math.eval(answer[0]*10**answer[1])));
        break;
      }
    }
    if(this.type == 'fraction'){
      let answerParts = this.getAnswer(raw);
      return {value: math.equal(math.eval(raw), answer[0]/answer[1]), simplified: answer[0] == answerParts.s*answerParts.n && answer[1] == answerParts.d};
    }
    if(this.type == 'algebra'){
      let validCoeffs = [];
      if(this.formula.command == 'expand'){
        raw.forEach((answerCoeff, index) => {
          validCoeffs.push(math.equal(answerCoeff, answer[index]))
        });
      } else if(this.formula.command == 'factor'){
        let earlyCheck = [];
        let x = 0;
        while(x < 5){
          earlyCheck.push(math.equal((raw[0]*x+raw[1])*(raw[2]*x+raw[3]), (answer[0]*x+answer[1])*(answer[2]*x+answer[3])));
          x++;
        }

        let checkSum = earlyCheck.reduce((total, value) => {
        	if(value)  return total + value
          return total
        }, 0);
        if(checkSum == 5){
          return [true, true, true, true];
        }

        let sameFactorOrder = [
          math.equal(raw[0], answer[0]),
          math.equal(raw[1], answer[1]),
          math.equal(raw[2], answer[2]),
          math.equal(raw[3], answer[3]),
          ];
        let diffFactorOrder = [
          math.equal(raw[0], answer[2]),
          math.equal(raw[1], answer[3]),
          math.equal(raw[2], answer[0]),
          math.equal(raw[3], answer[1]),
          ];
          let sameSum = sameFactorOrder.reduce((total, value) => {
          	if(value)  return total + value
            return total
          }, 0);
          let diffSum =diffFactorOrder.reduce((total, value) => {
          	if(value)  return total + value
            return total
          }, 0);

          if(diffSum > sameSum){
            validCoeffs = diffFactorOrder;
          } else {
            validCoeffs = sameFactorOrder;
          }
      }  else if(this.formula.command == 'equation'){
        validCoeffs = [true, true, true, true];
        let testAnswers = [];
        let studentAnswers = [];
        for(let i=0; i<nbOfSolutions; i++){
          testAnswers.push(raw[i]);
          studentAnswers.push(answer[i]);
        }
        if(testAnswers[0] == testAnswers[1] && testAnswers[1] == testAnswers[2]){
          let tripleRootCheck = [];
          studentAnswers.forEach(answer => {
            tripleRootCheck.push(answer == testAnswers[0] || answer == '...');
          });
          if (tripleRootCheck.every(val => val)){
            return [true, true, true, true];
          }
        }
        let doubleRoot = false;
        studentAnswers.forEach((answer, index) => {
          let matchIndex = testAnswers.indexOf(answer);
          if(matchIndex >= 0){
            testAnswers.splice(matchIndex, 1);
            let doubleRootCheck = testAnswers.indexOf(answer);
            if(doubleRootCheck >= 0){
              doubleRoot = true;
            }
          } else {
            validCoeffs[index] = false;
          }
        });
        let checkSum = validCoeffs.reduce((total, value) => {
        	if(!value)  return total + 1;
          return total
        }, 0);
        if(checkSum == 1 && doubleRoot){
          let falseIndex = validCoeffs.indexOf(false);
          if(studentAnswers[falseIndex] == '...') return [true, true, true, true];
        }
      }

      return validCoeffs;
    }
  }

  getAnswer(raw, answer){
    if(this.type == 'classic'){
      if(this.options.decimalsLimit){
        let limit = Math.pow(10, this.options.decimalsLimit);
        return Math.round(math.eval(math.eval(raw))*limit)/limit;
      } else {
        return math.eval(raw);
      }
    } else if(this.type == 'fraction'){
        return math.fraction(math.eval(raw));
    } else if(this.type == 'power'){
      let limit = Math.pow(10, 6);
      let answerValue = Math.round(math.eval(math.eval(raw))*limit)/limit;
      if(answerValue == 0){
        answerValue = math.round(math.eval(raw), 15);
      }
      if(this.formula.command == 'decimal'){
        return [answerValue, 1];
      } else if (this.formula.command == 'scientific'){
        let answerArray = math.format(answerValue, {notation: 'exponential'}).split('e');
        if(answerArray[1].charAt(0) == '+'){
          answerArray[1] = answerArray[1].substring(1);
        }
        return answerArray;
      } else {
        return [answer.base, answer.power];
      }
    }
  }

  formatAnswer(ans, displayEqualSign){
    let equalSign = displayEqualSign ? '=' : '';
    if(this.type == 'classic'){
      return katex.renderToString(`${equalSign}${ans}`, {throwOnError: false});
    }
    if(this.type == 'fraction'){
      let numerator = ans[0] == undefined ? ans.n : ans[0];
      let denominator = ans[1] == undefined ? ans.d : ans[1];
      let strToTex = '';
      if(numerator == 0){
        strToTex = `${equalSign}0`;
      } else if (denominator == 1){
        strToTex = equalSign+numerator;
      } else {
        let frac = displayEqualSign ? 'frac' : 'dfrac';
        strToTex = numerator < 0 ? `${equalSign}-\\${frac}{${-numerator}}{${denominator}}` : `${equalSign}\\${frac}{${numerator}}{${denominator}}`
      }
      return katex.renderToString(strToTex, {throwOnError: false});
    }
    if(this.type == 'power'){
      if (this.formula.command == 'scientific'){
        return katex.renderToString(`${equalSign}${ans[0]}\\times10^{${ans[1]}}`, {throwOnError: false});
      } else if(this.formula.command == 'decimal'){
        return katex.renderToString(`${equalSign}${ans[0]}`, {throwOnError: false});
      } else {
        return katex.renderToString(`${equalSign}${ans[0]}^{${ans[1]}}`, {throwOnError: false});
      }
    }
    if(this.type == 'algebra'){
      if(this.formula.command == 'equation' && displayEqualSign){
        equalSign = '\\to{}'
      }
      return katex.renderToString(equalSign+this.displayAlgebraAnswer(ans, this.formula.command, this.formula.nbOfSolutions), {throwOnError: false});
    }
  }

  getAnswerFormated(raw, answer){
    if(this.type == 'classic'){
      return katex.renderToString(" =" + this.getAnswer(raw), {throwOnError: false});
    }
    if(this.type == 'power'){
      let answerArray = this.getAnswer(raw, answer);
      switch(this.formula.command){
        case 'decimal':
          return katex.renderToString(" =" + String(answerArray[0]), { throwOnError: false });
        break;
        case 'power':
          return katex.renderToString(` =${answer.base}^{${answer.power}}`, { throwOnError: false });
        break;
        case 'power - give base':
          return katex.renderToString(` =${answer.base}^{${answer.power}}`, { throwOnError: false });
        break;
        case 'scientific':
          return katex.renderToString(` =${answerArray[0]} \\times 10^{${answerArray[1]}}`, { throwOnError: false });
        break;
      }
    }
    if(this.type == 'fraction'){
      let answerParts = this.getAnswer(raw)
      let sign = answerParts.s > 0 ? '' : '-';
      if(answerParts.d == 1){
        return katex.renderToString(` =${sign}${answerParts.n}`, {throwOnError: false});
      } else {
        return katex.renderToString(` =${sign}\\frac{${answerParts.n}}{${answerParts.d}}`, {throwOnError: false});
      }
    }
    if(this.type == 'algebra'){
      if(raw.command == 'equation'){
        return katex.renderToString(' → ' + this.displayAlgebraAnswer(raw.coeffs, raw.command, raw.nbOfSolutions), {throwOnError: false})
      } else {
        return katex.renderToString(' = ' + this.displayAlgebraAnswer(raw.coeffs, raw.command, raw.nbOfSolutions), {throwOnError: false})
      }
    }
  }


  linearExpr(a, b){
    if (a == 0) return b;
    let coeff = a
    if(a == 1){
      coeff = '';
    } else if (a == -1){
      coeff = '-';
    }
    if(b < 0){
      return `${coeff}x${b}`;
    } else if (b == 0){
      return `${coeff}x`;
    } else {
      return `${coeff}x+${b}`;
    }
  }

  displayAlgebraAnswer(coeffs, command, nbOfSolutions){
    let trickResult = 0;
    if(command == 'factor'){
      let zeroCount = _.sumBy(coeffs, x => x == 0);
      if(zeroCount >= 3){
        return '0';
      } else if (zeroCount == 2){
        if(coeffs[0] == 0 && coeffs[1] == 0){
          return this.linearExpr(coeffs[2], coeffs[3]);
        }
        if(coeffs[0] == 0 && coeffs[2] == 0){
          return `${coeffs[1]*coeffs[3]}`;
        }
        if(coeffs[0] == 0 && coeffs[3] == 0){
          return this.linearExpr(coeffs[1]*coeffs[2], 0);
        }
        if(coeffs[1] == 0 && coeffs[2] == 0){
          return this.linearExpr(coeffs[0]*coeffs[3], 0);
        }
        if(coeffs[1] == 0 && coeffs[3] == 0){
          return `${this.linearExpr(coeffs[0]*coeffs[2], 0)}^2`;
        }
        if(coeffs[2] == 0 && coeffs[3] == 0){
          return this.linearExpr(coeffs[0], coeffs[1]);
        }
      } else if (zeroCount == 1){
        if(coeffs[0] == 0){
          return `${coeffs[1]}(${this.linearExpr(coeffs[2], coeffs[3])})`;
        }
        if(coeffs[1] == 0){
         return `${this.linearExpr(coeffs[0], 0)}(${this.linearExpr(coeffs[2], coeffs[3])})`;
        }
        if(coeffs[2] == 0){
          return `${coeffs[3]}(${this.linearExpr(coeffs[0], coeffs[1])})`;
        }
        if(coeffs[3] == 0){
          return `${this.linearExpr(coeffs[2], 0)}(${this.linearExpr(coeffs[0], coeffs[1])})`;
        }
      } else {
        return `(${this.linearExpr(coeffs[0], coeffs[1])})(${this.linearExpr(coeffs[2], coeffs[3])})`
      }
    } else if(command == 'expand') {
      trickResult = '';
      coeffs.forEach((coeff, index) => {
        if(index < 2){
          if(coeff > 1){
            trickResult += '+' + coeff + 'x^' + (3-index);
          } else if (coeff == 1){
            trickResult += '+' + 'x^' + (3-index);
          } else if (coeff == -1){
            trickResult += '-' + 'x^' + (3-index);
          }
          else if (coeff < -1){
           trickResult += '-' + (-coeff) + 'x^' + (3-index);
         }
       } else if(index == 2) {
          if(coeff > 1){
            trickResult += '+' + coeff + 'x';
          } else if (coeff == 1){
            trickResult += '+x';
          }  else if (coeff == -1){
            trickResult += '-x';
          }  else if (coeff < -1){
            trickResult += '-' + (-coeff) + 'x';
          }
        } else {
          if(coeff > 0){
            trickResult += '+' + coeff;
          } else if(coeff < 0){
            trickResult += '-' + (-coeff);
          }
        }
      });
      if (trickResult == ''){
        trickResult = '0';
      }
      if (trickResult.charAt(0) == '+'){
        trickResult = trickResult.substring(1);
      }
    } else if(command == 'equation') {
      trickResult = '';
      coeffs.forEach((coeff, index) => {
        if(index < nbOfSolutions){
          trickResult += `x=${coeff},\\,`
        }
      });
      trickResult = trickResult.slice(0, -3);
    }

    return trickResult;
  }
}
