<template>
  <v-card class='pa-2 my-1 moveMe'>
    <v-layout row wrap>
      <v-flex xs12 style='display:flex;justify-content:space-between;align-items:center'>
        <span class='title py-1' style='overflow:hidden;white-space:nowrap'>
          {{ track.title }}
        </span>
        <span style='overflow-x:hidden;white-space:nowrap;min-width:76px'>
          <v-btn color="success" flat icon class='ma-0' @click="toggleDetails">
            <v-icon style='display:inline-flex'>visibility</v-icon>
          </v-btn>
          <v-btn color="error" flat icon class='ma-0' :ripple='false' @click="prepareRemoveTrack">
            <v-icon style='display:inline-flex'>delete_forever</v-icon>
          </v-btn>
        </span>
      </v-flex>
      <v-dialog v-model='deleteDialog' width='400'>
        <v-card>
          <v-card-title class='subheading pb-0'>Remove &nbsp<b>{{ track.title }}</b>&nbsp from hike ?</v-card-title>
          <v-card-text>
            Are you sure you want to remove this track ? All students progress will be deleted permanently.
          </v-card-text>
          <v-flex xs12 style='display:flex;justify-content:flex-end'>
            <v-btn color="primary" style='width:150px' flat @click="deleteDialog = false">Cancel</v-btn>
            <v-btn color="error" style='width:150px' :ripple='false' @click="removeTrack">Remove</v-btn>
          </v-flex>
        </v-card>
      </v-dialog>
      <transition name='fadeUp'>
        <div class='trackDetailsWrap' v-if='detailsDiv'>
          <div class='trackDetails'>
            <v-btn color="error" flat icon style='position:absolute;right:0;top:0;' :ripple='false' @click="detailsDiv = false">
              <v-icon style='display:inline-flex'>close</v-icon>
            </v-btn>
            <v-layout class='pa-2' row wrap>
              <v-flex xs12 class="grey--text subheading">
                Description
              </v-flex>
              <v-flex xs12>
                {{ track.text_description }}
              </v-flex>
              <v-flex xs12>
                <v-flex class="grey--text subheading mt-1">Tricks</v-flex>
                <v-flex v-if="track.tricks.length == 0" class="text-xs-center">
                  <v-chip>This track has no tricks</v-chip>
                </v-flex>
                <v-tooltip bottom v-for="trick in track.tricks" :key="`trick-${trick.id}`">
                  <template v-slot:activator="{ on }">
                    <v-chip label color="primary" text-color="white" v-on="on">
                      {{ trick.title }}
                    </v-chip>
                  </template>
                  <div style="max-width:150px">{{ trick.text_description | truncate }}</div>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </transition>
    </v-layout>
  </v-card>
</template>
<script>
  export default {
    props : { track: Object },
    data : function(){
      return {
        detailsDiv: false,
        deleteDialog: false
      }
    },
    filters: {
      truncate(value){
        if(value.length < 100){
          return value;
        } else {
          return value.substr(0,97)+'...';
        }
      }
    },
    methods: {
      toggleDetails(){
        this.detailsDiv = !this.detailsDiv;
      },
      prepareRemoveTrack(){
        if(this.track.new){
          this.removeTrack();
        } else {
          this.deleteDialog = true;
        }
      },
      removeTrack(){
        eventBus.$emit('removeTrack', this.track.id);
      }
    }
  }
</script>

<style scoped>
  .moveMe {
    cursor: grab;
  }
  .moveMe:active {
    cursor: grabbing;
  }
  .trackDetails {
    width: 550px;
    background: white;
    padding-bottom: 5%;
    clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 93% 95%, 90% 100%, 87% 95%, 0% 95%);
  }
  .trackDetailsWrap {
    position: absolute;
    cursor: default;
    right:12px;
    bottom:38px;
    filter: drop-shadow(-1px 6px 15px rgba(50, 50, 0, 0.5));
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  .fadeUp-enter-active {
    animation: fade-up .5s;
  }
  .fadeUp-leave-active {
    animation: fade-up .25s reverse;
  }
  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
</style>
