<template>
  <v-card style='margin:auto' max-width='1137px'>
    <div style='position:absolute;bottom:5px;width:100%;text-align:center' class='grey--text caption'>
      <i>{{ statusDisplay.hint }}</i>
    </div>
    <div
      :style="`position:absolute;${$vuetify.breakpoint.name == 'xs' ? 'right:10px;top:20px' : 'right:90px;top:0px'}`"
      v-if="race.studentProgress.reward != null">
      <v-img
        :src="`/images/medal ${race.studentProgress.reward}.png`"
        :width="$vuetify.breakpoint.name == 'xs' ? '50px' : '70px'">
      </v-img>
    </div>
    <v-card
      :style="$vuetify.breakpoint.name == 'xs' ? 'width:100%' : 'position:absolute;top:10px;right:10px;width:80px;'"
      :color="statusDisplay.labelColor"
      :class="`${statusDisplay.labelTextColor}--text font-weight-bold text-xs-center pa-1`">
      {{ statusDisplay.label }}
    </v-card>
    <v-tooltip left v-if='race.status == "active" || race.status == "upcoming"'>
      <v-btn slot="activator" flat round to="/races" :ripple='false' color='grey' style='position:absolute;bottom:45px;right:-5px;'>
        <v-icon style='display:inline-flex'>directions_run</v-icon>
        <v-icon style='display:inline-flex'>chevron_right</v-icon>
      </v-btn>
      <span>Go to races page</span>
    </v-tooltip>
    <v-card-title class="pb-1 text-xs-left">
      <v-layout row wrap>
        <v-flex xs12 sm6 md4 class="display-1" v-if='$root.status == "teacher"'>
          {{ race.title }}
        </v-flex>
        <v-flex xs12 sm6 class="display-1" v-else>
          {{ race.title }}
        </v-flex>
        <v-flex xs12 sm6 md4 class="display-1 font-weight-bold text-xs-center" style="white-space:nowrap" v-if='$root.status == "teacher"'>
          <lgnb-display :nb='allStudentsStars'/>
          <v-icon x-large color="orange" style='margin-left:-10px'>star</v-icon>
        </v-flex>
        <v-flex xs12 md4 v-if='$root.status == "teacher"'>
          <div v-if='race.phase_id == null'>
            <div class="grey--text subheading">Classes (term {{ race.term }})</div>
            <v-flex v-if="race.divisions.length">
              <v-chip v-for="cls in race.divisions" :key="cls.id">{{ cls.name }}</v-chip>
            </v-flex>
            <v-flex class="grey--text subheading text--lighten-2" v-else>
              No class took this race
            </v-flex>
          </div>
          <div v-else>
            <v-card dark color='black' class='py-1 mb-2 text-xs-center' width='120px'>Marathon race</v-card>
            <div class="title">{{ race.marathon.marathon.title }}</div>
            <div class='subheading grey--text'>
               {{ race.marathon.phase.title }}
            </div>
          </div>
        </v-flex>
        <v-flex xs12 sm6 class="font-weight-bold display-1" v-else style="white-space:nowrap">
          {{ totalStars }} / {{ tricks.length*5}}
          <v-icon x-large color="orange">star</v-icon>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text class="text-xs-left pt-1">
      <v-layout row wrap fill-height>
        <v-flex xs12 sm6>
          <div class="grey--text subheading">Track</div>
          <div class="headline font-weight-thin">{{ race.track.title }}</div>
        </v-flex>
      </v-layout>
    </v-card-text>
    <div style='width:100%;height:30px' v-if='$vuetify.breakpoint.name == "xs"'></div>
    <div style='position:absolute;bottom:0;right:0'>
      <v-dialog v-model="deleteDialog" width="340" v-if='$root.status == "teacher"'>
        <template v-slot:activator="{ on }">
          <v-btn color="error" fab small dark v-on="on">
            <v-icon style='display:inline-flex'>delete_forever</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Delete {{ race.title }}</v-card-title>
          <v-card-text>Are you sure you want to delete this race ?<br>This will also delete all students performances.</v-card-text>
          <v-card-actions>
            <v-btn color="primary" flat block @click="deleteDialog=false">
              No
            </v-btn>
            <v-btn color="error" block @click="deleteThisRace">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn fab dark small color='success' @click="seeRacePerfs">
        <v-icon style='display:inline-flex'>visibility</v-icon>
      </v-btn>
    </div>
    <race-perfs-dialog :race='race' :options='options' :runningTime='race.studentProgress.runningTime' :thisStudentHistory='race.studentHistory' :racePerfsDialog='perfsDialog'/>
  </v-card>
</template>
<script>
  export default {
    props : { race : Object },
    data : function(){
      return {
        options: {},
        progress: {},
        statusDisplay: {},
        tricks: [],
        deleteDialog: false,
        perfsDialog: false
      }
    },
    computed: {
      totalStars(){
        return _.sumBy(this.tricks, o => o.rating);
      },
      allStudentsStars(){
        return this.race.formatedProgress.progress.tricks ?  _.sumBy(this.race.formatedProgress.progress.tricks, o => o.stars).toString() : '0';
      }
    },
    created() {
      this.options = JSON.parse(this.race.options);
      this.progress = this.race.formatedProgress;
      this.tricks = this.race.studentProgress.tricks;
      this.tricks.forEach(trick => {
        trick.progress = _.find(this.race.studentProgress.tricks, {'trick_id': trick.id});
      });
      switch(this.race.status){
        case 'upcoming':
          this.statusDisplay = {
            label: 'Upcoming',
            labelColor: 'info',
            labelTextColor: 'white',
            hint: `Starts in ${this.race.timespan.starts_in}.`
          };
        break;
        case 'active':
          this.statusDisplay = {
            label: 'Started',
            labelColor: 'success',
            labelTextColor: 'white',
            hint: `Ends in ${this.race.timespan.ends_in}`
          };
        break;
        case 'closed':
          this.statusDisplay = {
            label: 'Closed',
            labelColor: 'grey',
            labelTextColor: 'white',
            hint: `Ended ${this.race.timespan.ends_in} ago`
          };
        break;
      }
      eventBus.$on('closePerfsDialog', this.closePerfsDialog);
    },
    beforeDestroy(){
      eventBus.$off('closePerfsDialog', this.closePerfsDialog);
    },
    methods : {
      closePerfsDialog(){
        this.perfsDialog = false;
      },
      seeRacePerfs(){
        this.perfsDialog = true;
      },
      deleteThisRace(){
        let vm = this;
        axios.delete(`/api/races/${this.race.id}`, {})
        .then(function(response){
          vm.$root.snackSuccess(`${vm.race.title} has been deleted`);
          vm.deleteDialog = false;
          eventBus.$emit('raceDeleted', vm.race.id);
        })
        .catch(function(error){
          console.log(error);
        });
      }
    }
  }
</script>
