<template>
  <div>
    <div class='pa-1'>
      <span class='headline grey--text'>
        Events in your class
      </span>
    </div>
    <div class='px-1' v-for='(event, index) in $root.classesEvents' :key='`${event.type}-${event.id}`'>
      <v-divider v-if='index != 0'/>
      <div class='py-2' style='display:flex;justify-content:space-between;alin-items:center'>
        <div>
          <div class='subheading text-truncate'>
            {{ event.title }}
          </div>
          <div class='grey--text'>
            {{ typeDisplay(event.type, event.isMarathon) }}
          </div>
        </div>
        <v-btn round flat to='/races' v-if='event.type=="race"'>
          <v-icon style='display:inline-flex' class='ml-2'>directions_run</v-icon>
          <v-icon style='display:inline-flex' >chevron_right</v-icon>
        </v-btn>
        <v-btn round flat to='/hikes' v-if='event.type=="hike"'>
          <v-icon style='display:inline-flex' class='ml-2'>directions_walk</v-icon>
          <v-icon style='display:inline-flex'>chevron_right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        //data here
      }
    },
    mounted() {

    },
    methods: {
      typeDisplay(type, isMarathon){
        if(isMarathon) {
          return 'Marathon '+type;
        } else {
          return type.charAt(0).toUpperCase()+type.slice(1);
        }
      }
    }
  }
</script>
