<template>
    <v-container>
        <v-layout row wrap class="text-xs-left">
            <v-flex xs12 style="display:flex;justify-content:space-between;align-items:center;">
                <span :class="titleClass($vuetify.breakpoint.name)">
                    Your collection
                </span>
                <span>
                    <div class="caption grey--text">
                        Collection value
                    </div>
                    <div style="display:flex;align-items:center;">
                        <lgnb-display :class="$vuetify.breakpoint.name == 'xs' ? 'headline' : 'display-2'" :nb='collectionValue'/>
                        <v-icon color="primary" :size="$vuetify.breakpoint.name == 'xs' ? 32 : 48">sentiment_satisfied_alt</v-icon>
                    </div>
                </span>
            </v-flex>
            <v-flex xs12 class="grey--text headline mt-3">
                Avatar
            </v-flex>
            <v-flex xs6 sm4 md3 lg2 style="position:relative;">
                <div class="grey--text">
                Current
                </div>
                <v-hover>
                    <v-img 
                        slot-scope="{ hover }" 
                        style="border-radius:50%;cursor:pointer" 
                        :class="`elevation-${hover ? 12 : 2}`" 
                        max-width='120px' 
                        :src='$root.user.images.avatar'
                        @click="showDeselectCard('avatar')"
                    />
                </v-hover>
            </v-flex>
            <v-flex xs6 sm8 md9 lg10 style="position:relative;min-height:513px;max-height:513px;">
                <div class="grey--text">
                Available
                </div>
                <avatar-collections :collections="myCollection.avatarCollections"/>
                <!-- <collectible-carousel :collectibles="myCollection.avatar" :avatar="true" :collectibleHeight="80" :collectibleWidth="80" :divHeight="195" :resetShiftCounter="resetShiftCounter"/> -->
                <v-btn
                    round
                    color="primary"
                    style="width:175px;position:absolute;right:0px;bottom:0px;"
                    :loading="searching.avatar"
                    :disabled="Object.values(searching).some(o => o)"
                    @click="shopForCollectibles('avatar')"
                >
                    Buy more
                </v-btn>
            </v-flex>
            <v-flex xs12 class="my-4 pt-3">
                <v-divider/>
            </v-flex>
            <v-flex xs12 class="headline grey--text">
                Tricks
            </v-flex>
            <v-flex xs6 sm4 md3 lg2>
                <div class="grey--text">
                    Current
                </div>
                <div style="height:90px;" v-for="colType in ['correct', 'incorrect', 'pending']" :key="colType">
                    <v-hover>
                        <v-img 
                            slot-scope="{ hover }" 
                            style="cursor:pointer" 
                            :class="`elevation-${hover ? 12 : 2}`" 
                            max-width='120px' 
                            :src='$root.user.images[colType]'
                            @click="showDeselectCard(colType)"
                        />
                    </v-hover>
                </div>
            </v-flex>
            <v-flex xs6 sm8 md9 lg10 style="position:relative;">
                <div class="grey--text">
                    Available
                </div>
                <collectible-carousel
                    v-for="colType in ['correct', 'incorrect', 'pending']"
                    :key="colType"
                    :collectibles="myCollection[colType]"
                    :avatar="false" :collectibleHeight="80"
                    :collectibleWidth="120" 
                    :divHeight="90"
                    :resetShiftCounter="resetShiftCounter"
                />
                <v-btn
                    round
                    color="primary"
                    style="width:175px;position:absolute;right:0px;bottom:0px;"
                    :loading="searching.tricks"
                    :disabled="Object.values(searching).some(o => o)"
                    @click="shopForCollectibles('tricks')"
                >
                    Buy more
                </v-btn>
            </v-flex>
            <v-flex xs12 class="my-4 pt-3">
                <v-divider/>
            </v-flex>
            <v-flex xs12 class="headline grey--text">
                Races
            </v-flex>
            <v-flex xs6 sm4 md3 lg2>
                <div class="grey--text">
                    Current
                </div>
                <div style="height:145px;" v-for="colType in ['upcoming', 'active', 'finished']" :key="colType">
                    <v-hover>
                        <v-img 
                            slot-scope="{ hover }" 
                            style="cursor:pointer" 
                            :class="`elevation-${hover ? 12 : 2}`" 
                            max-width='120px' 
                            :src='$root.user.images[colType]'
                            @click="showDeselectCard(colType)"
                        />
                    </v-hover>
                </div>
            </v-flex>
            <v-flex xs6 sm8 md9 lg10 style="position:relative;">
                <div class="grey--text">
                    Available
                </div>
                <collectible-carousel
                    v-for="colType in ['upcoming', 'active', 'finished']"
                    :key="colType"
                    :collectibles="myCollection[colType]"
                    :avatar="false" :collectibleHeight="120"
                    :collectibleWidth="135" 
                    :divHeight="145"
                    :resetShiftCounter="resetShiftCounter"
                />
                <v-btn
                    round
                    color="primary"
                    style="width:175px;position:absolute;right:0px;bottom:0px;"
                    :loading="searching.tricks"
                    :disabled="Object.values(searching).some(o => o)"
                    @click="shopForCollectibles('races')"
                >
                    Buy more
                </v-btn>
            </v-flex>
            <v-flex xs12 class="my-4 pt-3">
                <v-divider/>
            </v-flex>
            <v-flex xs12 class="headline grey--text">
                Toolbar colors
            </v-flex>
            <v-flex xs12>
                <div style="display:flex;align-items:center;">
                    <span class="grey--text subheading">Nb of colors</span>
                    <v-btn 
                        fab
                        small
                        v-for="index in tempToolbarNbOfColors"
                        :key="index"
                        :color="index == tempToolbarColors.length ? 'primary' : ''"
                        @click="updateTempsToolbarNbOfColors(index)"
                    >
                        {{ index }}
                    </v-btn>
                    <v-btn icon flat color="primary" @click="buyGradientSlotDialog = true">
                        <v-icon>add</v-icon>
                    </v-btn>
                </div>
                <div class="grey--text subheading">
                    Available colors
                </div>
                <div style="display:flex;flex-wrap:wrap;">
                    <v-menu offset-y v-for="color in formatedAvailableColors" :key="color.color">
                        <template v-slot:activator="{ on }">
                            <div 
                                style="min-height:40px;min-width:100px;cursor:pointer;display:flex;"
                                class="mr-1 mb-1 pa-1 elevation-1"
                                :style="`background-color:${color.color}`"
                                v-on="on"
                            >
                                <span v-for="i in color.selectedFor" :key="i" class="circledNb">{{ i }}</span>
                            </div>
                        </template>
                        <v-list>
                            <v-list-tile v-for="index in tempToolbarColors.length" :key="index" @click="useColor(index, color)">
                                Use as color {{ index }}
                            </v-list-tile>
                            <v-list-tile @click="useFontColor(color)">
                                Use as font color
                            </v-list-tile>
                        </v-list>
                    </v-menu>
                    <v-card height="40px" width="100px" hover ripple style="display:flex;justify-content:center;align-items:center;" @click="buyColorDialog = true">
                        <v-icon color="primary">add</v-icon>
                    </v-card>
                </div>
                <div v-for="(color, index) in tempToolbarColors" :key="index">
                    <div class="grey--text">
                        Color {{ index + 1 }}
                    </div>
                    <div style="display:flex;align-items:center;">
                        <v-card height="40px" width="100px" :color="color.color" :key="colorsForceRerender"/>
                        <v-slider v-model="color.position" class="ml-3" style="margin-top:-2px" thumb-label hide-details :min="0" :max="100" :disabled="tempToolbarColors.length < 2"/>
                    </div>
                </div>
                <div class="grey--text subheading mt-3">
                    Preview
                </div>
                <div 
                    style="width:100%;height:64px;display:flex;justify-content:center;align-items:center;position:relative;transition: all .5s;" class="elevation-4 mb-3" 
                    :style="`background:${tempToolbarBackground}`"
                >
                    <div class="title" :style="`color:${tempFontColor}`">
                        Text color
                    </div>
                    <div style="position:absolute;right:5px;bottom:0px;">
                        <v-btn color="primary" :disabled="updatingToolbarColors" small icon @click="resetTempToolbarColors">
                            <v-icon small>refresh</v-icon>
                        </v-btn>
                        <v-btn color="success" :disabled="updatingToolbarColors" small icon @click="saveToolbarColors">
                            <v-icon small>save</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-flex>
        </v-layout>
        <v-dialog v-model="shopDialog" width="80%">
            <v-card>
                <v-progress-linear indeterminate v-if="Object.values(searching).some(o => o)" class="ma-0" style="position:absolute"/>
                <v-card-title class="headline">
                    Buy collectibles
                </v-card-title>
                <v-card-text>
                    <v-layout row wrap>
                        <v-card width='100%' flat>
                            <v-layout row wrap>
                            <v-flex xs12 style='display:flex;align-items:center;' class='pa-2 mb-2'>
                                <v-checkbox hide-details label="Show affordable only" v-model="showAffordableOnly"/>
                                <v-text-field hide-details class='mr-1' v-model="searchTitle" append-icon="search" label="Search name" clearable/>
                                <v-btn style='width:150px;' color='primary' :disabled='Object.values(searching).some(o => o)' @click='searchCollectibles()'>
                                    {{ searchTitle == null || searchTitle.length == 0 ? "Refresh" : "Search" }}
                                </v-btn>
                            </v-flex>
                            </v-layout>
                        </v-card>
                    <div style='flex:1;display:flex;flex-wrap:wrap;justify-content:flex-start;align-content:flex-start;min-height:450px;'>
                        <collectible-mini-display class='ma-1' v-for='collectible in collectiblesDisplayed' :collectible='collectible' :key='collectible.id'/>
                    </div>
                    <div style='width:220px;display:flex;flex-direction:column;justify-content:flex-start;align-items:center;position:sticky;top:60px;align-self: flex-start;'>
                        <div class='pt-2' style='display:flex;justify-content:space-between;width:100%'>
                            <v-btn class='ma-0' icon :disabled='pinnedIndex == 0' @click='previousCard'>
                                <v-icon>chevron_left</v-icon>
                            </v-btn>
                            <v-chip v-if='pinnedCollectibles.length > 0'>
                                {{ pinnedIndex + 1 }} / {{ pinnedCollectibles.length }}
                            </v-chip>
                            <v-btn class='ma-0' icon :disabled='pinnedIndex == pinnedCollectibles.length - 1' @click='nextCard'>
                                <v-icon>chevron_right</v-icon>
                            </v-btn>
                        </div>
                        <div style='width:220px;overflow-x:hidden;'>
                            <div style='width:220px;display:flex;transition:all .2s' :style='`transform:translateX(-${pinnedIndex * 220}px)`'>
                                <div style='min-width:220px;max-width:220px;display:flex;justify-content:center' v-for='collectible in pinnedCollectibles' :key='collectible.id'>
                                    <collectible-card class='ma-1' :collectible='collectible'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" flat style="width:150px" @click="shopDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog min-width='350px' max-width='650px' v-model='selectDialog'>
            <v-card max-width='100%'>
              <v-card-title class='headline'>
                Select a new <span class='font-weight-bold font-italic mx-1'>{{ focusedCollectible.type }}</span> image
              </v-card-title>
              <v-layout row wrap>
                <v-flex sm6 hidden-xs-only style='display:flex;flex-direction:column;justify-content:flex-start;align-items:center'>
                  <div class='text-xs-center subheading font-weight-bold'>
                    Previous image
                  </div>
                  <v-img style='border-radius:50%' height='200px' width='200px' contain :src='$root.user.images[focusedCollectible.type]' v-if='focusedCollectible.type == "avatar"'/>
                  <v-img max-height='200px' width='200px' contain :src='$root.user.images[focusedCollectible.type]' v-else/>
                </v-flex>
                <v-flex xs12 sm6 style='display:flex;flex-direction:column;justify-content:flex-start;align-items:center'>
                  <div class='text-xs-center subheading font-weight-bold'>
                    New image
                  </div>
                  <v-img style='border-radius:50%' height='200px' width='200px' contain :src='focusedCollectible.url' v-if='focusedCollectible.type == "avatar"'/>
                  <v-img max-height='200px' width='200px' contain :src='focusedCollectible.url' v-else/>
                </v-flex>
                <v-flex xs12 style='display:flex;justify-content:flex-end'>
                  <v-btn color="error" :disabled='loading' style='width:150px' flat @click="selectDialog = false">Cancel</v-btn>
                  <v-btn color="primary" :loading='loading' style='width:150px' @click="submitSelect">Select</v-btn>
                </v-flex>
                <v-img src='/images/overlays/arrow select collectible.png' width='200px' style='position:absolute;top:135px;left:50%;margin-left:-120px' v-if='$vuetify.breakpoint.name != "xs"'/>
              </v-layout>
            </v-card>
          </v-dialog>
          <v-dialog min-width='350px' max-width='650px' v-model='deselectDialog'>
            <v-card max-width='100%'>
              <v-layout row wrap class='pa-2'>
                <v-flex xs12 d-flex>
                  <v-img :style='deselectType == "avatar" ? "border-radius:50%" : ""' width='250px' contain :src='$root.user.images[deselectType]'/>
                  <div class='pl-2'>
                    <div class='headline pb-3'>
                      Deselect current <span class='font-weight-bold font-italic mx-1'>{{ deselectType }}</span> image
                    </div>
                    <div class='subheading px-2' style='display:flex;align-items:center;'>
                      Are you sure you want to deselect the current image ? <br/> The default image will be shown instead until you select a new collectible.
                    </div>
                  </div>
                </v-flex>
                <v-flex xs12 style='display:flex;justify-content:flex-end'>
                  <v-btn color="error" :disabled='loading' style='width:150px;' flat @click="deselectDialog = false">Cancel</v-btn>
                  <v-btn color="primary" :loading='loading' style='width:150px;' @click="submitDeselect">Deselect</v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-dialog>
          <v-dialog width="450px" v-model="buyGradientSlotDialog">
                <v-card>
                    <v-card-title class="headline">
                        Buy a new gradient slot
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <div class="mb-2">
                            You currently have {{ tempToolbarNbOfColors }} gradient slots available.<br/>To purchase another slot :
                        </div>
                        <v-window v-model="buyGradientWindow">
                            <v-window-item :key="0">
                                <v-layout row wrap>
                                    <v-flex xs12 class='subheading'>
                                        <b>Requires :</b> <span class='title' :class='$root.user.star_count >= 1000*tempToolbarNbOfColors ? "" : "error--text font-weight-bold"'>
                                            {{ 1000*tempToolbarNbOfColors }}
                                        </span>
                                        <v-icon color="orange" class='ma-0' style='vertical-align:text-bottom;font-size:24px;'>
                                            star
                                        </v-icon>
                                    </v-flex>
                                    <v-flex xs12 style='display:flex;justify-content:space-evenly;border-top:1px solid black;position:relative;' class='mt-4 title'>
                                        <div style='position:absolute;top:-17px;left:15px;background-color:#f5f5f5;'>
                                        <v-chip outline color='black' class='ma-0 font-weight-bold'>
                                            Price
                                        </v-chip>
                                        </div>
                                        <span class='mt-2' :class='$root.user.smile_count >= 500 ? "" : "error--text font-weight-bold"'>
                                            {{ 1000*tempToolbarNbOfColors }} 
                                            <v-icon color="primary" class='ma-0' style='vertical-align:text-bottom;font-size:24px'>
                                                sentiment_satisfied_alt
                                            </v-icon>
                                        </span>
                                        <span class='mt-2' :class='$root.user.coin_stack >= 500 ? "" : "error--text font-weight-bold"'>
                                            {{ 5000*tempToolbarNbOfColors }} 
                                            <v-icon class='ma-0' style='vertical-align:text-bottom;font-size:24px;'>
                                                radio_button_checked
                                            </v-icon>
                                        </span>
                                    </v-flex>
                                    <v-flex xs12 class="mt-3" style="display:flex;justify-content:flex-end;">
                                        <v-btn flat style="width:140px" color="error" @click="buyGradientSlotDialog = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn flat color="primary" :disabled="cantBuyGradientSlot" style="width:140px" @click="buyGradientWindow = 1">
                                            Select
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-window-item>
                            <v-window-item :key="1">
                                <v-layout row wrap>
                                    <v-flex xs12>
                                        <div class='title mb-3'>
                                            <b>Requires :</b> {{ 1000 * tempToolbarNbOfColors }}  <v-icon color="orange" class='ma-0' style='vertical-align:text-bottom;font-size:24px'>star</v-icon> <v-icon color='success' class='ml-3'>done</v-icon>
                                        </div>
                                        <v-layout row wrap style='max-width:450px;border-top:1px solid black' class='pt-2'>
                                            <v-flex xs4></v-flex>
                                            <v-flex xs4 class='text-xs-right'>
                                                <v-icon color="primary" class='mr-2' medium>sentiment_satisfied_alt</v-icon>
                                            </v-flex>
                                            <v-flex xs4 class='text-xs-right'>
                                                <v-icon class='mr-2' medium>radio_button_checked</v-icon>
                                            </v-flex>
                                            <v-flex xs4 class='title primary--text' style='white-space:nowrap;'>
                                                Your funds
                                            </v-flex>
                                            <v-flex xs4 class='subheading primary--text text-xs-right'>
                                                {{ $root.user.smile_count }}
                                            </v-flex>
                                            <v-flex xs4 class='subheading primary--text text-xs-right'>
                                                {{ $root.user.coin_stack }}
                                            </v-flex>
                                            <v-flex xs4 class='title error--text'>
                                                Price
                                            </v-flex>
                                            <v-flex xs4 class='subheading error--text text-xs-right'>
                                                -{{ 1000 * tempToolbarNbOfColors }} 
                                            </v-flex>
                                            <v-flex xs4 class='subheading error--text text-xs-right'>
                                                -{{ 5000 * tempToolbarNbOfColors }}
                                            </v-flex>
                                            <v-flex xs4 class='title font-weight-bold'>
                                                Remaining
                                            </v-flex>
                                            <v-flex xs4 class='subheading font-weight-bold text-xs-right'>
                                                {{ $root.user.smile_count - 1000 * tempToolbarNbOfColors }}
                                            </v-flex>
                                            <v-flex xs4 class='subheading font-weight-bold text-xs-right'>
                                                {{ $root.user.coin_stack - 5000 * tempToolbarNbOfColors }}
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-2" style="display:flex;justify-content:flex-end;">
                                        <v-btn flat style="width:140px" color="primary" :disabled="buyColorLoading" @click="buyGradientWindow = 0">
                                            <v-icon left>chevron_left</v-icon> Back
                                        </v-btn>
                                        <v-btn color="primary" :loading="buyColorLoading" :disabled="cantBuyGradientSlot" style="width:140px" @click="buyGradientSlot">
                                            Buy
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-window-item>
                        </v-window>
                    </v-card-text>
                </v-card>
            </v-dialog>
          <v-dialog width="450px" v-model="buyColorDialog">
            <v-card>
                <v-card-title class="headline">
                    Buy a new color
                </v-card-title>
                <v-card-text class="pb-1" style="display:flex;justify-content:center;align-items:center;">
                    <v-layout row wrap>
                        <v-flex xs12 class="grey--text">
                            Select a color
                        </v-flex>
                        <v-flex xs6 class="mb-3">
                            <input type="color" v-model="newColor" style="width:200px;height:75px;cursor:pointer;">
                        </v-flex>
                        <v-flex xs6 style="height:75px;display:flex;align-items:center;justify-content:center;flex-direction:column;">
                            <div class="display-1" :class="colorOwned ? 'grey--text' : ''">
                                {{ this.newColor }}
                            </div>
                            <div class="error--text caption" v-if="colorOwned">
                                Color owned
                            </div>
                            <div style="min-height:18px;" v-else/>
                        </v-flex>
                        <v-flex xs12>
                            <v-window v-model="buyColorWindow">
                                <v-window-item :key="0">
                                    <v-layout row wrap>
                                        <v-flex xs12 class='subheading'>
                                            <b>Requires :</b> <span class='title' :class='$root.user.star_count >= 1000 ? "" : "error--text font-weight-bold"'>
                                                500
                                            </span>
                                            <v-icon color="orange" class='ma-0' style='vertical-align:text-bottom;font-size:24px'>
                                                star
                                            </v-icon>
                                        </v-flex>
                                        <v-flex xs12 style='display:flex;justify-content:space-evenly;border-top:1px solid black;position:relative;' class='mt-4 title'>
                                            <div style='position:absolute;top:-17px;left:15px;background-color:#f5f5f5;'>
                                            <v-chip outline color='black' class='ma-0 font-weight-bold'>
                                                Price
                                            </v-chip>
                                            </div>
                                            <span class='mt-2' :class='$root.user.smile_count >= 500 ? "" : "error--text font-weight-bold"'>
                                                100 
                                                <v-icon color="primary" class='ma-0' style='vertical-align:text-bottom;font-size:24px'>
                                                    sentiment_satisfied_alt
                                                </v-icon>
                                            </span>
                                            <span class='mt-2' :class='$root.user.coin_stack >= 500 ? "" : "error--text font-weight-bold"'>
                                                500 
                                                <v-icon class='ma-0' style='vertical-align:text-bottom;font-size:24px;'>
                                                    radio_button_checked
                                                </v-icon>
                                            </span>
                                        </v-flex>
                                        <v-flex xs12 class="mt-3" style="display:flex;justify-content:flex-end;">
                                            <v-btn flat style="width:140px" color="error" @click="buyColorDialog = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn flat color="primary" :disabled="cantBuyColor || colorOwned" style="width:140px" @click="buyColorWindow = 1">
                                                Select
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-window-item>
                                <v-window-item :key="1">
                                    <v-layout row wrap>
                                        <v-flex xs12>
                                            <div class='title mb-3'>
                                                <b>Requires :</b> 500 <v-icon color="orange" class='ma-0' style='vertical-align:text-bottom;font-size:24px'>star</v-icon> <v-icon color='success' class='ml-3'>done</v-icon>
                                            </div>
                                            <v-layout row wrap style='max-width:450px;border-top:1px solid black' class='pt-2'>
                                                <v-flex xs4></v-flex>
                                                <v-flex xs4 class='text-xs-right'>
                                                    <v-icon color="primary" class='mr-2' medium>sentiment_satisfied_alt</v-icon>
                                                </v-flex>
                                                <v-flex xs4 class='text-xs-right'>
                                                    <v-icon class='mr-2' medium>radio_button_checked</v-icon>
                                                </v-flex>
                                                <v-flex xs4 class='title primary--text' style='white-space:nowrap;'>
                                                    Your funds
                                                </v-flex>
                                                <v-flex xs4 class='subheading primary--text text-xs-right'>
                                                    {{ $root.user.smile_count }}
                                                </v-flex>
                                                <v-flex xs4 class='subheading primary--text text-xs-right'>
                                                    {{ $root.user.coin_stack }}
                                                </v-flex>
                                                <v-flex xs4 class='title error--text'>
                                                    Price
                                                </v-flex>
                                                <v-flex xs4 class='subheading error--text text-xs-right'>
                                                    -100
                                                </v-flex>
                                                <v-flex xs4 class='subheading error--text text-xs-right'>
                                                    -500
                                                </v-flex>
                                                <v-flex xs4 class='title font-weight-bold'>
                                                    Remaining
                                                </v-flex>
                                                <v-flex xs4 class='subheading font-weight-bold text-xs-right'>
                                                    {{ $root.user.smile_count - 100 }}
                                                </v-flex>
                                                <v-flex xs4 class='subheading font-weight-bold text-xs-right'>
                                                    {{ $root.user.coin_stack - 500 }}
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-2" style="display:flex;justify-content:flex-end;">
                                            <v-btn flat style="width:140px" color="primary" :disabled="buyColorLoading" @click="buyColorWindow = 0">
                                                <v-icon left>chevron_left</v-icon> Back
                                            </v-btn>
                                            <v-btn color="primary" :loading="buyColorLoading" :disabled="cantBuyColor" style="width:140px" @click="buyColor">
                                                Buy
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-window-item>
                            </v-window>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
          </v-dialog>
    </v-container>
</template>
<script>
import { isFunction } from 'util';

export default {
    props: {},
    data: function () {
        return {
            myCollection: [],
            shopDialog: false,
            selectDialog: false,
            deselectDialog: false,
            buyColorDialog: false,
            buyColorWindow: 0,
            buyGradientWindow: 0,
            buyColorLoading: false,
            updatingToolbarColors: false,
            buyGradientSlotDialog: false,
            deselectType: '',
            searchResult: [],
            showAffordableOnly: false,
            showItems: [
                { text: "All", value: "all" },
                { text: "Owned only", value: "owned" },
                { text: "Selected only", value: "selected" },
                { text: "Affordable only", value: "buyable" },
            ],
            page: 1,
            collectibles: [],
            pinnedCollectibles: [],
            focusedCollectible: {},
            pinnedIndex: 0,            
            searchTitle: '',
            searchShow: 'all',
            searching: {avatar: false, tricks: false, races: false},
            loading: false,
            searchData: { title: '', type: 'avatar', show: 'all', index: 1 },
            resetShiftCounter: 0,
            tempToolbarColors: [],
            tempFontColor: '',
            formatedAvailableColors: [],
            tempToolbarNbOfColors: 1,
            newColor: '#FFFFFF',
            colorsForceRerender: 0,
        }
    },
    computed: {
        collectiblesDisplayed(){
            return this.collectibles.filter(c => c.affordable.coins && c.affordable.stars && c.affordable.smiles || !this.showAffordableOnly);
        },
        nbPages(){
            return Math.floor((this.collectiblesDisplayed.length - 1)/50) + 1;
        },
        tempToolbarBackground() {
            if (this.tempToolbarColors.length == 1) {
                return this.tempToolbarColors[0].color;
            }
            let gradientSteps = '';
            this.tempToolbarColors.forEach(c => {
                gradientSteps += c.color + ' ' + c.position + '%,';
            });
            gradientSteps = gradientSteps.substring(0, gradientSteps.length - 1);

            return `linear-gradient(to right, ${gradientSteps})`;
        },
        cantBuyColor(){
            return this.$root.user.star_count < 500 || this.$root.user.smile_count < 100 || this.$root.user.coin_stack < 500;
        },
        colorOwned(){
            return this.formatedAvailableColors.some(c => c.color == this.newColor);
        },
        cantBuyGradientSlot(){
            return this.$root.user.star_count < 1000*this.tempToolbarNbOfColors || this.$root.user.smile_count < 1000 * this.tempToolbarNbOfColors || this.$root.user.coin_stack < 5000 * this.tempToolbarNbOfColors;
        },
        collectionValue(){
            return this.$root.user.fullSmileCount - this.$root.user.smile_count
        }
    },
    created() {
        eventBus.$emit('loading', { command: 'clear' });
        eventBus.$emit('loading', { command: 'start', name: 'collectibles-page' });
        this.initCollectibles();
        this.initTempToolbarColors();
    },
    mounted() {
        eventBus.$on('pinCollectible', this.pinCollectible);
        eventBus.$on('collectibleEdited', this.collectibleEdited);
        eventBus.$on('avatarPurchased', this.avatarPurchased);
        eventBus.$on('showCollectibleSelectCard', this.showCollectibleSelectCard);
        eventBus.$on('toolbarColorsUpdated', this.initTempToolbarColors);
        window.addEventListener('resize', this.resetCarouselsShift);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resetCarouselsShift);
        eventBus.$off('pinCollectible', this.pinCollectible);
        eventBus.$off('collectibleEdited', this.collectibleEdited);
        eventBus.$off('avatarPurchased', this.avatarPurchased);
        eventBus.$off('showCollectibleSelectCard', this.showCollectibleSelectCard);
        eventBus.$off('toolbarColorsUpdated', this.initTempToolbarColors);
    },
    methods: {
        initCollectibles() {
            axios.get('/api/collectibles/myCollection', {})
                .then(resp => {
                    this.myCollection = resp.data;
                    this.resetShiftCounter++;
                    eventBus.$emit('loading', { command: 'finish', name: 'collectibles-page' });
                }).catch(err => console.log(err.response));
        },
        initTempToolbarColors() {
            this.tempFontColor = this.$root.toolbarColors.font_color ? this.$root.toolbarColors.font_color : 'black';
            this.tempToolbarColors = [];
            this.formatedAvailableColors = [];
            this.$root.toolbarColors.activeSet.forEach(c => this.tempToolbarColors.push({ ...c }));
            this.$root.toolbarColors.availableColors.forEach(color => {
                let selectedFor = [];
                this.$root.toolbarColors.activeSet.forEach((c, i) =>{
                    if(c.color == color){
                        selectedFor.push(i+1);
                    }
                });
                this.formatedAvailableColors.push({ selectedFor, color })
            });
            this.tempToolbarNbOfColors = this.$root.toolbarColors.maxNbColors;
        },
        resetTempToolbarColors(){
            this.tempFontColor = this.$root.toolbarColors.font_color ? this.$root.toolbarColors.font_color : 'black';
            this.tempToolbarColors = [];
            this.$root.toolbarColors.activeSet.forEach(c => this.tempToolbarColors.push({ ...c }));
            const temp = this.formatedAvailableColors;
            this.formatedAvailableColors = [];
            temp.forEach(obj => {
                let selectedFor = [];
                this.$root.toolbarColors.activeSet.forEach((c, i) => {
                    if (c.color == obj.color) {
                        selectedFor.push(i + 1);
                    }
                });
                this.formatedAvailableColors.push({ selectedFor, color: obj.color })
            });
        },
        updateTempsToolbarNbOfColors(index){
            while (this.tempToolbarColors.length > index){
                this.tempToolbarColors.pop();
            }
            while (this.tempToolbarColors.length < index) {
                this.tempToolbarColors.push({ color: '#F5F5F5', position: 0 });
            }
        },
        buyColor(){
            this.buyColorLoading = true;
            axios.post('/api/collectibles/colors/newColor', {color: this.newColor})
            .then(resp => {
                this.formatedAvailableColors.unshift({ selectedFor: [], color: resp.data.color });
                this.buyColorDialog = false;
                this.buyColorWindow = 0;
                this.newColor = '#FFFFFF';
                this.buyColorLoading = false;
                eventBus.$emit('smileCountUpdate', resp.data.fundsLost.smiles);
                eventBus.$emit('coinStackUpdate', resp.data.fundsLost.coins);
            }).catch(err => {
                console.log(err.response);
                this.$root.snackError(err.response.data.message);
                this.buyColorDialog = false;
                this.buyColorWindow = 0;
                this.newColor = '#FFFFFF';
                this.buyColorLoading = false;
            });
        },
        useColor(index, color){
            this.formatedAvailableColors.forEach(c => {
                const i = c.selectedFor.indexOf(index);
                if(i > -1){
                    c.selectedFor.splice(i, 1);
                }
            })
            color.selectedFor.push(index);
            this.tempToolbarColors[index-1].color = color.color;
            this.colorsForceRerender++;
        },
        useFontColor(color){
            this.tempFontColor = color.color
        },
        buyGradientSlot(){
            this.buyColorLoading = true;
            axios.post('/api/collectibles/colors/newGradientSlot')
            .then(resp => {
                this.tempToolbarColors.push({color: '#F5F5F5', position: 0});
                this.tempToolbarNbOfColors++;
                this.buyColorLoading = false;
                this.buyGradientSlotDialog = false;
                this.buyGradientWindow = 0;
                this.$root.user.fullSmileCount = resp.data.fullSmileCount;
                eventBus.$emit('smileCountUpdate', resp.data.fundsLost.smiles);
                eventBus.$emit('coinStackUpdate', resp.data.fundsLost.coins);
            }).catch(err => {
                console.log(err.response);
                this.$root.snackError(err.response.data.message);
                this.buyColorLoading = false;
                this.buyGradientSlotDialog = false;
                this.buyGradientWindow = 0;
            });
        },
        saveToolbarColors(){
            this.updatingToolbarColors = true;
            axios.post('/api/collectibles/colors/updateToolbarColors', {colorSet: this.tempToolbarColors, fontColor: this.tempFontColor})
            .then(resp => {
                this.$root.toolbarColors.activeSet = resp.data.colorSet;
                this.$root.toolbarColors.font_color = resp.data.fontColor;
                this.updatingToolbarColors = false;
            }).catch(err => {
                console.log(err.response);
                this.$root.snackError(err.response.data.message);
                this.updatingToolbarColors = false;
            });
        },
        shopForCollectibles(type) {
            this.searching[type]= true;
            this.searchType = type;
            axios.get(`/api/collectibles/type/${type}`, {})
            .then(resp => {
                this.collectibles = resp.data;
                this.pinnedCollectibles = [];
                eventBus.$emit('unpinCollectibles', this.unpin);
                this.shopDialog = true;
                this.searching[type] = false;
            }).catch(err => console.log(err.response));
        },
        shopForToolbarColors(){
            this.toolbarDialog = true;
        },
        previousCard() {
            this.pinnedIndex = Math.max(0, this.pinnedIndex - 1);
        },
        nextCard() {
            this.pinnedIndex = Math.min(this.pinnedCollectibles.length - 1, this.pinnedIndex + 1);
        },
        searchCollectibles(){
            if(this.searchTitle == null || this.searchTitle.length == 0){
                this.shopForCollectibles(this.searchType);
            } else {
                this.searching[searchType] = true;
                axios.get(`/api/collectibles/search/${this.searchType}/${this.searchTitle}/`)
                .then(resp => {
                    this.collectibles = resp.data;
                    this.searching[searchType] = false;
                }).catch(err => console.log(err.response));
            }
        },
        goSearch(newSearch) {
            this.searching[searchType] = true;
            if (newSearch) {
                this.searchData = { title: this.searchTitle, type: this.searchType, show: this.searchShow, index: 1 };
                this.collectiblesPages = [];
            } else {
                this.searchData.index = this.page;
            }
            axios.post('/api/collectibles/search', this.searchData)
                .then(resp => {
                    this.collectibles = resp.data.collectibles;
                    this.collectiblesPages.push({ page: this.page, collectibles: resp.data.collectibles });
                    this.searching[searchType] = false;
                }).catch(err => console.log(err));
        },
        modifCollectible(col) {
            this.collectibles = this.collectibles.map(c => {
                if (col.id == c.id) {
                    return col
                } else {
                    return c
                }
            });
            this.pinnedCollectibles = this.pinnedCollectibles.map(c => {
                if (col.id == c.id) {
                    return col
                } else {
                    return c
                }
            });
        },
        pinCollectible(col) {
            this.pinnedCollectibles.unshift(col);
            this.pinnedIndex = 0;
        },
        collectibleEdited(col){
            this.collectibles = this.collectibles.map(c => col.id == c.id ? col : c);
            if(this.myCollection[col.type]){
                this.myCollection[col.type].unshift(col);
            } else {
                this.myCollection[col.type] = [col];
            }
        },
        avatarPurchased(collection){
            if(this.myCollection.avatarCollections.find(c => c.id == collection.id )){
                this.myCollection.avatarCollections = this.myCollection.avatarCollections.map(c => c.id == collection.id ? collection : c);
            } else {
                this.myCollection.avatarCollections = [collection, ...this.myCollection.avatarCollections];
            }
        },
        showCollectibleSelectCard(col){
            this.focusedCollectible = col;
            this.selectDialog = true;
        },
        showDeselectCard(type) {
            let defaults = {
                avatar: '/images/default avatar.png',
                correct: '/images/tick.png',
                incorrect: '/images/cross.png',
                pending: '/images/qmark.png',
                upcoming: '/images/race upcoming.png',
                active: '/images/race started.png',
                finished: '/images/race finished.png',
                toolbarColor: [{position: 0, color: '#f5f5f5'}]
            };
            if(this.$root.user.images[type] != defaults[type]){
                this.deselectType = type;
                this.deselectDialog = true;
            }
        },
        submitSelect() {
            this.loading = true;
            axios.post(`/api/collectibles/${this.focusedCollectible.id}/select`, {})
            .then(resp => {
                this.$root.user.images[this.focusedCollectible.type] = this.focusedCollectible.url;
                this.loading = false;
                this.selectDialog = false;
            }).catch(err => {
                console.log(err);
                this.$root.snackError(err.response.data.error);
            });
        },
        submitDeselect() {
            this.loading = true;
            axios.post(`/api/collectibles/${this.deselectType}/deselect`, {})
            .then(resp => {
                this.$root.user.images[this.deselectType] = resp.data;
                this.loading = false;
                this.deselectDialog = false;
            }).catch(err => {
                console.log(err);
                this.$root.snackError(err.response.data.error);
            });
        },
        titleClass(breakpointName){
            if (breakpointName == 'xs') return 'samfont-title';
            if (breakpointName == 'sm') return 'samfont-bigtitle';
            return 'samfont-display';
        },
        resetCarouselsShift(){
            this.resetShiftCounter++;
        }
    }
}
</script>

<style scoped>
    .circledNb{
        min-width: 15px;
        max-width: 15px;
        min-height: 15px;
        max-height: 15px;
        border-radius: 50%;
        background-color: black;
        color: white;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>