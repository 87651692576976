<template>
  <v-dialog v-model="addCollectibleDialog"  width="400px" @keydown.esc="cancelAddCollectible">
    <v-btn slot="activator" fab dark color="primary" fixed bottom right><v-icon dark style='display:inline-flex'>add</v-icon></v-btn>
    <v-card>
      <v-card-title>
        <span class="headline">Add a collectible</span>
      </v-card-title>
      <v-card-text>
        <v-text-field autofocus label="Title" v-model="title" :rules="titleRules" validate-on-blur autofocus clearable/>
        <v-select :items="types" label="type" v-model="type"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" :disabled='loading' flat style="width:140px" @click="cancelAddCollectible">Cancel</v-btn>
        <v-btn color="primary" :loading='loading' style="width:140px" @click="addCollectibleSubmit">Add</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props : {},
    data : function(){
      return {
        addCollectibleDialog: false,
        loading: false,
        title: '',
        type: null,
        types: [
          {text: "Avatar (300×300)", value: "avatar"},
          {text: "Correct answer (280×190)", value: "correct"},
          {text: "Incorrect answer (280×190)", value: "incorrect"},
          {text: "Pending answer (280×190)", value: "pending"},
          {text: "Race upcoming (400×450)", value: "upcoming"},
          {text: "Race active (400×450)", value: "active"},
          {text: "Race finished (400×450)", value: "finished"}
        ],
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 3) || 'The title must be at least 3 characters long'
        ]
      }
    },
    methods: {
      cancelAddCollectible(){
        this.type = null;
        this.title = '';
        this.addCollectibleDialog = false;
      },
      addCollectibleSubmit(){
        if(this.title.length >= 3 && this.type != null){
          this.loading = true;
          axios.post('/api/collectibles',
          {
            title: this.title,
            type: this.type
          })
          .then(resp => {
            let col = resp.data;
            eventBus.$emit('collectibleAdded', col);
            this.loading = false;
            this.cancelAddCollectible();
          })
          .catch(err => console.log(err));
        } else {
          this.$root.snackError('Missing data');
        }
      }
    }
  }
</script>
