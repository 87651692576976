<template>
    <v-layout row wrap>
        <v-flex xs12 class="text-xs-left py-2">
            <div style="display:flex;align-items:center">
                <v-btn icon>
                    <v-icon color="primary">directions_run</v-icon>
                </v-btn>
                <span style="flex:2;height:2px;" :style="`background:${this.$vuetify.theme.primary}`"/>
                <v-btn icon dark color="primary">
                    <v-icon dark>directions_run</v-icon>
                </v-btn>
                <span style="flex:1;height:1px;" :style="`background:${this.$vuetify.theme.primary}`"/>
                <v-btn icon>
                    <v-icon color="primary">location_on</v-icon>
                </v-btn>
                <span style="flex:1;height:1px;" class="grey"/>
                <v-btn icon disabled>
                    <v-icon color="primary">directions_run</v-icon>
                </v-btn>
            </div>
        </v-flex>
    </v-layout>
</template>
<script>
export default {
    props: { phase: Object },
    data: function () {
        return {
            //data here
        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>
