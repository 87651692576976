<template>
  <v-dialog v-model="newTrickDialog" @keydown.esc="cancelNewTrick" @keydown.enter.prevent="submitNewTrick" max-width="500px">
    <v-btn slot="activator" fab dark color="primary" fixed bottom right><v-icon dark style='display:inline-flex'>add</v-icon></v-btn>
      <v-card class="pa-2">
        <v-layout column fill-height>
          <v-card-title>
            <span class="headline">Make a new trick</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="newTrickForm" v-model="valid" lazy-validation>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    label="Title"
                    v-model="trickTitle"
                    :rules="titleRules"
                    autofocus clearable validate-on-blur
                    @blur="checkAvailability"
                    :messages="titleIsAvailable"
                    :error="Boolean(titleIsAvailable.length)"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-select :items="typeOptions" label="Type" v-model="trickType"></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-textarea label="Text description (HTML enabled)" v-model="trickDescription" :rules="descrRules" :counter='255' validate-on-blur class="mb-2"></v-textarea>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-layout>
              <v-spacer></v-spacer>
              <v-btn color="error" style="width:140px" flat @click="cancelNewTrick">Cancel</v-btn>
              <v-btn color="primary" style="width:140px"  @click="submitNewTrick">Create Trick</v-btn>
            </v-layout>
          </v-card-actions>
        </v-layout>
      </v-card>
  </v-dialog>
</template>
<script>
  export default {
    data : function(){
      return {
        newTrickDialog: false,
        valid: false,
        trickTitle: '',
        trickType: '',
        typeOptions: ['classic', 'algebra', 'fraction', 'power'],
        trickDescription: '',
        titleIsAvailable: [],
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 5) || 'The trick title must be at least 5 characters long'
        ],
        descrRules: [
          v => !!v || 'You must enter a description',
          v => (v && v.length >= 10) || 'The description must be at least 10 characters long',
          v => (v && v.length <= 255) || 'The description must be less 255 characters long'
        ]
      }
    },
    methods : {
      checkAvailability(){
        let vm = this;
        axios.get(`/api/tricks/available/${this.trickTitle}`, {})
        .then(function (response) {
          if(response.data){
            vm.titleIsAvailable = [];
          } else {
            vm.titleIsAvailable = ['This title is not available'];
          }
        })
        .catch(function (error) {
          console.log(error) // TODO fix that
        });
      },
      cancelNewTrick(){
        this.$refs.newTrickForm.reset();
        this.newTrickDialog = false;
      },
      submitNewTrick(){
        if(this.$refs.newTrickForm.validate()){
          let vm = this;
          axios.post('/api/tricks', {
            title: vm.trickTitle,
            type: vm.trickType,
            description: vm.trickDescription,
          })
          .then(function (response) {
            let newTrick = response.data;
            newTrick.options = JSON.parse(newTrick.options)
            eventBus.$emit('trickAdded', response.data);
            vm.trickTitle = '';
            vm.trickDescription = '';
            vm.newTrickDialog = false;
            vm.$root.snackSuccess("Trick created !");
            eventBus.$emit('editTrick', newTrick);
          })
          .catch(function (error) {
            if(error.response.status == 409){
              vm.$root.snackError("This title is not available.");
            } else {
              vm.$root.snackError("There was an error while creating the trick.");
            }

          });
        }
      }
    }
  }
</script>
