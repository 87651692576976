<template>
  <v-dialog v-model="joinClassDialog" persistent  max-width="500px" @keydown.esc="cancelJoin">
    <v-btn slot="activator" fab dark color="primary" fixed bottom right>
      <v-badge color='red' v-if='divisionInvites.length > 0 && !joinClassDialog'>
        <template v-slot:badge>
          <span>{{ divisionInvites.length }}</span>
        </template>
          <v-icon dark style='display:inline-flex'>
            add
          </v-icon>
      </v-badge>
      <v-icon dark style='display:inline-flex' v-else>
        add
      </v-icon>
    </v-btn>
    <v-card>
      <v-window v-model='activeWindow'>
        <v-window-item :key='0'>
          <v-card-title>
            <span class="headline">Class invites</span>
          </v-card-title>
          <v-card-text>
            <div>
              You have been invited to join the following classes :
            </div>
            <div v-for='(invite, index) in divisionInvites' :key='invite.id' class='mt-2'>
              <v-divider v-if='index > 0'/>
              <div style='display:flex;justify-content:space-between;align-items:center'>
                <span class='title'>
                  {{ invite.division.name }}
                </span>
                <span>
                  <v-btn color='primary' :loading='loading' :disabled='deletingInvite > 0' @click='acceptInvite(invite)'>
                    Join
                    <v-icon right>group</v-icon>
                  </v-btn>
                  <v-btn flat color='error' :disabled='loading || (deletingInvite>0 && deletingInvite!=invite.id)' :loading='deletingInvite == invite.id' @click='declineInvite(invite)'>
                    Delete
                    <v-icon right>delete_forever</v-icon>
                  </v-btn>
                </span>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" :disabled='loading' style="width:140px" flat @click="cancelJoin">Cancel</v-btn>
            <v-btn color="primary" flat :disabled='loading' @click="activeWindow = 1">Join another class</v-btn>
          </v-card-actions>
        </v-window-item>
        <v-window-item :key='1'>
          <v-card-title>
            <span class="headline">Look for a class</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="joinClassForm" v-model="valid" lazy-validation>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field label="Code" v-model="code" :rules="codeRules" :mask="mask" autofocus clearable validate-on-blur></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" :disabled='loading' style="width:140px" flat @click="cancelJoin">Cancel</v-btn>
            <v-btn color="primary" :loading='loading' style="width:140px" @click="findClass">Find Class</v-btn>
          </v-card-actions>
        </v-window-item>
        <v-window-item :key='2'>
          <v-card-title class='headline'>
            Join {{ division.name }}
          </v-card-title>
          <v-card-text>
            You are about to join <b>{{ division.owner.name }}</b>'s class. This person will be able to see :
            <ul>
              <li>Your name, email address and avatar</li>
              <li>Your star count and smile count</li>
              <li>All your performances on <span class='samfont'>Sam's Tricks</span></li>
            </ul>
            She/He will also be able set up races, hikes and marathon for you to train with your classmates.
            <div class='mt-2'>
              If you wish to join this class, indicate the name the teacher knows you by and click the "Join class" button below.
            </div>
            <div style="display:flex;">
              <v-text-field class='ma-2' validate-on-blur :rules="nameRules" label="First name" v-model='firstName'/>
              <v-text-field class='ma-2' validate-on-blur :rules="nameRules" label="Last name" v-model='lastName'/>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" style="width:140px" :disabled='loading' flat @click="cancelJoin">Cancel</v-btn>
            <v-btn color="primary" style="width:140px" :loading='loading' :disabled='firstName.length < 3 || lastName.length < 3' @click="joinClass">Join Class</v-btn>
          </v-card-actions>
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props: {divisionInvites: Array},
    data: function(){
      return {
        joinClassDialog: false,
        loading: false,
        deletingInvite: 0,
        acceptingInvite: null,
        activeWindow: 1,
        division: {owner: {}},
        valid : true,
        code : '',
        properName: '',
        firstName: '',
        lastName: '',
        mask : 'NNNNN',
        codeRules: [
          v => !!v || 'You must enter a class code',
          v => (v && v.length == 5) || 'The class code must be exactly 5 characters long'
        ],
        nameRules: [
          v => !!v || 'You must enter a name',
          v => (v && v.length > 3) || 'Your name must be at least 4 characters long'
        ],
      }
    },
    watch: {
      divisionInvites: {
        handler(val){
          if(val.length > 0)  {
            this.activeWindow = 0;
            this.joinClassDialog = true;
          }
        }
      }
    },
    created(){
      this.activeWindow = this.divisionInvites.length > 0 ? 0 : 1;
    },
    methods: {
      acceptInvite(invite){
        this.division = invite.division;
        // this.properName = invite.name;
        this.acceptingInvite = invite;
        this.activeWindow = 2;
      },
      declineInvite(invite){
        this.deletingInvite = invite.id;
        axios.delete(`/api/classes/invites/${invite.id}/decline`, {})
        .then(resp => {
          this.deletingInvite = 0;
          eventBus.$emit('removeInvite', invite);
        }).catch(err =>console.log(err.response));
      },
      findClass(){
        if(this.$refs.joinClassForm.validate()){
          this.loading = true;
          axios.get(`/api/classes/lookup/${this.code}`)
          .then(resp => {
            if(resp.data.properName){
              const nameParts = resp.data.properName.name.split(' ');
              this.firstName = nameParts[0];
              this.lastName = nameParts[1] ? nameParts[1] : '';
            }
            this.division = resp.data.division;
            this.activeWindow = 2;
            this.loading = false;
          }).catch(err => {
            console.log(err);
            console.log(err.response.data);
            this.$root.snackError(err.response.data.error);
          });
        }
      },
      fullName(){
        let firstNames = [];
        this.firstName.split(' ').forEach(part => {
          firstNames.push(part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());
        });
        
        return firstNames.join('-') + ' ' + this.lastName.toUpperCase();
      },
      joinClass(){
        // set full name
        if(this.firstName.length > 2 && this.lastName.length > 2){
          this.loading = true;
          axios.post(`/api/classes/join/${this.division.id}`, {properName: this.fullName()})
          .then(resp => {
            this.code = '';
            this.$refs.joinClassForm.reset();
            if(this.acceptingInvite != null){
              eventBus.$emit('removeInvite', this.acceptingInvite);
            }
            this.joinClassDialog = false;
            eventBus.$emit('refreshClassesData', {});
            this.loading = false;
          })
          .catch(function (error) {
            console.log(error)
            this.$root.snackError(error.response.data.error);
          });
        } else {
          this.$root.snackError('Invalid data submitted');
        }
      },
      cancelJoin(){
        this.code = '';
        this.activeWindow = this.divisionInvites.length > 0 ? 0 : 1;
        this.$refs.joinClassForm.reset();
        this.joinClassDialog = false;
      }
    }
  }
</script>
