<template>
  <v-layout>
    <v-hover v-if="!beingEdited">
      <v-flex xs12 sm6 md4 class="text-xs-left" style="height:50px;display:flex;align-items:center" slot-scope="{ hover }">
        <h3 class="headline mb-0" style="display:inline" >{{ className }}</h3>
        <v-icon v-if="hover" right @click="makeEditable">edit</v-icon>
      </v-flex>
    </v-hover>
    <v-flex xs12 class="text-xs-left" style="height:50px;" v-else  @keydown.esc="cancelNameChange" @keydown.enter.prevent="submitNewName">
      <v-form ref="changeClassNameForm" v-model="valid" lazy-validation>
        <v-layout>
            <v-flex xs6 md6>
              <v-text-field label="Class name" v-model="newClassName" :rules="nameRules" :error-messages="this.classNameError" autofocus></v-text-field>
            </v-flex>
            <v-flex xs6 md4 no-wrap>
              <v-btn color="primary" @click="submitNewName">Submit</v-btn>
              <v-btn color="error" flat @click="cancelNameChange" :ripple="false">Cancel</v-btn>
            </v-flex>
        </v-layout>
      </v-form>
    </v-flex>
    <v-flex sm6 md4 style="display:flex;align-items:center;justify-content:center" class="subheading grey--text">
      Class code : {{ code }}
    </v-flex>
    <v-flex md4 class="hidden-sm-and-down text-xs-right">
      <v-progress-circular indeterminate color="primary" class='mr-3 mt-2' :width="3" :size="30" v-if='calculating'/>
      <v-menu offset-y bottom left v-else>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <!-- <v-list-tile @click='calculateAdjustements'>
            <v-list-tile-avatar><v-icon>alarm_add</v-icon></v-list-tile-avatar>
            <v-list-tile-content>Calculate adjustments</v-list-tile-content>
          </v-list-tile> -->
          <v-list-tile :to='`/single-class/${code}/school-names`'>
            <v-list-tile-avatar><v-icon>label</v-icon></v-list-tile-avatar>
            <v-list-tile-content>Edit students' names</v-list-tile-content>
          </v-list-tile>
          <v-list-tile @click='inviteDialog = true'>
            <v-list-tile-avatar><v-icon>group_add</v-icon></v-list-tile-avatar>
            <v-list-tile-content>Invite students</v-list-tile-content>
          </v-list-tile>
          <v-list-tile @click='schoolDialog = true'>
            <v-list-tile-avatar><v-icon>school</v-icon></v-list-tile-avatar>
            <v-list-tile-content>School</v-list-tile-content>
          </v-list-tile>
          <edit-terms-dialog :terms="terms" :className="name" :classId="id"/>
          <v-divider></v-divider>
          <v-list-tile @click='archiveClassDialog = true'>
            <v-list-tile-avatar><v-icon color='error'>remove_circle</v-icon></v-list-tile-avatar>
            <v-list-tile-content class='error--text'>Archive</v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-flex>
    <!-- <handicap-dialog :students="handicaps" :classId="id"/> -->
    <v-dialog v-model="schoolDialog" width="450px">
      <v-card>
        <v-card-title class="headline">
          School
        </v-card-title>
        <v-card-text>
          <v-select outline v-model="schoolChoice" :items="schoolItems"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" style='width:150px;' flat :disabled="schoolLoading" @click="schoolDialog =false">Cancel</v-btn>
          <v-btn color="success" style='width:150px;' :loading="schoolLoading" @click="updateSchool">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="archiveClassDialog" width="350px">
      <v-card>
        <v-card-title class="headline pb-0">Archive {{ className }}</v-card-title>
        <v-card-text>Are you sure you want to archive this class ? <br/> It will be unsable by anyone until you take it out of the archive.</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" style='width:150px;' flat @click="archiveClassDialog=false">No</v-btn>
          <v-btn color="error" style='width:150px;' @click="proceedArchive()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model='inviteDialog' width='80%' max-width='850px'>
      <invite-students-card :id="id" :className="className" :studentsInvited="studentsInvited"/>
    </v-dialog>
  </v-layout>
</template>

<script>
  export default {
    props : {'name': String, 'code': String, 'id': Number, 'studentsInvited': Array, 'schoolId': Number, 'terms': Array },
    data : function(){
      return {
        beingEdited: false,
        newClassName: '',
        className: '',
        classNameError: '',
        valid: true,
        importingEmails: false,
        handicaps: [],
        archiveClassDialog: false,
        inviteDialog: false,
        calculating: false,
        inviteLoading: false,
        schoolDialog: false,
        schoolChoice: null,
        schoolLoading: false,
        nameRules: [
          v => !!v || 'You must enter a name',
          v => (v && v.length > 3) || 'The name must be at least 3 characters long',
          v => (v && v.length < 100) || 'The name must be at most 100 characters long'
        ],
        rules: {
          required: value => !!value || 'Required.',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }
        }
      }
    },
    computed: {
      schoolItems(){
        let schools = [{ text: 'No school yet', value: 0 }, { text: 'I do not want this class to belong to a school', value: 1 }];
        this.$root.schools.forEach(school => {
          schools.push({value: school.id, text: school.full_name});
        });

        return schools;
      }
    },
    mounted() {
      this.newClassName = this.name;
      this.className = this.name;
      this.schoolChoice = this.schoolId == null ? 0 : this.schoolId;
      eventBus.$on('closeInviteDialog', this.closeInviteDialog);
    },
    beforeDestroy(){
      eventBus.$off('closeInviteDialog', this.closeInviteDialog);
    },
    created() {

    },
    methods: {
      updateSchool(){
        this.schoolLoading = true;
        if(this.schoolChoice <= 1){
          axios.post(`/api/classes/${this.id}/quitschool`, {choice: this.schoolChoice})
          .then(resp => {
            eventBus.$emit('schoolUpdated', resp.data);
            eventBus.$emit('refreshClassesData', {});
            this.schoolLoading = false;
            this.schoolDialog = false;
          }).catch(err => console.log(err.response));
        } else {
          axios.post(`/api/classes/${this.id}/school/${this.schoolChoice}`, {})
          .then(resp => {
            eventBus.$emit('schoolUpdated', resp.data);
            eventBus.$emit('refreshClassesData', {});
            this.schoolLoading = false;
            this.schoolDialog = false;
          }).catch(err => console.log(err.response));
        }
      },
      closeInviteDialog(){
        this.inviteDialog = false;
      },
      makeEditable(){
        this.beingEdited = true;
      },
      submitNewName(){
        if(this.$refs.changeClassNameForm.validate()){
          let vm = this
          axios.patch('/api/classes/'+vm.id, {'name': vm.newClassName})
          .then(function (response) {
            vm.className = vm.newClassName;
            vm.beingEdited = false;
          })
          .catch(function (error) {
            // TODO do something here...
          });
        } else {
          this.classNameError = 'Invalid data submitted';
        }
      },
      cancelNameChange(){
        this.newClassName = this.name;
        this.beingEdited = false;
      },
      // calculateAdjustements(){
      //   this.calculating = true;
      //   axios.get(`/api/classes/${this.id}/classHandicap`, {})
      //   .then(resp => {
      //     console.log(resp.data);
      //     this.handicaps = resp.data;
      //     eventBus.$emit('showHandicapDialog', {});
      //     this.calculating = false;
      //   })
      //   .catch(err => console.log(err))
      // },
      proceedArchive(){
        let vm = this
        axios.put('/api/classes/'+this.id, {})
        .then(function (response) {
          vm.archiveClassDialog = false;
          vm.$router.go(-1);
        })
        .catch(function (error) {
          // TODO catch errors
        });
      }
    }
  } // TODO make a transition
</script>
