<template>
    <v-card flat>
        <v-card-title style='display:flex;justify-content:space-between;align-items:center'>
            <span class="headline">
            Buy challenges
            </span>
            <span>
            <div class='caption grey--text'>
                Your coin stack
            </div>
            <div class='title' :class='$root.user.coin_stack < 1000 ? "error--text" : ""'>
                {{ $root.user.coin_stack }} <v-icon :color='$root.user.coin_stack < 1000 ? "error" : "black"' class='ma-0' style='margin-left:-5px' right>radio_button_checked</v-icon>
            </div>
            </span>
        </v-card-title>
        <v-card-text>
            <div style='display:flex;flex-wrap:wrap;justify-content:space-between;' v-if='tracks.length > 0'>
            <buy-challenge-card v-for='(track, index) in tracks' :track='track' :key='track.id'/>
            </div>
            <div style='height:400px;display:flex;flex-direction:column' class='headline grey--text' v-else>
            <div style='height:100%;display:flex;justify-content:center;align-items:center;flex:1'>
                <span>
                loading...
                </span>
            </div>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" style='width:150px' flat @click="terminateShopping">
            Finish
            <v-icon right>chevron_right</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props: { tracks: Array },
    data: function () {
        return {
            //data here
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        terminateShopping(){
            eventBus.$emit('terminateChallengeShopping', {});
        }
    }
}
</script>
