<template>
  <v-card hover color="rgba(255, 255, 255, 0)" class='py-1 px-2 text-xs-right' style='white-space:nowrap' flat @click='showDetails'>
    <div class='subheading font-weight-medium'>
      {{ $root.user.star_count }}
      <v-icon size='20px' color="orange">star</v-icon>
    </div>
    <div class='subheading font-weight-medium'>
      {{ $root.user.smile_count }}
      <v-icon size='20px' color="blue">sentiment_satisfied_alt</v-icon>
    </div>
    <v-dialog v-model='dialog' width='90%'>
      <v-card class='pa-2'>
        <v-layout row wrap>
          <v-flex xs12 class='samfont-title mb-2'>
            Star and Smile count
          </v-flex>
          <v-flex xs12 class='mb-1'>
            <div class='title'>
              <v-icon color="orange">star</v-icon> are earned by trying tricks.
            </div>
            <div class='caption grey--text'>
              Your <span class='orange--text font-weight-bold'>star count</span> is the sum of your 3 best performances on all the tricks you've tried.
            </div>
          </v-flex>
          <v-flex xs12 class='mb-3'>
            <div class='title'>
              <v-icon color="blue">sentiment_satisfied_alt</v-icon> are earned by completing challenges.
            </div>
            <div class='caption grey--text'>
              Your <span class='blue--text font-weight-bold'>smile count</span> is the sum of all the smiles you've gained in challenges (minus the smiles you've lost by letting challenges expire).
            </div>
          </v-flex>
          <v-flex xs12 class='subheading'>
            <div>
              You can use <v-icon small color="orange">star</v-icon> and <v-icon small color="blue">sentiment_satisfied_alt</v-icon> to buy collectibles and personalise your Sam's Tricks experience
            </div>
            <div class='mt-2' style='width:100%;display:flex;justify-content:center'>
              <v-btn block class='pa-2' @click='goToCollectibles'>
                See collectibles
                <v-icon>chevron_right</v-icon>
              </v-btn>
            </div>
          </v-flex>
          <v-btn icon @click='dialog = false' style='position:absolute;right:-5px;top:-8px'>
            <v-icon color='error'>close</v-icon>
          </v-btn>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        dialog: false
      }
    },
    mounted() {
      eventBus.$on('starCountUpdate', newCount => {
        this.$root.user.star_count = newCount;
      });
      eventBus.$on('smileCountUpdate', data => {
        this.$root.user.smile_count = data.newCount;
      });
    },
    methods: {
      showDetails(){
        this.dialog = true;
      },
      goToCollectibles(){
        this.dialog = false;
        if(this.$router.currentRoute.path != '/collectibles'){
          this.$router.push('/collectibles');
        }
      }
    }
  }
</script>
