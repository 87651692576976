<template>
  <div>
    <v-toolbar style="transition: all 5s;" :style="`background: ${toolbarColor};`" app height='64px'>
      <v-toolbar-side-icon :dark="!isLight($root.toolbarColors.activeSet[0].color)" @click="openDrawer" :ripple='false' v-if="this.$root.status != 'guest' && !showDrawer"></v-toolbar-side-icon>
      <img src="/images/Samstricks logo.png" width='160px' style="cursor:pointer" @click="goHome" class="hidden-xs-only">
      <v-btn v-if='this.$root.user.id == 1' to='/admin' icon>
        <v-icon>dashboard</v-icon>
      </v-btn>
      <v-tooltip bottom v-for="school in $root.adminSchools" :key="school.id">
        <template v-slot:activator="{ on }">
          <v-btn icon :to="`/schools/${school.id}`" v-on="on">
            <v-icon :color='$root.toolbarColors.font_color'>school</v-icon>
          </v-btn>
        </template>
        <span>{{ school.full_name }}</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!-- <login-form :autoshow="autoshow" v-if="this.$root.status == 'guest'"></login-form>
        <register-form v-if="this.$root.status == 'guest'"></register-form> -->
        <profile-menu :user="user" v-if="this.$root.status != 'guest'"></profile-menu>
      </v-toolbar-items>
    </v-toolbar>
    <v-navigation-drawer
        v-if="this.$root.status != 'guest'"
        v-model="showDrawer"
        :mini-variant="miniDrawer"
        :permanent="permaDrawer"
        dark
        app
        :temporary="!permaDrawer"
        disable-resize-watcher
        mobile-break-point='100000'
        style="display:flex;flex-direction:column;overflow-y:hidden;">
          <v-list class="pa-1" grow>
            <v-list-tile v-if="miniDrawer" @click.stop="makeNormalDrawer">
              <v-list-tile-action>
                <v-icon>chevron_right</v-icon>
              </v-list-tile-action>
            </v-list-tile>

            <v-list-tile avatar tag="div" :class="{'my-5 py-3': !miniDrawer}">
              <v-list-tile-avatar :size='avatarSize'>
                <img :src="$root.user.images.avatar">
              </v-list-tile-avatar>
              <v-list-tile-content></v-list-tile-content>
              <v-list-tile-action class="text-xs-right">
                <v-btn icon @click.stop="makeMiniDrawer">
                  <v-icon>chevron_left</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>

            <v-list-tile>
              <v-list-tile-content>
                {{ this.$root.user.name }}
                <p class="grey--text">{{ this.$root.user.email }}</p>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider light></v-divider>

            <v-list-tile v-for="item in items" :key="item.title" :to="item.path">
              <v-list-tile-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ item.title }}
                  <v-progress-circular :width="1" :size="15" class='ml-2' color="white" indeterminate v-if='item.requiresClassesData && $root.classesDataLoaded == 0'/>
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>

          <v-spacer></v-spacer>

          <v-list class="mt-5">
            <v-list-tile to="/about">
              <v-list-tile-action>
                <v-icon>help</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>About</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile @click="closeDrawer">
              <v-list-tile-action>
                <v-icon>close</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>Close</v-list-tile-content>
            </v-list-tile>
          </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
  //import Routes
  import routes from '../routes';
  let navRoutes = [];
  routes.routes.forEach(function(route){
    if(route.navRoute){
      navRoutes.push(route);
    }
  });

  export default {
    props : {'status': String, 'autoshow': String, 'user': String, 'title': String},
    data : function(){
      return {
        showDrawer: false,
        miniDrawer: false,
        permaDrawer: false,
        mini: false,
        authUser: {},
        items: navRoutes,
        // avatar: '/images/default avatar.png',
      }
    },
    computed:{
      avatarSize: function(){
        return this.miniDrawer ? '35px' : '150px'
      },
      toolbarColor(){
        if(this.$root.toolbarColors.activeSet.length == 1){
          return this.$root.toolbarColors.activeSet[0].color;
        }
        let gradientSteps = '';
        this.$root.toolbarColors.activeSet.forEach(c => {
          gradientSteps += c.color + ' ' + c.position + '%,';
        });
        gradientSteps = gradientSteps.substring(0, gradientSteps.length - 1);
        
        return `linear-gradient(to right, ${gradientSteps})`;
      }
    },
    created(){
      if(this.user != ""){
        this.$root.user = JSON.parse(this.user);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$root.user.api_token;
      } else {
        delete axios.defaults.headers.common["Authorization"];
      }
      this.$root.status = this.status;
    },
    methods : {
      openDrawer(){
        this.showDrawer = true;
        if(this.miniDrawer){
          this.permaDrawer = true;
        }
      },
      closeDrawer(){
        this.showDrawer = false;
        this.permaDrawer = false;
      },
      makeMiniDrawer(){
        this.miniDrawer = true;
        this.permaDrawer = true;
      },
      makeNormalDrawer(){
        this.miniDrawer = false;
        this.permaDrawer = false;
      },
      goHome(){
        if(this.$root.status == 'guest'){
          window.location.replace('/');
        } else {
          this.$router.push('/home');
        }
      },
      isLight(color) {
        if(color){
          const hex = color.replace('#', '');
          const c_r = parseInt(hex.substring(0, 0 + 2), 16);
          const c_g = parseInt(hex.substring(2, 2 + 2), 16);
          const c_b = parseInt(hex.substring(4, 4 + 2), 16);
          const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
          return brightness > 155;
        }
        return false;
      }
    }
  }
</script>
