<template>
    <v-layout row wrap class="pa-1 text-xs-left">
        <v-flex xs12 class="samfont grey--text mb-2" style="font-size:28px;">
            Global announcement !
        </v-flex>
        <v-flex xs8>
            <div class="headline mb-2">
                {{ announcement.data.title }}
            </div>
            <div v-html="announcement.data.message"/>
            <div class="mt-3" v-if="announcement.data.includeButton">
                <v-btn color="primary" style="height:36px" block :to="announcement.data.linkUrl">
                    Check it out !
                </v-btn>
            </div>
        </v-flex>
        <v-flex xs4 style="background-image:url('/images/announcement sam.png');background-size:cover;margin-bottom:-10px"/>
    </v-layout>
</template>
<script>
export default {
    props: { announcement: Object }
}
</script>
