<template>
  <v-container class='mb-5'>
    <div v-if="$root.status == 'teacher'">
      <hike-card-teacher 
        :hike='hike' 
        :key="`hike-${hike.id}-${hike.updated_at}`" 
        v-for="hike in hikes"
      />
    </div>
    <div v-else>
      <hike-card-student
        :hike='hike'
        :key="`hike-${hike.id}-${hike.updated_at}`"
        v-for="hike in hikes"
      />
    </div>
    <edit-hike-dialog :classes='classes' v-if="$root.status == 'teacher'"/>
    <add-hike-fab v-if="$root.status == 'teacher'"/>
    <hike-perfs-dialog/>
  </v-container>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        hikes: [],
      }
    },
    computed: {
      classes(){
        let classes = [];
        this.$root.classesData.divisions.forEach(cl => {
          classes.push({id: cl.id, name: cl.name, value: cl.id, text: cl.name});
        });
        return classes;
      },
    },
    created(){
      eventBus.$emit('loading', {command: 'clear'});
      eventBus.$emit('loading', {command: 'start', name: 'hikes-page'});
    },
    mounted() {
      eventBus.$on('hikeAdded', this.addHike);
      eventBus.$on('hikeDeleted', this.removeHike);
      eventBus.$on('hikeStarsAdded', this.addStars);
      eventBus.$on('hikeUpdated', this.updateHike);
      axios.get('/api/hikes', {})
      .then(resp => {
        this.hikes = Object.values(resp.data);
        eventBus.$emit('loading', {command: 'finish', name: 'hikes-page'});
      }).catch(err => console.log(err));
    },
    beforeDestroy(){
      eventBus.$off('hikeStarsAdded', this.addStars);
      eventBus.$off('hikeAdded', this.addHike);
      eventBus.$off('hikeDeleted', this.removeHike);
      eventBus.$off('hikeUpdated', this.updateHike);
    },
    methods: {
      addStars(data){
        let hike = this.hikes.find(h => h.id == data.hikeId);
        hike.sectionStars += data.starsAdded;
        let track = hike.tracks.find(t => t.sectionId == data.sectionId);
        track.sectionStars += data.starsAdded;
        let trick = track.tricks.find(t => t.id == data.trickId);
        trick.sectionStars += data.starsAdded;
      },
      addHike(hike){
        this.hikes.unshift(hike);
        this.currentHike = hike.id;
      },
      removeHike(id){
        this.hikes = this.hikes.filter(h => h.id != id);
      },
      updateHike(hike){
        this.hikes = this.hikes.map(h => h.id == hike.id ? hike : h);
      },
      // refreshHike(hike){
      //   this.hikes = this.hikes.map(h => h.id == hike.id ? hike : h);
        // this.loading = true;
        // axios.get(`/api/hikes/${id}`, {})
        // .then(resp => {
        //   let tempArray = this.hikes;
        //   this.hikes = [];
        //   tempArray.forEach(hike => {
        //     if(hike.id == resp.data.id){
        //       this.hikes.push(resp.data);
        //       eventBus.$emit('hikeRefreshed', resp.data);
        //     } else{
        //       this.hikes.push(hike);
        //     }
        //   });
        //   this.forceUpdateCounter++;
        //   this.loading = false;
        // }).catch(err => console.log(err));
      // }
    }
  }
</script>
