<template>
  <v-layout row wrap>
    <v-flex xs12 sm6 md3 class="mb-5" v-for="(stat, index) in formatedStats" :key="index">
      <div class="title font-weight-thin mb-1 grey--text text-xs-center">
        {{ stat.title }}
        <v-icon>{{ stat.icon }}</v-icon>
      </div>
      <div style="height:70px;display:flex;justify-content:center;align-items:center" class="text-xs-center display-2 font-weight-bold">
        {{ stat.value }}
      </div>
      <div style='display:flex;justify-content:center;align-items:center' v-html='stat.footer'></div>
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    props : {stats: Object},
    data : function(){
      return {

      }
    },
    computed:{
      formatedStats(){
        let medalFooter = '';
        ['gold', 'silver', 'bronze'].forEach(color => medalFooter += `<span class="mx-1 font-weight-bold">${this.stats.races[color]}</span><div style="background:${this.$root.medalColors[color]};width:15px;height:15px;border-radius:50%;margin-right:5px"></div>`);
        let students = {
          title: "Total points earned",
          icon: "adjust",
          value: (this.stats.students.totalScore).toLocaleString('fr-FR'),
          footer: `over <span class="mx-1 font-weight-bold">${this.stats.students.nbTerms}</span> term${this.stats.students.nbTerms>1 ? 's' : ''}`
        };
        let races = {
          title: "Races finished",
          icon: "directions_run",
          value: this.stats.races.finished,
          footer: `out of <span class="mx-1 font-weight-bold">${this.stats.races.total}</span> race${this.stats.races.total > 1 ? 's' : ''}`
        };
        let medals = {
          title: "Medals won",
          icon: "check_circle",
          value: this.stats.races.bronze+this.stats.races.silver+this.stats.races.gold,
          footer: medalFooter
        };
        let hikes = {
          title: "Hikes progress",
          icon: "directions_walk",
          value: `${this.stats.hikes.globalProgress}%`,
          footer: `from <span class="mx-1 font-weight-bold">${this.stats.hikes.nb}</span> hike${this.stats.hikes.nb > 1 ? 's' : ''}`
        };
        return [students, races, medals, hikes];
      }
    }
  }
</script>
