<template>
    <v-layout align-center style="position:relative;min-height:492px;max-height:492px;">
        <v-item-group v-model="window" class="shrink mr-2" mandatory tag="v-flex" v-if="byThree.length > 1">
            <v-item v-for="n in byThree.length" :key="n">
                <div slot-scope="{ active, toggle }">
                <v-btn :input-value="active" flat icon @click="toggle">
                    <v-icon>radio_button_unchecked</v-icon>
                </v-btn>
                </div>
            </v-item>
        </v-item-group>
        <v-flex>
            <v-window v-model="window" vertical>
                <v-window-item v-for="(group, index) in byThree" :key="index">
                    <div style="min-height:492px;max-height:492px;">
                        <collection-display v-for="collection in group" :key="collection.id" :collection="collection"/>
                    </div>
                </v-window-item>
            </v-window>
        </v-flex>
        <collectible-card 
            style="position:absolute;top:5px;right:5px" 
            :collectible='focus.avatar'
            :collectionId="focus.collection.id"
            closable
            v-if="focus"
        />
    </v-layout>
</template>
<script>
export default {
    props: { collections: Array },
    data: function () {
        return {
            window: 0,
            buyAvatarDialog: false,
            focus: null,
        }
    },
    computed:{
        byThree(){
            const result = [];
            if(this.collections){
                for (let i = 0; i < this.collections.length; i += 3) {
                    result.push(this.collections.slice(i, i + 3));
                }
            }
            return result;
        }
    },
    mounted() {
        eventBus.$on('showBuyAvatarDialog', this.showBuyAvatarDialog);
        eventBus.$on('closeCollectibleCard', this.closeCollectibleCard);
    },
    beforeDestroy() {
        eventBus.$off('showBuyAvatarDialog', this.showBuyAvatarDialog);
        eventBus.$off('closeCollectibleCard', this.closeCollectibleCard);
    },
    methods: {
        showBuyAvatarDialog(data){
            this.focus = data;
        },
        closeCollectibleCard(){
            this.focus = null;
        }
    }
}
</script>
