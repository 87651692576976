<template>
  <span>
    {{ formatedTime }}
  </span>
</template>
<script>
  export default {
    props : { time: Number },
    computed: {
      formatedTime(){
        if(isNaN(this.time)) return '';
        if(this.time < 60) return `${this.time}s`;
        if(this.time < 3600){
          let minutes = Math.floor(this.time/60);
          let seconds = this.time % 60;
          if(seconds < 10){
            return `${minutes}m0${seconds}s`;
          } else {
            return `${minutes}m${seconds}s`;
          }
        }
        if(this.time < 3600*24){
          let hours = Math.floor(this.time/3600);
          let minutes = Math.floor((this.time % 3600)/60);
          if(minutes < 10){
            return `${hours}h0${minutes}m`;
          } else {
            return `${hours}h${minutes}m`;
          }
        }
        let days = Math.floor(this.time/(3600*24));
        let hours = Math.floor((this.time % (3600*24))/3600);
        if(hours < 10){
          return `${days}d0${hours}h`;
        } else {
          return `${days}d${hours}h`;
        }
      }
    }
  }
</script>
