<template>
  <v-layout row wrap>
    <v-flex xs12 class='subheading grey--text' v-if='students.length > 0'>
      Leading students in your classes
    </v-flex>
    <v-flex xs12 style='display:flex;flex-wrap:wrap'>
      <span
        style='display:flex;flex-direction:column;align-items:center;justify-content:flex-start;min-width:70px;max-width:70px;overflow-x:hidden;'
        class='mx-1 my-2'
        v-for='student in sortedStudents' :key='student.id'
      >
        <v-img width='50px' max-width='50px' height='50px' max-height='50px' style='border-radius:50%' :src='student.avatar'/>
        <div class='containedName text-xs-center' v-if='showNames'>{{ student.name }}</div>
        <div class='font-weight-bold text-xs-center' v-if='student.totalScore > 0'>
          {{ student.totalScore }} pt<span v-if='student.totalScore > 1'>s</span>
        </div>
      </span>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    props : { students: Array, showNames: Boolean },
    computed: {
      sortedStudents(){
        return this.students.sort((a,b) => a.totalScore < b.totalScore ? 1 : -1);
      }
    }
  }
</script>

<style scoped>
.containedName {
  max-height:42px;
  min-height:42px;
  overflow-y:hidden;
  overflow-wrap:break-word;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
</style>
