<template>
  <v-layout row wrap>
    <v-flex class='mt-2' xs12 v-for='(set, index) in trickSets' :key='set.id'>
        <tricks-carousel :index='index' :title='set.title' :tricks='set.data.tricks'/>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    props : { nbSetsDisplayed: {type: Number, default: 3}},
    data : function(){
      return {
        trickSets: []
      }
    },
    created() {
      this.getTrickSets();
      eventBus.$on('newSet',this.newSet);
    },
    beforeDestroy(){
      eventBus.$off('newSet', this.newSet);
    },
    watch: {
      nbSetsDisplayed(val){
        while(this.trickSets.length > val){
          this.trickSets.pop();
        }
      }
    },
    methods: {
      getTrickSets(){
        axios.get('/api/index/tricks/3', {})
        .then(resp => {
          this.trickSets = resp.data;
        })
        .catch(err => console.log(err));
      },
      newSet(index){
        axios.get('/api/index/tricks/4', {})
        .then(resp => {
          let usedIds = this.trickSets.map(o =>o.id);
          let temp = this.trickSets;
          this.trickSets = [];
          temp.forEach((s, i) => {
            if(i == index) {
              let k = 0;
              while(usedIds.includes(resp.data[k].id)){
                k++;
              }
              this.trickSets.push(resp.data[k]);
            } else {
              this.trickSets.push(s);
            }
          });
        })
        .catch(err => console.log(err));
      }
    }
  }
</script>
