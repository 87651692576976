<template>
  <v-list-tile @click="getPerfs" v-if='tile'>
    <v-list-tile-avatar><v-icon color="success">visibility</v-icon></v-list-tile-avatar>
    <v-list-tile-title>See perfs</v-list-tile-title>
    <race-perfs-dialog
      :race='race'
      :options='options'
      :thisStudentHistory='studentHistory'
      :racePerfsDialog='dialog'
      v-if='race.progress'
    />
  </v-list-tile>
  <v-btn icon :loading='loading' @click.stop='getPerfs' v-else>
    <v-icon style='display:inline-flex' color='success'>visibility</v-icon>
    <race-perfs-dialog
      :race='race'
      :options='options'
      :thisStudentHistory='studentHistory'
      :racePerfsDialog='dialog'
      v-if='race.progress'
    />
  </v-btn>
</template>
<script>
  export default {
    props : { raceId: Number, alwaysReloadRace: {type: Boolean, default: false}, tile: {type: Boolean, default: false}},
    data : function(){
      return {
        loading: false,
        race: {},
        options: {},
        studentHistory: {},
        dialog: false
      }
    },
    mounted() {
      eventBus.$on('closePerfsDialog', this.closePerfsDialog);
    },
    beforeDestroy() {
      eventBus.$off('closePerfsDialog', this.closePerfsDialog);
    },
    methods: {
      getPerfs(){
        if(!this.race.progress || this.alwaysReloadRace){
          this.loading = true;
          axios.get(`/api/races/${this.raceId}/progress`, {})
          .then(resp => {
            this.race = resp.data;
            this.options = JSON.parse(resp.data.options);
            this.studentHistory = resp.data.studentHistory;
            this.loading = false;
            this.dialog = true;
          }).catch(err => console.log(err));
        } else {
          if(typeof this.options != 'object'){
            this.options = JSON.parse(this.race.options);
          }
          this.dialog = true;
        }
      },
      closePerfsDialog(id){
        if(this.raceId == id){
          this.dialog = false;
        }
      },
    }
  }
</script>
