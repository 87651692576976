<template>
  <v-expansion-panel v-model='open' style='width:350px' v-if='$root.status == "teacher"'>
    <v-expansion-panel-content hide-actions>
      <template v-slot:header>
        <div style='display:flex;justify-content:space-between;align-items:center;'>
          <span>
            <span class='subheading grey--text' v-if='nb.students>0'>
              Verifying {{ nb.students }} student<span v-if='nb.students>1'>s</span> <span v-if='nb.classes>0'>({{ nb.classes }} class<span v-if='nb.classes>1'>es</span>)</span>
            </span>
            <span class='subheading grey--text' v-else>
              Not verifying anyone
              <v-btn class='ma-0' icon @click.stop='showHelp = true'>
                <v-icon color='primary'>help</v-icon>
              </v-btn>
            </span>
          </span>
          <v-icon color='success' v-if='perfVerification.code'>verified_user</v-icon>
          <v-icon color='grey lighten-2' v-else>shield</v-icon>
        </div>
      </template>
      <v-card>
        <v-layout row wrap class='px-3'>
          <v-flex xs6 class='px-2' v-if='perfVerification.code'>
            <div class='grey--text caption'>Time left</div>
            <div>{{ timeLeft }}</div>
          </v-flex>
          <v-flex xs6 class='px-2' v-if='perfVerification.code'>
            <div class='grey--text caption'>Code</div>
            <div>{{ perfVerification.code }}</div>
          </v-flex>
          <v-flex xs12 class='mt-2'>
            <v-expansion-panel v-model='selectClasses'>
              <v-expansion-panel-content>
                <template v-slot:header>
                  <div>
                    Classes ({{ selectedClasses.length }} selected)
                  </div>
                </template>
                <div style='display:flex;justify-content:space-between;flex-wrap:wrap;' class='px-2'>
                  <v-checkbox
                    v-model="selectedClasses"
                    :disabled='loading'
                    hide-details
                    :label="division.name"
                    :value='division.id'
                    class='mx-1 mb-2 mt-0'
                    v-for='division in $root.classesData.divisions'
                    :key='division.id'
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-flex>
        </v-layout>
        <div style='display:flex;'>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon :disabled='loading' @click="stopDialog = true">
                <v-icon color="error" dark v-on="on">dangerous</v-icon>
              </v-btn>
            </template>
            <span>Stop verifying</span>
          </v-tooltip>
          <v-spacer/>
          <v-btn :disabled='loading' flat color="primary" style='width:150px' @click="moreOptions">
            More options
          </v-btn>
          <v-spacer/>
          <v-tooltip top v-if="perfVerification.code">
            <template v-slot:activator="{ on }">
              <v-btn icon :loading='loading' @click="extendVerifying">
                <v-icon color="primary" dark v-on="on">add_circle</v-icon>
              </v-btn>
            </template>
            <span>Extend verifying</span>
          </v-tooltip>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on }">
              <v-btn icon :loading='loading' @click="startVerifying">
                <v-icon color="success" dark v-on="on">play_circle_outline</v-icon>
              </v-btn>
            </template>
            <span>Start verifying</span>
          </v-tooltip>
        </div>
        <div></div>
      </v-card>
    </v-expansion-panel-content>
    <v-dialog v-model='stopDialog' width='450px'>
      <v-card>
        <v-card-title class='headline'>Stop verifying ?</v-card-title>
        <v-card-text>
          Are you sure you want to stop all verifying now ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled='loading' flat style='width:150px' @click="stopDialog=false">
            Cancel
          </v-btn>
          <v-btn color="error" :loading='loading' style='width:150px' @click="stopVerifying">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width='450px' v-model='showHelp'>
      <v-card>
        <v-card-title class='headline'>
          Performance verification
        </v-card-title>
        <v-card-text>
          Performance verification allow teachers to attest for their students performances.
          <br/>
          Turn on performance verification when you can see that your students are working with no outside help.
          You can then see in their history which performances were done with supervision and which ones weren't.
          You can also make performance verification mandatory for races, ensuring a fair competition.
          <br/>
          You can verify an entire class or individual students (via a code which allow them to send you a verification request).
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn style='width:150px' color='primary' flat @click='showHelp = false'>
            Got it !
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expansion-panel>
  <v-card class='py-2 px-3' width='350px' @keydown.enter='proceedAsking' v-else>
    <v-window v-model='studentWindow'>
      <v-window-item :key='0'>
        <v-hover v-if='perfVerification && perfVerification.id'>
          <div style='display:flex;justify-content:space-between;align-items:center;height:45px' slot-scope="{ hover }">
              <span class='subheading text-truncate'>
                Verified by {{ perfVerification.teacherName }}
              </span>
              <span style='display:flex;align-items:center;overflow-x:hidden;transition: all .3s;' :style='hover ? "width:52px" : "width:24px"'>
                <v-icon color='success'>verified_user</v-icon>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn small v-on='on' :loading='loading' class='ma-0' icon @click='cancelVerificationDialog = true'>
                      <v-icon color='error'>cancel</v-icon>
                    </v-btn>
                  </template>
                  <span>Cancel verification</span>
                </v-tooltip>
              </span>
          </div>
        </v-hover>
        <div style='display:flex;justify-content:space-between;align-items:center;' v-else-if='verificationRequest && verificationRequest.id'>
          <span class='subheading grey--text'>
            Request sent
          </span>
          <span>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on='on' :loading='loading' class='ma-0' icon @click='cancelRequest'>
                  <v-icon color='error'>cancel</v-icon>
                </v-btn>
              </template>
              <span>Cancel request</span>
            </v-tooltip>
          </span>
        </div>
        <div style='display:flex;justify-content:space-between;align-items:center;' v-else>
          <span class='subheading grey--text'>
            Not being verified
          </span>
          <v-btn outline round small class='mx-0' color='primary' @click='studentWindow = 1'>
            Ask
          </v-btn>
        </div>
      </v-window-item>
      <v-window-item :key='1'>
        <div style='display:flex;align-items:center;'>
          <v-text-field class='mr-2 mt-0' v-model='verifyingCode' label='code' mask='NNNNN'/>
          <v-btn class='mx-0' icon flat :disabled='loading' @click="studentWindow = 0">
            <v-icon color="error">cancel</v-icon>
          </v-btn>
          <v-btn class='mx-0' icon :loading='loading' @click="askForVerifying">
            <v-icon color="success">play_arrow</v-icon>
          </v-btn>
        </div>
      </v-window-item>
    </v-window>
    <v-dialog v-model='cancelVerificationDialog' width='450px'>
      <v-card>
        <v-card-title class='headline'>Cancel verification ?</v-card-title>
        <v-card-text>
          Are you sure you want to stop being verified by {{ perfVerification.teacherName }} now ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled='loading' flat style='width:150px' @click="cancelVerificationDialog=false">
            Cancel
          </v-btn>
          <v-btn color="error" :loading='loading' style='width:150px' @click="cancelVerifying">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
  export default {
    props : { perfVerification: Object, verificationRequest: Object },
    data : function(){
      return {
        selectedClasses: [],
        open: null,
        selectClasses: null,
        stopDialog: false,
        loading: false,
        studentWindow: 0,
        verifyingCode: '',
        cancelVerificationDialog: false,
        showHelp: false
      }
    },
    computed:{
      divisions(){
        let classes = [];
        this.$root.classesData.divisions.forEach(cl => {
          classes.push({text: cl.name, value: cl.id});
        });
        return classes;
      },
      timeLeft(){
        return this.perfVerification ? `${Math.round(this.perfVerification.expiry/60)} minutes` : 0;
      },
      nb(){
        let students = 0;
        let classes = 0;
        if(this.perfVerification){
          Object.keys(this.perfVerification.classes).forEach(key => {
            if(key != 0){
              classes++;
            }
            students += this.perfVerification.classes[key].length;
          });
        }
        return {classes, students}
      }
    },
    mounted() {

    },
    methods: {
      proceedAsking(){
        if(this.$root.status == 'student' && this.studentWindow == 1){
          this.askForVerifying();
        }
      },
      cancelRequest(){
        this.loading = true;
        axios.delete(`/api/verify/ask`, {})
        .then(resp => {
          this.studentWindow = 0;
          eventBus.$emit('updateVerifying', {classes: [], code: null, expiry: 0});
          this.loading = false;
        }).catch(err => console.log(err));
      },
      askForVerifying(){
        if(this.verifyingCode.length == 5){
          this.loading = true;
          axios.post(`/api/verify/ask`, {code: this.verifyingCode})
          .then(resp => {
            this.studentWindow = 0;
            this.loading = false;
            eventBus.$emit('verificationRequested', resp.data);
            this.verifyingCode = '';
          }).catch(err => {
            console.log(err.response);
            this.$root.snackError(err.response.data.message);
            this.loading = false;
            this.verifyingCode = '';
          });
        } else {
          this.$root.snackError("Incomplete code");
        }
      },
      startVerifying(){
        this.loading = true;
        axios.post(`/api/verify`, {divisions: this.selectedClasses, students: ['all'], time: 30})
        .then(resp => {
          eventBus.$emit('updateVerifying', resp.data);
          console.log(resp.data);
          this.loading = false;
          this.selectClasses = null;
        }).catch(err => console.log(err));
      },
      extendVerifying() {
        this.loading = true;
        axios.post(`/api/verify/extend`, {})
          .then(resp => {
            eventBus.$emit('updateVerifying', resp.data);
            this.loading = false;
            this.selectClasses = null;
          }).catch(err => console.log(err));
      },
      moreOptions(){
        eventBus.$emit('openVerifyingDialog', {});
      },
      stopVerifying(){
        this.loading = true;
        axios.delete(`/api/verify`, {})
        .then(resp => {
          eventBus.$emit('updateVerifying', {classes: [], code: null, expiry: 0});
          this.loading = false;
          this.open = null;
          this.selectClasses = null;
          this.selectedClasses = [];
          this.stopDialog = false;
        }).catch(err => console.log(err));
      },
      cancelVerifying(){
        this.loading = true;
        axios.delete(`/api/verify/${this.perfVerification.id}`, {})
        .then(resp => {
          this.cancelVerificationDialog = false;
          eventBus.$emit('updateVerifying', resp.data);
          this.loading = false;
        }).catch(err => console.log(err));
      }
    }
  }
</script>

<style scoped>
  .sliding {
    overflow-x: hidden;
    transition: all .5s;
  }
</style>
