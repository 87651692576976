<template>
  <v-container grid-list-md>
    <v-text-field
      label="Search a collectible"
      append-icon="search"
      v-model="searchedTitle"
      outline clearable
      @keyup="userTyping"
      @click:clear="initCollectibles"
      @keydown.enter="goSearch"
    />
    <v-layout row wrap>
      <div class='title grey--text text--lighten-2 text-xs-center' style='width:100%' v-if='collectibles.length == 0'>Nothing to show here...</div>
      <collectible-admin-card v-for='collectible in collectibles' class='ma-1' :collectible='collectible' :key='collectible.id'/>
    </v-layout>
    <edit-collectible-dialog :collectible='focusedCollectible' v-if='focusedCollectible != null'/>
    <add-collectible-fab/>
  </v-container>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        collectibles: [],
        focusedCollectible: null,
        typingTimer: null,
        searchedTitle: ''
      }
    },
    created() {
      eventBus.$emit('loading', {command: 'clear'});
      eventBus.$emit('loading', {command: 'start', name: 'admin-collectibles-page'});
      this.initCollectibles()
    },
    mounted() {
      eventBus.$on('editCollectible', this.editCollectible);
      eventBus.$on('collectibleAdded', this.addCollectible);
      eventBus.$on('collectibleEdited', this.modifCollectible);
      eventBus.$on('removeCollectible', this.removeCollectible);

    },
    beforeDestroy() {
      eventBus.$off('editCollectible', this.editCollectible);
      eventBus.$off('collectibleAdded', this.addCollectible);
      eventBus.$off('collectibleEdited', this.modifCollectible);
      eventBus.$off('removeCollectible', this.removeCollectible);
    },
    methods: {
      initCollectibles(){
        axios.get('/api/collectibles', {})
        .then(resp => {
          this.collectibles = resp.data;
          this.focusedCollectible = this.collectibles[0];
          eventBus.$emit('loading', {command: 'finish', name: 'admin-collectibles-page'});
        }).catch(err => console.log(err));
      },
      userTyping(){
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this.goSearch, 750);
      },
      goSearch(){
        clearTimeout(this.typingTimer);
        if(this.searchedTitle.length == 0){
          this.initCollectibles();
        } else if(this.searchedTitle.length >= 3){
          let filters = JSON.stringify({types: ['avatar', 'correct', 'incorrect', 'pending']});
          axios.get(`/api/collectibles/search/${this.searchedTitle}/${filters}`)
          .then(resp => {
            this.collectibles = resp.data;
          }).catch(err => console.log(err));
        }
      },
      editCollectible(col){
        this.focusedCollectible = col;
      },
      addCollectible(col){
        this.collectibles.unshift(col);
        eventBus.$emit('editCollectible', col);
      },
      modifCollectible(col){
        this.collectibles = this.collectibles.map(c => {
          if(col.id == c.id){
            return col
          } else {
            return c
          }
        });
        this.$root.snackSuccess('Collectible edited');
      },
      removeCollectible(id){
        this.collectibles = this.collectibles.filter(c => c.id != id);
      }
    }
  }
</script>
