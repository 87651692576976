<template>
  <v-expansion-panel v-model="mainPanel">
    <v-expansion-panel-content >
      <template v-slot:header>
        <div style='position:absolute;top:0;height:100%;width:1px' :style="{background:strip, left:`${index}px`}" v-for="(strip, index) in strips" :key="`strip-${index}`"></div>
        <v-layout row class="mr-2">
          <v-flex xs10 sm4 :class="`text-xs-left ${mainPanel == 0 ? 'title' : 'text-truncate'}`" style='display:flex;align-items:center'>
            {{ track.title }}
          </v-flex >
          <v-flex sm4 :class="`${starCountFormat.color}--text font-weight-${starCountFormat.fontWeight} hidden-xs-only text-xs-center`" :style='`font-size:${starCountFormat.size}px;display:flex;align-items:center;justify-content:center`'>
            <div>
              {{ starCountFormat.display }}
              <v-icon :color='starCountFormat.color' :size='starCountFormat.size' style='vertical-align:text-top'>star</v-icon>
            </div>
          </v-flex >
          <v-flex sm4 class="grey--text hidden-xs-only text-xs-center" style='display:flex;align-items:center;justify-content:center'>
            <difficulty-display :difficulty='track.relative_difficulty' size='standard'></difficulty-display>
          </v-flex >
          <v-flex xs2 class="grey--text hidden-sm-and-up text-xs-center" style='display:flex;align-items:center;justify-content:center'>
            <difficulty-display :difficulty='track.relative_difficulty' size='small'></difficulty-display>
          </v-flex >
        </v-layout>
      </template>
      <v-card class="pa-1">
        <v-flex xs12 class="px-3" style='display:flex;'>
          <div style='flex:1'>
            {{ track.text_description }}
          </div>
        </v-flex>
        <v-card-text>
          <tricks-carousel :index='0' title='Tricks' :tricks='tricks' :showRefresh='false'/>
        </v-card-text>
        <div style="position:absolute;right:0;bottom:0">
          <v-btn color="primary" fab small dark  @click="editThisTrack" v-if="dbtrack.editable">
            <v-icon style='display:inline-flex'>edit</v-icon>
          </v-btn>
          <v-dialog v-model="deleteDialog" width="320">
           <template v-slot:activator="{ on }">
             <v-btn color="error" fab small dark v-on="on" v-if="dbtrack.editable">
               <v-icon style='display:inline-flex'>delete_forever</v-icon>
             </v-btn>
           </template>
           <v-card>
             <v-card-title class="headline">Delete {{ track.title }}</v-card-title>
             <v-card-text>Are you sure you want to delete this track ?</v-card-text>
             <v-card-actions>
               <v-btn color="primary" flat block @click="deleteDialog=false">
                 No
               </v-btn>
               <v-btn color="error" block @click="deleteThisTrack">
                 Yes
               </v-btn>
             </v-card-actions>
           </v-card>
         </v-dialog>
        </div>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
  export default {
    props : { dbtrack: Object },
    data : function(){
      return {
        mainPanel: null,
        track : {},
        totalStars: 0,
        tricks: [],
        editableRessource: true,
        deleteDialog: false,
        strips: [],
        shiftPos: 0,
        startPos: true,
        finishPos: false,
      }
    },
    computed: {
      starCount(){
        let starCount = 0;
        this.tricks.forEach(trick => {
          trick.star_count_perfs.forEach(perf => {
            starCount += this.$root.scoreToRating(perf.score);
          });
        });

        return starCount;
      },
      starCountFormat(){
        let display = `${this.starCount}/${this.totalStars}`;
        let color = 'grey';
        let size = 16;
        let fontWeight = 'regular';
        if(this.starCount == this.totalStars){
          color = 'success'
          size = 32
          fontWeight = 'bold';
        } else if (this.starCount >= 0.8*this.totalStars){
          color = 'primary'
          size = 24
        }
        return {display, color, size, fontWeight};
      }
    },
    created() {
      this.track = this.dbtrack;
      this.tricks = this.track.tricks;
      this.totalStars = this.tricks.length*15;
      this.makeStrips();

      eventBus.$on('trackUpdated', (track) => {
        if(track.id == this.track.id){
          this.track = {};
          this.track = track;
          this.tricks = [];
          this.tricks = this.track.progress.tricks;
          this.totalStars = this.tricks.length*15;
          this.makeStrips();
        }
      });
    },
    methods: {
      editThisTrack(){
        eventBus.$emit('editTrack', this.track);
      },
      deleteThisTrack(){
        let vm = this;
        axios.delete(`/api/tracks/${this.track.id}`, {})
        .then(function(response){
          vm.$root.snackSuccess(`${vm.track.title} has been deleted`);
          vm.deleteDialog = false;
          eventBus.$emit('trackDeleted', vm.track.id);
        })
        .catch(function(error){
          console.log(error); 
        });
      },
      makeStrips(){
        this.strips = [];
        this.tricks.forEach(trick => this.strips.push(this.$root.trickColors[trick.type]));
      }
    }
  }
</script>
