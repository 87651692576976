<template>
  <v-card style="width:90%" class="py-3" hover>
    <v-chip label small color="red" text-color="white" style="position:absolute;top:0;right:0" v-if="perf.new">NEW !</v-chip>
    <v-flex class="text-xs-center mx-a headline mb-3">
      <v-avatar color="primary">
        <span class="white--text headline">{{ perf.place }}</span>
      </v-avatar>
    </v-flex>
    score : <b>{{ perf.score }}</b>
    <v-rating v-model="rating" size="28" color="green" empty-icon='star' full-icon='stars' background-color="green lighten-3" v-if='hike' readonly/>
    <v-rating v-model="rating" size="28" color="orange" background-color="orange lighten-3" v-else readonly/>
    <v-flex class="grey--text">{{ readableDate }}</v-flex>
  </v-card>
</template>
<script>
  import moment from 'moment-timezone';

  export default {
    props : {perf: Object, hike: {type: Boolean, default: false}},
    data : function(){
      return {
        readableDate: '',
        rating: 0,
      }
    },
    mounted(){
      this.readableDate = this.perf.new ? 'Just now' : moment.utc(this.perf.date).tz(moment.tz.guess()).fromNow();
      this.rating = this.$root.scoreToRating(this.perf.score);
    }
  }
</script>
