<template>
    <v-card
        hover ripple 
        class="key white--text" 
        width="30"
        height="35"
        :color="bgColor"
        @click="emitChar"
    >
        <span v-if="char.value.length == 1">
            {{ char.value }}
        </span>
        <v-icon small color="white" v-else>{{ char.value }}</v-icon>
    </v-card>
</template>
<script>
export default {
    props: { char: Object },
    data: function () {
        return {
            //data here
        }
    },
    computed: {
        bgColor() {
            let color = '#808A9F';
            switch (this.char.status) {
                case 'placed':
                    color = '#00CC66';
                    break;
                case 'misplaced':
                    color = '#F0A202';
                    break;
                case 'absent':
                    color = '#232E21';
                    break;
            }
            return color;
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        emitChar() {
            eventBus.$emit('riddleChar', this.char.value);
        }
    }
}
</script>
<style scoped>
    .key {
        /* width: 30px;
        height: 35px;
        border-radius: 2px; */
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 20px;
        transition: background-color 1.5s ;
    }
</style>