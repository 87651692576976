<template>
  <v-dialog v-model="newHikeDialog" @keydown.esc="cancelNewHike" @keydown.enter.prevent="submitNewHike" max-width="500px">
    <v-btn slot="activator" fab dark color="primary" fixed bottom right><v-icon dark style='display:inline-flex'>add</v-icon></v-btn>
    <v-card>
      <v-card-title>
        <span class="headline">Create a new hike</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-form ref="newHikeForm" v-model="valid" lazy-validation>
            <v-layout wrap>
              <v-flex xs12 sm10>
                <v-text-field label="Name" v-model="hikeTitle" :rules="titleRules" autofocus clearable validate-on-blur></v-text-field>
              </v-flex>
              <v-flex xs12 sm2>
                <v-select v-model="hikeTerm" :items="[1,2,3,4,5,6,7,8,9,10]" label="Term"></v-select>
              </v-flex>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" style="width:140px" flat @click="cancelNewHike">Cancel</v-btn>
        <v-btn color="primary" style="width:140px" @click="submitNewHike">Create Hike</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment-timezone';

  export default {
    data : function(){
      return {
        newHikeDialog: false,
        valid: false,
        hikeTitle: '',
        hikeTerm: 1,
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 3) || 'The hike title must be at least 3 characters long'
        ]
      }
    },
    methods : {
      cancelNewHike(){
        this.$refs.newHikeForm.reset();
        this.newHikeDialog = false;
      },
      submitNewHike(){
        if(this.$refs.newHikeForm.validate()){
          let vm = this;
          axios.post('/api/hikes', {
            title: vm.hikeTitle,
            term: vm.hikeTerm
          })
          .then(function (response) {
            eventBus.$emit('hikeAdded', response.data);
            vm.$refs.newHikeForm.reset();
            vm.newHikeDialog = false;
            vm.$root.snackSuccess("Hike created !");
            eventBus.$emit('editHike', response.data);
          })
          .catch(function (error) {
            console.log(error)
            vm.$root.snackError("There was an error while creating the hike."); // TODO test that
          });
        }
      }
    }
  }
</script>
