<template>
  <v-dialog v-model="showDialog" width='565px' @keydown.esc="closeDialog">
    <v-card>
     <v-card-title class='headline'>
       Edit leaderboard students names
     </v-card-title>
     <v-card-text style='display:flex;flex-wrap:wrap'>
       <v-card v-for='student in studentsDetails' :key='student.id' width='250px' class='px-3 py-2 ma-2'>
         <v-text-field v-model='student.name' :rules='nameRules' label='Student name' hide-details/>
         <div class='grey--text mt-2 text-xs-right'>
           <div>{{ student.email }}</div>
           <div>{{ student.className }}</div>
         </div>
       </v-card>
     </v-card-text>
     <div style='display:flex;justify-content:flex-end'>
       <div style='display:flex'>
         <span class='grey--text'>
           Also save as class name
         </span>
         <v-checkbox class='mt-0 pt-0' :ripple='false' hide-details v-model='saveAsProper'/>
       </div>
     </div>
     <div style='display:flex;justify-content:flex-end'>
       <v-btn color="error" style='width:180px' flat @click="closeDialog">Cancel</v-btn>
       <v-btn color="success" style='width:180px' @click="submit">Confirm</v-btn>
     </div>
   </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props : { students: Array },
    data : function(){
      return {
        showDialog: false,
        saveAsProper: true,
        studentsDetails: [],
        nameRules: [
          v => !!v || 'You must enter a name',
          v => (v && v.length > 2) || 'This name is too short'
        ]
      }
    },
    mounted() {
      eventBus.$on('showLeaderboardEditDialog', this.initDialog);
    },
    beforeDestroy(){
      eventBus.$off('showLeaderboardEditDialog', this.initDialog);
    },
    methods: {
      initDialog() {
        this.studentsDetails = this.students.map(s => ({id: s.id, name: s.name, classId: s.classId, className: s.className, email: s.email}));
        this.showDialog = true;
      },
      closeDialog(){
        this.showDialog = false;
      },
      submit(){
        let changes = [];
        this.studentsDetails.forEach((st, index) => {
          if(st.name != this.students[index].name){
            changes.push(st);
          }
        });
        if(changes.length){
          eventBus.$emit('editLeaderboardNames', changes);
          if(this.saveAsProper){
            changes.forEach(s => {
              if(s.name.length > 2){
                axios.post(`/api/marathons/${this.$route.params.id}/classes/${s.classId}/properNames`, {students: [{id: s.id, properName: s.name}]})
                .then(resp => this.$root.snackSuccess('Names saved'))
                .catch(err => console.log(err))
              }
            });
          }
        }
        this.showDialog = false;
      }
    }
  }
</script>
