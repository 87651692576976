<template>
  <v-card hover
    @click="expandTrick"
    :style="`width:${width}px;height:${height}px;display:inline-block;vertical-align:top;border-radius:${bradius}px;overflow:hidden;`"
    class="pa-2 ma-1"
    >
    <difficulty-display :difficulty='trick.relative_difficulty' size='small' style='position:absolute'></difficulty-display>
    <v-flex style="width:90%;margin-left:5%;text-align:center;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
      {{ trick.title }}
    </v-flex>
      <v-layout row wrap v-if="showDetails" class="mt-2">
        <v-flex xs12 class="text-xs-center" v-if="showRating">
          <v-rating v-model="rating" size="14" color="orange" background-color="orange lighten-3" readonly></v-rating>
        </v-flex>
        <v-flex xs12>
          {{ trickDetails }}
        </v-flex>
        <div class="bottomGradient" style="height:60px;display:flex;justify-content:flex-end">
          <v-btn fab small flat color="success" :ripple='false' @click.stop="seeMore">
            <v-icon dark style='display:inline-flex'>visibility</v-icon>
          </v-btn>
        </div>
      </v-layout>
  </v-card>
</template>
<script>
  export default {
    props : { trick: Object, showRating: {type: Boolean, default: true}},
    data : function(){
      return {
        updatedTrick: {},
        rating: 0,
        width: '180',
        height: '35',
        bradius: '8',
        showDetails: false,
        trickDetails: ''
      }
    },
    created(){
      this.updatedTrick = this.trick;
      this.rating = this.trick.rating
    },
    mounted() {
      let vm = this;
      eventBus.$on('expandTrick', (id) => {
        if(vm.trick.id != id){
          this.width = '180';
          this.height = '35';
          this.bradius = '8';
          this.showDetails = false;
        }
      });
      eventBus.$on('trickFinished', (data) => {
        if(data.id == vm.trick.id){
          vm.rating = Math.max(vm.rating, vm.$root.scoreToRating(data.score));
          if(data.starCountUpdate > 0){
            this.updatedTrick.star_count_perfs.push({score: data.score});
            if(this.updatedTrick.star_count_perfs.length > 3){
              this.updatedTrick.star_count_perfs.sort((a,b)=>(a.score > b.score) ? -1 : 1);
              this.updatedTrick.star_count_perfs.pop();
            }
          }
        }
      });
    },
    methods : {
      expandTrick(){
        if(this.showDetails){
          this.width = '180';
          this.height = '35';
          this.bradius = '8';
          this.showDetails = false;
        } else {
          eventBus.$emit('expandTrick', this.trick.id)
          this.width = '250';
          this.height = '200';
          this.bradius = '2';
          this.showDetails = true;
          this.trickDetails = this.trick.text_description
        }
      },
      seeMore(){
        eventBus.$emit('focusTrick', this.updatedTrick);
      }
    }
  }
</script>

<style scoped>
.bottomGradient{
  position:absolute;
  left:0;
  bottom:0;
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items:flex-end;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1), rgba(255,255,255,1));
}

</style>
