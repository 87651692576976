<template>
  <v-flex>
    <v-btn flat class="my-0" icon color="primary" @click="tryTrick()" v-if="small">
      <v-icon dark>grade</v-icon>
    </v-btn>
    <v-layout style='display:flex' v-else>
      <v-flex style='flex:1'>
        <v-btn color="primary" :ripple="false" block @click="tryTrick()">
          Try
          <v-icon right>grade</v-icon>
        </v-btn>
      </v-flex>
      <v-flex style='flex:0;position:relative'>
        <v-btn :color="timeModfierColor" class='my-1 ml-1 mr-0' fab small dark @click="showTimeModifierSlider = !showTimeModifierSlider">
          <v-icon style='display:inline-flex'>timer</v-icon>
        </v-btn>
        <v-card flat dark class='tmBadge caption' v-if="timeModifier" :color='timeModfierColor'>
          {{ timeModifier }}s
        </v-card>
      </v-flex>
      <v-slide-y-reverse-transition>
        <div class='timerSliderWrap2' v-if='showTimeModifierSlider'>
          <div class='timerSliderWrap'>
            <div :class='withEdit ? "timerSliderWithEdit" : "timerSlider"'>
              <v-btn flat icon color="primary" style="position:absolute;top:-5px;right:-5px;" @click="timeModifierReset">
                <v-icon>settings_backup_restore</v-icon>
              </v-btn>
              <div class='pa-1'>
                <span class='caption grey--text'>Extra Time (+{{ timeModifier }}s)</span>
              </div>
            </div>
          </div>
          <v-slider v-model="timeModifier" thumb-label :color="timeModfierColor" :thumb-size="32" min="0" max="45" class='mx-3 mt-1' @change="emitTimeModifier">
            <template v-slot:thumb-label="props">
              <span>
                +{{ timeModifier }}s
              </span>
            </template>
          </v-slider>
        </div>
      </v-slide-y-reverse-transition>
    </v-layout>
  </v-flex>
</template>
<script>
  export default {
    props : {
      trickId: Number,
      small:{type: Boolean, default: false},
      type: {type: String, default: 'classic'},
      message:{type: String, default: 'Try'},
      withEdit: {type: Boolean, default: false}
    },
    data : function(){
      return {
        showTimeModifierSlider: false,
        timeModifier: 0
      }
    },
    computed: {
      timeModfierColor: function(){
        if(this.timeModifier == 0) return 'primary'
        if(this.timeModifier < 10) return 'success'
        if(this.timeModifier < 20) return 'success darken-1'
        if(this.timeModifier < 30) return 'success darken-2'
        if(this.timeModifier < 50) return 'success darken-3'
      }
    },
    mounted(){
      eventBus.$on('initTimeModifier', data => {
        this.showTimeModifierSlider = false;
        this.timeModifier = 0;
        eventBus.$emit('timeModifierUpdated', 0);
      });
    },
    methods : {
      tryTrick(){
        eventBus.$emit('tryTrick', {id: this.trickId, timeModifier: this.timeModifier, type: this.type});
      },
      emitTimeModifier(){
        eventBus.$emit('timeModifierUpdated', this.timeModifier);
      },
      timeModifierReset(){
        this.timeModifier = 0;
        eventBus.$emit('timeModifierUpdated', 0);
      }
    }
  }
</script>

<style scoped>
  .timerSlider {
    width: 375px;
    height:80px;
    background: white;
    clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 98% 80%, 93% 100%, 88% 80%, 0% 80%);
  }
  .timerSliderWithEdit {
    width: 375px;
    height:80px;
    background: white;
    clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 86% 80%, 81% 100%, 76% 80%, 0% 80%);
  }
  .timerSliderWrap {
    position: absolute;
    left:2px;
    bottom:0px;
    filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
  }
  .timerSliderWrap2 {
    position: absolute;
    display:flex;
    align-items: flex-end;
    bottom: 48px;
    left: 20px;
    height: 45px;
    width: 360px;
  }
  .tmBadge {
    position: absolute;
    top: 0px;
    display:flex;
    justify-content: center;
    align-items: center;
    right: -7px;
    top:-7px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
</style>
