<template>
  <transition-group name='bestList' tag='div' v-if='runners'>
    <div style='width:100%;display:block' class='py-1' v-for="runner in runners" :key="`best-${runner.user_id}`">
      <best-runner-bar :stars='runner.stars' :max='maxStars' :name='runner.user_data.name' :avatar='runner.user_data.avatar' :reward='runner.reward'/>
    </div>
  </transition-group>
  <v-layout row wrap v-else>
    <v-flex xs12 class='grey--text text--lighten-1 text-xs-center headline pa-3'>
      Nothing to see here <v-icon color='grey lighten-1'>sentiment_dissatisfied</v-icon>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    props: { runners: Array, maxStars: Number },
  }
</script>

<style scoped>
  .bestList-enter {
    display:none;
  }
  .bestList-enter-active, {
    transition: all 1s;
  }
  .bestList-leave-active {
    transition: all 1s;
    position: absolute;
  }
  .bestList-move {
    transition: transform 1s;
  }
  .bestList-enter-to {
    animation: grow 1s;
  }
  .bestList-leave-to {
    animation: grow 1s reverse;
  }
  @keyframes grow {
    0% {
      transform-origin: center left;
      transform: scale(0);
    }
    100% {
      transform-origin: center left;
      transform: scale(1);
    }
  }
</style>
