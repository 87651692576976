<template>
  <div>
    <v-layout row wrap v-if='you'>
      <v-flex xs12 class='headline grey--text text-xs-center'>
        Your performance
      </v-flex>
      <v-flex xs12 style='display:flex;justify-content:center'>
        <v-img max-width='150px' style='border-radius:50%' :src='$root.user.images.avatar'/>
      </v-flex>
      <v-flex xs12 class='display-1 font-weight-bold mb-2 text-xs-center'>
        <span v-if='showing == "race"'>
          {{ you.score }} pts
        </span>
        <span v-else>
          {{ you.totalScore }} pts
        </span>
      </v-flex>
    </v-layout>
    <v-layout row wrap  v-if='showing == "race"'>
      <v-flex xs6 style='display:flex;flex-direction:column;justify-content:space-around;'>
        <div class='display-1' style='white-space:nowrap'>
          {{ you.details.stars }}/{{ you.details.maxStars }}
          <v-icon :size='42' style='margin-left:-10px;' color='orange'>star</v-icon>
        </div>
        <div class='grey--text text-xs-center' v-if='you.runningTime'>
          Race finished in {{ $root.formatRunningTime(you.runningTime) }}
        </div>
      </v-flex>
      <v-flex xs6>
        <v-img height='120' contain class='mx-2' :src="`/images/medal ${you.reward}.png`" v-if='you.reward'/>
      </v-flex>
      <div ref='medalsDiv'/>
    </v-layout>
    <v-layout row wrap v-else>
      <v-flex xs6 class='subheading grey--text text-xs-center'>
        Races points
      </v-flex>
      <v-flex xs6 class='subheading grey--text text-xs-center'>
        Hike points
      </v-flex>
      <v-flex xs6 class='title text-xs-center'>
        {{ you.details.raceRewardsScore + you.details.raceStarsScore }} pts
      </v-flex>
      <v-flex xs6 class='title text-xs-center'>
        {{ you.details.hikeScore }} pts
      </v-flex>
      <v-flex xs12 class='px-1 mt-3'>
        <div class='subheading grey--text'>Races rewards</div>
        <div ref='medalsDiv' style='position:relative'>
          <v-img
            width='50'
            :src="`/images/medal ${medal} small.png`"
            style='position:absolute;top:0px;'
            :style='`left:${medalSpace*index}px`'
            v-for='(medal, index) in medals' :key='index'
          />
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
  export default {
    props : { you: Object, showing: String },
    data : function(){
      return {
        divWidth: 350
      }
    },
    watch: {
      showing(val){
        this.setDivWidth();
      }
    },
    computed:{
      medals(){
        let medals = [];
        if(this.you.details.races){
          this.you.details.races.forEach(r => {
            if(r.phaseScore.reward){
              medals.push(r.phaseScore.reward);
            }
          });
        }
        return medals;
      },
      medalSpace(){
        return (this.divWidth-50)/this.medals.length;
      }
    },
    mounted() {
      this.$nextTick(function(){
        window.addEventListener('resize', this.setDivWidth);
        this.setDivWidth();
     });
    },
    beforeDestroy(){
      window.removeEventListener('resize', this.setDivWidth);
    },
    methods: {
      setDivWidth(){
        this.divWidth = this.$refs['medalsDiv'].clientWidth;
      }
    }
  }
</script>
