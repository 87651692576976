<template>
    <div>
        <div class='pa-1'>
            <span class='headline grey--text'>
                School invite
            </span>
        </div>
        <v-layout row wrap>
            <v-flex xs12 class='pa-1 subheading'>
                <div>
                    You have been invited by <b>{{ announcement.invite.inviter.name }}</b> to join the school
                </div>
                <div class="headline mt-3 text-xs-center">
                    <b>{{ announcement.invite.school.full_name }}</b>
                </div>
                <div class="grey--text text-xs-center mb-3">
                    ({{ announcement.invite.school.short_name }})
                </div>
            </v-flex>
            <v-flex xs12 class="caption mb-3">
                If you accept this invite, the school administrator from this site will be able to see and edit your classes. They will also be able to enroll your classes in the school's marathons.
            </v-flex>
            <v-flex xs12 style="display:flex;justify-content:space-around;">
                <v-btn color="error" flat style="width:140px;height:32px;" :disabled='loading' @click="answer(false)">
                    Decline
                </v-btn>
                <v-btn color="success" style="width:140px;height:32px;" :loading='loading' @click="answer(true)">
                    Accept
                </v-btn>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
export default {
    props: { announcement: Object },
    data: function () {
        return {
            loading: false
        }
    },
    methods: {
        answer(decision){
            this.loading = true;
            axios.post(`/api/schools/${this.announcement.invite.school.id}/invite/response/${decision ? 1 : 0}`, {})
            .then(resp => {
                eventBus.$emit('deleteAnnouncement', this.announcement.id);
                this.loading = false;
            }).catch(err => console.log(err.response));
        }
    }
}
</script>
