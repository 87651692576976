<template>
  <v-layout row wrap>
    <v-flex xs12 style='display:flex;justify-content:center'>
      <v-card width="500px" class='mt-4 mx-2'>
        <v-card-title>
          <p class="headline">Request password reset</p>
        </v-card-title>
        <v-card-text>
          <v-form ref="requestForm" v-model="valid" lazy-validation>
              <v-text-field label="Email" type="email" v-model="userEmail"></v-text-field>
              <v-btn block color="primary" @click="sendMail">Send reset email</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    props : [],
    data : function(){
      return {
        valid: false,
        userEmail: ''
      }
    },
    methods : {
      sendMail(){
        let vm = this;
        axios.post('/password/email', {
          email: this.userEmail
        })
        .then(function (response) {
          vm.$root.snackSuccess('The reset email has been sent.');
        })
        .catch(function (error) {
          vm.$root.snackError('There was an error while sending the email.');
          console.log(error); // TODO fix this
        });
      }
    }
  }
</script>
