<template>
  <v-layout row wrap>
    <v-flex xs12 class='grey--text caption'>
      Validate tricks
    </v-flex>
    <v-flex xs12>
      <div style='display:flex;justify-content:space-between;align-items:center;max-width:250px'>
        <v-tooltip left content-class='subheading'>
         <template v-slot:activator="{ on }">
           <span v-on="on">
             <v-rating v-model="options.trickValidation" size="16" color="orange" background-color="orange lighten-3" readonly></v-rating>
           </span>
         </template>
         <span>{{ options.trickValidation }}<v-icon style='vertical-align:top;margin-right:3px' size="20" color="orange">star</v-icon> to validate a trick.</span>
       </v-tooltip>
       <span style='display:flex'>
         <v-tooltip top content-class='subheading'>
           <template v-slot:activator="{ on }">
             <span style='display:flex;align-items:center' v-on="on">
               <v-icon :color='verifIcon.color'>verified_user</v-icon>
             </span>
           </template>
           <span>
             <div>{{ verifIcon.text }}</div>
             <div class='caption' v-for='teacher in verifIcon.teachers' :key='teacher.id'>- {{ teacher.name }}</div>
           </span>
         </v-tooltip>
         <v-tooltip right content-class='subheading'>
           <template v-slot:activator="{ on }">
             <span style='display:flex;align-items:center' v-on="on">
               <v-icon :color='`${race.maxExtraTime > 0 ? "primary" : "grey lighten-2"}`'>timer</v-icon>
               <span v-if="race.maxExtraTime > 0">
                 +{{ race.maxExtraTime }}s
               </span>
             </span>
           </template>
           <span>
             <span v-if="race.maxExtraTime > 0">
               +{{ race.maxExtraTime }}s extra time allowed !
             </span>
             <span v-else>
               No extra time allowed
             </span>
           </span>
         </v-tooltip>
       </span>
      </div>
    </v-flex>
    <v-flex xs12 class='grey--text caption'>
      Finish the race
    </v-flex>
    <v-flex xs12>
      <v-tooltip left content-class='subheading'>
       <template v-slot:activator="{ on }">
         <div style='max-width:250px' v-on="on">
           <v-chip label style='width:40px' small :color="i <= options.trackValidation ? 'success' : 'primary'" v-for='i in race.track.tricks.length' :key='`vit-${i}`'>
           </v-chip>
         </div>
       </template>
       <span>Validate {{ options.trackValidation }} tricks to complete the race.</span>
     </v-tooltip>
    </v-flex>
    <v-flex xs12 class='grey--text caption mt-1'>
      Get rewards
    </v-flex>
    <v-flex xs12>
      <div style='position:relative;height:60px;max-width:100%;overflow-x:hidden'>
        <v-tooltip left content-class='subheading' v-if='options.rewardRule == "fastest"'>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-img width='50' src='/images/stopwatch small.png' style='position:absolute;'></v-img>
            </span>
          </template>
          <span><b>Fastest gets gold :</b><br>Rewards awarded to the fastest running times.</span>
        </v-tooltip>
        <v-tooltip left content-class='subheading' v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-img width='50' src='/images/place ribbon small.png' style='position:absolute;'></v-img>
            </span>
          </template>
          <span><b>First gets gold :</b><br>Reward awarded to the first students to finish the race.</span>
        </v-tooltip>
        <v-tooltip right content-class='subheading'>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-img width='50'
                v-for='k in parseInt(options.bronze)'
                :src="`/images/medal bronze small.png`"
                style='position:absolute'
                :style='`left:${medalGap*(parseInt(options.bronze)-k)+medalGap*(options.gold)+medalGap*(options.silver)+60}px`'
                :key='`bronze-${k}`'>
              </v-img>
              <v-img width='50'
              v-for='k in parseInt(options.silver)'
                :src="`/images/medal silver small.png`"
                style='position:absolute'
                :style='`left:${medalGap*(parseInt(options.silver)-k)+medalGap*options.gold+55}px`'
                :key='`silver-${k}`'>
              </v-img>
              <v-img width='50'
                v-for='k in parseInt(options.gold)'
                :src="`/images/medal gold small.png`"
                style='position:absolute'
                :style='`left:${medalGap*(parseInt(options.gold)-k)+50}px`'
                :key='`gold-${k}`'>
              </v-img>
            </span>
          </template>
          <span>
            <b>{{ options.gold }}</b> gold medal<span v-if='options.gold > 1'>s</span>
            <b>{{ options.silver }}</b> silver medal<span v-if='options.silver > 1'>s</span>
            <b>{{ options.bronze }}</b> bronze medal<span v-if='options.bronze > 1'>s</span>
          </span>
        </v-tooltip>
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    props : { options: Object, race: Object },
    data : function(){
      return {
        // data here
      }
    },
    computed:{
      medalGap(){
        let nbMedals = parseInt(this.options.gold) + parseInt(this.options.silver) + parseInt(this.options.bronze);
        if(nbMedals > 30){
          return 8;
        } else if(nbMedals > 20){
          return 10;
        } else {
          return 20;
        }
      },
      verifIcon(){
        if (this.options.verificationRequired && this.options.verificationRequired.length){
          let color = this.$root.status == 'student' && !this.options.verificationRequired.find(o => o.id == this.$root.perfVerification.teacherId) ? 'error' : 'success';
          return {color, text: 'Verification required', teachers: this.options.verificationRequired};
        }
        return {color: 'grey lighten-2', text: 'No verification required', teachers: []};
      }
    },
    mounted() {

    },
    methods: {

    }
  }
</script>
