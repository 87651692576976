<template>
    <v-window v-model="window" style="position:relative;">
        <v-window-item v-for="(challenge, index) in challenges" :key="`challenge-${challenge.id}`" :value="index">
            <v-card class="pa-2" min-width="75%" style="margin: 0px 49px;" >
                <v-layout row wrap class="text-xs-center">
                    <v-flex xs12 class='body font-weight-thin font-italic mt-2 mb-2 grey--text text-xs-right' v-if='challenge.timeLeft'>
                        - Expires in {{ challenge.timeLeft }} -
                    </v-flex>
                    <v-flex 
                        xs12
                        style="min-height:48px;position:relative;display:flex;justify-content:center;align-items:center;"
                        class='title py-1 font-weight-bold'
                    >
                        <div 
                            style='position:absolute;top:4px;height:40px;width:1px' 
                            :style="{ background: $root.trickColors[trick.type], left: `${index}px` }" 
                            v-for="(trick, index) in challenge.track.tricks" :key="`strip-${index}`"
                        />
                        <span>
                            {{ challenge.track.title }}
                        </span>
                    </v-flex>
                </v-layout>
                <div style='position:absolute;top:0px;display:flex;'>
                    <v-img width='65' :src='`/images/Challenge bonus - variety x${challenge.options.varietyBonus}.png`'  v-if='challenge.options.varietyBonus > 1'></v-img>
                    <v-img width='65' :src='`/images/Challenge bonus - diff x${challenge.options.difficultyBonus}.png`' v-if='challenge.options.difficultyBonus > 1'></v-img>
                </div>
            </v-card>
        </v-window-item>
        <v-btn style="position:absolute;top:15px;left:0px;" icon :disabled="window == 0" @click="window--">
            <v-icon>chevron_left</v-icon>
        </v-btn>
        <v-btn style="position:absolute;top:15px;right:0px;" icon :disabled="window == challenges.length - 1 || challenges.length == 0" @click="window++">
            <v-icon>chevron_right</v-icon>
        </v-btn>
    </v-window>
</template>
<script>
export default {
    props: { challenges: Array },
    data: function () {
        return {
            window: 0,
            fetchingChallenges: false,
        }
    },
    mounted(){
        eventBus.$on('exitChallenge', this.initCarousel);
    },
    beforeDestroy(){
        eventBus.$off('exitChallenge', this.initCarousel);
    },
    watch:{
        window(val){
            eventBus.$emit('activeChallengeUpdated', val);
        }
    },
    methods:{
        initCarousel(){
            this.window = 0;
        }
    }
}
</script>
