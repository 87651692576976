<template>
  <canvas id='graphChallengesOverTime'></canvas>
</template>
<script>
  // import Chart from 'chart.js'

  export default {
    data : function(){
      return {
        ctx: {},
        chart: {}
      }
    },
    mounted() {
      this.ctx = document.getElementById('graphChallengesOverTime');
      this.chart = new Chart(this.ctx, {});
      eventBus.$on('challengesOverTime', this.updateGraph);
    },
    beforeDestroy(){
      eventBus.$off('challengesOverTime', this.updateGraph);
    },
    methods: {
      updateGraph(perfs){
        this.chart.destroy();
        let smileCount = [];
        let labels = [];
        perfs.forEach(perf => {
          smileCount.push(perf.smileCount);
          labels.push(perf.date);
        });
        this.chart = new Chart(this.ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets: [
              {
                data: smileCount,
                label: 'Smile count increase',
                borderColor: 'rgba(0, 0, 255, 1)',
                backgroundColor: 'rgba(0, 0, 255, 0.1)'
              },
            ]
          },
        });
      }
    }
  }
</script>
