<template>
  <v-dialog v-model='dialog' max-width='850px'>
    <v-card>
      <v-layout row wrap>
        <v-flex xs12 sm6 md4>
          <v-hover>
            <div slot-scope="{ hover }" style='max-height:400px;height:100%;display:flex;align-items:center;position:relative'>
              <v-img :src="imageUrl" v-if="imageUrl"></v-img>
              <v-img max-width='400px' max-height='100%' contain :src='collectible.url' v-else/>
              <input type="file" style="display:none" ref="image" accept="image/*" @change="onFilePicked">
              <v-btn medium v-if='hover' icon style='position:absolute;right:20px;bottom:20px' @click='pickFile'>
                <v-icon medium>edit</v-icon>
              </v-btn>
            </div>
          </v-hover>
        </v-flex>
        <v-flex xs12 sm6 md8>
          <v-layout column style='height:100%' class='py-3 pl-4 pr-3'>
            <v-layout row wrap>
              <v-flex xs12 class='headline mb-2'>
                Edit Collectible details
              </v-flex>
              <v-flex xs12>
                <v-text-field label="Title" v-model="collectible.title" :rules="titleRules" validate-on-blur autofocus clearable/>
                <v-select :items="types" label="type" v-model="collectible.type"/>
              </v-flex>
              <v-flex xs12 class='caption grey--text'>
                Price
              </v-flex>
              <v-flex xs4 class='pr-2'>
                <v-text-field label="Star count req." type="number" min="0" max="5000" v-model="collectible.stars"/>
              </v-flex>
              <v-flex xs4 class='px-2'>
                <v-text-field label="Smiles" type="number" min="0" max="5000" v-model="collectible.smiles"/>
              </v-flex>
              <v-flex xs4 class='pr-2'>
                <v-text-field label="Coins" type="number" min="0" max="9999" v-model="collectible.coins"/>
              </v-flex>
            </v-layout>
            <v-spacer/>
            <v-layout style='display:flex;justify-content:flex-end;align-items:flex-end;'>
              <v-btn color="error" :disabled='loading || imageLoading' style='width:200px' flat @click="dialog = false">Cancel</v-btn>
              <v-btn color="success" :loading='loading || imageLoading' style='width:200px' @click="submit">Save</v-btn>
            </v-layout>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props : {collectible: Object},
    data : function(){
      return {
        dialog: false,
        loading: false,
        imageLoading: false,
        alsoUpdatingImage: false,
        imageName: '',
        imageFile: '',
        imageUrl: '',
        types: [
          {text: "Avatar (300×300)", value: "avatar"},
          {text: "Correct answer (280×190)", value: "correct"},
          {text: "Incorrect answer (280×190)", value: "incorrect"},
          {text: "Pending answer (280×190)", value: "pending"},
          {text: "Race upcoming (400×450)", value: "upcoming"},
          {text: "Race active (400×450)", value: "active"},
          {text: "Race finished (400×450)", value: "finished"}
        ],
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 3) || 'The title must be at least 3 characters long'
        ]
      }
    },
    mounted() {
      eventBus.$on('editCollectible', this.show);
    },
    beforeDestroy() {
      eventBus.$off('editCollectible', this.show);
    },
    methods: {
      show(col){
        this.imageUrl = col.url;
        this.dialog = true;
      },
      submit() {
        if(this.imageFile != ''){
          this.alsoUpdatingImage = true;
          this.submitImage();
        }
        axios.patch(`/api/collectibles/${this.collectible.id}`, {
          title: this.collectible.title,
          type: this.collectible.type,
          stars: this.collectible.stars,
          smiles: this.collectible.smiles,
          coins: this.collectible.coins
        }).then(resp => {
          if(!this.alsoUpdatingImage){
            this.concludeEdit(resp.data);
          }
        }).catch(err => console.log(err));

      },
      pickFile () {
        this.$refs.image.click();
      },
      onFilePicked (e) {
        const files = e.target.files;
        if(files[0] !== undefined) {
          if(files[0].size < 512*1000){
            this.imageName = files[0].name;
            if(this.imageName.lastIndexOf('.') <= 0) {
              return
            }
            const fr = new FileReader()
            fr.readAsDataURL(files[0])
            fr.addEventListener('load', () => {
              this.imageUrl = fr.result;
              this.imageFile = files[0];
            });
          } else {
            this.$root.snackError("This file is too large (max 512kb)");
            this.imageName = '';
            this.imageFile = '';
            this.imageUrl = '';
          }
        } else {
          this.imageName = '';
          this.imageFile = '';
          this.imageUrl = '';
        }
      },
      submitImage(){
        this.imageLoading = true;
        let data = new FormData();
        data.append('image', this.imageFile);
        axios.post(`/api/collectibles/${this.collectible.id}/image`, data)
        .then(resp => {
          this.imageLoading = false;
          this.alsoUpdatingImage = false;
          if(!this.loading){
            this.concludeEdit(resp.data);
          }
        })
        .catch(err => console.log(err));
      },
      concludeEdit(col){
        eventBus.$emit('collectibleEdited', col);
        this.dialog = false;
      }
    }
  }
</script>
