<template>
  <div>
    <div class='section-1' style='position:relative'>
      <div class='overlay-1'>
        <div style='display:flex;' class='pt-2 mr-1'>
          <v-spacer/>
          <div style='display:flex'>
            <login-form/>
            <register-form/>
          </div>
        </div>
        <div>
          <div class='samfont mt-4' style='font-size:36px;'>
            Welcome to
          </div>
          <samstricks-display style='margin-top:-15px'/>
          <div class='white--text hidden-sm-and-down' style='margin-top:-20px;font-family:Piko;font-size:55px;text-shadow:rgba(255,255,255,0.9) 0px 0px 5px;'>
            Take your mental calculation skills to the stars !
          </div>
        </div>
        <v-dialog max-width="450px" class='videoWrapper' persistent v-model="videoDialog">
          <iframe frameborder="0" width="100%" height="100%" src='https://www.youtube.com/embed/zP7mTcv0CXA?enablejsapi=1'/>
          <template v-slot:activator="{ on }">
            <v-hover style='position:absolute;bottom:110px;right:10px;cursor:pointer'>
              <v-img v-on="on" slot-scope="{ hover }" style='transition: all .5s' :max-width='`${hover ? 55 : 50}px`' :min-width='`${hover ? 55 : 50}px`' src="/images/Play button.png" contain/>
            </v-hover>
          </template>
          <div style='position:absolute;top:2%; left:0px;width:100%;display:flex;justify-content:center'>
            <v-btn color="error" outline large @click='closeVideoDialog'>
              Close <v-icon right large>close</v-icon>
            </v-btn>
          </div>
        </v-dialog>
      </div>
      <div style='height:100px'></div>
      <div class="custom-shape-divider-bottom-1629635770">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        </svg>
      </div>
    </div>
    <v-container>
      <v-layout row wrap>
        <v-flex sm6 lg4 hidden-xs-only>
          <v-img src='/images/Welcome/Sam section 2.png' max-width='400px' height='100%' contain/>
        </v-flex>
        <v-flex xs12 sm6 lg8>
          <samstricks-display style='margin-top:-15px;max-width:760px'/>
          <div class='text-xs-center grey--text' style='font-family:Piko;font-size:36px;max-width:760px'>
            Where you try tricks and take daily challenges to push your mental calculations to the next level, and the next level, and the next level...
          </div>
          <v-layout d-flex justify-center row wrap class='mt-2 text-xs-center' style='max-width:760px'>
            <v-card
              min-width='300px'
              max-width='280px'
              height='250px'
              style='border-radius:10%'
              hover
              class='ma-2'
              @mouseover="cardOneActive = true"
              @mouseleave="cardOneActive = false"
            >
              <v-card-title class='samfont-title py-0' style='display:flex;justify-content:space-between;align-items:center;height:110px;min-height:110px;max-height:110px;'>
                <div style='min-width:150px;max-width:150px' class='text-xs-left'>
                  Tricks and challenges
                </div>
                <div>
                  <v-img src="/images/Welcome/Samanimation.gif" width="70px" class='ma-2' v-if='cardOneActive'/>
                  <v-img src="/images/Welcome/Sam head default.png" width="80px" class='ma-2' style="padding:12px;" v-else/>
                </div>
              </v-card-title>
              <v-card-text class='font-weight-medium grey--text' style='text-align:justify;'>
                <span class='samfont'>Sam's Tricks</span> contains over <span class='font-weight-bold'>250 tricks</span>, organised by theme and difficulty, for you to work on.
                You'll also get <span class='font-weight-bold'>daily challenges</span> to help you train regularly, keeping your brain fast and sharp.
              </v-card-text>
            </v-card>
            <v-card
              min-width='300px'
              max-width='280px'
              height='250px'
              style='border-radius:10%'
              hover
              class='ma-2'
              @mouseover="activateCardTwo"
              @mouseleave="deactivateCardTwo"
            >
              <v-card-title class='py-0' style='display:flex;justify-content:space-between;align-items:center;height:110px'>
                <div style='min-width:150px;max-width:150px' class='text-xs-left samfont-title'>
                  Stars and smiles
                </div>
                <div class='headline' :class='{"grey--text": !cardTwoActive, "text--lighten-1": !cardTwoActive}'>
                  <div>
                    {{ starSmileCount }}<v-icon class='ml-1' medium :color="cardTwoActive ? 'orange' : 'grey lighten-1'">star</v-icon>
                  </div>
                  <div>
                    {{ starSmileCount }}<v-icon class='ml-1' medium :color="cardTwoActive ? 'blue' : 'grey lighten-1'">sentiment_satisfied_alt</v-icon>
                  </div>
                </div>
              </v-card-title>
              <v-card-text class='font-weight-medium grey--text' style='text-align:justify;'>
                Trying ticks will reward you with <b>stars</b> and challenges will get you <b>smiles</b>.
                Keep your star count and smile count up and claim <b>special rewards</b> to customize your <span class='samfont'>Sam's Tricks</span> experience.
              </v-card-text>
            </v-card>
            <v-card
              min-width='300px'
              max-width='280px'
              height='250px'
              style='border-radius:10%'
              hover
              class='ma-2'
              @mouseover="cardThreeActive = true"
              @mouseleave="cardThreeActive = false"
            >
              <v-card-title class='samfont-title py-0' style='display:flex;justify-content:space-between;align-items:center;height:110px'>
                <div style='min-width:150px;max-width:150px' class='text-xs-left'>
                  Races and hikes
                </div>
                <div>
                  <v-img src="/images/Welcome/Racing Sam.png" width="100px" class='ma-2' :class='{"racing": cardThreeActive}'></v-img>
                </div>
              </v-card-title>
              <v-card-text class='font-weight-medium grey--text' style='text-align:justify;'>
                Teachers can use <span class='samfont'>Sam's Tricks</span> <b>in the classroom</b> to follow up on students' progress.
                They can organise <b>races and hikes</b> amongst students and give them reward for their hard work.
              </v-card-text>
            </v-card>
            <v-card
              min-width='300px'
              max-width='280px'
              height='250px'
              style='border-radius:10%'
              hover
              class='ma-2'
              @mouseover="cardFourActive = true"
              @mouseleave="cardFourActive = false"
            >
              <v-card-title class='samfont-title py-0' style='display:flex;justify-content:space-between;align-items:center;height:110px'>
                <div style='min-width:150px;max-width:150px' class='text-xs-left'>
                  All of Sam for free
                </div>
                <div>
                  <v-img src="/images/Welcome/No money.png" width="80px" class='ma-2 slowTransition' :class='{"flipped": cardFourActive}'></v-img>
                </div>
              </v-card-title>
              <v-card-text class='font-weight-medium grey--text' style='text-align:justify;'>
                The energy Sam lives on is <b>brain power</b>, so he doesn't need any of your human currencies.
                Individuals and schools can use <span class='samfont'>Sam's Tricks</span> <b>as much as they want</b>, it won't cost them a dime.
              </v-card-text>
            </v-card>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <div class='section-3' style='position:relative'>
      <div class="custom-shape-divider-top-1629636265">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        </svg>
      </div>
      <div class='overlay-3 pb-5' style='position:relative'>
        <v-container>
          <v-layout row wrap>
            <v-flex xs12 class='samfont-display text-xs-left mb-4 mt-5'>
              Let's try a trick !
            </v-flex>
            <v-flex xs12 style='position:relative'>
              <div class='grey--text headline'>
                This trick is called
              </div>
              <div class='display-1 mb-2'>
                {{ trick.title }}
              </div>
              <div style='position:absolute;right:0px;top:-30px'>
                <v-btn icon @click='newTrick' v-if="$vuetify.breakpoint.name == 'xs'">
                  <v-icon>refresh</v-icon>
                </v-btn>
                <v-btn flat :loading='fetchingTrick' @click='newTrick' v-else>
                  Try another trick
                  <v-icon right>refresh</v-icon>
                </v-btn>
              </div>
            </v-flex>
            <v-flex xs12 style='display:flex;align-items:center;position:sticky;top:0;z-index:2' :style='qnb == 6 ? "visibility:hidden" : ""'>
              <v-progress-linear class='mt-3' v-model="score"></v-progress-linear>
              <v-btn icon @click='timerOn = !timerOn'>
                <v-icon v-if='timerOn'>pause</v-icon>
                <v-icon v-else>play_arrow</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs12 class='my-2'>
              <v-rating class='my-3' v-model="rating" :size='$vuetify.breakpoint.name == "xs" ? 52 : 64' color="orange" background-color="orange lighten-3" readonly></v-rating>
            </v-flex>
            <v-flex xs12 class='mb-5' style='display:flex;justify-content:center;align-items:center;flex-direction:column;'>
              <div style='width:450px;max-width:100%;position:relative' id='questionDisplay'>
                <div style='position:absolute;top:-10px;display:flex' v-if='qnb <= 5'>
                  <span v-for='i in 5' :key='i' :class="i > qnb ? 'pip' : 'fullpip'"/>
                </div>
                <display-classic
                  v-for='(question, index) in questionList' :key='`question-${index}`' name='nextQ'
                  style='position:absolute;width:100%'
                  :question='question.forTex'
                  :questionFormated='question.formated'
                  :options='{}'
                  :active='index + 1 == qnb && questionRunning'
                  :showing='index + 1 == qnb'
                  :forceAnswerDisplay = '[]'
                  v-if='index + 1 == qnb'
                />
                <v-card height='100px' class='px-3' style='position:absolute;width:100%;' v-if='qnb == 6'>
                  <v-layout row wrap style='height:100%'>
                    <v-flex xs4 :class='$vuetify.breakpoint.name == "xs" ? "headline" : "display-2"' style='display:flex;justify-content:center;align-items:center'>
                      <div>
                        {{ rating }}/5
                        <v-icon style='margin-left:-10px' :size='$vuetify.breakpoint.name == "xs" ? "36px" : "54px"' color="orange">star</v-icon>
                      </div>
                    </v-flex>
                    <v-flex xs4 class='samfont' :style='$vuetify.breakpoint.name == "xs" ? "font-size:18px" : "font-size:24px"' style='display:flex;justify-content:center;align-items:center'>
                      {{ trickFeedback[rating] }}
                    </v-flex>
                    <v-flex xs4 style='display:flex;justify-content:center;align-items:center'>
                      <v-img style='margin-left:20px' :src='`/images/Welcome/Sam head ${rating}.png`' height='100px' contain/>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <keypad-classic style='margin-top:130px' welcomePage></keypad-classic>
            </v-flex>
          </v-layout>
        </v-container>
        <div class="custom-shape-divider-bottom-1629680195">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
          </svg>
        </div>
      </div>
    </div>
    <div>
      <footer-lg/>
    </div>
    <transition name='bounce'>
      <v-btn fab dark color="secondary" small fixed bottom right @click='scrollTop' v-if='showScrollTop'>
        <v-icon dark style='display:inline-flex'>
          expand_less
        </v-icon>
      </v-btn>
    </transition>
  </div>
</template>
<script>
  import trick from '../../tricks';

  export default {
    props : [],
    data : function(){
      return {
        cardOneActive: false,
        cardTwoActive: false,
        cardThreeActive: false,
        cardFourActive: false,
        videoDialog: false,
        starSmileCount: 0,
        countingUp: null,
        rating: 0,
        score: 1000,
        internalScore: 1000,
        fetchingTrick: false,
        trick: {},
        options: {},
        questionList: [],
        timePerQ: 0,
        qnb: 0,
        questionRunning: true,
        timerOn: false,
        countdown: null,
        trickFeedback: ['Anyone here ?', 'Keep working !', 'Not bad...', 'Good job !', 'Well done !', 'Awesome !'],
        showScrollTop: false
      }
    },
    created() {
      eventBus.$emit('loading', {command: 'clear'});
      this.getRandomTrick();
      eventBus.$on('submitAnswer', answer => {
        if (this.trick.checkAnswer(this.questionList[this.qnb-1].raw, answer)){
          eventBus.$emit('answerChecked', true);
          this.finishQuestion(true);
        } else {
          eventBus.$emit('answerChecked', false);
        }
      });
      window.addEventListener('scroll', _.throttle(this.toggleCountdownOnScroll, 400))
    },
    destroyed(){
      window.removeEventListener('scroll', _.throttle(this.toggleCountdownOnScroll, 400))
    },
    methods : {
      closeVideoDialog(){
        let iframe = document.querySelectorAll('iframe')[0];
        iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'stopVideo' }), '*');
        this.videoDialog = false;
      },
      activateCardTwo() {
        if(!this.cardTwoActive){
          this.cardTwoActive = true;
          this.countingUp = setInterval(() => {
            this.starSmileCount++;
            if(this.starSmileCount >= 1000){
              clearInterval(this.countingUp);
              this.starSmileCount = 1000;
            }
          }, 50);
        }
      },
      deactivateCardTwo() {
        this.cardTwoActive = false;
        clearInterval(this.countingUp);
        this.starSmileCount = 0;
      },
      getRandomTrick(){
        axios.get('/api/welcome/trick', {})
        .then(response => {
          this.fetchingTrick = true;
          this.options = JSON.parse(response.data.trick.options);
          this.trick = new trick(response.data.trick.title, response.data.trick.image_description, response.data.trick.text_description.split('\n').join('<br>'), response.data.trick.type, JSON.parse(response.data.trick.formula), this.options);
          this.timePerQ = this.options.time + 5;
          this.questionList = this.trick.displayQuestions(5);
          this.fetchingTrick = false;
          setTimeout(this.startQuestion(), 300);
        })
        .catch(err => console.log(err));
      },
      newTrick(){
        this.rating = 0;
        this.qnb = 0;
        this.score = 1000;
        this.getRandomTrick();
      },
      startQuestion(){
        this.qnb++;
        this.score = 1000;
        this.internalScore = 1000;
        this.questionRunning = true;
        this.countdown = setInterval(() => {
          if(this.timerOn){
            this.internalScore -= 100/this.timePerQ;
          }
          if(this.internalScore <= 990){
            this.score = this.internalScore/10;
          }
          if(this.score <= 0 && this.questionRunning){
            this.finishQuestion(false);
            eventBus.$emit('endQuestion', String(this.trick.getAnswer(this.questionList[this.qnb-1].raw)));
          }
        }, 100);
      },
      finishQuestion(success){
        this.questionRunning = false;
        clearInterval(this.countdown);
        if(success){
          this.rating++;
        }
        if(this.qnb < 5){
          setTimeout(this.startQuestion, 1500);
        } else if(this.qnb == 5){
          setTimeout(() => this.qnb++, 1500);
        }
      },
      toggleCountdownOnScroll(){
        if(document.documentElement.scrollTop > 200){
          this.showScrollTop = true;
        } else {
          this.showScrollTop = false;
        }
        let qd = document.querySelector('#questionDisplay').getBoundingClientRect();
        if(qd.top + 50 <= (window.innerHeight || document.documentElement.clientHeight) && qd.top + 50 >= 0){
          this.timerOn = true;
        } else {
          this.timerOn = false;
        }
      },
      scrollTop(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }
</script>

<style scoped>
.section-1 {
  background: rgb(255,255,255);
  background: linear-gradient(15deg, rgba(240,240,255,0.6) 0%, rgba(180,186,227,0.8) 100%);
  /* height: 100vh; */
}

.overlay-1 {
  height:100%;
  background-image: url('/images/Welcome/Sam section 1.png');
  background-size: auto 70%;
  background-position: center bottom;
  height: 100vh;
}

.section-3 {
  background: rgb(255,255,255);
  background: linear-gradient(345deg, rgba(250,250,255,0.3) 0%, rgba(200,200,227,0.6) 100%);
}
.overlay-3 {
  height:100%;
  background-image: url('/images/Welcome/Sam section 3.png');
  background-size: 600px;
  background-position: right 0px bottom 50px;
}

.pip {
  height: 10px;
  width: 10px;
  margin-right: 2px;
  border-radius: 50%;
  background: rgba(0,0,0,0);
  border: 1px solid #1976D2;
}
.fullpip {
  height: 10px;
  width: 10px;
  margin-right: 2px;
  border-radius: 50%;
  background: #1976D2;
  border: 1px solid #1976D2;
}

.slowTransition {
  transition: all 2s;
}

.racing {
  animation: race-sam 2s;
}

@keyframes race-sam {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(100vw);
  }
  52% {
    opacity: 0;
  }
  54% {
    transform: translateX(-100vw);
  }
  56% {
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
  }
}

.flipped {
  transform: rotateY(360deg);
}

.nextQ-item {
  transition: all 1.5s;
  display: inline-block;
  margin-right: 10px;
}
.nextQ-enter, .nextQ-leave-to{
  opacity: 0;
  transform: translateY(45px);
}
.nextQ-leave-active {
  position: absolute;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.custom-shape-divider-bottom-1629635770 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1629635770 svg {
    position: relative;
    display: block;
    width: calc(122% + 1.3px);
    height: 113px;
}

.custom-shape-divider-bottom-1629635770 .shape-fill {
    fill: #FAFAFA;
}

.custom-shape-divider-top-1629636265 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1629636265 svg {
    position: relative;
    display: block;
    width: calc(137% + 1.3px);
    height: 66px;
}

.custom-shape-divider-top-1629636265 .shape-fill {
    fill: #FAFAFA;
}
.custom-shape-divider-bottom-1629680195 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1629680195 svg {
    position: relative;
    display: block;
    width: calc(109% + 1.3px);
    height: 45px;
}

.custom-shape-divider-bottom-1629680195 .shape-fill {
    fill: #00111A;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
}
iframe{
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
}

/* .glow {
  text-shadow: rgba(200,200,227,1) 0px 0px 40px;
} */

</style>
