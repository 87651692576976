<template>
  <span>
    <v-btn flat icon color='success' v-if="open && !loading" @click="openSection(trackId, false)">
      <v-icon>check_circle</v-icon>
    </v-btn>
    <v-btn flat icon color='info' class="spinMe" disabled v-if="open && loading">
      <v-icon>group_work</v-icon>
    </v-btn>
    <v-btn flat icon color='info' class="spinMeR" disabled v-if="!open && loading">
      <v-icon>group_work</v-icon>
    </v-btn>
    <v-btn flat icon color='error' v-if="!open && !loading" @click="openSection(trackId, true)">
      <v-icon>cancel</v-icon>
    </v-btn>
  </span>
</template>
<script>
  export default {
    props : { hikeId: Number, trackId: Number, divisionId: Number, openInit: Boolean, marathonId: Number, editable: Boolean},
    data : function(){
      return {
        open: false,
        loading: false
      }
    },
    mounted() {
      this.open = this.openInit;
    },
    methods: {
      openSection(trackId, value){
        if(this.editable){
          this.loading = true;
          let path = this.marathonId == null ? `/api/hikes/${this.hikeId}/section/${this.trackId}/division/${this.divisionId}` : `/api/hikes/${this.hikeId}/section/${this.trackId}/marathon/${this.marathonId}`;
          axios.patch(path, {value})
          .then(resp => {
            this.loading = false;
            this.open = value;
          })
          .catch(err => console.log(err));
        }
      }
    }
  }
</script>

<style scoped>
  .spinMe {
    animation: spin 1s infinite
  }
  .spinMeR {
    animation: spinR 1s infinite
  }
  @keyframes spin { 100% { transform:rotate(-360deg); } }
  @keyframes spinR { 100% { transform:rotate(360deg); } }
</style>
