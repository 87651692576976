<template>
    <v-dialog :persistent='loading' v-model="dialog" max-width="500px" @keydown.esc="cancelCreateSchool" @keydown.enter.prevent="createSchoolSubmit">
        <v-btn slot="activator" fab dark color="primary" fixed bottom right><v-icon dark
                style='display:inline-flex'>add</v-icon></v-btn>
        <v-card>
            <v-card-title>
                <span class="headline">Create a school</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-form ref="createSchoolForm" v-model="valid" lazy-validation>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Short name" v-model="shortName" :rules="shortNameRules" autofocus clearable validate-on-blur/>
                                <v-text-field label="Full name" v-model="fullName" :rules="fullNameRules" autofocus clearable validate-on-blur />
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat style="width:140px" :disabled='loading' @click="cancelCreateSchool">Cancel</v-btn>
                <v-btn color="primary" style="width:140px" :loading='loading' @click="createSchoolSubmit" :disabled="!valid">Create School</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data: function () {
        return {
            dialog: false,
            shortName: '',
            fullName: '',
            valid: true,
            loading: false,
            shortNameRules: [
                v => !!v || 'You must choose a name',
                v => (v && v.length >= 3) || 'The short name must be at least 3 characters long'
            ],
            fullNameRules: [
                v => !!v || 'You must choose a name',
                v => (v && v.length >= 6) || 'The short name must be at least 6 characters long'
            ]
        }
    },
    methods: {
        createSchoolSubmit() {
            this.loading = true;
            if (this.$refs.createSchoolForm.validate()) {
                let vm = this
                axios.post('./api/schools', {shortName: this.shortName, fullName: this.fullName})
                    .then(resp => {
                        this.loading = false;
                        this.shortName = '';
                        this.fullName = '';
                        this.dialog = false;
                        eventBus.$emit('schoolCreated', resp.data);
                    }).catch(err => console.log(err));
            } else {
                vm.$root.snackError('Invalid data submitted');
            }
        },
        cancelCreateSchool() {
            this.shortName = '';
            this.fullName = '';
            this.loading = false;
            this.$refs.createSchoolForm.reset();
            this.dialog = false;
        }
    }
}
</script>
