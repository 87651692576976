<template>
  <span>{{ displayNb }}</span>
</template>
<script>
  export default {
    props : { text: [String, Number] },
    data(){
      return {
        displayNb: 0
      }
    },
    watch: {
      text: {
        immediate: true,
        handler: function(val) {
          let nbVal = parseInt(val);
          if(nbVal > this.displayNb){
            let interval = setInterval(() => {
              this.displayNb++;
              if(nbVal <= this.displayNb) {
                clearInterval(interval);
                this.displayNb = nbVal;
              }
            }, Math.floor(1000/(nbVal - this.displayNb)));
          }
          if(nbVal < this.displayNb){
            let interval = setInterval(() => {
              this.displayNb--;
              if(nbVal >= this.displayNb){
                clearInterval(interval);
                this.displayNb = nbVal;
              }
            }, Math.floor(1000/(this.displayNb - nbVal)));
          }
        }
      }
    }
  }
</script>
