<template>
  <v-container fluid class='pa-0' style='background:#00111A;color:#74838C;'>
    <v-layout class='px-3 pt-5 title font-weight-bold' row wrap style='display:flex;justify-content:space-between;padding-bottom:75px'>
      <div style='display:flex;flex-direction:column;align-items:flex-start;'>
        <div class='samfont-bigtitle'>Sam's Tricks</div>
        <a class='footerLink my-3' @click='sendAMessage'>Send a message</a>
        <v-dialog v-model='aboutDialog' width='650px'>
          <template v-slot:activator="{ on }">
            <a class='footerLink' v-on="on">About Sam</a>
          </template>
          <v-card class="pa-3">
            <about-sam/>
          </v-card>
        </v-dialog>
      </div>
      <div style='display:flex;flex-direction:column;' :style='$vuetify.breakpoint.name == "xs" ? "align-items:flex-start;margin-top:30px;" : "align-items:flex-end"'>
        <div class='samfont-bigtitle'>Images</div>
        <a class='my-3 footerLink' target="_blank" href="https://inktopiaillustration.wixsite.com/portfolio">Inktopia illustration</a>
        <images-credit-dialog/>
      </div>
    </v-layout>
    <v-layout style='display:flex;justify-content:center;align-items:center;padding-top:50px;padding-bottom:150px'>
      <v-img src="/images/Sam head footer.png" max-width="120px"/>
      <samstricks-display/>
    </v-layout>
    <v-layout style='display:flex;justify-content:center;padding-bottom:50px;'>
      <support-button lg/>
    </v-layout>
    <leave-a-message :displayButton='false'/>
  </v-container>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        creditDialog: false,
        aboutDialog: false
      }
    },
    mounted() {

    },
    methods: {
      sendAMessage(){
        eventBus.$emit('sendMessageToSam', {});
      }
    }
  }
</script>

<style scoped>
  a {
    text-decoration: none;
    font-weight: bold;
    color: #74838C;
  }
  a:hover{
    color: #A7B6BF
  }
</style>
