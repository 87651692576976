<template>
    <v-dialog v-model="dialog" :persistent="mainWindow == 2" no-click-animation width="480">
        <v-window v-model="mainWindow">
            <v-window-item :key="0">
                <buy-a-challenge :tracks="tracks"/>
            </v-window-item>
            <v-window-item :key="1">
                <v-card>
                    <v-slide-y-transition>
                        <v-card width="200" class="pa-1 caption" transition="slide-x-reverse-transition" style="position:absolute;top:55px;right:5px;z-index:5" v-if="hintDisplay">
                            <span v-if="wholeSectionRevealed">
                                You revealed this entire section, well done !<br/> Close this modal and pick another section to reveal next !
                            </span>
                            <span v-else>
                                Taking challenges will reveal more of the image. Each correct answer reveals a pixel. You get a new challenge every few hours and you can also buy challenges with coins (which you earn by trying tricks).
                            </span>
                        </v-card>
                    </v-slide-y-transition>
                    <v-card-title class="headline" style="display:flex;justify-content:space-between;align-items:center;">
                        <span class="grey--text" v-if="wholeSectionRevealed">
                            All done here <v-icon medium>sentiment_satisfied_alt</v-icon>
                        </span>
                        <span v-else>
                            Take a challenge !
                        </span>
                        <v-btn icon @click="hintDisplay = !hintDisplay">
                            <v-icon color="grey">help</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text style="min-height:420px;">
                        <v-layout row wrap>
                            <v-flex xs12 class="caption grey--text">
                                Sections
                            </v-flex>
                            <v-flex xs12 style="display:flex;position:relative;">
                                <v-spacer v-if="$vuetify.breakpoint.name == 'xs'"/>
                                <div style="display:flex;flex-wrap:wrap;position:relative;" :style="`min-width:${pixelSize * 9}px;max-width:${pixelSize * 9}px;`">
                                    <challenge-section-display :section="section" :sectionIndex="sectionIndex" :revealed="revealed" :pixelSize="pixelSize"/>
                                    <div style="position:absolute;top:0px;left:0px;display:flex;flex-wrap:wrap;width:100%;">
                                        <v-card 
                                            flat ripple
                                            :hover="!takingChallenge"
                                            color="transparent"
                                            style="display:flex;justify-content:center;align-items:center;"
                                            :width="pixelSize * 3"
                                            :height="pixelSize * 3"
                                            :style="`${revealed.subsections.indexOf(`${sectionIndex}-${subsectionNb-1}`) == -1 && revealed.sections.indexOf(`${sectionIndex}`) == -1 ? '' : 'visibility:hidden'}`"
                                            v-for="subsectionNb in 9"
                                            :key="subsectionNb"
                                            @click="takeChallenge(subsectionNb-1)"
                                        >
                                            <v-icon :size="pixelSize">
                                                help_outline
                                            </v-icon>
                                        </v-card>
                                    </div>
                                </div>
                                <v-spacer v-if="$vuetify.breakpoint.name != 'xs'"/>
                                <div class="pl-2" :style="`max-width:${390-pixelSize*9}px;`" v-if="$vuetify.breakpoint.name != 'xs'">
                                    <v-img src='/images/nice.png' :width="370 - pixelSize * 9" v-if="wholeSectionRevealed"/>
                                    <v-img src='/images/take a challenge.png' :width="370-pixelSize*9" v-else/>
                                </div>
                                <v-spacer/>
                                <div class="overlay" v-if="challenges.length == 0 && !wholeSectionRevealed">
                                    <v-card>
                                        <v-card-title class="display-1 error--text text-xs-center">
                                            No challenge available.
                                        </v-card-title>
                                        <v-card-text class="subheading">
                                            You used all your challenges. Buy more or wait for some new ones (you get a new challenge every few hours).
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </v-flex>
                            <v-flex xs12 class="caption grey--text mt-3 mb-2" :style="wholeSectionRevealed ? 'visibility:hidden' : ''">
                                Challenge
                            </v-flex>
                            <v-flex xs12 class="mb-1">
                                <div class="samfont-bigtitle revealDiv" v-if="wholeSectionRevealed">
                                    Whole section revealed !
                                </div>
                                <challenge-carousel :challenges="challenges" :key="challenges.length" v-else/>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn :loading='fetchingChallenges' color='primary' round @click='loadChallengesToBuy' v-if="!wholeSectionRevealed">
                            Buy challenges <v-icon class='ml-1'>radio_button_checked</v-icon>
                        </v-btn>
                        <v-spacer/>
                        <v-btn :flat="!wholeSectionRevealed" :color="wholeSectionRevealed ? 'success' : 'error'" :style="$vuetify.breakpoint.name == 'xs' ? '' : 'width:150px'" @click="closeDialog">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-window-item>
            <v-window-item :key="2">
                <take-a-challenge-card :sectionIndex="sectionIndex" :subsectionIndex="subsectionIndex"/>
            </v-window-item>
        </v-window>
    </v-dialog>
</template>
<script>
    import trick from '../../tricks';

    export default {
        props: { section: Array, sectionIndex: Number,  revealed: Object, challenges: Array },
        data: function() {
            return {
                dialog: false,
                pixelSize: 27,
                mainWindow: 1,
                hintDisplay: false,
                activeChallengeIndex: 0,
                subsectionIndex: null,
                tracks: [],
                fetchingChallenges: false,
                takingChallenge: false
            }
        },
        computed:{
            activeChallenge(){
                return this.challenges[this.activeChallengeIndex];
            },
            wholeSectionRevealed(){
                return this.revealed.sections.indexOf(`${this.sectionIndex}`) != -1;
            }
        },
        mounted() {
            eventBus.$on('showChallengeDialog', this.showChallengeDialog);
            eventBus.$on('terminateChallengeShopping', this.terminateChallengeShopping);
            eventBus.$on('exitChallenge', this.exitChallenge);
            eventBus.$on('activeChallengeUpdated', this.activeChallengeUpdated);
        },
        beforeDestroy() {
            eventBus.$off('showChallengeDialog', this.showChallengeDialog);
            eventBus.$off('terminateChallengeShopping', this.terminateChallengeShopping);
            eventBus.$off('exitChallenge', this.exitChallenge);
            eventBus.$off('activeChallengeUpdated', this.activeChallengeUpdated);
        },
        methods: {
            showChallengeDialog(){
                this.mainWindow = 1;
                this.activeChallengeIndex = 0;
                this.takingChallenge = false;
                this.dialog = true;
                // axios.patch(`/api/challenges/584/cancelProgress`, {})
                // .then(resp =>{
                //     console.log(resp.data);
                // }).catch(err => console.log(err.response));
            },
            activeChallengeUpdated(index){
                this.activeChallengeIndex = index ? index : 0;
            },
            loadChallengesToBuy() {
                this.fetchingChallenges = true;
                axios.get('/api/challenges/new', {})
                .then(resp => {
                    this.tracks = resp.data;
                    this.mainWindow = 0;
                    this.fetchingChallenges = false;
                }).catch(err => {
                    this.$root.snackError('Could not find new challenges');
                    this.dialog = false;
                    this.fetchingChallenges = false;
                });
            },
            terminateChallengeShopping(){
                this.tracks = [];
                this.mainWindow = 1;
            },
            takeChallenge(nb){
                if(this.challenges.length == 0){
                    this.$root.snackError('No challenge available');
                } else if(!this.takingChallenge){
                    this.takingChallenge = true;
                    this.subsectionIndex = nb;
                    axios.patch(`/api/challenges/${this.activeChallenge.id}`, { command: 'progress' })
                    .then(resp => {
                        if(resp.data.revealed.sections.includes(`${this.sectionIndex}`) || resp.data.revealed.subsections.includes(`${this.sectionIndex}-${this.subsectionIndex}`)){
                            this.closeDialog();
                            this.takingChallenge = false;
                            eventBus.$emit('canvasUpdate', {revealed: resp.data.revealed});
                            this.$root.snackInfo('Canvas updated, please try again');
                            axios.patch(`/api/challenges/${this.activeChallenge.id}/cancelProgress`, {})
                            .then(resp => {
                                eventBus.$emit('challengesUpdated', false);
                            }).catch(err => console.log(err.response));
                        } else {
                            let tricks = [];
                            let maxTime = 0;
                            resp.data.challenge.tricks.forEach((tk, index) => {
                                let currentTrick = new trick(tk.title, tk.image_description, tk.text_description.split('\n').join('<br>'), tk.type, JSON.parse(tk.formula), JSON.parse(tk.options));
                                currentTrick.question = currentTrick.displayQuestions(1)[0];
                                let optns = { relative: currentTrick.options.relative };
                                if (currentTrick.type == 'power') {
                                    optns.command = currentTrick.formula.command;
                                    optns.base = currentTrick.question.answer.base;
                                }
                                if (currentTrick.type == 'fraction') {
                                    optns.simplify = currentTrick.formula.simplification;
                                }
                                if (currentTrick.type == 'algebra') {
                                    optns.longQuestion = currentTrick.question.raw.length > 10 || currentTrick.question.nbOfSolutions == 2;
                                    optns.command = currentTrick.formula.command;
                                    optns.nbOfSolutions = currentTrick.question.nbOfSolutions ? currentTrick.question.nbOfSolutions : 0;
                                    optns.startingDegree = currentTrick.formula.startingDegree;
                                }
                                currentTrick.displayOptions = optns;
                                currentTrick.solved = resp.data.revealed.pixels.includes(`${this.sectionIndex}-${this.subsectionIndex}-${index}`);
                                tricks.push(currentTrick);
                                maxTime += currentTrick.options.time;
                            });
                            eventBus.$emit('startChallenge', {
                                challenge: {
                                    id: this.activeChallenge.id,
                                    title: this.activeChallenge.track.title,
                                    bonus: this.activeChallenge.options,
                                    nbQuestionsToSolve: resp.data.challenge.nbQuestionsToSolve
                                },
                                tricks,
                                maxTime: Math.floor(3*maxTime/9)});
                            this.mainWindow = 2;
                            eventBus.$emit('challengesUpdated', false);
                            this.takingChallenge = false;
                        }
                    }).catch(err => console.log(err));
                }
            },
            exitChallenge(data){
                this.takingChallenge = false;
                this.subsectionIndex = null;
                this.mainWindow = 1;
                this.activeChallengeIndex = 0;
            },
            closeDialog(){
                this.takingChallenge = false;
                this.dialog = false;
            },
        }
    }
</script>

<style scoped>
    .overlay {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.75);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .revealDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        text-shadow: 2px 2px 3px rgba(58, 50, 98, 0.5);
    }
</style>
