<template>
  <v-container class='mb-5'>
    <v-card>
      <div style='display:flex;justify-content:space-between;align-items:center'>
        <v-btn flat to="/races" class="grey--text" style="width:175px;" :ripple="false">
          <v-icon>chevron_left</v-icon>
          Back to races
        </v-btn>
        <v-spacer/>
        <span style='max-width:350px'>
          <v-select
            solo
            hide-details
            class='ma-2'
            :items="[{text: 'Division races', value: 'divisions'}, {text: 'Marathon races', value: 'marathons'}]"
            v-model='racesType'
            @change='filtersOpen = false'
          />
        </span>
      </div>
      <v-card-title class='py-0 px-5' style='display:flex;justify-content:space-between;align-items:center'>
        <span class='headline'>
          All races
          <v-btn fab small :style='`border-radius:${filtersOpen ? "0%" : "50%"}`' @click='filtersOpen = !filtersOpen'>
            <v-icon medium style='display:inline-flex'>filter_list</v-icon>
          </v-btn>
          <v-card style='position:absolute;z-index:2;margin-top:-5px' class='filters' v-if='filtersOpen && racesType == "divisions"'>
            <v-card-title class='headline' style='display:flex;justify-content:space-between;align-items:center'>
              <span>
                Filter classes
              </span>
              <span>
                <v-btn color='primary' class='mx-0 px-0' small round style='width:80px' @click='filterNoneClasses'>
                  none
                </v-btn>
                <v-btn color='primary' class='mx-0 px-0' small round style='width:80px' @click='filterAllClasses'>
                  all
                </v-btn>
              </span>
            </v-card-title>
            <v-card-text style='display:flex;flex-wrap:wrap' class='pb-0'>
              <v-checkbox
                class='ma-2'
                v-model='selectedDivisions'
                v-for="division in divisions"
                :key='`checkdiv-${division.id}`'
                :label="division.name"
                :value="division.id"
                hide-details
              >
            </v-checkbox>
            </v-card-text>
             <v-card-actions class='mt-2'>
               <v-spacer></v-spacer>
               <v-btn color='primary' flat style='width:120px' @click='filtersOpen = false'>
                 Done
               </v-btn>
             </v-card-actions>
          </v-card>
          <v-card style='position:absolute;z-index:2;margin-top:-5px' class='filters' v-if='filtersOpen && racesType == "marathons"'>
            <v-card-title class='headline' style='display:flex;justify-content:space-between;align-items:center'>
              <span>
                Filter marathons
              </span>
              <span>
                <v-btn color='primary' class='mx-0 px-0' small round style='width:80px' @click='filterNoneMarathons'>
                  none
                </v-btn>
                <v-btn color='primary' class='mx-0 px-0' small round style='width:80px' @click='filterAllMarathons'>
                  all
                </v-btn>
              </span>
            </v-card-title>
            <v-card-text style='display:flex;flex-wrap:wrap' class='pb-0'>
              <v-checkbox
                class='ma-2'
                v-model='selectedMarathons'
                v-for="marathon in marathons"
                :key='`checkdiv-${marathon.id}`'
                :label="marathon.title"
                :value="marathon.id"
                hide-details
              >
            </v-checkbox>
            </v-card-text>
             <v-card-actions class='mt-2'>
               <v-spacer></v-spacer>
               <v-btn color='primary' flat style='width:120px' @click='filtersOpen = false'>
                 Done
               </v-btn>
             </v-card-actions>
          </v-card>
        </span>
        <v-spacer/>
        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
     <v-data-table
       :headers="racesType == 'divisions' ? tableHeaders : tableMarathonHeaders"
       :pagination.sync="pagination"
       :items="racesType == 'divisions' ? races : marathonRaces"
       :rows-per-page-items='[20,50,100]'
       :search="search"
      >
       <template v-slot:no-data>
        Nothing to show here...
      </template>
       <template v-slot:items="props">
         <td>{{ props.item.title }}</td>
         <td>{{ props.item.trackTitle }}</td>
         <td v-if="$root.status == 'teacher' && racesType == 'divisions'">
           <v-chip color='grey lighten-4' v-if='props.item.divisions.length == 0'>
             None
           </v-chip>
           <v-chip color='grey lighten-2' v-if='props.item.divisions.length == 1'>
             {{ props.item.divisions[0].name }}
           </v-chip>
           <v-tooltip top v-if='props.item.divisions.length > 1'>
             <template v-slot:activator="{ on }">
               <v-chip color='grey' v-on="on">
                 {{ props.item.divisions.length }} classes
               </v-chip>
             </template>
            <span class='mx-3' v-for='(division, index) in props.item.divisions' :key='`div-${division.id}`'>{{ division.name }}<br v-if='index>0 && index%5==0'/></span>
           </v-tooltip>
         </td>
         <td v-if="$root.status == 'student'" style='display: flex;justify-content:center;align-items:center;'>
           <medal-dot :reward='props.item.reward' :raceClosed='props.item.status == "closed"' :size='20'/>
         </td>
         <td v-if='racesType == "divisions"'>{{ props.item.term }}</td>
         <td v-if='racesType == "marathons"'>{{ props.item.marathon.marathon.title }}</td>
         <td v-if='racesType == "marathons"'>{{ props.item.marathon.phase.title }}</td>
         <td>{{ props.item.start | timeDisplay }}</td>
         <td>{{ props.item.finish | timeDisplay }}</td>
         <td>
           <v-card label class='white--text' style='display:flex;justify-content:center;align-items:center' :color='statusColor[props.item.status]' width='80px' height='25px'>
             {{ props.item.status }}
           </v-card>
         </td>
         <td style='white-space:nowrap'>
           <v-menu bottom left v-if='props.item.editable'>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <race-perfs-btn :raceId='props.item.id' tile :alwaysReloadRace='props.item.status == "active"'/>
                <v-list-tile @click='editRace(props.item.id)'>
                  <v-list-tile-avatar>
                    <v-icon color="primary">edit</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    Edit race
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click='deleteRace(props.item.id)'>
                    <v-list-tile-avatar>
                      <v-icon color="error">delete_forever</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>
                      Delete race
                    </v-list-tile-title>
                  </v-list-tile>
              </v-list>
            </v-menu>
            <race-perfs-btn :raceId='props.item.id' :alwaysReloadRace='props.item.status == "active"' v-else/>
            <!-- <v-btn icon @click='editRace(props.item.id)' v-if='props.item.editable'>
              <v-icon style='display:inline-flex' color='primary'>edit</v-icon>
            </v-btn> -->
          </td>
       </template>
       <template v-slot:no-results>
          Your search for "{{ search }}" found no results.
       </template>
     </v-data-table>
   </v-card>
   <v-dialog v-model="editDialog" @input="cancelEdit" max-width="400">
      <v-card>
        <v-card-title class="headline">Edit {{ focusedRace.title }}</v-card-title>
        <v-card-text>
          <v-flex xs12>
            <v-form ref="editRaceForm" v-model="valid" lazy-validation>
              <v-text-field label="Title" v-model="focusedRace.title" :rules="titleRules" autofocus validate-on-blur></v-text-field>
              <v-select v-model="focusedRace.term" :items="[1,2,3,4,5,6,7,8,9,10]" label="Term"></v-select>
            </v-form>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" :disabled="loading" flat block @click="cancelEdit">
            Cancel
          </v-btn>
          <v-btn color="success" :loading="loading" block @click="sumbitEdit">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Delete {{ focusedRace.title }}</v-card-title>
        <v-card-text>
          <p>Are you sure you want to delete this race ?</p>
          <p><b><v-icon color="error">warning</v-icon> This action cannot be undone</b></p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :disabled="loading" flat block @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" :loading="loading" block @click="sumbitDelete">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <race-perfs-dialog
      :race='activeRace'
      :options='activeRace.options'
      :runningTime='activeRace.studentProgress.runningTime'
      :thisStudentHistory='activeRace.studentHistory'
      :racePerfsDialog='perfsDialog'
      v-if='activeRace != null'
    /> -->
  </v-container>
</template>
<script>
  import moment from 'moment-timezone';

  export default {
    props : {},
    data : function(){
      return {
        races: [],
        // activeRace: null,
        // perfsDialog: false,
        loading: false,
        marathonRaces: [],
        racesType: 'divisions',
        allRaces: [],
        allMarathonRaces: [],
        divisions: [],
        marathons: [],
        selectedDivisions:[],
        selectedMarathons: [],
        editDialog: false,
        deleteDialog: false,
        valid: true,
        focusedRace: {},
        focusedRaceBackup: {},
        pagination: {
          sortBy: 'finish',
          descending: true
        },
        filtersOpen: false,
        tableHeaders: [],
        tableMarathonHeaders: [],
        search: '',
        statusColor: {
          draft: 'grey lighten-1',
          upcoming: 'green lighten-2',
          active: 'success',
          closed: 'grey darken-1',
        },
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 3) || 'The race title must be at least 3 characters long'
        ],
      }
    },
    filters:{
      timeDisplay(val){
        return moment.tz(val, 'UTC').clone().tz(moment.tz.guess()).format('MMM DD HH:mm');
      }
    },
    created(){
      eventBus.$emit('loading', {command: 'clear'});
      eventBus.$emit('loading', {command: 'start', name: 'allRaces-page'});
      axios.get('/api/allraces', {})
      .then(resp => {
        this.races = typeof resp.data.races == 'object' ? Object.values(resp.data.races) : resp.data.races;
        this.allRaces = typeof resp.data.races == 'object' ? Object.values(resp.data.races) : resp.data.races;
        this.marathonRaces = typeof resp.data.marathonRaces == 'object' ? Object.values(resp.data.marathonRaces) : resp.data.marathonRaces;
        this.allMarathonRaces = typeof resp.data.marathonRaces == 'object' ? Object.values(resp.data.marathonRaces) : resp.data.marathonRaces;
        this.divisions = typeof resp.data.divisions == 'object' ? Object.values(resp.data.divisions) : resp.data.divisions;
        this.marathons = typeof resp.data.marathons == 'object' ? Object.values(resp.data.marathons) : resp.data.marathons;
        this.filterAllClasses();
        this.filterAllMarathons();
        eventBus.$emit('loading', {command: 'finish', name: 'allRaces-page'});
      })
      .catch(err => console.log(err));
      if(this.$root.status == 'teacher'){
        this.tableHeaders = [
          {text: 'Title', align: 'center', sortable: true, value: 'title'},
          {text: 'Track', align: 'center', sortable: true, value: 'trackTitle'},
          {text: 'Classes', align: 'center', sortable: false},
          {text: 'Term', align: 'center', sortable: true, value: 'term'},
          {text: 'Start', align: 'center', sortable: true, value: 'start'},
          {text: 'Finish', align: 'center', sortable: true, value: 'finish'},
          {text: 'Status', align: 'center', sortable: true, value: 'status'},
          {text: 'Actions', align: 'center', sortable: false}
        ];
        this.tableMarathonHeaders = [
          {text: 'Title', align: 'center', sortable: true, value: 'title'},
          {text: 'Track', align: 'center', sortable: true, value: 'trackTitle'},
          {text: 'Marathon', align: 'center', sortable: true, value: 'marathon.marathon.title'},
          {text: 'Phase', align: 'center', sortable: true, value: 'marathon.phase.title'},
          {text: 'Start', align: 'center', sortable: true, value: 'start'},
          {text: 'Finish', align: 'center', sortable: true, value: 'finish'},
          {text: 'Status', align: 'center', sortable: true, value: 'status'},
          {text: 'Actions', align: 'center', sortable: false}
        ];
      } else {
        this.tableHeaders = [
          {text: 'Title', align: 'center', sortable: true, value: 'title'},
          {text: 'Track', align: 'center', sortable: true, value: 'trackTitle'},
          {text: 'Reward', align: 'center', sortable: true, value: 'reward'},
          {text: 'Term', align: 'center', sortable: true, value: 'term'},
          {text: 'Start', align: 'center', sortable: true, value: 'start'},
          {text: 'Finish', align: 'center', sortable: true, value: 'finish'},
          {text: 'Status', align: 'center', sortable: true, value: 'status'},
          {text: 'Actions', align: 'center', sortable: false}
        ];
        this.tableMarathonHeaders = [
          {text: 'Title', align: 'center', sortable: true, value: 'title'},
          {text: 'Track', align: 'center', sortable: true, value: 'trackTitle'},
          {text: 'Reward', align: 'center', sortable: true, value: 'reward'},
          {text: 'Marathon', align: 'center', sortable: true, value: 'marathon.marathon.title'},
          {text: 'Phase', align: 'center', sortable: true, value: 'marathon.phase.title'},
          {text: 'Start', align: 'center', sortable: true, value: 'start'},
          {text: 'Finish', align: 'center', sortable: true, value: 'finish'},
          {text: 'Status', align: 'center', sortable: true, value: 'status'},
          {text: 'Actions', align: 'center', sortable: false}
        ];
      }
      window.addEventListener('scroll', _.throttle(this.hideFilters, 500));
    },
    destroyed(){
      window.removeEventListener('scroll', _.throttle(this.hideFilters, 500));
    },
    watch:{
      selectedDivisions: {
        handler: function(val){
          this.races = [];
          this.allRaces.forEach(race => {
            let raceDivisionIds = race.divisions.map(o => o.id);
            if(raceDivisionIds.filter(id => val.includes(id)).length > 0){
              this.races.push(race);
            }
          });
        }
      },
      selectedMarathons: {
        handler(val){
          this.marathonRaces = this.allMarathonRaces.filter(race => val.includes(race.marathon.marathon.id));
        }
      }
    },
    methods: {
      editRace(id){
        this.focusedRace = _.find(this.races, o => o.id == id);
        this.focusedRaceBackup = {title: this.focusedRace.title, term: this.focusedRace.term};
        this.editDialog = true;
      },
      cancelEdit(){
        this.focusedRace.title = this.focusedRaceBackup.title;
        this.focusedRace.term = this.focusedRaceBackup.term;
        this.editDialog = false;
      },
      sumbitEdit(){
        if(this.$refs.editRaceForm.validate()){
          this.loading = true;
          let vm = this;
          axios.patch(`api/races/fromall/${this.focusedRace.id}`, {
            title: this.focusedRace.title,
            term: this.focusedRace.term
          })
          .then(function (response) {
            vm.editDialog = false;
            vm.$root.snackSuccess('Race updated.');
            vm.loading = false;
          })
          .catch(function (error) {
            vm.$root.snackError('Error ! Your race could not be updated');
            console.log(error);
          });
        } else {
          this.$root.snackError('Invalid data submitted');
        }
      },
      deleteRace(id) {
        this.focusedRace = _.find(this.races, o => o.id == id);
        this.deleteDialog = true;
      },
      sumbitDelete() {
        this.loading = true;
        axios.delete(`api/races/${this.focusedRace.id}`)
        .then(resp => {
          this.$root.snackInfo('Race deleted');
          this.races = this.races.filter(r => r.id != this.focusedRace.id);
          this.allRaces = this.races.filter(r => r.id != this.focusedRace.id);
          this.marathonRaces = this.races.filter(r => r.id != this.focusedRace.id);
          this.allMarathonRaces = this.races.filter(r => r.id != this.focusedRace.id);
          this.loading = false;
          this.deleteDialog = false;
        }).catch(err => err.response ? console.log(err.response) : console.log(err));
      },
      filterNoneClasses(){
        this.selectedDivisions = [];
      },
      filterNoneMarathons(){
        this.selectedMarathons = [];
      },
      filterAllClasses(){
        this.selectedDivisions = [];
        this.divisions.forEach(division => {
          this.selectedDivisions.push(division.id);
        });
      },
      filterAllMarathons(){
        this.selectedMarathons = [];
        this.marathons.forEach(marathon => {
          this.selectedMarathons.push(marathon.id);
        });
      },
      hideFilters(){
        this.filtersOpen = false;
      }
    }
  }
</script>

<style>
.filters {
  transition: all 0.2s
}
</style>
