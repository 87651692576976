<template>
  <v-dialog v-model='handicapDialog' width='750px'>
    <v-card>
      <v-progress-linear height='3' indeterminate style='position:absolute;top:0' class='ma-0' v-if='loading'/>
      <v-card-title class='headline d-block'>
        <div>
          Individual time adjustments
        </div>
        <div class='body-1 grey--text'>
          Based on students' running time in previous races
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <v-card :color='cardColor(student.handicap)' class='pa-1 ma-1' style='min-width:150px' v-for='student in students' :key='student.id'>
            <div class='text-xs-center'>
              {{ student. name }}
            </div>
            <v-edit-dialog :return-value.sync="student.handicap" large lazy>
              <div class='pb-2 subheading font-weight-bold'>{{ student.handicap }}s</div>
              <template v-slot:input>
                <v-text-field v-model="student.handicap" type='number' min='0' max='45' label="Adjustment" single-line counter autofocus/>
              </template>
            </v-edit-dialog>
          </v-card>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn :disabled='loading' color="error" style='width:200px' flat @click="handicapDialog=false">Cancel</v-btn>
        <v-btn :disabled='loading' color="primary" style='width:200px' @click="submitHandicaps">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props : { students: Array, classId: Number },
    data : function(){
      return {
        handicapDialog: false,
        loading: false
      }
    },
    mounted() {
      eventBus.$on('showHandicapDialog', () => this.handicapDialog = true);
    },
    methods: {
      cardColor(h){
        if(h == 0) return 'white';
        if(h >= 20) return 'primary lighten-3';
        if(h >= 10) return 'primary lighten-4';
        if(h >= 5) return 'primary lighten-5';
      },
      submitHandicaps(){
        this.loading = true;
        axios.post(`/api/classes/${this.classId}/classHandicap`, { students: this.students })
        .then(resp => {
          eventBus.$emit('handicapUpdated', resp.data);
          this.loading = false;
          this.handicapDialog = false;
        })
        .catch(err => console.log(err.message))
      }
    }
  }
</script>
