<template>
  <v-container>
    <v-layout row wrap class="mb-5">
      <v-flex xs12>
        <v-text-field label="Search for a title" append-icon="search" v-model="searchedTitle" outline clearable @keyup="userTyping" @click:clear="initTracks"></v-text-field>
      </v-flex>
      <track-panel v-for="track in tracks" :key="track.id" class="mb-2" :dbtrack="track"></track-panel>
      <edit-track-dialog></edit-track-dialog>
      <add-track-fab v-if="$root.status == 'teacher'"></add-track-fab>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    data : function(){
      return {
        tracks : [],
        allTracks : [],
        searchedTitle: '',
        typingTimer: null
      }
    },
    created() {
      eventBus.$emit('loading', {command: 'clear'});
      eventBus.$emit('loading', {command: 'start', name: 'tracks-page'});
      let vm = this;
      axios.get('/api/tracks', {})
      .then(function (response) {
        vm.allTracks = response.data;
        vm.tracks = [...vm.allTracks];
        eventBus.$emit('loading', {command: 'finish', name: 'tracks-page'});
      })
      .catch(function (error) {
        console.log(error); // TODO fix this
      });
      eventBus.$on('trackAdded', (newTrack) => {
        this.searchedTitle = '';
        this.allTracks.unshift(newTrack);
        this.tracks = [...this.allTracks];
      });
      eventBus.$on('trackDeleted', (id) => {
        let tempArray = this.tracks;
        let vm = this;
        this.allTracks = [];
        tempArray.forEach(function(track){
          if(track.id != id){
            vm.allTracks.push(track);
          }
        });
        vm.searchedTitle = '';
        vm.tracks = [...vm.allTracks];
      });
    },
    methods : {
      userTyping(){
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this.searchTracks, 500);
      },
      searchTracks(){
        if(this.searchedTitle == ''){
          this.tracks = [...this.allTracks];
        } else {
          this.tracks = [];
          this.allTracks.forEach(track => {
            if(track.title.toLowerCase().includes(this.searchedTitle.toLowerCase())) this.tracks.push(track);
          });
        }
      },
      initTracks(){
        this.searchedTitle = '';
        this.tracks = [...this.allTracks];
      }
    }
  }
</script>
