<template>
    <v-card style="position:relative;">
        <v-progress-circular
            :rotate="270" :size="50" :width="10"
            :value="clock.timePercentage"
            :color="clockColor"
            style="position:absolute;top:10px;right:10px"
            v-if="challengeActive"
        >
            {{ timeLeft }}
          </v-progress-circular>
        <v-card-title class="headline">
            {{ trackTitle }}
        </v-card-title>
        <v-card-text style="min-height:510px;max-height:510px;position:relative;" class="py-0">
            <v-window touchless vertical style="min-height:510px;max-height:510px;" v-model="challengeWindow">
                <v-window-item :key="0" style="min-height:510px;max-height:510px;">
                    <div class="headline pa-2 mb-2 text-xs-center" :style="challengeActive ? 'success--text font-weight-bold' : 'grey--text'">
                        {{ titleMessage }}
                    </div>
                    <div style="display:flex;justify-content:center;">
                        <challenge-tiles :tricks="tricks" :challengeActive="challengeActive"/>
                    </div>
                    <v-layout row wrap class="mt-3">
                        <v-flex xs6>
                            <div class="caption grey--text">
                                Solve 9 questions
                            </div>
                            <div class="display-1 mt-2 text-xs-center">
                                <v-rating length="9" v-model="nbQsolved">
                                    <template v-slot:item="props">
                                        <v-icon :color="props.index < nbQsolved ? 'success' : 'primary'">
                                            {{ props.index < nbQsolved ? 'check' : 'help_outline' }}
                                        </v-icon>
                                    </template>
                                </v-rating>
                            </div>
                        </v-flex>
                        <v-flex xs6>
                            <div class="caption grey--text">
                                Nb Smiles
                            </div>
                            <div class="display-1 mt-2 text-xs-center primary--text">
                                {{ nbSmiles }} <v-icon x-large color="primary">sentiment_satisfied_alt</v-icon>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-window-item>
                <v-window-item :key="1" style="min-height:510px;max-height:510px;">
                    <challenge-solve-trick :trick="tricks[tileIndex]" :isActive="challengeWindow == 1" v-if="tricks[tileIndex]" :key="`${tileIndex}-${challengeId}`"/>
                </v-window-item>
            </v-window>
            <div class="overlay" v-if="!challengeActive">
                <transition name="bounce">
                    <v-card width="365" v-if="showSummary">
                        <v-card-title class="headline text-xs-center">
                            {{ challengeSummary.title }}
                        </v-card-title>
                        <v-card-text>
                            <div>
                                {{ challengeSummary.message }}
                            </div>
                            <div class="subheading grey--text mt-2">
                                Smiles earned
                            </div>
                            <v-layout row wrap v-if='bonus.varietyBonus > 1 || bonus.difficultyBonus > 1'>
                                <v-flex xs4 class="display-1 text-xs-center secondary--text pt-3" style="display:flex;justify-content:center;align-items:center;">
                                    {{ nbSmiles }} <v-icon size="42" color="secondary">sentiment_satisfied_alt</v-icon>
                                </v-flex>
                                <v-flex xs4>
                                    <v-img width="80%" style="margin:auto;" :src='`/images/Challenge bonus - variety x${bonus.varietyBonus}.png`'  v-if='bonus.varietyBonus > 1'/>
                                </v-flex>
                                <v-flex xs4>
                                    <v-img width="80%" style="margin:auto;" :src='`/images/Challenge bonus - diff x${bonus.difficultyBonus}.png`'  v-if='bonus.difficultyBonus > 1'/>
                                </v-flex>
                            </v-layout>
                            <v-layout
                                class="mt-2"
                                :class="`${challengeSummary.fontColor}--text`"
                                :style="`font-size:${challengeSummary.fontSize}px;`"
                            >
                                <v-flex xs12 style="display:flex;justify-content:center;align-items:center;">
                                    <span v-if='bonus.varietyBonus > 1 || bonus.difficultyBonus > 1'>=</span>
                                    +{{ nbSmiles * bonus.varietyBonus * bonus.difficultyBonus }} 
                                    <v-icon :size="challengeSummary.fontSize+5" :color="challengeSummary.fontColor">{{ challengeSummary.icon }}</v-icon>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn color="primary" @click="exitChallenge">
                                {{ challengeSummary.btnText }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </transition>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-btn flat color="error" @click="confirmExitChallenge" v-if="challengeActive">
                Abort challenge
            </v-btn>
        </v-card-actions>
        <div class="overlay" v-if="blurred">
            <v-card>
                <v-card-title class="headline error--text" style="display:flex;justify-content:space-between;align-items:center;">
                    <span>Focus lost</span>
                    <v-icon large color="error">warning</v-icon>
                </v-card-title>
                <v-card-text>
                    <div>
                        The window has lost focus. The challenge will end in
                    </div>
                    <div class="mt-2 display-3 error--text text-xs-center pa-4">
                        {{ blurCount }}s
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <div class="overlay" v-if="showConfirmAbort">
            <v-card width="420">
                <v-card-title class="headline error--text" style="display:flex;justify-content:space-between;align-items:center;">
                    <span>Are you sure ?</span>
                    <v-icon large color="error">warning</v-icon>
                </v-card-title>
                <v-card-text>
                    You are about to <span class="font-weight-bold error--text">ABORT</span> a challenge. This action cannot be undone.
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="endChallenge">Abort challenge</v-btn>
                    <v-spacer/>
                    <v-btn flat color="primary" @click="showConfirmAbort = false">Keep going !</v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-card>
</template>
<script>
export default {
    props: { sectionIndex: Number, subsectionIndex: Number },
    data: function () {
        return {
            challengeWindow: 0,
            challengeId: null,
            titleMessage: 'Pick a question',
            trackTitle: '',
            tricks: [],
            clock: {},
            clockColor: 'green',
            timeLeft: 0,
            timer: null,
            tileIndex: null,
            bonus: {},
            challengeSummary: {},
            nbQuestionsToSolve: 9,
            blurTimer: null,
            blurCount: 10,
            blurred: false,
            showConfirmAbort: false,
            initialQsolved: 0,
            showSummary: false,
            timeQstarted: 0
        }
    },
    computed: {
        nbQsolved(){
            return this.tricks.reduce((acc, val) => acc + val.solved, 0);
        },
        nbSmiles(){
            return Math.ceil((this.nbQsolved - this.initialQsolved)/2);
        },
        challengeActive(){
            return this.timeLeft > 0;
        }
    },
    mounted() {
        eventBus.$on('startChallenge', this.startChallenge);
        eventBus.$on('challengeTrickPicked', this.challengeTrickPicked);
        eventBus.$on('backToTiles', this.backToTiles);
        eventBus.$on('challengeQuestionSolved', this.updateChallengeQuestions);
        document.addEventListener('keyup', this.handleKeyDown);
        window.addEventListener('blur', this.windowBlurred);
        window.addEventListener('focus', this.windowFocused);
    },
    beforeDestroy() {
        eventBus.$off('startChallenge', this.startChallenge);
        eventBus.$off('challengeTrickPicked', this.challengeTrickPicked);
        eventBus.$off('backToTiles', this.backToTiles);
        eventBus.$off('challengeQuestionSolved', this.updateChallengeQuestions);
        document.removeEventListener('keyup', this.handleKeyDown);
        window.removeEventListener('blur', this.windowBlurred);
        window.removeEventListener('focus', this.windowFocused);
    },
    methods: {
        startChallenge(data){
            this.titleMessage = 'Pick a question';
            this.challengeId = data.challenge.id;
            this.trackTitle = data.challenge.title;
            this.tricks = data.tricks;
            this.bonus = data.challenge.bonus;
            this.nbQuestionsToSolve = data.challenge.nbQuestionsToSolve;
            this.clock.maxTime = data.maxTime;
            this.timeLeft = data.maxTime;
            this.initialQsolved = this.tricks.reduce((acc, val) => acc + val.solved, 0);
            this.clockColor = 'green';
            this.timer = setInterval(() => {
                // if(this.timeQstarted == this.timeLeft){
                //     eventBus.$emit('adjustDisplayFontSize', {});
                // }
                this.timeLeft--;
                this.clock.timePercentage = Math.round(100 * this.timeLeft / this.clock.maxTime);
                if (this.timeLeft / this.clock.maxTime <= 0.5) {
                    this.clockColor = 'blue';
                }
                if (this.timeLeft / this.clock.maxTime <= 0.25) {
                    this.clockColor = 'orange';
                }
                if (this.timeLeft / this.clock.maxTime <= 0.1) {
                    this.clockColor = 'red';
                }
                if (this.timeLeft <= 0) {
                    this.endChallenge();
                }
            }, 1000);
        },
        challengeTrickPicked(index) {
            this.timeQstarted = this.timeLeft;
            this.tileIndex = index;
            this.challengeWindow = 1;
            setTimeout(()=> eventBus.$emit('adjustDisplayFontSize', {}), 300);
        },
        backToTiles(){
            this.challengeWindow = 0;
            this.tileIndex = 0;
            if(this.nbQsolved == this.nbQuestionsToSolve){
                this.endChallenge(true);
            }
        },
        updateChallengeQuestions(){
            this.tricks[this.tileIndex].solved = true;
        },
        endChallenge(finished = false){
            clearInterval(this.timer);
            this.showConfirmAbort = false;
            this.blurred = false;
            this.timeLeft = 0;
            this.challengeWindow = 0;
            const nbOfSmiles = this.nbSmiles;
            this.titleMessage = 'Challenge finished';
            let title = finished ? 'Challenge finished' : this.blurCount == 0 ? 'Where did you go ?' : "Time's up !";
            let message = finished ? 'You solved all the questions ! This section of the image is now revealed !' : this.blurCount == 0 ? 'The window lost focus and the challenge had to be cut short.' : 'The time for this challenge has expired. Each question you have solved will reveal a pixel of the image.';
            let fontSize = 52 + 3*Math.ceil(Math.sqrt(nbOfSmiles));
            let smilesAdded = nbOfSmiles * this.bonus.varietyBonus * this.bonus.difficultyBonus
            let fontColor = smilesAdded == 0 ? 'grey' : smilesAdded <= 5 ? 'black' : smilesAdded <= 20 ? 'primary' : 'success';
            let icon = nbOfSmiles == 0 ? 'sentiment_dissatisfied' : 'sentiment_satisfied_alt';
            let btnText = nbOfSmiles <= 2 ? 'OK' : 'Great !';
            this.challengeSummary = { title, message, fontSize, fontColor, icon, btnText };
            setTimeout(() => this.showSummary = true, 300);
            let pixelsToReveal = this.tricks.map(t => t.solved);
            axios.post(`/api/challenges/${this.challengeId}/performance`, { 
                nbOfSmiles,
                sectionIndex: this.sectionIndex,
                subsectionIndex: this.subsectionIndex,
                pixelsToReveal
            }).then(resp => {
                eventBus.$emit('smileCountUpdate', { newCount: resp.data.smileCount, smilesAdded: resp.data.smilesAdded });
                eventBus.$emit('canvasUpdate', resp.data.canvas);
                if(resp.data.completedSmiles > 0){
                    eventBus.$emit('canvasCompleted', resp.data.completedSmiles);
                }
            }).catch(err => console.log(err));
        },
        confirmExitChallenge(){
            this.showConfirmAbort = true;
        },
        exitChallenge(){
            clearInterval(this.timer);
            this.challengeWindow = 0;
            eventBus.$emit('exitChallenge', null);
        },
        handleKeyDown(e){
            if (e.keyCode == 27) {
                e.preventDefault();
                this.backToTiles();
            }
            if(this.challengeActive && this.challengeWindow == 0 && (e.keyCode >= 97 && e.keyCode <= 105)){ 
                e.preventDefault();
                e.stopPropagation();
                let index = e.keyCode - 97 + 6 * (1 - Math.floor((e.keyCode - 97) / 3));
                if(this.tricks[index].solved){
                    this.$root.snackSuccess('Question already solved');
                } else {
                    this.challengeTrickPicked(index);
                }
            }
            if (this.challengeActive && this.challengeWindow == 0 && (e.keyCode >= 49 && e.keyCode <= 57)) {
                e.preventDefault();
                e.stopPropagation();
                let index = e.keyCode - 49 + 6 * (1 - Math.floor((e.keyCode - 49) / 3));
                if (this.tricks[index].solved) {
                    this.$root.snackSuccess('Question already solved');
                } else {
                    this.challengeTrickPicked(index);
                }

            }
        },
        windowBlurred(){
            if(this.challengeActive){
                this.blurred = true;
                this.blurTimer = setInterval(() => {
                    this.blurCount--;
                    if (this.blurCount == 0){
                        clearInterval(this.blurTimer);
                        this.blurred = false;
                        this.endChallenge();
                    }
                }, 1000);
            }
        },
        windowFocused(){
            if(this.challengeActive){
                this.blurred = false;
                clearInterval(this.blurTimer);
            }
        }
    }
}
</script>

<style scoped>
    .overlay {
        position: absolute;   
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        background: rgba(255, 255, 255, 0.75);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bounce-enter-active {
        animation: bounce-in 0.7s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.7s reverse;
        }
        @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        30% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }
</style>