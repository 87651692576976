<template>
  <div ref='wrapper' style='overflow-x:hidden'>
    <div class='title grey--text' style='display:flex;align-items:center;justify-content:space-between'>
      <v-btn :disabled='startPos' small class='ma-0' icon @click='shift(-1)'>
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <span class='text-truncate'>
        {{ title }}
      </span>
      <v-btn :disabled='finishPos' small class='ma-0' icon @click='shift(1)'>
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </div>
    <div>
      <div style='display:flex;transition:all .5s' :style='`transform:translateX(-${shiftPos*160}px)`'>
        <trick-card :trick="trick" :height="150" :style="`display:inline-block;`" v-for='trick in tricks' :key='trick.id'/>
        <div class='lastCard' v-if='showRefresh'>
          <v-btn fab large flat :loading='loading' @click='reload'>
            <v-icon size='72px' color='grey lighten-2'>refresh</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props : { title: String, tricks: Array, index: Number, showRefresh: {type: Boolean, default: true} },
    data : function(){
      return {
        shiftPos: 0,
        startPos: true,
        finishPos: false,
        loading: false
      }
    },
    mounted() {

    },
    computed: {
      maxShift(){
        return this.tricks.length - Math.floor(this.$refs.wrapper.clientWidth/160) + this.showRefresh;
      }
    },
    methods: {
      shift(dir){
        this.shiftPos = Math.min(this.maxShift, Math.max(0, this.shiftPos + dir));
        this.startPos = this.shiftPos == 0;
        this.finishPos = this.shiftPos == this.maxShift;
      },
      reload(){
        this.loading = true;
        eventBus.$emit('newSet', this.index);
      }
    }
  }
</script>

<style scoped>
.lastCard {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  max-width: 150px;
  height: 150px;
}
</style>
