<template>
  <!-- <div ref='outer'> -->
  <div>
    <v-card class='py-3' :style='`font-size:${fontSize}px`' style='height:120px;display:flex;justify-content:center;align-items:center;'>
      <span></span>
      <v-avatar style='position:absolute;top:5px;left:5px' :color="questionNb >= 20 ? 'red' : 'blue'" v-if='questionNb > 0'>
        <span class="white--text headline font-weight-bold">{{ questionNb % 20 }}</span>
      </v-avatar>
      <div style='position:absolute;right:0px;top:0px;height:100%;display:flex;align-items:center'>
        <v-img :src='statusImgName' width='130px' style='position:absolute;right:0px;top:15px' v-if='questionNb == 0'/>
      </div>
      <transition name="fade">
        <div style='position:absolute;right:0px;top:0px;width:100%;text-align:center;z-index:10;' class='subheading red--text font-weight-bold' v-if="remindSimplification">
          Simplifaction required
        </div>
      </transition>
      <div style='position:absolute;right:0px;top:0px;width:100%;text-align:center' class='subheading grey--text text--lighten-1 font-weight-bold' v-if="requireSimplification">
        Simplifaction required
      </div>
      <div style='position:absolute;right:0px;top:0px;width:100%;text-align:center' class='subheading grey--text text--lighten-2 font-weight-thin' v-else>
        Simplifaction <span class='font-weight-bold'>not</span> required
      </div>
      <!-- <div ref='inner'>
        <span style='white-space:nowrap;' v-html='formatedDisplay'></span>
      </div> -->
      <div ref='outer' class="px-2" style="display:flex;flex-wrap:nowrap;align-items:center;width:100%;">
        <div ref='inner' style='white-space:nowrap;flex:1;overflow-x:visible;display:flex;justify-content:center;align-items:center;'>
          <span style="white-space:nowrap;" v-html='formatedDisplay'/>
        </div>
        <div style="min-width:130px;max-width:130px;"/>
      </div>
    </v-card>
  </div>
</template>
<script>
  import katex from 'katex';

  export default {
    props : { question: String, questionFormated: String, options: Object, active: Boolean, forceAnswerDisplay: [String, Array, Object], questionNb: {type: Number, default: 0} },
    data : function(){
      return {
        orgFontSize: 35,
        fontSize: 35,
        result: ['', ''],
        answerSign: '+',
        requireSimplification: false,
        remindSimplification: false,
        statusImgName: '',
        imgTimer: null,
        instantReplace: [false, false],
        displayingCorrectAnswer: false,
        answerColor: 'black',
        activeIndex: 0,
        resultForceUpdate: 0,
        previousResultLength: 0
      }
    },
    computed: {
      formatedDisplay(){
        this.resultForceUpdate;
        let formatedParts = ['\\color{grey}{...}', '\\color{grey}{...}']
        this.result.forEach((part, index) => {
          if(part != ''){
            formatedParts[index] = part;
          }
          let color = 'black';
          if(this.instantReplace[index] && this.activeIndex == index){
            color = 'darkgrey';
          } else if(this.instantReplace[index]){
            color = 'lightgrey';
          } else if(this.activeIndex == index){
            color = 'blue';
          }
          if(this.displayingCorrectAnswer){
            color = 'green';
          }
          formatedParts[index] = `\\color{${color}}{${formatedParts[index]}}`;
          if(index == this.activeIndex || this.displayingCorrectAnswer){
            formatedParts[index] = `\\large{${formatedParts[index]}}`;
          }
        });
        if(this.answerSign == '+'){
          return katex.renderToString(`${this.question}=\\frac{${formatedParts[0]}}{${formatedParts[1]}}`, {throwOnError: false});
        } else {
          return katex.renderToString(`${this.question}=-\\frac{${formatedParts[0]}}{${formatedParts[1]}}`, {throwOnError: false});
        }
      }
    },
    watch: {
      forceAnswerDisplay: {
        handler: function(val){
          if(Object.values(val).length > 0){
            this.result = [val.n, val.d];
            this.answerSign = val.s > 0 ? '+' : '-';
            this.displayingCorrectAnswer = true;
            this.statusImgName = this.$root.user.images.correct;
          }
        },
        immediate: true
      }
    },
    created() {
      if(Object.values(this.forceAnswerDisplay).length > 0){
        this.statusImgName = this.$root.user.images.correct;
      } else {
        this.statusImgName = this.$root.user.images.pending;
      }
    },
    mounted() {
      this.requireSimplification = this.options.simplify;
      eventBus.$on('resultUpdate', this.resultUpdate);
      eventBus.$on('answerChecked', this.validateResult);
      eventBus.$on('endQuestion', this.displayAnswer);
      eventBus.$on('needSimplification', this.simplificationReminder);
      eventBus.$on('adjustDisplayFontSize', this.adjustFontSize);
      if (window.innerWidth <= 380) {
        this.orgFontSize = 25;
        this.fontSize = 25;
      }
    },
    beforeDestroy(){
      eventBus.$off('resultUpdate', this.resultUpdate);
      eventBus.$off('needSimplification', this.simplificationReminder);
      eventBus.$off('answerChecked', this.validateResult);
      eventBus.$off('endQuestion', this.displayAnswer);
      eventBus.$off('adjustDisplayFontSize', this.adjustFontSize);
    },
    methods: {
      resultUpdate(char){
        this.resultForceUpdate++;
        if(this.active){
          if(this.instantReplace[this.activeIndex] && char != '/' && char != '+/-'){
            this.result[this.activeIndex] = '';
            // this.fontSize = this.originalFontSize;
            this.instantReplace[this.activeIndex] = false;
          }
          if (char == 'backsp'){
            this.result[this.activeIndex] = this.result[this.activeIndex].substring(0,this.result[this.activeIndex].length-1);
            this.adjustFontSize();
          } else if (char == 'clear'){
            this.result[this.activeIndex] = '';
            this.adjustFontSize();
          } else if (char == '/'){
            this.activeIndex = (this.activeIndex + 1)%2;
          } else if (char == 'submit'){
            let submittedAnswer = [...this.result];
            if(this.result[0] == ''){
              submittedAnswer[0] = 0;
            }
            if(this.result[1] == ''){
              submittedAnswer[1] = 1;
            }
            if(this.answerSign == '-'){
              submittedAnswer[0] = -submittedAnswer[0];
            }
            eventBus.$emit('submitAnswer', submittedAnswer);
          } else if(this.result[this.activeIndex].length < 5){
            if (parseInt(char) > 0 || char == '0'){
              this.result[this.activeIndex] += char;
            } else if(char == '+/-'){
              this.answerSign = this.answerSign == '-' ? '+' : '-';
            }
            this.adjustFontSize();
          }
        }
      },
      // adjustFontSize(){
      //   let divWidth = this.$refs.outer.clientWidth;
      //   let textWidth = this.$refs.inner.clientWidth;
      //   if(textWidth > 0.75*divWidth){
      //     this.fontSize = Math.floor(this.fontSize*0.75*divWidth/textWidth);
      //   } else if(textWidth < 0.6*divWidth){
      //     this.fontSize = this.orgFontSize;
      //   }
      // },
      adjustFontSize() {
        let divWidth = this.$refs.outer.clientWidth - 146;
        let textWidth = this.$refs.inner.clientWidth;
        if (textWidth > divWidth) {
          const minFontSize = this.questionNb == 0 ? 16 : 32;
          this.fontSize = Math.max(minFontSize, Math.floor(this.fontSize * 0.9));
        } else if (textWidth <= divWidth && Math.max(this.result[0].length, this.result[1].length) < this.previousResultLength) {
          this.fontSize = Math.min(this.orgFontSize, Math.floor(this.fontSize * 1.1));
        }
        // if (Math.max(this.result[0].length, this.result[1].length) == 0) {
        //   this.fontSize = this.orgFontSize;
        // }
        this.previousResultLength = Math.max(this.result[0].length, this.result[1].length);
      },
      validateResult(check){
        if(this.active){
          clearTimeout(this.imgTimer);
          if(check){
            this.statusImgName = this.$root.user.images.correct;
            this.displayingCorrectAnswer = true;
          } else {
            this.statusImgName = this.$root.user.images.incorrect;
            this.imgTimer = setTimeout(()=>{
              this.statusImgName = this.$root.user.images.pending;
            }, 800);
            this.instantReplace = [true, true];
          }
        }
      },
      simplificationReminder(){
        this.remindSimplification = true;
        setTimeout(() => this.remindSimplification = false, 1000);
      },
      displayAnswer(answer){
        if(this.active){
          this.displayingCorrectAnswer = true;
          this.answerSign = answer.s > 0 ? '+' : '-';
          this.result = [answer.n, answer.d];
        }
      }
    }
  }
</script>

<style scoped>
  .fade-enter-active {
    transition: opacity .2s;
  }
  .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
