<template>
  <v-dialog v-model="historyDialog" fullscreen hide-overlay transition="slide-x-transition" @keydown.esc="closehistoryDialog">
    <v-card>
      <v-toolbar fixed dark color="primary">
        <v-img max-width='60px' style='border-radius:50%;margin:2px;' :src='student.images.avatar' v-if='student.images && !loading'/>
        <v-toolbar-title v-if='!loading'>
          <div v-if='student.properName && $root.status == "teacher"'>
            {{ student.properName.name }}
          </div>
          <div v-else>{{ student.name }}</div>
          <div class='grey--text text--lighten-1 subheading text-truncate'>
            {{ student.email }}
          </div>
        </v-toolbar-title>
        <span v-if='$vuetify.breakpoint.name != "xs" && !loading'>
          <span class='headline font-weight-medium ml-4 mr-2'>
            {{ student.smile_count }}
            <v-icon medium color="white">sentiment_satisfied_alt</v-icon>
          </span>
          <span class='headline font-weight-medium'>
            {{ student.star_count }}
            <v-icon medium color="orange">star</v-icon>
          </span>
        </span>
         <v-spacer></v-spacer>
         <v-btn icon dark @click="closehistoryDialog">
           <v-icon>close</v-icon>
         </v-btn>
      </v-toolbar>
      <v-progress-linear indeterminate class='ma-0' style='position:fixed;top:64px;z-index:10' v-if='loading'></v-progress-linear>

      <v-card-text class='mt-5'>
        <v-layout row wrap class='pa-2'>
          <v-flex xs12 style='display:flex;justify-content:space-between'>
            <span class="headline grey--text text-xs-left">
              {{ historyTitle  }}
            </span>
            <div style='display:flex;'>
              <v-icon large :color='starSmileSwitch ? "grey" : "orange"'>star</v-icon>
              <v-switch class='my-0 ml-2' height='28px' color='primary' v-model="starSmileSwitch"></v-switch>
              <v-icon large :color='starSmileSwitch ? "primary" : "grey"'>sentiment_satisfied_alt</v-icon>
            </div>
          </v-flex>
          <challenge-history-table :perfs='smilesHistory' :loading='historyLoading' :empty='emptyPerfHistory' v-if='starSmileSwitch'></challenge-history-table>
          <perf-history-table :perfs='perfsHistory' :loading='historyLoading' :empty='emptysmilesHistory' v-else></perf-history-table>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import Chart from 'chart.js'

  export default {
    props : {},
    data : function(){
      return {
        student: {},
        historyDialog: false,
        starSmileSwitch: false,
        search: '',
        userId: 0,
        loading: false,
        historyLoading: false,
        perfsHistory: [],
        smilesHistory: [],
        emptyPerfHistory: true,
        emptysmilesHistory: true
      }
    },
    mounted() {
      eventBus.$on('showHistory', this.initData);
    },
    computed: {
      historyTitle(){
        return this.starSmileSwitch ? 'Challenge history' : 'Performance history';
      }
    },
    methods: {
      closehistoryDialog(){
        this.historyDialog = false;
      },
      initData(data){
        this.historyDialog = true;
        this.loading = true;
        this.perfsHistory = [];
        this.smilesHistory = [];
        this.starSmileSwitch = false;
        this.userId = data.userId;
        let vm = this;
        axios.get(`/api/stats/${this.userId}/perflist/init`, {})
        .then(response => {
          console.log(response.data);
          vm.student = response.data.student;
          vm.perfsHistory = response.data.perfList;
          this.emptyPerfHistory = vm.perfsHistory.length > 0;
          vm.smilesHistory = response.data.smileList;
          this.emptysmilesHistory = vm.smilesHistory.length > 0;
          vm.loading = false;
          vm.historyLoading = true;
          axios.get(`/api/stats/${this.userId}/perflist/cont`, {})
          .then(response => {
            response.data.perfList.forEach(perf => vm.perfsHistory.push(perf));
            response.data.smileList.forEach(perf => vm.smilesHistory.push(perf));
            vm.historyLoading = false;
          })
          .catch(error => console.log(error));
        })
        .catch(error => console.log(error));
      }
    }
  }
</script>
