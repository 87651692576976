<template>
    <v-card>
        <v-card-title class="headline">
            Global announcement
        </v-card-title>
        <v-card-text>
            <v-text-field outline v-model="title" label="Title"/>
            <v-textarea outline v-model="message" label="Message"/>
            <div style="display: flex;">
                <v-checkbox v-model="includeButton" label="Action button"/>
                <v-text-field :disabled="!includeButton" v-model="linkUrl" label="URL"/>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer/>
            <v-btn flat color="error" :disabled="loading" width="160px" @click="closeDialog">
                Cancel
            </v-btn>
            <v-btn :loading="loading" width="160px" color="success" @click="sendAnnouncement">
                Send
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props: {},
    data: function () {
        return {
            loading: false,
            title: '',
            message: '',
            includeButton: false,
            linkUrl: '',
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        closeDialog(){
            eventBus.$emit('closeGlobalAnnouncementDialog');
        },
        sendAnnouncement(){
            this.loading = true;
            axios.post('/api/admin/announcement/global', { title: this.title, message: this.message, includeButton: this.includeButton, linkUrl: this.linkUrl })
            .then(resp => {
                console.log(resp.data)
                this.loading = false;
                this.$root.snackSuccess('Announcement saved');
                this.closeDialog();
            }).catch(err => console.log(err.response));
        }
    }
}
</script>
