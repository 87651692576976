<template>
    <v-card width="200px" style="position:relative" class="pa-1">
        <v-progress-linear indeterminate :height="3" class="my-0" style="position:absolute;top:0px;left:0px;width:100%;" v-if="loading"/>
        <div style="display:flex;justify-content:space-between;align-items:center;" class="pb-3">
            <v-btn small dark color="grey lighten-1" @click="publish" v-if="riddle.published_on == null">
                Draft
            </v-btn>
            <v-chip color="success" dark v-else>
                {{ riddle.published_on | formatDateTime }}
            </v-chip>
            <v-icon large color="grey lighten-1">contact_support</v-icon>
        </div>
        <!-- <div class="headline text-xs-center pb-1" style="cursor:pointer;" @click="editFormula">
            {{ riddle.formula }}
        </div> -->
        <div>
            <v-edit-dialog large @save="editFormula">
                <div class="headline text-xs-center pb-1">
                    {{ riddle.formula }}
                </div>
                <template v-slot:input>
                  <v-text-field
                    v-model="formula"
                    :counter="8"
                    label="Edit formula"
                  />
                </template>
              </v-edit-dialog>
        </div>
        <div style="display:flex;">
            <v-spacer/>
            <v-btn icon @click="deleteRiddle">
                <v-icon color="error">delete</v-icon>
            </v-btn>
        </div>
        <div class="overlay" v-if="loading"/>
    </v-card>
</template>
<script>
import moment from 'moment-timezone';

export default {
    props: { riddle: Object },
    data: function () {
        return {
            editDialog: false,
            loading: false,
            formula: ''
        }
    },
    filters: {
        formatDateTime(val) {
            return moment.tz(val, 'UTC').clone().tz(moment.tz.guess()).format('DD - MM - YY');
        }
    },
    mounted() {
        this.formula = this.riddle.formula;
    },
    beforeDestroy() {

    },
    methods: {
        editFormula(){
            if (this.formula.length == 8) {
                this.loading = true;
                axios.patch(`/api/admin/riddles/${this.riddle.id}`, { formula: this.formula })
                .then(resp => {
                    this.loading = false;
                    eventBus.$emit('updateRiddle', resp.data);
                    this.$root.snackSuccess('Riddle updated');
                }).catch(err => {
                    console.log(err);
                    console.log(err.response);
                    this.$root.snackError(err.response.data.message);
                    this.loading = false;
                });
            } else {
                this.$root.snackWarning('The formula has to be exactly 8 characters long');
            }
        },
        publish(){
            this.loading = true;
            axios.post(`/api/admin/riddles/${this.riddle.id}/publish`, {})
            .then(resp => {
                this.loading = false;
                eventBus.$emit('updateRiddle', resp.data);
                this.$root.snackSuccess('Riddle published');
            }).catch(err => console.log(err));
        },
        deleteRiddle(){
            eventBus.$emit('deleteRiddle', this.riddle);
        }
    }
}
</script>
<style scoped>
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.75);
    }
</style>
