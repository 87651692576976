<template>
  <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top>
    {{ snackbar.text }}
    <v-btn dark flat :ripple='false' @click="snackbar.show = false">Close</v-btn>
  </v-snackbar>
</template>
<script>
  export default {
    props : {snackbar: Object},
  }
</script>
