<template>
  <div style='height:100%'>
    <v-dialog v-model="loginDialog"  max-width="400px" @keydown.esc="loginCancel" @keydown.enter="loginSubmit">
      <v-btn round color="primary" slot="activator" style='width:160px'>Login</v-btn>
      <v-card>
        <v-card-title class='pb-0'>
          <span class="headline">Login</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 style='display:flex;justify-content:center'>
              <a href="/login/google">
                <v-img src='/images/Google signin.png' width='300'></v-img>
              </a>
            </v-flex>
            <v-flex xs12 class='text-xs-center subheading font-weight-bold my-2'> - OR - </v-flex>
          </v-layout>
            <v-form ref="loginForm">
              <v-layout row wrap class='px-2'>
                <v-flex xs12>
                  <v-text-field label="Email" v-model="email" :rules="emailRules" :autofocus='true' clearable></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field label="Password" type="password" v-model='password' :rules="passwordRules" clearable></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox v-model="loginRemember" label="Remember me"></v-checkbox> <!-- TODO check it actually remembers...-->
                </v-flex>
              </v-layout>
            </v-form>
          <a href='/password/reset' class="grey--text caption" style='text-decoration:none'>Forgot your password ?</a>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" flat block @click="loginCancel">Cancel</v-btn>
          <v-btn color="primary" block @click="loginSubmit">Log in</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: ['autoshow'],
    data : function(){
      return {
        loginDialog: false,
        loginRemember: false,
        email: '',
        password: '',
        emailRules: [],
        passwordRules: [],
      }
    },
    mounted() {
      if(this.autoshow == 1){
        this.loginDialog = true;
      }
    },
    methods: {
      loginSubmit(){
        this.emailRules = [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid'
        ];
        this.passwordRules = [
          v => !!v || 'You must choose a password',
          v => (v && v.length >= 6) || 'password must be at least 6 characters long'
        ];
        if(this.$refs.loginForm.validate()){
          let vm = this
          axios.post('/login', {
            email: this.email,
            password: this.password,
            remember: this.loginRemember
          })
          .then(function (response) {
            if (response.status == 200){
              window.location.replace('/home');
            }
          })
          .catch(function (error) {
            vm.$root.snackError('Incorrect login or password');
          });
        } else {
          this.$root.snackError('Incomplete credentials');
        }

      },
      loginCancel(){
        this.loginDialog = false;
        this.$refs.loginForm.reset();
      }
    }
  }
</script>
