<template>
  <v-layout row wrap>
    <v-text-field v-model="search" append-icon="search" label="Trick title" clearable></v-text-field>
    <v-data-table
    :headers="smileHeaders"
    :items="formatedPerfs"
    :search='search'
    :rows-per-page-items = "[25, 50, 100]"
    disable-initial-sort
    style='width:100%'
    >
    <template v-slot:items="props">
      <td class='title'>{{ props.item.trackTitle }}</td>
      <td class="text-xs-center">{{ props.item.status }}</td>
      <td class="text-xs-center">{{ props.item.varietyMultiplier | formatMultiplier }}</td>
      <td class="text-xs-center">{{ props.item.diffMultiplier | formatMultiplier  }}</td>
      <td class="text-xs-center title" :class='`${props.item.color}--text`'>
        {{ props.item.result | formatResult }}
        <v-icon :color="props.item.color" v-if='props.item.icon'>{{ props.item.icon }}</v-icon>
      </td>
      <td class="text-xs-center">{{ props.item.setDate | formatDate}}</td>
      <td class="text-xs-center">{{ props.item.resolveDate | formatDate}}</td>
    </template>
    <template slot="no-data">
      <template v-if="empty">
        <v-layout style='justify-content:center' class='headline grey--text'>
          loading...
        </v-layout>
      </template>
      <template v-else>
        <v-layout style='justify-content:center' class='headline grey--text text--lighten-2'>
          No data available <v-icon color='grey lighten-2' class='ml-1' medium>sentiment_dissatisfied</v-icon>
        </v-layout>
      </template>
    </template>
  </v-data-table>
  <v-flex xs12 class='text-xs-right' v-if='loading'>
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-flex>
  </v-layout>
</template>
<script>
  import moment from 'moment-timezone';

  export default {
    props : { perfs: Array, loading: Boolean, empty: Boolean },
    data : function(){
      return {
        search: '',
        smileHeaders: [
          {text: 'Trick title', align: 'center', value: 'trackTitle'},
          {text: 'Status', align: 'center', value: 'status'},
          {text: 'Variety Multiplier', align: 'center', value: 'varietyMultiplier'},
          {text: 'Difficulty Multiplier', align: 'center', value: 'diffMultiplier'},
          {text: 'Smile update', align: 'center', value: 'result'},
          {text: 'Set date', align: 'center', value: 'setDate'},
          {text: 'Resolve date', align: 'center', value: 'resolveDate'}
        ]
      }
    },
    computed: {
      formatedPerfs(){
        let formatedPerfs = [];
        this.perfs.forEach(perf => {
          let varietyMultiplier = 1;
          let diffMultiplier = 1;
          let status, result, icon, color;
          let options = JSON.parse(perf.options);
          if(options){
            varietyMultiplier = options.varietyBonus;
            diffMultiplier = options.difficultyBonus;
          }
          if(isNaN(perf.status)){
            status = perf.status;
            if(status == 'expired'){
              result = -5;
              icon = 'sentiment_very_dissatisfied';
              color = 'error';
            } else {
              result = 0;
              icon = 'sentiment_dissatisfied';
              color = 'grey'
            }
          } else {
            status = 'completed';
            if(perf.status == 0){
              result = 0;
              icon = 'sentiment_dissatisfied';
              color = 'grey';
            } else {
              result = perf.status;
              icon = 'sentiment_satisfied_alt';
              color = 'primary';
            }
          }
          formatedPerfs.push({
            trackTitle: perf.track.title,
            status, result, icon, color, varietyMultiplier, diffMultiplier,
            setDate: perf.created_at,
            resolveDate: status == 'active' ? '' : perf.updated_at
          });
        });

        return formatedPerfs;
      }
    },
    filters:{
      formatDate(date){
        return date == '' ? '-' : moment.tz(date, 'UTC').clone().tz(moment.tz.guess()).format('Do MMMM YYYY hh:mm A');
      },
      formatMultiplier(multiplier){
        return multiplier <= 1 ? '-' : `×${multiplier}`;
      },
      formatResult(result){
        return result > 0 ? `+${result}` : result;
      }
    },
    mounted() {

    },
    methods: {

    }
  }
</script>
