<template>
<v-card class='pa-3'>
  <v-card-title class='pa-0'>
    <v-spacer></v-spacer>
    <v-text-field v-model="search" append-icon="search" label="Lookup word" @keyup='lookupWord' clearable></v-text-field>
  </v-card-title>
  <v-card-text>
    <v-card class='ma-1' v-for='(word, index) in displayWords' :key='`word-${index}`'>
      <v-card-title class='headline font-weight-bold pb-0'>
        <span>
          {{ word.singular }}
        </span>
        <v-spacer></v-spacer>
        <v-icon :color='word.iconColor' v-if='word.icon'>{{ word.icon }}</v-icon>
      </v-card-title>
      <v-card-text class='subheading'>
        {{ word.hint }}
      </v-card-text>
    </v-card>
  </v-card-text>
</v-card>
</template>
<script>
  import glossary from '../../glossary.json';

  export default {
    props : {},
    data : function(){
      return {
        search: '',
        allWords: [],
        displayWords: []
      }
    },
    mounted() {
      // this.initWords();
      this.allWords = [];
      Object.values(glossary).forEach(word => this.allWords.push(word));
      this.allWords.sort(function(a, b){
        if(a.singular < b.singular) { return -1; }
        if(a.singular > b.singular) { return 1; }
        return 0;
      });
      this.lookupWord();
    },
    methods: {
      lookupWord(){
        this.displayWords = [];
        this.allWords.forEach(word => {
          if(word.singular.indexOf(this.search) >= 0){
            this.displayWords.push(word);
          }
        });
      },
      initWords(){

      }
    }
  }
</script>
