<template>
  <div style='overflow-x:visible;'>
    <v-card class='py-3' :style='`font-size:${fontSize}px`' style='height:100px;display:flex;justify-content:center;align-items:center;position:relative;'>
      <v-img :src='statusImgName' min-width='130' max-width='130' style="position:absolute;top:5px;right:0px;" v-if='questionNb == 0'/>
      <span></span>
      <v-avatar style='position:absolute;top:5px;left:5px' :color="questionNb >= 20 ? 'red' : 'blue'" v-if='questionNb > 0'>
        <span class="white--text headline font-weight-bold">{{ questionNb % 20 }}</span>
      </v-avatar>
      <div ref='outer' class="px-2" style="display:flex;flex-wrap:nowrap;align-items:center;width:100%;">
        <div ref='inner' style='white-space:nowrap;flex:1;overflow-x:visible;display:flex;justify-content:center;align-items:center;'>
          <span style="white-space:nowrap;" v-html='formatedDisplay'/>
        </div>
        <div style="min-width:130px;max-width:130px;"/>
      </div>
    </v-card>
  </div>

</template>
<script>
  import katex from 'katex';

  export default {
    props : { question: String, questionFormated: String, options: Object, active: Boolean, forceAnswerDisplay: [String, Array], questionNb: {type: Number, default: 0} },
    data : function(){
      return {
        orgFontSize: 35,
        fontSize: 35,
        result: '',
        previousResultLength: 0,
        statusImgName: '',
        imgTimer: null,
        instantReplace: false,
        displayingCorrectAnswer: false,
        answerColor: 'black',
        uniqueId: 0,
      }
    },
    computed: {
      formatedDisplay(){
        if (this.displayingCorrectAnswer){
          this.answerColor = 'green';
        } else if(this.result == '' || this.instantReplace){
          this.answerColor = 'lightgrey';
        } else {
          this.answerColor = 'black';
        }
        let answer = '...';
        if(this.result != ''){
          let answerArray = this.result.toString().split(".");
          let intPartArray = answerArray[0].toString().split("").reverse();
          let intPartArrayWithSpaces = [];
          intPartArray.forEach(function(item, index){
            intPartArrayWithSpaces.push(item);
            if (index%3 == 2){
              intPartArrayWithSpaces.push("\\,");
            }
          });
          answer = intPartArrayWithSpaces.reverse().join("").trim('\\,');
          if(answerArray[1]){
            let decPartArray = answerArray[1].toString().split("");
            let decPartArrayWithSpaces = [];
            decPartArray.forEach(function(item, index){
              decPartArrayWithSpaces.push(item);
              if (index%3 == 2){
                decPartArrayWithSpaces.push("\\,");
              }
            });
            answer += '.' + decPartArrayWithSpaces.join("").trim('\\,');
          } else if(this.result.indexOf('.') >= 0){
            answer += '.';
          }
        }
        return katex.renderToString(`${this.question}=\\color{${this.answerColor}}{${answer}}`, {throwOnError: false})
      }
    },
    watch: {
      forceAnswerDisplay: {
        handler: function(val){
          if(val.length > 0){
            this.result = val;
            this.displayingCorrectAnswer = true;
            this.statusImgName = this.$root.user.images.correct;
          }
        },
        immediate: true
      }
    },
    created(){
      if(this.forceAnswerDisplay.length > 0){
        this.statusImgName = this.$root.user.images.correct;
      } else {
        this.statusImgName = this.$root.user.images.pending;
      }
    },
    mounted() {
      eventBus.$on('resultUpdate', this.resultUpdate);
      eventBus.$on('answerChecked', this.validateResult);
      eventBus.$on('endQuestion', this.displayAnswer);
      eventBus.$on('adjustDisplayFontSize', this.adjustFontSize);
      if (window.innerWidth <= 380) {
        this.orgFontSize = 25;
        this.fontSize = 25;
      }
    },
    beforeDestroy(){
      eventBus.$off('resultUpdate', this.resultUpdate);
      eventBus.$off('answerChecked', this.validateResult);
      eventBus.$off('endQuestion', this.displayAnswer);
      eventBus.$off('adjustDisplayFontSize', this.adjustFontSize);
    },
    methods: {
      resultUpdate(char){
        if(this.active){
          if(this.instantReplace){
            this.result = '';
            // this.fontSize = this.orgFontSize;
            this.instantReplace = false;
          }
          if (char == 'backsp'){
            this.result = this.result.substring(0,this.result.length-1);
            this.adjustFontSize();
          } else if (char == 'clear'){
            this.result = '';
            this.adjustFontSize();
          } else if (char == 'submit'){
            eventBus.$emit('submitAnswer', this.result);
          } else if(this.result.length < 9){
            if (parseInt(char) > 0 || char == '0'){
              this.result += char;
            } else if ((char == '.') && this.result.indexOf('.') == -1){
              if(this.result == ''){
                this.result = 0;
              }
                this.result += char;
            } else if(char == '+/-'){
              if(this.options.relative){
                this.result = this.result.substr(0, 1) == '-' ? this.result.substr(1) : '-'+this.result;
              }
            }
            this.adjustFontSize();
          }
        }
      },
      adjustFontSize(){
        let divWidth = this.$refs.outer.clientWidth - 146;
        let textWidth = this.$refs.inner.clientWidth;
        if(textWidth > divWidth){
          const minFontSize = this.questionNb == 0 ? 16 : 28;
          this.fontSize = Math.max(minFontSize, Math.floor(this.fontSize * 0.9 * divWidth / textWidth));
        } else if(textWidth <= divWidth && this.result.length < this.previousResultLength){
          this.fontSize = Math.min(this.orgFontSize, Math.floor(this.fontSize * 1.1));
        }
        this.previousResultLength = this.result.length;
      },
      validateResult(check){
        if(this.active){
          clearTimeout(this.imgTimer);
          if(check){
            this.statusImgName = this.$root.user.images.correct;
            this.displayingCorrectAnswer = true;
          } else {
            this.statusImgName = this.$root.user.images.incorrect;
            this.imgTimer = setTimeout(()=>{
              this.statusImgName = this.$root.user.images.pending;
            }, 800);
            this.instantReplace = true;
          }
        }
      },
      displayAnswer(answer){
        if(this.active){
          this.displayingCorrectAnswer = true;
          this.result = answer;
        }
      }
    }
  }
</script>
