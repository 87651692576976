<template>
    <v-container grid-list-md>
        <div style="display:flex;justify-content:flex-end;">
            <v-btn :loading="fetchingCollections" @click="findCollections">
                Find more
            </v-btn>
        </div>
        <collection-admin-display
            v-for="collection in collections"
            :key="collection.title"
            :collection="collection"
        />
    </v-container>
</template>
<script>
export default {
    props: {},
    data: function () {
        return {
            collections: [],
            fetchingCollections: false
        }
    },
    created() {
        eventBus.$emit('loading', { command: 'clear' });
        eventBus.$emit('loading', { command: 'start', name: 'admin-collections-page' });
        this.initCollections()
    },
    mounted() {
        eventBus.$on('collectionApproved', this.approveCollection);
    },
    beforeDestroy() {
        eventBus.$off('collectionApproved', this.approveCollection);
    },
    methods: {
        initCollections() {
            axios.get('/api/collections', {})
            .then(resp => {
                resp.data.forEach(col => {
                    col.approved = true;
                })
                this.collections = resp.data;
                eventBus.$emit('loading', { command: 'finish', name: 'admin-collections-page' });
            }).catch(err => console.log(err));
        },
        findCollections(){
            this.fetchingCollections = true;
            axios.get('/api/findCollections', {})
                .then(resp => {
                    this.collections = this.collections.concat(resp.data);
                    this.fetchingCollections = false;
                }).catch(err => console.log(err));
        },
        approveCollection(title){
            const collection = this.collections.find(c => c.title == title);
            collection.approved = true;
        }
    },
}
</script>
