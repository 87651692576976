<template>
  <v-card class='ma-1 pa-1' hover :height="`${height}px`" min-width="150px" max-width="150px" style="overflow:hidden;" @click="cardClicked">
    <difficulty-display :difficulty='trick.relative_difficulty' size='small'></difficulty-display>
    <div style="position:absolute;top:5px;right:5px;width:30px;height:42px">
      <v-img src="/images/trick logo.png" width="30px" class='trickLogo' ></v-img>
      <div :style="`position:absolute;top:20px;right:15px;box-shadow:0 0 20px 10px ${$root.trickColors[trick.type]}`"></div>
      <div :style='`width:100%;position:absolute;bottom:3px;text-align:center;color:${$root.trickColors[trick.type]};font-family:cursive;font-size:8px`'>{{ trick.type }}</div>
    </div>
    <v-card-title style="width:120px;" class="px-2 py-0 text-xs-center">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div v-on="on" class="subheading font-weight-bold" style="overflow-wrap:break-word;word-wrap:break-word;width:100%;min-height:48px;max-height:48px;overflow-y:hidden">
            {{ trick.title }}
          </div>
        </template>
        <span>
          {{ trick.title }}
        </span>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="text-xs-left pa-1" style="overflow-wrap:break-word;word-wrap:break-word;">
      <v-layout row wrap>
        <v-flex xs8 style="text-overflow: ellipsis;" class='caption' v-html="trick.text_description.split('\n').join('<br>')"></v-flex>
        <v-flex xs4>
          <div style='display:flex;align-items:center;justify-content:center;white-space:nowrap;min-height:84px;max-height:84px;margin-right:-10px;width:50px'>
            <v-progress-circular :size='50' v-model="starCountRatio"  :color='starCountColor' style='margin-left:2px'>
              <span :class='starCount == 15 ? `${starCountColor}--text font-weight-bold` : "grey--text"'>
                {{ starCount }}
              </span>
              <v-icon size='15' :color="starCountColor">{{ starCountIcon }}</v-icon>
            </v-progress-circular>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
    <div class="bottomGradient" style="height:60px" v-if="displayButtons">
      <v-btn flat class="ma-0" icon color="primary"  @click="emitAdd">
        <v-icon dark>add</v-icon>
      </v-btn>
      <v-btn flat class="ma-0" icon color="success" @click="emitFocus">
        <v-icon dark>visibility</v-icon>
      </v-btn>
    </div>
    <div class="bottomGradient" style="height:30px" v-if="!displayButtons"></div>
  </v-card>
</template>

<script>
  export default {
    props : {trick: Object, displayButtons: {type: Boolean, default: false}, height: {type: Number, default: 200}, sectionPerfs: {type: Boolean, default: false}},
    data : function(){
      return {
        starCount: 0,
        starCountColor: "orange",
        starCountIcon: "star"
      }
    },
    computed: {
      starCountRatio(){
        return this.starCount*100/15;
      }
    },
    mounted(){
      if(this.sectionPerfs){
        this.starCount = this.trick.sectionStars;
        this.starCountColor = "green";
        this.starCountIcon = "stars";
      } else {
        this.starCount = 0;
        this.trick.star_count_perfs.forEach(perf => {
          this.starCount += this.$root.scoreToRating(perf.score);
        });
      }
      eventBus.$on('trickUpdated', (newValues) => {
        if(newValues.id == this.trick.id){
          this.trick.title = newValues.title;
          this.trick.text_description = newValues.text_description;
          this.$forceUpdate();
        }
      });
      eventBus.$on('trickFinished', (data) => {
        if(data.id == this.trick.id){
          if(data.starCountUpdate > 0){
            this.trick.star_count_perfs.push({score: data.score});
            if(this.trick.star_count_perfs.length > 3){
              this.trick.star_count_perfs.sort((a,b)=>(a.score > b.score) ? -1 : 1);
              this.trick.star_count_perfs.pop();
            }
            this.starCount += data.starCountUpdate;//this.trick.star_count_perfs.reduce((s, x) => s+this.$root.scoreToRating(x.score), 0);
          }
        }
      });
    },
    methods : {
      cardClicked(){
        if(!this.displayButtons){
          this.emitFocus();
        }
      },
      emitFocus(){
        eventBus.$emit('focusTrick', this.trick);
      },
      emitAdd(){
        eventBus.$emit('addTrick', this.trick);
      }
    }
  }
</script>

<style scoped>
  .bottomGradient{
    position:absolute;
    bottom:0;
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:flex-end;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0.5), rgba(255,255,255,0.75), rgba(255,255,255,0.95));
  }
</style>
