<template>
    <v-dialog width="450" v-model="editDialog">
        <v-card>
            <v-card-title class="headline">
                Edit School
            </v-card-title>
            <v-card-text>
                <v-text-field label="Short name" v-model="editedSchool.short_name" :rules="shortNameRules" autofocus clearable validate-on-blur />
                <v-text-field label="Full name" v-model="editedSchool.full_name" :rules="fullNameRules" autofocus clearable validate-on-blur />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat style="width:140px" :disabled='editSchoolLoading'
                    @click="editDialog=false">Cancel</v-btn>
                <v-btn color="primary" style="width:140px" :loading='editSchoolLoading' @click="submitEditSchool">Update
                    School</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {},
    data: function () {
        return {
            editedSchool: {},
            editDialog: false,
            editSchoolLoading: false,
            shortNameRules: [
                v => !!v || 'You must choose a name',
                v => (v && v.length >= 3) || 'The short name must be at least 3 characters long'
            ],
            fullNameRules: [
                v => !!v || 'You must choose a name',
                v => (v && v.length >= 6) || 'The short name must be at least 6 characters long'
            ]
        }
    },
    mounted() {
        eventBus.$on('showEditSchoolDialog', this.initEdit);
    },
    befroeDestroy() {
        eventBus.$off('showEditSchoolDialog', this.initEdit);
    },
    methods: {
        initEdit(school){
            this.editedSchool = school;
            this.editDialog = true;
        },
        submitEditSchool() {
            this.editSchoolLoading = true;
            axios.patch(`/api/schools/${this.editedSchool.id}`, { shortName: this.editedSchool.short_name, fullName: this.editedSchool.full_name })
                .then(resp => {
                    eventBus.$emit('updateSchool', resp.data);
                    this.editSchoolLoading = false;
                    this.editDialog = false;
                }).catch(err => console.log(err));
        },
    }
}
</script>
