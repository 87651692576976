<template>
  <v-card width='100%' max-width='400px' class="pa-2">
    <v-layout row wrap>
      <v-layout row wrap fill-height>
        <v-flex xs3 v-for="(padrow, index) in padrows" :key="`row-${index}`">
          <v-layout column fill-height>
            <v-flex v-for="(padkey, index) in padrow" :key="`key-${index}`" style="height:80px">
              <v-card ripple hover height="100%" width="100%" class="display-1 text-xs-center" @click="updateResult(padkey, false)">
                <div style='display:flex;justify-content:center;align-items:center;height:100%' v-if="padkey.icon">
                  <v-icon x-large color='black'>{{ padkey.icon }}</v-icon>
                </div>
                <div style='display:flex;justify-content:center;align-items:center;height:100%' v-else>
                  {{ padkey.digit }}
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column fill-height>
            <v-flex style="height:80px">
              <v-card ripple hover height="100%" width="100%" class="display-1 text-xs-center"  @click="updateResult({digit: 'backsp'}, false)">
                <div style='display:flex;justify-content:center;align-items:center;height:100%'>
                  <v-icon>backspace</v-icon>
                </div>
              </v-card>
            </v-flex>
            <v-flex style="height:80px">
              <v-card ripple hover height="100%" width="100%" class="display-1 text-xs-center" @click="updateResult({digit: 'clear'}, false)">
                <div style='display:flex;justify-content:center;align-items:center;height:100%'>
                  <v-icon>cancel</v-icon>
                </div>
              </v-card>
            </v-flex>
            <v-flex style="height:160px">
              <v-card ripple hover height="100%" width="100%" class="display-1 text-xs-center" @click="updateResult({digit: 'submit'}, false)"><br>
                  <v-icon>keyboard_return</v-icon>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-card>
</template>

<script>
  var basicCodes = [
    {code : 32, char : '/'},
    {code : 37, char: '/'},
    {code : 38, char: '/'},
    {code : 39, char : '/'},
    {code : 40, char: '/'},
    {code : 81, char : '/'},
    {code : 65, char : '/'},
    {code : 191, char : '/'},
    {code : 109, char : '+/-'},
    {code : 107, char : '+/-'},
    {code : 189, char : '+/-'},
    {code : 187, char : '+/-'},
    {code : 8, char : 'backsp'},
    {code : 110, char : 'backsp'},
    {code : 106, char : '/'},
    {code : 111, char : '/'},
    {code : 13, char : 'submit'}
  ]
  for (var i=0; i<10 ; i++){
    basicCodes.push({code : 96+i, char : i});
    basicCodes.push({code : 48+i, char : i});
  }

export default{
  data: function(){
    return {
      result : '',
      keycodes : basicCodes,
      padrows: [
        [{digit: '7'},{digit: '4'},{digit: '1'},{digit: '0'}],
        [{digit: '8'},{digit: '5'},{digit: '2'},{digit: '/', icon: 'loop'}],
        [{digit: '9'},{digit: '6'},{digit: '3'},{digit: '+/-'}]],
    }
  },
  methods : {
    updateResult(event, fromKeyboard = true){
      if(fromKeyboard){
        event.preventDefault();
        if(_.find(this.keycodes, {code: event.keyCode})) {
          let pressedKeyCode = _.find(this.keycodes, {code: event.keyCode})
          eventBus.$emit('resultUpdate', pressedKeyCode.char);
        }
      } else {
        eventBus.$emit('resultUpdate', event.digit);
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.updateResult);
  },
  beforeDestroy(){
    window.removeEventListener('keydown', this.updateResult);
  }
}
</script>
