<template>
  <v-card style='width:100%' class='ma-1' hover @mouseenter='showDetails' @mouseleave='hideDetails' @click='toggleDetails'>
    <v-card-title class='px-3 pt-2 pb-1'>
      <span style='max-width:275px'>
        <div class='title py-2'>
          {{ phase.title }}
        </div>
        <div class='grey--text'>
          {{ phase.description }}
        </div>
      </span>
      <v-window
        v-model='mainWindow'
        style='position:absolute;right:0px;top:0px;width:290px;max-height:56px;'
        class='py-1 px-2'
      >
        <v-window-item :key='0'>
          <div style='display:flex'>
            <v-spacer/>
            <v-chip label color='success' dark v-if='phase.active'>Active</v-chip>
            <span class='grey--text pa-2' v-else>
              {{ phase.races.length }} race<span v-if='phase.races.length > 1'>s</span>
            </span>
          </div>
        </v-window-item>
        <v-window-item :key='1' style='background:white'>
          <v-btn small round color='error' @click='activation(false)' v-if='phase.active'>
            Deactivate
          </v-btn>
          <v-btn small round color='success' class='ml-3' :disabled='!activable' @click='activation(true)' v-else>
            Activate
          </v-btn>
          <v-btn class='ma-0' :disabled='phase.order == 1' icon flat @click='move("up")'>
            <v-icon small>arrow_upward</v-icon>
          </v-btn>
          <v-btn class='ma-0' :disabled='last' icon flat @click='move("down")'>
            <v-icon small>arrow_downward</v-icon>
          </v-btn>
          <v-btn class='ma-0' icon flat @click='editDialog=true'>
            <v-icon color="primary">edit</v-icon>
          </v-btn>
          <v-btn class='ma-0' icon flat @click='prepareDeletePhase'>
            <v-icon :color="phase.races.length == 0 ? 'error' : 'grey'">delete_forever</v-icon>
          </v-btn>
        </v-window-item>
      </v-window>
    </v-card-title>
    <v-card-text class='pa-0 slowTransition' style='overflow-y:hidden' :style='phase.active || hovered ? "max-height:500px;" : "max-height:0px;"'>
      <div class='pb-3'>
        <div v-if='phase.rules.system == "points"'>
          <div class='title grey--text px-3' style='display:flex;justify-content:flex-start;align-items:center;'>
            <span>
              Points phase
            </span>
            <v-btn class='mx-1' icon flat color='grey' @click.stop='showRules = !showRules'>
              <v-icon style='display:inline-flex'>help</v-icon>
            </v-btn>
          </div>
          <div :style='showRules ? "height:155px;box-shadow: inset 0 0 10px #ccc" : "height:0px"' class='smoothTransition' style='overflow-y:hidden;'>
            <div class='px-3 py-1'>
              <div class='mb-1'>
                Hike stars <v-icon small color='green'>stars</v-icon> : {{ phase.rules.hikeStars }} pt<span v-if='phase.rules.hikeStars > 1'>s</span>
              </div>
              <div class='mb-1'>
                Race stars <v-icon small color='orange'>star</v-icon> : {{ phase.rules.raceStars }} pt<span v-if='phase.rules.raceStars > 1'>s</span>
              </div>
              <div class='mb-1'>
                Race finished <v-icon small>directions_run</v-icon> : {{ phase.rules.raceFinished }} pt<span v-if='phase.rules.raceFinished > 1'>s</span>
              </div>
              <div class='mb-1'>
                Bronze medal <v-icon small :color='this.$root.medalColors.bronze'>radio_button_checked</v-icon> : {{ phase.rules.bronze }} pt<span v-if='phase.rules.bronze > 1'>s</span>
              </div>
              <div class='mb-1'>
                Silver medal <v-icon small :color='this.$root.medalColors.silver'>radio_button_checked</v-icon> : {{ phase.rules.silver }} pt<span v-if='phase.rules.silver > 1'>s</span>
              </div>
              <div>
                Gold medal <v-icon small :color='this.$root.medalColors.gold'>radio_button_checked</v-icon> : {{ phase.rules.gold }} pt<span v-if='phase.rules.gold > 1'>s</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class='title grey--text px-3' style='display:flex;justify-content:flex-start;align-items:center;'>
            <span>
              Time phase
            </span>
            <v-btn class='mx-1' icon flat color='grey' @click.stop='showRules = !showRules'>
              <v-icon style='display:inline-flex'>help</v-icon>
            </v-btn>
          </div>
          <div :style='showRules ? "height:70px;box-shadow: inset 0 0 10px #ccc" : "height:0px"' class='smoothTransition' style='overflow-y:hidden;'>
            <div class='px-3 py-1'>
              Points are attributed for each race finished based on the running time (the faster the student finishes the race, the more points she/he gets).
            </div>
          </div>
        </div>
        <div class='px-3 mt-2' style='display:flex;justify-content:flex-start;align-items:center;'>
          <span class='title grey--text'>
            Races
          </span>
          <v-btn icon dark small color='primary' @click='addRace' v-if='editable'>
            <v-icon small dark style='display:inline-flex'>add</v-icon>
          </v-btn>
        </div>
        <v-data-table
          class='px-2'
          :headers="racesHeaders"
          hide-actions hide-headers
          :rows-per-page-items='[-1]'
          :items="phase.races"
          no-data-text='No race in this phase yet'
          >
         <template v-slot:items="props">
           <td class="text-xs-left px-1">{{ props.item.title }}</td>
           <td class="text-xs-center px-1">{{ props.item.status }}</td>
           <td class="text-xs-center px-1" style='display:flex;justify-content:center;align-items:center' v-if='$root.status == "student"'>
             <medal-dot size='20' :reward='props.item.reward' :raceClosed='props.item.status == "closed"'/>
           </td>
           <td class="text-xs-center px-1" style='width:70px;white-space:nowrap'>
             <v-menu bottom left offset-x v-if='editable && phase.active'>
               <template v-slot:activator="{ on }">
                 <v-btn icon v-on="on">
                   <v-icon>more_vert</v-icon>
                 </v-btn>
               </template>
               <v-list>
                 <v-list-tile @click="seeRaceDetails(props.item)">
                   <v-list-tile-avatar><v-icon color="primary">subject</v-icon></v-list-tile-avatar>
                   <v-list-tile-title>See details</v-list-tile-title>
                 </v-list-tile>
                 <race-perfs-btn :raceId='props.item.id' tile :alwaysReloadRace='true'/>
                 <v-list-tile @click="editRace(props.item)">
                   <v-list-tile-avatar><v-icon color="primary">edit</v-icon></v-list-tile-avatar>
                   <v-list-tile-title>Edit race</v-list-tile-title>
                 </v-list-tile>
                 <v-list-tile @click="prepareUnlockRace(props.item)" v-if='props.item.status == "upcoming"'>
                   <v-list-tile-avatar><v-icon color="info">lock</v-icon></v-list-tile-avatar>
                   <v-list-tile-title>Unlock race</v-list-tile-title>
                 </v-list-tile>
                 <v-list-tile @click="prepareLockRace(props.item)" v-if='props.item.status == "draft"'>
                   <v-list-tile-avatar><v-icon color="warning">lock</v-icon></v-list-tile-avatar>
                   <v-list-tile-title>Lock race</v-list-tile-title>
                 </v-list-tile>
                 <v-list-tile @click="prepareDeleteRace(props.item)">
                   <v-list-tile-avatar><v-icon color="error">delete_forever</v-icon></v-list-tile-avatar>
                   <v-list-tile-title>Delete race</v-list-tile-title>
                 </v-list-tile>
               </v-list>
             </v-menu>
             <v-menu bottom left offset-x v-else>
               <template v-slot:activator="{ on }">
                 <v-btn icon v-on="on" @click.stop>
                   <v-icon>more_vert</v-icon>
                 </v-btn>
               </template>
               <v-list>
                 <v-list-tile @click="seeRaceDetails(props.item)">
                   <v-list-tile-avatar><v-icon color="primary">subject</v-icon></v-list-tile-avatar>
                   <v-list-tile-title>See details</v-list-tile-title>
                 </v-list-tile>
                 <race-perfs-btn :raceId='props.item.id' tile :alwaysReloadRace='true'/>
               </v-list>
             </v-menu>
           </td>
           <edit-locked-race-dialog :raceId='props.item.id' :classes='[]' :availablePhases='availablePhases'/>
         </template>
       </v-data-table>
      </div>
      <div style='display:flex'>
        <v-spacer/>
        <v-btn :ripple='false' round flat to='/races'>
          Go to races page
          <v-icon style='display:inline-flex' class='ml-2'>directions_run</v-icon>
          <v-icon style='display:inline-flex' >chevron_right</v-icon>
        </v-btn>
      </div>
    </v-card-text>
    <div style='position:absolute;top:0px;left:0px;width:100%;height:100%;background:rgba(255,255,255,0.6)' v-if='loading'/>
    <v-dialog width='400px' v-model='editDialog'>
      <v-card>
        <v-card-title>
          Edit phase {{ phase.title }}
        </v-card-title>
        <v-card-text>
          <v-text-field label="Title" v-model="phase.title" :rules="titleRules" autofocus clearable validate-on-blur/>
          <v-text-field label="Description (optional)" v-model="phase.description" clearable/>
          <v-select :items = 'systemItems' label="System" v-model='phase.rules.system'/>
          <v-container class='pa-0' grid-list-lg v-if='phase.rules.system == "points"'>
            <v-layout row wrap>
              <v-flex xs4>
                <v-text-field label="Hike stars" v-model="phase.rules.hikeStars" type="number" min="0"/>
              </v-flex>
              <v-flex xs4>
                <v-text-field label="Race stars" v-model="phase.rules.raceStars" type="number" min="0"/>
              </v-flex>
              <v-flex xs4>
                <v-text-field label="Race finished" v-model="phase.rules.raceFinished" type="number" min="0"/>
              </v-flex>
              <v-flex xs4>
                <v-text-field label="Bronze medal" v-model="phase.rules.bronze" type="number" min="0"/>
              </v-flex>
              <v-flex xs4>
                <v-text-field label="Silver medal" v-model="phase.rules.silver" type="number" min="0"/>
              </v-flex>
              <v-flex xs4>
                <v-text-field label="Gold medal" v-model="phase.rules.gold" type="number" min="0"/>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="error" :disabled='loading' style="width:140px" flat @click="editDialog=false">Cancel</v-btn>
          <v-btn color="primary" :loading='loading' style="width:140px"  @click="submitChanges">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width='450px'>
      <v-card>
        <v-card-title class='headline'>
          Delete {{ phase.title }}
        </v-card-title>
        <v-card-text>
          <div>
            Are you sure you want to delete this phase ?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" :disabled='loading' style="width:140px" flat @click="deleteDialog=false">Cancel</v-btn>
          <v-btn color="error" :loading='loading' style="width:140px"  @click="deletePhase">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteRaceDialog" width="340">
      <v-card>
        <v-card-title class="headline">Delete {{ deleteRaceTitle }}</v-card-title>
        <v-card-text>Are you sure you want to delete this race ?<br>This will also delete all students performances.</v-card-text>
        <v-card-actions>
          <v-btn color="primary" flat block @click="deleteRaceDialog=false">
            No
          </v-btn>
          <v-btn color="error" block @click="deleteThisRace">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="lockDialog" width="320">
      <v-card>
        <v-card-title class="headline">Lock {{ lockRace.title }}</v-card-title>
        <v-card-text>Are you sure you want to lock this race ?<br>You won't be able to edit some of the details once the race is locked.</v-card-text>
        <v-card-actions>
          <v-btn color="error" flat block @click="lockDialog=false">
            No
          </v-btn>
          <v-btn color="success" block @click="lockThisRace(true)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="unlockDialog" width="320">
      <v-card>
        <v-card-title class="headline">Unlock {{ lockRace.title }}</v-card-title>
        <v-card-text>Are you sure you want to unlock this race ?<br>Students won't be able to participate in this race until it is locked.</v-card-text>
        <v-card-actions>
          <v-btn color="error" flat block @click="unlockDialog=false">
            No
          </v-btn>
          <v-btn color="success" block @click="lockThisRace(false)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="raceDetailsDialog" width="350" v-if='selectedRace.options'>
      <v-card>
        <v-card :color='selectedRace.statusDisplay.bgColor' class='white--text font-weight-bold px-2 py-1' style='position:absolute;top:5px;right:5px;'>
          {{ selectedRace.statusDisplay.text }}
        </v-card>
        <v-card-title class="headline">{{ selectedRace.title }}</v-card-title>
        <v-card-text class='py-0'>
          <div class='grey--text title'>
            Track
          </div>
          <div class='subheading'>
            {{ selectedRace.track.title }}
          </div>
          <div class='grey--text title mt-2'>
            Rules
          </div>
          <race-rules :options='selectedRace.options' :race='selectedRace'/>
          <div class='caption text-xs-center mt-2 text-italic'>
            from <b>{{ selectedRace.start | formatDate}}</b> to <b>{{ selectedRace.finish | formatDate }}</b>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="error" flat @click="raceDetailsDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
  import moment from 'moment-timezone';

  export default {
    props : { phase: Object, editable: Boolean, loading: Boolean, last: Boolean, activable: Boolean, availablePhases: Array },
    data : function(){
      return {
        mainWindow: 0,
        showRules: false,
        editDialog: false,
        deleteDialog: false,
        lockDialog: false,
        unlockDialog: false,
        lockRace: {},
        hovered: false,
        deleteRaceDialog: false,
        deleteRaceTitle: '',
        deleteRaceTitle: 0,
        raceDetailsDialog: false,
        selectedRace: {track: {}},
        systemItems: [
          {text: 'Points phase', value: 'points'},
          {text: 'Time phase', value: 'raceTime'},
        ],
        racesHeaders: [
          {text: 'Title', align: 'center'},
          {text: 'Status', align: 'center'},
          {text: 'Perf', align: 'center', value: ''}
        ],
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 5) || 'The title must be at least 5 characters long'
        ],
      }
    },
    mounted(){
      eventBus.$on('raceAdded', this.raceAdded);
      eventBus.$on('raceUpdated', this.raceEdited);
    },
    beforeDestroy(){
      eventBus.$off('raceAdded', this.raceAdded);
      eventBus.$off('raceUpdated', this.raceEdited);
    },
    filters:{
      formatDate(date){
        return moment.tz(date, 'UTC').clone().tz(moment.tz.guess()).format('Do MMM HH:mm');
      },
    },
    watch: {
      loading(val){
        if(!val){
          this.editDialog = false;
        }
      }
    },
    methods: {
      raceAdded(race){
        if(race.phase_id == this.phase.id){
          race.status = 'draft';
          this.phase.races.unshift(race);
        }
      },
      raceRemoved(id){
        this.phase.races = this.phase.races.filter(phaseRace => id != phaseRace.id);
      },
      raceEdited(race){
        this.phase.races = this.phase.races.map(phaseRace => race.id == phaseRace.id ? race : phaseRace);
        this.phase.races.forEach(race => {
          if(race.phase_id != this.phase.id){
            eventBus.$emit('raceAdded', race);
            this.raceRemoved(race.id);
          }
        })
      },
      seeRaceDetails(race){
        this.selectedRace = race;
        this.selectedRace.options = typeof this.selectedRace.options == 'object' ? this.selectedRace.options : JSON.parse(this.selectedRace.options);
        let statusDisplay;
        switch(this.selectedRace.status){
          case 'draft':
            statusDisplay = {text: 'Draft', bgColor: 'grey lighten-2'};
          break;
          case 'upcoming':
            statusDisplay = {text: 'Upcoming', bgColor: 'green lighten-3'};
          break;
          case 'active':
            statusDisplay = {text: 'Open', bgColor: 'green'};
          break;
          case 'closed':
            statusDisplay = {text: 'Closed', bgColor: 'grey darken-1'};
          break;
        }
        this.selectedRace.statusDisplay = statusDisplay;
        this.raceDetailsDialog = true;
      },
      toggleDetails(){
        this.hovered = !this.hovered;
        if(this.editable && this.mainWindow == 0){
          this.mainWindow = 1;
        } else {
          this.mainWindow = 0;
        }
      },
      showDetails(){
        if(this.editable && this.phase.active){
          this.mainWindow = 1;
        }
      },
      hideDetails(){
        if(this.phase.active){
          this.mainWindow = 0;
        }
      },
      activation(val){
        eventBus.$emit('phaseActivation', {id: this.phase.id, activation: val});
      },
      move(direction){
        eventBus.$emit('phaseMove', {id: this.phase.id, direction});
      },
      addRace(){
        eventBus.$emit('newPhaseRace', this.phase);
      },
      prepareLockRace(race){
        this.lockRace = race;
        this.lockDialog = true;
      },
      prepareUnlockRace(race){
        this.lockRace = race;
        this.unlockDialog = true;
      },
      lockThisRace(val){
        let vm = this;
        axios.post(`/api/races/${this.lockRace.id}/lock`, {value: val})
        .then(function(response){
          if(val){
            vm.$root.snackSuccess(`${vm.lockRace.title} has been locked !`);
          } else {
            vm.$root.snackSuccess(`${vm.lockRace.title} has been unlocked !`);
          }
          vm.lockDialog = false;
          vm.unlockDialog = false;
          vm.lockRace.status = response.data;
        })
        .catch(function(error){
          console.log(error); // TODO fix this
          if(error.response.data == 'Finish is past'){
            vm.$root.snackError('The finish date can\'t be in the past');
            vm.lockDialog = false;
          }
        });
      },
      prepareDeletePhase(){
        if(this.phase.races.length > 0){
          this.$root.snackError("You cannot delete a phase which has races");
        } else {
          this.deleteDialog = true;
        }
      },
      deletePhase(){
        eventBus.$emit('deletePhase', this.phase.id);
      },
      prepareDeleteRace(race){
        this.deleteRaceTitle = race.title;
        this.deleteRaceId = race.id;
        this.deleteRaceDialog = true;
      },
      deleteThisRace(){
        axios.delete(`/api/races/${this.deleteRaceId}`, {})
        .then(resp => {
          this.raceRemoved(resp.data);
          this.$root.snackSuccess(`${this.deleteRaceTitle} has been deleted`);
          this.deleteRaceDialog = false;
          this.deleteRaceTitle = '';
          this.deleteRaceId = 0;
        })
        .catch(function(error){
          console.log(error); // TODO fix this
        });
      },
      editRace(race){
        if(race.locked){
          eventBus.$emit('editLockedRace', race);
        } else {
          eventBus.$emit('editRace', race);
        }
      },
      submitChanges(){
        eventBus.$emit('phaseEdit', {id: this.phase.id, title: this.phase.title, description: this.phase.description, rules: this.phase.rules});
        this.editLoading = true;
      },
      seeRacePerfs(id){
        axios.get(`/api/races/${id}`, {})
        .then(resp => {
          eventBus.$emit('seeRacePerfs', resp.data);
        })
        .catch(err => console.log(err));
      }
      // seeRaceDetails(race){
      //   console.log(race);
      // }
    }
  }
</script>

<style>
  .smoothTransition {
    transition: all .3s;
  }
  .slowTransition {
    transition: all .8s;
  }
</style>
