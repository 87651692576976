<template>
  <v-dialog v-model="dialog"  fullscreen hide-overlay transition="slide-x-transition" @keydown.esc="dialog = false">
    <v-card class="px-2" style='max-height:100vh;overflow-y:hidden;display:flex;flex-direction:column;'>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Students verification</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear class='my-0' indeterminate style='position:absolute;top:64px;' v-if='loading'/>
      <div class='pa-3' style="display:flex;">
        <v-select hide-details outline :items='timeSpans' label='Timespan' v-model='timeSpan'/>
        <v-dialog v-model="searchDialog" width="650">
          <template v-slot:activator="{ on }">
            <v-btn dark color="primary" fab v-on="on">
              <v-icon dark style="display:inline-flex">search</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-2">
            <div style="display:flex;">
              <div class="pr-2" style="flex:1;">
                <v-text-field
                  hide-details
                  outline
                  append-icon="search"
                  label="Search name"
                  v-model="searchedName"
                  :loading="searchLoading"
                  @keyup="searchTyping"
                />
              </div>
              <div >
                <v-btn dark color="primary" :loading="loadingPaste" style="margin-top:3px;" fab @click="pasteNames">
                  <v-icon dark style="display:inline-flex">content_paste</v-icon>
                </v-btn>
              </div>
            </div>
            <div>
              <div class="caption grey--text mt-2">
                Verified students
              </div>
              <div class="mb-2">
                <v-chip label close v-for="student in searchedStudents" :key="student.id" @input="removeFromSearched(student.id)">
                  {{ student.name }}
                </v-chip>
              </div>
            </div>
            <v-divider v-if="notFound.length"/>
            <div class="caption mt-2 grey--text" v-if="notFound.length">
              Names not found
            </div>
            <div style="display:flex;flex-wrap:wrap;">
              <v-chip disabled v-for="(name, index) in notFound" :key="index">{{ name }}</v-chip>
            </div>
            <v-card-actions>
              <v-spacer/>
              <v-btn :disabled='searchLoading || loadingPaste' flat color="error" style='width:150px' @click="dialog = false">
                Cancel
              </v-btn>
              <v-btn :disabled='searchLoading || loadingPaste' color="primary" style='width:150px' @click="addSearchedStudents">
                Add students
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
        <div class='classesWrapper pa-2' ref='classesWrapper'>
          <div style='display:flex;justify-content:center;transition: all .3s ease' :style='`transform:translateX(${-250*shift}px)`'>
            <div class='mx-3' style='width:250px;' v-for='(division, index) in this.classes' :key='division.id'>
              <div style='display:flex;justify-content:center'>
                <v-card flat class='subheading pa-2' style='display:flex;justify-content:center;align-items:center' width='200px' v-if='division.id == 0'>
                  Individual students
                </v-card>
                <v-card
                  v-else
                  hover
                  class='subheading pa-2'
                  style='display:flex;justify-content:center;align-items:center'
                  v-ripple
                  width='200px'
                  @click='toggleClassSelection(division)'
                >
                <span class='text-truncate'>
                  {{ division.name }}
                </span>
                <v-icon color='success' right v-if='classesSelection.all[index]'>done_outline</v-icon>
                <v-icon color='primary' right v-else-if='classesSelection.some[index]'>done</v-icon>
                <v-icon color='grey' right v-else>dangerous</v-icon>
              </v-card>
            </div>
            <v-checkbox
            v-for='student in division.students'
            :key='student.id'
            v-model="student.selected"
            hide-details
            :label="student.name"
            class='mx-1 mt-1 text-truncate'
            />
          </div>
          </div>
          <div style='position:absolute;top:0px;left:0px;height:100%;display:flex;align-items:center' @click='shiftDown' v-if='shift > 0'>
            <v-btn icon large>
              <v-icon>chevron_left</v-icon>
            </v-btn>
          </div><div style='position:absolute;top:0px;right:0px;height:100%;display:flex;align-items:center' @click='shiftUp' v-if='shift < maxShift'>
            <v-btn icon large>
              <v-icon>chevron_right</v-icon>
            </v-btn>
          </div>
        </div>
      <div style='display:flex;justify-content:space-between' class='py-2'>
        <v-btn :disabled='loading' color="error" style='width:150px' @click="stopVerifying">
          Stop verifying
        </v-btn>
        <span>
          <v-btn :disabled='loading' flat color="error" style='width:150px' @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn :disabled='loading' color="primary" style='width:150px' @click="submit">
            Submit
          </v-btn>
        </span>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        dialog: false,
        searchDialog: false,
        timeSpans: [{text: "10 minutes", value: 10},{text: "15 minutes", value: 15}, {text: "30 minutes", value: 30}, {text: "60 minutes", value: 60}],
        timeSpan: 30,
        shift: 0,
        classes: [],
        selectedStudents: [],
        loading:false,
        maxShift: 0,
        searchedName: '',
        tabs: 'classes',
        typingTimer: null,
        searchLoading: false,
        searchedStudents: [],
        loadingPaste: false,
        notFound: []
      }
    },
    mounted() {
      eventBus.$on('openVerifyingDialog', this.init);
    },
    computed: {
      classesSelection(){
        let all = [];
        let some = []
        this.classes.forEach(cl => {
          all.push(cl.students.every(st => st.selected));
          some.push(cl.students.some(st => st.selected));
        });
        return {all, some};
      }
    },
    methods: {
      init(){
        this.dialog = true;
        this.loading = true;
        axios.get(`/api/verify/check`, {})
        .then(resp => {
          let perfVerification = resp.data.classes;
          eventBus.$emit('updateVerifying', resp.data);
          this.classes = [];
          this.$root.classesData.divisions.forEach(cl => {
            let students = cl.students.map((st) => ({id: st.id, name: st.name, selected: perfVerification[cl.id] != undefined && perfVerification[cl.id].indexOf(st.id) >= 0}));
            this.classes.push({id: cl.id, name: cl.name, students});
          });
          if(perfVerification['0']){
            let students = perfVerification['0'].map((st) => ({id: st.id, name: st.name, selected: true}));
            this.classes.push({id: 0, name: 'Individual students', students});
          } else {
            this.classes.push({ id: 0, name: 'Individual students', students: [] });
          }
          this.maxShift = this.classes.length - Math.floor(this.$refs.classesWrapper.clientWidth/250) + 2;
          this.loading = false;
        }).catch(err => console.log(err));
      },
      searchTyping(){
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this.searchInSchool, 500)
      },
      searchInSchool(){
        if(this.searchedName.length > 2){
          this.searchLoading = true;
          axios.post('/api/schools/searchStudent', { name: this.searchedName})
          .then(resp => {
            if(resp.data.length == 0){
              this.$root.snackInfo('No student found');
            }
            resp.data.forEach(st => {
              if(!this.searchedStudents.some(stu => stu.id == st.id)){
                this.searchedStudents.push(st);
              }
            });
            this.searchLoading = false;
          }).catch(err => console.log(err));
        }
      },
      pasteNames(){
        this.notFound = [];
        this.loadingPaste = true;
        navigator.clipboard.readText()
        .then(text => {
          const rows = text.replace(/\s/g, '*-+>>').split('*-+>>*-+>>').map(s => s.split('*-+>>').join(' '));
          const names = [];
          if (rows.length > 0) {
            rows.forEach(name => {
              if (name != '') {
                names.push(name);
              }
            });
          } else {
            this.$root.snackError('Failed to read clipboard contents');
          }
          console.log(names);
          if(names.length > 0) {
            axios.post('/api/schools/searchStudent/multiple', { names })
            .then(resp => {
              console.log(resp.data);
              this.notFound = resp.data.notFound;
              resp.data.students.forEach(st => {
                if (!this.searchedStudents.some(stu => stu.id == st.id)) {
                  this.searchedStudents.push(st);
                }
              });
            }).catch(err => console.log(err));
          } else {
            this.$root.snackError('No names found');
          }
          this.loadingPaste = false;
        })
        .catch(err => {
          this.$root.snackError('Failed to read clipboard contents');
          console.log(err);
        });
      },
      removeFromSearched(id){
        this.searchedStudents = this.searchedStudents.filter(st => st.id != id);
      },
      addSearchedStudents(){
        const outOfClassesStudents = [];
        this.searchedStudents.forEach(st => {
          let outOfClass = true;
          this.classes.forEach(cl => {
            cl.students.forEach(clst => {
              if(clst.id == st.id){
                clst.selected = true;
                outOfClass = false;
              }
            });
          });
          if(outOfClass){
            outOfClassesStudents.push(st);
          }
        })
        let students = outOfClassesStudents.map(st => ({ id: st.id, name: st.name, selected: true }));
        const indivStudentsDivision = this.classes.find(cl => cl.id == 0);
        if(indivStudentsDivision){
          indivStudentsDivision.students = indivStudentsDivision.students.concat(students);
        } else {
          this.classes.push({ id: 0, name: 'Individual students', students });
        }
        this.searchedStudents = [];
        this.searchedName = '';
        this.searchDialog = false;
      },
      toggleClassSelection(cl){
        if(cl.students.every(st => st.selected)){
          cl.students.forEach(st => st.selected = false);
        } else {
          cl.students.forEach(st => st.selected = true);
        }
      },
      shiftDown(){
        this.shift = Math.max(0, this.shift-1);
      },
      shiftUp(){
        this.shift = Math.min(this.maxShift, this.shift+1);
      },
      submit(){
        this.notFound = [];
        this.loading = true;
        let divisions = [];
        let students = [];
        this.classes.forEach(cl => {
          if(cl.students.some(st => st.selected)){
            divisions.push(cl.id);
          }
          cl.students.forEach(st => {
            if(st.selected && students.indexOf(st.id) == -1){
              students.push(st.id);
            }
          })
        });
        this.searchedStudents = [];
        axios.post(`/api/verify`, {divisions, students, time: this.timeSpan})
        .then(resp => {
          eventBus.$emit('updateVerifying', resp.data);
          this.loading = false;
          this.selectClasses = null;
        }).catch(err => console.log(err));
      },
      stopVerifying(){
        this.loading = true;
        axios.delete(`/api/verify`, {})
        .then(resp => {
          eventBus.$emit('updateVerifying', {classes: [], code: null, expiry: 0});
          this.loading = false;
          this.dialog = false;
        }).catch(err => console.log(err));
      }
    }
  }
</script>

<style scoped>
  .classesWrapper {
    display:flex;
    max-width: 100vw;
    overflow-x:hidden;
    overflow-y:auto;
    flex: 1;
    position: relative;
  }
</style>
