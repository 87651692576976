<template>
  <v-card class='mb-4' hover width='100%'>
    <v-progress-linear :indeterminate="true" style='position:absolute;top:-15px;left:0px' height='4' v-if='loading'></v-progress-linear>
    <v-btn icon style='position:absolute;top:0px;right:0px' @click='refreshHike'>
      <v-icon>refresh</v-icon>
    </v-btn>
    <v-card-title class='py-1'>
      <v-layout row wrap>
        <v-flex xs6 class='headline' style='display:flex;align-items:center;justify-content:flex-start'>
          {{ hike.title }}
        </v-flex>
        <v-flex xs2 class='headline' style='display:flex;align-items:center;justify-content:center;white-space:nowrap'>
          <lgnb-display :nb='nbStars' :class='loading ? "grey--text text--lighten-2" : ""'/>
          <v-icon medium color="green">stars</v-icon>
        </v-flex>
        <v-flex xs4 class='headline' style='display:flex;align-items:center;justify-content:center'>
          <v-chip v-if='hike.marathon_id == null'>Term {{ hike.term }}</v-chip>
          <v-chip label dark color='black' v-else>Marathon hike</v-chip>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text class='py-0'>
      <v-data-table no-data-text='This hike is empty, edit to add tracks' :loading='updatingHike' hide-actions :headers="tableHeaders" :items="tableItems">
        <template v-slot:items="props">
          <td class='subheading' :class='loading ? "grey--text text--lighten-2" : ""'>{{ props.item.title }}</td>
          <td style='white-space:nowrap' :class='loading ? "grey--text text--lighten-2" : ""'>{{ props.item.sectionStars }}<v-icon small color="green">stars</v-icon></td>
          <td :class='loading ? "grey--text text--lighten-2" : ""'>+{{ props.item.timeModifier }}s</td>
          <td class='px-0' v-for="division in props.item.divisions" :key='`division-${division.id}`'>
            <division-toggle
              :hikeId='hike.id'
              :trackId='props.item.id'
              :divisionId='division.id'
              :openInit='division.open'
              :marathonId='hike.marathon_id'
              :editable='true'
            />
          </td>
        </template>
      </v-data-table>
      <v-layout style='display:flex;justify-content:flex-end'>
        <v-btn color="success" fab small dark  @click="viewPerfs" :ripple="false">
          <v-icon style='display:inline-flex'>visibility</v-icon>
        </v-btn>
        <v-btn color="primary" fab small dark  @click="editThisHike" :ripple="false" v-if="hike.editable">
          <v-icon style='display:inline-flex'>edit</v-icon>
        </v-btn>
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    props : { hike: Object },
    data : function(){
      return {
        open: false,
        updatingHike: false,
        rotation: 0,
        loading: false
      }
    },
    computed: {
      nbStars(){
        return this.hike.tracks.reduce((total, track) => total + track.sectionStars, 0)
      },
      tableHeaders(){
        const tableHeaders = [
          { text: "Tracks", align: "center", class: 'pa-1', sortable: false },
          { text: "Section stars", align: "center", class: 'pa-1', sortable: false },
          { text: "Extra time", align: "center", class: 'pa-1', sortable: false }
        ];
        if (this.hike.marathon_id == null) {
          this.hike.divisions.forEach(division => {
            tableHeaders.push({ text: division.name, align: "center", class: 'pa-1 text-truncate', width: '65px', sortable: false, id: division.id });
          });
        } else {
          tableHeaders.push({ text: 'Open', align: "center", class: 'pa-1 text-truncate', width: '65px', sortable: false, id: 0 });
        }
        return tableHeaders;
      },
      tableItems(){
        const tableItems = [];
        this.hike.tracks.forEach(track => {
          track.divisions = [];
          if (this.hike.marathon_id == null) {
            this.hike.divisions.forEach(division => {
              track.divisions.push({ id: division.id, open: track.openClasses && track.openClasses.includes(division.id), loading: false });
            });
          } else {
            track.divisions = [{
              id: 0,
              open: track.openClasses ? track.openClasses.length > 0 : false,
              loading: false
            }]
          }
          tableItems.push(track);
        });
        return tableItems;
      }
    },
    mounted() {
      this.refreshHike();
    },
    methods: {
      editThisHike(){
        eventBus.$emit('editHike', this.hike);
      },
      viewPerfs(){
        eventBus.$emit('seeHikePerfs', this.hike.id);
      },
      refreshHike() {
        this.loading = true;
        axios.get(`/api/hikes/${this.hike.id}`, {})
          .then(resp => {
            eventBus.$emit('hikeUpdated', resp.data);
            this.loading = false;
          }).catch(err => console.log(err));
      }
    }
  }
</script>
