<template>
    <v-dialog fullscreen hide-overlay v-model="showDialog">
        <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Edit {{ riddle.data.title }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon dark @click="closeDialog">
                    <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-container grid-list-lg>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-text-field outline v-model="riddle.data.title" label="Title"/>
                        <v-textarea outline v-model="riddle.data.body" label="Body"/>
                        <div style="display:flex;justify-content:space-between;align-items:center;">
                            <span class="caption grey--text">
                                Variables
                            </span>
                            <v-btn icon @click="addVariable">
                                <v-icon small>add</v-icon>
                            </v-btn>
                        </div>
                        <div>
                            <v-layout row wrap v-for="(variable, index) in riddle.data.variables" :key='`variable-${index}`'>
                                <v-flex xs12 sm1 class="display-1 font-weight-bold font-italic">
                                {{ variable.name }}
                                </v-flex>
                                <v-flex xs12 sm3 class="text-xs-center">
                                <v-select :items="variableItems" v-model="variable.type"/>
                                </v-flex>
                                <v-flex xs12 sm7>
                                    <v-text-field
                                        v-if='variable.type == "enum"'
                                        label="Values (separate by a comma ',')"
                                        v-model="variable.values"
                                        @input="userTyping"
                                        @keyup='formatEnumValues(variable.name)'
                                    />
                                    <v-text-field
                                        v-if='variable.type == "depend"'
                                        label="Formula (using previous variables)"
                                        v-model="variable.formula"
                                        @input="userTyping"
                                    />
                                    <v-layout v-if='variable.type == "grad"' row wrap>
                                        <v-flex xs3>
                                            <v-text-field 
                                                label="min"
                                                v-model="variable.min"
                                                type="number"
                                                :min="-1000"
                                                :max="1000"
                                                @input="userTyping"
                                            />
                                        </v-flex>
                                        <v-flex xs3>
                                            <v-text-field
                                                label="max"
                                                v-model="variable.max"
                                                type="number"
                                                :min="-1000"
                                                :max="1000"
                                                @input="userTyping"
                                            />
                                        </v-flex>
                                        <v-flex xs3>
                                            <v-text-field
                                                label="step"
                                                v-model="variable.step"
                                                type="number"
                                                :min="0.01"
                                                :max="100"
                                                :step="0.01"
                                                @input="userTyping"
                                            />
                                        </v-flex>
                                        <v-flex xs3>
                                        <v-text-field label="exclude" v-model="variable.exclude" @input="userTyping"/>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-flex>
                    <v-flex xs12 sm6>
                        <riddle-display :riddle="riddle"/>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-spacer/>
                    <v-btn color="error" :disabled='loading' style='width:160px' flat @click="closeDialog">Cancel</v-btn>
                    <v-btn color="success" :loading='loading' style='width:160px' @click="save">Save</v-btn>
                </v-layout>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: { riddle: Object},
    data: function () {
        return {
            loading: false,
            show: false,
            riddle: {},
            variableItems: [
                { value: 'grad', text: 'Gradual' }, { value: 'enum', text: 'Enumarate' }, { value: 'depend', text: 'Dependant' }
            ],
            typingTimer: null,
        }
    },
    mounted() {
        eventBus.$on('editRiddle', this.editRiddle);
    },
    beforeDestroy() {
        eventBus.$off('editRiddle', this.editRiddle);
    },
    methods: {
        editRiddle(riddle){
            this.riddle = {...riddle};
        },
        closeDialog(){
            eventBus.$emit('closeEditRiddleDialog', {});
        },
        save(){
            this.loading = true;
            axios.patch(`/api/admin/riddles/${this.riddle.id}`, { title: this.riddle.data.title, body: this.riddle.data.body })
            .then(resp => {
                console.log(resp.data);
                eventBus.$emit('updateRiddle', resp.data);
                this.loading = false;
                this.closeDialog();
                this.$root.snackSuccess('Riddle edited');
            }).catch(err => console.log(err.response));
        },
        emitFormula() {
            // let newFormula = { formulas: _.map(this.formulas, 'raw'), variables: this.variables };
            // eventBus.$emit('formulaUpdated', newFormula);
        },
        userTyping() {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(this.emitFormula, 500);
        },
        formatEnumValues(name) {
            this.riddle.data.variables.forEach(variable => {
                if (variable.name == name) {
                    variable.values = variable.values.replace(/[^\d,.]/g, '');
                }
            });
        },
        addVariable() {
            if (this.variables.length < 20) {
                let nextChar = String.fromCharCode(this.variables[this.variables.length - 1].name.charCodeAt(0) + 1);
                this.variables.push({ name: nextChar, min: 0, max: 10, step: 1, type: 'grad', values: '' });
                this.emitFormula();
            }
        },
        removeVariable() {
            if (this.variables.length > 1) {
                this.variables.pop();
                this.emitFormula();
            }
        },
    }
}
</script>
