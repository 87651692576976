<template>
  <v-layout row wrap>
    <v-flex xs12 class='mb-4' style='display:flex;justify-content:center'>
      <v-layout row wrap style='max-width:1350px'>
        <v-flex xs12 class='text-xs-left'>
          <v-btn flat :to="backRoute" class="grey--text mt-2" style="width:215px;" :ripple="false">
            <v-icon>chevron_left</v-icon>
            Back to class page
          </v-btn>
        </v-flex>
        <v-flex xs12 class='headline pb-3'>
          Students proper school names
        </v-flex>
        <v-flex xs12 md8>
          <v-card class='mx-2'>
            <v-card-title class="pb-0">
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="search" label="Find student" clearable></v-text-field>
            </v-card-title>
            <v-data-table class='ml-3' :headers="studentsHeaders" :items="students" hide-actions :search="search" :key='forceRefresh'>
              <template v-slot:items="props">
                <td>
                  <div>
                    {{ props.item.name }}
                  </div>
                  <div class='caption grey--text'>
                    {{ props.item.email }}
                  </div>
                </td>
                <td>
                  {{ props.item.properName.name }}
                </td>
                <td>
                  <v-btn fab small color='success' @click='confirmLinkName(props.item.id)' v-if='focusedName != null'>
                    <v-icon dark style='display:inline-flex'>link</v-icon>
                  </v-btn>
                  <v-btn icon small @click='cancelCopyName(props.item.id)' v-else-if='props.item.nameCopied'>
                    <v-icon color='error'>undo</v-icon>
                  </v-btn>
                  <v-btn icon small @click='copyName(props.item.id)' v-else>
                    <v-icon color='success'>arrow_right_alt</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-edit-dialog :return-value.sync="props.item.properName.newName" large lazy  @save="changedName(props.item.id)">
                    <div>{{ props.item.properName.newName }}</div>
                    <!-- <template v-slot:input>
                      <div class="mt-3 title">Update proper name</div>
                    </template> -->
                    <template v-slot:input>
                      <v-text-field v-model="props.item.properName.newName" :rules="[max50chars]" label="Edit" single-line counter autofocus></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td style='white-space:nowrap'>
                  <v-btn icon small :ripple='false' @click='confirmSmartLink(props.item.id)' v-if='props.item.nameImported'>
                    <v-icon color='success'>check</v-icon>
                  </v-btn>
                  <v-btn icon small :ripple='false' @click='cancelSmartLink(props.item.id)' v-if='props.item.nameImported'>
                    <v-icon color='error'>cancel</v-icon>
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
        <v-flex xs12 md4>
          <v-btn block class='mx-1 mb-3' color='error' @click='initNewNames'>
            Discard all new names
          </v-btn>
          <div class='headline grey--text text-xs-left'>
            Hint
          </div>
          <div class='pa-1 mb-4 smoothTransition' style='overflow-y:hidden' :style='expandHint ? "max-height:155px" : "max-height:50px"'>
            <div class='text-xs-left subheading' style='display:flex;'>
              <span>
                To get proper sorting in the classes tables, students' first name should be written in one word only (use an hyphen for composite names, <i>eg</i> Charles-Ludwig)
              </span>
              <v-btn small icon @click='expandHint = !expandHint'>
                <v-icon class='smoothTransition' :style='`transform: rotate(${expandHint ? "180" : "0"}deg)`'>expand_more</v-icon>
              </v-btn>
            </div>
            <div class='text-xs-left'>
              To sort out the students in the tables, the software looks for the second word of the proper name or, if there is no proper name, the first word of the name.
              Hence if the student's proper name is "Charles Ludwig Dodgson", that student will be placed in the table based on the word "Ludwig". On the other hand, if you enter the proper name "Charles-Ludwig Dodgson" (using a hyphen), it will be placed according to the word "Dodgson", which seems more logical.
            </div>
          </div>
          <div style='position:sticky;top:80px'>
            <v-btn block class='ma-1' @click='pasteFromSpreadsheet'>
              Paste from spreadsheet
            </v-btn>
            <v-layout row wrap>
              <v-card
              :height='focusedName != null && focusedName.id == name.id ? "68px" : "52px"'
              class='pa-2 ma-1'
              :flat='focusedName != null && focusedName.id != name.id'
              :class='focusedName == null ? "" : focusedName.id == name.id ? "subheading font-weight-bold" : "grey--text"'
              v-for='name in importedNames' :key='name.id'
              >
              {{ name.name }}
              <v-btn fab small color='success' @click="cancelLinkName" v-if='focusedName != null && focusedName.id == name.id'>
                <v-icon dark style='display:inline-flex'>link</v-icon>
              </v-btn>
              <v-btn icon class='ma-0' @click='linkImportedName(name)' v-else>
                <v-icon color='success'>link</v-icon>
              </v-btn>
              <v-btn icon class='ma-0' :ripple='false' @click='removeImportedName(name)' :disabled='focusedName != null'>
                <v-icon color='error'>delete</v-icon>
              </v-btn>
            </v-card>
          </v-layout>
          <v-layout>
            <v-btn color='error' :ripple='false' v-if='importedNames.length > 0' :disabled='focusedName != null' @click='importedNames = []'>
              Clear all <v-icon right>delete_forever</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color='primary' @click='smartLink' :loading='applyingSmartLink' :disabled='focusedName != null' v-if='importedNames.length > 0'>
              Smart link <v-icon right>link</v-icon>
            </v-btn>
          </v-layout>
          <v-btn class='mt-5' block color='success' :loading='savingNames' :disabled='focusedName != null' @click='saveNames'>
            Save names
          </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-snackbar v-model="snackbar" :timeout='0' bottom color='success'>
      Link this name to a student
      <v-btn dark flat :ripple='false' @click="cancelLinkName">
        Cancel
      </v-btn>
    </v-snackbar>
  </v-layout>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        code: '',
        search: '',
        students: [],
        division: {},
        max50chars: v => v.length <= 50 || 'Input too long!',
        studentsHeaders: [
          {text: 'User name', align: 'center', value: 'name', width: '200px'},
          {text: 'Current school name', align: 'center', value: 'properName', width: '200px'},
          {text: '', sortable: false},
          {text: 'New School name', align: 'center', value: 'newProperName', width: '200px'},
          {text: '', sortable: false}
        ],
        importedNames: [],
        focusedName: null,
        snackbar: false,
        applyingSmartLink: false,
        savingNames: false,
        expandHint: false,
        forceRefresh: 0
      }
    },
    computed:{
      backRoute(){
        return `/single-class/${this.$route.params.code}`;
      }
    },
    created(){
      eventBus.$emit('loading', {command: 'clear'});
      eventBus.$emit('loading', {command: 'start', name: 'schoolname-page'});
      this.code = this.$route.params.code
      axios.get(`/api/classes/${this.code}/properNames` , {})
      .then(resp => {
        this.students = resp.data.students;
        this.initNewNames();
        this.division = resp.data.division;
        eventBus.$emit('loading', {command: 'finish', name: 'schoolname-page'});
      })
      .catch(err => console.log(err));
    },
    methods: {
      initNewNames(){
        this.students.forEach(s => {
          s.properName.newName = '';
          s.nameImported = false;
          s.nameCopied = false;
          this.focusedName = null;
          this.snackbar = false;
        });
        this.forceRefresh++;
      },
      pasteFromSpreadsheet(){
        navigator.clipboard.readText()
        .then(text => {
          let rows = text.replace(/\s/g, '*-+>>').split('*-+>>*-+>>').map(s => s.split('*-+>>').join(' '));
          if(rows.length >0){
            let id = 1;
            this.importedNames.forEach(name => {
              id = Math.max(id, name.id + 1);
            });
            rows.forEach(name => {
              if(name != '' && this.importedNames.filter(o => o.name == name).length == 0){
                this.importedNames.push({name, id});
                id++;
              }
            });
          } else {
            this.$root.snackError('Failed to read clipboard contents');
          }
        })
        .catch(err => {
          this.$root.snackError('Failed to read clipboard contents');
          console.log(err);
        });
      },
      removeImportedName(name){
        this.importedNames = this.importedNames.filter(o => o.id != name.id);
        this.forceRefresh++;
      },
      linkImportedName(name){
        this.focusedName = name;
        this.snackbar = true;
      },
      cancelLinkName(){
        this.focusedName = null;
        this.snackbar = false;
      },
      confirmLinkName(id){
        let student = _.find(this.students, o => o.id == id);
        if(student.properName){
          student.nameCopied = true;
          student.oldProperName = student.properName.newName;
          student.properName.newName = this.focusedName.name;
        } else {
          student.properName = {newName: this.focusedName.name};
          student.nameCopied = false;
        }
        this.removeImportedName(this.focusedName);
        this.focusedName = null;
        this.snackbar = false;
      },
      copyName(id){
        let student = _.find(this.students, o => o.id == id);
        if(student.properName){
          student.nameCopied = true;
          student.oldProperName = student.properName.newName;
          student.properName.newName = student.properName.name;
        } else {
          student.properName = {newName: student.name};
          student.nameCopied = true;
          student.oldProperName = '';
        }
        this.forceRefresh++;
      },
      cancelCopyName(id){
        let student = _.find(this.students, o => o.id == id);
        student.properName.newName = student.oldProperName;
        student.nameCopied = false;
        this.forceRefresh++;
      },
      nameCompareScore(name1, name2){
        let score = 0;
        let one = name1.replace(/[^a-z]/gi, '').split(" ").join("").toLowerCase().split('');
        let two = name2.replace(/[^a-z]/gi, '').split(" ").join("").toLowerCase().split('');
        one.forEach((letter1, index1) => {
          two.forEach((letter2, index2) => {
            if(letter1 == letter2) {
              score += 1;
              let i = 1;
              while(one[index1+i] != undefined && two[index2+i] != undefined && one[index1+i] == two[index2+i] && i <= 10){
                score += Math.pow(10, i);
                i++;
              }
            }
          });
        });

        return score;
      },
      smartLink(){
        this.applyingSmartLink = true;
        let pairs = [];
        this.students.forEach(student => {
          this.importedNames.forEach(imported => {
            let score = this.nameCompareScore(student.properName.name, imported.name);
            if(score > 100){
              pairs.push({student, imported, score});
            }
            score = this.nameCompareScore(student.name, imported.name);
            if(score > 100){
              pairs.push({student, imported, score});
            }
          });
        });
        this.applyingSmartLink = false;
        pairs.sort((a,b) => (a.score > b.score) ? -1 : ((b.score > a.score) ? 1 : 0))
        while(pairs.length > 0){
          let pair = pairs[0];
          pair.student.properName.newName = pair.imported.name;
          pair.student.nameImported = true;
          pairs = pairs.filter(o => o.student.id != pair.student.id && o.imported.id != pair.imported.id);
          this.importedNames = this.importedNames.filter(o => o.id != pair.imported.id);
        }
        this.forceRefresh++;
      },
      confirmSmartLink(studentId){
        let student = _.find(this.students, o => o.id == studentId);
        student.nameImported = false;
        this.forceRefresh++;
      },
      cancelSmartLink(studentId){
        let student = _.find(this.students, o => o.id == studentId);
        let id = 1;
        this.importedNames.forEach(name => {
          id = Math.max(id, name.id + 1);
        });
        this.importedNames.push({name: student.properName.newName, id});
        student.properName.newName = "";
        student.nameImported = false;
        this.forceRefresh++;
      },
      changedName(id){
        let student = _.find(this.students, o => o.id == id);
        student.nameCopied = false;
        this.forceRefresh++;
      },
      saveNames(){
        this.savingNames = true;
        let studentsProperNames = [];
        this.students.forEach(student => {
          if(student.properName.newName != ""){
            studentsProperNames.push({id: student.id, properName: student.properName.newName});
          }
        });
        axios.post(`/api/classes/${this.division.id}/properNames` , {students : studentsProperNames})
        .then(resp => {
          this.$root.snackSuccess("Names saved");
          this.students.forEach(student => {
            student.nameImported = false;
            student.nameCopied = false;
            this.focusedName = null;
            this.snackbar = false;
            if (student.properName.newName.length > 0){
              student.properName.name = student.properName.newName;
              student.properName.newName = '';
            }
          });
          this.savingNames = false;
          this.forceRefresh++;
        })
        .catch(err => console.log(err));

      }
    }
  }
</script>

<style>
.v-menu__activator{
  justify-content: center;
}
.smoothTransition {
  transition: all .5s
}
</style>
