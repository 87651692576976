<template>
  <v-dialog v-model="editTrickDialog" fullscreen hide-overlay transition="slide-x-transition" @keydown.esc="cancelEditTrick">
    <v-card>
     <v-toolbar dark color="primary">
       <v-toolbar-title>Edit {{ trick.title }}</v-toolbar-title>
       <v-spacer></v-spacer>
       <v-chip label :color="$root.trickColors[trick.type]" text-color="white">
         {{ trick.type }}
       </v-chip>
       <v-btn icon dark @click="cancelEditTrick">
         <v-icon>close</v-icon>
       </v-btn>
     </v-toolbar>
     <v-card-text>
       <v-stepper v-model="stage" vertical non-linear>
        <v-stepper-step step="1" editable @click='toggleStep(1)'>
          Title and description
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-container grid-list-lg>
            <v-layout row wrap>
              <v-flex xs12 sm6 class="text-xs-center">
                <h4 class="display-1 mb-1 grey--text">Details</h4>
                <v-form ref="editTrickForm" v-model="valid" lazy-validation>
                  <v-text-field label="Title" v-model="trick.title" :rules="titleRules" autofocus clearable validate-on-blur></v-text-field>
                  <v-btn block color="primary" @click="pickFile" class="mb-0">Choose a new image <v-icon right>image</v-icon></v-btn>
                  <v-flex class="mt-0 pt-0 mb-4 grey--text text-xs-center">recommended size : 600px &times; 600px</v-flex>
                  <input type="file" style="display:none" ref="image" accept="image/*" @change="onFilePicked">
                  <v-textarea label="Text description (HTML enabled)" v-model="trick.text_description" :rules="textRules" :counter='255' class="mb-2" validate-on-blur></v-textarea>
                </v-form>
              </v-flex>
              <v-flex xs12 sm6 class="text-xs-center">
                <h4 class="display-1 mb-1 grey--text">Preview</h4>
                <v-card width="100%" max-width='600px' v-if="trick.title">
                  <v-card-title primary-title>
                    <h3 class="display-1 mb-2">{{ trick.title }}</h3>
                  </v-card-title>
                  <v-flex class="body-1 text-xs-left" xs12 v-if="trick.text_description">
                    <p class="grey--text caption ma-0 pa-0">Text description</p>
                    <p v-html="formatedtext_description"></p>
                  </v-flex>
                  <v-flex xs12 class='text-xs-left' v-if="trick.image_description || imageUrl">
                    <p class="grey--text caption ma-0 pa-0">Image description</p>
                    <img :src="imageUrl" width="100%" max-width='600px' aspect-ratio="1" contain v-if="imageUrl"/>
                    <img :src="imgsrc" width="100%" max-width='600px' aspect-ratio="1" contain v-else/>
                  </v-flex>
                </v-card>
              </v-flex>
              <!-- </v-flex> -->
            </v-layout>
          </v-container>
        </v-stepper-content>

        <v-stepper-step step="2" editable @click='toggleStep(2)'>
          Formula
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-container grid-list-lg>
            <v-layout row wrap>
              <v-flex xs12 md6 class="text-xs-center">
                <h4 class="display-1 mb-1 grey--text">Details</h4>
                <component :is="`formula-input-${trick.type}`" :formula="trick.formula"></component>
              </v-flex>
              <v-flex xs12 md6 class="text-xs-center">
                <h4 class="display-1 mb-1 grey--text">Preview</h4>
                <v-layout row wrap class='text-xs-left'>
                  <v-flex xs12 v-if='formatedFormula'>
                    <v-flex class="grey--text caption ma-0 pa-0">Formulas</v-flex>
                    <v-flex v-html="formatedFormula.formulas" class="mb-2 pa-0"></v-flex>
                    <v-flex class="grey--text caption ma-0 pa-0">Variables</v-flex>
                    <v-flex v-html="formatedFormula.variables" class="mb-2 pa-0"></v-flex>
                    <p class="grey--text caption ma-0 pa-0">Examples</p>
                    <v-layout v-if="trickExamples.length == 6" row wrap>
                      <v-flex style='flex:1' class='mx-3 my-2 subheading' v-for="(example, index) in this.trickExamples" :key="`ex-${index}`">
                        <div style='white-space:nowrap' class='text-xs-left'>
                          <span v-html="example.computation"></span> <span class="grey--text" v-html="example.result"></span>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-layout v-else fill-height align-content-center justify-center class='text-xs-center'>
                    <v-flex class="headline grey--text text--lighten-2 font-weight-thin">
                      Formula error
                      <v-icon color="grey lighten-2" medium>sentiment_very_dissatisfied</v-icon>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-stepper-content>

        <v-stepper-step step="3" editable @click='toggleStep(3)'>
          Options
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-layout row wrap>
            <v-flex xs12 class="text-xs-left">
              <p class="grey--text caption ma-0 pa-0">Status</p>
              <v-select :items="statusItems" label="Status" solo v-model="dbtrick.status" :messages='approvalWarning'></v-select>
            </v-flex>
            <v-flex xs12 class="text-xs-left">
              <v-text-field label="Time per question (in seconds)" v-model="dbtrick.options.time" type="number" :min="3" :max="100"></v-text-field>
              <v-text-field label="Difficulty" v-model="dbtrick.difficulty" type="number" :min="500" :max="2000"></v-text-field>
              <!-- <v-switch label='Relative numbers' v-model='relative'/> -->
              <v-select label='Relative numbers' :items='relativeOptions' v-model='dbtrick.options.relative'/>
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper>
     </v-card-text>
     <v-card-actions>
       <v-btn color="error" @click="archiveTrick">Archive trick</v-btn>
       <v-spacer></v-spacer>
       <v-btn color="error" style="width:200px" flat @click="cancelEditTrick">Cancel</v-btn>
       <v-btn color="success" style="width:200px" @click="submitEditTrick">Save</v-btn>
     </v-card-actions>
   </v-card>

   <v-dialog v-model="archiveDialog" width="350">
     <v-card>
       <v-card-title class="headline">Archive {{ dbtrick.title }}</v-card-title>
       <v-card-text>
         <v-flex>Are you sure you want to archive this trick ?</v-flex>
         <v-flex v-if="details.stars">
           <span class="font-weight-bold red--text">Warning !</span> Students have tried this trick {{ details.tries }} times and obtained a total of {{ details.stars }} stars on it. This will be lost to them !
         </v-flex>
         <v-flex v-if="details.tracks.length">
           <span class="font-weight-bold red--text">Warning !</span> Deleting this trick will remove it from the following tracks:
           <ul>
             <li v-for="(track, index) in details.tracks" :key="index">{{ track }}</li>
           </ul>
         </v-flex>
         <v-flex class="grey--text text-xs-center" v-else>This trick is not used in any track</v-flex>
       </v-card-text>
       <v-card-actions>
         <v-btn color="primary" flat block @click="archiveDialog=false">
           No
         </v-btn>
         <v-btn color="error" block @click="proceedArchive">
           Yes
         </v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
  </v-dialog>
</template>
<script>
  import trick from '../../tricks';

  export default {
    props : [],
    data : function(){
      return {
        stage: 0,
        trick: new trick(),
        dbtrick: {options:{}},
        // relative: null,
        relativeOptions: [
          {text: 'No relative numbers', value: null},
          {text: 'Standard notation added automatically', value: 'standard'},
          {text: 'Full notation given in formula', value: 'full'}
        ],
        editTrickDialog: false,
        trickExamples: [],
        statusItems: [
          {value: 'draft', text: 'Draft - Only you can view this trick'},
          {value: 'private', text: 'Private - Only you and your students can view this trick'},
          {value: 'public', text: 'Public - Everyone can view this trick'}
        ],
        archiveDialog: false,
        details: {tracks: [], stars: 0, tries: 0},
        statusExplanation: '',
        valid: false,
        imgsrc: '',
        imageName: '',
    		imageUrl: '',
    		imageFile: '',
        titleRules: [
          v => !!v || 'You must enter a title',
          v => (v && v.length >= 6) || 'Title must be at least 6 characters long'
        ],
        textRules: [
          v => !!v || 'You must enter a text description',
          v => (v && v.length >= 10) || 'Text description must be at least 10 characters long',
          v => (v && v.length <= 255) || 'The description must be less 255 characters long'
        ]
      }
    },
    computed: {
      formatedtext_description: function(){
        return this.trick.text_description.split('\n').join('<br>');
      },
      formatedFormula: function(){
        try{
          let displays = this.trick.displayFormula();
          return {formulas: displays.formulas, variables: displays.variables};
        } catch(err) {
          console.log(err);
        }
      },
      approvalWarning: function(){
        if(this.dbtrick.status == "public" && this.dbtrick.approval != "approved"){
          return 'This trick is waiting for Sam\'s approval. It is not public yet.';
        } else {
          return '';
        }
      }
    },
    mounted() {
      eventBus.$on('editTrick', (editedTrick) => {
        this.trick = new trick(editedTrick.title, editedTrick.image_description, editedTrick.text_description, editedTrick.type, JSON.parse(editedTrick.formula), editedTrick.options);
        this.dbtrick = editedTrick;
        this.editTrickDialog = true;
        this.trickExamples = this.trick.displayExamples();
        if(editedTrick.image_description){
          this.imgsrc = editedTrick.image_description;
        } else {
          this.imgsrc = '';
        }
        // this.relative = this.dbtrick.options.relative ? this.dbtrick.options.relative : null;
        this.stage = 0;
      });
      eventBus.$on('formulaUpdated', this.updateFormula);
      eventBus.$on('formulaUpdated', (newFormula) => {
        this.trick.formula = newFormula;
        this.trickExamples = this.trick.displayExamples();
      });
    },
    beforeDestroy(){
      eventBus.$off('formulaUpdated', this.updateFormula);
    },
    methods : {
      updateFormula(newFormula){
        this.trick.formula = newFormula;
        this.trickExamples = this.trick.displayExamples();
      },
      toggleStep(step){
        if(step == this.stage){
          this.stage = 0;
        }
      },
      pickFile () {
        this.$refs.image.click();
      },
      onFilePicked (e) {
        const files = e.target.files;
        if(files[0] !== undefined) {
          if(files[0].size < 2048*1000){
            this.imageName = files[0].name;
            if(this.imageName.lastIndexOf('.') <= 0) {
              return
            }
            const fr = new FileReader()
            fr.readAsDataURL(files[0])
            fr.addEventListener('load', () => {
              this.imageUrl = fr.result;
              this.imageFile = files[0];
            })
          } else {
            this.$root.snackError("This file is too large (max 2MB)");
            this.imageName = '';
            this.imageFile = '';
            this.imageUrl = '';
          }
        } else {
          this.imageName = '';
          this.imageFile = '';
          this.imageUrl = '';
        }
      },
      submitEditTrick(){
        let vm = this;
        // if(this.relative){
        //   this.dbtrick.options.relative = this.relative;
        // } else {
        //   delete this.dbtrick.options.relative;
        // }
        if(this.$refs.editTrickForm.validate()){
          axios.patch(`/api/tricks/${vm.dbtrick.id}`, {
            title: vm.trick.title,
            text_description: vm.trick.text_description,
            formula: vm.trick.formula,
            status: vm.dbtrick.status,
            difficulty: vm.dbtrick.difficulty,
            options: vm.dbtrick.options
          })
          .then(function (response) {
            if (vm.imageFile){
              let data = new FormData();
              data.append('image', vm.imageFile);
              axios.post(`/api/tricks/${response.data.id}/image`, data)
              .then(function (response) {
                eventBus.$emit('trickUpdated', response.data);
                vm.$root.snackSuccess("Trick updated.");
                vm.cancelEditTrick();
              })
              .catch(function (error) {
                vm.$root.snackError("There was an error while uploading the image."); // TODO test that
              });
            } else {
              eventBus.$emit('trickUpdated', response.data);
              vm.$root.snackSuccess("Trick updated.");
              vm.cancelEditTrick();
            }
          })
          .catch(function (error) {
            vm.$root.snackError("There was an error updating the trick.");
            console.log(error); // TODO fix this
          });
        } else {
          vm.$root.snackError("The data submitted is invalid");
        }
      },
      cancelEditTrick(){
        this.imageName = '';
        this.imageFile = '';
        this.imageUrl = '';
        this.trickExamples = [];
        this.dbtrick = {options:{}};
        this.trick = new trick();
        this.editTrickDialog = false;
      },
      archiveTrick(){
        let vm = this;
        axios.get(`/api/tricks/${this.dbtrick.id}/details`, {})
        .then(function (response) {
          vm.details = response.data;
          vm.archiveDialog = true;
        })
        .catch(function (error) {
          console.log(error); // TODO fix this
        });
      },
      proceedArchive(){
        let vm = this;
        axios.put(`/api/tricks/${this.dbtrick.id}`, {})
        .then(function (response) {
          eventBus.$emit('trickArchived', vm.dbtrick.id);
          vm.archiveDialog = false;
          vm.cancelEditTrick();
        })
        .catch(function (error) {
          console.log(error); // TODO fix this
        });
      }
    }
  }
</script>
