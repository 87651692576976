<template>
  <v-dialog v-model="registerDialog"  :width="`${400+activeWindow*200}px`" max-width='90%' min-height='572px' max-height='572px' @keydown.esc="registerCancel">
    <v-btn round color='primary' slot="activator" style='width:160px'>Register</v-btn>
    <v-card>
      <v-card-title>
        <span class="headline">Register</span>
      </v-card-title>
        <v-window v-model='activeWindow'>
          <v-window-item :key='0'>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 style='display:flex;justify-content:center'>
                  <a href="/login/google">
                    <v-img src='/images/Google signin.png' width='300'></v-img>
                  </a>
                </v-flex>
                <v-flex xs12 class='text-xs-center subheading font-weight-bold my-2'> - OR - </v-flex>
              </v-layout>
              <v-form ref="registerForm">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field label="Email" v-model='email' :rules="emailRules" autofocus clearable></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field label="Password" type="password" v-model='password' :rules="passwordRules" clearable></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field label="Confirm Password" type="password" v-model='confirmPassword' :rules="confirmRules" clearable></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select :items="['Student', 'Teacher']" label="Role" v-model='role' :rules="roleInputRules"></v-select>
                  </v-flex>
                  <v-flex xs12 class='caption'>
                    By registering you agree to our <span class='font-weight-bold primary--text' style='cursor:pointer' @click='activeWindow = 1'>terms and conditions</span>.
                  </v-flex>
                </v-layout>
              </v-form>
              <a href='/password/reset' class="grey--text caption" style='text-decoration:none'>Already registered and forgot your password ?</a>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" block flat @click="registerCancel">Cancel</v-btn>
              <v-btn color="primary" block @click="registerSubmit">Register</v-btn>
            </v-card-actions>
          </v-window-item>
          <v-window-item :key='1'>
            <v-btn flat small @click='activeWindow = 0'>
              <v-icon left>chevron_left</v-icon>Back
            </v-btn>
            <div class='title pa-2'>
              End User Licence Agreement
            </div>
            <div class='pa-2 eula'>
              <div class='font-weight-bold'>
                If you are under 18 years of age or, for any reason, under the care of a person other than yourself, your legal guardian has to validate these terms for you.
              </div>
              <div>
                The following are the terms of service (“Terms of Service”) that define the relationship between Sam’s Tricks, doing business as Sam’s Tricks (“Sam’s Tricks”, “samstricks.net”, “we”, or “us”), and you (“the user”), and govern your use of Sam’s Tricks services.
              </div>
              <div class='font-italic'>
                Last updated : 07/04/2022
              </div>
              <div>
                Thank you for your interest in Sam’s Tricks, which owns and operates the services offered on samstricks.net (“Sam’s Tricks Website”) or products and services that we may provide now or in the future (collectively, the “Service”). By signing up or using the Sam’s Tricks Website you agree to these terms and conditions.
                Sam’s Tricks can amend, supplement or remove parts of these Terms at any time and without prior notice.

                These Terms of Service contain general terms that apply to you as a user of the Sam’s Tricks Service ("User"), along with additional terms that may apply to you as a User registered as a teacher, school leader, aide, or other similar personnel ("School Personnel")

                We give you permission to use Sam’s Tricks as long as you are complying with all of the terms and conditions of this Agreement, we give you permission to access and use the Service solely to enable your use of the Service. The Service is available for personal, noncommercial use and should only be used for educational purposes.
              </div>
              <div>
                The Service is free.
              </div>
              <div>
                We only store personal information provided by you : name, email address and encrypted password. All meters related to the use of The Service (star count, smile count, race rewards, hike stars, marathon rewards, collectibles, etc.) are the sole property of Sam’s Tricks.

                We will not sell or disclose any of your personal information to any third party.

                The Service includes a “Class”system. If you join a Class, the creator (“Teacher”) of this class will have access to your name, email address, avatar as well as your performances and all the metrics The Service offers. The teacher can remove you from The Class without having to provide notice or reason.
                Your teacher can decide to enroll your class in a <i>marathon</i> (a competition across multiple classes organised by a teacher). If he does, all participants of the marathon and their teachers will be able to see your name, class and avatar if you place amongst the top performers. Furthermore, the organiser of the marathon will have access to your name, email address, avatar as well as your performances and all the metrics The Service offers.
                If you register to the Service as a <i>teacher</i>, other teachers will be able to see your name through search.
              </div>
              <div>
                We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether, including, for example, if you do not comply with this Agreement or if we are investigating suspected misconduct. We may also add or create new limits to our Service or restrict your access to all or a part of the Service at any time without notice or liability.

                The Service and the Sam's Tricks Technology are intended solely for the personal, non-commercial use of our Users and may only be used in accordance with this Agreement. “Sam's Tricks Technology” means all past, present and future content of the Service, including, all the software, hardware and technology used to provide the Service, user interfaces, materials displayed or performed on the Service, such as text, graphics, articles, photographs, images, illustrations and the design, structure, sequence and “look and feel” of the Services, and all other intellectual property, including all Sam's Tricks Marks. “Sam's Tricks Marks” means the trademarks, service marks, logos, or any other trade name, trademarks, service marks and other distinctive or proprietary brand features of Sam's Tricks.

                Sam's Tricks Technology is protected by copyright and other intellectual property laws. Using our Service does not give you ownership of any intellectual property rights in our Service or the Sam's Tricks Technology. You agree that, as between you and Sam's Tricks, all the intellectual property rights in the Sam's Tricks Service and Sam's Tricks Technology, which does not include User Content (as defined below), are owned by Sam's Tricks or its licensors. These terms do not grant you the right to use any Sam's Tricks Marks.
              </div>
              <div>
                You will not, nor will you allow any third party (whether or not for your benefit) to:
                Run, license, rent, lease, loan, distribute, or sell access to the Sam's Tricks Service or the Sam's Tricks Technology.
                Build or support (and/or assist a third-party in building or supporting) products or services in competition with Sam's Tricks, or access the Sam's Tricks Service to build a product using similar ideas, features, functions, interface or graphics of the Sam's Tricks Service.
                Use, store, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, create derivative works from, display, license, sell or otherwise exploit the Sam's Tricks Technology for any purposes other than as expressly permitted under this Agreement.
                Decompile, reverse engineer, disassemble, or otherwise attempt to obtain the source code of any Sam's Tricks Technology.
                Circumvent, disable or otherwise interfere with security related features or features that prevent or restrict use or copying of any User Content or Sam's Tricks Technology or enforce limitations on use of the Sam's Tricks Service or the User Content and Sam's Tricks Technology.
                Remove, obscure, or alter any copyright, logo, trademark, or other legal notices displayed in or along with our Services or any Sam's Tricks Premium Features or other custom products or merchandise, or otherwise use any of the Sam's Tricks Technology in a manner that creates the impression that the Sam's Tricks Technology belongs to you.
              </div>
              <div>
                In order to allow Sam's Tricks to provide the Service, you hereby grant to us a limited, non-exclusive, sublicensable (as necessary to perform The Service, including distributing Activities), worldwide, royalty-free, and transferable (only to a successor) right and license to:
                use, host, copy, store, distribute, publicly perform and display, publish (in whole or in part), modify, and create derivative works (such as changes we make so that your content works better with our Service) such User Content as necessary to (a) provide, improve and make the Service available to you and other Users including through any future media in which the Service may be distributed;
                use and disclose metrics and analytics regarding the User Content in an aggregate or other non-personally identifiable manner (including, for use in improving our service or in marketing and business development purposes);
                use, modify, prepare derivative works, publish, distribute and sublicense Feedback without any compensation to you;
                use any User Content (including any Student Data or Education Record) that has been de-identified for any product development, research or other lawful purpose

                We may, now or in the future, incorporate certain functions that allow you to interact with the Service through your accounts on certain supported third-party social networks or network storage sites, such as with “Like” and “Share” buttons, features allowing you to register on Sam's Tricks through social networks or otherwise grant access to a third-party social networking service, such as through Facebook Connect or Google, or other similar features (collectively “Linked Accounts”). If you choose to use such features, you grant Company permission to access and use your Linked Account for the purpose of processing your requests. Additionally, if you decide to use a Linked Account to register for a Sam's Tricks account through an authentication service (such as Google Accounts, Microsoft Accounts, Facebook Connect, or Clever) (“Authentication Service”) you give Sam's Tricks the permission to store and use certain information already associated with your Authentication Service. You may revoke Sam's Tricks’s access to your account on any Authentication Service at any time by updating the appropriate settings in the account preferences of the respective Authentication Service. You should check your privacy settings on each Authentication Service to understand and change the information sent to us through each Authentication Service. Please review each Authentication Service’s terms of use and privacy policies carefully before using their services and connecting to our Service. Your use of Linked Accounts and Authentication Services is subject to the applicable third-party terms and privacy policies.

                The use of any third-party applications in conjunction with Sam's Tricks (other than the ones mentioned in the paragraph above) is strictly prohibited.
              </div>
              <div>
                You warrant, represent and agree that you will not provide any User Content or otherwise use the Service in a manner that
                (i) infringes, violates or misappropriates another’s intellectual property rights, rights of publicity or privacy, or other rights;
                (ii) violates any international, federal, state or local law, statute, ordinance or regulation or which would render Company in violation of any applicable laws or regulations, including without limitation;
                (iii) is harmful, fraudulent, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, or otherwise objectionable; or
                (iv) jeopardizes the security of your account or the Service in any way, such as allowing someone else access to your account or password or submitting User Content that contains viruses. Additionally, you represent, warrant and agree that you
                (i) possess all rights necessary to provide your User Content and grant Company the rights in this Agreement
                (ii) you will comply with the Laws in connection with your use of the Service; and
                (iii) you are solely responsible for providing notices and obtaining consents required by applicable Laws for students to use the Services or to provide User Content
              </div>
              <div>
                We do our best to keep Sam's Tricks safe, but we cannot guarantee it. We need your help to keep Sam's Tricks safe, which includes the following commitments by you when using our Service:
              </div>
              <div>
                <ul>
                  <li>You will only use The Service as permitted by law, including applicable export or re-export control laws and regulations.</li>
                  <li>You will not post unauthorized commercial communications (such as spam, promotional emails, or advertisements) on or through The Service.</li>
                  <li>You will not collect users' content or information, or otherwise access The Service, using automated means (such as harvesting bots, robots, spiders, or scrapers) without our prior permission.</li>
                  <li>You will not engage in unlawful multi-level marketing, such as a pyramid scheme, on the Service.</li>
                  <li>You will not upload viruses or other malicious code, files or programs.</li>
                  <li>You will not collect, solicit or otherwise obtain login information or access an account belonging to someone else.</li>
                  <li>You will not use the Service to violate a person’s right to privacy or publicity or otherwise collect, use or disclose data, including personal information, about other users without their consent or for unlawful purposes or in violation of any Law.</li>
                  <li>You will not bully, intimidate, or harass any User or use the Service in any manner that is threatening, abusive, violent, or harmful to any person or entity, or invasive of another’s privacy.</li>
                  <li>You will not post or approve any User Content or use the Service in a manner that infringes, violates or misappropriates any third-party’s intellectual property rights or other proprietary rights, privacy rights or contractual rights.</li>
                  <li>You will not use the Service in any way to upload, post, transmit, email or otherwise distribute content that: is hate speech, discriminating, defamatory, threatening, pornographic or obscene; incites violence; contains nudity or graphic or gratuitous violence; or is otherwise objectionable as reasonably determined by Sam's Tricks.</li>
                  <li>You will not use the Service to do anything unlawful, deceptive, misleading, illegal, unethical, malicious, or discriminatory.</li>
                  <li>You will not do anything that could disable, overburden, or impair the proper working or appearance of the Service or prevent other Users from using the Service, such as a denial of service attack or interference with page rendering or other Service functionality.</li>
                  <li>You will not access (or attempt to gain unauthorized access) to the Service or to Sam's Tricks’s computer systems by any means other than as permitted in this Agreement or engage in any activity that disrupts, diminishes the quality of, interferes with the performance of, or impairs the functionality of, the Service.</li>
                  <li>You will not use automated means, including spiders, robots, crawlers, data mining tools, or the like to download data from the Service, including any Users' content or information, or otherwise access the Service.</li>
                  <li>You will not employ misleading email or IP addresses, or forged headers or otherwise manipulated identifiers in order to disguise the origin on any content transmitted to or through the Service.</li>
                  <li>You will not use the Service in any commercially unreasonable manner or in any manner that would disparage Sam's Tricks.</li>
                  <li>You will not impersonate a Sam's Tricks employee, or any other person, or falsely state or otherwise misrepresent your affiliation with any person or entity.</li>
                  <li>You will not use the Service in any manner that is harmful to minors. Without limiting the foregoing, you will not transmit or post any content anywhere on the Service, including any User Content, that violate child pornography laws or that otherwise violates any child sexual exploitation laws. Sam's Tricks absolutely does not tolerate this and will report any suspected instances of child pornography, including reporting any of your user registration information, to law enforcement, including the National Center for Missing and Exploited Children.</li>
                  <li>You will not copy, modify, or distribute any text, graphics, or other material or content available through the Service without our prior written permission, or if such content is a User Content, the prior written consent of such User.</li>
                  <li>You will comply at all times with the Community Guidelines.</li>
                  <li>You will not facilitate or encourage any violations of this Agreement or our policies, including, without, limitation, to facilitate the unlawful distribution of copyrighted content.</li>
                </ul>
                Any violation of the above may be grounds for termination of your right to access or use the Service.
              </div>
              <div>
                DISCLAIMER OF WARRANTIES. THE SERVICE (AND ANY ASSOCIATED PRODUCTS, PREMIUM FEATURES, CONTENT, THIRD-PARTY CONTENT, THIRD-PARTY WEBSITES, THIRD-PARTY APPLICATIONS, USER CONTENT, SAM'S TRICKS TECHNOLOGY OR SOFTWARE AND ANY OTHER CONTENT (“COLLECTIVELY THE “SAM'S TRICKS OFFERINGS” ) ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.

                WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, SAM'S TRICKS (AND ITS PARENT, SUCCESSORS, AFFILIATES, SUBSIDIARIES, CONTRACTORS,SERVICE PROVIDERS, EMPLOYEES, OFFICERS, DIRECTORS, SUPPLIERS, LICENSORS, PARTNERS AND AGENTS (“SAM'S TRICKS PARTIES”) EXPRESSLY DISCLAIMS ALL WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, REGARDING THE SAM'S TRICKS OFFERINGS, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF QUALITY, ACCURACY, PERFORMANCE, AVAILABILITY, MERCHANTABILITY, QUIET ENJOYMENT, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT.

                IN PARTICULAR, SAM'S TRICKS AND THE SAM'S TRICKS PARTIES MAKES NO REPRESENTATION OR WARRANTY THAT THE SAM'S TRICKS OFFERINGS (1) WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR BE TO YOUR LIKING, (2) WILL BE TIMELY, SECURE, ACCURATE, FREE FROM ERRORS OR LOSS, OR UNINTERRUPTED, OR THAT THE SERVICES ARE FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS, OR (3) THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED. SOME FEATURES ARE EXPERIMENTAL AND HAVE NOT BEEN TESTED IN ANY MANNER.

                ANY MATERIAL DOWNLOADED FROM THE WEBSITE OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT THE USER'S OWN DISCRETION AND RISK, AND THE USER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO THEIR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM ACCESSING OR DOWNLOADING ANY SUCH MATERIAL.

                THIS SERVICE MAY CONTAIN TRANSLATIONS POWERED BY GOOGLE. GOOGLE DISCLAIMS ALL WARRANTIES RELATED TO THE TRANSLATIONS, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, RELIABILITY, AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.

                NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY THE USER FROM SAM'S TRICKS SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS AND CONDITIONS OF USE.

                LIMITATION OF LIABILITY. UNDER NO LEGAL THEORY, INCLUDING, BUT NOT LIMITED TO NEGLIGENCE, SHALL SAM'S TRICKS OR THE SAM'S TRICKS PARTIES , BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, COST OF COVER, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF SAM'S TRICKS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING FROM OR RELATING TO (i) THIS AGREEMENT; (ii) YOUR USE OR THE INABILITY TO USE THE SAM'S TRICKS OFFERINGS; OR (iii) ANY INTERACTION WITH ANY THIRD-PARTY THROUGH OR IN CONNECTION WITH THE SAM'S TRICKS OFFERINGS, INCLUDING OTHER USERS.

                IN NO EVENT WILL SAM'S TRICKS OR THE SAM'S TRICKS PARTIES BE LIABLE TO YOU IN THE AGGREGATE (FOR ALL POTENTIAL CLAIMS BY YOU) FOR ANY DAMAGES INCURRED IN EXCESS OF THE GREATER OF ANY FEES YOU HAVE ACTUALLY PAID TO COMPANY FOR USE OF THE SAM'S TRICKS OFFERINGS IN THE 12 MONTHS PRIOR TO THE EVENTS GIVING RISE TO THE CLAIM, OR ONE DOLLAR ($1).

                CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF YOU RESIDE IN SUCH A JURISDICTION, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS. IN SUCH CASES, YOU DO NOT HAVE THE RIGHT TO USE SAM'S TRICK’S.
              </div>
            </div>
          </v-window-item>
        </v-window>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data : function(){
      return {
        registerDialog: false,
        activeWindow: 0,
        email: '',
        password: '',
        confirmPassword: '',
        role: '',
        emailRules: [],
        passwordRules: [],
        confirmRules: [],
        roleInputRules: [],
      }
    },
    mounted(){
      eventBus.$on('registerMe', e => {
        this.registerDialog = true;
      });
    },
    methods : {
      registerSubmit(){ // TODO Test or change that
        this.emailRules = [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid'
        ];
        this.passwordRules = [
          v => !!v || 'Confirm your password',
          v => (v && v.length >= 6) || 'password must be at least 6 characters long'
        ];
        this.confirmRules = [v => (!!v && v === this.password) || 'Passwords do not match'];
        this.roleInputRules = [v => (!!v && (v === 'Student' || v === 'Teacher')) || 'You must choose a role'];
        if(this.$refs.registerForm.validate()){
          let vm = this;
          axios.post('/register', {
            name: this.email.split('@')[0],
            email: this.email,
            password: this.password,
            password_confirmation : this.confirmPassword,
            role: this.role.toLowerCase()
          })
          .then(function (response) {
            if (response.status == 200){
                window.location.replace('/home');
            }
          })
          .catch(function (error) {
            vm.$root.snackError('Something went wrong during registration...'); // TODO improve that
          });
        } else {
          this.$root.snackError('Incomplete credentials');
        }
      },
      registerCancel(){
        this.registerDialog = false;
        this.$refs.registerForm.reset();
      }
    }
  }
</script>

<style scoped>
  .eula {
    text-align: justify;
  }
  .eula > div {
    margin-bottom: 10px;
  }
</style>
