<template>
  <v-dialog v-model="hikePerfsDialog"  fullscreen hide-overlay transition="slide-x-transition" @keydown.esc="initAndClose">
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>{{ hike.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark class='mr-2' @click="loadStats">
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-btn icon dark @click="initAndClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear :indeterminate="true" style='position:absolute;' class='ma-0' height='6' v-if='loading'></v-progress-linear>
      <span style='position:absolute;right:0'>
        <v-btn fab small color='primary' @click="openAllPanels">
          <v-icon medium style='display:inline-flex'>unfold_more</v-icon>
        </v-btn>
        <v-btn fab small color='primary' @click="closeAllPanels">
          <v-icon medium style='display:inline-flex'>unfold_less</v-icon>
        </v-btn>
      </span>
      <v-card-text v-if='!loading'>
        <v-layout row wrap class='grey--text'>
          <v-flex xs12 sm4>
            <v-layout row wrap>
              <v-flex xs12 class='title text-xs-center'>
                Active hikers
              </v-flex>
              <v-flex xs12 class='headline font-weight-bold text-xs-center text--darken-1 mb-2'>
                {{ globalStats.nbActiveHikers }} / {{ globalStats.nbHikers }}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm4>
            <v-flex xs12 class='title text-xs-center'>
              Stars earned
            </v-flex>
            <v-flex xs12 class='headline font-weight-bold text-xs-center text--darken-1 mb-2'>
              {{ globalStats.allStudentsStars }}
              <v-icon medium color='success'>stars</v-icon>
            </v-flex>
          </v-flex>
          <v-flex xs12 sm4>
            <v-layout row wrap>
              <v-flex xs12 class='title text-xs-center'>
                Global progress
              </v-flex>
              <v-flex class='headline font-weight-bold text-xs-center text--darken-1 mb-2'>
                {{ globalStats.avgProgress }}%
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 style='display:flex;justify-content:center' v-for="(data, index) in studentsPerfsDisplayed" :key="data.student.id">
            <hike-perfs-panel :data='data' :trackTitles='hike.trackTitles'></hike-perfs-panel>
          </v-flex>
          <v-flex xs12 style='display:flex;justify-content:center'>
            <v-pagination v-model="page" :length="Math.ceil(studentsPerfs.length/studentPerPage)" @input="updateDisplay"></v-pagination>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        hikePerfsDialog: false,
        loading: false,
        hike: {},
        globalStats: {},
        studentsPerfs: [],
        studentsPerfsDisplayed: [],
        page: 1,
        studentPerPage: 12,
        panels: []
      }
    },
    mounted() {
      eventBus.$on('seeHikePerfs', id => {
        this.hikePerfsDialog = true;
        this.hike.id = id;
        this.loadStats();
      });
    },
    methods: {
      loadStats(){
        this.loading = true;
        this.panels = [];
        axios.get(`/api/hikes/${this.hike.id}/perfs`, {})
        .then(resp => {
          this.hike = resp.data.hike;
          this.globalStats = resp.data.globalStats;
          this.globalStats.allStudentsStars = this.globalStats.allStudentsStars.toLocaleString('fr-FR');
          this.studentsPerfs = resp.data.studentsPerfs;
          this.studentsPerfsDisplayed = resp.data.studentsPerfs.slice(0,this.studentPerPage);
          this.loading = false;
        })
        .catch(err => console.log(err));
      },
      openAllPanels(){
        eventBus.$emit('openHikePanels', true);
      },
      closeAllPanels(){
        eventBus.$emit('openHikePanels', false);
      },
      updateDisplay(){
        this.panels = [];
        this.studentsPerfsDisplayed = this.studentsPerfs.slice((this.page-1)*this.studentPerPage,this.page*this.studentPerPage);
      },
      initAndClose(){
        this.hikePerfsDialog = false;
      }
    }
  }
</script>
