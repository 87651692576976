<template>
  <v-container>
    <v-layout row wrap v-if="classId > 0" class="text-xs-left">
      <v-flex xs12 v-if="$root.status == 'teacher'">
        <div style='display:flex;'>
          <v-menu bottom right offset-y>
            <template v-slot:activator="{ on }">
              <v-btn large icon v-on="on">
                <v-icon large>expand_more</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile @click='goBackToClasses'>
                <v-list-tile-avatar>
                  <v-icon>chevron_left</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-title class='title'>
                  Back to classes
                </v-list-tile-title>
              </v-list-tile>
              <v-divider/>
              <v-list-tile v-for='division in otherDivisions' :key='division.id' @click="gotoClass(division.code)">
                <v-list-tile-avatar>
                  <v-icon>group</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-title style='display:flex;justify-content:space-between'>
                  <span class='title mr-5'>
                    {{ division.name }}
                  </span>
                </v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
          <class-name
            :name="className"
            :code="code"
            :studentsInvited='studentsInvited'
            :id="classId"
            :schoolId="schoolId"
            :terms="terms"
            :key='classId'
          />
        </div>
        <class-tabs 
          :classId="classId"
          :students="students"
          :studentsInvited='studentsInvited'
          :terms="terms"
          :titles='titles'
          :key='classId'
        />
      </v-flex>
      <v-flex xs12 v-else>
        <v-layout row wrap class="text-xs-center mb-5">
          <v-flex xs12 style='display:flex;margin-top:-12px'>
            <v-btn color="grey" flat @click='goBackToClasses'>
              <v-icon left style='display:inline-flex'>chevron_left</v-icon> Back to classes
            </v-btn>
            <v-spacer></v-spacer>
            <v-dialog v-model="leaveDialog" width="340">
              <template v-slot:activator="{ on }">
                <v-btn color="error" flat v-on="on">
                  Leave class <v-icon right style='display:inline-flex'>exit_to_app</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline pb-0 font-weight-bold">Leave {{ className }}</v-card-title>
                <v-card-text>Are you sure you want to leave this class ?<br>You will lose all medals and rewards and won't be able to participate in future races.</v-card-text>
                <v-card-actions>
                  <v-btn color="primary" :disabled='leaveLoading' flat block @click="leaveDialog=false">
                    No
                  </v-btn>
                  <v-btn color="error" :loading='leaveLoading' block @click="leaveThisClass">
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
          <v-flex xs12 class="samfont-display" >{{ className }}</v-flex>
          <v-flex xs12 class='grey--text' style='margin-top:-15px'>{{ teacherName }}'s class</v-flex>
          <v-flex xs12 class='display-2 font-weight-bold mt-3' v-if="activeTerm">
            {{ activeTerm.score }}
            <span style="margin-left:5px">pt</span><span v-if="activeTerm.score > 1">s</span>
          </v-flex>
          <v-flex xs12 class='grey--text' v-if="activeTerm">
            {{ activeTerm.name }}
          </v-flex>
          <v-flex xs12 class="my-3">
            <v-alert 
              :value="true"
              color="success"
              icon="directions_run" 
              outline
              v-for="race in activeRaces"
              :key="race.id"
            >
              <div style="display:flex;justify-content:space-between;align-items:center;flex-wrap:wrap;">
                <span class="headline"><b>Race open :</b> {{ race.title }}</span>
                <span class="headline" v-if="race.finished">
                  Race finished
                  <v-icon large>done</v-icon>
                </span>
                <v-btn color="success" to="/races" v-else>
                  Go to races page 
                  <v-icon class="ml-1">directions_run</v-icon>
                  <v-icon>chevron_right</v-icon>
                </v-btn>
              </div>
            </v-alert>
            <v-alert
              :value="true"
              color="primary"
              icon="directions_walk"
              outline
              v-for="hike in openHikes"
              :key="hike.id"
            >
              <div style="display:flex;justify-content:space-between;align-items:center;flex-wrap:wrap;">
                <span class="headline"><b>Hike open :</b> {{ hike.title }} ({{ hike.progress }}%)</span>
                <v-btn color="primary" to="/hikes">
                  Go to hikes page 
                  <v-icon class="ml-1">directions_walk</v-icon>
                  <v-icon>chevron_right</v-icon>
                </v-btn>
              </div>
            </v-alert>
          </v-flex>
        </v-layout>
        <student-class-stats :stats="stats" :totalPoints="totalPoints"/>
        <terms-tabs :terms="terms" class="mb-5"/>
      </v-flex>
    </v-layout> 
  </v-container>
</template>
<script>
  export default {
    props : [],
    data : function(){
      return {
        className : '',
        teacherName: '',
        properName: null,
        classId : 0,
        code: '',
        leaveDialog: false,
        leaveLoading: false,
        students: [],
        terms: [],
        stats: {},
        studentsInvited: [],
        schoolId: 0,
        school: null
      }
    },
    created() {
      this.initData();
    },
    computed:{
      otherDivisions(){
        return this.$root.classesData.divisions.filter(cl => cl.id != this.classId);
      },
      activeTerm() {
        return this.terms.find(t => t.active);
      },
      totalPoints(){
        return this.terms.reduce((acc, val) => acc + val.score, 0);
      }
    },
    mounted(){
      eventBus.$on('handicapUpdated', this.updateHandicap);
      eventBus.$on('termsUpdated', this.termsUpdated);
      eventBus.$on('termUp', this.termUp);
      eventBus.$on('deleteTerm', this.deleteTerm);
      eventBus.$on('addTerm', this.addTerm);
      eventBus.$on('deactivateTerms', this.deactivateTerms);
      eventBus.$on('updatedInvitedStudents', this.updatedInvitedStudents);
      eventBus.$on('newTerms', this.newTerms);
    },
    beforeDestroy(){
      eventBus.$off('handicapUpdated', this.updateHandicap);
      eventBus.$off('termsUpdated', this.termsUpdated);
      eventBus.$off('termUp', this.termUp);
      eventBus.$off('deleteTerm', this.deleteTerm);
      eventBus.$off('addTerm', this.addTerm);
      eventBus.$off('deactivateTerms', this.deactivateTerms);
      eventBus.$off('updatedInvitedStudents', this.updatedInvitedStudents);
    },
    methods: {
      initData(){
        eventBus.$emit('loading', {command: 'clear'});
        eventBus.$emit('loading', {command: 'start', name: 'single-page'});
        this.code = this.$route.params.code
        let vm = this;
        axios.get(`/api/classes/${this.code}` , {})
        .then(function (response) {
          vm.schoolId = response.data.schoolId;
          vm.school = response.data.school;
          vm.className = response.data.name;
          vm.studentsInvited = response.data.studentsInvited;
          vm.classId = response.data.id;
          vm.teacherName = response.data.teacher;
          if(response.data.properName){
            vm.properName = response.data.properName.name;
          }
          vm.students = response.data.students;
          vm.terms = response.data.terms;
          vm.activeRaces = response.data.activeRaces;
          vm.openHikes = response.data.openHikes;
          vm.titles = response.data.titles;
          vm.stats = response.data.stats;
          eventBus.$emit('loading', {command: 'finish', name: 'single-page'});
        })
        .catch(function (error) {
          console.log(error) // TODO catch errors
        });
      },
      updatedInvitedStudents(invites){
        this.studentsInvited = invites;
      },
      gotoClass(code){
        this.$router.push(`/single-class/${code}`);
        this.initData();
      },
      goBackToClasses(){
        this.$router.push('/classes');
      },
      updateHandicap(students){
        let temp = this.students;
        this.students = [];
        temp.forEach(student => {
          let currentStudent = students.filter(s => s.id == student.id)[0];
          student.handicap = currentStudent.handicap;
          this.students.push(student);
        });
      },
      leaveThisClass(){
        this.leaveLoading = true;
        axios.post(`/api/classes/${this.classId}/leave` , {})
        .then(resp => {
          this.leaveLoading = false;
          eventBus.$emit('refreshClassesData', {});
          this.$root.snackSuccess("You left the class");
          this.leaveDialog = false;
          this.$router.push('/classes');
        })
        .catch(err => console.log(err));
      },
      termsUpdated(terms){
        this.terms = terms;
        eventBus.$emit('refreshClassesData', {});
      },
      newTerms(data){
        if(data.id == this.classId){
          console.log(data.terms);
          this.terms = data.terms;
        }
      },
      termUp(id){
        const index = this.terms.findIndex(el => el.id == id);

        this.terms[index].nb = this.terms[index].nb - 1;
        this.terms[index-1].nb = this.terms[index-1].nb + 1;
        const element = this.terms[index];
        this.terms.splice(index, 1);
        this.terms.splice(index - 1, 0, element);
      },
      deactivateTerms(nb){
        this.terms.forEach(term => {
          if(term.nb != nb){
            term.active = false;
          }
        });
      },
      addTerm() {
        const nextTermNb = this.terms.length + 1;
        this.terms.push({
          name: `Term ${nextTermNb}`,
          rules: {
            hikeStars: 1,
            raceStars: 2,
            raceFinished: 50,
            bronze: 70,
            silver: 85,
            gold: 100
          },
          nb: nextTermNb,
          races: [],
          hikes: [],
          active: false
        });
      },
      deleteTerm(nb){
        this.terms = this.terms.filter(t => t.nb != nb);
      }
    }
  }
</script>
