<template>
  <v-data-table :headers="raceHeaders" :items="raceItems" hide-actions :search="search" v-if="raceHeaders.length && show">
    <template slot="headerCell" slot-scope="props">
      <v-tooltip top v-if='props.header.fullText'>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            {{ props.header.text }}
          </span>
        </template>
        <span>
          {{ props.header.fullText }}
        </span>
      </v-tooltip>
      <span v-else>
        {{ props.header.text }}
      </span>
    </template>
    <template v-slot:items="props">
      <td :style="`width:200px;${props.item.color}`" class="pl-2 pr-0 py-0" v-if='props.item.properName'>{{ props.item.properName.name }}</td>
      <td :style="`width:200px;${props.item.color}`" class="pl-2 pr-0 py-0 grey--text" v-else>{{ props.item.name }}</td>
      <td
      :style="`${props.item.color}`"
      class="text-xs-center subheading pa-0"
      v-for="trick in props.item.tricks"
      :class="`${trick.rating >= activeRaceOptions.trickValidation ? 'font-weight-bold success--text' : trick.rating == 0 ? 'grey--text text--lighten-1' : ''}`"
      :key="`trick-${trick.id}-${trick.rating}`"
      >
      {{ trick.rating }}
      </td>
      <td :style="props.item.color" class="font-weight-bold text-xs-center pa-0">{{ props.item.total }}</td>
      <td :style="props.item.color" class="text-xs-center pa-0" v-if='activeRaceOptions.rewardRule == "fastest"'>{{ $root.formatRunningTime(props.item.runningTime) }}</td>
      <td :style="props.item.color" class="text-xs-center pa-0" v-else>{{ props.item.finishPlace }}</td>
    </template>
  </v-data-table>
</template>
<script>
  export default {
    props : { data: Object, search: String, show: Boolean },
    data : function(){
      return {
        raceHeaders: [],
        raceItems: [],
        rowColor: {gold: 'rgba(255, 215, 0, 0.4)', silver: 'rgba(192, 192, 192, 0.5)', bronze: 'rgba(80, 50, 20, 0.3)', finished: 'rgba(76, 175, 80, 0.15)'}
      }
    },
    watch: {
      data(val){
        if(this.show){
          this.activeRaceOptions = JSON.parse(val.race.options);
          let racesDetails = val.perfs;
          this.raceHeaders = [{text: 'Name', align: 'center', value: 'lastName'}];
          racesDetails[0].tricks.forEach( (trick, index) => {
            let colTitle = trick.title.length < 15 ? trick.title : trick.title.substr(0, 6)+'...'+trick.title.substr(-5, 5);
            this.raceHeaders.push({text: colTitle, fullText: trick.title, align: 'center', value: `tricks[${index}].rating`, class: 'pa-0 text-truncate'});
            this.nbOfTricks++;
          });
          this.raceHeaders.push({text: 'Total', align: 'center', value: 'nbOfStars', class: 'px-1'});
          if(this.activeRaceOptions.rewardRule == 'fastest'){
            this.raceHeaders.push({text: 'Running time', align: 'center', value: 'runningTime', class: 'px-1'});
          } else {
            this.raceHeaders.push({text: 'Finish Place', align: 'center', value: 'finishPlace', class: 'px-1'});
          }
          this.raceItems = [];
          racesDetails.forEach(detail => {
            let row = {name: detail.student.name, properName: detail.student.properName, lastName: detail.student.lastName, userId: detail.student.id};
            let tricks = [];
            detail.tricks.forEach( trick => {
              tricks.push({id: trick.id, rating: trick.rating});;
            });
            row['tricks'] = tricks;
            row['nbOfStars'] = detail.nbStars;
            row['total'] = `${detail.nbStars}/${detail.totalStars}`;
            if (detail.reward == null){
              row['color'] = '';
              row['runningTime'] = '--';
              row['finishPlace'] = '--';
            } else {
              row['color'] = `background:${this.rowColor[detail.reward.reward]}`;
              row['runningTime'] = detail.reward.runningTime;
              row['finishPlace'] = detail.reward.finishPlace;
            }
            this.raceItems.push(row);
          });
        }
      }
    },
    mounted() {

    },
    methods: {

    }
  }
</script>
