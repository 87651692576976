<template>
  <v-window :style="$vuetify.breakpoint.name == 'xs' ? 'min-width:350px;' : 'min-width:432px;'" v-model="challengeWindow">
    <v-window-item :key="0">
      <v-layout class='mt-1' row wrap style='position:relative'>
        <v-progress-circular indeterminate width='3' color='grey' v-if='fetchingChallenges' style='position:absolute;right:0px'/>
        <v-flex xs12 class='grey--text text-xs-center'>
          <div class='samfont-bigtitle'>
            Challenge canvas
          </div>
          <div class="mb-3" style="display:flex;justify-content:center;">
            <v-btn 
              style="width:250px" 
              round
              color="success"
              :loading="gettingCanvas"
              @click="getNewCanvas"
              v-if="challengeCanvas.completed"
            >
              Get new canvas
            </v-btn>
            <v-btn 
              style="width:250px" 
              round flat
              :color="riddle.data.status == 'pending' ? 'success' : 'primary'"
              :class="riddle.data.status == 'pending' ? 'pulse' : ''" 
              @click="challengeWindow = 1"
              v-else
            >
              See daily mystery
              <v-icon>chevron_right</v-icon>
            </v-btn>
          </div>
        </v-flex>
        <v-flex xs12 sm6 md12 style="display:flex;justify-content:center;align-items:center;">
          <challenge-canvas :canvas="challengeCanvas" :challenges="challenges" :credit="riddle.data.credit"/>
        </v-flex>
        <v-flex xs12 sm6 md12 v-if="riddle && riddle.data.credit > 0">
          <div class="headline">
            Mystery solved !
          </div>
          <div>
            Choose a section to reveal pixels from this canvas.
          </div>
          <div>
            You have <b>{{ riddle.data.credit }}</b> credit<span v-if="riddle.data.credit > 1">s</span> remaining
          </div>
        </v-flex>
        <v-flex xs12 sm6 md12 class="text-xs-center mt-2" style="display:flex;flex-direction:column;justify-content:center;" v-else>
          <div class="title grey--text">
            {{ challenges.length }} challenge<span v-if="challenges.length > 1">s</span> available
          </div>
          <div style="display:flex;justify-content:space-between;" class="pa-3">
            <div class="grey--text pl-2" style="width:200px;display:flex;align-items:center;justify-content:flex-start;">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon class="mr-1" large color="primary" v-on="on" style="cursor:pointer;">sentiment_satisfied_alt</v-icon>
                </template>
                <span>You get a new challenge every few hours</span>
              </v-tooltip>
              <div>
                <div class="caption text-xs-left">
                  Next challenge in
                </div>
                <div class="subheading text-xs-left">
                  {{ timeTillNextChallenge }}
                </div>
              </div>
            </div>
            <div class="grey--text pr-2" v-if="challenges.length > 0" style="width:200px;display:flex;align-items:center;justify-content:flex-end;">
              <div>
                <div class="caption text-xs-right">
                  Next expiry in
                </div>
                <div class="subheading text-xs-right">
                  {{ timeTillNextExpiry }}
                </div>
              </div>
              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon class="ml-1" large color="error" v-on="on" style="cursor:pointer;">sentiment_dissatisfied</v-icon>
                  </template>
                  <span>You lose 5 smiles when a challenge expires</span>
                </v-tooltip>
            </div>
          </div>
        </v-flex>
        <div class="pa-2" style="width:100%">
            <v-btn flat round block to="/canvas">
              Go to Canvas page
              <v-icon right>open_in_new</v-icon>
            </v-btn>
          </div>
      </v-layout>
    </v-window-item>
    <v-window-item :key="1">
      <v-layout class='mt-1' row wrap style='position:relative'>
          <v-flex xs12 class='grey--text text-xs-center'>
            <div class='samfont-bigtitle'>
              Mystery calculation
            </div>
            <div class="mb-3" style="display:flex;justify-content:center;">
              <v-btn style="width:250px" round flat color="primary" @click="challengeWindow = 0">
                <v-icon>chevron_left</v-icon>
                See challenge canvas
              </v-btn>
            </div>
          </v-flex>
          <v-flex xs12 sm6 md12 style="display:flex;flex-direction:column;justify-content:center;align-items:center;">
            <riddle-display :riddle="riddle" v-if="riddle.data.status" :key="riddle.id"/>
            <div class="text-xs-center grey--text pa-4">
              Find the mystery calculation in less than 6 tries and reveal part of your current canvas.
            </div>
          </v-flex>
        </v-layout>
    </v-window-item>
  </v-window>
</template>
<script>

  export default {
    props : {},
    data : function(){
      return {
        fetchingChallenges: false,
        challenges: [],
        challengeCanvas: {id: 0, revealed: {sections: [], subsections: [], pixels: []}},
        timeTillNextChallenge: '',
        timeTillNextExpiry: '',
        unfinishedCanvas: [],
        totalUnrevealed: 0,
        refreshChallengesTimer: null,
        gettingCanvas: false,
        completedBonusSmiles: 0,
        challengeWindow: 0,
        riddle: {data: {}}
      }
    },
    created(){
      this.loadChallenges(true);
    },
    mounted(){
      eventBus.$on('challengesUpdated', this.loadChallenges);
      eventBus.$on('canvasUpdate', this.updateCanvas);
      eventBus.$on('imageUpdate', this.imageUpdate);
      eventBus.$on('canvasPurchased', this.imageUpdate);
      eventBus.$on('closeNewCanvasDialog', this.closeNewCanvasDialog);
      eventBus.$on('canvasCompleted', this.canvasCompleted);
      eventBus.$on('riddleFinished', this.riddleFinished);
      eventBus.$on('backToChallenges', this.backToChallenges);
      this.refreshChallengesTimer = setInterval(() => {
        this.loadChallenges(false);
      }, 180000);
    },
    beforeDestroy(){
      eventBus.$off('challengesUpdated', this.loadChallenges);
      eventBus.$off('canvasUpdate', this.updateCanvas);
      eventBus.$off('imageUpdate', this.imageUpdate);
      eventBus.$off('canvasPurchased', this.imageUpdate);
      eventBus.$off('closeNewCanvasDialog', this.closeNewCanvasDialog);
      eventBus.$off('canvasCompleted', this.canvasCompleted);
      eventBus.$off('riddleFinished', this.riddleFinished);
      eventBus.$off('backToChallenges', this.backToChallenges);
      clearInterval(this.refreshChallengesTimer);
    },
    methods: {
      loadChallenges(init){
        this.fetchingChallenges = true;
        axios.get(`/api/challenges${init ? '' : '/refresh'}`, {})
        .then(resp => {
          this.riddle = resp.data.riddle;
          this.challenges = resp.data.challenges;
          if(init){
            this.challengeCanvas = resp.data.challengeCanvas;
          }
          this.timeTillNextChallenge = resp.data.timeTillNextChallenge;
          this.timeTillNextExpiry = resp.data.timeTillNextExpiry;
          this.fetchingChallenges = false;
        }).catch(err => console.log(err.response));
      },
      getNewCanvas(){
        this.gettingCanvas = true;
        axios.get(`/api/challenges/canvas/next`, {})
        .then(resp => {
          this.challengeCanvas = resp.data;
          this.$root.snackSuccess('Canvas updated');
          this.gettingCanvas = false;
        }).catch(err => console.log(err));
      },
      updateCanvas(canvas) {
        this.challengeCanvas.revealed = canvas.revealed;
      },
      closeNewCanvasDialog(){
        this.newCanvasDialog = false;
      },
      imageUpdate(canvas){
        this.challengeCanvas = canvas;
      },
      seeImage(canvas) {
        if (canvas.image) {
          this.focusedCanvas = canvas;
          this.imageDialog = true;
        } else {
          this.loading = true;
          this.imageLoading = canvas.id;
          axios.get(`/api/challenges/canvasImage/${canvas.id}`, {})
          .then(resp => {
            canvas.image = resp.data;
            this.focusedCanvas = canvas;
            this.imageDialog = true;
            this.imageLoading = 0;
            this.loading = false;
          }).catch(err => console.log(err));
        }
      },
      selectCanvas(canvas) {
        this.loading = true;
        this.selecting = canvas.id;
        axios.post(`/api/challenges/canvas/${canvas.id}/select`)
        .then(resp => {
          eventBus.$emit('imageUpdate', resp.data);
          this.selecting = 0;
          this.canvases.forEach(c => c.open = false);
          canvas.open = true;
          this.loading = false;
        }).catch(err => console.log(err));
      },
      canvasCompleted(){
        this.challengeCanvas.completed = true;
      },
      // showCompletedCard(nbSmiles) {
      //   this.completedBonusSmiles = nbSmiles;
      //   this.completedCard = true;
      // },
      backToChallenges() {
        this.challengeWindow = 0;
      },
      riddleFinished(riddle){
        this.riddle = riddle;
      }
    }
  }
</script>

<style scoped>
  .pulse {
  box-shadow: 0 0 0 rgba(44, 204, 79, 0.4);
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(44, 204, 79, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(44, 204, 79, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(44, 204, 79, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(44, 204, 79, 0.4);
    box-shadow: 0 0 0 0 rgba(44, 204, 79, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(44, 204, 79, 0);
      box-shadow: 0 0 0 10px rgba(44, 204, 79, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(44, 204, 79, 0);
      box-shadow: 0 0 0 0 rgba(44, 204, 79, 0);
  }
}
</style>
