<template>
  <div ref='outer'>
    <v-card class='py-3' :style='`font-size:${fontSize}px`' style='height:150px;' v-if='active | showing'>
      <span></span>
      <v-avatar style='position:absolute;top:5px;right:5px;' :color="questionNb >= 20 ? 'red' : 'blue'" v-if='questionNb > 0'>
        <span class="white--text headline font-weight-bold">{{ questionNb % 20 }}</span>
      </v-avatar>
      <div style='position:absolute;right:0px;top:0px;height:100%;display:flex;align-items:center'>
        <v-img :src='statusImgName' width='130px' style='position:absolute;right:0px;top:25px' v-if='questionNb == 0'/>
      </div>
      <div ref='inner' style='position:relative'>
        <div style='white-space:nowrap;position:absolute;top:-5px;left:10px' v-html='questionFormated'></div>
        <div style='white-space:nowrap;position:absolute;top:50px;left:50px;' v-html='answerFormated'></div>
      </div>
    </v-card>
    <v-card :style='`font-size:${fontSize*0.6}px`' style='height:100px;' class='py-3' v-else>
      <span style='position:absolute;top:5px;left:5px' class='display-1 font-weight-thin grey--text' v-if='questionNb > 0'>{{ questionNb }}</span>
      <div style='position:absolute;right:0px;top:0px;height:100%;display:flex;align-items:center'>
        <v-img :src='statusImgName' width='130px' style='position:absolute;right:0px;top:15%'/>
      </div>
      <div class='pl-2' ref='inner' style='position:relative'>
        <div style='white-space:nowrap;' v-html='questionFormated'/>
        <div style='white-space:nowrap;' v-html='answerFormated'/>
      </div>
    </v-card>
  </div>

</template>
<script>
  import katex from 'katex';

  export default {
    props : {
      question: String,
      questionFormated: String,
      options: Object,
      active: Boolean,
      showing: {type: Boolean, default: false},
      forceAnswerDisplay: [String, Array],
      questionNb: {type: Number, default: 0}
    },
    data : function(){
      return {
        fontSize: 35,
        result: '',
        statusImgName: '',
        imgTimer: null,
        takingAnswers: false,
        instantReplace: true,
        command: 'expand',
        activeCoeff: 10,
        validCoeffs: [false, false, false, false],
        answerCoeffs: ['...', '...', '...', '...'],
        answerOperations: ['+', '+', '+', '+'],
        displayingCorrectAnswer: false,
        nbOfSolutions: 1,
        formatedAnswerForceUpdate: 0
      }
    },
    computed: {
      answerFormated(){
        this.formatedAnswerForceUpdate;
        let resultString = '';

        if(this.command == 'expand'){
          resultString += '=';
          let higherIndex = 0;
          if(!(parseInt(this.answerCoeffs[0]) > 0)){
            higherIndex++;
            if(!(parseInt(this.answerCoeffs[1]) > 0)){
              higherIndex++;
            }
          }
          this.answerCoeffs.forEach((coeff, index) => {
            let degree = 3-index;
            let newTerm = '';
            if(degree >= 2){
              newTerm += coeff + 'x^' + degree;
            } else if(degree == 1) {
              newTerm += coeff + 'x';
            } else {
              newTerm += coeff;
            }
            let currentOperation = this.answerOperations[index];
            if(this.activeCoeff == index){
              if(index <= higherIndex && currentOperation == '+'){
                currentOperation = '';
              }
              if(this.validCoeffs[index]){
                if(this.displayingCorrectAnswer){
                  resultString += `\\textcolor{green}{${currentOperation}{${newTerm}}}`;
                } else {
                  resultString += `\\Large{\\textcolor{green}{${currentOperation}{${newTerm}}}}`;
                }
              } else if(this.showRedCorrection) {
                resultString += `\\Large{\\textcolor{red}{${currentOperation}{${newTerm}}}}`;
              } else {
                resultString += `\\Large{\\textcolor{blue}{${currentOperation}{${newTerm}}}}`;
              }
            } else {
              if(index >= higherIndex || index > this.activeCoeff){
                if(index <= higherIndex && index <= this.activeCoeff && currentOperation == '+'){
                  currentOperation = '';
                }
                if(coeff != 0){
                  if(index != 3 && coeff == 1){
                    newTerm = newTerm.split('1').join('');
                  }
                  if(this.validCoeffs[index]){
                    resultString += `\\textcolor{green}{\\normalsize{${currentOperation}${newTerm}}}`;
                  } else if(this.showRedCorrection) {
                    resultString += `\\textcolor{red}{\\normalsize{${currentOperation}${newTerm}}}`;
                  } else {
                    resultString += `\\normalsize{${currentOperation}${newTerm}}`;
                  }
                }
              }
            }
          });
          resultString += '\\Large{\\,}'
        } else if(this.command == 'factor') {
          resultString += '=';
          let chars = [
            {name: 'openBracket-1', char: '(', display: true, color: 'black', size: 'normalsize'},
            {name: 'xSign-1', char: this.answerOperations[0], display: true, color: 'black', size: 'normalsize'},
            {name: 'xTerm-1', char: `${this.answerCoeffs[0]}x`, display: true, color: 'black', size: 'normalsize'},
            {name: 'cSign-1', char: this.answerOperations[1], display: true, color: 'black', size: 'normalsize'},
            {name: 'cTerm-1', char: `${this.answerCoeffs[1]}`, display: true, color: 'black', size: 'normalsize'},
            {name: 'closeBracket-1', char: ')', display: true, color: 'black', size: 'normalsize'},
            {name: 'times', char: '\\times', display: false, color: 'black', size: 'normalsize'},
            {name: 'openBracket-2', char: '(', display: true, color: 'black', size: 'normalsize'},
            {name: 'xSign-2', char: this.answerOperations[2], display: true, color: 'black', size: 'normalsize'},
            {name: 'xTerm-2', char: `${this.answerCoeffs[2]}x`, display: true, color: 'black', size: 'normalsize'},
            {name: 'cSign-2', char: this.answerOperations[3], display: true, color: 'black', size: 'normalsize'},
            {name: 'cTerm-2', char: `${this.answerCoeffs[3]}`, display: true, color: 'black', size: 'normalsize'},
            {name: 'closeBracket-2', char: ')', display: true, color: 'black', size: 'normalsize'}
          ];
          const charElement = name => chars[_.findIndex(chars, o => o.name == name)];

          if(this.answerOperations[0] == '+'){
            charElement('xSign-1').display = false;
          }
          if(this.answerOperations[2] == '+'){
            charElement('xSign-2').display = false;
          }

          const terms = [['xSign-1', 'xTerm-1'], ['cSign-1', 'cTerm-1'], ['xSign-2', 'xTerm-2'], ['cSign-2', 'cTerm-2']];

          if(this.answerCoeffs[0] == 1 && this.activeCoeff != 0){
            charElement('xTerm-1').char = 'x';
          }
          if(this.answerCoeffs[2] == 1 && this.activeCoeff != 2){
            charElement('xTerm-2').char = 'x';
          }
          terms.forEach((term, index) => {
            if(this.answerCoeffs[index] == 0 && this.activeCoeff != index){
              term.forEach(char => {
                charElement(char).display = false;
              });
              if(index <= 1){
                charElement('openBracket-1').display = false;
                if(charElement('cSign-1').char == '+'){
                  charElement('cSign-1').display = false;
                }
                charElement('closeBracket-1').display = false;
              } else {
                charElement('times').display = true;
                if(charElement('cSign-2').char == '+'){
                  charElement('cSign-2').display = false;
                }
                if((index == 2 && this.answerOperations[3] == '+') || (index == 3 && this.answerOperations[2] == '+')){
                  charElement('openBracket-2').display = false;
                  charElement('closeBracket-2').display = false;
                }
              }
            }
          });
          let makeBig = typeof terms[this.activeCoeff] != 'undefined' ? terms[this.activeCoeff] : [];
          let makeGreen = [];
          let makeRed = [];
          terms.forEach( (term, index) => {
            if(this.validCoeffs[index]){
              makeGreen.push(...terms[index]);
            } else if(this.showRedCorrection){
              makeRed.push(...terms[index]);
            }
          });

          chars.forEach(char =>{
            if(makeBig.indexOf(char.name) >= 0 && !this.displayingCorrectAnswer){
              char.color = 'blue';
              char.size = 'Large';
            }
            if(makeRed.indexOf(char.name) >= 0){
              char.color = 'red';
            }
            if(makeGreen.indexOf(char.name) >= 0){
              char.color = 'green';
            }
            if(char.display){
              resultString += `\\${char.size}{\\textcolor{${char.color}}{${char.char}}}`;
            }
          });
          resultString += '\\Large{\\,}'
        } else if(this.command == 'equation') {
          resultString += '\\normalsize{\\to\\,}';
          let chars = [
            {name: 'sol-1', char: this.answerCoeffs[0], sign: this.answerOperations[0], display: true, color: 'black', size: 'normalsize'},
            {name: 'sol-2', char: this.answerCoeffs[1], sign: this.answerOperations[1], display: true, color: 'black', size: 'normalsize'},
            {name: 'sol-3', char: this.answerCoeffs[2], sign: this.answerOperations[2], display: true, color: 'black', size: 'normalsize'},
          ];
          chars.forEach((char, index) =>{
            if(index == this.activeCoeff && !this.displayingCorrectAnswer){
              char.color = 'blue';
              char.size = 'Large';
            }
            if(this.showRedCorrection){
              char.color = 'red';
            }
            if(this.validCoeffs[index]){
              char.color = 'green';
            }
            if(index < this.options.nbOfSolutions){
              let sign = char.sign == '+' ? '' : '-';
              resultString += `x=\\${char.size}{\\textcolor{${char.color}}{${sign}${char.char}}}\\Large{\\;}\\normalsize{\\;}`;
              if(index != this.options.nbOfSolutions - 1){
                resultString += ';\\Large{\\;}\\normalsize{\\;}';
              }
            }
          });
        }

        return katex.renderToString(resultString, {throwOnError: false});
      }
    },
    watch:{
      active: {
        handler: function(val){
          if(val){
            if(this.command == 'expand'){
              this.activeCoeff = 3-this.options.startingDegree;
            } else {
              this.activeCoeff = 0;
            }
          } else {
            this.activeCoeff = 10;
          }
        }
      },
      forceAnswerDisplay: {
        handler: function(val) {
          if(val.length > 0){
            val.forEach((ans, index) => {
              this.answerOperations[index] = ans >= 0 ? '+' : '-';
              this.answerCoeffs[index] = Math.abs(ans);
            });
            this.validCoeffs = [true, true, true, true];
            this.statusImgName = this.$root.user.images.correct;
            this.formatedAnswerForceUpdate++
          }
        },
        immediate: true
      }
    },
    created() {
      if(this.forceAnswerDisplay.length > 0){
        this.statusImgName = this.$root.user.images.correct;
      } else {
        this.statusImgName = this.$root.user.images.pending;
      }
    },
    mounted() {
      this.displayingCorrectAnswer = false;
      this.command = this.options.command;
      if(this.options.longQuestion){
        this.fontSize = this.fontSize * 0.6;
      }
      if(this.$vuetify.breakpoint.name == 'xs'){
        this.fontSize -= 10;
      }
      if(this.active){
        if(this.command == 'expand'){
          this.activeCoeff = 3-this.options.startingDegree;
        } else {
          this.activeCoeff = 0;
        }
      }
      if(this.questionNb > 0){
        this.activeCoeff = 4
      }
      eventBus.$on('resultUpdate', this.resultUpdate);
      eventBus.$on('answerChecked', this.validateResult);
      eventBus.$on('endQuestion', this.displayAnswer);
    },
    beforeDestroy(){
      eventBus.$off('resultUpdate', this.resultUpdate);
      eventBus.$off('answerChecked', this.validateResult);
      eventBus.$off('endQuestion', this.displayAnswer);
    },
    methods: {
      resultUpdate(char){
        if(this.active){
          this.showRedCorrection = false;
          if(char == 'go-right'){
            let max = this.command == 'equation' ? this.options.nbOfSolutions - 1 : 3
            this.activeCoeff = Math.min((this.activeCoeff + 1), max);
          }
          if(char == 'go-left'){
            this.activeCoeff = Math.max((this.activeCoeff - 1), 0);
          }
          if (char == 'cycle') {
            let max = this.command == 'equation' ? this.options.nbOfSolutions - 1 : 3
            this.activeCoeff = (this.activeCoeff + 1) % (max+1);
          }
          if(!isNaN(char)){
            if(this.validCoeffs[this.activeCoeff]){
              this.$root.snackSuccess('This answer is correct')
            } else {
              if(this.instantReplace){
                this.answerCoeffs[this.activeCoeff] = char.toString();
              } else {
                this.answerCoeffs[this.activeCoeff] += char.toString();
              }
              this.instantReplace = false;
              this.formatedAnswerForceUpdate++;
            }
          } else {
            this.instantReplace = true;
          }
          if(char == '+/-'){
            if(this.validCoeffs[this.activeCoeff]){
              this.$root.snackSuccess('This answer is correct')
            } else {
              this.answerOperations[this.activeCoeff] = this.answerOperations[this.activeCoeff] == '-' ? '+' : '-';
              this.formatedAnswerForceUpdate++;
            }
          }
          if (char == 'backsp'){
            this.answerCoeffs[this.activeCoeff] = '...';
            this.answerOperations[this.activeCoeff] = '+'
            this.formatedAnswerForceUpdate++;
          }
          if (char == 'clear'){
            this.validCoeffs = [false, false, false, false];
            this.answerCoeffs = ['...', '...', '...', '...'];
            this.answerOperations = ['+', '+', '+', '+'];
            this.formatedAnswerForceUpdate++;
          }
          if(char == 'submit'){
            let coeffs = [];
            this.answerCoeffs.forEach((coeff, index) => {
              if(coeff == '...') {
                coeffs.push(0)
              } else {
                if(this.answerOperations[index] == '+'){
                  coeffs.push(parseInt(coeff));
                } else {
                  coeffs.push(-parseInt(coeff));
                }
              }
            })
            eventBus.$emit('submitAnswer', coeffs);
          }
        }
      },
      validateResult(data){
        if(this.active){
          clearTimeout(this.imgTimer);
          this.validCoeffs = data.validCoeffs;
          if(data.correctAnswer){
            this.displayingCorrectAnswer = true
            this.statusImgName = this.$root.user.images.correct;
          } else {
            this.statusImgName = this.$root.user.images.incorrect;
            this.showRedCorrection = true;
            this.imgTimer = setTimeout(()=>{
              this.statusImgName = this.$root.user.images.pending;
            }, 800);
            this.instantReplace = true;
          }
        }
      },
      displayAnswer(answer){
        this.displayingCorrectAnswer = true;
        for(let i=0; i< 4; i++){
          if(answer[i] < 0){
            this.answerOperations[i] = "-";
            this.answerCoeffs[i] = -answer[i];
          } else {
            this.answerOperations[i] = "+";
            this.answerCoeffs[i] = answer[i];
          }
        }
      }
    }
  }
</script>
