<template>
    <div>
        <v-btn block outline round color="primary" class="mb-2" @click="backToTiles">
            Choose another question
        </v-btn>
        <component :is="`display-${trick.type}`"
            style='width:100%;height:100%;margin-bottom:20px;'
            :question='trick.question.forTex'
            :questionFormated='trick.question.formated'
            :options='trick.displayOptions'
            :active = 'isActive'
            :forceAnswerDisplay = '[]'
        />
        <component style="margin:auto;" :is="`keypad-${trick.type}`" v-if="isActive"/>
    </div>
</template>
<script>
export default {
    props: { trick: Object, isActive: Boolean },
    data: function () {
        return {
            acceptingAnswers: true
        }
    },
    mounted() {
        eventBus.$on('submitAnswer', this.checkStudentAnswer);
    },
    beforeDestroy() {
        eventBus.$off('submitAnswer', this.checkStudentAnswer);
    },
    methods: {
        backToTiles() {
            eventBus.$emit('backToTiles', {});
        },
        checkStudentAnswer(answer) {
            if (this.acceptingAnswers) {
                this.acceptingAnswers = false;
                let trick = this.trick;
                let rightAnswer = false;
                if (trick.type == 'classic' || trick.type == 'power') {
                    if (trick.checkAnswer(this.trick.question.raw, answer)) {
                        eventBus.$emit('answerChecked', true);
                        eventBus.$emit('endQuestion', answer);
                        rightAnswer = true;
                    } else {
                        eventBus.$emit('answerChecked', false);
                    }
                } else if (trick.type == 'fraction') {
                    let answerCheck = trick.checkAnswer(this.trick.question.raw, answer);
                    let validAnswer = false;
                    if (answerCheck.simplified || (answerCheck.value && !trick.formula.simplification)) {
                        eventBus.$emit('answerChecked', true);
                        rightAnswer = true;
                    } else if (answerCheck.value) {
                        eventBus.$emit('answerChecked', false);
                        eventBus.$emit('needSimplification', {});
                    } else {
                        eventBus.$emit('answerChecked', false);
                    }
                } else if (trick.type == 'algebra') {
                    let validCoeffs = trick.checkAnswer(this.trick.question.coeffs, answer, this.trick.question.nbOfSolutions);
                    if (validCoeffs.every(val => val)) {
                        eventBus.$emit('answerChecked', { correctAnswer: true, validCoeffs });
                        rightAnswer = true;
                    } else {
                        eventBus.$emit('answerChecked', { correctAnswer: false, validCoeffs });
                    }
                }
                if (rightAnswer) {
                    eventBus.$emit('challengeQuestionSolved', {});
                    setTimeout(this.backToTiles, 500);
                } else {
                    this.acceptingAnswers = true;
                }
            }
        },
    }
}
</script>
