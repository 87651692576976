<template>
  <v-dialog v-model="classPracticeDialog" fullscreen hide-overlay transition="slide-y-transition">
    <v-btn icon @click="cancelPractice" :ripple="false" style='position:fixed;top:7px;right:5px;z-index:100'>
      <v-icon>close</v-icon>
    </v-btn>
    <v-card id='mainDialog' :style='autoScroll && globalState < 2 ? "height:100vh;overflow-y:hidden" : ""'>
      <div ref='practiceCard'>
        <v-layout style='position:fixed;top:0px;width:100%;z-index:10;background:white' class='pt-2'
          v-if='globalState < 2'>
          <v-progress-linear v-model="times[n-1].ratio" height='15' :color='timerColor'
            v-for="n in questionList1.length" :key='`timer-${n}`' v-if='n==qnb'></v-progress-linear>
          <span style='white-space:nowrap'>
            <v-btn :disabled='globalState != 1' icon @click='previousQuestion'>
              <v-icon>skip_previous</v-icon>
            </v-btn>
            <v-btn :disabled='globalState != 1' icon @click='timeBack'>
              <v-icon>fast_rewind</v-icon>
            </v-btn>
            <v-btn :disabled='globalState != 1' icon @click='running = !running'>
              <v-icon v-if='running'>pause</v-icon>
              <v-icon v-else>play_arrow</v-icon>
            </v-btn>
            <v-btn :disabled='globalState != 1' icon @click='timeForward'>
              <v-icon>fast_forward</v-icon>
            </v-btn>
            <v-btn :disabled='globalState != 1' icon>
              <v-icon @click='nextQuestion'>skip_next</v-icon>
            </v-btn>
          </span>
          <span style='width:70px'></span>
        </v-layout>
        <div style='position:fixed;bottom:5px;right:5px;z-index:10' class='text-xs-right' v-if='!running'>
          <div style='display:flex;'>
            <div style='flex:1'></div>
            <v-switch v-model='autoScroll' hide-details style='flex:0;min-width:fit-content;' class='mr-3'
              label='Enable auto scroll' v-if="displayQuestions == 'All at once'"></v-switch>
          </div>
          <v-btn :ripple='false' color='error' @click="finishPractice">
            Finish practice
            <v-icon right dark>forward</v-icon>
          </v-btn>
          <v-btn :ripple='false' color='primary' @click='restartPractice'>
            Restart practice
            <v-icon right dark>settings_backup_restore</v-icon>
          </v-btn>
        </div>
        <v-window v-model='globalState' style='overflow-y:hidden'>
          <v-window-item key='global-0'>
            <v-layout style='margin-top:186px' row wrap v-if="displayQuestions == 'One by one' && secondSet">
              <v-flex xs12 style='background-color:rgba(0,0,255,0.1);height:196px;'>
                <span class='insetTextBlue display-1'>
                  Question set 1
                </span>
              </v-flex>
              <v-flex xs12 style='background-color:rgba(255,0,0,0.1);height:196px;'>
                <span class='insetTextRed display-1'>
                  Question set 2
                </span>
              </v-flex>
            </v-layout>
            <v-layout style='position:fixed;width:100%;top:114px'
              v-else-if="displayQuestions == 'All at once' && secondSet">
              <v-flex xs6 style='background-color:rgba(0,0,255,0.1);height:100vh;'>
                <span class='insetTextBlue display-1'>
                  Question set 1
                </span>
              </v-flex>
              <v-flex xs6 style='background-color:rgba(255,0,0,0.1);height:100vh'>
                <span class='insetTextRed display-1'>
                  Question set 2
                </span>
              </v-flex>
            </v-layout>
            <v-layout v-else>

            </v-layout>
            <v-layout row wrap style="position:fixed;top:250px;left:50%;width:300px;margin-left:-150px;"
              class="text-xs-center display-4 font-weight-black">
              <v-flex xs12>
                <v-progress-circular :rotate="90" :size="250" :width="25" :value="initialCountdown" color="primary"
                  v-if='autoStart'>
                  {{ initialCountdownDisplay }}
                </v-progress-circular>
                <v-btn style='margin-top:95px' fab large :ripple='false' @click='startPractice' v-else>
                  <v-icon style='display:inline-flex'>play_arrow</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-window-item>
          <v-window-item key='global-1'>
            <v-layout style='margin-top:114px' row wrap v-if="displayQuestions == 'One by one'">
              <v-flex xs12>
                <v-stepper :value="qnb" class="overflow-hidden hidden-xs-only mb-2">
                  <v-stepper-header>
                    <template v-for="(question, index) in questionList1">
                      <v-stepper-step color='black' :key="`${index}-step`" :complete="qnb > index+1"
                        :step="index+1"></v-stepper-step>
                      <v-divider v-if="index+1 !== questionList1.length" :key="`div-${index}`"></v-divider>
                    </template>
                  </v-stepper-header>
                  <v-stepper-items>
                    <v-stepper-content class='pa-0' v-for='(question, index) in questionList1'
                      :key='`question-${index}`' :step="index+1">
                      <v-layout row wrap>
                        <v-flex xs12 class="text-xs-center pa-5" style='position:relative'
                          :style='secondSet ? "background-color:rgba(0,0,255,0.1)" : ""'>
                          <span style='position:absolute;top:10px;left:10px' class='insetTextBlue display-1'
                            v-if='secondSet'>
                            Set 1
                          </span>
                          <component :is="`display-${question.type}`"
                            style='display:block;max-width:600px;margin:auto;' :question='question.forTex'
                            :questionFormated='question.formated' :options='question.options' :questionNb='index+1'
                            :forceAnswerDisplay='question.hiddenAnswer' :active='true'>
                          </component>
                          <div style='position:absolute;right:0px;top:0px'>
                            <v-btn fab dark small color='success' @click='revealAnswer(1, index)'>
                              <v-icon style='display:inline-flex' v-if='showAnswer[0]'>visibility_off</v-icon>
                              <v-icon style='display:inline-flex' v-else>visibility</v-icon>
                            </v-btn>
                          </div>
                          <div style='position:absolute;right:0px;bottom:0px;display:flex;align-items:center'>
                            <span class='display-2' v-if='showStudent'>{{ grilledStudents[index] ?
                              grilledStudents[index].text : '' }}</span>
                            <v-btn icon @click='showStudent = !showStudent'>
                              <v-icon large color='error' dark style='display:inline-flex'
                                v-if='showStudent'>visibility_off</v-icon>
                              <v-icon large color='primary' style='display:inline-flex' v-else>person</v-icon>
                            </v-btn>
                          </div>
                        </v-flex>
                        <v-flex xs12 class="text-xs-center pa-5"
                          style='position:relative;background-color:rgba(255,0,0,0.1)' v-if='secondSet'>
                          <span style='position:absolute;top:10px;left:10px' class='insetTextRed display-1'>
                            Set 2
                          </span>
                          <component :is="`display-${questionList2[index].type}`"
                            style='display:block;max-width:600px;margin:auto;'
                            :question='questionList2[index].forTex' :questionFormated='questionList2[index].formated'
                            :options='questionList2[index].options' :questionNb='index+21'
                            :forceAnswerDisplay='questionList2[index].hiddenAnswer' :active='true'>
                          </component>
                          <div style='position:absolute;right:0px;top:0px'>
                            <v-btn fab dark small color='success' @click='revealAnswer(2, index)'>
                              <v-icon style='display:inline-flex' v-if='showAnswer[1]'>visibility_off</v-icon>
                              <v-icon style='display:inline-flex' v-else>visibility</v-icon>
                            </v-btn>
                          </div>
                          <div style='position:absolute;right:0px;bottom:0px;display:flex;align-items:center'>
                            <span class='display-2' v-if='showStudent'>{{ grilledStudents2[index] ?
                              grilledStudents2[index].text : '' }}</span>
                            <v-btn icon @click='showStudent = !showStudent'>
                              <v-icon large color='error' dark style='display:inline-flex'
                                v-if='showStudent'>visibility_off</v-icon>
                              <v-icon large color='primary' style='display:inline-flex' v-else>person</v-icon>
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-flex>
            </v-layout>
            <v-layout style='display:flex;justify-content:center;align-content:center;flex-wrap:wrap;margin-top:114px'
              row wrap v-else-if='secondSet'>
              <v-flex class='pa-0' xs6>
                <v-layout class='pb-5' row wrap style='background-color:rgba(0,0,255,0.1);'>
                  <span class='insetTextBlue display-1'>
                    Question set 1
                  </span>
                  <v-flex xs12 class='pa-2 text-xs-center' v-for='(question, index) in questionList1'
                    :key="`q-${index}`">
                    <component :is="`display-${question.type}`"
                      style='display:block;max-width:600px;margin:auto;' :questionNb='index+1'
                      :question='question.forTex' :questionFormated='question.formated' :options='question.options'
                      :forceAnswerDisplay='answers[index]' :active='true'>
                    </component>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex class='pa-0' xs6>
                <v-layout class='pb-5' row wrap style='background-color:rgba(255,0,0,0.1)'>
                  <span class='insetTextRed display-1'>
                    Question set 2
                  </span>
                  <v-flex xs12 class='pa-2 text-xs-center' v-for='(question, index) in questionList2'
                    :key="`q-${index}`">
                    <component :is="`display-${question.type}`"
                      style='display:block;max-width:600px;margin:auto;' :questionNb='index+21'
                      :question='question.forTex' :questionFormated='question.formated' :options='question.options'
                      :forceAnswerDisplay='answers[index]' :active='true'>
                    </component>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout style='display:flex;justify-content:center;align-content:center;flex-wrap:wrap;margin-top:114px'
              row wrap v-else-if='questionList1.length <= 5'>
              <v-flex xs12 class='text-xs-center my-3' v-for='(question, index) in questionList1' :key="`q-${index}`">
                <component :is="`display-${question.type}`"
                  style='display:block;max-width:600px;margin:auto;' :questionNb='index+1'
                  :question='question.forTex' :questionFormated='question.formated' :options='question.options'
                  :forceAnswerDisplay='answers[index]' :active='true'>
                </component>
              </v-flex>
            </v-layout>
            <v-layout style='display:flex;justify-content:center;align-content:center;flex-wrap:wrap;margin-top:114px'
              row wrap v-else>
              <v-flex xs12 md5 class='ma-2' v-for='(question, index) in questionList1' :key="`q-${index}`">
                <component :is="`display-${question.type}`"
                  style='display:block;max-width:600px;margin:auto;' :questionNb='index+1'
                  :question='question.forTex' :questionFormated='question.formated' :options='question.options'
                  :forceAnswerDisplay='answers[index]' :active='true'>
                </component>
              </v-flex>
            </v-layout>
          </v-window-item>
          <v-window-item key='global-2'>
            <v-layout row wrap style="position:fixed;top:50%;left:50%;width:600px;margin-left:-300px;margin-top: -250px"
              class="text-xs-center display-2 font-weight-thin">
              <v-flex xs12>
                Practice Finished
              </v-flex>
              <v-flex xs12>
                <v-btn color='primary' large @click='showCorrection'>
                  Show correction
                </v-btn>
              </v-flex>
            </v-layout>
          </v-window-item>
          <v-window-item key='global-3'>
            <v-layout style='display:flex;justify-content:center;align-content:center;flex-wrap:wrap;margin-top:64px'
              class='pb-3' row wrap v-if='secondSet'>
              <v-flex class='pa-0' xs6>
                <v-layout class='pb-5' row wrap style='background-color:rgba(0,0,255,0.1);'>
                  <span class='insetTextBlue display-1'>
                    Question set 1
                  </span>
                  <v-flex xs12 class='pa-2 text-xs-center' v-for='(question, index) in questionList1'
                    :key="`q-${index}`">
                    <component :is="`display-${question.type}`"
                      style='display:block;max-width:600px;margin:auto;' :questionNb='index+1'
                      :question='question.forTex' :questionFormated='question.formated' :options='question.options'
                      :forceAnswerDisplay='question.answer' :active='true'>
                    </component>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex class='pa-0' xs6>
                <v-layout class='pb-5' row wrap style='background-color:rgba(255,0,0,0.1)'>
                  <span class='insetTextRed display-1'>
                    Question set 2
                  </span>
                  <v-flex xs12 class='pa-2 text-xs-center' v-for='(question, index) in questionList2'
                    :key="`q-${index}`">
                    <component :is="`display-${question.type}`"
                      style='display:block;max-width:600px;margin:auto;' :questionNb='index+21'
                      :question='question.forTex' :questionFormated='question.formated' :options='question.options'
                      :forceAnswerDisplay='question.answer' :active='true'>
                    </component>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout class='pb-5'
              style='display:flex;justify-content:center;align-content:center;flex-wrap:wrap;margin-top:64px' row wrap
              v-else-if='questionList1.length <= 5'>
              <v-flex xs12 class='text-xs-center my-3' v-for='(question, index) in questionList1' :key="`q-${index}`">
                <component :is="`display-${question.type}`"
                  style='display:block;max-width:600px;margin:auto;' :questionNb='index+1'
                  :question='question.forTex' :questionFormated='question.formated' :options='question.options'
                  :forceAnswerDisplay='question.answer' :active='true'>
                </component>
              </v-flex>
            </v-layout>
            <v-layout class='pb-5'
              style='display:flex;justify-content:center;align-content:center;flex-wrap:wrap;margin-top:64px' row wrap
              v-else>
              <v-flex xs12 md5 class='ma-2' v-for='(question, index) in questionList1' :key="`q-${index}`">
                <component :is="`display-${question.type}`"
                  style='display:block;max-width:600px;margin:auto;' :questionNb='index+1'
                  :question='question.forTex' :questionFormated='question.formated' :options='question.options'
                  :forceAnswerDisplay='question.answer' :active='true'>
                </component>
              </v-flex>
            </v-layout>
            <v-card class='pa-2' style='position:fixed;bottom:0px;right:20px;width:400px;' v-if='showStudentsCard'>
              <v-card-title class='pa-1 headline'>
                <span>
                  Students picked
                </span>
                <v-spacer />
                <v-btn icon @click='showGrilled = !showGrilled'>
                  <v-icon v-if='showGrilled'>expand_more</v-icon>
                  <v-icon v-else>expand_less</v-icon>
                </v-btn>
              </v-card-title>
              <div v-if='showGrilled'>
                <v-chip close v-for='student in allGrilled' :key='student.value'
                  @input='removeFromGrilled(student.value)'>
                  {{ student.text }}
                </v-chip>
                <v-btn color='primary' block class='mx-1 mb-1 mt-3' @click='removeStudentsForNext'>
                  Remove for next practice
                </v-btn>
              </div>
            </v-card>
          </v-window-item>
        </v-window>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
  import trick from '../../tricks';

  export default {
    props : {},
    data : function(){
      return {
        classPracticeDialog: true,
        globalState: 0,
        initialCountdownDisplay: '',
        initialCountdown: 0,
        running: true,
        questionList1: [],
        questionList2: [],
        qnb: 1,
        answers: [[], [], [], [], [], [], [], [], [], []],
        showAnswer: [false, false],
        showStudent: false,
        autoCorr: false,
        autoStart: false,
        autoScroll: false,
        secondSet: false,
        times: [],
        tricks: [],
        countdown: null,
        buildUpTimer: null,
        internalTime: 1000,
        timeRatio: [],
        timerColor: 'success',
        displayQuestions: 'One by one',
        students: [],
        grilledStudents: [],
        grilledStudents2: [],
        allGrilled: [],
        showGrilled: true,
        showStudentsCard: true
      }
    },
    mounted() {
      this.displayQuestions = this.$root.options.displayQuestions;
      this.autoCorr = this.$root.options.autoEnd;
      this.autoStart = this.$root.options.autoStart;
      this.autoScroll = this.$root.options.autoScroll;
      this.secondSet = this.$root.options.secondSet;
      this.students = [...this.$root.options.students];
      this.showStudent = this.$root.options.showStudent;
      this.studentsIndex = -1;
      let qList1 = [];
      let qList2 = [];
      let addingToList1 = true;
      for(let i=0 ; i < this.$root.tricks.length ; i++) {
        let t = this.$root.tricks[i];
        let trickOptions = typeof t.options == 'string' ? JSON.parse(t.options) : t.options;
        let currentTrick = new trick(t.title, t.image_description, t.text_description.split('\n').join('<br>'), t.type, JSON.parse(t.formula), trickOptions);
        let newQuestions = currentTrick.displayQuestions(t.nb*2);
        newQuestions.forEach(question => {
          question.type = currentTrick.type;
          let optns = {relative: currentTrick.options.relative}
          let answer;
          if(currentTrick.type == 'classic'){
            answer = String(currentTrick.getAnswer(question.raw))
          }
          if(currentTrick.type == 'power'){
            answer = currentTrick.getAnswer(question.raw, question.answer);
            optns.command = currentTrick.formula.command;
            optns.base = question.answer.base;
          }
          if(currentTrick.type == 'fraction'){
            answer = currentTrick.getAnswer(question.raw);
            optns.simplify = currentTrick.formula.simplification;
          }
          if(currentTrick.type == 'algebra'){
            answer = question.coeffs
            optns.longQuestion = question.raw.length > 20;
            optns.command = currentTrick.formula.command;
            optns.nbOfSolutions = question.nbOfSolutions;
            optns.startingDegree = currentTrick.formula.startingDegree;
          }
          question.options = optns;
          question.answer = answer;
          if(addingToList1){
            qList1.push({hiddenAnswer: [], ...question});
            addingToList1 = false;
          } else {
            qList2.push({hiddenAnswer: [], ...question});
            addingToList1 = true;
          }
          this.times.push({ratio: 100, time: Math.max(3, currentTrick.options.time/3 + this.$root.options.extraTime)});
          this.tricks.push(currentTrick);
        });
        if(this.displayQuestions == 'All at once'){
          let totalTime = this.times.reduce((acc, val) => acc + val.time, 0);
          this.times[0].time = Math.floor(totalTime/2);
        }
      }
      this.questionList1 = _.shuffle(qList1);
      this.questionList2 = _.shuffle(qList2);
      if(this.autoStart){
        this.startPractice();
      }
    },
    methods: {
      startPractice(){
        this.autoStart = true;
        this.qnb = this.questionList1.length;
        let vm = this;
        this.buildUpTimer = setInterval(() => {
          vm.initialCountdown += 5;
          if(vm.initialCountdown >= 100){
            clearInterval(this.buildUpTimer);
            vm.initialCountdownDisplay = 3;
            let initTimer = setInterval(() => {
              vm.initialCountdown -= 33;
              vm.initialCountdownDisplay -= 1;
              if(vm.initialCountdown <= 1){
                vm.initialCountdownDisplay = 'GO';
                clearInterval(initTimer);
                setTimeout(() => {
                  vm.globalState = 1;
                  vm.qnb = 1;
                  vm.startQuestion();
                }, 500);
              }
            }, 1000);
          }
        }, 10);
      },
      restartPractice(){
        clearInterval(this.countdown);
        clearInterval(this.buildUpTimer);
        this.globalState = 0;
        this.times.forEach(time => time.ratio = 100);
        this.initialCountdown = 0;
        this.running = true;
        this.startPractice();
      },
      startQuestion(){
        if(this.qnb > this.grilledStudents.length){
          if(this.students.length){
            this.grilledStudents.push(this.students.shift());
          } else {
            this.grilledStudents.push({value: 0, text: ''});
          }
          if(this.secondSet){
            if(this.students.length){
              this.grilledStudents2.push(this.students.shift());
            } else {
              this.grilledStudents2.push({value: 0, text: ''});
            }
          }
          eventBus.$emit('adjustDisplayFontSize', {});
        }
        this.showAnswer = [false, false];
        this.countdown = setInterval(() => {
          if(this.running){
            this.times[this.qnb-1].ratio -= 10/this.times[this.qnb-1].time;
          }
          if(this.autoScroll){
            let windowHeight = window.innerHeight - 112;
            let cardHeight = this.$refs.practiceCard.clientHeight;
            if(cardHeight > windowHeight){
              let top = (cardHeight - windowHeight - 100)*(100 - this.times[this.qnb-1].ratio)/100
              let mainDialog = document.querySelector("#mainDialog");
              mainDialog.scrollTo({ top, behavior: 'smooth' });
            }
          }
          if (this.times[this.qnb-1].ratio >= 75){
            this.timerColor = 'success';
          } else if (this.times[this.qnb-1].ratio >= 50){
            this.timerColor = 'primary';
          } else if (this.times[this.qnb-1].ratio >= 25){
            this.timerColor = 'warning';
          } else {
            this.timerColor = 'error';
          }
          if(this.times[this.qnb-1].ratio <= 0){
            clearInterval(this.countdown);
            setTimeout(() => {
              if(this.qnb < this.questionList1.length && this.displayQuestions == 'One by one'){
                this.qnb++;
                this.startQuestion();
              } else {
                this.finishPractice();
              }
            }, 200);
          }
        }, 100);
        setTimeout(()=> {
          eventBus.$emit('adjustDisplayFontSize', this.adjustFontSize);
        }, 200);
      },
      timeForward(){
        this.times[this.qnb-1].ratio -= 100/this.times[this.qnb-1].time;
      },
      timeBack(){
        if(this.displayQuestions == 'One by one'){
          this.times[this.qnb-1].ratio = 100;
        } else {
          this.times[this.qnb-1].ratio += 100/this.times[this.qnb-1].time;

        }
      },
      revealAnswer(list, index){
        if(list == 1){
          this.showAnswer[0] = ! this.showAnswer[0]
          let temp = this.questionList1;
          this.questionList1 = [];
          temp.forEach((q, i) => {
            if(i == index){
              q.hiddenAnswer = this.showAnswer[0] ? q.answer : [];
            }
            this.questionList1.push(q);
          });
        } else {
          this.showAnswer[1] = ! this.showAnswer[1]
          let temp = this.questionList2;
          this.questionList2 = [];
          temp.forEach((q, i) => {
            if(i == index){
              q.hiddenAnswer = this.showAnswer[1] ? q.answer : [];
            }
            this.questionList2.push(q);
          });
        }
      },
      previousQuestion(){
        clearInterval(this.countdown);
        if(this.displayQuestions == 'One by one'){
          this.times[this.qnb-1].ratio = 100;
          if(this.qnb > 1){
            this.times[this.qnb-2].ratio = 100;
            this.qnb--;
          }
        } else {
          this.times[this.qnb-1].ratio = 100;
        }
        this.startQuestion();
      },
      nextQuestion(){
        if(this.displayQuestions == 'One by one'){
          clearInterval(this.countdown);
          if(this.qnb < this.questionList1.length){
            this.qnb++;
            this.startQuestion();
          } else {
            this.finishPractice();
          }
        } else {
          this.times[this.qnb-1].ratio -= 1000/this.times[this.qnb-1].time;
        }
      },
      showCorrection(){
        this.globalState = 3;
        setTimeout(()=> {
          eventBus.$emit('adjustDisplayFontSize', this.adjustFontSize);
        }, 200);
      },
      finishPractice(){
        clearInterval(this.countdown);
        this.allGrilled = [...this.grilledStudents, ...this.grilledStudents2].filter(s => s.value != 0);
        this.running = true;
        if(this.autoCorr){
          this.globalState = 3;
          setTimeout(()=> {
            eventBus.$emit('adjustDisplayFontSize', this.adjustFontSize);
          }, 200);
        } else {
          this.globalState = 2;
        }
      },
      removeFromGrilled(id){
        let temp = this.allGrilled;
        this.allGrilled = [];
        temp.forEach(s => {
          if(s.value != id){
            this.allGrilled.push(s);
          }
        });
      },
      removeStudentsForNext(){
        eventBus.$emit('grilledStudents', this.allGrilled);
        this.showStudentsCard = false;
      },
      cancelPractice(){
        clearInterval(this.countdown);
        this.$root.trickId = 0;
      }
    }
  }
</script>

<style scoped>
  .insetTextBlue {
    font-weight: bold;
    background-color: rgba(0,0,255,0.3);
    color: transparent;
    text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
  }
  .insetTextRed {
    font-weight: bold;
    background-color: rgba(255,0,0,0.3);
    color: transparent;
    text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
  }
</style>
