<template>
  <div style='height:100%;display:flex;align-items:center;'>
    <count-display-mobile class='mr-3' v-if='$vuetify.breakpoint.name == "xs"' />
    <count-display class='mr-3' v-else />
    <user-notifications class='mr-3 mt-1'></user-notifications>
    <announcements-display />
    <v-menu offset-y left origin v-model="profileMenu">
      <v-btn block flat slot="activator">
        <span v-if='$vuetify.breakpoint.name != "xs"' :style="`color:${fontColor}`">
          {{ $root.user.name }}
        </span>
        <v-icon>arrow_drop_down</v-icon>
      </v-btn>
      <v-list style='display:flex;flex-direction:column'>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-flex class='mr-2 mb-2 headline text-xs-right' v-on="on">
              {{ coinStack }}<v-icon medium style='display:inline' class='ml-1'>radio_button_checked</v-icon>
            </v-flex>
          </template>
          <span><b>Coin stack</b><br>Get coins by trying tricks and use them to purchase more challenges</span>
        </v-tooltip>
        <v-divider></v-divider>
        <v-dialog width="550" v-model="profileDialog">
          <template v-slot:activator="{ on }">
            <v-list-tile v-on="on">
              <v-list-tile-avatar><v-icon>face</v-icon></v-list-tile-avatar>
              <v-list-tile-content>Edit Profile</v-list-tile-content>
            </v-list-tile>
          </template>
          <v-card>
            <v-card-title class="title">
              Edit profile
            </v-card-title>
            <v-card-text>
              <v-tabs v-model="profileTabs" fixed-tabs>
                <v-tab>
                  Edit name
                </v-tab>
                <v-tab>
                  Change password
                </v-tab>
                <v-tab>
                  Delete profile
                </v-tab>
                <v-tab-item :key="0" class="fixedHeight">
                  <v-text-field label="Name" v-model='formUserName' :rules="nameRules" max="30"/>
                </v-tab-item>
                <v-tab-item :key="1" class="fixedHeight">
                  <div style="width:100%">
                    <v-text-field label="Current Password" type="password" v-model='currentPassword' clearable />
                    <v-text-field label="New Password" type="password" v-model='password' :rules="passwordRules"
                      clearable />
                    <v-text-field label="Confirm New Password" type="password" v-model='confirmPassword'
                      :rules="confirmRules" clearable />
                  </div>
                </v-tab-item>
                <v-tab-item :key="2" class="fixedHeight">
                  <div style="width:100%">
                    <v-text-field label="Password" type="password" v-model='deletePassword' clearable />
                    <div style="width:100%;display:flex;justify-content:center;">
                      <v-dialog width='350px' v-model="deleteDialog">
                        <template v-slot:activator="{ on }">
                          <v-btn style="width:200px" color="error" dark v-on="on">Delete account</v-btn>
                        </template>
                        <v-card>
                          <v-icon color='error' x-large style='position:absolute;right:5px;top:5px'>
                            warning
                          </v-icon>
                          <v-card-title class='headline font-weight-bold'>
                            Delete Account
                          </v-card-title>
                          <v-card-text class='pt-0'>
                            Are you sure you want to delete this account ?<br>
                            You will not be able to access it and will loose all your progress...
                            <v-checkbox v-model="unlockDeleteOne"
                              label="I understand that this action is permanent, irreversible and cannot be undone." />
                            <v-checkbox v-model="unlockDeleteTwo"
                              label="I understand that I will loose all progress on this account and will never be able to get it back." />
                            <v-checkbox v-model="unlockDeleteThree"
                              label="I understand that all collectibles and canvas will be permanently lost." />
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn style="width:120px" color="primary" flat @click="cancelDeleteDialog">
                              Cancel
                            </v-btn>
                            <v-btn style="width:120px" color="error" :disabled='!unlockDeleteOne && !unlockDeleteTwo && !unlockDeleteThree' @click="confirmDelete">
                              Delete
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn flat color="error" :disabled="profileLoading" width="180px" @click="cancelEditProfile">
                Cancel
              </v-btn>
              <v-btn :loading="profileLoading" :disabled="profileTabs == 2" width="180px" color="primary"
                @click="submitEditProfile">
                {{ editSubmitText }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-list-tile @click='seeStats'>
          <v-list-tile-avatar><v-icon>bar_chart</v-icon></v-list-tile-avatar>
          <v-list-tile-content>See stats</v-list-tile-content>
        </v-list-tile>
        <v-list-tile @click='seeHistory'>
          <v-list-tile-avatar><v-icon>list</v-icon></v-list-tile-avatar>
          <v-list-tile-content>See history</v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile @click="logoutFromApp">
          <v-list-tile-avatar><v-icon>power_settings_new</v-icon></v-list-tile-avatar>
          <v-list-tile-content>Log out</v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-menu>
    <user-stats></user-stats>
    <history-tables></history-tables>
  </div>
</template>
<script>
  export default {
    props : { 'user': String },
    data : function(){
      return {
        coinStack: 0,
        profileDialog: false,
        deleteDialog: false,
        unlockDeleteOne: false,
        unlockDeleteTwo: false,
        unlockDeleteThree: false,
        profileMenu: false,
        profileTabs: 0,
        profileLoading: false,
        // validProfileForm: true,
        formUserName: '',
        formUserEmail: '',
        deletePassword: '',
        currentPassword: '',
        password: '',
        confirmPassword: '',
        nameRules: [
          v => !!v || 'The name is required',
          v => (v && v.length >= 3) || 'Your name must be at least 3 characters long',
          v => (v && v.length <= 30) || 'Your name must be at most 30 characters long'
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid'
        ]
      }
    },
    computed:{
      passwordRules: function(){
        if(this.password.length > 0 || this.confirmPassword.length > 0){
          return [
            v => !!v || 'Confirm your password',
            v => (v && v.length >= 6) || 'password must be at least 6 characters long'
          ];
        } else {
          return [];
        }
      },
      confirmRules: function(){
        if(this.password.length > 0 || this.confirmPassword.length > 0){
          return [v => (!!v && v === this.password) || 'Passwords do not match'];
        } else {
          return [];
        }
      },
      fontColor(){
        return this.$root.toolbarColors && this.$root.toolbarColors.font_color ? this.$root.toolbarColors.font_color : 'black';
      },
      editSubmitText(){
        let text = 'Submit'
        switch (this.profileTabs){
          case 0:
            text +=  ' name';
            break;
          case 1:
            text +=  ' password';
            break;
        }
        return text;
      }
    },
    watch: {
      deleteDialog(val){
        this.unlockDeleteOne = false;
        this.unlockDeleteTwo = false;
        this.unlockDeleteThree = false;
      }
    },
    created() {
      this.coinStack = this.$root.user.coin_stack;
      let vm = this;
      window.addEventListener('scroll', this.handleScroll);
      eventBus.$on('coinStackUpdate', this.updateCoinStack);

    },
    destroyed() {
      eventBus.$off('coinStackUpdate', this.updateCoinStack);
      window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
      this.formUserName = this.$root.user.name;
      this.formUserEmail = this.$root.user.email;
    },
    methods : {
      logoutFromApp(){
        axios.post('/logout', {})
        .then(function (response) {
          window.location.replace('/');
        })
        .catch(function (error) {
          window.location.replace('/');
          // TODO catch errors ?
        });
      },
      seeStats(){
        eventBus.$emit('showStats', {userId: this.$root.user.id, classId: 0, isTeacher: this.$root.status == 'teacher'});
      },
      seeHistory(){
        eventBus.$emit('showHistory', {userId: this.$root.user.id, classId: 0, isTeacher: this.$root.status == 'teacher'});
      },
      openEditProfile(){
        this.profileDialog = true;
      },
      submitEditProfile(){
        switch(this.profileTabs){
          case 0: 
            if (this.formUserName.length >= 3 && this.formUserName.length <= 30){
              this.profileLoading = true;
              axios.patch('/api/profile/name', {name: this.formUserName})
                .then(resp => {
                  console.log(resp.data);
                  this.$root.snackSuccess('Your name has been updated');
                  this.$root.user.name = resp.data;
                  this.profileLoading = false;
                }).catch(err => console.log(err.response));
            } else {
              this.$root.snackWarning('Your name is too short or too long');
            }
          break;
          case 1:
            console.log('password');
            if(this.password.length < 6){
              this.$root.snackWarning('Your password is too short');
            } else if (this.password.length > 100) {
              this.$root.snackWarning('Your password is too long');
            } else if(this.password != this.confirmPassword){
              this.$root.snackWarning('Mismatch password confirmation');
            } else {
              axios.patch('/api/profile/password', { 
                  currentPassword: this.currentPassword, 
                  password: this.password, 
                  password_confirmation: this.confirmPassword 
                }).then(resp => {
                  console.log(resp.data);
                  this.$root.snackSuccess('Your password has been updated');
                  this.profileLoading = false;
                }).catch(err => {
                  console.log(err.response);
                  this.$root.snackError(err.response.data);
                });
            }
        }
      },
      cancelDeleteDialog(){
        this.deleteDialog = false;
        this.unlockDeleteOne = false;
        this.unlockDeleteTwo = false;
        this.unlockDeleteThree = false;
      },
      cancelEditProfile(){
        this.profileDialog = false;
        this.profileMenu = false;
      },
      confirmDelete(){
        let vm = this;
        axios.post(`/api/profile/archive`, { password: this.deletePassword })
        .then(response => {
          console.log(response.data);
          vm.logoutFromApp();
        })
        .catch(error =>{
          console.log(error);
          vm.$root.snackError(error.response.data);
        })
      },
      handleScroll(){
        this.profileMenu = false;
      },
      updateCoinStack(data){
        this.coinStack = data.newAmount;
        this.$root.user.coin_stack = data.newAmount;
      }
    }
  }
</script>

<style scoped>
  .fixedHeight {
    min-height: 250px;
    max-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
  }
</style>
