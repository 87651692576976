<template>
  <v-expansion-panel v-model='panel'>
    <v-expansion-panel-content>
      <template v-slot:header>
        <div class='headline grey--text'>Archived classes</div>
      </template>
      <template v-slot:actions>
        <v-progress-circular :size="25" :width="2" color="primary" indeterminate v-if='loading'/>
        <v-icon color="grey" v-else>$vuetify.icons.expand</v-icon>
      </template>
      <v-card class='px-4 pt-3'>
        <v-data-table disable-initial-sort :headers="headers" :items="classes">
          <template v-slot:items="props">
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.nbStudents }}</td>
            <td>{{ props.item.nbRaces }}</td>
            <td>{{ props.item.archivedFormated }}</td>
            <td>
              <v-btn icon @click='prepareUnarchive(props.item.id)'>
                <v-icon>refresh</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn icon @click='prepareDelete(props.item.id)'>
                <v-icon color='error'>delete_forever</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
        <div class='caption grey--text text-xs-center'>
          Archived classes will be deleted after 18 months of inactivity.
        </div>
      </v-card>
    </v-expansion-panel-content>
    <v-dialog v-model="unarchiveDialog" width="320px">
      <v-card>
        <v-card-title class="headline">Unarchive {{ unarchiveClass.name }}</v-card-title>
        <v-card-text>Are you sure you want to pull this class out of the archive ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" style='width:120px' :disabled='loading' flat @click="unarchiveDialog=false">No</v-btn>
          <v-btn color="error" style='width:120px' :loading='loading' @click="proceedUnarchive">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="320px">
      <v-card>
        <v-card-title class="headline">Delete {{ unarchiveClass.name }}</v-card-title>
        <v-card-text>
          <div>
            Are you sure you want to <b>permanently delete</b> this class ? All races will be deleted and it will be removed from any hike.
          </div>
          <v-checkbox v-model='confirmDelete' label='I want to delete this class, as well as all its races and links to hikes.'/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" style='width:120px' :disabled='loading' flat @click="deleteDialog=false">Cancel</v-btn>
          <v-btn color="error" style='width:120px' :loading='loading' :disabled='!confirmDelete' @click="proceedDelete">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expansion-panel>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        panel: null,
        loading: false,
        unarchiveDialog: false,
        unarchiveClass: {id: 0, name: ''},
        deleteDialog: false,
        confirmDelete: false,
        deleteClass: {id: 0, name: ''},
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Nb of students', value: 'nbStudents' },
          { text: 'Nb of races', value: 'nbRaces' },
          { text: 'Archived', value: 'archived' },
          { text: 'Bring back', value: '', sortable: false },
          { text: 'Delete', value: '', sortable: false},
        ],
        classes: [],
        loading: false
      }
    },
    watch:{
      panel(val){
        if(val == 0){
          this.loadClasses();
        }
      }
    },
    mounted() {
      eventBus.$on('newClassArchived', this.loadClasses);
    },
    beforeDestroy(){
      eventBus.$off('newClassArchived', this.loadClasses);
    },
    methods: {
      loadClasses(){
        this.loading = true;
        axios.get('/api/classes/archived', {})
        .then(resp => {
          this.classes = resp.data;
          this.loading = false;
        })
        .catch(err => console.log(err));
      },
      prepareUnarchive(id){
        this.unarchiveClass = this.classes.find(cl => cl.id == id);
        this.unarchiveDialog = true;
      },
      proceedUnarchive(){
        this.loading = true;
        axios.put(`/api/classes/${this.unarchiveClass.id}/unarchive`, {})
        .then(resp => {
          eventBus.$emit('refreshClassesData', {});
          let temp = this.classes;
          this.classes = [];
          temp.forEach(cl => {
            if(cl.id != resp.data.id){
              this.classes.push(cl)
            }
          });
          this.unarchiveDialog = false;
          this.loading = false;
        })
        .catch(err => console.log(err));
      },
      prepareDelete(id){
        this.deleteClass = this.classes.find(cl => cl.id == id);
        this.confirmDelete = false;
        this.deleteDialog = true;
      },
      proceedDelete(){
        this.loading = true;
        axios.delete(`/api/classes/${this.deleteClass.id}`, {})
        .then(resp => {
          this.$root.snackSuccess('Class deleted');
          this.classes = this.classes.filter(cl => cl.id != this.deleteClass.id);
          this.confirmDelete = false;
          this.deleteDialog = false;
          this.loading = false;
        }).catch(err => console.log(err));
      }
    }
  }
</script>
