<template>
  <v-layout row wrap v-if="hikePerfs.length == 0">
    <v-flex xs12 class="grey--text title">Star count performances</v-flex>
    <three-perfs-display :bestPerfs="bestPerfs"/>
  </v-layout>
  <v-layout style='height:280px' v-else>
    <v-tabs centered fixed-tabs icons-and-text v-model="activeTab" style='width:100%'>
      <v-tab :key="0" ripple>
        Star count
        <v-icon>star</v-icon>
      </v-tab>
      <v-tab v-for='(hikeTitle, index) in hikeTitles' :key="index+1">
        {{ hikeTitle }}
        <v-icon>directions_walk</v-icon>
      </v-tab>
      <v-tab-item :key="0">
        <three-perfs-display class='mt-2' :bestPerfs="bestPerfs"/>
      </v-tab-item>
      <v-tab-item v-for='(hikePerf, index) in hikePerfs' :key="index+1">
        <three-perfs-display class='mt-2' :bestPerfs="hikePerf" hike/>
      </v-tab-item>
    </v-tabs>
  </v-layout>
</template>
<script>
  export default {
    props : { bestPerfs: Array, hikePerfs: Array, hikeTitles: Array },
    data : function(){
      return {
        activeTab: 1
      }
    }
  }
</script>
