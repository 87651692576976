<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-text-field
        label="Track"
        v-model="trackTitle"
        :rules="forceValidate ? trackRules : []"
        append-icon="search"
        @keyup="userTyping"
        clearable validate-on-blur
        @click:clear='inputCleared'
        @change="trackSelected=false;">
      </v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-expansion-panel :value="openPanel">
        <transition-group style='width:100%' name='bounce'>
          <v-expansion-panel-content v-for="track in tracks" :key="`track-${track.id}`">
            <template v-slot:header>
              <v-flex class="title">{{ track.title }}</v-flex>
            </template>
            <v-card style="min-width:250px">
              <v-layout class="px-3 py-2">
                <v-flex>
                  <v-flex>
                    {{ track.text_description }}
                  </v-flex>
                  <v-flex class="grey--text mt-3">Tricks</v-flex>
                  <v-flex v-if="track.tricks.length == 0" class="text-xs-center">
                    <v-chip>This track has no tricks</v-chip>
                  </v-flex>
                  <v-tooltip bottom v-for="trick in track.tricks" :key="`trick-${trick.id}`">
                    <template v-slot:activator="{ on }">
                      <v-chip label color="primary" text-color="white" v-on="on">
                        {{ trick.title }}
                      </v-chip>
                    </template>
                    <div style="max-width:150px">{{ trick.text_description | truncate }}</div>
                  </v-tooltip>
                </v-flex>
                <v-flex style="width:110px;max-width:110px">
                  <v-layout column fill-height>
                    <v-spacer style='text-align:right'>
                      <v-icon large color="success" v-if="trackSelected">check_circle_outline</v-icon>
                    </v-spacer>
                    <v-btn color="success" @click="selectTrack(track.id)" v-if="!trackSelected" :ripple="false"> {{ multi ? "Add" : "Select" }}</v-btn>
                    <v-btn color="warning" small @click="deselectTrack()" v-if="trackSelected" :ripple="false">Deselect</v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
           </v-card>
          </v-expansion-panel-content>
        </transition-group>
      </v-expansion-panel>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    props: { multi: {type: Boolean, default: false}, forceValidate: {type: Boolean, default: true} },
    data : function(){
      return {
        tracks: [],
        trackTitle: '',
        trackId: null,
        oldTracks: [],
        oldTrackTitle: '',
        trackSelected: false,
        openPanel: 0,
        trackRules: [
          v => !!v || 'You must choose a track'
        ]
      }
    },
    mounted() {
      let vm = this;
      eventBus.$on('editRace', function(race){
        vm.oldTracks = [];
        vm.oldTrackTitle = '';
        let track = race.track;
        track.tricks = race.track.tricks;
        vm.tracks = [track];
        vm.trackTitle = race.track.title;
        vm.trackId = race.track.id;
        vm.trackSelected = true;
        vm.openPanel = 0;
      });

      eventBus.$on('raceAdded', function(race){
        vm.clearAll();
      });
      eventBus.$on('raceUpdated', function(race){
        vm.clearAll();
      });
      eventBus.$on('raceUpdateCanceled', function(race){
        vm.clearAll();
      });
      eventBus.$on('clearTrackSearch', vm.clearAll);
    },
    filters: {
      truncate(value){
        if(value.length < 80){
          return value;
        } else {
          return value.substr(0,77)+'...'
        }
      }
    },
    methods : {
      userTyping(){
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this.searchTracks, 500);
      },
      searchTracks(){
        if (this.trackTitle.length > 0){
          let vm = this
          axios.get(`/api/tracks/search/${this.trackTitle}`, {})
          .then(function (response) {
            vm.openPanel = null;
            vm.tracks = [];
            response.data.forEach(function(track){
              vm.tracks.push(track);
            });
          })
          .catch(function (error) {
            console.log(error) // TODO fix this
          });
        }
      },
      selectTrack(id){
        let currentTrack = _.find(this.tracks, function(o){
          return o.id == id;
        });
        this.oldTracks = this.tracks;
        this.oldTrackTitle = this.trackTitle;
        this.trackId = id;
        eventBus.$emit('trackSelected', {id: id, nbOfTricks: currentTrack.tricks.length, track: currentTrack});
        if(this.multi){
          this.tracks = [];
          this.oldTracks.forEach(track => {
            if(track.id != currentTrack.id){
              this.tracks.push(track);
            }
          });
        } else {
          this.trackSelected = true;
          this.tracks = [currentTrack];
          this.trackTitle = currentTrack.title;
        }
      },
      deselectTrack(){
        this.tracks = this.oldTracks;
        this.trackTitle = this.oldTrackTitle;
        this.trackSelected = false;
        eventBus.$emit('trackSelected', {id: 0, nbOfTricks: 0});
      },
      inputCleared(){
        this.tracks = [];
      },
      clearAll(){
        this.oldTracks = [];
        this.oldTrackTitle = '';
        this.deselectTrack();
      }
    }
  }
</script>

<style scoped>
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
