<template>
  <v-hover :disabled='validated'>
    <v-card
      slot-scope="{ hover }"
      :class="`elevation-${hover ? 12 : 0}`"
      :color="bgColor"
      class="white--text text-xs-center pa-1 ma-1"
      style="display:inline-block;vertical-align:top;cursor:pointer"
      height="50" width="140"
      @click='seeMore'>
      <v-layout fill-height column>
        <v-spacer></v-spacer>
        <v-flex>
          <div class="body-2 text-truncate">{{ trick.title }}</div>
          <v-rating v-model="trick.rating" size="12" color="orange" background-color="orange lighten-3" readonly></v-rating>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
    </v-card>
  </v-hover>
</template>
<script>
  export default {
    props : { trick: Object, validation: Number, active: Boolean, options: Object },
    data : function(){
      return {

      }
    },
    computed: {
      bgColor() {
        if(this.validated){
          return 'success'
        } else if (this.active && this.verified) {
          return 'primary'
        } else {
          return 'grey'
        }
      },
      validated(){
        return this.trick.rating >= this.options.trickValidation;
      },
      verified(){
        return this.$root.status == 'student' && (this.options.verificationRequired.length == 0 || this.options.verificationRequired.find(o => o.id == this.$root.perfVerification.teacherId))
      }
    },
    methods : {
      seeMore(){
        eventBus.$emit('focusTrick', this.trick);
      }
    }
  }
</script>
