<template>
  <v-layout row wrap>
    <v-flex xs12 style='position:relative;height:280px;' ref='medalRackWrapper'>
      <div style='background:black;height:80px;margin-top:60px;width:96%;margin-left:2%'>
        <div style='display:flex;justify-content:space-between;height:50px'>
          <div style='height:10px;width:10px;border-radius:50%;background:white;margin:10px;'/>
          <div style='height:100px;width:120px;border-radius:50%;background:black;margin-top:-30px'/>
          <div style='height:10px;width:10px;border-radius:50%;background:white;margin:10px;'/>
          <v-img src="/images/medal rack head.png" width="70px" style='position:absolute;top:-15px;left:50%;margin-left:-35px'/>
        </div>
        <div class='samfont-title text-xs-center white--text' style='margin-top:-50px;display:flex;justify-content:center'>
          <span style='margin-left:47px'>Medal</span>
          <span style='margin-left:100px'>Collection</span>
        </div>
        <div style='width:90%;margin-left:5%;display:flex'>
          <span style='width:20px;height:20px;border-radius:50%;background:white;margin-right:-10px;'/>
          <span style='height:20px;flex:1;background:white'/>
          <span style='width:20px;height:20px;border-radius:50%;background:white;margin-left:-10px;'/>
          </span>
        </div>
      </div>
      <div style='position:relative;margin-top:-25px;margin-left:55px'>
        <v-img
        width='100px'
        style='position:absolute;top:0px;'
        :style='`left:${(k-1)*medalGap}px`'
        :src="`/images/medal bronze.png`"
        v-for='k in rewardsCount.bronze' :key='`bronze-${k}`'
        ></v-img>
        <v-img
        width='100px'
        style='position:absolute;top:0px;'
        :style='`left:${(rewardsCount.bronze+k-1)*medalGap}px`'
        :src="`/images/medal silver.png`"
        v-for='k in rewardsCount.silver' :key='`silver-${k}`'
        ></v-img>
        <v-img
        width='100px'
        style='position:absolute;top:0px;'
        :style='`left:${(rewardsCount.bronze+rewardsCount.silver+k-1)*medalGap}px`'
        :src="`/images/medal gold.png`"
        v-for='k in rewardsCount.gold' :key='`gold-${k}`'
        ></v-img>
      </div>
    </v-flex>
    <v-flex xs12 class='text-xs-center display-1 mt-4'>
      <b>{{ rewardsCount.total }}</b> race<span v-if='rewardsCount.total > 1'>s</span> finished
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    props : {rewardsCount : Object},
    data : function(){
      return {
        divWidth: 600
      }
    },
    computed:{
      medalGap(){
        let totalMedals = this.rewardsCount.gold + this.rewardsCount.silver + this.rewardsCount.bronze;

        return this.divWidth/totalMedals;
      }
    },
    mounted() {
      this.$nextTick(function(){
       window.addEventListener('resize', this.setDivWidth);

       this.setDivWidth();
     });
    },
    beforeDestroy(){
      window.removeEventListener('resize', this.setDivWidth);
    },
    methods: {
      setDivWidth(){
        this.divWidth = this.$refs['medalRackWrapper'].clientWidth-180;
      }
    }
  }
</script>
