<template>
  <!-- <div ref='outer'> -->
  <div>
    <v-card class='py-3' :style='`font-size:${fontSize}px`' style='height:100px;display:flex;justify-content:center;align-items:center;'>
      <span></span>
      <v-avatar style='position:absolute;top:5px;left:5px' :color="questionNb >= 20 ? 'red' : 'blue'" v-if='questionNb > 0'>
        <span class="white--text headline font-weight-bold">{{ questionNb % 20 }}</span>
      </v-avatar>
      <!-- <div ref='inner'>
        <v-img :src='statusImgName' width='130px' style='position:absolute;right:0px;top:5px' v-if='questionNb == 0'/>
        <span style='white-space:nowrap;' v-html='formatedDisplay'></span>
      </div> -->
      <v-img :src='statusImgName' min-width='130' max-width='130' style="position:absolute;top:5px;right:0px;" v-if='questionNb == 0'/>
      <div ref='outer' class="px-2" style="display:flex;flex-wrap:nowrap;align-items:center;width:100%;">
        <div ref='inner' style='white-space:nowrap;flex:1;overflow-x:visible;display:flex;justify-content:center;align-items:center;'>
          <span style="white-space:nowrap;" v-html='formatedDisplay'/>
        </div>
        <div style="min-width:130px;max-width:130px;"/>
      </div>
    </v-card>
  </div>

</template>
<script>
  import katex from 'katex';

  export default {
    props : { question: String, questionFormated: String, options: Object, active: Boolean, forceAnswerDisplay: [String, Array], questionNb: {type: Number, default: 0} },
    data : function(){
      return {
        orgFontSize: 35,
        fontSize: 35,
        result: ['', ''],
        activeIndex: 0,
        lockIndex: false,
        statusImgName: '',
        imgTimer: null,
        instantReplace: [false, false],
        displayingCorrectAnswer: [false, false],
        answerColor: ['black', 'black'],
        uniqueId: 0,
        formatedAnswerForceUpdate: 0,
        previousResultLength: 0
      }
    },
    computed: {
      formatedDisplay(){
        this.formatedAnswerForceUpdate;
        for(let i=0 ; i <= 1 ; i++){
          if (this.displayingCorrectAnswer[i]){
            this.answerColor[i] = 'green';
          } else if(this.result[i] == '' || this.instantReplace[i]){
            if(i == this.activeIndex){
              this.answerColor[i] = 'grey';
            } else {
              this.answerColor[i] = 'lightgrey';
            }
          } else {
            if(i == this.activeIndex){
              this.answerColor[i] = 'blue';
            } else {
              this.answerColor[i] = 'black';
            }
          }
        }
        if(this.options.command == 'power - give base'){
          this.answerColor[0] = 'black';
        }
        let answer = ['...', '...'];
        if(this.result[0] != ''){
          let answerArray = this.result[0].toString().split(".");
          let intPartArray = answerArray[0].toString().split("").reverse();
          let intPartArrayWithSpaces = [];
          intPartArray.forEach(function(item, index){
            intPartArrayWithSpaces.push(item);
            if (index%3 == 2){
              intPartArrayWithSpaces.push("\\,");
            }
          });
          answer[0] = intPartArrayWithSpaces.reverse().join("").trim('\\,');
          if(answerArray[1]){
            let decPartArray = answerArray[1].toString().split("");
            let decPartArrayWithSpaces = [];
            decPartArray.forEach(function(item, index){
              decPartArrayWithSpaces.push(item);
              if (index%3 == 2){
                decPartArrayWithSpaces.push("\\,");
              }
            });
            answer[0] += '.' + decPartArrayWithSpaces.join("").trim('\\,');
          } else if(this.result[0].toString().indexOf('.') >= 0){
            answer[0] += '.';
          }
        }
        if(this.result[1] != ''){
          let inversedAnswer = this.result[1].toString().split("").reverse();
          let withSpaces = [];
          inversedAnswer.forEach(function(item, index){
            withSpaces.push(item);
            if (index%3 == 2){
              withSpaces.push("\\,");
            }
          });
          answer[1] = withSpaces.reverse().join("").trim('\\,');
        }
        let sizes = this.activeIndex == 0 ? ['\\Large', '\\normalsize'] : ['\\normalsize', '\\Large'];
        if(this.activeIndex == 2){
          sizes = ['\\normalsize', '\\normalsize'];
        }
        switch(this.options.command){
          case 'power':
            return katex.renderToString(
              `${this.question}=\\Large{\\,\\!}${sizes[0]}{\\color{${this.answerColor[0]}}{${answer[0]}}}^{\\color{${this.answerColor[1]}}${sizes[1]}{{${answer[1]}}}}`,
              {throwOnError: false}
            );
          break;
          case 'power - give base':
            return katex.renderToString(
              `${this.question}=\\Large{\\,\\!}${sizes[0]}{\\color{${this.answerColor[0]}}{${answer[0]}}}^{\\color{${this.answerColor[1]}}${sizes[1]}{{${answer[1]}}}}`,
              {throwOnError: false}
            );
          break;
          case 'decimal':
            return katex.renderToString(`${this.question}=\\color{${this.answerColor[0]}}{${answer[0]}}`, {throwOnError: false});
          break;
          case 'scientific':
            return katex.renderToString(
              `${this.question}\\newline=\\Large{\\,\\!}${sizes[0]}{\\color{${this.answerColor[0]}}{${answer[0]}}}\\times{10}^{\\color{${this.answerColor[1]}}${sizes[1]}{{${answer[1]}}}}`,
              {throwOnError: false}
            );
          break;
        }
      }
    },
    watch: {
      forceAnswerDisplay: {
        handler: function(val){
          if(val.length > 0){
            this.result = val;
            this.displayingCorrectAnswer = [true, true];
            this.statusImgName = this.$root.user.images.correct;
            this.activeIndex = 2;
          }
        },
        immediate: true
      },
    },
    created(){
      if(this.forceAnswerDisplay.length > 0){
        this.statusImgName = this.$root.user.images.correct;
      } else {
        this.statusImgName = this.$root.user.images.pending;
      }
      if (window.innerWidth <= 450) {
        this.orgFontSize = 25;
        this.fontSize = 25;
      }
    },
    mounted() {
      if(this.options.command == 'power - give base'){
        this.result[0] = this.options.base;
        this.activeIndex = 1;
        this.lockIndex = true;
      } else if(this.options.command == 'decimal'){
        this.result[1] = 1;
        this.activeIndex = 0;
        this.lockIndex = true;
      }
      eventBus.$on('resultUpdate', this.resultUpdate);
      eventBus.$on('answerChecked', this.validateResult);
      eventBus.$on('endQuestion', this.displayAnswer);
      eventBus.$on('adjustDisplayFontSize', this.adjustFontSize);
      // this.$nextTick(this.adjustFontSize);
    },
    beforeDestroy(){
      eventBus.$off('resultUpdate', this.resultUpdate);
      eventBus.$off('answerChecked', this.validateResult);
      eventBus.$off('endQuestion', this.displayAnswer);
      eventBus.$off('adjustDisplayFontSize', this.adjustFontSize);
    },
    methods: {
      resultUpdate(char){
        if(this.active){
          if(this.instantReplace[this.activeIndex]){
            this.result[this.activeIndex] = '';
            this.instantReplace[this.activeIndex] = false;
          }
          if (char == 'switch' && !this.lockIndex){
            this.activeIndex = (this.activeIndex + 1)%2;
          } else if (char == 'backsp'){
            this.result[this.activeIndex] = this.result[this.activeIndex].substring(0,this.result[this.activeIndex].length-1);
            this.adjustFontSize();
          } else if (char == 'clear'){
            this.result = ['', ''];
            this.adjustFontSize();
          } else if (char == 'submit'){
            eventBus.$emit('submitAnswer', this.result);
          } else if((this.activeIndex == 0 && this.result[0].length < 9) || (this.activeIndex == 1 && this.result[1].length < 4)){
            if (parseInt(char) > 0 || char == '0'){
              this.result[this.activeIndex] += char;
            } else if ((char == '.') && this.activeIndex == 0 && this.result[0].indexOf('.') == -1){
              if(this.result[0] == ''){
                this.result[0] = 0;
              }
              this.result[0] += '.';
            } else if(char == '+/-'){
              if(this.options.relative && this.activeIndex == 0){
                this.result[0] = this.result[0].substr(0, 1) == '-' ? this.result[0].substr(1) : '-'+this.result[0];
              } else if(this.activeIndex == 1){
                this.result[1] = this.result[1].substr(0, 1) == '-' ? this.result[1].substr(1) : '-'+this.result[1];
              }
            }
            this.adjustFontSize();
          }
        }
        this.formatedAnswerForceUpdate++;
      },
      // adjustFontSize(){
      //   let divWidth = this.$refs.outer.clientWidth;
      //   let textWidth = this.$refs.inner.clientWidth;
      //   if(textWidth > 0.75*divWidth){
      //     this.fontSize = Math.floor(this.fontSize*0.75*divWidth/textWidth);
      //   } else if(textWidth < 0.6*divWidth){
      //     this.fontSize = this.orgFontSize;
      //   }
      // },
      adjustFontSize() {
        let divWidth = this.$refs.outer.clientWidth - 146;
        let textWidth = this.$refs.inner.clientWidth;
        if (textWidth > divWidth) {
          const minFontSize = this.questionNb == 0 ? 16 : 32;
          this.fontSize = Math.max(minFontSize, Math.floor(this.fontSize * 0.9));
        } else if (textWidth <= divWidth && Math.max(this.result[0].length, this.result[1].length) < this.previousResultLength) {
          this.fontSize = Math.min(this.orgFontSize, Math.floor(this.fontSize * 1.1));
        }
        // if (Math.max(this.result[0].length, this.result[1].length) == 0) {
        //   this.fontSize = this.orgFontSize;
        // }
        this.previousResultLength = Math.max(this.result[0].length, this.result[1].length);
      },
      validateResult(check){
        if(this.active){
          clearTimeout(this.imgTimer);
          if(check){
            this.statusImgName = this.$root.user.images.correct;
            this.activeIndex = 2;
            this.displayingCorrectAnswer = ['true', 'true'];
          } else {
            this.statusImgName = this.$root.user.images.incorrect;
            this.imgTimer = setTimeout(()=>{
              this.statusImgName = this.$root.user.images.pending;
            }, 800);
            this.instantReplace = [true, true];
          }
        }
      },
      displayAnswer(answer){
        if(this.active){
          this.activeIndex = 2;
          this.displayingCorrectAnswer = ['true', 'true'];
          this.result = answer;
        }
      }
    }
  }
</script>
