<template>
  <v-dialog v-model="tryTrickDialog" persistent no-click-animation fullscreen hide-overlay transition="slide-y-transition">
    <v-card>
      <v-toolbar :style="`background: ${toolbarColor};color:${toolbarFontColor}`" class="hidden-xs-only">
        <v-toolbar-title>{{ trick.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeTryTrick">
          <v-icon :color="toolbarFontColor">close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-dialog v-model='quitDialog' width="400">
        <v-card>
          <v-card-title class='headline pb-0'>
            Quit trick ?
          </v-card-title>
          <v-card-text>
            <div>
              Are you sure you want to quit this trick ? No performance will be recorded.
            </div>
            <div v-for='race in races' :key='race.id'>
              <v-icon color='error' class='mr-1'>warning</v-icon>
              You will suffer a penalty for the race <b>{{ race.title }}</b>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" :disabled='aborting' style='width:125px' flat @click="quitDialog = false">Stay</v-btn>
            <v-btn color="error" :loading='aborting' :ripple='false' style='width:125px' @click="abortTryTrick">Quit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-alert :value="blurAlert" type="error" transition="scale-transition" style='position:fixed;top:48px;width:100%;z-index:100'>
        {{ formatedBlurWarning }}
      </v-alert>
      <v-window v-model="globalState" touchless>
        <v-window-item :key="0">
          <v-layout row wrap style="position:fixed;top:50%;left:50%;width:300px;margin-left:-150px;margin-top: -130px" class="text-xs-center display-4 font-weight-black">
            <v-flex xs12>
              <v-progress-circular
              :indeterminate='loadingTrick'
              :rotate="90"
              :size="250"
              :width="loadingTrick ? 10 : 25"
              :value="initialCountdown"
              :color="loadingTrick ? 'grey lighten-2' : 'primary'"
              >
              {{ initialCountdownDisplay }}
            </v-progress-circular>
            </v-flex>
            <v-flex xs12 class='display-1 grey--text text--lighten-2 text-xs-center' v-if='loadingTrick'>
              Trick loading...
            </v-flex>
          </v-layout>
        </v-window-item>
        <v-window-item :key="1">
          <v-container fill-height grid-list-md>
            <v-btn icon @click="quitDialog = true" class="hidden-sm-and-up" :ripple='false' style="position:absolute;top:0px;right:0px">
              <v-icon>close</v-icon>
            </v-btn>
            <v-layout justify-center row wrap>
              <v-flex xs12>
                <v-stepper v-model="qnb" class="overflow-hidden hidden-xs-only mb-2">
                  <v-stepper-header>
                    <template v-for="n in questionList.length">
                      <v-stepper-step :key="`${n}-step`" :complete="qnb > n" :step="n"></v-stepper-step>
                      <!-- <v-divider v-if="n !== questionList.length" :key="n"></v-divider> -->
                      <v-divider v-if="n !== questionList.length" :key="n"/>
                    </template>
                  </v-stepper-header>
                  <v-stepper-items style="display:none">
                    <v-stepper-content v-for="n in questionList.length" :key="`${n}-content`" :step="n"></v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
                <v-progress-linear v-model="scoreProgress"></v-progress-linear>
              </v-flex>
              <v-flex xs12 md8 lg6 class="text-xs-center pa-3">
                <v-layout class="display-1 my-4 hidden-sm-and-down" justify-space-between align-items-center>
                  <v-flex class="headline font-weight-thin" style="display:flex;align-items:flex-end;justify-content:center">
                    Score : {{ score }}
                  </v-flex>
                  <v-flex class="font-weight-medium">
                    Total : {{ totalScore }}
                  </v-flex>
                </v-layout>
                <v-layout style='position:relative;display:block;max-height:100px;'>
                  <transition-group name='nextQ' >
                    <component :is="displayName"
                      v-for='(question, index) in questionList' :key='`question-${index}`'
                      style='position:absolute;width:100%'
                      :question='question.forTex'
                      :questionFormated='question.formated'
                      :options='displayOptions[index]'
                      :active='index + 1 == qnb && questionRunning'
                      :showing='index + 1 == qnb'
                      :forceAnswerDisplay = '[]'
                      v-if='index + 1 == qnb'
                    >
                    </component>
                  </transition-group>
                </v-layout>
              </v-flex>
              <v-flex xs12 md4 lg6>
                <div class='hidden-md-and-up' :style='`display:block;height:${pushKeypadDown}px`'></div>
                <component :is="keypadName" style="margin:auto"></component>
              </v-flex>
            </v-layout>
          </v-container>
        </v-window-item>
        <v-window-item :key="2">
          <v-container fill-height grid-list-md>
            <div style='position:absolute;top:5px;right:5px'>
              <v-card hover width='300' class='pa-3' v-if="verifiedBy != null" style='display:flex;justify-content:space-between;align-items:center'>
                <span class='text-truncate subheading'>
                  Verified by {{ verifiedBy }}
                </span>
                <v-icon color='success'>verified_user</v-icon>
              </v-card>
              <v-card hover dark width='300' class='pa-2' style='position:relative' color='grey' v-if="timeModifierNotice">
                <v-btn flat icon color="white" :ripple="false" style="position:absolute;top:-10px;right:-10px" @click="timeModifierNotice = false">
                  <v-icon>close</v-icon>
                </v-btn>
                <v-card-title class='pa-1 subheading font-weight-bold'>
                  Extra time
                </v-card-title>
                <v-card-text class='px-1 py-2'>
                  You tried this trick with <b>+{{ $root.timeModifier }}s</b> extra time, your performance will not be added to your star count or appear in this tricks' best scores.
                </v-card-text>
              </v-card>
            </div>
            <v-layout justify-center class="mt-3 text-xs-center" row wrap>
              <v-flex xs12 class="display-2">Trick Finished ! </v-flex>
              <v-flex xs12>Your final score is <b>{{ totalScore }}</b></v-flex>
              <v-flex xs12>
                <v-rating v-model="rating" size="64" color="orange" background-color="orange lighten-3" readonly></v-rating>
              </v-flex>
              <v-flex xs12 md8 class='text-xs-left' :class='{"pr-2": !["xs", "sm"].includes($vuetify.breakpoint.name)}'>
                <span class="grey--text title">Questions</span>
                <answers-breakdown :attempt='attempt' :orgTrick='trick'></answers-breakdown>
              </v-flex>
              <v-flex xs12 md4 class='text-xs-left' :class='{"pl-2": !["xs", "sm"].includes($vuetify.breakpoint.name)}'>
                <span class="grey--text title">Rewards</span>
                <trick-rewards :rewards='debouncedRewards'></trick-rewards>
              </v-flex>
              <v-flex xs12 class='my-4' style='display:flex;justify-content:center;align-items:center'>
                <v-btn large rounded outline :ripple="false" style='width:200px;max-width:40%' color="error" @click="canceltryTrick">Close</v-btn>
                <span style='width:10%;max-width:50px;'></span>
                <v-btn large rounded outline :ripple="false" style='width:200px;max-width:40%' color="primary" @click="retryTrick">Retry </v-btn>
              </v-flex>
              <v-layout class="ma-5 text-xs-left" row wrap>
                <trick-best-perfs :bestPerfs="bestPerfs" :hikePerfs='hikePerfs' :hikeTitles='hikeTitles'/>
              </v-layout>
            </v-layout>
          </v-container>
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>
<script>
  import trick from '../../tricks';

  export default {
    props : {},
    data : function(){
      return {
        tryTrickDialog: true,
        loadingTrick: true,
        sessionId: 0,
        initialCountdown: 0,
        initialCountdownDisplay: '',
        blurAlert: false,
        blurCountdown: 5,
        blurTimer: null,
        globalState: 0,
        displayName: '',
        keypadName: '',
        pushKeypadDown: 100,
        questionList: [],
        trick: {},
        options: {},
        key: 0,
        displayOptions: {},
        activeDisplayId: 0,
        countdown: null,
        questionRunning: false,
        qnb: 1,
        scoreProgress: 100,
        score: 1000,
        internalScore: 1000,
        rating: 0,
        perfs: [],
        bestPerfs: [],
        racesProgress: [],
        rewards: [],
        debouncedRewards: [],
        answers: [],
        studentAnswers: [],
        attempt: {},
        currentStudentAnswers: [],
        starCountUpdate: 0,
        starCountTotal: 0,
        coinStackUpdate: 0,
        coinStackTotal: 0,
        timeModifierNotice: false,
        hikePerfs: [],
        hikeTitles: [],
        hikesProgress: [],
        races: [],
        quitDialog: false,
        aborting: false,
        verifiedBy: null
      }
    },
    computed: {
      totalScore: function(){
        return _.sum(this.perfs);
      },
      formatedBlurWarning(){
        if(this.blurCountdown > 1){
          return `Lost focus. The trick will end in ${this.blurCountdown} seconds`;
        } else if(this.blurCountdown == 1){
          return `Lost focus. The trick will end in ${this.blurCountdown} second`;
        } else {
          return 'This trick has been stopped because the window lost focus';
        }
      },
      toolbarColor() {
        if (this.$root.toolbarColors.activeSet.length == 1) {
          return this.$root.toolbarColors.activeSet[0].color;
        }
        let gradientSteps = '';
        this.$root.toolbarColors.activeSet.forEach(c => {
          gradientSteps += c.color + ' ' + c.position + '%,';
        });
        gradientSteps = gradientSteps.substring(0, gradientSteps.length - 1);

        return `linear-gradient(to right, ${gradientSteps})`;
      },
      toolbarFontColor(){
        return this.$root.toolbarColors && this.$root.toolbarColors.font_color ? this.$root.toolbarColors.font_color : 'black';
      }
    },
    created() {
      let vm = this;
      this.sessionId = this.$root.sessionId;
      axios.post(`/api/tricks/${this.$root.trickId}/attempt`, {timeModifier: this.$root.timeModifier})
      .then(response => {
        eventBus.$emit('tryingTrick', {timeModifier: this.$root.timeModifier, ...response.data.trick});
        vm.races = response.data.races
        vm.key = response.data.key;
        vm.options = JSON.parse(response.data.trick.options);
        vm.trick = new trick(response.data.trick.title, response.data.trick.image_description, response.data.trick.text_description.split('\n').join('<br>'), response.data.trick.type, JSON.parse(response.data.trick.formula), vm.options);
        vm.timePerQ = vm.options.time + vm.$root.timeModifier;
        vm.timeModifierNotice = vm.$root.timeModifier > 0;
        if(vm.trick.type == 'fraction'){
          vm.pushKeypadDown = 120;
        }
        if(vm.trick.type == 'algebra'){
          vm.pushKeypadDown = 150;
        }
        vm.questionList = vm.trick.displayQuestions(10, response.data.questions);
        vm.displayOptions = [];
        vm.questionList.forEach(question => {
          let optns = {relative: vm.options.relative}
          if(vm.trick.type == 'fraction'){
            optns.simplify = vm.trick.formula.simplification;
          }
          if(vm.trick.type == 'power'){
            optns.command = vm.trick.formula.command;
            optns.base = question.answer.base;
          }
          if(vm.trick.type == 'algebra'){
            optns.longQuestion = question.raw.length > 20;
            optns.command = vm.trick.formula.command;
            optns.nbOfSolutions = question.nbOfSolutions;
            optns.startingDegree = vm.trick.formula.startingDegree;
          }
          vm.displayOptions.push(optns);
        });
        vm.loadingTrick = false;
        let buildUpTimer  = setInterval(() => {
          vm.initialCountdown += 5;
          if(vm.initialCountdown >= 100){
            clearInterval(buildUpTimer);
            vm.initialCountdownDisplay = 3;
            let initTimer = setInterval(() => {
              vm.initialCountdown -= 33;
              vm.initialCountdownDisplay -= 1;
              if(vm.initialCountdown <= 1){
                vm.initialCountdownDisplay = 'GO';
                clearInterval(initTimer);
                setTimeout(() => {
                  vm.startTrick();
                }, 500);
              }
            }, 1000);
          }
        }, 10);
        this.qnb = this.questionList.length;
        vm.countdown = null;
        vm.displayName = `display-${vm.trick.type}`;
        vm.keypadName = `keypad-${vm.trick.type}`;

        eventBus.$on('submitAnswer', this.checkStudentAnswer);
        eventBus.$on('displayActive', this.setDisplayId)
        window.addEventListener('blur', this.windowBlurred);
        window.addEventListener('focus', this.windowFocused);
      })
      .catch(error => console.log(error));
    },
    beforeDestroy(){
      clearInterval(this.blurTimer);
      clearInterval(this.countdown);
      eventBus.$off('submitAnswer', this.checkStudentAnswer);
      eventBus.$off('displayActive', this.setDisplayId)
      window.removeEventListener('blur', this.windowBlurred);
      window.removeEventListener('focus', this.windowFocused);
    },
    methods: {
      windowBlurred(){
        if(this.globalState == 1){
          this.blurAlert = true;
          this.blurTimer = setInterval(()=>{
            this.blurCountdown--;
            if(this.blurCountdown <= 0){
              if(this.studentAnswers.length){
                this.finishTrick();
              } else {
                this.abortTryTrick();
              }
            }
          }, 1000)
        }
      },
      windowFocused(){
        if(this.globalState == 1){
          this.blurAlert = false;
          clearInterval(this.blurTimer);
        }
      },
      startTrick(){
        this.globalState = 1;
        this.qnb = 1;
        this.startQuestion();
      },
      startQuestion(){
        this.score = 1000;
        this.internalScore = 1000;
        this.questionRunning = true;
        this.countdown = setInterval(() => {
          this.internalScore -= 100/this.timePerQ;
          this.score = Math.round(this.internalScore, 0);
          if(this.sessionId != this.$root.sessionId){
            // console.log("destroyed")
            clearInterval(this.countdown);
            this.$destroy();
          }
          if (this.score <= 990){
            this.scoreProgress = parseInt(this.score)/10;
          }
          if(this.score <= 0){
            this.finishQuestion(0);
          }
        }, 100)
      },
      checkStudentAnswer(answer){
        let answerIsCorrect;
        if(this.trick.type == 'classic' || this.trick.type == 'power'){
          if (this.trick.checkAnswer(this.questionList[this.qnb-1].raw, answer)){
            eventBus.$emit('answerChecked', true);
            answerIsCorrect = true;
            // this.finishQuestion(this.score);
          } else {
            eventBus.$emit('answerChecked', false);
            answerIsCorrect = false;
          }
        } else if(this.trick.type == 'fraction'){
          let answerCheck = this.trick.checkAnswer(this.questionList[this.qnb-1].raw, answer);
          if(answerCheck.simplified || (answerCheck.value && !this.trick.formula.simplification)){
            eventBus.$emit('answerChecked', true);
            answerIsCorrect = true;
            // this.finishQuestion(this.score);
          } else if(answerCheck.value){
            eventBus.$emit('answerChecked', false);
            eventBus.$emit('needSimplification', {});
            answerIsCorrect = false;
          } else {
            eventBus.$emit('answerChecked', false);
            answerIsCorrect = false;
          }
        } else if(this.trick.type == 'algebra'){
          let validCoeffs = this.trick.checkAnswer(this.questionList[this.qnb-1].coeffs, answer, this.questionList[this.qnb-1].nbOfSolutions);
          if (validCoeffs.every(val => val)){
            eventBus.$emit('answerChecked', {correctAnswer: true, validCoeffs});
            answerIsCorrect = true;
            // this.finishQuestion(this.score);
          } else {
            eventBus.$emit('answerChecked', {correctAnswer: false, validCoeffs});
            answerIsCorrect = false;
          }
        }
        if(answer != ''){
          let a = this.trick.type == 'classic' ? answer : [...answer];
          if(answerIsCorrect){
            this.currentStudentAnswers.push({value: a, score: this.score, correct: true, isCorrection: false});
            this.finishQuestion(this.score);
          } else {
            this.currentStudentAnswers.push({value: a, score: this.score, correct: false, isCorrection: false});
          }
        }
      },
      finishQuestion(score){
        if(this.$root.trickId == 0){
          console.log('***** DELAYED *****')
          console.log(this);
          console.log(this.countdown)
        }

        this.questionRunning = false;
        clearInterval(this.countdown);
        this.perfs.push(score);
        let correction;
        if(score == 0){
          if(this.trick.type == 'classic'){
            correction = String(this.trick.getAnswer(this.questionList[this.qnb-1].raw));
          } else if(this.trick.type == 'power'){
            correction = this.trick.getAnswer(this.questionList[this.qnb-1].raw, this.questionList[this.qnb-1].answer);
          } else if(this.trick.type == 'fraction'){
            correction = this.trick.getAnswer(this.questionList[this.qnb-1].raw);
          } else if(this.trick.type == 'algebra'){
            correction = this.questionList[this.qnb-1].coeffs;
          }
          eventBus.$emit('endQuestion', correction);
          this.currentStudentAnswers.push({value: correction, score: 0, correct: false, isCorrection: true});
        }
        this.studentAnswers.push({question: this.questionList[this.qnb-1].raw, answers: this.currentStudentAnswers});
        this.currentStudentAnswers = [];
        if(this.qnb == this.questionList.length){
          this.finishTrick();
        } else {
          setTimeout(() => {
            this.scoreProgress = 100;
          }, 800);
          setTimeout(() => {
            this.qnb++;
            this.startQuestion();
          }, 1000);
        }
      },
      finishTrick(){
        // console.log(this.studentAnswers);
        if(this.$root.trickId == 0){
          console.log('----------DELAYED----------')
          console.log(this);
          console.log(this.countdown)
        }
        let vm = this;
        if(this.$root.status != 'guest' && this.$root.trickId > 0){
          axios.post(`/api/tricks/${this.$root.trickId}/performance`, {score: this.totalScore, timeModifier: this.$root.timeModifier, userData: this.studentAnswers, key: this.key})
          .then(function (response) {
            vm.verifiedBy = response.data.verifiedBy;
            vm.attempt = response.data.attempt;
            vm.rewards.push({type: 'coins', added: response.data.coinStackUpdate.added, total: response.data.coinStackUpdate.newAmount});
            eventBus.$emit('coinStackUpdate', response.data.coinStackUpdate);

            if(response.data.star_count_update){
              vm.rewards.push({type: 'stars', added: response.data.star_count_update.added, total: response.data.star_count_update.newCount});
              eventBus.$emit('starCountUpdate', response.data.star_count_update.newCount);
              eventBus.$emit('trickFinished', {id: vm.$root.trickId, score: vm.totalScore, starCountUpdate: response.data.star_count_update.added});
            } else {
              eventBus.$emit('trickFinished', {id: vm.$root.trickId, score: vm.totalScore, starCountUpdate: 0});
            }
            response.data.hikesProgress.forEach(progress => {
              vm.hikePerfs.push(progress.hikePerfs);
              vm.hikeTitles.push(progress.hikeTitle);
              if(progress.starsAdded > 0){
                vm.rewards.push({type: 'hike', hikeTitle: progress.hikeTitle, added: progress.starsAdded});
                eventBus.$emit('hikeStarsAdded', progress);
              }
            });
            response.data.racesProgress.forEach(function(progress){
              eventBus.$emit('raceRunningTime', {id: progress.raceId, runningTime: progress.runningTime});
              eventBus.$emit('raceHistory', {id: progress.raceId, studentHistory: progress.studentHistory});
              if(progress.trickProgress){
                if(progress.trickValidated){
                  vm.rewards.push({type: 'race-trick', raceTitle: progress.raceTitle});
                }
                eventBus.$emit('trickValidated', {id: vm.$root.trickId, score: vm.totalScore, raceId: progress.raceId});
              }
              if(progress.raceFinished){
                vm.rewards.push({type: 'race-finished', raceTitle: progress.raceTitle});
                eventBus.$emit('raceFinished', {id: progress.raceId, runningTime: progress.runningTime, finishPlace: progress.finishPlace});
              }
            });
            if(response.data.divisionsProgress) {
              eventBus.$emit('divisionsProgress', response.data.divisionsProgress);
            }
            response.data.bestPerfs.forEach(function(perf){
              vm.bestPerfs.push(perf);
            });
            setTimeout(() => {
              let rewardsInterval = setInterval(() => {
                vm.debouncedRewards.push(vm.rewards.shift());
                if(vm.rewards.length == 0) clearInterval(rewardsInterval);
              }, 300);
            }, 300)
          })
          .catch(error => console.log(error));
        }
        clearInterval(this.countdown);
        clearInterval(this.blurTimer);
        this.rating = this.$root.scoreToRating(this.totalScore);
        this.questionList.forEach(question => {
          if(this.trick.type == 'algebra'){
            this.answers.push(this.trick.getAnswerFormated({coeffs: question.coeffs, command: this.trick.formula.command, nbOfSolutions: question.nbOfSolutions}));
          } else if(this.trick.type == 'power') {
            this.answers.push(this.trick.getAnswerFormated(question.raw, question.answer));
          } else {
            this.answers.push(this.trick.getAnswerFormated(question.raw));
          }
        });
        setTimeout(function(){
          this.qnb = 1000;
          vm.globalState = 2;
        }, 500);
      },
      closeTryTrick(){
        if(this.globalState == 2){
          this.canceltryTrick()
        } else {
          this.quitDialog = true;
        }
      },
      abortTryTrick(){
        this.aborting = true;
        if(this.races.length){
          axios.post(`api/races/abandon/${this.key}`)
          .then(resp => {
            resp.data.forEach(race => {
              eventBus.$emit('raceRunningTime', {id: race.id, runningTime: race.runningTime});
            });
          })
          .catch(err => console.log(err));
        }
        this.canceltryTrick()
      },
      canceltryTrick(){
        clearInterval(this.countdown);
        clearInterval(this.blurTimer);
        this.tryTrickDialog = false;
        this.$root.overlay = '';
        this.$root.trickId = 0;
        this.$root.sessionId = 0;
      },
      retryTrick(){
        this.canceltryTrick();
        eventBus.$emit('retryTrick', null);
      }
    }
  }
</script>

<style scoped>
  .nextQ-item {
    transition: all 1.5s;
    display: inline-block;
    margin-right: 10px;
  }
  .nextQ-enter, .nextQ-leave-to{
    opacity: 0;
    transform: translateY(45px);
  }
  .nextQ-leave-active {
    position: absolute;
  }
</style>
