<template>
    <v-card class="pa-2">
        <div class="grey--text title text-xs-left mb-2">
            {{ collection.title }}
        </div>
        <div style="display:flex;align-items:center;">
            <v-img 
                style="border-radius:50%;" 
                max-width='100px'
                class="mr-1"
                :src='avatar.url'
                v-for="avatar in collection.collectibles"
                :key="avatar.id"
            />
            <v-btn fab dark medium color="success" :loading="loading" class="ml-3" @click="approve" v-if="!collection.approved">
                <v-icon large dark style="display:inline-flex;">done</v-icon>
            </v-btn>
        </div>
    </v-card>
</template>
<script>
export default {
    props: { collection: Object },
    data: function () {
        return {
            loading: false,
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        approve(){
            this.loading = true;
            axios.post('/api/collections', {collection: this.collection})
            .then(resp => {
                eventBus.$emit('collectionApproved', this.collection.title);
                this.loading = false;
            }).catch(err => console.log(err));
        }
    } 
}
</script>
