<template>
  <v-dialog v-model="editLockedDialog" width="600">
    <v-card class='pa-3'>
      <v-layout row wrap>
        <v-flex xs12 class="display-1 text-xs-center">
          Edit <b>locked</b> race
        </v-flex>
        <v-flex xs12 class='grey--text caption text-xs-center pt-0'>
          This race is <b>locked</b>, only limited editing is allowed.
        </v-flex>
      </v-layout>
      <v-card-text>
        <v-form ref="editRaceForm" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs9 class='pr-1' v-if='racePhaseId == 0'>
              <v-text-field label="Title" v-model="title" :rules="titleRules" autofocus validate-on-blur></v-text-field>
            </v-flex>
            <v-flex xs12 v-else>
              <v-text-field label="Title" v-model="title" :rules="titleRules" autofocus validate-on-blur></v-text-field>
            </v-flex>
            <v-flex xs3 class='pl-1' v-if='racePhaseId == 0'>
              <v-select v-model="raceTerm" :items="[1,2,3,4,5,6,7,8,9,10]" label="Term"></v-select>
            </v-flex>
            <v-flex xs12 class='mb-2' v-if='racePhaseId == 0'>
              <span class='grey--text caption'>Classes</span>
              <v-expansion-panel>
                <v-expansion-panel-content>
                  <template v-slot:header class='pa-2'>
                    <div style='margin-left:-16px' class='grey--text' v-if='raceClasses.length == 0'>
                      No classes selected
                    </div>
                    <div style='margin-left:-16px' v-else>
                      <v-chip v-for='(name, index) in raceClassesNames' :key='`classChip-${index}`'>{{ name }}</v-chip>
                    </div>
                  </template>
                  <v-layout row wrap class='pa-2'>
                    <v-flex xs12 d-flex class='pa-0'>
                      <v-checkbox v-model="raceClasses" v-for="currentClass in classes" :disabled='orgClasses.includes(currentClass.id)' :label="currentClass.name" :value="currentClass.id" :key="`class-${currentClass.id}`"></v-checkbox>
                    </v-flex>
                    <v-flex xs12 class='pa-0 grey--text caption text-xs-center'>
                      Classes can be added but not removed.
                    </v-flex>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-flex>
            <v-flex xs12 v-else>
              <v-select class='py-2' outline v-model="racePhaseId" :items="availablePhases" hide-details label="Phase"/>
            </v-flex>
            <v-flex xs12 style='display:flex;align-items:center;position:relative' class='mt-2'>
              <span class='pr-2'>
                Performance verification
              </span>
              <v-btn icon @click="showVerificationInfo = !showVerificationInfo" style='align-self:center'>
                <v-icon color='primary'>help</v-icon>
              </v-btn>
              <v-switch hide-details class='mt-0 pt-0' v-model='perfVerificationSwitch' :label='perfVerificationSwitch ? "Required" : "Optional"'/>
              <v-card class='pa-3' width='250px' style='position:absolute;left:75px;top:45px;background:white;z-index:10;cursor:pointer' v-if='showVerificationInfo' @click='showVerificationInfo=false'>
                <div class='subheading grey--text'>Performance verification</div>
                <div>
                  If required, students performances will have to be verified by you<span v-if='racePhaseId != 0'> or the marathon organiser</span>.
                  <br/>
                  <span class='caption'>
                    Verfication functionnalities can be found in the event menu (click on the icon next to your avatar in the upper-right corner).
                  </span>
                </div>
              </v-card>
            </v-flex>
            <v-flex xs12>
              <v-expansion-panel class='mb-2' v-if='perfVerificationSwitch'>
                <v-expansion-panel-content>
                  <template v-slot:header>
                    <div class='caption grey--text' style='position:absolute;top:0px'>
                      Authorised verifiers
                    </div>
                    <div class='mt-2'>
                      <v-chip>You</v-chip>
                      <v-chip v-for='verifier in verifiers' :key='verifier.id'>
                        {{ verifier.name }}
                        <v-btn style='margin-right:-25px' small class='ml-2 mr-0' icon @click.stop='removeverifier(verifier)'>
                          <v-icon v-ripple>remove</v-icon>
                        </v-btn>
                      </v-chip>
                    </div>
                  </template>
                  <v-card class='px-3 py-1'>
                    <v-text-field
                      v-model='searchTeacher'
                      hide-details
                      :loading='searchLoading'
                      :disabled='searchLoading'
                      label='Search for teachers'
                      @keyup='typingSearch'
                    />
                    <v-checkbox
                      class='mt-0'
                      v-model='restrictSearch'
                      :disabled='searchLoading'
                      :label='`Restrict to your domain (${teacherDomain})`'
                      @change='typingSearch'
                    />
                    <div style='min-height:100px;max-height:80px;overflow-y:auto;'>
                      <v-chip v-for='teacher in teachersFound' :key='teacher.id'>
                        {{ teacher.name }}
                        <v-btn style='margin-right:-25px' small class='ml-2 mr-0' icon @click='addverifier(teacher)'>
                          <v-icon v-ripple>add</v-icon>
                        </v-btn>
                      </v-chip>
                    </div>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-flex>
            <!-- <v-flex xs12 class='grey--text caption'>

            </v-flex> -->
            <v-flex xs8 class='pr-2'>
              <div class='grey--text caption'>Max extra time</div>
              <v-slider v-model="options.timeModifier" thumb-label min="0" max="45">
                <template v-slot:thumb-label="props">
                  <span>
                    +{{ options.timeModifier }}s
                  </span>
                </template>
              </v-slider>
            </v-flex>
            <v-flex xs4 class='pl-2'>
              <v-select hide-details label='Individual extra time' v-model="options.handicap"
                :items="[{text: 'None', value: 0}, {text: '25%', value: '0.25'}, {text: '50%', value: '0.5'}, {text: '75%', value: '0.75'}, {text: '100%', value: '1'}]"
              />
            </v-flex>
            <v-flex xs12 class="caption grey--text">Medals</v-flex>
            <v-flex xs2>
              <v-text-field v-model="options.gold" type="number" label="Gold" min="1" max="10"></v-text-field>
            </v-flex>
            <v-flex xs2></v-flex>
            <v-flex xs2>
              <v-text-field v-model="options.silver" type="number" label="Silver" min="1" max="10"></v-text-field>
            </v-flex>
            <v-flex xs2></v-flex>
            <v-flex xs2>
              <v-text-field v-model="options.bronze" type="number" label="Bronze" min="1" max="10"></v-text-field>
            </v-flex>
            <v-flex xs12 md5>
              <v-menu v-model="menuStart" :close-on-content-click="false" :nudge-right="40" lazy transition="scale-transition" offset-y full-width min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field :value="startFormated" label="Start date" append-icon="event" :rules="startRules" readonly clearable v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="start" @input="menuStart=false"></v-date-picker>
              </v-menu>
              <v-text-field type='time' v-model="startTime" label="Start time" append-icon="access_time" :rules="startRules"></v-text-field>
            </v-flex>
            <v-flex xs12 md2></v-flex>
            <v-flex xs12 md5>
              <v-menu v-model="menuFinish" :close-on-content-click="false" :nudge-right="40" lazy transition="scale-transition" offset-y full-width min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field :value="finishFormated" label="Finish date" append-icon="event" :rules="finishRules" readonly clearable v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="finish" @input="menuFinish=false"></v-date-picker>
              </v-menu>
              <v-text-field type='time' v-model="finishTime" label="Finish time" append-icon="access_time" :rules="finishRules"></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" flat block :disabled='submitLoading' @click="editLockedDialog=false">
          Cancel
        </v-btn>
        <v-btn color="success" block :loading='submitLoading' @click="sumbitEdit">
          Save
        </v-btn>
      </v-card-actions>
      <div class="marathonTagWrapper" v-if='racePhaseId != 0'>
        <div class='marathonTag'>
          Marathon race
        </div>
      </div>
      <!-- <v-chip label color='black' dark style='position:absolute;top:0px;right:0px;' v-if='racePhaseId != 0'>Marathon race</v-chip> -->
    </v-card>
  </v-dialog>
</template>
<script>
  import moment from 'moment-timezone';

  export default {
    props : { raceId: Number, classes: Array, availablePhases: {type: Array, default: []} },
    data : function(){
      return {
        valid: true,
        title: '',
        raceTerm: 1,
        racePhaseId: 0,
        editLockedDialog: false,
        perfVerificationSwitch: false,
        showVerificationInfo: false,
        searchTeacher: '',
        searchTimer: '',
        restrictSearch: false,
        searchLoading: false,
        submitLoading: false,
        teachersFound: [],
        verifiers: [],
        options: {},
        orgClasses: [],
        raceClasses: [],
        menuStart: false,
        menuStartTime: false,
        start: '',
        startTime: '',
        menuFinish: false,
        menuFinishTime: false,
        finish: '',
        finishTime: '',
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 3) || 'The race title must be at least 3 characters long'
        ],
        startRules: [
          v => !!v || 'You must choose a start date and time'
        ],
        finishRules: [
          v => !!v || 'You must choose a fninsh date and time'
        ]
      }
    },
    computed: {
      startFormated () {
        return this.start ? moment(this.start).format('Do MMMM YYYY') : '';
      },
      finishFormated () {
        return this.finish ? moment(this.finish).format('Do MMMM YYYY') : '';
      },
      raceClassesNames(){
        let names = [];
        this.raceClasses.forEach(cl1 =>{
          let clRef = _.find(this.classes, o => o.id==cl1);
          names.push(clRef.name)
        });
        return names;
      },
      teacherDomain(){
        return `@${this.$root.user.email.split('@')[1]}`;
      },
      verifiersId(){
        return [this.$root.user.id, ...this.verifiers.map(o => o.id)];
      }
    },
    mounted() {
      eventBus.$on('editLockedRace', race => {
        if(this.raceId == race.id){
          this.title = race.title;
          this.raceTerm = race.term;
          this.racePhaseId = race.phase_id ? race.phase_id : 0;
          this.editLockedDialog = true;
          this.options = JSON.parse(race.options);
          this.verifiers = this.options.verificationRequired.filter(o => o.id != this.$root.user.id);
          this.start = race.start;
          this.startTime = moment.tz(race.start, 'UTC').clone().tz(moment.tz.guess()).format('HH:mm');
          this.finish = race.finish;
          this.finishTime = moment.tz(race.finish, 'UTC').clone().tz(moment.tz.guess()).format('HH:mm');
          this.perfVerificationSwitch = this.options.verificationRequired.length > 0;
          this.orgClasses = [];
          this.raceClasses = [];
          if(race.divisions){
            race.divisions.forEach(cl => {
              this.orgClasses.push(cl.id);
              this.raceClasses.push(cl.id);
            });
          }
        }
      });
    },
    methods: {
      typingSearch(){
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(this.proceedSearch, 500);
      },
      proceedSearch(){
        if(this.searchTeacher.length > 3){
          this.searchLoading = true;
          axios.get(`/api/races/verifiers/${this.searchTeacher}/${this.restrictSearch}/${JSON.stringify(this.verifiersId)}`, {})
          .then(resp => {
            this.teachersFound = resp.data;
            this.searchLoading = false;
          }).catch(err => console.log(err));
        }
      },
      addverifier(teacher){
        this.teachersFound = this.teachersFound.filter(o => o.id != teacher.id);
        if(!this.verifiers.some(o => o.id == teacher.id)){
          this.verifiers.push(teacher);
        }
      },
      removeverifier(teacher){
        this.verifiers = this.verifiers.filter(o => o.id != teacher.id);
        if(!this.teachersFound.some(o => o.id == teacher.id)){
          this.teachersFound.unshift(teacher);
        }
      },
      sumbitEdit(){
        let vm = this;
        this.submitLoading = true;
        if(moment(this.start).isAfter(this.finish, 'day')){
          this.$root.snackError('The start date must be before the finish date');
          return;
        }

        let newStart = moment(this.start.split(' ')[0]+' '+this.startTime+':00').tz(moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm');
        let newFinish = moment(this.finish.split(' ')[0]+' '+this.finishTime+':00').tz(moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm');
        this.options.verificationRequired = this.perfVerificationSwitch ? [{id: this.$root.user.id, name: this.$root.user.name}, ...this.verifiers] : [];

        if(this.$refs.editRaceForm.validate()){
          axios.patch(`/api/races/locked/${this.raceId}`, {
            title: this.title,
            term: this.raceTerm,
            classes: this.raceClasses,
            phaseId: this.racePhaseId,
            options: JSON.stringify(this.options),
            start: newStart,
            finish: newFinish
          })
          .then(function (response) {
            eventBus.$emit('raceUpdated', response.data);
            vm.editLockedDialog = false;
            vm.submitLoading = false;
            vm.$root.snackSuccess('Race updated.');
          })
          .catch(function (error) {
            if(error.response.data.errors.finish){
              vm.$root.snackError('The finish date can\'t be in the past');
            } else {
              vm.$root.snackError('Error ! Your race could not be updated');
            }
            console.log(error);
          });
        } else {
          this.$root.snackError('Invalid data submitted');
        }
      }
    }
  }
</script>

<style scoped>
  .marathonTagWrapper {
    position:absolute;
    top:0px;
    right:0px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 110px;
    height: 110px;
  }
  .marathonTag {
    background:black;
    color:white;
    position:absolute;
    top:-26px;
    right:-75px;
    padding: 5px 35px;
    transform: rotate(45deg);
    transform-origin: top left;
  }
</style>
