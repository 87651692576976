<template>
    <div style="overflow-x:visible;position:relative;">
        <div ref="wrapper" style="position:relative;overflow-x:hidden;">
            <div 
                style="transition: all .5s;display:flex;flex-flow:column wrap;align-content:flex-start;" 
                :style='`transform:translateX(-${shift*collectibleWidth}px);min-height:${divHeight}px;max-height:${divHeight}px;`'
            >
                <v-hover 
                    style="margin:5px;"
                    :style="`height:${collectibleHeight};max-height:${collectibleHeight};`"
                    v-for="collectible in collectibles" :key="collectible.id"
                    >
                    <v-img 
                        slot-scope="{ hover }" 
                        style="cursor:pointer" 
                        :style="avatar ? 'border-radius:50%' : ''"
                        :class="`elevation-${hover ? 10 : 2}`" 
                        :width='`${collectibleWidth}px`'
                        :height='`${collectibleHeight}px`'
                        :max-height='`${collectibleHeight}px`'
                        :src='collectible.url'
                        @click="showSelectCard(collectible)"
                    />
                </v-hover>
                <div :style="`transform:translateX(${collectibleWidth*2}px)`" id="carouselEnd"/>
            </div>
        </div>
        <v-btn icon style="position:absolute;left:-40px;" :style="`top:${btnTopMargin}px`" @click="shiftDiv(-1)" v-if="shift > 0">
            <v-icon>chevron_left</v-icon>
        </v-btn>
        <v-btn icon style="position:absolute;right:0px;" :style="`top:${btnTopMargin}px`" @click="shiftDiv(1)" v-if="divOverflow">
            <v-icon>chevron_right</v-icon>
        </v-btn>
    </div>
</template>
<script>
    export default {
        props: { collectibles: Array, avatar: Boolean, collectibleWidth: Number, collectibleHeight: Number, divHeight: Number, resetShiftCounter: Number },
        data: function () {
            return {
                shift: 0,
                divOverflow: false
            }
        },
        computed: {
            btnTopMargin(){
                return (Math.floor(this.divHeight/this.collectibleHeight)*this.collectibleHeight)/2 - 14;
            }
        },
        watch:{
            resetShiftCounter(){
                this.shift = 0;
                this.shiftDiv();
            }
        },
        mounted() {
            
        },
        beforeDestroy() {
            
        },
        methods: {
            shiftDiv(dir = 0) {
                let nbCollectibles = this.collectibles ? this.collectibles.length : 0;
                let maxShift = Math.ceil(nbCollectibles / 2) - Math.max(Math.ceil(this.$refs.wrapper.clientWidth / this.collectibleWidth), 0);
                this.shift = Math.max(this.shift+dir, 0);
                this.divOverflow = this.shift <= maxShift+2;
            },
            showSelectCard(collectible){
                eventBus.$emit('showCollectibleSelectCard', collectible);
            },
            shopForCollectibles(type){
                console.log(this.collectibles);
                console.log(type);
            }
        }
    }
</script>
