<template>
    <v-container>
        <v-layout row wrap>
            <v-flex xs12 style="display:flex;justify-content:space-between;" class="mb-2">
                <v-btn :disabled="loading" flat to="/home">
                    <v-icon left>chevron_left</v-icon> Back home
                </v-btn>
                <span>
                    <buy-canvas-dialog :inHomePage="false"/>
                </span>
            </v-flex>
            <v-tabs style="width:100%">
                <v-tab v-for="n in 3" :key="n" :disabled="canvases[n-1].length == 0">
                    Level {{ n }} ({{ nbCompleted[n-1] }} / {{ nbCanvas[n-1] }})
                </v-tab>
                <v-tab-item :key="1">
                    <v-card flat class="pa-2">
                        <v-layout row wrap :style="$vuetify.breakpoint.name == 'xs' ? 'justify-content:center;' : 'justify-content:flex-start;'">
                            <canvas-card v-for="canvas in canvases[0]" :key="canvas.id" :canvas="canvas" :selecting="selecting"/>
                        </v-layout>
                    </v-card>
                </v-tab-item>
                <v-tab-item :key="2" style="width:100%">
                    <v-card flat class="pa-2" width="100%">
                        <v-timeline dense>
                            <v-timeline-item small color="primary lighten-2" v-for="canvas in canvases[1]" :key="canvas.id">
                                <canvas-timeline-card :canvas="canvas" :selecting="selecting"/>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card>
                </v-tab-item>
                <v-tab-item :key="3">
                    <v-card flat class="pa-2" width="100%">
                            <v-timeline dense>
                                <v-timeline-item small color="primary lighten-2" v-for="canvas in canvases[2]" :key="canvas.id">
                                    <canvas-timeline-card :canvas="canvas" :selecting="selecting"/>
                                </v-timeline-item>
                            </v-timeline>
                        </v-card>
                </v-tab-item>
            </v-tabs>
            
            <v-dialog width="324" v-model="imageDialog">
                <challenge-canvas-display :imageBySection="focusedCanvas.image" :revealed="focusedCanvas.revealed" :depth="focusedCanvas.depth"/>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    props: {},
    data: function () {
        return {
            canvases: [[], [], []],
            nbCanvas: [0, 0, 0],
            loading: false,
            unfinishedCanvas: [],
            totalUnrevealed: 0,
            imageDialog: false,
            imageLoading: 0,
            selecting: 0,
            focusedCanvas: {},
            paying: false,
            totalUnrevealed: 0
        }
    },
    computed: {
        selectedId(){
            const selectedCanvas = this.canvases.find(o => o.open);
            
            return selectedCanvas ? selectedCanvas.id : 0
        },
        nbCompleted(){
            return [
                this.canvases[0].reduce((acc, val) => acc += val.completed ? 1 : 0, 0),
                this.canvases[1].reduce((acc, val) => acc += val.completed ? 1 : 0, 0),
                this.canvases[2].reduce((acc, val) => acc += val.completed ? 1 : 0, 0)
            ]
        }
    },
    created() {
        eventBus.$emit('loading', { command: 'clear' });
        eventBus.$emit('loading', { command: 'start', name: 'canvas-page' });
        this.initData();
    },
    mounted() {
        eventBus.$on('canvasPurchased', this.canvasPurchased);
        eventBus.$on('selectCanvas', this.selectCanvas);
    },
    beforeDestroy() {
        eventBus.$off('canvasPurchased', this.imageUpdate);
        eventBus.$off('selectCanvas', this.selectCanvas);
    },
    methods: {
        initData(){
            axios.get('/api/challenges/myCanvas', {})
            .then(resp => {
                this.canvases = resp.data.canvases;
                this.nbCanvas = resp.data.nbCanvas;
                this.totalUnrevealed = resp.data.totalUnrevealed;
                eventBus.$emit('loading', { command: 'finish', name: 'canvas-page' });
            }).catch(err => console.log(err));
        },
        canvasPurchased(canvas){
            this.canvases[canvas.depth-1].unshift(canvas);
            this.totalUnrevealed += canvas.totalPixels;
        },
        selectCanvas(canvas) {
            this.loading = true;
            this.selecting = canvas.id;
            axios.post(`/api/challenges/canvas/${canvas.id}/select`)
            .then(resp => {
                eventBus.$emit('imageUpdate', resp.data);
                this.selecting = 0;
                for(let i = 0 ; i <= 2 ; i++){
                    this.canvases[i].forEach(c => c.open = false);
                }
                canvas.open = true;
                this.loading = false;
            }).catch(err => console.log(err));
        }
    }
}
</script>
