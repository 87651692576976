<template>
  <v-container class='pa-1'>
    <v-layout row wrap>
      <v-btn pa-0 ma-0 small flat primary to="/home" class="grey--text" :ripple="false">
        <v-icon left>chevron_left</v-icon>
        Back home
      </v-btn>
      <leave-a-message></leave-a-message>
      <v-flex xs12 class="samfont-display mb-2">
        About Sam
      </v-flex>
      <v-flex xs12>
        <v-tabs class='mb-4 subheading font-weight-thin' grow fixed-tabs>
          <v-tab href="#howTo">How to Sam</v-tab>
          <v-tab href="#classroom">Sam in the classroom</v-tab>
          <v-tab href="#glossary">Sam's Glossary</v-tab>
          <v-tab href="#tutorials">Sam's Tutorials</v-tab>
          <!-- <v-tab href="#tutorials">Sam's Tutorials <span class='ml-2'><span class='blue'>II</span><span class='white'>II</span><span class='red'>II</span></span></v-tab> -->
          <v-tab href="#more">More about Sam</v-tab>
          <v-tab-item value='howTo'>
            <v-card class='pa-3 text-xs-left'>
              <v-container class='pa-0' grid-list-xl>
                <v-layout row wrap class='mb-5 pb-5'>
                  <v-flex xs12 md5 lg7 style='display:flex;flex-direction:column;justify-content:center;'>
                    <div class='grey--text samfont-bigtitle mb-1'>
                      Tricks and stars
                    </div>
                    <div>
                      Sam's core feature is <about-text about='trick' plural />.<br>
                      A <about-text about='trick' /> is a short mental calculation exercise : a series of 10
                      questions on a given topic.<br>
                      The questions are timed and, at the end, you get a <about-text about='star' /> rating
                      depending on how fast you found the answers.
                      Your <about-text about='starCount' /> is the sum of your best 3 performances on each
                      tricks. It is shown in the top right hand corner, next to your avatar.
                      There are dozens of <about-text about='trick' plural /> available and you should try
                      them all to build up your star count !<br>
                      Tricks are organised by theme into <about-text about='track' plural />. A <about-text
                        about='track' /> is a series of <about-text about='trick' plural />. There are usually 8
                      <about-text about='trick' plural /> in a <about-text about='track' />.
                    </div>
                  </v-flex>
                  <v-flex xs12 md7 lg5>
                    <about-gif fileName='trick'></about-gif>
                  </v-flex>
                </v-layout>
                <v-layout row wrap class='mb-5 pb-5'>
                  <v-flex xs12 md7 lg5>
                    <about-gif fileName='challenge'></about-gif>
                  </v-flex>
                  <v-flex xs12 md5 lg7 style='display:flex;flex-direction:column;justify-content:center;'>
                    <div class='grey--text samfont-bigtitle'>
                      Challenges and smiles
                    </div>
                    <div>
                      A <about-text about='challenge' /> is a set of 9 questions from the same <about-text
                        about='track' /> presented at the same time. You have a limited amount of time to solve as many
                      as you can.
                      <about-text about='track' plural capitalize /> are designed to group together similar
                      tricks and give you a guide to follow. You gain <about-text about='smile' plural /> based on the
                      number of correct answers you give.
                      Each correct answer is rewarded with a <about-text about='star' plural />.<br>
                      Initially, your <about-text about='smileCount' /> is the sum of all <about-text about='smile'
                        plural /> earned during <about-text about='challenge' plural />.
                      However, contrary to your <about-text about='starCount' />, your <about-text about='smileCount' />
                      can be decreased. <about-text about='challenge' plural capitalize /> are given to your
                      regularly (every
                      few hours if you have less than 5 active <about-text about='challenge' plural />).
                      Once a <about-text about='challenge' /> has been set, you have 36 hours to complete it.
                      If you don't, the <about-text about='challenge' /> and you will loose <about-text about='smile'
                        plural />.<br>
                      Be sure to visit Sam every day to keep him smiling ! Solving <about-text about='challenge'
                        plural /> also gradually reveal the <about-text about='canvas' /> on your home page.
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout row wrap class='mb-2'>
                  <v-flex xs12 md5 lg7 style='display:flex;flex-direction:column;justify-content:center;'>
                    <div class='grey--text samfont-bigtitle mb-1'>
                      Collectibles and coins
                    </div>
                    <div>
                      Once you have earned enough <about-text about='star' plural /> and <about-text about='smile'
                        plural />, you will be able to purchase <about-text about='collectible' plural /><br>
                      <about-text about='collectible' plural capitalize /> allow you to customize your Sam's Tricks
                      experience : avatar, images colors... which you can apply to your app.<br>
                      Buying <about-text about='collectible' plural /> costs <about-text about='smile' plural /> and
                      <about-text about='coin' plural />
                      <br>
                      <about-text about='coin' plural capitalize /> are an internal Sam's tricks currency.
                      You earn <about-text about='coin' plural /> by trying tricks and you can then spend
                      them to purchase <about-text about='challenge' plural />
                      You also earn <about-text about='coin' plural /> regularly if you visit this site and
                      practice your mental calculations skills every day...
                    </div>
                  </v-flex>
                  <v-flex xs12 md7 lg5>
                    <about-gif fileName='collectible'></about-gif>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-tab-item>
          <v-tab-item value='classroom'>
            <v-card class='pa-3 text-xs-left'>
              <v-container class='pa-0' grid-list-xl>
                <v-layout row wrap class='mb-5 pb-5'>
                  <v-flex xs12 md5 lg7 style='display:flex;flex-direction:column;justify-content:center;'>
                    <div class='grey--text samfont-bigtitle'>
                      Classes, races and hikes
                    </div>
                    <div>
                      If you are a teacher, you can create <about-text about='class' plural />.
                      Students can join any <about-text about='class' /> using a code (provided to the
                      teacher).
                      The teacher can then follow the students performances.
                      He can also set up <about-text about='race' plural /> for students to run.
                      <br>
                      <about-text about='race' plural capitalize /> are run on a <about-text about='track' /> by
                      students from one or several <about-text about='class' plural />.
                      The first students to validate enough <about-text about='trick' plural /> from this
                      <about-text about='track' /> receive a <about-text about='medal' /> as
                      reward.
                      <br>
                      A <about-text about='hike' /> is a series of tracks which a teacher recommends to his
                      students to focus on. Contrary to <about-text about='race' plural />,
                      <about-text about='hike' plural /> are not competitives and not limited in time.
                    </div>
                  </v-flex>
                  <v-flex xs12 md7 lg5>
                    <about-gif fileName='class race hike'></about-gif>
                  </v-flex>
                </v-layout>
                <v-layout row wrap class='mb-5 pb-5'>
                  <v-flex xs12 md7 lg5>
                    <about-gif fileName='class details'></about-gif>
                  </v-flex>
                  <v-flex xs12 md5 lg7 style='display:flex;flex-direction:column;justify-content:center;'>
                    <div class='grey--text samfont-bigtitle'>
                      Students progress
                    </div>
                    <div>
                      Once a teacher sets up a <about-text about='class' />, he can also follow his students
                      progress in great details :
                      <about-text about='trick' plural /> they try, <about-text about='challenge' plural /> they take,
                      <about-text about='medal' plural /> they earn...
                      <br>
                      As a complement, Sam's tricks also provides teachers with <about-text about='classPractice' />.
                      This feature is designed to be used in the classroom without the need for students computers.
                      It displays a series of questions to be shown in class using an overhead projector.
                      <br>
                      Students gain <about-text about='classPoint' plural /> when they participate in <about-text
                        about='hike' plural /> and <about-text about='race' plural />. The <about-text
                        about='classPoint' plural /> help teachers assess if their students are doing well or not in the
                      activities they set for them.
                      Finally, at the end of the term, teachers can easily convert <about-text about='classPoint'
                        plural /> into grades if they want.
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-tab-item>
          <v-tab-item value='glossary'>
            <sam-glossary></sam-glossary>
          </v-tab-item>
          <v-tab-item value='tutorials' style='position:relative'>
            <v-card class="pa-2">
              <span style='display:flex;align-items:baseline;position:absolute;right:5px;top:-5px;z-index:1000'>
                <v-img max-width='30px' min-width='30px' class='mr-2'
                  :style='frenchTutorials ? "filter: grayscale(50%)" : ""' src="/images/flag uk.png" contain />
                <v-switch hide-details v-model='frenchTutorials' />
                <v-img max-width='30px' min-width='30px' :style='frenchTutorials ? "" : "filter: grayscale(50%)"'
                  src="/images/flag fr.png" contain />
              </span>
              <v-window v-model='tutorialWindow'>
                <v-window-item :key='0'>
                  <v-layout row wrap class='text-xs-left px-3 py-5'>
                    <v-flex xs12 class='samfont-bigtitle mb-3'>
                      What is Sam's Tricks ?
                    </v-flex>
                    <v-flex xs12 style="display: flex;justify-content: center">
                      <iframe :width='`${videoWidth*2}px`' :height='`${videoWidth*9/8}px`'
                        src='https://www.youtube.com/embed/RgvwRMK3W_g' />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap style="background:#D4D6EE;" class="px-3 py-5">
                    <v-flex xs12 class='samfont-bigtitle mb-2 text-xs-left'>
                      User manual
                    </v-flex>
                    <v-flex xs12 style="display:flex;justify-content:center;">
                      <iframe :width='`${videoWidth}px`' :height='`${videoWidth * 9 / 16}px`'
                        src='https://www.youtube.com/embed/c7L_NpkPW7k' class="mx-1" />
                      <iframe :width='`${videoWidth}px`' :height='`${videoWidth * 9 / 16}px`'
                        src='https://www.youtube.com/embed/N01mnybKusM' class="mx-1" />
                      <iframe :width='`${videoWidth}px`' :height='`${videoWidth * 9 / 16}px`'
                        src='https://www.youtube.com/embed/jrk4YhGmn_8' class="mx-1" />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap style="background:#EEA198;" class="px-3 py-5">
                    <v-flex xs12 class='samfont-bigtitle mb-2 text-xs-left'>
                      Student guide
                    </v-flex>
                    <v-flex xs12 style="display:flex;justify-content:center;">
                      <iframe :width='`${videoWidth}px`' :height='`${videoWidth * 9 / 16}px`'
                        src='https://www.youtube.com/embed/Gtwjqidvgaw' class="mx-1" />
                      <iframe :width='`${videoWidth}px`' :height='`${videoWidth * 9 / 16}px`'
                        src='https://www.youtube.com/embed/nkoc05bvBrU' class="mx-1" />
                      <iframe :width='`${videoWidth}px`' :height='`${videoWidth * 9 / 16}px`'
                        src='https://www.youtube.com/embed/07girdRmrO8' class="mx-1" />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap style="background:#90FF92;" class="px-3 py-5">
                    <v-flex xs12 class='samfont-bigtitle mb-2 text-xs-left'>
                      Teacher compendium
                    </v-flex>
                    <v-flex xs12 style="display:flex;justify-content:center;">
                      <iframe :width='`${videoWidth}px`' :height='`${videoWidth * 9 / 16}px`'
                        src='https://www.youtube.com/embed/YAgZjqiJzCE' class="mx-1" />
                      <iframe :width='`${videoWidth}px`' :height='`${videoWidth * 9 / 16}px`'
                        src='https://www.youtube.com/embed/N8SzgsMXQs4' class="mx-1" />
                      <iframe :width='`${videoWidth}px`' :height='`${videoWidth * 9 / 16}px`'
                        src='https://www.youtube.com/embed/uN8eKYABXH8' class="mx-1" />
                    </v-flex>
                  </v-layout>
                </v-window-item>
                <v-window-item :key='1'>
                  <v-layout row wrap class='text-xs-left'>
                    <v-flex xs12>
                      <v-alert :value="true" type="error">
                        Ces vidéos sont anciennes. Certaines informations et visuels peuvent dater un peu...
                      </v-alert>
                    </v-flex>
                    <v-flex xs12 class='headline py-2'>
                      Présentation
                    </v-flex>
                    <v-flex xs12>
                      <iframe :width='`${videoWidth}px`' :height='`${videoWidth*9/16}px`'
                        src='https://www.youtube.com/embed/QIBsVPUnbfk' />
                    </v-flex>
                    <v-flex xs12 class='headline py-2'>
                      Guides
                    </v-flex>
                    <v-flex class='mt-4' xs12 md6 v-for='(vid, index) in videos' :key='index'>
                      <iframe :width='`${videoWidth}px`' :height='`${videoWidth*9/16}px`' :src='vid' />
                    </v-flex>
                  </v-layout>
                </v-window-item>
              </v-window>
            </v-card>
          </v-tab-item>
          <v-tab-item value='more'>
            <v-card class="pa-3">
              <about-sam class="text-xs-left" />
            </v-card>
            <!-- <v-card height='400px'>
              <v-layout fill-height d-flex justify-center align-center class='headline font-weight-thin grey--text'>
                More info will come soon...
              </v-layout>
            </v-card> -->
          </v-tab-item>
        </v-tabs>
      </v-flex>
      <transition name="bounce">
        <v-btn fab dark small color="primary"
          :style='`position:fixed;bottom:${yBackToTop}px;left:50%;margin-left:-20px`' @click="scrollToTop"
          v-if='displayBackToTop'>
          <v-icon dark style='display:inline-flex'>keyboard_arrow_up</v-icon>
        </v-btn>
      </transition>
      <v-flex xs12 style='display:flex'>
        <v-btn flat block to="/home" class="grey--text" :ripple="false">
          <v-icon>chevron_left</v-icon>
          Back home
        </v-btn>
        <v-btn color='primary' class='mx-1' block to="/tricks" :ripple="false">
          Tricks
          <v-icon right>star</v-icon>
        </v-btn>
        <v-btn color='primary' block to="/tracks" :ripple="false">
          Tracks
          <v-icon right>show_chart</v-icon>
        </v-btn>
        <v-btn color='primary' class='ml-1' block to="/races" :ripple="false">
          Races
          <v-icon right>directions_run</v-icon>
        </v-btn>
        <v-btn color='primary' class='ml-1' block to="/hikes" :ripple="false">
          Hikes
          <v-icon right>directions_walk</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        displayBackToTop: false,
        yBackToTop: 0,
        frenchTutorials: false,
        videos: ['https://www.youtube.com/embed/6JP5s2h8_JE', 'https://www.youtube.com/embed/z3uqNYRGSfs', 'https://www.youtube.com/embed/1wh9dMgkBGI']
      }
    },
    computed: {
      tutorialWindow(){
        return this.frenchTutorials ? 1 : 0;
      },
      videoWidth(){
        switch(this.$vuetify.breakpoint.name){
          case 'xs':
            return 250;
          break;
          case 'sm':
            return 300;
          break;
          case 'md':
            return 300;
          break;
          case 'lg':
            return 350;
          break;
          default:
            return 350;
          break;
        }
      }
    },
    created() {
      window.addEventListener('scroll', _.throttle(this.bttDisplay, 200));
      eventBus.$emit('loading', {command: 'clear'});
    },
    beforeDestroy(){
      window.removeEventListener('scroll', _.throttle(this.bttDisplay, 200));
    },
    methods : {
      bttDisplay(){
        if (window.scrollY < 250){
          this.displayBackToTop = false;
        }
        if (window.scrollY > 250){
          this.displayBackToTop = true;
        }
        if((window.innerHeight + window.scrollY) >= document.body.offsetHeight-50){
          this.yBackToTop = 110;
        } else {
          this.yBackToTop = 0;
        }
      },
      scrollToTop(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }
</script>

<style scoped>
  .blue {
    background: #0000FF;
    color: rgba(0,0,0,0);
  }
  .white {
    background: #FFFFFF;
    color: rgba(0,0,0,0);
  }
  .red {
    background: #FF0000;
    color: rgba(0,0,0,0);
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
