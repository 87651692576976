<template>
  <v-card width="100%" flat class="pa-1 text-xs-left">
    <v-layout row wrap>
      <v-flex xs12 style='display:flex;align-content:center'>
        <span
          class='mr-1 subheading'
          :class="simplClass"
        >
          Require simplification
        </span>
        <v-switch v-model="simplification" class='mt-0 pt-0'></v-switch>
      </v-flex>
      <v-flex xs12 v-for='(singleFormula, index) in formulas' :key='`formula-${index}`'>
        <v-text-field :label="`Formula ${index + 1}`" v-model="singleFormula.raw" :rules="formulaRules" @input="userTyping" clearable validate-on-blur></v-text-field>
      </v-flex>
    </v-layout>
    <v-flex class="text-xs-right">
      <v-btn flat icon color="primary" @click="removeFormula" :disabled="formulas.length <= 1">
        <v-icon dark>remove</v-icon>
      </v-btn>
      <v-btn flat icon color="primary" @click="addFormula" :disabled="formulas.length >= 4">
        <v-icon dark>add</v-icon>
      </v-btn>
    </v-flex>
    <v-card-text class="text-xs-left">
      <p class="grey--text caption ma-0 pa-0">Variables</p>
      <v-layout row wrap v-for="(variable, index) in variables" :key='`variable-${index}`'>
        <v-flex xs4 class="text-xs-center">
          <span class="headline font-weight-bold font-italic">{{ variable.name }}</span>
          <v-select solo flat hide-details :items="variableItems" v-model="variable.type"></v-select>
        </v-flex>
        <v-flex xs8 v-if='variable.type == "enum"'>
          <v-text-field label="Values (separate by a comma ',')" v-model="variable.values" @input="userTyping" @keyup='formatEnumValues(variable.name)'></v-text-field>
        </v-flex>
        <v-flex xs8 v-if='variable.type == "depend"'>
          <v-text-field label="Formula (depending on previous variables)" v-model="variable.formula" @input="userTyping"></v-text-field>
        </v-flex>
        <v-flex xs8 v-if='variable.type == "grad"'>
          <v-layout row wrap>
            <v-flex xs4>
              <v-text-field label="min" v-model="variable.min" type="number" :min="-1000" :max="1000" @input="userTyping"></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field label="max" v-model="variable.max" type="number" :min="-1000" :max="1000" @input="userTyping"></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field label="step" v-model="variable.step" type="number" :min="0.01" :max="100" :step="0.01" @input="userTyping"></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-flex xs12 class="text-xs-right">
      <v-btn flat icon color="primary" @click="removeVariable" :disabled="variables.length <= 1">
        <v-icon dark>remove</v-icon>
      </v-btn>
      <v-btn flat icon color="primary" @click="addVariable" :disabled="variables.length >= 8">
        <v-icon dark>add</v-icon>
      </v-btn>
    </v-flex>
  </v-card>
</template>

<script>
  import katex from 'katex';

  export default {
    props : {
      formula: Object
    },
    data : function(){
      return {
        formulas: [],
        variables: [],
        simplification: false,
        variableItems: [
          {value: 'grad', text: 'Gradual'}, {value: 'enum', text: 'Enumarate'}, {value: 'depend', text: 'Dependant'}
        ],
        typingTimer: null,
        formulaRules: [
          v => !!v || 'You must enter a formula',
        ]
      }
    },
    watch: {
      formula: {
        handler(val){
          this.formulas = [];
          val.formulas.forEach( formula => {
            this.formulas.push({raw: formula});
          });
          this.variables = val.variables;
          this.simplification = val.simplification;
        },
        immediate: true
      }
    },
    computed: {
      simplClass: function(){
        return this.simplification ? 'font-weight-bold' : 'grey--text';
      }
    },
    methods: {
      addFormula(){
        if (this.formulas.length < 4){
          this.formulas.push({raw: ''});
          this.emitFormula();
        }
      },
      removeFormula(){
        if (this.formulas.length > 1){
          this.formulas.pop();
          this.emitFormula();
        }
      },
      addVariable(){
        if (this.variables.length < 20){
          let nextChar = String.fromCharCode(this.variables[this.variables.length-1].name.charCodeAt(0) + 1);
          this.variables.push({name: nextChar, min: 0, max: 10, step: 1, type: 'grad', values: ''});
          this.emitFormula();
        }
      },
      removeVariable(){
        if (this.variables.length > 1){
          this.variables.pop();
          this.emitFormula();
        }
      },
      formatEnumValues(name){
        this.variables.forEach( variable => {
          if(variable.name == name){
            variable.values = variable.values.replace(/[^\d,.]/g, '');
          }
        });
      },
      emitFormula(){
        let newFormula = {formulas: _.map(this.formulas, 'raw'), variables: this.variables, simplification: this.simplification};
        eventBus.$emit('formulaUpdated', newFormula);
      },
      userTyping(){
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this.emitFormula, 500);
      }
    }
  }
</script>
