<template>
  <v-dialog v-model="messageDialog" @keydown.esc="cancelMessage" max-width="500px">
    <v-btn slot="activator" fab dark color="primary" fixed bottom right v-if='displayButton'>
      <v-icon dark style='display:inline-flex'>mail_outline</v-icon>
    </v-btn>
    <v-card max-width="500px">
      <v-card-title class="headline">
        Leave Sam a message
      </v-card-title>
      <v-form ref="messageForm" v-model="valid" lazy-validation>
        <v-card-text>
          <v-flex xs12>
            <v-text-field label="Your email" v-model="email" :rules="emailRules" :autofocus='email.length == 0' clearable validate-on-blur></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-textarea label="Your message" v-model="message" :rules="messageRules" :autofocus='email.length > 0' validate-on-blur></v-textarea>
          </v-flex>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-btn color="error" block flat @click="cancelMessage">Cancel</v-btn>
        <v-btn color="primary" block @click="sendMessage">Send</v-btn>
      </v-card-actions>
    </v-card>
    <!-- <div class="g-recaptcha" TODO vue recaptcha (dansnow) + server side = guzzle
          data-sitekey="6Leeb6wUAAAAADBsnWerTPrs0K0UtkDJ521oilwc"
          data-callback="sendMessage"
          data-size="invisible">
    </div> -->
  </v-dialog>
</template>
<script>
  export default {
    props: {displayButton: {type: Boolean, default: true}},
    data : function(){
      return {
        messageDialog: false,
        email: '',
        message: '',
        valid: false,
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid'
        ],
        messageRules: [
          v => !!v || 'You have to type a message',
          v => (v && v.length >= 10) || 'The message must be at least 10 characters long'
        ]
      }
    },
    mounted(){
      if(this.$root.status != 'guest'){
        this.email = this.$root.user.email;
      }
      eventBus.$on('sendMessageToSam', () => this.messageDialog = true);
    },
    methods : {
      cancelMessage(){
        this.message = '';
        this.messageDialog = false;
      },
      sendMessage(token){
        if(this.$refs.messageForm.validate()){
          let vm = this;
          axios.post('/api/message', {
            email: vm.email,
            message: vm.message
          })
          .then(function (response) {
            vm.$root.snackSuccess('Your message to Sam has been sent !');
            vm.message = '';
            vm.messageDialog = false;
          })
          .catch(function (error) {
            console.log(error)
            vm.$root.snackError("There was an error while sending your message."); // TODO test that
          });
        }
      }
    }
  }
</script>
