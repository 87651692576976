<template>
  <v-layout :row="!['xs', 'sm'].includes($vuetify.breakpoint.name)" :column="['xs', 'sm'].includes($vuetify.breakpoint.name)" :reverse="['xs', 'sm'].includes($vuetify.breakpoint.name)" wrap class='pa-2'>
    <v-flex xs12 md8>
      <v-layout row wrap class='grey--text text--darken-1'>
        <v-flex xs12 class="grey--text title mb-2">General stats</v-flex>
        <v-flex xs6 sm3 v-for='(stat, index) in formatedStats' :key='`stat-${index}`'
          style='display: flex;justify-content: center;align-items: center;'>
            <v-layout row wrap>
              <v-flex xs12 class='py-0 text-xs-center'>
                {{ stat.legend }}
              </v-flex>
              <v-flex xs12 class='headline font-weight-bold text-xs-center'>
                <text-fly :text='stat.mainFigure'></text-fly>
                <v-icon medium :color="stat.iconColor" style='vertical-align:top' v-if='stat.icon'>{{ stat.icon }}</v-icon>
              </v-flex>
            </v-layout>
          </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 md4 v-if='race.studentProgress.nbStars'>
      <v-layout row wrap class='pl-3'>
        <v-flex xs12 class="grey--text title pb-0">
          Your performance
        </v-flex>
        <v-flex xs12 class='pt-4 px-0'>
          <v-layout d-flex align-center>
            <v-flex class="display-2 font-weight-bold text-xs-center py-0">
              <v-flex style='white-space:nowrap;'class='py-0'>
                {{ totalStars }} / {{ race.track.tricks.length*5 }}
                <v-icon color="orange" style='vertical-align:text-top;font-size:48px;margin-left:-10px'>star</v-icon>
              </v-flex>
              <v-flex class='pt-0'>
                <div class='subheading font-weight-thin py-0 text-xs-center grey--text' style='max-height:35px'>
                  Race finished in
                </div>
                <div class='title font-weight-thin py-0 text-xs-center grey--text'>
                  {{ formatedRunningTime }}
                </div>
              </v-flex>
            </v-flex>
            <temp-ranking
              v-if='raceOpen'
              :rule='options.rewardRule'
              :runningTime="race.studentProgress.runningTime"
              :finishPlace='race.studentProgress.finishPlace'
              :style='`${["xs","sm"].includes($vuetify.breakpoint.name) ? "margin-right:50px;margin-top:-50px" : ""}`'
              >
            </temp-ranking>
            <div style='max-width:180px;min-width:180px;margin-top:-48px;margin-left:-30px' v-else-if='race.studentProgress.reward'>
              <v-img :src="`/images/medal ${race.studentProgress.reward}.png`" width="120"/>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    props : {
      race: Object, options: Object, raceOpen: Boolean
    },
    data : function(){
      return {
        formatedStats: []
      }
    },
    computed: {
      totalStars(){
        return _.sumBy(this.race.studentProgress.tricks, o => o.rating);
      },
      formatedRunningTime(){
        return this.$root.formatRunningTime(this.race.studentProgress.runningTime)
      }
    },
    watch: {
      race: {
        handler(val){
          this.formatedStats = [
            {legend: 'Nb of runners', mainFigure: val.formatedProgress.progress.metrics.nbRunners},
            {legend: 'Best performance', icon: 'star', iconColor: 'orange', mainFigure: val.formatedProgress.highScores[0] ? val.formatedProgress.highScores[0].stars : 0},
            {legend: 'Nb tricks tried', mainFigure: val.formatedProgress.progress.metrics.nbTricksTried},
            {legend: 'Nb tricks validated', mainFigure: val.formatedProgress.progress.metrics.nbTricksValidated},
            {legend: 'Nb of finishers', mainFigure: val.formatedProgress.progress.metrics.nbFinishers},
            {legend: 'Nb of perfect scores', mainFigure: val.formatedProgress.progress.metrics.nbPerfect},
            {legend: 'Best completion time', mainFigure: this.$root.formatRunningTime(val.formatedProgress.progress.metrics.bestTime) },
            {legend: 'Total stars awarded', icon: 'star', iconColor: 'orange', mainFigure: val.formatedProgress.progress.metrics.nbStars},
          ];
        },
        immediate: true,
        deep: true
      }
    }
  }
</script>
