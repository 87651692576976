<template>
  <v-hover>
    <div slot-scope='{ hover }' :style='`overflow-x:hidden;width:${hover ? "300px" : "75px"};transition: all .5s`'>
      <v-menu offset-y transition="slide-y-transition" v-model='historyOpen'>
        <template v-slot:activator="{ on }">
          <v-btn large style='width:220px;height:58px;margin-top:0px' round v-on="on" v-if='hover'>
            Trick history
            <v-badge :color="trickHistory.length > 0 ? 'primary' : 'grey'">
              <template v-slot:badge>
                <span>{{ trickHistory.length }}</span>
              </template>
              <v-icon right medium>history</v-icon>
            </v-badge>
          </v-btn>
          <v-btn fab v-on="on" v-else>
            <v-badge :color="trickHistory.length > 0 ? 'primary' : 'grey'">
              <template v-slot:badge>
                <span>{{ trickHistory.length }}</span>
              </template>
              <v-icon medium style='display:inline-flex'>history</v-icon>
            </v-badge>
            <!-- <v-icon medium style='display:inline-flex'>history</v-icon> -->
          </v-btn>
        </template>
        <v-card flat height='36px' class='pt-2 pb-1 pl-2 pr-1' style='display:flex;justify-content:space-between;align-items:center' v-if='trickHistory.length > 0'>
          <div class='subheading grey--text pt-1' style='height:36px;display:flex;align-items:center'>
            Clear history
          </div>
          <v-btn icon @click='trickHistory = []'>
            <v-icon color='error'>delete_sweep</v-icon>
          </v-btn>
        </v-card>
        <v-list two-line style='min-width:300px'>
          <v-list-tile class='grey--text text-xs-center' style='width:100%' v-if='trickHistory.length == 0'>
            History is empty
          </v-list-tile>
          <v-divider/>
          <v-list-tile class='px-1' v-for='trick in trickHistory' :key='trick.id' @click="focusTrick(trick)">
            <v-list-tile-content>
              <v-list-tile-title style='display:flex;justify-content:space-between'>
                <span>
                  {{ trick.title }}
                </span>
              </v-list-tile-title>
              <v-list-tile-sub-title>
                Last perf
                <span v-if='trick.lastPerf'>
                  <span class='orange--text font-weight-bold'>{{ trick.lastPerf }}</span><v-icon small color='orange'>star</v-icon>
                </span>
                <span class='grey--text' v-else> --- </span>
                <span v-if='trick.timeModifier > 0'>
                  (+{{ trick.timeModifier }}s)
                </span>
              </v-list-tile-sub-title>
            </v-list-tile-content>
            <v-progress-circular :size='50' v-model="trick.starCountRatio"  color='orange' class='ml-4' style='white-space:nowrap;font-size:14px'>
              <span :class='trick.starCount == 15 ? `orange--text font-weight-bold` : "grey--text"'>
                {{ trick.starCount }}
              </span>
              <v-icon size='15' color="orange">star</v-icon>
            </v-progress-circular>
          </v-list-tile>
        </v-list>
      </v-menu>
    </div>
  </v-hover>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        trickHistory: [],
        historyOpen: false,
      }
    },
    mounted() {
      eventBus.$on('tryingTrick', this.addTrickToHistory);
      eventBus.$on('retryTrick', this.retryTrick);
      eventBus.$on('trickFinished', this.updateTrick);
    },
    methods: {
      focusTrick(trick){
        eventBus.$emit('focusTrick', trick);
      },
      addTrickToHistory(trick){
        this.trickHistory = this.trickHistory.filter(o => o.id != trick.id);
        trick.starCount = trick.star_count_perfs.reduce((a,s) => a+this.$root.scoreToRating(s.score), 0);
        trick.starCountRatio = trick.starCount*100/15;
        this.trickHistory.push(trick);
        if(this.trickHistory.length > 6){
          this.trickHistory.shift();
        }
      },
      updateTrick(data){
        let trick = this.trickHistory.find(o => o.id == data.id);
        if(trick){
          trick.lastPerf = this.$root.scoreToRating(data.score);
          if(data.starCountUpdate > 0){
            trick.star_count_perfs.push({score: data.score});
            if(trick.star_count_perfs.length > 3){
              trick.star_count_perfs.sort((a,b)=>(a.score > b.score) ? -1 : 1);
              trick.star_count_perfs.pop();
            }
            trick.starCount += data.starCountUpdate;
            trick.starCountRatio = trick.starCount*100/15;
          }
        }
      },
      retryTrick(){
        this.focusTrick(this.trickHistory[this.trickHistory.length-1]);
      }
    }
  }
</script>
