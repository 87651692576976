<template>
    <v-card>
        <v-card-title class='headline' style='display:flex;justify-content:space-between;align-items:center'>
            <span>
                Invite students to {{ className }}
            </span>
            <v-btn @click='pasteFromSpreadsheet' :loading='importingEmails'>
                Paste from clipboard
            </v-btn>
        </v-card-title>
        <v-card-text>
            <div style='display:flex;align-items:center'>
                <div style='flex:1'>
                    <v-form ref="lookupEmailForm" v-model="validEmailForm">
                        <v-text-field label='Student email' :disabled='searchingStudent' v-model='studentEmail'
                            :rules="[rules.required, rules.email]" validate-on-blur :error-messages="this.emailError"
                            @keyup='userTyping' @keydown.enter.prevent='lookupEmail' class='mr-3
                    ' />
                    </v-form>
                </div>
                <div style='width:40px'>
                    <v-progress-circular indeterminate :width='2' v-if='searchingStudent' />
                </div>
            </div>
            <div style='min-height:100px'>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-data-table :headers="studentsInvitedHeaders" :items="allStudentsInvited" disable-initial-sort
                            no-data-text='No student invited yet'
                            :rows-per-page-items='[10, 25, { "text": "$vuetify.dataIterator.rowsPerPageAll", "value": -1 }]'>
                            <template v-slot:items="props">
                                <td>{{ props.item.email }}</td>
                                <td class='text-xs-left'>
                                    <v-edit-dialog :return-value.sync="props.item.name" lazy class='pa-3'>
                                        {{ props.item.name }}
                                        <template v-slot:input>
                                            <v-text-field v-model="props.item.name" :rules="nameRules" label="Edit name" />
                                        </template>
                                    </v-edit-dialog>
                                </td>
                                <td class='pa-0 text-xs-center'>
                                    <v-tooltip top v-if='props.item.exists'>
                                        <template v-slot:activator="{ on }">
                                            <v-icon color="success" v-on="on">done</v-icon>
                                        </template>
                                        <span>Account found</span>
                                    </v-tooltip>
                                    <v-tooltip top v-else>
                                        <template v-slot:activator="{ on }">
                                            <v-icon color="error" v-on="on">close</v-icon>
                                        </template>
                                        <span>Account not found</span>
                                    </v-tooltip>
                                </td>
                                <td class='pa-0 text-xs-center'>
                                    <v-btn icon v-if='props.item.invited' @click='props.item.invited = false'>
                                        <v-icon color='success'>check_circle</v-icon>
                                    </v-btn>
                                    <v-btn icon v-else @click='props.item.invited = true'>
                                        <v-icon color='error'>cancel</v-icon>
                                    </v-btn>
                                </td>
                            </template>
                        </v-data-table>
                    </v-flex>
                </v-layout>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn color="error" :disabled='inviteLoading' style='width:150px' flat @click="cancelInvites">Cancel</v-btn>
            <v-btn color="primary" :loading='inviteLoading' style='width:150px' @click="confirmInvites">Confirm</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props: { className: String, studentsInvited: Array, id: Number },
    data: function () {
        return {
            importingEmails: false,
            inviteLoading: false,
            searchingStudent: false,
            typingTimer: null,
            validEmailForm: true,
            emailError: '',
            studentEmail: '',
            studentsInvitedHeaders: [
                { text: 'Email', value: 'email', align: 'left' },
                { text: 'Name', value: 'name', align: 'center' },
                { text: 'Exists', value: 'exists', align: 'center', width: '50px' },
                { text: 'Invited', value: 'invited', align: 'center', width: '50px' },
            ],
            newStudentsInvited: [],
            nameRules: [
                v => !!v || 'You must enter a name',
                v => (v && v.length > 3) || 'The name must be at least 3 characters long',
                v => (v && v.length < 100) || 'The name must be at most 100 characters long'
            ],
            rules: {
                required: value => !!value || 'Required.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            }
        }
    },
    computed: {
        allStudentsInvited() {
            return [...this.studentsInvited, ...this.newStudentsInvited]
        }
    },
    methods: {
        pasteFromSpreadsheet() {
            this.importingEmails = true;
            navigator.clipboard.readText()
                .then(text => {
                    let rows = text.replace(/\s/g, '*-+>>').split('*-+>>*-+>>').map(s => s.split('*-+>>').join(' '));
                    if (rows.length > 0) {
                        let emails = [];
                        rows.forEach(row => emails.push(row.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)));
                        axios.post(`/api/classes/${this.id}/lookupStudents/multiple`, { emails: emails.flat() })
                            .then(resp => {
                                resp.data.forEach(invite => this.newStudentsInvited.push(invite));
                                this.importingEmails = false;
                            }).catch(err => {
                                console.log(err.response);
                                this.$root.snackError('Failed to import emails');
                            })
                    } else {
                        this.$root.snackError('Failed to read clipboard contents');
                    }
                })
                .catch(err => {
                    this.$root.snackError('Failed to read clipboard contents');
                    console.log(err);
                });
        },
        userTyping() {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(this.lookupEmail, 750);
        },
        lookupEmail() {
            if (this.$refs.lookupEmailForm.validate()) {
                if (this.newStudentsInvited.find(s => s.email == this.studentEmail)) {
                    return this.$root.snackError('This student has already been invited');
                }
                this.emailError = ''
                this.searchingStudent = true;
                axios.get(`/api/classes/${this.id}/lookupStudents/${this.studentEmail}`, {})
                    .then(resp => {
                        if (!this.newStudentsInvited.find(s => s.email == resp.data.email)) {
                            this.newStudentsInvited.push(resp.data);
                        }
                        this.studentEmail = '';
                        this.searchingStudent = false;
                    })
                    .catch(err => {
                        console.log(err.response);
                        if (err.response.status == 422) {
                            this.$root.snackError(err.response.data);
                        }
                        this.studentEmail = '';
                        this.searchingStudent = false;
                    });
            } else {
                this.emailError = 'Invalid e-mail.'
            }
        },
        confirmInvites() {
            this.inviteLoading = true;
            axios.post(`/api/classes/${this.id}/inviteStudents`, { students: this.allStudentsInvited })
                .then(resp => {
                    eventBus.$emit('updatedInvitedStudents', resp.data);
                    this.newStudentsInvited = [];
                    this.inviteLoading = false;
                    // this.inviteDialog = false;
                    eventBus.$emit('closeInviteDialog', {});
                    this.$root.snackSuccess('Students invited !');
                })
                .catch(err => {
                    this.$root.snackError("Could not invite students");
                    console.log(err.response);
                })
        },
        cancelInvites() {
            this.studentEmail = '';
            this.newStudentsInvited = [];
            eventBus.$emit('closeInviteDialog', {});
            // this.inviteDialog = false;
        },
    }
}
</script>
