<template>
  <transition-group name='bounce' style='display:flex;flex-wrap:wrap'>
    <div
      style="display:flex;flex-direction:column;align-items:center;min-width:100px;max-width:100px"
      class='mx-2 mb-3'
      v-for="student in runners" :key="student.user_id"
    >
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-img min-width='80px' max-width='80px' :src='student.user_data.avatar' style='border-radius:50%;' v-on="on"/>
          </template>
          <span>{{ student.user_data.name }}</span>
        </v-tooltip>
      </div>
      <div class='grey--text subheading' :style='student.user_id == $root.user.id ? "font-weight:bold" : ""'>{{ $root.formatRunningTime(student.runningTime) }}</div>
      <v-chip color='success' dark class='title' v-if='student.user_id == $root.user.id'>
        You
      </v-chip>
    </div>
  </transition-group>
</template>
<script>
  export default {
    props : { runners: Array, rule: String },
    data : function(){
      return {

      }
    },
  }
</script>

<style scoped>
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
