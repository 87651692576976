<template>
  <v-dialog v-model='roleDialog' max-width='300px' persistent>
    <v-card class='pa-3'>
      <v-flex class='headline font-weight-thin'>
        You are a...
      </v-flex>
      <v-radio-group v-model="role" row>
        <v-radio label="Student" value="student"></v-radio>
        <v-radio label="Teacher" value="teacher"></v-radio>
      </v-radio-group>
      <v-layout>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submit">Submit</v-btn>
      </v-layout>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props : [],
    data : function(){
      return {
        role: 'student',
        roleDialog: true
      }
    },
    methods : {
      submit(){
        let vm = this
        axios.post('/role', {role: this.role})
        .then(function (response) {
          window.location.replace('/home');
        })
        .catch(function (error) {
          vm.$root.snackError('There was an error attributing the role');
          console.log(error);
        });
      }
    }
  }
</script>
