<template>
  <div style='width:100%'>
    <v-tabs grow fixed-tabs>
      <v-tab href="#student-list">Students</v-tab>
      <v-tab href="#terms">Terms</v-tab>
      <v-tab href="#races">Races</v-tab>
      <v-tab href="#hikes">Hikes</v-tab>
      <!-- <v-tab href="#recap">Recap</v-tab> -->
      <v-tab-item value='student-list'>
        <v-flex xs12>
          <v-card>
            <v-card-title class="py-1">
              <v-chip v-if='studentsInvited.length > 0'>
                {{ `${studentsInvited.length} student${studentsInvited.length > 1 ? "s" : ""} invited` }}
              </v-chip>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="search" hide-actions label="Find student" clearable></v-text-field>
            </v-card-title>
            <v-data-table :headers="studentsHeaders" :items="students" hide-actions :search="search">
              <template v-slot:items="props">
                <td class='px-0'>
                  <v-img style='border-radius:50%;margin:2px;' max-width='40px' :src='props.item.images.avatar'/>
                </td>
                <td v-if='props.item.properName'>{{ props.item.properName.name }}</td>
                <td class='grey--text' v-else>{{ props.item.name }}</td>
                <td class="text-xs-center">{{ props.item.star_count }}</td>
                <td class="text-xs-center">{{ props.item.smile_count }}</td>
                <td class="text-xs-center">{{ props.item.tricks }}</td>
                <td class="text-xs-center" v-if="props.item.latest">{{ props.item.latest }}</td>
                <td class="text-xs-center grey--text" v-else>--</td>
                <td>
                  <v-progress-circular indeterminate color="primary" :width="2" :size="25" class='mx-5 px-5' v-if='props.item.handicapLoading'/>
                  <v-edit-dialog :return-value.sync="props.item.handicap" large lazy  @save="changeHandicap(props.item.id)" v-else>
                    <div>{{ props.item.handicap }}s</div>
                    <template v-slot:input>
                      <v-text-field v-model="props.item.handicap" type='number' min='0' max='45' label="Extra time" single-line counter autofocus></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="text-xs-center">
                  <v-menu bottom left>
                   <template v-slot:activator="{ on }">
                     <v-btn icon v-on="on">
                       <v-icon>more_vert</v-icon>
                     </v-btn>
                   </template>
                   <v-list>
                     <v-list-tile @click='showDetailsDialog(props.item.id)'>
                       <v-list-tile-avatar><v-icon color='success'>bar_chart</v-icon></v-list-tile-avatar>
                       <v-list-tile-content>See stats</v-list-tile-content>
                     </v-list-tile>
                     <v-list-tile @click='showHistoryDialog(props.item.id)'>
                       <v-list-tile-avatar><v-icon color='primary'>list</v-icon></v-list-tile-avatar>
                       <v-list-tile-content>See history</v-list-tile-content>
                     </v-list-tile>
                     <v-divider></v-divider>
                     <v-list-tile @click='showDeleteDialog(props.item.name, props.item.id)'>
                       <v-list-tile-avatar><v-icon color='error'>remove_circle</v-icon></v-list-tile-avatar>
                       <v-list-tile-content>Remove</v-list-tile-content>
                     </v-list-tile>
                   </v-list>
                 </v-menu>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-tab-item>
      <v-tab-item value='terms'>
        <v-card>
          <v-card-title class="py-1">
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" hide-actions label="Find student" clearable></v-text-field>
          </v-card-title>
          <div class="px-3 pb-3">
              <v-expansion-panel class="elevation-4">
                  <v-expansion-panel-content>
                      <template v-slot:header>
                        <div style="display:flex;">
                          <div class='grey--text headline'>Turn into grades</div>
                          <v-switch hide-details class="mt-0 ml-3" v-model="toGrades" :label="toGrades ? 'ON' : 'OFF'" @click.stop="toGrades = !toGrades"/>
                        </div>
                      </template>
                      <v-card class='px-4'>
                        <v-slider label="Max grade" v-model="maxGrade" ticks="always" tick-size="3" thumb-label="always" min="2" max="10"/>
                          <div style="display:flex;flex-wrap:wrap;gap:10px;" class="mb-2">
                            <v-text-field 
                              v-for="i in maxGrade"
                              :key="i"
                              v-model="gradeLimits[i-1].value"
                              :append-icon="gradeLimits[i - 1].lock ? 'lock' : 'lock_open'"
                              @click:append="toggleGradeLimitLock(i)"
                              style="min-width: 150px;max-width: 150px;"
                              :outline="gradeLimits[i - 1].lock" 
                              :label="`Min pts for ${i}`"
                              type="number" min="0" max="10000"
                              step="1"
                              hide-details
                              :rules="[v => i == 1 || v > gradeLimits[i - 2].value || 'Incorrect value']"
                              validate-on-blur
                            />  
                          </div>
                          <div class="my-2" style="display:flex;">
                            <v-btn style="width:150px;" flat color="error" @click="initGradeLimits">
                              Reset
                            </v-btn>
                            <v-spacer/>
                            <v-tooltip bottom v-if="noGradeLimitLocked">
                              <template v-slot:activator="{ on }">
                                <div v-on="on">
                                  <v-btn style="width:150px;" flat color="primary" disabled>
                                    Linearize
                                  </v-btn>
                                </div>
                              </template>
                              <span>Lock at least one grade limit</span>
                            </v-tooltip>
                            <v-btn style="width:150px;" flat color="primary" @click="linearizeGradeLimits" v-else>
                              Linearize
                            </v-btn>
                          </div>
                      </v-card>
                  </v-expansion-panel-content>
              </v-expansion-panel>
          </div>
          <v-data-table :headers="termsHeaders" :items="termsItems" hide-actions :search="search">
            <template v-slot:items="props">
              <td class='px-0'>
                <v-img style='border-radius:50%;margin:2px;' max-width='40px' :src='props.item.avatar'/>
              </td>
              <td v-if='props.item.properName'>{{ props.item.properName.name }}</td>
              <td class='grey--text' v-else>{{ props.item.name }}</td>
              <td :class="`text-xs-center ${index == activeTerm.nb - 1 ? 'font-weight-bold' : 'grey--text text-darken-2'}`" v-for="(score, index) in props.item.scores">
                {{ scoreToGrade(score) }}
              </td>
              <td class="text-xs-center">{{ props.item.total }}</td>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item value='races'>
        <v-flex xs12>
          <v-card>
            <v-card-title class="pb-0">
              <v-select solo label="Choose race" v-model="focusedRace" :items="titles.races" :loading='loadingRaces' :disabled='loadingRaces' @change="getraceItems" class="mr-3 mt-3"></v-select>
              <v-text-field v-model="search" append-icon="search" label="Find student" clearable></v-text-field>
            </v-card-title>
            <v-data-table :headers="raceHeaders" :items="raceItems" hide-actions :search="search" v-if="raceHeaders.length">
              <template slot="headerCell" slot-scope="props">
                <v-tooltip top v-if='props.header.fullText'>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ props.header.text }}
                    </span>
                  </template>
                  <span>
                    {{ props.header.fullText }}
                  </span>
                </v-tooltip>
                <span v-else>
                  {{ props.header.text }}
                </span>
              </template>
              <template v-slot:items="props">
                <td :style="`width:200px;${props.item.color}`" class="pl-2 pr-0 py-0" v-if='props.item.properName'>{{ props.item.properName.name }}</td>
                <td :style="`width:200px;${props.item.color}`" class="pl-2 pr-0 py-0 grey--text" v-else>{{ props.item.name }}</td>
                <td
                  :style="`${props.item.color}`"
                  class="text-xs-center subheading pa-0"
                  v-for="trick in props.item.tricks"
                  :class="`${trick.rating >= activeRaceOptions.trickValidation ? 'font-weight-bold success--text' : trick.rating == 0 ? 'grey--text text--lighten-1' : ''}`"
                  :key="`trick-${trick.id}-${trick.rating}`"
                >
                  {{ trick.rating }}
                </td>
                <td :style="props.item.color" class="font-weight-bold text-xs-center pa-0">{{ props.item.total }}</td>
                <td :style="props.item.color" class="text-xs-center pa-0" v-if='activeRaceOptions.rewardRule == "fastest"'>{{ props.item.runningTime | formatRunningTime }}</td>
                <td :style="props.item.color" class="text-xs-center pa-0" v-else>{{ props.item.finishPlace }}</td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-tab-item>
      <v-tab-item value='hikes'>
        <v-flex xs12>
          <v-card>
            <v-card-title class="pb-0">
              <v-select solo label="Choose hike" v-model="focusedHike" :items="titles.hikes" :loading='loadingHikes' :disabled='loadingHikes' @change="gethikeItems" class="mr-3 mt-3"></v-select>
              <v-text-field v-model="search" append-icon="search" label="Find student" clearable></v-text-field>
            </v-card-title>
            <v-card-text class='pt-0' v-if="focusedHike > 0">
              <v-layout row wrap>
                <v-flex xs12 sm8>
                  <v-select outline hide-details label="Section" v-model="activeSection" :items="hikeSectionsOptions"></v-select>
                </v-flex>
                <v-flex xs12 sm4 :class='$vuetify.breakpoint.name == "xs" ? "mt-1" : "pl-1"'>
                  <v-select outline hide-details label="Display" v-model="hikeDisplay" :items="hikeDisplayOptions"></v-select>
                </v-flex>
              </v-layout>
              <v-window v-model='activeSection'>
                <v-window-item :key='0'>
                  <v-data-table :headers="globalHikeHeaders" :items="globalHikeItems" hide-actions :search="search">
                    <template slot="headerCell" slot-scope="props">
                      <v-tooltip top v-if='props.header.fullText'>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">
                            {{ props.header.text }}
                          </span>
                        </template>
                        <span>
                          {{ props.header.fullText }}
                        </span>
                      </v-tooltip>
                      <span v-else>
                        {{ props.header.text }}
                      </span>
                    </template>
                    <template v-slot:items="props">
                      <td v-if='props.item.student.properName'>{{ props.item.student.properName.name }}</td>
                      <td class='grey--text' v-else>{{ props.item.student.name }}</td>
                      <td
                        class="text-xs-center"
                        :class="`${perf.percent >= 80 ? 'font-weight-bold success--text' : perf.raw == 0 ? 'grey--text text--lighten-1' : ''}`"
                        v-for="(perf, index) in props.item.perfs" :key='`globalHikePerf-${index}`' v-if='hikeDisplay=="sc"'
                      >
                        {{ perf.raw }}
                      </td>
                      <td
                        class="text-xs-center"
                        :class="`${perf.percent >= 80 ? 'font-weight-bold success--text' : perf.raw == 0 ? 'grey--text text--lighten-1' : ''}`"
                        v-for="(perf, index) in props.item.perfs" :key='`globalHikePerf-${index}`' v-if='hikeDisplay=="pc"'
                      >
                        {{ perf.percent }}%
                      </td>
                      <td class="text-xs-center" v-for="(perf, index) in props.item.perfs" :key='`globalHikePerf-${index}`' v-if='hikeDisplay=="dg"'>
                        <v-progress-circular
                          :size='30' :width='8' :rotate="-90"
                          :value="perf.percent"
                          :color="hikeDiagramColor(perf.percent)"
                        />
                      </td>
                      <td
                        class="text-xs-center font-weight-bold subheading"
                        :class="`${props.item.totalPercent >= 80 ? 'success--text' : props.item.total == 0 ? 'grey--text text--lighten-1' : ''}`"
                        v-if='hikeDisplay=="sc"'
                      >
                        {{ props.item.total }}
                      </td>
                      <td
                        class="text-xs-center font-weight-bold subheading"
                        :class="`${props.item.totalPercent >= 80 ? 'success--text' : props.item.total == 0 ? 'grey--text text--lighten-1' : ''}`"
                        v-if='hikeDisplay=="pc"'
                      >
                        {{ props.item.totalPercent }}%
                      </td>
                      <td class="text-xs-center font-weight-bold subheading" v-if='hikeDisplay=="dg"'>
                        <v-progress-circular
                          :size='40' :width='10' :rotate="-90"
                          :value="props.item.totalPercent"
                          :color="hikeDiagramColor(props.item.totalPercent)"
                        />
                      </td>
                    </template>
                  </v-data-table>
                </v-window-item>
                <v-window-item v-for="(section, index) in sectionsData" :key='index+1'>
                  <v-data-table :headers="section.headers" :items="section.items" hide-actions :search="search">
                    <template slot="headerCell" slot-scope="props">
                      <v-tooltip top v-if='props.header.fullText'>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">
                            {{ props.header.text }}
                          </span>
                        </template>
                        <span>
                          {{ props.header.fullText }}
                        </span>
                      </v-tooltip>
                      <span v-else>
                        {{ props.header.text }}
                      </span>
                    </template>
                    <template v-slot:items="props">
                      <td v-if='props.item.student.properName'>{{ props.item.student.properName.name }}</td>
                      <td class='grey--text' v-else>{{ props.item.student.name }}</td>
                      <td
                        class="text-xs-center"
                        :class="`${trick.stars >= 12 ? 'font-weight-bold success--text' : trick.stars == 0 ? 'grey--text text--lighten-1' : ''}`"
                        v-for="trick in props.item.tricks" :key='`sectionTrickPerf-${trick.id}`' v-if='hikeDisplay=="sc"'
                      >
                        {{ trick.stars }}
                      </td>
                      <td
                        class="text-xs-center"
                        :class="`${trick.stars >= 12 ? 'font-weight-bold success--text' : trick.stars == 0 ? 'grey--text text--lighten-1' : ''}`"
                        v-for="trick in props.item.tricks" :key='`sectionTrickPerf-${trick.id}`' v-if='hikeDisplay=="pc"'
                      >
                        {{ trick.percent }}%
                      </td>
                      <td class="text-xs-center" v-for="trick in props.item.tricks" :key='`sectionTrickPerf-${trick.id}`' v-if='hikeDisplay=="dg"'>
                        <v-progress-circular
                          :size='30' :width='8' :rotate="-90"
                          :value="trick.percent"
                          :color="hikeDiagramColor(trick.percent)"
                        />
                      </td>
                      <td
                        class="text-xs-center font-weight-bold subheading"
                        :class="`${props.item.totalPercent >= 80 ? 'success--text' : props.item.totalStars == 0 ? 'grey--text text--lighten-1' : ''}`"
                        v-if='hikeDisplay=="sc"'
                      >
                        {{ props.item.totalStars }}
                      </td>
                      <td class="text-xs-center font-weight-bold subheading" v-if='hikeDisplay=="pc"'>
                        {{ props.item.totalPercent }}%
                      </td>
                      <td class="text-xs-center font-weight-bold subheading" v-if='hikeDisplay=="dg"'>
                        <v-progress-circular
                          :size='30' :width='8' :rotate="-90"
                          :value="props.item.totalPercent"
                          :color="hikeDiagramColor(props.item.totalPercent)"
                        />
                      </td>
                    </template>
                  </v-data-table>
                </v-window-item>
              </v-window>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-tab-item>
      
    </v-tabs>
    <v-dialog v-model="deleteDialog" width="300px">
      <v-card>
        <v-card-title class="headline">Remove {{ deleteName }}</v-card-title>
        <v-card-text>Are you sure you want to remove this student from this class ?</v-card-text>
        <v-card-actions>
          <v-layout row>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="deleteDialog=false">No</v-btn>
            <v-btn color="error" @click="proceedDelete(deleteId)">Yes</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import moment from 'moment-timezone';

  export default {
    props : {classId: Number, students: Array, terms: Array, titles: Object, studentsInvited: Array },
    data : function(){
      return {
        studentsRecap: [],
        search: '',
        studentsHeaders: [
          {text: '', align: 'center', value: 'lastName', sortable: false},
          {text: 'Name', align: 'center', value: 'lastName'},
          {text: 'Stars count', align: 'center', value: 'star_count'},
          {text: 'Smile count', align: 'center', value: 'smile_count'},
          {text: 'Nb tricks this week', align: 'center', value: 'tricks'},
          {text: 'Latest trick', align: 'center', value: 'latestRaw'},
          {text: 'Extra time', align: 'center', value: 'adjustment'},
          {text: 'Actions', align: 'center', sortable: false}
        ],
        recapHeaders: [
          {text: 'Name', align: 'center', value: 'lastName'},
          {text: 'Final grade', align: 'center', value: 'stars'},
          {text: 'Grand total', align: 'center', value: 'totalPoints'},
          {text: 'Star count', align: 'center', value: 'starPoints'},
          {text: 'Smile count', align: 'center', value: 'smilePoints'},
          {text: 'Races rewards', align: 'center', value: 'rewardsPoints'},
          {text: 'Hikes stars', align: 'center', value: 'hikesPoints'},
        ],
        globalHikeHeaders: [],
        globalHikeItems: [],
        sectionsData: [],
        raceHeaders: [],
        raceItems: [],
        loadingRaces: false,
        loadingHikes: false,
        nbOfTricks: 0,
        activeRaceOptions: {},
        focusedRace: 0,
        focusedHike: 0,
        hikeSectionsOptions: [],
        hikeDisplayOptions: [
          {text: 'Star count', value: 'sc'},
          {text: 'Percentages', value: 'pc'},
          {text: 'Diagram', value: 'dg'},
        ],
        hikeDisplay: 'sc',
        // recapData: [],
        // recapDataLoaded: false,
        // recapDataLoading: false,
        activeSection: 0,
        activeTab: null,
        deleteName: '',
        deleteId: null,
        deleteDialog: false,
        detailsDialog: false,
        focusedStudent: {},
        races: [],
        medals: ['gold', 'silver', 'bronze'],
        rowColor: {gold: 'rgba(255, 215, 0, 0.4)', silver: 'rgba(192, 192, 192, 0.5)', bronze: 'rgba(80, 50, 20, 0.3)', finished: 'rgba(76, 175, 80, 0.15)'},
        maxGrade: 10,
        gradeLimits: [],
        toGrades: false
      }
    },
    computed:{
      termsHeaders(){
        const headers = [{ text: '', align: 'center', sortable: false }, {text: 'Name', value: 'lastName'}];
        this.terms.forEach(t => headers.push({ text: t.name, align: 'center', value: `scores[${t.nb-1}]`, class: t.active ? 'font-weight-bold' : ''}));
        headers.push({ text: 'Total', align: 'center', value: 'total'});

        return headers;
      },
      termsItems(){
        const items = [];
        this.students.forEach(s => {
          let scores = Array.isArray(s.pivot.term_scores) ? s.pivot.term_scores : JSON.parse(s.pivot.term_scores);
          if(!scores){
            scores = [];
          }
          while(scores.length < this.terms.length){
            scores.push(0);
          }
          scores.slice(this.terms.length - 1);
          items.push({
            id: s.id,
            avatar: s.images.avatar,
            name: s.name,
            properName: s.properName,
            lastName: s.lastName,
            scores,
            total: scores.reduce((acc, val) => acc + val, 0)
          });
        });
        
        return items;
      },
      activeTerm(){
        return this.terms.find(t => t.active);
      },
      noGradeLimitLocked(){
        return this.gradeLimits.every(g => !g.lock);
      }
    },
    filters:{
      formatRunningTime(time){
        if(isNaN(time)) return '';
        if(time < 60) return `${time}s`;
        if(time < 3600){
          let minutes = Math.floor(time/60);
          let seconds = time % 60;
          if(seconds < 10){
            return `${minutes}m0${seconds}s`;
          } else {
            return `${minutes}m${seconds}s`;
          }
        }
        if(time < 3600*24){
          let hours = Math.floor(time/3600);
          let minutes = Math.floor((time % 3600)/60);
          if(minutes < 10){
            return `${hours}h0${minutes}m`;
          } else {
            return `${hours}h${minutes}m`;
          }
        }
        let days = Math.floor(time/(3600*24));
        let hours = Math.floor((time % (3600*24))/3600);
        if(hours < 10){
          return `${days}d0${hours}h`;
        } else {
          return `${days}d${hours}h`;
        }
      }
    },
    created(){
      this.initGradeLimits();
    },
    mounted(){
      eventBus.$on('racePerf', this.handleRacePerf);
    },
    beforeDestroy(){
      eventBus.$off('racePerf', this.handleRacePerf);
    },
    methods : {
      changeHandicap(id){
        let student = this.students.filter(s => s.id == id)[0];
        student.handicapLoading = true;
        axios.post(`/api/classes/${this.classId}/handicap`, {userId: id, handicap: student.handicap})
        .then(resp => {
          student.handicapLoading = false;
          this.$root.snackSuccess("Extra time updated");
        })
        .catch(err => console.log(err));
      },
      handleRacePerf(e){
        if(e.id == this.focusedRace){
          this.updateRaceItems();
        }
      },
      updateRaceItems(){
        axios.get(`/api/classes/${this.classId}/races/${this.focusedRace}`, {})
        .then(resp => {
          this.raceItems = [];
          resp.data.perfs.forEach( detail => {
            let row = {name: detail.student.name, properName: detail.student.properName, lastName: detail.student.lastName, userId: detail.student.id};
            let tricks = [];
            let total = 0;
            detail.tricks.forEach( trick => {
              tricks.push({id: trick.id, rating: trick.rating});;
              total += trick.rating;
            });
            row['tricks'] = tricks;
            row['nbOfStars'] = total;
            row['total'] = `${total}/${detail.totalStars}`;
            if (detail.reward == null){
              row['color'] = '';
              row['runningTime'] = '--';
              row['finishPlace'] = '--';
            } else {
              row['color'] = `background:${this.rowColor[detail.reward.reward]}`;
              row['runningTime'] = detail.reward.runningTime;
              row['finishPlace'] = detail.reward.finishPlace;
            }
            this.raceItems.push(row);
          });
        }).catch(err => console.log(err));
      },
      getraceItems(){
        this.loadingRaces = true;
        let vm = this;
        axios.get(`/api/classes/${this.classId}/races/${this.focusedRace}`, {})
        .then(function (response) {
          vm.activeRaceOptions = JSON.parse(response.data.race.options);
          let racesDetails = response.data.perfs;
          vm.raceHeaders = [{text: 'Name', align: 'center', value: 'lastName'}];
          racesDetails[0].tricks.forEach( (trick, index) => {
            let colTitle = trick.title.length < 15 ? trick.title : trick.title.substr(0, 6)+'...'+trick.title.substr(-5, 5);
            vm.raceHeaders.push({text: colTitle, fullText: trick.title, align: 'center', value: `tricks[${index}].rating`, class: 'pa-0 text-truncate'});
            vm.nbOfTricks++;
          });
          vm.raceHeaders.push({text: 'Total', align: 'center', value: 'nbOfStars', class: 'px-1'});
          if(vm.activeRaceOptions.rewardRule == 'fastest'){
            vm.raceHeaders.push({text: 'Running time', align: 'center', value: 'runningTime', class: 'px-1'});
          } else {
            vm.raceHeaders.push({text: 'Finish Place', align: 'center', value: 'finishPlace', class: 'px-1'});
          }
          vm.raceItems = [];
          racesDetails.forEach( detail => {
            let row = {name: detail.student.name, properName: detail.student.properName, lastName: detail.student.lastName, userId: detail.student.id};
            let tricks = [];
            let total = 0;
            detail.tricks.forEach( trick => {
              tricks.push({id: trick.id, rating: trick.rating});
              total += trick.rating;
            });
            row['tricks'] = tricks;
            row['nbOfStars'] = total;
            row['total'] = `${total}/${detail.totalStars}`;
            if (detail.reward == null){
              row['color'] = '';
              row['runningTime'] = '--';
              row['finishPlace'] = '--';
            } else {
              row['color'] = `background:${vm.rowColor[detail.reward.reward]}`;
              row['runningTime'] = detail.reward.runningTime;
              row['finishPlace'] = detail.reward.finishPlace;
            }
            vm.raceItems.push(row);
          });
          vm.loadingRaces = false;
        })
        .catch(function (error) {
          console.log(error) // TODO fix this
        });
      },
      gethikeItems(){
        this.loadingHikes = true;
        axios.get(`/api/classes/${this.classId}/hikes/${this.focusedHike}`, {})
        .then(resp => {
          console.log(resp.data)
          this.hikeSectionsOptions = resp.data.sections.options;
          this.globalHikeHeaders = [{text: 'Name', align: 'center', value: 'student.lastName'}];
          resp.data.sections.tracks.forEach((track, index) => {
            let colTitle = track.title.length < 25 ? track.title : track.title.substr(0, 12)+'...'+track.title.substr(-8, 8);
            this.globalHikeHeaders.push({text: colTitle, fullText: track.title, align: 'center', value: `perfs[${index}].raw`, class: 'pa-0 text-truncate'});
          });
          this.globalHikeHeaders.push({text: 'Total Stars', align: 'center', value: 'total'});
          this.globalHikeItems = resp.data.globalPerfs;
          this.sectionsData = [];
          resp.data.sections.tracks.forEach((track, index1) => {
            let tricksHeader = [{text: 'Name', align: 'center', value: 'student.lastName'}];
            track.tricks.forEach((trick, index2) => {
              let colTitle = trick.title.length < 15 ? trick.title : trick.title.substr(0, 6)+'...'+trick.title.substr(-5, 5);
              tricksHeader.push({text: colTitle, fullText: trick.title, align: 'center', value: `tricks[${index2}].stars`, class: 'pa-0 text-truncate'});
            });
            tricksHeader.push({text: 'Total', align: 'center', value: `totalStars`});
            this.sectionsData.push({headers: tricksHeader, items: resp.data.perfsBySection[index1]});
          });
          this.loadingHikes = false;
        });
      },
      hikeDiagramColor(val){
        if(val <= 25) return 'error';
        if(val <= 50) return 'warning';
        if(val <= 75) return 'primary';
        return 'success';
      },
      addStudent(studentId){
        let vm = this;
        axios.get(`/api/classes/${this.classId}/students/${studentId}`, {})
        .then(function (response) {
          vm.students.push(response.data);
          if(vm.focusedRace > 0){
            vm.getraceItems();
          }
        })
        .catch(function (error) {
          console.log(error) // TODO catch error
        });
      },
      showDetailsDialog(userId){
        eventBus.$emit('showStats', {userId: userId, classId: this.classId, isTeacher: false});
      },
      showHistoryDialog(userId){
        eventBus.$emit('showHistory', {userId: userId, classId: this.classId, isTeacher: false});
      },
      showDeleteDialog(name, id){
        this.deleteName = name;
        this.deleteId = id;
        this.deleteDialog = true;
      },
      proceedDelete(id){
        let vm = this
        axios.delete(`/api/classes/${this.classId}/students/${id}`, {})
        .then(function (response) {
          let index = _.findIndex(vm.students, stu => {
            return stu.id == response.data
          });
          vm.students.splice(index, 1);
          vm.deleteId = '';
          vm.deleteName = '';
          vm.deleteDialog = false;
          if(vm.focusedRace > 0){
            vm.getraceItems();
          }
        })
        .catch(function (error) {
          console.log(error)// TODO catch error
        });
      },
      scoreToGrade(points){
        if (this.toGrades){
          let index = this.maxGrade - 1;
          while(index >= 0 && points < this.gradeLimits[index].value){
            index--;
          }
          return index + 1;
        }
        return points;
      },
      initGradeLimits(){
        this.gradeLimits = [];
        for (let i = 1; i <= this.maxGrade; i++) {
          this.gradeLimits.push({ value: 100 * i, lock: false });
        } 
      },
      linearizeGradeLimits(){
        let mainIndex = 0;
        const rankArray = [];
        let unlockStreak = 1;
        while(mainIndex < this.maxGrade){
          if(this.gradeLimits[mainIndex].lock){
            for(let i=1 ; i<=unlockStreak ; i++){
              rankArray.push({ rank: i, outOf: unlockStreak });
            }
            unlockStreak = 1;
          } else {
            unlockStreak++;
          }
          mainIndex++;
        }
        let step = 0;
        let base = 0;
        let rank = 1;
        for(let i=0 ; i<this.maxGrade ; i++){
          if(rankArray[i] && rankArray[i].rank == 1){
            base = i == 0 ? 0 : this.gradeLimits[i-1].value;
            step = Math.floor((this.gradeLimits[i+rankArray[i].outOf-1].value-base)/(rankArray[i].outOf));
            rank = 1;
          }
          if(!this.gradeLimits[i].lock){
            this.gradeLimits[i].value = base + rank*step;
          }
          rank++;
        }
      },
      toggleGradeLimitLock(i){
        this.gradeLimits[i-1].lock = !this.gradeLimits[i - 1].lock;
      }
    }
  }
</script>
