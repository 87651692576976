import home from './components/Views/home-view';
import classes from './components/Views/classes-view';
import singleClass from './components/Views/single-class-view';
import schoolNames from './components/Views/school-names-view';
import tricks from './components/Views/tricks-view';
import tracks from './components/Views/tracks-view';
import races from './components/Views/races-view';
import allRaces from './components/Views/allRaces-view';
import hikes from './components/Views/hikes-view';
import marathons from './components/Views/marathons-view';
import marathonsAll from './components/Views/marathons-all-view';
import marathonClasses from './components/Views/marathonClasses-view';
import marathonRanking from './components/Views/marathonRanking-view';
import about from './components/Views/about-view';
import messages from './components/Views/messages-view';
import admin from './components/Views/admin-view';
import suspicious from './components/Views/suspicious-view';
import collectibles from './components/Views/collectibles-view';
import adminCollectibles from './components/Views/admin-collectibles-view';
import adminCollections from './components/Views/admin-collections-view';
import announcements from './components/Views/announcements-view';
import globalAnnoucements from './components/Views/global-announcements-view';
import schools from './components/Views/schools-view';
import singleSchool from './components/Views/single-school-view';
import challengeCanvas from './components/Views/admin-challenge-canvas-view';
import canvas from './components/Views/canvas-view';
import riddles from './components/Views/riddles-view';
import errors from './components/Views/errors-view';

export default {
    routes: [
      {
        path: '/home',
        component: home,
        navRoute: true,
        requiresClassesData: false,
        title: 'Home',
        icon: 'home'
      },
      {
        path: '/tricks',
        component: tricks,
        navRoute: true,
        requiresClassesData: false,
        title: 'Tricks',
        icon: 'grade'
      },
      {
        path: '/tracks',
        component: tracks,
        navRoute: true,
        requiresClassesData: false,
        title: 'Tracks',
        icon: 'show_chart'
      },
      {
        path: '/classes',
        component: classes,
        navRoute: true,
        requiresClassesData: true,
        title: 'Classes',
        icon: 'group'
      },
      {
        path: '/single-class/:code',
        component: singleClass,
        navRoute: false,
        requiresClassesData: true,
        title: 'Class',
        icon: 'group'
      },
      {
        path: '/single-class/:code/school-names',
        component: schoolNames,
        navRoute: false,
        requiresClassesData: true,
        title: 'School names',
        icon: 'group'
      },
      {
        path: '/races',
        component: races,
        navRoute: true,
        requiresClassesData: true,
        title: 'Races',
        icon: 'directions_run'
      },
      {
        path: '/all-races',
        component: allRaces,
        navRoute: false,
        requiresClassesData: true,
        title: 'Races',
        icon: 'directions_run'
      },
      {
        path: '/hikes',
        component: hikes,
        navRoute: true,
        requiresClassesData: true,
        title: 'Hikes',
        icon: 'directions_walk'
      },
      {
        path: '/about',
        component: about,
        navRoute: false,
        requiresClassesData: false,
        title: 'About',
        icon: 'help_outline'
      },
      {
        path: '/admin',
        component: admin,
        navRoute: false,
        requiresClassesData: false,
        title: 'Messages',
        icon: 'dashboard'
      },
      {
        path: '/messages',
        component: messages,
        navRoute: false,
        requiresClassesData: false,
        title: 'Messages',
        icon: 'mail'
      },
      {
        path: '/suspicious',
        component: suspicious,
        navRoute: false,
        requiresClassesData: false,
        title: 'Suspicious',
        icon: 'report'
      },
      {
        path: '/collectibles',
        component: collectibles,
        navRoute: false,
        requiresClassesData: false,
        title: 'Collectibles',
        icon: 'camera'
      },
      {
        path: '/admin/collectibles',
        component: adminCollectibles,
        navRoute: false,
        requiresClassesData: false,
        title: 'Collectibles',
        icon: 'camera'
      },
      {
        path: '/admin/collections',
        component: adminCollections,
        navRoute: false,
        requiresClassesData: false,
        title: 'Collections',
        icon: 'camera'
      },
      {
        path: '/marathons/:id',
        component: marathons,
        navRoute: false,
        requiresClassesData: true,
        title: 'Marathon',
        icon: 'emoji_events'
      },
      {
        path: '/marathons-all',
        component: marathonsAll,
        navRoute: false,
        requiresClassesData: true,
        title: 'All marathons',
        icon: 'emoji_events'
      },
      {
        path: '/marathons/:id/classes',
        component: marathonClasses,
        navRoute: false,
        requiresClassesData: true,
        title: 'Marathon classes',
        icon: 'emoji_events'
      },
      {
        path: '/marathons/:id/ranking',
        component: marathonRanking,
        navRoute: false,
        requiresClassesData: true,
        title: 'Marathon ranking',
        icon: 'emoji_events'
      },
      {
        path: '/announcements',
        component: announcements,
        navRoute: false,
        requiresClassesData: false,
        title: 'Announcements',
        icon: 'bookmark'
      },
      {
        path: '/announcements/global',
        component: globalAnnoucements,
        navRoute: false,
        requiresClassesData: true,
        title: 'Global announcements',
        icon: 'bookmark'
      },
      {
        path: '/schools',
        component: schools,
        navRoute: false,
        requiresClassesData: true,
        title: 'Schools',
        icon: 'school'
      },
      {
        path: '/schools/:id',
        component: singleSchool,
        navRoute: false,
        requiresClassesData: true,
        title: 'School',
        icon: 'school'
      },
      {
        path: '/admin/challengeCanvas',
        component: challengeCanvas,
        navRoute: false,
        requiresClassesData: false,
        title: 'Challenge Canvas',
        icon: 'image'
      },
      {
        path: '/admin/riddles',
        component: riddles,
        navRoute: false,
        requiresClassesData: false,
        title: 'Riddles',
        icon: 'image'
      },
      {
        path: '/canvas',
        component: canvas,
        navRoute: false,
        requiresClassesData: false,
        title: 'Challenge Canvas',
        icon: 'image'
      },
      {
        path: '/admin/errors',
        component: errors,
        navRoute: false,
        requiresClassesData: false,
        title: 'Errors',
        icon: 'bug_report'
      },
  ]
}
