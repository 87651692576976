<template>
  <v-layout row wrap>
    <transition-group name='bounce'>
      <v-hover v-for='(reward, index) in formatedRewards' :key='`rew-${index}`'>
        <v-card
          width='100%'
          max-width='300px'
          :height='hover ? "100px" : "50px"'
          style='overflow-y:hidden'
          :style='hover ? "" : "border-radius:20px"'
          class='ma-1 px-1'
          slot-scope="{ hover }"
        >
          <div style='height:50px;display:flex;align-items:center;justify-content:space-between'>
            <v-icon color='grey lighten-2'>{{ reward.iconLeft.name }}</v-icon>
            <span class='title'>
              {{ reward.title }}
            </span>
            <span class='title'>
              {{ reward.iconRight.prefix }}<v-icon :color='reward.iconRight.color'>{{ reward.iconRight.name }}</v-icon>
            </span>
          </div>
          <div class='px-1' style='height:50px;display:flex;align-items:center;justify-content:center'>
            {{ reward.comment }}
          </div>
        </v-card>
      </v-hover>
    </transition-group>
  </v-layout>
</template>
<script>
  export default {
    props : { rewards: Array },
    data : function(){
      return {
        formatedRewards: []
      }
    },
    watch:{
      rewards:{
        handler: function(val){
          this.formatedRewards = [];
          val.forEach(reward => {
            switch(reward.type){
              case 'stars':
                this.formatedRewards.push({
                  iconLeft: {name : 'star'},
                  iconRight: {name: 'star', color: 'orange', prefix: `+${reward.added}`},
                  title: 'Stars added',
                  comment: `Your star count is now ${reward.total}`
                });
              break;
              case 'coins':
                this.formatedRewards.push({
                  iconLeft: {name : 'radio_button_checked'},
                  iconRight: {name: 'radio_button_checked', color: 'primary', prefix: `+${reward.added}`},
                  title: 'Coins earned',
                  comment: `Your coin stack is now ${reward.total}`
                });
              break;
              case 'race-trick':
                this.formatedRewards.push({
                  iconLeft: {name : 'directions_run'},
                  iconRight: {name: 'done', color: 'success', prefix: ''},
                  title: 'Trick validated',
                  comment: `for the race ${reward.raceTitle}`
                });
              break;
              case 'race-finished':
                this.formatedRewards.push({
                  iconLeft: {name : 'directions_run'},
                  iconRight: {name: 'check_circle', color: 'success', prefix: ''},
                  title: 'Race finished !',
                  comment: `You just finished ${reward.raceTitle}`
                });
              break;
              case 'hike':
                this.formatedRewards.push({
                  iconLeft: {name : 'directions_walk'},
                  iconRight: {name: 'stars', color: 'green', prefix: `+${reward.added}`},
                  title: 'Hike progress',
                  comment: `Stars added to ${reward.hikeTitle}`
                });
              break;
            }
          });
        },
        immediate: true,
        deep: true
      }
    },
    methods: {

    }
  }
</script>

<style scoped>
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
