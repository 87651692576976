<template>
    <v-card width="324" height="432" color="grey lighten-3" class="py-2">
        <div>
            <div style="display:flex;justify-content:center;margin:auto;" class="my-1" v-for="(row, i) in rows" :key="i">
                <riddle-tile
                    style="margin:5px;"
                    v-for="(tile, j) in row" 
                    :key="`${j}-${chars}`" 
                    :status="tile.status"
                    :value="tile.value"
                    :delay="j*100"
                />
            </div>
        </div>
        <div class="mt-2">
            <div style="display:flex;justify-content:center;margin:auto;" v-for="(keyRow, i) in keys" :key="i">
                <riddle-key style="margin:2px;" v-for="(key, j) in keyRow" :key="j" :char="key"/>
            </div>
        </div>
        <Transition>
            <div class="errorDisplay" v-if="error.length">
                <v-chip label dark color="error" class="font-weight-bold">
                    {{ error }}
                </v-chip>
            </div>
        </Transition>
        <Transition name="drop">
            <div class="overlay pa-4" v-if="finished || showHelp"/>
        </Transition>
        <Transition name="zoom">
                <div width="350" class="finishCard pa-2" :style="finishData.border" v-if="riddle.data.status != 'pending' && showFinishCard && finished">
                    <div class="headline text-xs-center">
                        {{ finishData.title }}
                    </div>
                    <div class="grey--text text-xs-center mb-2">
                        {{ finishData.text }}
                    </div>
                    <div>
                        <div v-for="(row, i) in finishData.rows" :key="i" style="display:flex;justify-content:center;">
                            <div v-for="(sq, j) in row" class="ma-1 elevation-2" style="width:20px;height:20px;" :style="`background:${sqColor(sq)}`" :key="j"/>
                        </div>
                    </div>
                    <div class="mt-3">
                        <v-btn small block color="primary" @click="share">
                            Share result
                        </v-btn>
                    </div>
                    <div class="mt-2" v-if="riddle.data.status == 'finished'">
                        <div class="caption" v-if="credit > 0">
                            You can now reveal <b>{{ credit }}</b> pixel<span v-if="credit > 1">s</span> from your current canvas.
                        </div>
                        <div class="caption" v-else>
                            You used all your credit. Wait for tomorrow's mystery calculation to reveal more of your canvas.
                        </div>
                        <v-btn small block color="success" @click="backToChallenges" v-if="credit > 0">
                            Reveal
                            <v-icon right>grid_view</v-icon>
                        </v-btn>
                    </div>
                    <v-icon size="16" style="position:absolute;top:0px;right:0px;" @click="showFinishCard = false">cancel</v-icon>
                </div>
        </Transition>
        <!-- <Transition name="drop">
            <div class="overlay pa-4" v-if="showHelp"/>
        </Transition> -->
        <Transition name="zoom">
            <v-card width="275" class="pa-2" v-if="showHelp" style="position:absolute;left:25px;bottom:20px;">
                <div class="headline text-xs-center mb-1">
                    Rules
                </div>
                <div class="caption text-xs-left">
                    <p>You have to guess a mystery calculation in 6 tries or less. You don't get any help on the first try, but afterwards, you have the following clues :</p>
                    <div class="mb-2" style="display:flex;align-items:center;">
                        <div style="width:30px;height:30px;background:#232E21;"/>
                        <div style="flex:1" class="body pl-2">
                            Means this symbol is <b>not</b> in the mystery calculation
                        </div>
                    </div>
                    <div class="mb-2" style="display:flex;align-items:center;">
                        <div style="width:30px;height:30px;background:#F0A202;"/>
                        <div style="flex:1" class="body pl-2">
                            Means this symbol is in the mystery calculation, but not at this place
                        </div>
                    </div>
                    <div class="mb-2" style="display:flex;align-items:center;">
                        <div style="width:30px;height:30px;background:#00CC66;"/>
                        <div style="flex:1" class="body pl-2">
                            Means this symbol is at the correct place in the mystery calculation
                        </div>
                    </div>
                </div>
                <div class="subheading text-xs-center">
                    Good luck !
                </div>
                <v-icon size="16" style="position:absolute;top:0px;right:0px;" @click="showHelp = false">cancel</v-icon>
            </v-card>
        </Transition>
            
        <v-icon size="16" style="position:absolute;bottom:5px;right:5px;" @click="showFinishCard = true" v-if="!showFinishCard  && !showHelp">visibility</v-icon>
        <v-icon size="16" style="position:absolute;bottom:5px;left:5px;" @click="showHelp = true" v-if="!showHelp">help</v-icon>
        <v-progress-linear height="2px" class="my-0" indeterminate style="position:absolute;top:0px;width:100%" v-if="loading"/>
    </v-card>
</template>
<script>
    import math from 'mathjs';
    import moment from 'moment-timezone';

    export default {
        props: { riddle: Object },
        data: function () {
            return {
                rows: [],
                keys: [
                    [{ value: '1', status: 'neutral' }, { value: '2', status: 'neutral' }, { value: '3', status: 'neutral' }, { value: '4', status: 'neutral' }, { value: '5', status: 'neutral' }, { value: 'backspace', status: 'neutral' }],
                    [{ value: '6', status: 'neutral' }, { value: '7', status: 'neutral' }, { value: '8', status: 'neutral' }, { value: '9', status: 'neutral' }, { value: '0', status: 'neutral' }, { value: 'cancel', status: 'neutral' }],
                    [{ value: '+', status: 'neutral' }, { value: '-', status: 'neutral' }, { value: '×', status: 'neutral' }, { value: '÷', status: 'neutral' }, { value: '=', status: 'neutral' }, { value: 'keyboard_return', status: 'neutral' }]
                ],
                activeRow: 0,
                currentAnswer: '',
                error: '',
                finished: false,
                loading: false,
                showFinishCard: true,
                showHelp: false,
                chars: 0
            }
        },
        computed:{
            finishData(){
                const title = this.riddle.data.status == 'finished' ? 'Mystery solved' : 'The mystery remains';
                const text = this.riddle.data.status == 'finished' ? 'Great job !' : 'Try again tomorrow'
                const color = this.riddle.data.status == 'finished' ? this.$vuetify.theme.success : this.$vuetify.theme.primary;
                const rows = [];
                this.rows.forEach(row => {
                    if(row.some(sq => sq.status != 'neutral')){
                        rows.push(row.map(sq => sq.status));
                    }
                })
                return {title, text, rows, border: `border: 3px solid ${color}`};
            },
            credit(){
                return this.riddle.data.credit != undefined ? this.riddle.data.credit : 120 - (Math.max(3, this.finishData.rows.length) - 3)*20;
            }
        },
        mounted() {
            this.initTile();
            eventBus.$on('riddleChar', this.charEmited);
        },
        beforeDestroy() {

        },
        methods: {
            initTile(){
                if(this.riddle.data.rows){
                    this.rows = this.riddle.data.rows;
                    this.activeRow = this.rows.findIndex(row => row[0].value == null);
                    this.keys = this.riddle.data.keys;
                    if(this.riddle.data.status != 'pending'){
                        this.finished = true;
                    }
                } else {
                    this.rows = [];
                    for(let k=0 ; k<6 ; k++){
                        const row = [];
                        for(let i=0 ; i<8 ; i++){
                            row.push({value: '', status: 'neutral'});
                        }
                        this.rows.push(row);
                    }
                    this.rows[0][0].status = 'active';
                }
            },
            charEmited(char){
                if(this.activeRow <= 5 && this.riddle.data.status == 'pending'){
                    if(char == 'backspace'){
                        if(this.currentAnswer.length > 0){
                            this.currentAnswer = this.currentAnswer.slice(0, -1);
                        }
                    } else if (char == 'cancel') {
                        this.currentAnswer = '';
                    } else if(char == 'keyboard_return'){
                        this.validateAnswer();
                        return;
                    } else if(this.currentAnswer.length < 8){
                        this.currentAnswer += char;
                    }
                    this.updateTiles();
                }
            },
            updateTiles(){
                this.chars++;
                const row = this.currentAnswer.split('').map(char => ({value: char, status: 'neutral'}));
                if(row.length < 8){
                    row.push({value: '', status: 'active'});
                }
                while(row.length < 8) {
                    row.push({ value: '', status: 'neutral' });
                }
                this.rows[this.activeRow] = row
            },
            validateAnswer(){
                if(this.currentAnswer.length < 8){
                    this.displayError('Incomplete answer');
                } else if(this.currentAnswer.indexOf('=') < 0){
                    this.displayError('Missing "="');
                } else {
                    const parts = this.currentAnswer.split('×').join('*').split('÷').join('/').split('=');
                    let result = null;
                    try {
                        result = math.eval(parts[0])
                    } catch (error) {
                        this.displayError('Answer does not compute');
                    }
                    if(result == null || result != parts[1]){
                        this.displayError('Answer does not compute');
                    } else {
                        const newRow = [];
                        const answer = this.riddle.formula.split('*').join('×').split('/').join('÷').split('');
                        const submitted = this.currentAnswer.split('');
                        submitted.forEach((char, index) => {
                            if (char == answer[index]) {
                                answer[index] = 'taken';
                                newRow.push({value: char, status: 'placed'});
                                const matchKey = this.keys.flat().find(key => key.value == char);
                                matchKey.status = 'placed';
                            } else {
                                newRow.push({value: char, status: 'absent'});
                            }
                        });
                        submitted.forEach((char, index) => {
                            if (answer.indexOf(char) >= 0 && newRow[index].status != 'placed') {
                                answer[answer.indexOf(char)] = 'taken';
                                newRow[index].status = 'misplaced';
                                const matchKey = this.keys.flat().find(key => key.value == char);
                                if(matchKey.status != 'placed'){
                                    matchKey.status = 'misplaced';
                                }
                            }
                        });
                        submitted.forEach((char, index) => {
                            if(newRow[index].status == 'absent'){
                                const matchKey = this.keys.flat().find(key => key.value == char);
                                if (matchKey.status == 'neutral') {
                                    matchKey.status = 'absent';
                                }
                            }
                        })
                        this.rows[this.activeRow] = newRow;
                        // if(this.rows[this.activeRow].every(char => char.status == 'placed')){
                        if (this.rows[this.activeRow][6].status == 'placed' && this.rows[this.activeRow][7].status == 'placed' && this.rows[this.activeRow].every(char => char.status == 'misplaced' || char.status == 'placed')) {
                            this.rows[this.activeRow].forEach(ch => ch.status = 'placed');
                            this.finishRiddle();
                        } else if(this.activeRow == 5){
                            this.riddleFailed();
                        } else {
                            this.activeRow++;
                            this.rows[this.activeRow][0].status = 'active';
                            axios.post('/api/riddles/progress', {rows: this.rows, keys: this.keys, status: 'pending'});
                        }
                        this.currentAnswer = '';
                    }
                }
            },
            displayError(error){
                this.error = error;
                setTimeout(() => this.error = '', 1500);
            },
            finishRiddle(){
                this.loading = true;
                this.activeRow = 1000;
                setTimeout(() => this.finished = true, 1000);
                // this.finished = true;
                axios.post('/api/riddles/progress', { rows: this.rows, keys: this.keys, status: 'finished' })
                .then(resp => {
                    eventBus.$emit('riddleFinished', resp.data);
                    this.loading = false;
                }).catch(err => console.log(err));
            },
            riddleFailed(){
                this.finished = true;
                this.loading = true;
                this.activeRow = 1000;
                axios.post('/api/riddles/progress', { rows: this.rows, keys: this.keys, status: 'failed' })
                .then(resp => {
                    eventBus.$emit('riddleFinished', resp.data);
                    this.loading = false;
                }).catch(err => console.log(err));
            },
            sqColor(status){
                let color = '#808A9F';
                switch (status) {
                    case 'placed':
                        color = '#00CC66';
                        break;
                    case 'misplaced':
                        color = '#F0A202';
                        break;
                    case 'absent':
                        color = '#232E21';
                        break;
                }
                return color;
            },
            backToChallenges(){
                eventBus.$emit('backToChallenges', {});
            },
            share(){
                const chars = {
                    absent: '⬛',
                    misplaced: '🟨',
                    placed: '🟩',
                    neutral: ''
                }
                let resultString = `Sam's Tricks Daily Mystery - ${moment(this.riddle.published_on).format('Do MMM YY')}\r\n`;
                this.rows.forEach(row => {
                    let emptyRow = true;
                    row.forEach(sq => {
                        if(sq.status != 'neutral'){
                            emptyRow = false;
                        }
                        resultString += chars[sq.status];
                    });
                    if(!emptyRow){
                        resultString += '\r\n';
                    }
                });
                resultString += 'Solve it at samstricks.net';
                navigator.clipboard.writeText(resultString)
                    .then(resp => {
                        this.$root.snackSuccess('Results copied to clipboard');
                    });
            }
        }
}
</script>
<style scoped>
    .errorDisplay{
        position: absolute;
        top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .v-enter-active{
        transition: opacity .3s;
    }
    .v-leave-active {
        transition: opacity 2s ease;
    }

    .v-enter,
    .v-leave-to {
        opacity: 0;
    }
    .overlay {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .finishCard {
        position: absolute;
        left: 37px;
        bottom: 20px;
        background: white;
        border-radius: 10px;
        width: 250px;
    }
    .drop-enter-active,
    .drop-leave-active {
        transition: all 0.5s ease;
    }

    .drop-enter,
    .drop-leave-to {
        height: 0px;
    }
    .zoom-enter-active,
    .zoom-leave-active {
        transition-delay: 0.5s;
        transition: all 0.5s ease;
    }

    .zoom-enter,
    .zoom-leave-to {
        scale: 0;
    }
</style>