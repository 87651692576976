<template>
  <v-dialog v-model="editTrackDialog" fullscreen hide-overlay transition="slide-x-transition" @keydown.esc="cancelEditTrack">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Edit Track</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancelEditTrack">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <v-form ref="editTrackForm" v-model="valid" lazy-validation>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field label="Name" v-model="track.title" :rules="titleRules" autofocus clearable></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea label="Text description (HTML enabled)" v-model="track.text_description" :rules="descrRules" :counter="255" class="mb-2"></v-textarea>
                  </v-flex>
                  <v-flex xs12 class="text-xs-left">
                    <p class="grey--text caption ma-0 pa-0">Status</p>
                    <v-select :items="statusItems" label="Status" solo v-model="track.status"></v-select>
                  </v-flex>
                </v-layout>
              </v-form>
              <v-flex xs12 class="grey--text subheading mt-3">Tricks</v-flex>
              <v-flex v-if="tricks.length == 0" class="grey--text text-xs-center" style="width:100%">Nothing yet...</v-flex>
              <draggable v-model="tricks">
                <transition-group>
                  <v-chip label color="primary" text-color="white" v-for="trick in tricks" :key="trick.id" close @input="removeTrick(trick.id)">
                    {{ trick.title }}
                  </v-chip>
                </transition-group>
              </draggable>
            </v-flex>
            <v-flex xs12 md6>
              <v-text-field label="Search for tricks" append-icon="search" v-model="searchedTitle" outline clearable @keyup="userTyping" @click:clear="clearTricks"></v-text-field>
              <v-layout row wrap>
                <trick-card v-for="trick in searchedTricks" :key="trick.id" :trick="trick" :displayButtons="true"></trick-card>
              </v-layout>
              <focused-trick :dbtrick="focusedTrick" v-if="focusedTrick" :key="focusedTrick.id"></focused-trick>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" flat block @click="cancelEditTrack">Cancel</v-btn>
        <v-btn color="success" block @click="saveEditTrack">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
  import draggable from 'vuedraggable'

  export default {
    components: { draggable },
    data : function(){
      return {
        track: {},
        valid: true,
        statusItems: [
          {value: 'draft', text: 'Draft - Only you can view this track'},
          {value: 'private', text: 'Private - Only you and your students can view this track'},
          {value: 'public', text: 'Public - Everyone can view this track'}
        ],
        tricks: [],
        editTrackDialog: false,
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 5) || 'The track title must be at least 5 characters long'
        ],
        descrRules: [
          v => (v && v.length <= 255) || 'The description must be less 255 characters long'
        ],
        searchedTitle: '',
        searchedTricks: [],
        focusedTrick: null
      }
    },
    mounted() {
      let vm = this;
      eventBus.$on('editTrack', (track) => {
        this.track = track;
        this.tricks = track.progress.tricks;
        this.editTrackDialog = true;
      });
      eventBus.$on('trickFocused', (id) => {
        axios.get(`/api/tricks/${id}`, {})
        .then(function (response) {
          vm.focusedTrick = response.data;
        })
        .catch(function (error) {
          console.log(error.response.data.error); // TODO fix this
        });
      });
      eventBus.$on('addTrick', (trick) => {
        if(this.tricks.length < 10){
          if(!_.find(this.tricks, {'id': trick.id})){
            this.tricks.push(trick);
          } else {
            this.$root.snackError("This trick is already in the track");
          }
        } else {
          this.$root.snackError("Maximum 10 tricks per track");
        }
      });
    },
    methods : {
      userTyping(){
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this.searchTricks, 500);
      },
      searchTricks(){
        let vm = this;
        if(this.searchedTitle.length == 0) {
          this.searchedTricks = [];
        } else {
          if(this.searchedTitle.length > 2){
            axios.get(`/api/tricks/search/${vm.searchedTitle}`, {})
            .then(function (response) {
              if(response.data.status == 'OK'){
                vm.searchedTricks = response.data.tricks;
              } else {
                vm.$root.snackError('Too many tricks, be more specific');
              }
            })
            .catch(function (error) {
              console.log(error.response.data.error);
            });
          }
        }
      },
      clearTricks(){
        this.searchedTricks = [];
      },
      removeTrick(id){
        let backup = this.tricks;
        _.remove(backup, function(trick) {
            return trick.id == id;
        });
        this.tricks = [];
        let vm = this;
        backup.forEach(function(trick){
          vm.tricks.push(trick);
        });
      },
      cancelEditTrack(){
        this.editTrackDialog = false;
      },
      saveEditTrack(){
        if(this.$refs.editTrackForm.validate()){
          let vm = this;
          let tricksIds = [];
          this.tricks.forEach(function(trick){
            tricksIds.push(trick.id);
          });

          axios.patch(`/api/tracks/${vm.track.id}`, {
            title: vm.track.title,
            text_description: vm.track.text_description,
            status: vm.track.status,
            tricks: tricksIds
          })
          .then(function (response) {
            eventBus.$emit('trackUpdated', response.data);
            vm.editTrackDialog = false;
            vm.$root.snackSuccess("Track updated.");
          })
          .catch(function (error) {
            vm.$root.snackError(error.response.data);
            console.log(error.response); // TODO fix this
          });
        }
      }
    }
  }
</script>
