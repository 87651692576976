<template>
  <v-container class='text-xs-left'>
    <v-btn to="/admin" :ripple="false" flat>
      <v-icon>chevron_left</v-icon> Back to admin panel
    </v-btn>
    <v-layout row wrap>
      <v-text-field v-model="search" append-icon="search" label="Search" clearable></v-text-field>
      <v-data-table style='width:100%' :search='search' :headers="headers" :items="suspiciousPerfs" :rows-per-page-items='[25, 50, 100]'>
        <template v-slot:items="props">
          <tr @click="props.expanded = !props.expanded">
            <td>{{ props.item.user.name }}</td>
            <td>{{ props.item.trick.title }}</td>
            <td class='text-xs-center'>{{ props.item.suspicious }}</td>
            <td class='text-xs-center'>{{ props.item.performance.score }}</td>
            <td>{{ props.item.created_at | formatDateTime }}</td>
            <td>
              <v-btn icon small color='error' @click.stop='updateSuspicious(props.item.id)'>
                <v-icon small>report</v-icon>
              </v-btn>
              <v-btn icon small color='warning' @click.stop='focusUser(props.item.user.id)'>
                <v-icon small>perm_identity</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:expand="props">
          <v-card flat class='mb-3'>
            <answers-recap :attempt='props.item' :orgTrick='props.item.trick' v-if='props.item'/>
            <div v-else class='grey--text text--lighten-2 title text-xs-center pa-4'>
              No details available
            </div>
          </v-card>
        </template>
      </v-data-table>
    </v-layout>
    <v-dialog v-model='suspiciousDialog' width='400px'>
      <v-card>
        <v-card-title class='headline'>
          Change suspicious value
        </v-card-title>
        <v-card-text>
          Change suspicious value of this attempt to
          <v-text-field type="number" label='suspicious' min="0" max="200" v-model="focusedSuspiciousValue"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="error" style='width:150px' flat @click="suspiciousDialog = false">Cancel</v-btn>
          <v-btn color="success" style='width:150px' @click="submitChanges">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <user-dialog :userId='focusedUserId'/>
  </v-container>
</template>
<script>
  import moment from 'moment-timezone';

  export default {
    props : {},
    data : function(){
      return {
        suspiciousPerfs: [],
        search: '',
        headers: [
          {text: 'User name', value: 'user.name'},
          {text: 'Trick title', value: 'trick.title'},
          {text: 'Suspicious', value: 'suspicious'},
          {text: 'Score', value: 'score'},
          {text: 'Date', value: 'created_at'},
          {text: 'Actions', value: ''}
        ],
        suspiciousDialog: false,
        focusedAttemptId: 0,
        focusedSuspiciousValue: 0,
        userDialog: false,
        focusedUserId: 0
      }
    },
    mounted() {
      eventBus.$on('initUser', d => this.focusedUserId = 0);

      axios.get('/api/admin/suspicious', {})
      .then(resp => {
        this.suspiciousPerfs = resp.data
      })
      .catch(err => console.log(err));
    },
    filters: {
      formatDateTime(val){
        return moment.tz(val, 'UTC').clone().tz(moment.tz.guess()).format('DD/MM/YY - HH:mm');
      }
    },
    methods: {
      updateSuspicious(id){
        let focusedAttempt = this.suspiciousPerfs.filter(o => o.id == id)[0];
        this.focusedAttemptId = id;
        this.focusedSuspiciousValue = focusedAttempt.suspicious;
        this.suspiciousDialog = true;
      },
      focusUser(id){
        this.focusedUserId = id;
      },
      submitChanges(){
        axios.patch(`/api/admin/suspicious/${this.focusedAttemptId}`, {value: this.focusedSuspiciousValue})
        .then(resp => {
          this.$root.snackSuccess("Value updated");
          let focusedAttempt = this.suspiciousPerfs.filter(o => o.id == this.focusedAttemptId)[0];
          focusedAttempt.suspicious = this.focusedSuspiciousValue;
          this.suspiciousDialog = false;
        })
        .catch(err => console.log(err));
      }
    }
  }
</script>
