<template>
  <v-container>
    <v-layout row wrap>
      <v-btn flat style='width:250px;' @click='goBackToClasses'>
        <v-icon left>chevron_left</v-icon>
        Back to Classes
      </v-btn>
      <v-data-table
        :headers="headers"
        :items="marathons"
        :rows-per-page-items="[20,50]"
        class="elevation-1"
        style='width:100%'
      >
        <template v-slot:items="props">
          <td>{{ props.item.title }}</td>
          <td>{{ props.item.status }}</td>
          <td>{{ props.item.updated_at | formatTime }}</td>
          <td>
            <v-btn flat @click='goMarathon(props.item.id)'>
              See details
              <v-icon right>chevron_right</v-icon>
            </v-btn>
          </td>
          <td>
            <span v-if="props.item.author_id == $root.user.id">
              <v-tooltip bottom v-if="props.item.status == 'active'">
                <template v-slot:activator="{ on }">
                  <v-icon color="grey" v-on="on">delete_forever</v-icon>
                </template>
                <span>You cannot delete an active marathon</span>
              </v-tooltip>
              <v-btn icon @click="prepareDeleteMarathon(props.item)" v-else>
                <v-icon color="error">delete_forever</v-icon>
              </v-btn>
            </span>
          </td>
        </template>
      </v-data-table>
    </v-layout>
    <v-dialog v-model="deleteDialog" width="450">
      <v-card>
        <v-card-title class="headline">
          Delete {{  focusedMarathon.title }} ?
        </v-card-title>
        <v-card-text>
          <p>Are you sure you want to delete this marathon ? This will also delete the marathon's hike and all of its races.</p> 
          <p><b>This action is permanent and irreversible.</b></p>
          <div>
            <v-checkbox v-model="confirmDelete" label="Yes, delete it all !"/>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn flat color="primary" :disabled="deleteLoading" width="120px" @click="deleteDialog=false">Cancel</v-btn>
          <v-btn color="error" :disabled="!confirmDelete" :loading="deleteLoading" width="120px" @click="proceedDelete">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import moment from 'moment-timezone';

  export default {
    props : {},
    data : function(){
      return {
        marathons: [],
        headers: [
          {text: 'Title', value: 'title', align: 'center'},
          {text: 'Status', value: 'status', align: 'center'},
          {text: 'Last active', value: 'updated_at', align: 'center'}
        ],
        focusedMarathon: {},
        confirmDelete: false,
        deleteDialog: false,
        deleteLoading: false
      }
    },
    filters: {
      formatTime(val){
        return moment(new Date(val)).format('MMMM d, YYYY');
      }
    },
    created() {
      eventBus.$emit('loading', {command: 'clear'});
      eventBus.$emit('loading', {command: 'start', name: 'marathons-all'});
      axios.get(`/api/marathons`, {})
      .then(resp => {
        this.marathons = Object.values(resp.data);
        eventBus.$emit('loading', {command: 'finish', name: 'marathons-all'});
      }).catch(err => console.log(err));
    },
    methods: {
      goBackToClasses(){
        this.$router.push('/classes');
      },
      goMarathon(id){
        this.$router.push(`/marathons/${id}`);
      },
      prepareDeleteMarathon(marathon){
        this.confirmDelete = false;
        this.focusedMarathon = marathon;
        this.deleteDialog = true;
      },
      proceedDelete(){
        this.deleteLoading = true;
        axios.delete(`/api/marathons/${this.focusedMarathon.id}`, {})
        .then(resp => {
          this.$root.snackSuccess("Marathon deleted");
          this.marathons = this.marathons.filter(m => m.id != resp.data);
          this.deleteLoading = false;
          this.deleteDialog = false;
        }).catch(err => {
          this.$root.snackError(err.response.data.error);
          this.deleteLoading = false;
          this.deleteDialog = false;
          console.log(err.response.data);
        })
      }
    }
  }
</script>
