<template>
  <v-dialog v-model="studentDialog" fullscreen hide-overlay transition="slide-x-transition" @keydown.esc="closestudentDialog">
    <v-card>
      <v-toolbar fixed dark color="primary">
        <v-toolbar-title>{{ student.name }}</v-toolbar-title>
        <span v-if='$vuetify.breakpoint.name != "xs"'>
          <span class='headline font-weight-medium ml-4 mr-2'>
            {{ student.smile_count }}
            <v-icon medium color="white">sentiment_satisfied_alt</v-icon>
          </span>
          <span class='headline font-weight-medium'>
            {{ student.star_count }}
            <v-icon medium color="orange">star</v-icon>
          </span>
        </span>
         <v-spacer></v-spacer>
         <v-chip color="secondary" text-color="white" v-if='$vuetify.breakpoint.name != "xs"'>
           {{ division.name }}
         </v-chip>
         <v-btn icon dark @click="closestudentDialog">
           <v-icon>close</v-icon>
         </v-btn>
      </v-toolbar>
      <v-progress-linear indeterminate class='ma-0' style='position:fixed;top:64px;z-index:10' v-if='loading'></v-progress-linear>

      <v-card-text class='mt-5'>
        <v-layout row wrap class='pa-2'>
          <v-flex xs12 class="headline grey--text text-xs-left">
            Latest tricks
          </v-flex>
          <v-flex xs12 v-if='perfs.latest.length == 0' class='grey--text text--lighten-2'>
            No tricks tried
          </v-flex>
          <v-flex xs12 v-else>
            <v-card class="pa-2 ma-2 text-xs-center" style="display:inline-block" width="200" v-for="(perf, index) in perfs.latest" :key="`lastPerf-${index}`">
              <div class="subheading text-truncate">{{ perf.trick.title }}</div>
              <div><b>{{ perf.score }}</b> points</div>
              <v-rating v-model="perf.rating" size="12" color="orange" background-color="orange lighten-3" readonly></v-rating>
              <div class='grey--text caption'>{{ perf.ago }}</div>
            </v-card>
          </v-flex>
          <v-flex xs12 md6>
            <v-layout row wrap>
              <v-flex xs12 class="headline grey--text text-xs-left">
                Recommended tricks
              </v-flex>
              <v-flex xs12 v-if='student.affinities.length == 0' class='grey--text text--lighten-2'>
                None
              </v-flex>
              <v-flex xs12>
                <v-chip v-for="(trick, index) in student.affinities" :key="`affinity-${index}`">
                  {{ trick.title }}
                </v-chip>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 md6>
            <v-layout row wrap>
              <v-flex xs12 class="headline grey--text text-xs-left">
                Active challenges
              </v-flex>
              <v-flex xs12 md6 v-if='student.activeChallenges.length == 0' class='grey--text text--lighten-2'>
                None
              </v-flex>
              <v-flex xs12>
                <v-chip v-for="(challenge, index) in student.activeChallenges" color='blue' dark :key="`challenge-${index}`">
                  {{ challenge.trick.title }}
                </v-chip>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 class="headline grey--text mt-3 text-xs-left">
            Last 12 weeks performances
          </v-flex>
          <v-flex xs12 md6>
            <graph-perfsOverTime></graph-perfsOverTime>
          </v-flex>
          <v-flex xs12 md6>
            <graph-challengesOverTime></graph-challengesOverTime>
          </v-flex>
          <v-flex xs12 class="headline grey--text mt-3 text-xs-left">
            Races performances and rewards
          </v-flex>
          <v-flex xs12>
            <graph-racesPerfs></graph-racesPerfs>
          </v-flex>
          <v-flex xs12 class="headline grey--text mt-3 text-xs-left">
            Tracks star count
          </v-flex>
          <v-flex xs12>
            <graph-tracksStarCount></graph-tracksStarCount>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import Chart from 'chart.js'

  export default {
    props : {},
    data : function(){
      return {
        studentDialog: false,
        student: {affinities: [], activeChallenges: []},
        division: {},
        perfs: {latest: []},
        loading: false
      }
    },
    mounted() {
      eventBus.$on('showStudentDetails', this.initData);
    },
    beforeDestroy(){
      eventBus.$off('showStudentDetails', this.initData);
    },
    methods: {
      closestudentDialog(){
        this.student = {affinities: [], activeChallenges: []};
        this.division = {};
        this.perfs = {latest: []};
        this.studentDialog = false;
        eventBus.$emit('tracksPolars', 0);

      },
      initData(data){
        this.studentDialog = true;
        this.loading = true;
        let vm = this
        axios.get(`/api/classes/${data.classId}/details/${data.userId}`, {})
        .then(function (response) {
          vm.student = response.data.student;
          vm.division = response.data.division;
          vm.perfs = response.data.perfs;
          eventBus.$emit('perfsOverTime', response.data.perfs.last12weeks);
          eventBus.$emit('challengesOverTime', response.data.perfs.last12weeksChallenges);
          eventBus.$emit('tracksPolars', data.userId);
          eventBus.$emit('racesPerfs', {userId: data.userId, classId: data.classId});
          vm.loading = false;
        })
        .catch(function (error) {
          console.log(error) // TODO catch error
        });
      }
    }
  }
</script>
