<template>
  <v-card hover width='100%' class='mb-4'>
    <v-img width='150px' max-height='90%' contain src='/images/hike.png' style='position:absolute;bottom:0px;right:10px'/>
    <v-card-title class="pb-0">
      <v-progress-linear 
        :indeterminate="true"
        style='position:absolute;top:-18px;left:0px' height='4' 
        v-if='loading'
      />
      <v-progress-linear
        :value="Math.round(hike.sectionStars*100/maxStars)"
        style='position:absolute;top:-15px;left:0px'
        color='success'
        height='12'
      />
      <v-layout row wrap>
        <v-flex xs6 sm5 class='headline text-truncate' style='display:flex;align-items:center;justify-content:flex-start;'>
          {{ hike.title }}
        </v-flex>
        <v-flex xs6 sm2 class='headline' :class='loading ? "grey--text text--lighten-2" : ""' style='display:flex;align-items:center;justify-content:center'>
          {{ hike.sectionStars }}/{{ maxStars }}
          <v-icon medium color="green" class='mx-1'>stars</v-icon>
        </v-flex>
        <v-flex xs6 sm4 style='display:flex;align-items:center;justify-content:center'>
          <span style="white-space:nowrap;display:flex;justify-content:space-around;align-items:center;width:100%;" v-if="hike.marathon_id ==null">
            <span>
              <v-chip v-for="(name, index) in classesNames" :key="index">{{ name }}</v-chip>
            </span>
            <span>Term {{ hike.term }}</span>
          </span>
          <v-chip label dark color='black' v-else>Marathon hike</v-chip>
        </v-flex>
        <v-flex xs6 sm1/>
      </v-layout>
    </v-card-title>
    <v-card-text class="text-xs-left">
      <div style='margin-right:150px'>
        <div class="subheading grey--text">
          Sections
        </div>
        <v-expansion-panel v-model='openPanel'>
          <track-hike-panel
            v-for='(track, index) in hike.tracks'
            :key='`track-${track.id}`' :track='track'
            :openPanel='hike.tracks[openPanel] != undefined && hike.tracks[openPanel].id == track.id'
            :loading='loading'
          >
        </track-hike-panel>
      </v-expansion-panel>
      </div>
    </v-card-text>
    <div style='display:flex;position:absolute;top:10px;right:5px'>
      <v-btn small icon class='ma-0' @click='showOverlay=true' v-if='!["xs","sm"].includes($vuetify.breakpoint.name)'>
        <v-icon color='primary'>help</v-icon>
      </v-btn>
      <v-btn small icon class='ma-0' @click='refreshHike'>
        <v-icon>refresh</v-icon>
      </v-btn>
    </div>
    <transition name='expandRounded' v-if='!["xs","sm"].includes($vuetify.breakpoint.name)'>
      <div class='overlay' v-if='showOverlay'/>
    </transition>
    <transition name='fade' v-if='!["xs","sm"].includes($vuetify.breakpoint.name)'>
      <div class='insideOverlay text-xs-left headline' v-if='showOverlay'>
        <div style='position:absolute;top:40px;left:75px;display:flex;align-items:baseline;'>
          <v-img width='75px' src="/images/overlays/arrow race card overlay.png" style="transform:scaleX(-1) rotate(205deg);" />
          <div style='font-family:Piko;max-width:450px;margin-left:-15px' class='hintCard elevation-3'>
            <div style='font-size:48px'>
              This is a hike !
            </div>
            <div style='font-size:24px'>
              Hikes are a set of tracks you have to work on. For each trick in these tracks, your <u>three best performances</u> are recorded.
            </div>
          </div>
        </div>
        <v-btn large :ripple='false' color='primary' style='width:250px;position:absolute;right:5px;bottom:5px' @click='showOverlay=false'>
          Got it !
        </v-btn>
      </div>
    </transition>
  </v-card>
</template>
<script>
  export default {
    props : { hike: Object },
    data : function(){
      return {
        openPanel: null,
        maxStars: 0,
        showOverlay: false,
        loading: false
      }
    },
    computed: {
      classesNames(){
        const classes = [];
        if(this.hike.divIds){
          this.hike.divIds.forEach(id => {
            const division = this.$root.classesData.divisions.find(d => d.id == id);
            if(division){
              classes.push(division.name);
            }
          });
        }
        return classes;
      }
    },
    mounted() {
      this.hike.tracks.forEach(track => {
        this.maxStars += track.tricks.length*15
      });
      this.refreshHike();
    },
    methods: {
      refreshHike() {
        this.loading = true;
        axios.get(`/api/hikes/${this.hike.id}`, {})
        .then(resp => {
          eventBus.$emit('hikeUpdated', resp.data);
          this.loading = false;
        }).catch(err => console.log(err));
      }
    }
  }
</script>

<style scoped>
  .overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.65) 50%, rgba(255,255,255,1) 85%, rgba(255,255,255,1) 100%);
  }
  .insideOverlay {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
  }
  .expandRounded-enter-active {
    animation: expand-rounded .25s;
  }
  .expandRounded-leave-active {
    animation: expand-rounded .25s reverse;
  }
  @keyframes expand-rounded {
    0% {
      width: 0%;
      height: 0%;
      border-radius: 25% 0% 25% 10%;
    }
    25% {
      width: 10%;
      height: 10%;
      border-radius: 25% 0% 25% 10%;
    }
    95% {
      width: 100%;
      height: 100%;
      border-radius: 10% 0% 25% 0%;
    }
    100% {
      width: 100%;
      height: 100%;
      border-radius: 0%;
    }
  }

  .fade-enter-active {
    animation: fade .25s;
  }
  .fade-leave-active {
    animation: fade .25s reverse;
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .hintCard {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 16px 12px 16px;
  }
</style>
