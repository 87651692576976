<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 class='text-xs-right'>
        <v-dialog v-model="globalAnnouncementDialog" width="500">
        <template v-slot:activator="{ on }">
          <v-btn flat class="grey--text" v-on="on">
            Global announcement
          </v-btn>
        </template>
        <global-announcement-card/>
      </v-dialog>
      <!-- <v-btn flat to="/admin/challengeCanvas" class="grey--text" :ripple="false">
            To challenge canvas
            <v-icon right>image</v-icon>
          </v-btn>
        <v-btn flat to="/schools" class="grey--text" :ripple="false">
          To schools
          <v-icon right>school</v-icon>
        </v-btn>
        <v-btn flat to="/admin/collectibles" class="grey--text" :ripple="false">
          To collectibles
          <v-icon right>chevron_right</v-icon>
        </v-btn>
        <v-btn flat to="/admin/collections" class="grey--text" :ripple="false">
          To collections
          <v-icon right>chevron_right</v-icon>
        </v-btn> -->
      </v-flex>
      <v-flex xs12 md9>
        <div style='display:flex;'>
          <v-select
            :items="timespan"
            :loading="perfsLoading"
            :disabled="perfsLoading"
            v-model="timeUnitSelected"
            label="Timespan"
            outline
            hide-details
            style='flex:1'
            @change="updateGraph"
          />
          <div style='display:flex;align-items:center' class='ml-2'>
            <v-icon large :color='starSmileSwitch ? "grey" : "orange"'>star</v-icon>
            <v-switch hide-details class='my-0 ml-2' color='primary' v-model="starSmileSwitch" @change='drawGraph'></v-switch>
            <v-icon large :color='starSmileSwitch ? "primary" : "grey"'>sentiment_satisfied_alt</v-icon>
            <v-menu class="ml-2" bottom left>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-tile
                  v-for="(page, i) in adminPages"
                  :key="i"
                  @click="goTo(page.url)"
                >
                  <v-list-tile-avatar>
                    <v-icon>{{ page.icon }}</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    {{ page.name }}
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </div>
        </div>
        <canvas id='graph'></canvas>
        <div class='mt-3'>
          <v-select :items='fixes' label='Fix' outline v-model='currentFix'/>
          <v-window v-model='currentFix'>
            <v-window-item :key='0'>
              <fix-hikes-progress/>
            </v-window-item>
            <v-window-item :key='1'>
              <div style='display:flex;align-items:flex-end;' class='headline grey--text text-xs-left'>
                <span>
                  Fix Races Progress
                </span>
                <span style='display:flex;' class='mx-3'>
                  <v-text-field hide-details :disabled='fixingRacesProgress' style='width:75px;' class='mx-1 my-0' label="First" type='number' v-model="firstRace"/>
                  <v-text-field hide-details :disabled='fixingRacesProgress' style='width:75px' class='mx-1 my-0' label="Last" type='number' v-model="lastRace"/>
                </span>
                <v-dialog width='400px' v-model='confirmFixRacesProgressDialog'>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" v-on="on">
                      Fix Progress
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class='headline'>
                      Fix progress for {{ lastRace - firstRace + 1 }} races ?
                    </v-card-title>
                    <v-card-text>
                      Are you sure you want to do this ? It might take a while and cause major damage...
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer/>
                      <v-btn color='primary' flat style='width:150px' @click='confirmFixRacesProgressDialog = false'>
                        Cancel
                      </v-btn>
                      <v-btn color='error' style='width:150px' :loading='fixingRacesProgress' @click='fixRacesProgress'>
                        Confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-checkbox class='mt-0' style='align-self:center' hide-details v-model="forceRecalc" label="Force Recalc"/>
                <v-btn icon color='error' @click='fixingRacesProgress = false' v-if='fixingRacesProgress'>
                  <v-icon dark>cancel</v-icon>
                </v-btn>
              </div>
            </v-window-item>
            <v-window-item :key='2'>
              <div style='display:flex;align-items:flex-end;' class='headline grey--text text-xs-left'>
                <span>
                  Fix Users' Star Count
                </span>
                <span style='display:flex;' class='mx-3'>
                  <v-text-field hide-details :disabled='fixingStarcount' style='width:75px;' class='mx-1 my-0' label="First" type='number' v-model="firstUser"/>
                  <v-text-field hide-details :disabled='fixingStarcount' style='width:75px' class='mx-1 my-0' label="Last" type='number' v-model="lastUser"/>
                </span>
                <v-dialog width='400px' v-model='confirmFixStarcountDialog'>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" v-on="on">
                      Fix Star Counts
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class='headline'>
                      Fix star count for {{ lastUser - firstUser + 1 }} users ?
                    </v-card-title>
                    <v-card-text>
                      Are you sure you want to do this ? It might take a while and cause major damage...
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer/>
                      <v-btn color='primary' flat style='width:150px' @click='confirmFixStarcountDialog = false'>
                        Cancel
                      </v-btn>
                      <v-btn color='error' style='width:150px' :loading='fixingStarcount' @click='fixStarCount'>
                        Confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn icon color='error' @click='fixingStarcount = false' v-if='fixingStarcount'>
                  <v-icon dark>cancel</v-icon>
                </v-btn>
              </div>
            </v-window-item>
          </v-window>
          <div class='text-xs-left'>
            <v-chip
              :color='raceStatusColor(race.status)'
              dark
              class='font-weight-bold'
              v-for='race in races' :key='races.id'
            >
              {{ race.title }} - {{ race.status }}
            </v-chip>
          </div>
          <div class='text-xs-left'>
            <v-chip
              :color='user.status == "checked" ? "primary" : user.status == "fixed" ? "success" : "grey darken-3"'
              dark
              class='font-weight-bold'
              v-for='user in users' :key='user.id'
            >
              {{ user.name }} - {{ user.status }}
            </v-chip>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 md3>
        <v-layout :column="!['xs', 'sm'].includes($vuetify.breakpoint.name)" wrap>
          <v-flex xs6>
            <v-layout row wrap>
              <v-flex xs12 class="grey--text headline font-weight-medium text-xs-left mt-3">
                Trick count
              </v-flex>
              <v-flex xs6>
                <div class="display-1 text-xs-center">
                  {{ stats.trickCount.lastDay }}
                </div>
                <div class='grey--text text-xs-center'>
                  Last 24 hours
                </div>
              </v-flex>
              <v-flex xs6>
                <div class="display-1 text-xs-center">
                  {{ stats.trickCount.last10Day }}
                </div>
                <div class='grey--text text-xs-center'>
                  Last 10 days
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6>
            <v-layout row wrap>
              <v-flex xs12 class="grey--text headline font-weight-medium text-xs-left mt-3">
                Messages
              </v-flex>
              <v-flex xs4>
                <div class="display-1 text-xs-center">
                  {{ stats.messages.nb }}
                </div>
                <div class='grey--text text-xs-center'>
                  Total
                </div>
              </v-flex>
              <v-flex xs4>
                <div class="display-1 text-xs-center primary--text">
                  {{ stats.messages.new }}
                </div>
                <div class='grey--text text-xs-center'>
                  New
                </div>
              </v-flex>
              <v-flex xs4>
                <div class="display-1 text-xs-center error--text">
                  {{ stats.messages.important }}
                </div>
                <div class='grey--text text-xs-center'>
                  Important
                </div>
              </v-flex>
              <v-flex xs12 class='text-xs-right'>
                <v-btn to="/messages" :ripple="false" flat>
                  See messages <v-icon>chevron_right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6>
            <v-layout row wrap>
              <v-flex xs12 class="grey--text headline font-weight-medium text-xs-left mt-3">
                Suspicious perfs
              </v-flex>
              <v-flex xs6>
                <div class="display-1 text-xs-center primary--text">
                  {{ stats.suspicious[0] }}
                </div>
                <div class='grey--text text-xs-center'>
                  High (>50)
                </div>
              </v-flex>
              <v-flex xs6>
                <div class="display-1 text-xs-center error--text">
                  {{ stats.suspicious[1] }}
                </div>
                <div class='grey--text text-xs-center'>
                  Critical (>100)
                </div>
              </v-flex>
              <v-flex xs12 class='text-xs-right'>
                <v-btn to="/suspicious" :ripple="false" flat>
                  See suspicious perfs <v-icon>chevron_right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs6 class="grey--text headline font-weight-medium text-xs-left mt-3">
            Pending jobs : <span :class="stats.jobs > 5 ? 'font-weight-bold error-text' : ''">{{ stats.jobs }}</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  import Chart from 'chart.js'

  export default {
    props : {},
    data : function(){
      return {
        ctx: {},
        chart: {},
        timespan: [
          {text: 'Last 12 days', value: 'days'},
          {text: 'Last 24 days', value: 'bidays'},
          {text: 'Last 12 weeks', value: 'weeks'},
          {text: 'Last 12 months', value: 'months'}
        ],
        timeUnitSelected: 'days',
        graphSource: {},
        perfsLoading: false,
        starSmileSwitch: false,
        stats: {trickCount: {}, suspicious: [], messages: {}, jobs: 0},
        firstUser: 1,
        lastUser: 10,
        firstRace: 1,
        lastRace: 10,
        currentUser: null,
        currentRace: null,
        users: [],
        races: [],
        fixingStarcount: false,
        confirmFixStarcountDialog: false,
        fixingRacesProgress: false,
        confirmFixRacesProgressDialog: false,
        forceRecalc: false,
        fixes: [
          {text: 'Hikes Progress', value: 0},
          {text: 'Race Progress', value: 1},
          {text: 'Hikes Progress', value: 2}
        ],
        currentFix: null,
        globalAnnouncementDialog: false,
        adminPages: [
          { name: 'Challenge canvas', icon: 'image', url: '/admin/challengeCanvas' },
          { name: 'Riddles', icon: 'contact_support', url: '/admin/riddles' },
          { name: 'Schools', icon: 'school', url: '/schools' },
          { name: 'Collectibles', icon: 'category', url: '/admin/collectibles' },
          { name: 'Collections', icon: 'category', url: '/admin/collections' },
          { name: 'Global announcements', icon: 'info', url: '/admin/announcements' },
          { name: 'Errors', icon: 'bug_report', url: '/admin/errors' },
        ]
      }
    },
    created(){
      eventBus.$emit('loading', {command: 'clear'});
    },
    mounted() {
      eventBus.$emit('loading', {command: 'start', name: 'admin'});
      this.ctx = document.getElementById('graph');
      this.chart = new Chart(this.ctx, {});
      this.updateGraph();
      eventBus.$on('closeGlobalAnnouncementDialog', this.closeGlobalAnnouncementDialog);
      axios.get('/api/admin/index', {})
      .then(resp => {
        this.stats = resp.data;
        eventBus.$emit('loading', {command: 'finish', name: 'admin'});
      })
      .catch(err => console.log(err));
    },
    beforeDestroy(){
      eventBus.$off('closeGlobalAnnouncementDialog', this.closeGlobalAnnouncementDialog);
    },
    methods: {
      // fetchUsers(){
      //   this.fetchingUsers = true;
      //   axios.get(`/api/admin/fetchUsers/${this.firstUser}/${this.lastUser}`, {})
      //   .then(resp =>{
      //     console.log(resp.data);
      //     this.users = resp.data;
      //     this.fetchingUsers = false;
      //   }).catch(err => console.log(err));
      // },
      fixStarCount(){
        this.confirmFixStarcountDialog = false;
        this.fixingStarcount = true;
        if(this.currentUser === null){
          this.users = [];
          this.races = [];
          this.currentUser = this.firstUser;
        }
        axios.post(`/api/admin/fixStarCount/${this.currentUser}`, {})
        .then(resp => {
          this.users.push(resp.data);
          if(this.currentUser < this.lastUser){
            this.currentUser++;
            this.fixStarCount();
          } else {
            this.currentUser = null;
            this.fixingStarcount = false;
            this.$root.snackSuccess("All done :-)")
          }
        }).catch(err => console.log(err));
      },
      fixRacesProgress(){
        this.confirmFixRacesProgressDialog = false;
        this.fixingRacesProgress = true;
        if(this.currentRace === null){
          this.users = [];
          this.races = [];
          this.currentRace = this.firstRace;
        }
        let fr = this.forceRecalc ? 1 : 0;
        axios.post(`/api/admin/fixRaceProgress/${this.currentRace}/${fr}`, {})
        .then(resp => {
          console.log(resp.data);
          this.races.push(resp.data);
          if(this.currentRace < this.lastRace){
            this.currentRace++;
            this.fixRacesProgress();
          } else {
            this.currentRace = null;
            this.fixingRacesProgress = false;
            this.$root.snackSuccess("All done :-)")
          }
        }).catch(err => console.log(err));
      },
      raceStatusColor(status){
        switch(status){
          case 'set':
            return "primary";
          break;
          case 'blank':
            return "success";
          break;
          case 'recalc':
            return "success darken-3";
          break;
          case 'miss':
            return "grey darken-3";
          break;
        }
      },
      updateGraph(){
        this.perfsLoading = true;
        axios.get(`/api/admin/history/${this.timeUnitSelected}`, {})
        .then(resp => {
          this.graphSource = resp.data;
          this.drawGraph();
          this.perfsLoading = false;
        })
        .catch(err => console.log(err));
      },
      drawGraph() {
        this.chart.destroy();
        let source = this.starSmileSwitch ? this.graphSource.challenges : this.graphSource.perfs;
        let firstLabel = this.starSmileSwitch ? 'Nb Challenges taken' : 'Nb Tricks tried';
        let nb = [];
        let nbUsers = [];
        let labels = [];
        source.forEach(o => {
          nb.push(o.nb);
          nbUsers.push(o.nbUsers);
          labels.push(o.date);
        });
        this.chart = new Chart(this.ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets: [
              {
                data: nb,
                label: firstLabel,
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)'
              },
              {
                data: nbUsers,
                label: 'Nb active users',
                borderColor: 'rgba(99, 132, 255, 1)',
                backgroundColor: 'rgba(99, 132, 255, 0.4)'
              },
            ]
          },
          options: {scales: {yAxes: [{ticks: {beginAtZero: true}}]}}
        });
      },
      closeGlobalAnnouncementDialog(){
        this.globalAnnouncementDialog = false;
      },
      goTo(url){
        this.$router.push(url);
      }
    }
  }
</script>
