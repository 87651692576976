<template>
  <v-card flat width='220' class='my-2 pa-1 text-xs-left colSpread' :style="`border: 5px solid ${borderColor};`">
    <!-- <div style='position:absolute;top:5px;height:50px;width:1px' :style="{background:strip, left:`${index}px`}" v-for="(strip, index) in strips" :key="`strip-${index}`"/> -->
    <div style="display:flex;justify-content:space-between;">
      <div>
        <div class="subheading font-weight-bold twoLines">
          {{ track.title }}
        </div>
        <v-btn class='mt-3' style='width:140px' round disabled v-if='purchased'>
          Purchased <v-icon right>sentiment_satisfied_alt</v-icon>
        </v-btn>
        <v-btn
          class='mt-3 mb-0'
          style='width:140px'
          round
          color='primary'
          outline
          :loading='challengeLoading'
          :disabled='$root.user.coin_stack < 1000'
          @click='purchaseChallenge'
          v-else
        >
          Buy (-1000 <v-icon class='ml-1'>radio_button_checked</v-icon>)
        </v-btn>
      </div>
      <div style='min-width:65px;padding:5px;'>
        <v-img width='55' :src='`/images/Challenge bonus - variety x${track.bonus.varietyBonus}.png`'  v-if='track.bonus.varietyBonus > 1' class="mb-2"/>
        <v-img width='55' :src='`/images/Challenge bonus - diff x${track.bonus.difficultyBonus}.png`' v-if='track.bonus.difficultyBonus > 1'/>
      </div>
    </div>
  </v-card>
</template>
<script>
  export default {
    props : { track: Object },
    data : function(){
      return {
        strips: [],
        challengeLoading: false,
        purchased: false,
        borderColor: 'white'
      }
    },
    mounted() {
      this.borderColor = this.$root.trickColors[this.track.tricks[0].type];
      // this.makeStrips();
    },
    methods: {
      // makeStrips(){
      //   this.strips = [];
      //   this.track.tricks.forEach(trick => this.strips.push(this.$root.trickColors[trick.type]));
      // },
      purchaseChallenge(){
        this.challengeLoading = true;
        axios.post(`/api/challenges/purchase/${this.track.id}`, {})
        .then(response => {
          if(response.data.challenge){
            this.purchased = true;
            // eventBus.$emit('challengeAdded', response.data.challenge);
            eventBus.$emit('challengesUpdated', false);
            eventBus.$emit('activeChallengeUpdated', 0);
            eventBus.$emit('coinStackUpdate', response.data.coinStackUpdate);
          } else {
            this.$root.snackError(response.data.message);
          }
          this.challengeLoading = false;
        })
        .catch (err => console.log(err))
      }
    }
  }
</script>

<style scoped>
  .colSpread {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
  }
  .twoLines {
    max-height: 48px;
    min-height: 48px;
    overflow-y: hidden;
    padding-left: 10px;
  }
</style>
