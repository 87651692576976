<template>
  <v-container class='text-xs-left'>
    <v-btn to="/admin" :ripple="false" flat>
      <v-icon>chevron_left</v-icon> Back to admin panel
    </v-btn>
    <v-layout row wrap class="mb-5">
      <v-flex xs12 class="headline mb-3" style='display:flex;justify-content:space-between;align-items:center'>
        <div>Messages to Sam</div>
        <div style='width:150px'>
          <v-select
            label='Display'
            outline dense
            v-model='statusDisplayed'
            :items="['All', 'New', 'Important', 'TBD', 'Done']"
            @change="reloadMessages"
            ></v-select>
          </div>
      </v-flex>
      <v-flex xs12 v-for="(message, index) in messages" :key="`message-${index}`">
        <v-card class='mb-2'>
          <v-card-title class="pa-2">
            <v-layout row wrap>
              <v-flex xs4 class='text-xs-left subheading font-weight-bold pr-1'>
                From {{ message.user_email }}
              </v-flex>
              <v-flex xs4 class='text-xs-center grey--text'>
                {{ message.ago }}
              </v-flex>
              <v-flex xs4 class='text-xs-right'>
                <v-chip label :color='message.statusColor' text-color='white'>
                  {{ message.status }}
                </v-chip>
                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-tile @click="markAs('Important', message.id)">
                      <v-list-tile-title>Mark as important</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="markAs('TBD', message.id)">
                      <v-list-tile-title>Mark as TBD</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="markAs('Done', message.id)">
                      <v-list-tile-title>Mark as done</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="prepareDelete(message.id)">
                      <v-list-tile-title>Delete</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-card-text class='text-xs-left'>
            {{ message.message }}
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="deleteDialog" width="340px">
     <v-card>
       <v-card-title class="headline">Delete Message</v-card-title>
       <v-card-text>Are you sure you want to delete this message ?</v-card-text>
       <v-card-actions>
         <v-btn color="primary" flat block @click="deleteDialog=false">
           No
         </v-btn>
         <v-btn color="error" block @click="deleteMessage">
           Yes
         </v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment-timezone';

  export default {
    data : function(){
      return {
        messages: [],
        deleteDialog: false,
        deleteId: null,
        statusDisplayed: 'All',
        statusColors: {New: 'primary', Important: 'error', TBD: 'warning', Done: 'grey'}
      }
    },
    created() {
      eventBus.$emit('loading', {command: 'clear'});
      this.reloadMessages();
    },
    filters:{
      formatDate(date){
        return moment(date).fromNow();
      }
    },
    methods : {
      markAs(status, id){
        let vm = this;
        axios.patch(`/api/messages/${id}`, { status })
        .then(function (response) {
          vm.$root.snackSuccess('Status updated');
          if(vm.statusDisplayed == 'All'){
            vm.messages.forEach( message => {
              if (message.id == id){
                message.status = status;
                message.statusColor = vm.statusColors[status];
              }
            });
          } else if(status != vm.statusDisplayed){
            let tempArray = vm.messages;
            vm.messages = [];
            tempArray.forEach( message => {
              if(message.id != id){
                vm.messages.push(message);
              }
            });
          }
        })
        .catch(function (error) {
          console.log(error); // TODO fix this
        });
      },
      reloadMessages(){
        eventBus.$emit('loading', {command: 'start', name: 'load-messages'});
        let vm = this;
        axios.get(`/api/messages/${this.statusDisplayed}`, {})
        .then(function (response) {
          vm.messages = response.data;
          vm.messages.forEach( message => {
            message.statusColor = vm.statusColors[message.status];
          });
          eventBus.$emit('loading', {command: 'finish', name: 'load-messages'});
        })
        .catch(function (error) {
          vm.$root.snackError('The status could not be updated');
          console.log(error); // TODO fix this
        });
      },
      prepareDelete(id){
        this.deleteId = id;
        this.deleteDialog = true;
      },
      deleteMessage(){
        let vm = this;
        axios.delete(`/api/messages/${this.deleteId}`, {})
        .then(function (response) {
          let tempArray = vm.messages;
          vm.messages = [];
          tempArray.forEach( message => {
            if(message.id != vm.deleteId){
              vm.messages.push(message);
            }
          });
          vm.$root.snackSuccess('Message deleted');
          vm.deleteDialog = false;
        })
        .catch(function (error) {
          vm.$root.snackError('The message could not be deleted');
          console.log(error); // TODO fix this
        });
      }
    }
  }
</script>
