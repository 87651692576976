<template>
    <v-container>
        <v-layout row wrap>
            <v-flex xs12>
                <div class="title">Schools !</div>
                <v-data-table :rows-per-page-items="[10,25,50]" :headers="headers" :items="schools">
                    <template v-slot:items="props">
                        <td>{{ props.item.short_name }}</td>
                        <td>{{ props.item.full_name }}</td>
                        <td>{{ props.item.teachers.length }}</td>
                        <td>{{ props.item.updated_at }}</td>
                        <td>
                            <v-btn icon small @click="editSchool(props.item)">
                                <v-icon small color="primary">edit</v-icon>
                            </v-btn>
                            <v-btn icon small @click="seeSchool(props.item.id)">
                                <v-icon small color="success">visibility</v-icon>
                            </v-btn>
                        </td>
                    </template>
                </v-data-table>
                <edit-school-dialog/>
                <add-school-fab/>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    props: {},
    data: function () {
        return {
            schools: [],
            headers: [
                {text: 'Short name', value: 'short_name', align: 'center'},
                { text: 'Full name', value: 'full_name', align: 'center' },
                { text: 'Nb teachers', value: 'nbTeachers', align: 'center'},
                { text: 'Last updated', value: 'updated_at', align: 'center'},
            ]
        }
    },
    created() {
        eventBus.$emit('loading', { command: 'clear' });
        eventBus.$emit('loading', { command: 'start', name: 'schools-page' });
        axios.get('./api/schools', {})
        .then(resp => {
            this.schools = resp.data;
            eventBus.$emit('loading', { command: 'finish', name: 'schools-page' });
        }).catch(err => console.log(err));
    },
    mounted() {
        eventBus.$on('schoolCreated', this.addSchool);
        eventBus.$on('updateSchool', this.updateSchool);
    },
    befroeDestroy() {
        eventBus.$off('schoolCreated', this.addSchool);
        eventBus.$off('updateSchool', this.updateSchool);
    },
    methods: {
        addSchool(data){
            this.schools.push(data);
        },
        editSchool(school){
            eventBus.$emit('showEditSchoolDialog', school);
        },
        updateSchool(school){
            let temp = this.schools;
            this.schools = [];
            temp.forEach(sch => {
                if (sch.id == school.id) {
                    this.schools.push(school);
                } else {
                    this.schools.push(sch);
                }
            });
        },
        seeSchool(id){
            return this.$router.push(`/schools/${id}`);
        }
    }
}
</script>
