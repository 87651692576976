<template>
  <v-container style='max-width:1200px'>
      <v-layout :row='!smallScreen' :column='smallScreen' :reverse='smallScreen'>
        <div style='flex: 1;max-width:100%;overflow-x:hidden'>
            <div class='samfont-bigtitle grey--text text-xs-center my-1'>
              {{ currentHeading }}
            </div>
          <home-tricks :nbSetsDisplayed='3'/>
        </div>
        <div style='flex: 0 0 350px;max-width: 100%;'>
          <daily-challenges/>
        </div>
      </v-layout>
  </v-container>
</template>
<script>
  export default {
    props : [],
    data : function(){
      return {
        tricksHeadings: [
          'Absolutely delicious tricks',
          'Tricks from the hot tracks',
          'This is the tracks',
          'Fancy tricks buffet',
          'Sweet tricks are made of these',
          'The trickster\'s tracks',
          'No dirty tricks here',
          'Who wants tricks ?',
          'Extra fresh tricks',
          'Right on tracks',
          'Addicted to tricks',
          'These tricks are a treat',
          'Supremely fresh tricks',
          'My favorite tricks',
          'Treat yourself to some tricks !',
          'Trickalicious !',
          'Such fancy tracks !',
          'It\'s trick o\'clock !',
          'In tricks we trust !',
          'Have some tricks',
          'Tricks, tricks, tricks !',
          'A basket full of tricks',
          'Trick me once',
          'Tricks of the trade',
          'Free tricks !'
        ],
        currentHeading: ''
      }
    },
    computed: {
      smallScreen(){
        return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name);
      }
    },
    created() {
      this.currentHeading = this.tricksHeadings[Math.floor(Math.random()*this.tricksHeadings.length)];
      eventBus.$emit('loading', {command: 'clear'});
    },
    methods: {
    },
  }
</script>
