<template>
    <div class="pa-2 mb-2">
        <div class="grey--text title text-xs-left">
            {{ collection.title }}
        </div>
        <div style="display:flex;align-items:flex-start;flex-wrap:wrap;">
            <div 
                class="px-1"
                style="position:relative;"
                v-for="avatar in collection.collectibles"
                :key="avatar.id"
            >
                <v-img 
                    style="border-radius:50%;" 
                    min-width='80px'
                    max-width='80px'
                    class="mr-1"
                    :src='avatar.url'                    
                />
                <v-btn 
                    small round block 
                    color="success" 
                    :disabled="!avatar.accessible" 
                    v-if="avatar.owned"
                    @click="select(avatar)"
                >
                    Select
                </v-btn>
                <v-btn
                    small round block 
                    color="primary"
                    v-else-if="avatar.available"
                    @click="buy(avatar)"
                >
                    View
                </v-btn>
                <div class="notAccessible" v-if="!avatar.accessible"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: { collection: Object },
    data: function () {
        return {
            //
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        select(avatar){
            eventBus.$emit('showCollectibleSelectCard', avatar);
        },
        buy(avatar){
            eventBus.$emit('showBuyAvatarDialog', {collection: this.collection, avatar});
        }
    }
}
</script>
<style scoped>
    .notAccessible {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color: rgba(250,250,250,0.75);
    }
</style>