<template>
  <v-container class='pt-1'>
    <v-layout row wrap>
      <v-flex xs12 style='display:flex;'>
        <v-btn flat small @click='goBack'>
          <v-icon small left style='display:inline-flex;'>chevron_left</v-icon>
          Back to marathon page
        </v-btn>
        <v-spacer/>
        <v-dialog style="margin-top:25px;" v-model="visualizeDialog" :width="visualizeDialogWidth">
          <span class="widthSliderWrapper">
            <div class="widthSliderInner pa-2">
              <div class="subheading grey--text">Modal width</div>
              <v-slider class="py-0 mt-0 mb-2" hide-details v-model="visualizeDialogWidth" min="200" max="2000" />
            </div>
          </span>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" flat v-on="on">
              Visualize leading students
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline" primary-title>
              Leading students
            </v-card-title>
            <v-card-text style="display:flex;flex-wrap:wrap">
              <div v-for="leader in leaders" :key="leader.id" class="text-xs-center fixedDims">
                <v-img :src='leader.avatar' width='80px' max-width='80px' style='border-radius:50%;margin:auto'/>
                {{ leader.name }}
              </div>
            </v-card-text>        
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" flat @click="visualizeDialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn color='primary' flat @click='downloadCSVData'>
          Downlaod as csv
        </v-btn>
        <v-menu style='overflow-y:hidden;' bottom offset-y left :close-on-content-click='false'>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-badge :color="badgeNb > 0 ? 'primary' : 'error'">
                <template v-slot:badge>
                  <span>{{ badgeNb }}</span>
                </template>
                  <v-icon>more_vert</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <div style='display:flex;align-items:center;background:white' v-for='box in phaseBoxes' :key='box.id'>
            <v-checkbox class='ma-3' hide-details  :label='box.title' :disabled='box.loading' v-model='box.value' @change='handleBox(box)'/>
            <span style='min-width:25px' class='mr-3'>
              <v-progress-circular :size="25" :width="1" indeterminate v-if='box.loading'/>
            </span>
          </div>
        </v-menu>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="students"
          :rows-per-page-items='[20,25,30,35,40,50]'
          :key="frCounter"
          >
          <template v-slot:items="props">
            <td>
              <!-- <v-edit-dialog :return-value.sync="props.item.name" @save="save" @cancel="cancel" @open="open" @close="close"> -->
              <v-edit-dialog :return-value.sync="props.item.name" large @save="submitChangeName(props.item)">
                <div>
                  {{ props.item.name }}
                </div>
                <template v-slot:input>
                  <div>
                    <v-text-field v-model="props.item.name" label="Edit name" hide-details class="mt-2" autofocus></v-text-field>
                    <div class="grey--text mb-2">
                      {{  props.item.email  }}
                    </div>
                    <div style='display:flex;justify-content:flex-end'>
                      <div style='display:flex'>
                        <span class='grey--text'>
                          Also save as class name
                        </span>
                        <v-checkbox class='mt-0 pt-0' :ripple='false' hide-details v-model='saveAsProper' />
                      </div>
                    </div>
                    <!-- <div style='display:flex;justify-content:flex-end'>
                      <v-btn color="success" style='width:150px' @click="submitChangeName(props.item)">Confirm</v-btn>
                    </div> -->
                  </div>
                </template>
              </v-edit-dialog>
            </td>
            <!-- <td>{{ props.item.name }}</td> -->
            <td>{{ props.item.className }}</td>
            <td>{{ props.item.details.hikeScore }}</td>
            <td>{{ props.item.details.raceStarsScore }}</td>
            <td>{{ props.item.details.raceRewardsScore }}</td>
            <td>{{ $root.formatRunningTime(props.item.details.runningTime) }}</td>
            <td>{{ props.item.totalScore }}</td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        frCounter: 0,
        loading: false,
        visualizeDialog: false,
        visualizeDialogWidth: 600,
        phaseBoxes: [],
        studentsSource: [],
        updateCounter: 0,
        saveAsProper: true,
        headers: [
          {text: 'Name', value: 'name', align: 'center'},
          {text: 'Class name', value: 'className', align: 'center'},
          {text: 'Hike stars', value: 'details.hikeScore', align: 'center'},
          {text: 'Races stars', value: 'details.raceStarsScore', align: 'center'},
          {text: 'Races rewards', value: 'details.raceRewardsScore', align: 'center'},
          {text: 'Running time', value: 'details.runningTime', align: 'center'},
          {text: 'Total score', value: 'totalScore', align: 'center'},
        ],
      }
    },
    computed: {
      students(){
        this.updateCounter;
        let students = [];
        this.studentsSource.forEach(s => {
          let student = students.filter(o => o.id == s.id)[0];
          if(student){
            student.details.hikeScore += s.details.hikeScore;
            student.details.raceRewardsScore += s.details.raceRewardsScore;
            student.details.raceStarsScore += s.details.raceStarsScore;
            student.details.runningTime += s.details.runningTime;
            student.totalScore += s.totalScore;
          } else {
            students.push({...s})
          }
        });
        return students;
      },
      leaders(){
        return this.students.sort((a,b) => a.totalScore > b.totalScore ? -1 : 1);
      },
      badgeNb(){
        let nb = 0;
        this.phaseBoxes.forEach(box => nb += box.value ? 1 : 0);
        return nb
      }
    },
    mounted(){
      eventBus.$emit('loading', {command: 'clear'});
      eventBus.$emit('loading', {command: 'start', name: 'marathonRanking'});
      axios.get(`/api/marathons/${this.$route.params.id}/ranking`, {})
      .then(resp => {
        resp.data.phases.forEach(phase => this.phaseBoxes.push({id: phase.id, title: phase.title, value: false, loading:false}));
        eventBus.$emit('loading', {command: 'finish', name: 'marathonRanking'});
      })
      .catch(err => console.log(err));
    },
    methods: {
      goBack(){
        this.$router.push(`/marathons/${this.$route.params.id}`);
      },
      handleBox(box){
        if(box.value){
          box.loading = true;
          axios.get(`/api/marathons/${this.$route.params.id}/phases/${box.id}/ranking/`, {})
          .then(resp => {
            Object.values(resp.data.students).forEach(student => this.studentsSource.push({source: box.id, ...student}));
            box.loading = false;
          })
          .catch(err => console.log(err));
        } else {
          this.studentsSource = this.studentsSource.filter(o => o.source != box.id);
        }
        this.updateCounter++;
      },
      downloadCSVData() {
        let csv = 'Name,Email,Class,Hike stars,Races stars, Races rewards,Running time,Total score\n';
        this.students.forEach(s => {
          csv += `${s.name},${s.email},${s.className},${s.details.hikeScore},${s.details.raceStarsScore},${s.details.raceRewardsScore},${this.$root.formatRunningTime(s.details.runningTime)},${s.totalScore}\n`;
        });

        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        anchor.download = 'Marathon Ranking.csv';
        anchor.click();
      },
      submitChangeName(item){
        this.frCounter++;
        if (this.saveAsProper) {
          if (item.name.length > 2) {
            axios.post(`/api/marathons/${this.$route.params.id}/classes/${item.classId}/properNames`, { students: [{ id: item.id, properName: item.name }] })
              .then(resp => this.$root.snackSuccess('Name saved'))
              .catch(err => console.log(err));
          }
        }
        console.log(item);
      }
    }
  }
</script>

<style scoped>
.fixedDims{
    min-width: 110px;
    max-width: 110px;
    min-height: 140px;
    max-height: 140px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .widthSliderWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  .widthSliderInner{
    max-width: 80%;
    width: 450px;
    background-color: white;
    border-radius: 10px;
    z-index: 10000;
  }
</style>
