<template>
    <div style="display:flex;flex-wrap:wrap;width:400px;min-height:300px;">
        <v-card 
            style="margin:1%"
            :style="`cursor:${trick.solved ? 'auto' : 'pointer'}`"
            width="31%"
            :hover="!trick.solved && challengeActive"
            :ripple="!trick.solved && challengeActive"
            :flat="trick.solved || !challengeActive" 
            v-for="(trick, index) in tricks"
            :key="index" @click="startTrick(trick, index)"
        >
            <v-img style="margin:10%;" :src='trick.solved ? $root.user.images.correct : $root.user.images.pending' width='80%'/>
        </v-card>
    </div>
</template>
<script>
export default {
    props: { tricks: Array, challengeActive: Boolean },
    data: function () {
        return {
            //data here
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        startTrick(trick, index){
            if(this.challengeActive){
                if(trick.solved){
                    this.$root.snackSuccess('Question already solved');
                } else {
                    // eventBus.$emit('challengeTrickPicked', {trick, index});
                    eventBus.$emit('challengeTrickPicked', index);
                }
            }
        }
    }
}
</script>
