<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="slide-x-transition" @keydown.esc="initAndClose">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>{{ division.name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="initAndClose">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-progress-linear indeterminate style="position:absolute;top:0px;left:0px;" v-if="loading"/>
            <div style="display:flex;" class="pa-2">
                <v-btn @click="inviteDialog = true">
                    Invite students <v-icon right>group_add</v-icon>
                </v-btn>
                <v-spacer/>
                <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details/>
            </div>
            <div>
                <v-data-table :headers="headers" :search="search" :items="division.students" hide-actions>
                    <template v-slot:items="props">
                        <!-- <td>{{ props.item.name }}</td> -->
                        <td>
                            <v-edit-dialog :return-value.sync="props.item.name" large lazy @save="changedName(props.item)">
                                <div>{{ props.item.name }}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="props.item.name" :rules="nameRules" label="Edit name" single-line autofocus/>
                                </template>
                            </v-edit-dialog>
                        </td>
                        <td>{{ props.item.email }}</td>
                        <td class="text-xs-center">{{ props.item.star_count }}</td>
                        <td class="text-xs-center">{{ props.item.smile_count }}</td>
                        <td class="text-xs-center">
                            <v-btn icon @click="prepareMoveStudent(props.item)">
                                <v-icon color="error">exit_to_app</v-icon>
                            </v-btn>
                        </td>
                    </template>
                    <template v-slot:no-results>
                        <div class="text-xs-center headline grey--text text--lighten-2 py-3">
                            No student matching this search
                        </div>
                    </template>
                </v-data-table>
            </div>
        </v-card>
        <v-dialog v-model="moveStudentDialog" width="450">
            <v-card>
                <v-card-title class="headline">
                    Move {{ focusStudent.name }}
                </v-card-title>
                <v-card-text>
                    <v-select hide-details outline label="Move to" v-model="studentDestination" :items="moveStudentItems" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" :disabled="moveStudentLoading" width="160px" @click="closeMoveStudentDialog">
                        Cancel
                    </v-btn>
                    <v-btn :loading="moveStudentLoading" width="160px" color="error" @click="moveStudent">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model='inviteDialog' width='80%' max-width='850px'>
            <invite-students-card :id="division.id" :className="division.name" :studentsInvited="division.invites" />
        </v-dialog>
    </v-dialog>
</template>
<script>
export default {
    props: { school: Object },
    data: function () {
        return {
            dialog: false,
            division: {invites: []},
            headers: [
                {text: 'Name', value: 'name'},
                {text: 'Email', value: 'email'},
                { text: 'Star count', value: 'star_count', align: 'center'},
                { text: 'Smile count', value: 'smile_count', align: 'center' },
                { text: 'Move', value: '', align: 'center', sortable: false}
            ],
            search: '',
            focusStudent: {},
            moveStudentDialog: false,
            moveStudentLoading: false,
            loading: false,
            oldName: '',
            studentDestination: 0,
            inviteDialog: false,
            nameRules: [
                v => !!v || 'You must choose a name',
                v => (v && v.length >= 3) || 'The short name must be at least 3 characters long'
            ],
        }
    },
    computed: {
        moveStudentItems(){
            if(this.school.divisions){
                return [{ text: 'Remove from class', value: 0 }, ...this.school.divisions.filter(div => div.id != this.division.id).map(div => ({text: div.name, value: div.id}))]
            }
            return [{ text: 'Remove from class', value: 0 }];
        }
    },
    mounted() {
        eventBus.$on('seeSchoolClass', this.initDialog);
        eventBus.$on('closeInviteDialog', this.closeInviteDialog);
        eventBus.$on('updatedInvitedStudents', this.updateInvitedStudents);
    },
    beforeDestroy() {
        eventBus.$off('seeSchoolClass', this.initDialog);
        eventBus.$off('closeInviteDialog', this.closeInviteDialog);
        eventBus.$off('updatedInvitedStudents', this.updateInvitedStudents);
    },
    methods: {
        initDialog(division){
            this.division = division;
            this.dialog = true;
        },
        prepareMoveStudent(student){
            this.focusStudent = student;
            this.moveStudentDialog = true;
        },
        closeMoveStudentDialog(){
            this.moveStudentDialog = false;
        },
        changedName(student){
            this.loading = true;
            axios.patch(`/api/schools/${this.school.id}/classes/${this.division.id}/students/${student.id}/name`, { name: student.name })
            .then(resp => {
                this.$root.snackSuccess('Name updated');
                this.loading = false;
            })
        },
        updateInvitedStudents(invites){
            this.division.invites = invites;
        },
        closeInviteDialog() {
            this.inviteDialog = false;
        },
        moveStudent(){
            if(this.studentDestination != null){
                this.moveStudentLoading = true;
                axios.patch(`/api/schools/${this.school.id}/classes/${this.division.id}/students/${this.focusStudent.id}/move`, { divId: this.studentDestination })
                .then(resp => {
                    this.moveStudentLoading = false;
                    this.moveStudentDialog = false;
                    this.division.students = this.division.students.filter(st => st.id != this.focusStudent.id);
                    this.$root.snackSuccess('Student moved');
                    eventBus.$emit('updateSchool', resp.data);
                }).catch(err => console.log(err.response));
            }
        },
        initAndClose(){
            this.division = {invites: []};
            this.dialog = false;
        }
    }
}
</script>