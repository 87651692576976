<template>
    <v-dialog v-model="dialog" width="300">
        <v-card class="pa-2">
            <v-card-title class="headline">
                Reveal pixels
            </v-card-title>
            <v-card-text style="display:flex;flex-direction:column;justify-content:center;align-items:center;">
                <div>
                    Click on a section to reveal it.
                </div>
                <div style="display:flex;flex-wrap:wrap;position:relative;" :style="`min-width:${pixelSize * 9}px;max-width:${pixelSize * 9}px;`">
                    <challenge-section-display :section="section" :sectionIndex="sectionIndex" :revealed="revealed" :pixelSize="pixelSize"/>
                    <div style="position:absolute;top:0px;left:0px;display:flex;flex-wrap:wrap;width:100%;">
                        <v-card 
                            flat ripple hover
                            color="transparent"
                            style="display:flex;justify-content:center;align-items:center;"
                            :width="pixelSize * 3"
                            :height="pixelSize * 3"
                            :style="`${revealed.subsections.indexOf(`${sectionIndex}-${subsectionNb - 1}`) == -1 && revealed.sections.indexOf(`${sectionIndex}`) == -1 ? '' : 'visibility:hidden'}`"
                            v-for="subsectionNb in 9"
                            :key="subsectionNb"
                            @click="revealSection(subsectionNb - 1)"
                        >
                            <v-icon :size="pixelSize*2" color="grey">
                                grid_view
                            </v-icon>
                        </v-card>
                    </div>
                    <div class="greyOverlay" v-if="loading || credit <= 0"/>
                </div>
                <div class="subheading text-xs-center mt-2">
                    <b>{{ credit }}</b> pixels remaining
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn flat color="primary" @click="dialog = false">
                    Close
                </v-btn>
            </v-card-actions>
            <v-progress-linear height="2px" class="my-0" indeterminate style="position:absolute;top:0px;left:0px;width:100%" v-if="loading"/>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: { section: Array, sectionIndex: Number, revealed: Object, credit: Number },
    data: function () {
        return {
            dialog: false,
            pixelSize: 27,
            loading: false,
            subSectionsClicked: []
        }
    },
    mounted() {
        eventBus.$on('showRevealPixelDialog', this.showRevealPixelDialog);
    },
    beforeDestroy() {
        eventBus.$off('showRevealPixelDialog', this.showRevealPixelDialog);
    },
    methods: {
        showRevealPixelDialog(){
            this.subSectionsClicked = [];
            this.dialog = true;
        },
        revealSection(subSectionIndex){
            if(this.subSectionsClicked.indexOf(subSectionIndex) < 0){
                this.loading = true;
                this.subSectionsClicked.push(subSectionIndex);
                axios.post('/api/canvas/reveal', {sectionIndex: this.sectionIndex, subSectionIndex: subSectionIndex})
                .then(resp => {
                    eventBus.$emit('canvasUpdate', resp.data.canvas);
                    eventBus.$emit('riddleFinished', resp.data.riddle);
                    if(resp.data.bonusSmiles > 0){
                        eventBus.$emit('canvasCompleted', resp.data.bonusSmiles);
                        eventBus.$emit('smileCountUpdate', { newCount: resp.data.smileCount, smilesAdded: resp.data.bonusSmiles });
                    }
                    this.loading = false;
                }).catch(err => {
                    console.log(err);
                    if(err.response){
                        this.$root.snackError(err.response.data.message);
                    }
                    this.loading = false;
                });
            }
        }
    }
}
</script>

<style scoped>
    .greyOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(150, 150, 150, 0.2);
    }
</style>
