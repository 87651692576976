<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs12 class="headline font-weight-bold">
        Recent announcements
      </v-flex>
      <v-flex xs12 class="grey--text caption mb-3">
        Announcements are deleted after 6 weeks
      </v-flex>
      <v-flex xs12 style="display:flex;flex-wrap:wrap;margin-bottom:150px;">
        <div style="height:218px;position:relative;width:370px;" class="ma-1" v-for='announcement in announcements' :key='`${announcement.id}`'>
          <v-hover>
            <v-card 
              hover slot-scope="{ hover }" 
              class='px-2 pt-2 pb-1' 
              width='370px' 
              style='border-radius:20px;height:fit-content;overflow-y:hidden;position:absolute;'
              :style="hover ? `max-height:${cardMaxHeight(announcement)}px;z-index:100` : 'max-height:220px;z-index:1'"
            >
              <component :is='`announcement-${announcement.type}`' :announcement='announcement' />
              <div class="mt-2 grey--text caption">
                {{ announcement.timeAgo }}
              </div>
            </v-card>
          </v-hover>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        announcements: [],
      }
    },
    created(){
      eventBus.$emit('loading', { command: 'clear' });
      eventBus.$emit('loading', { command: 'start', name: 'announcements-page' });
    },
    mounted() {
      this.loadAnnouncements()
      eventBus.$on('deleteAnnouncement', this.deleteAnnouncement);
    },
    beforeDestroy(){
      eventBus.$off('deleteAnnouncement', this.deleteAnnouncement);
    },
    methods: {
      deleteAnnouncement(id) {
        this.announcements = this.announcements.filter(a => a.id != id);
      },
      cardMaxHeight(announcement) {
        if (announcement.type == 'marathonCheckpoint') return 450;
        if (announcement.type == 'schoolInvite') return 310;
        if (announcement.type == 'global') return 400;
        return 250;
      },
      loadAnnouncements(){
        axios.get('/api/announcements', {})
        .then(resp => {
          this.announcements = resp.data;
          eventBus.$emit('loading', { command: 'finish', name: 'announcements-page' });
        }).catch(err => console.log(err));
      },
      viewAnnouncement(id){
        let announcement = this.rawAnnouncements.find(o => o.id == id)
        console.log(announcement);
      }
    }
  }
</script>
