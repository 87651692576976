<template>
  <v-layout row wrap>
    <v-flex xs12 md8 offset-md2 lg6 offset-lg3>
      <v-card width="100%" elevation="3">
        <v-card-title>
          <p class="headline">Reset password</p>
        </v-card-title>
        <v-card-text>
          <v-form >

              <v-text-field label="Email" name="email" :value="userEmail" disabled></v-text-field>
              <v-text-field :type="'password'" label="Password" name="password" clearable></v-text-field>
              <v-text-field :type="'password'" label="Confirm Password" name="password_confirmation" clearable></v-text-field>

              <v-btn color="primary">Reset password</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    props : [],
    data : function(){
      return {
        r
      }
    },
    created() {

    },
    methods : {

    }
  }
</script>
