<template>
  <v-card flat class='pa-2'>
    <v-layout row wrap>
      <v-flex xs12 class='headline text-xs-center font-weight-bold ma-2'>
        Total : {{ totalScore }}
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <span style='display:flex' v-for='(question, index) in questions' :key='index'>
            <v-card class='qnb body-2 my-1 mr-1' dark color='primary'>
              {{ index + 1 }}
            </v-card>
            <span
            style='font-size:16px;height:30px;display:flex;align-items:center;'
            class='ma-1'
            v-html='question.formated'
            />
            <span style='font-size:16px;height:30px;display:flex;align-items:center;' class='ma-1' v-html='answers[index].html' v-if='answers[index]'></span>
            <span style='font-size:16px;height:30px;display:flex;align-items:center;' class='my-1 ml-2 mr-3' v-else>???</span>
            <span style='font-size:16px;height:30px;display:flex;align-items:center;' class='my-1 ml-1 mr-3 katex' v-if='answers[index]'>({{ answers[index].score }})</span>
          </span>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
  import trick from '../../tricks';

  export default {
    props : { attempt: Object, orgTrick: Object },
    data : function(){
      return {
        trick: {},
      }
    },
    watch: {
      orgTrick:{
        handler: function(val){
          if(val.formula != undefined && val.options != undefined){
            let formula = typeof val.formula === 'object' && val.formula !== null ? val.formula : JSON.parse(val.formula);
            let options = typeof val.options === 'object' && val.options !== null ? val.options : JSON.parse(val.options);
            this.trick = new trick(val.title, val.image_description, val.text_description, val.type, formula, options);
          }
        },
        immediate: true
      }
    },
    computed:{
      questions(){
        return this.attempt.questions ? this.trick.displayQuestions(10, JSON.parse(this.attempt.questions)) : [];
      },
      answers(){
        if(this.attempt.answers == undefined) return [];
        let orgAnswers = JSON.parse(this.attempt.answers);
        let answers = [];
        orgAnswers.forEach(orgAnswer => {
          let answer = { score: 0, html: '' };
          orgAnswer.answers.forEach(ans => {
            if(ans.correct){
              answer.html = ans.value == null ? '--' : this.trick.formatAnswer(ans.value, true);
              answer.score = ans.score;
            }
          });
          answers.push(answer);
        });

        return answers;
      },
      totalScore() {
        return this.answers.reduce((total, num) => total + num.score, 0);
      }
    },
    methods: {

    }
  }
</script>

<style scoped>
  .qnb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
