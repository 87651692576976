<template>
  <v-dialog v-model='userDialog' fullscreen hide-overlay transition="slide-x-transition" @keydown.esc="initAndClose">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          <div>{{ user.name }}</div>
          <div class='grey--text text--lighten-1 subheading text-truncate'>
            {{ user.email }}
          </div>
        </v-toolbar-title>
        <span v-if='$vuetify.breakpoint.name != "xs"'>
          <span class='headline font-weight-medium ml-4 mr-2'>
            {{ user.smile_count }}
            <v-icon medium color="white">sentiment_satisfied_alt</v-icon>
          </span>
          <span class='headline font-weight-medium'>
            {{ user.star_count }}
            <v-icon medium color="orange">star</v-icon>
          </span>
        </span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="initAndClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear indeterminate class='ma-0' style='position:fixed;top:64px;z-index:10' v-if='loading'></v-progress-linear>
      <v-container grid-list-md>
        <v-layout :style='$vuetify.breakpoint.name == "xs" ? "flex-direction:column-reverse" : "flex-direction:row"' wrap>
          <v-flex xs12 sm8>
            <v-hover v-for='perf in displayedPerfs' :key='perf.id'>
              <v-card class='pa-2 ma-1' slot-scope="{ hover }">
                <v-layout row wrap>
                  <v-flex xs3 class='subheading grey--text'>Trick title</v-flex>
                  <v-flex xs3 class='subheading grey--text'>Suspicious</v-flex>
                  <v-flex xs3 class='subheading grey--text'>Score</v-flex>
                  <v-flex xs3 class='subheading grey--text'>Date</v-flex>
                  <v-flex xs3>{{ perf.trick.title }}</v-flex>
                  <v-flex xs3>
                    <span v-if='perf.attempt'>
                      {{ perf.attempt.suspicious }}
                    </span>
                    <span v-else class='error--text font-weight-bold subheading'>
                      ??
                    </span>
                  </v-flex>
                  <v-flex xs3>{{ perf.score }}</v-flex>
                  <v-flex xs3>{{ perf.created_at | formatDateTime }}</v-flex>
                  <v-expand-transition>
                    <v-flex xs12 v-if='hover'>
                      <answers-recap :attempt='perf.attempt' :orgTrick='perf.trick' v-if='perf.attempt'/>
                      <v-layout justify-center v-else class='error--text headline pa-2'>
                        No Attempt
                      </v-layout>
                    </v-flex>
                  </v-expand-transition>
                </v-layout>
              </v-card>
            </v-hover>
          </v-flex>
          <v-flex xs12 sm4 class='text-xs-center'>
            <v-avatar size="72x">
              <img :src="avatar" v-if='avatar'>
            </v-avatar>
            <div class='headline'>
              {{ user.name }}
            </div>
            <div class='caption grey--text mb-2'>
              created {{ user.created_at | formatDate }}
            </div>
            <div class='mb-2' v-if='user.properNames'>
              <div class='subheading grey--text'>
                Proper names
              </div>
              <v-chip v-for='(properName, index) in user.properNames' :key='index'>{{ properName.name }}</v-chip>
            </div>
            <div class='subheading grey--text'>
              Coin stack
            </div>
            <div class='headline'>
              {{ user.coin_stack }}<v-icon medium style='display:inline' class='ml-1'>radio_button_checked</v-icon>
            </div>
            <div>
              <v-pagination v-model="perfsPage" :length="5"/>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
  import moment from 'moment-timezone';

  export default {
    props : { userId: Number},
    data : function(){
      return {
        avatar: null,
        userDialog: false,
        user: {},
        perfs: [],
        displayedPerfs: [],
        perfsPage: 1,
        loading: false,
      }
    },
    filters: {
      formatDate(val){
        return moment.tz(val, 'UTC').clone().tz(moment.tz.guess()).fromNow();
      },
      formatDateTime(val){
        return moment.tz(val, 'UTC').clone().tz(moment.tz.guess()).format('DD/MM/YY - HH:mm');
      }
    },
    watch: {
      userId:{
        handler: function(val){
          if(val != 0) {
            this.userDialog = true;
            this.loading = true;
            axios.get(`/api/admin/user/${val}`, {})
            .then(resp => {
              this.user = resp.data.user;
              this.perfs = resp.data.perfs;
              this.displayedPerfs = resp.data.perfs.slice(0,10);
              this.avatar = resp.data.user.avatar ? resp.data.user.avatar : 'images/default avatar.png';
              this.loading = false;
            })
            .catch(err => console.log(err));
          }
        }
      },
      perfsPage:{
        handler: function(val){
          this.displayedPerfs = this.perfs.slice(10*(val-1),10*(val-1)+10);
        }
      }
    },
    methods: {
      initAndClose(){
        this.userDialog = false;
        eventBus.$emit('initUser', {});
      }
    }
  }
</script>
