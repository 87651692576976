<template>
  <v-dialog :persistent='loading' v-model="addClassDialog"  max-width="500px" @keydown.esc="cancelAddClass" @keydown.enter.prevent="addClassSubmit">
    <v-btn slot="activator" fab dark color="primary" fixed bottom right><v-icon dark style='display:inline-flex'>add</v-icon></v-btn>
    <v-card>
      <v-card-title>
        <span class="headline">Create a class</span>
      </v-card-title>
      <v-card-text>
        <p class="caption red--text text-xs-center" style="margin:0px;height:18px">{{ addClassError }}</p>
        <v-container grid-list-md>
          <v-form ref="addClassForm" v-model="valid" lazy-validation>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field label="Name" v-model="name" :rules="nameRules" autofocus clearable validate-on-blur></v-text-field>
                <v-select outline label="School" v-model="schoolChoice" :items="schoolItems" />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" flat style="width:140px" :disabled='loading' @click="cancelAddClass">Cancel</v-btn>
        <v-btn color="primary" style="width:140px" :loading='loading' @click="addClassSubmit" :disabled="!valid">Create Class</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    data : function(){
      return {
        addClassDialog: false,
        name : '',
        schoolChoice: 0,
        addClassError : '',
        valid : true,
        loading: false,
        nameRules: [
          v => !!v || 'You must choose a name',
          v => (v && v.length >= 3) || 'The class name must be at least 3 characters long'
        ]
      }
    },
    computed: {
      schoolItems() {
        let schools = [{ text: 'No school yet', value: 0 }, { text: 'I do not want this class to belong to a school', value: 1 }];
        this.$root.schools.forEach(school => {
          schools.push({ value: school.id, text: school.full_name });
        });

        return schools;
      }
    },
    methods : {
      addClassSubmit(){
        this.loading = true;
        if(this.$refs.addClassForm.validate()){
          let vm = this
          axios.post('./api/classes', {name: this.name, schoolId: this.schoolChoice})
          .then(function (response) {
            vm.name = '';
            vm.$refs.addClassForm.reset();
            vm.loading = false;
            vm.addClassDialog = false;
            vm.$root.snackSuccess("Class created");
            eventBus.$emit('refreshClassesData', {});
            // eventBus.$emit('divisionAdded', response.data);
          })
          .catch(function (error) {
            console.log(error);
            if (typeof error.response.data.errors !== 'undefined') {
              vm.addClassError = '';
              _.forEach(error.response.data.errors, function(value){
                vm.addClassError += " "+value;
              });
            } else {
              vm.$root.snackError('An error has occured, the class could not be added.');
            }
          });
        } else {
          vm.$root.snackError('Invalid data submitted');
        }
      },
      cancelAddClass(){
        this.name = '';
        this.$refs.addClassForm.reset();
        this.addClassDialog = false;
      }
    }
  }
</script>
