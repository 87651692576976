<template>
  <v-hover>
    <v-card style='position:relative' slot-scope="{ hover }">
      <v-img :src="`/images/about/${fileName}.gif`" v-if='runGif'></v-img>
      <v-img :src="`/images/about/${fileName}-alt.png`" v-else></v-img>
      <v-btn fab large style='position:absolute;top:50%;left:50%;margin-top:-36px;margin-left:-36px' v-if='hover' @click='runGif = ! runGif'>
        <v-icon style='display:inline-flex' v-if='runGif'>pause</v-icon>
        <v-icon style='display:inline-flex' v-else>play_arrow</v-icon>
      </v-btn>
    </v-card>
  </v-hover>
</template>
<script>
  export default {
    props : { fileName: String },
    data : function(){
      return {
        runGif: true
      }
    }
  }
</script>
