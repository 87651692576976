<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span style='cursor:pointer' class='primary--text font-weight-bold' :class='{"text-capitalize": capitalize}' v-on="on">
        {{ aboutData.text }}
      </span>
    </template>
    <span>
      <div class='font-weight-bold text-capitalize'>
        {{ aboutData.name }}
      </div>
      {{ aboutData.hint }}
    </span>
  </v-tooltip>
</template>
<script>
  import glossary from '../../glossary.json';

  export default {
    props : { about: String, plural: {type: Boolean, default: false}, capitalize: {type: Boolean, default: false} },
    data : function(){
      return {

      }
    },
    computed: {
      aboutData(){
        let name = glossary[this.about].singular;
        let text = this.plural ? glossary[this.about].plural : glossary[this.about].singular;
        let hint = glossary[this.about].hint;
        return {name, text, hint}
      }
    }
  }
</script>
