<template>
  <v-hover style='position:relative;align-self:flex-start;overflow-x:hidden;overflow-y:hidden;'>
    <v-card
      slot-scope="{ hover }" hover flat
      :height='hover ? "442px" : "64px"'
      :width='hover ? "420px" : "220px"'
      color="rgba(255,255,255,0)"
      style='position:absolute'
    >
    <div class='pb-2 mt-1' style='display:flex;justify-content:flex-end;align-items:center;height:64px;white-space:nowrap;' :style="`color:${$root.toolbarColors.font_color}`">
      <span class='headline font-weight-medium'>
        {{ $root.user.smile_count }}
        <v-icon medium color="blue">sentiment_satisfied_alt</v-icon>
      </span>
      <span class='headline font-weight-medium ml-3'>
        {{ $root.user.star_count }}
        <v-icon medium color="orange">star</v-icon>
      </span>
    </div>
    <transition name='slideFade'>
      <v-layout style="background: #f5f5f5;margin-top:-3px;" row wrap class='pa-2' v-if='hover'>
        <v-flex xs12 class='mb-2'>
          <span class='samfont-title ml-1'>
            Star count :
          </span>
          <span class='headline'>
            {{ $root.user.star_count }} <v-icon medium color="orange" style='margin-left:-5px'>star</v-icon>
          </span>
        </v-flex>
        <v-flex xs12 class='mb-1'>
          <div class='title'>
            <v-icon color="orange" size='20px'>star</v-icon> are earned by trying tricks.
          </div>
          <div class='caption grey--text'>
            Your <span class='orange--text font-weight-bold'>star count</span> is the sum of your 3 best performances on all the tricks you've tried.
          </div>
        </v-flex>
        <v-flex xs12 class='my-2'>
          <div>
            <span class='samfont-title ml-1'>
              Smile count :
            </span>
            <span class='headline'>
              {{ $root.user.smile_count }} <v-icon medium color="blue" style='margin-left:-5px'>sentiment_satisfied_alt</v-icon>
            </span>
          </div>
          <div class='subheading pl-1 pb-2'>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  Full smile count :
                  {{ $root.user.fullSmileCount }}
                  <v-icon color="blue" size='20px' style='margin-left:-2px'>sentiment_satisfied_alt</v-icon>
                </span>
              </template>
              <span>Includes smiles spent on collectibles</span>
            </v-tooltip>
          </div>
        </v-flex>
        <v-flex xs12 class='mb-3'>
          <div class='title'>
            <v-icon color="blue" size='20px'>sentiment_satisfied_alt</v-icon> are earned by completing challenges.
          </div>
          <div class='caption grey--text'>
            Your <span class='blue--text font-weight-bold'>smile count</span> is the sum of all the smiles you've gained in challenges (minus the smiles you've lost by letting challenges expire).
          </div>
        </v-flex>
        <v-flex xs12 class='subheading'>
          <div>
            You can use <v-icon small color="orange">star</v-icon> and <v-icon small color="blue">sentiment_satisfied_alt</v-icon> to buy collectibles and personalise your Sam's Tricks experience
          </div>
          <div class='py-2' style='width:100%;display:flex;justify-content:center'>
            <v-btn block color='primary' class='pa-2 mx-1' @click='goToCollectibles'>
              See collectibles
              <v-icon>chevron_right</v-icon>
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </transition>
    </v-card>
  </v-hover>
</template>
<script>
import { isFulfilled } from 'q';

  export default {
    data : function(){
      return {
        //
      }
    },
    mounted() {
      eventBus.$on('starCountUpdate', newCount => {
        this.$root.user.star_count = newCount;
      });
      eventBus.$on('smileCountUpdate', data => {
        this.$root.user.smile_count = data.newCount;
        if(data.smilesAdded){
          this.$root.user.fullSmileCount += data.smilesAdded;
        }
      });
    },
    methods: {
      goToCollectibles(){
        if(this.$router.currentRoute.path != '/collectibles'){
          this.$router.push('/collectibles');
        }
      }
    }
  }
</script>

<style scoped>
.slideFade-enter-active {
  animation: slideFade .5s;
}
.slideFade-leave-active {
  opacity: 0;
}

@keyframes slideFade {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  20% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
