<template>
  <v-dialog v-model='editHikeDialog' fullscreen hide-overlay transition="slide-x-transition" @keydown.esc="initAndClose">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Edit Hike</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip label dark color='black' v-if='hike.marathon_id != null'>Marathon hike</v-chip>
        <v-btn icon dark @click="initAndClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="py-3 px-5">
        <v-form ref="editHikeForm" v-model="formValid" lazy-validation>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-text-field label="Title" v-model="hike.title" :rules="titleRules" autofocus clearable validate-on-blur :disabled='hike.marathon_id != null'/>
                <v-select v-model="hikeClasses" :items="classes" chips deletable-chips label="Classes" multiple v-if='hike.marathon_id == null'/>
                <v-select v-model="hike.term" :items="[1,2,3,4,5,6,7,8,9,10]" label="Term" v-if='hike.marathon_id == null'/>
                <div class='headline grey--text text-xs-center my-5 font-weight-thin' style='width:100%' v-if='hike.marathon_id != null'>-- Marathon hike --</div>
                <div style='display:flex;align-items:center;position:relative;'>
                  <span>
                    Individual extra time
                  </span>
                  <v-btn icon @click="showHandicapDialog" v-if='hike.marathon_id == null'>
                    <v-icon color='primary'>help</v-icon>
                  </v-btn>
                  <Transition name="slide-fade">
                    <v-card class='pa-3' width='250px' style='position:absolute;left:175px;top:-45px;background:white;z-index:10;cursor:pointer' v-if='showHandicapInfo' @click="showHandicapInfo=false">
                        <v-btn icon small @click="showHandicapInfo=false" style="position:absolute;top:0px;right:0px;">
                          <v-icon small color="red">close</v-icon>
                        </v-btn>
                        <div class='subheading grey--text'>Individual Extra Time</div>
                        <div>
                          You can allow students to use extra time on races trick. This helps students who are struggling to still be able to participate in the race.
                          <br/>
                          <span class='caption'>
                            To attribute the extra time allowance, navigate to the class' page and edit the "extra time column" in the class' table.
                          </span>
                        </div>
                      </v-card>
                  </Transition>
                  <v-select
                    v-model="handicap"
                    class='ml-1'
                    :items="[{text: 'None', value: 0}, {text: '25%', value: '0.25'}, {text: '50%', value: '0.5'}, {text: '75%', value: '0.75'}, {text: '100%', value: '1'}]"
                    label="Apply"
                  />
                </div>
              </v-flex>
              <v-flex xs12 md6>
                <track-search :multi='true' :forceValidate='false' ></track-search>
              </v-flex>
              <v-flex xs12>
                <span class='mt-2 grey--text'>Tracks</span>
                <draggable v-model="hike.tracks">
                  <transition-group name='bounce'>
                    <v-layout class='my-1' v-for="track in hike.tracks" :key='`track-${track.id}`'>
                      <v-flex xs9>
                        <hike-track-card :track="track"></hike-track-card>
                      </v-flex>
                      <v-flex xs3>
                        <v-select v-model="track.timeModifier" outline hide-details dense style='max-width:200px' class='ml-2' :items="timeModifItems" append-icon='timer' label="Extra time"></v-select>
                      </v-flex>
                    </v-layout>
                  </transition-group>
                </draggable>
              </v-flex>
              <v-flex xs12 style='display:flex'>
                <v-dialog v-model="deleteDialog" width="400" v-if='hike.marathon_id == null'>
                  <template v-slot:activator="{ on }">
                    <v-btn color="error" style='width:200px' v-on="on" v-if='!["xs", "sm"].includes($vuetify.breakpoint.name)'>
                      Delete
                    </v-btn>
                    <v-btn color="error" fab small dark v-on="on" v-else>
                      <v-icon style='display:inline-flex'>delete_forever</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline" primary-title>
                      Delete {{ hike.title }} ?
                    </v-card-title>
                    <v-card-text>
                      Are you sure you want to permanently delete this hike and all of its sections ?
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" style='width:125px' flat @click="deleteDialog = false">Cancel</v-btn>
                      <v-btn color="error" style='width:125px' @click="proceedDelete">Delete</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-spacer></v-spacer>
                <v-btn color="error" style='width:200px' flat @click="initAndClose" v-if='!["xs", "sm"].includes($vuetify.breakpoint.name)'>Cancel</v-btn>
                <v-btn color="success" style='width:200px' @click="submitChanges">Save</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props : { classes: Array },
    data : function(){
      return {
        editHikeDialog: false,
        deleteDialog: false,
        formValid: false,
        showHandicapInfo: false,
        hike: {},
        hikeClasses: [],
        handicap: 0,
        timeModifItems: [
          {value: 0, text: '0s'},
          {value: 5, text: '5s'},
          {value: 10, text: '10s'},
          {value: 15, text: '15s'},
          {value: 20, text: '20s'},
          {value: 30, text: '30s'},
          {value: 45, text: '45s'},
        ],
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 3) || 'The hike title must be at least 3 characters long'
        ]
      }
    },
    mounted() {
      eventBus.$on('editHike', this.showDialog);
    },
    beforeDestroy(){
      eventBus.$off('editHike', this.showDialog);
      eventBus.$off('trackSelected', this.addTrack);
      eventBus.$off('removeTrack', this.removeTrack);
    },
    methods: {
      showDialog(hike){
        this.hike = {...hike};
        this.handicap = JSON.parse(hike.options).handicap;
        this.hikeClasses = hike.divisions.map(d => d.id);
        eventBus.$on('trackSelected', this.addTrack);
        eventBus.$on('removeTrack', this.removeTrack);
        this.editHikeDialog = true;
      },
      showHandicapDialog(){
        this.showHandicapInfo = !this.showHandicapInfo;
      },
      addTrack(data){
        if(this.editHikeDialog && data.id > 0){
          let duplicate = false;
          this.hike.tracks.forEach(track => {
            if(track.id == data.track.id){
              duplicate = true;
            }
          });
          // if(!this.hike.tracks.some(t => t.id == data.id)){
          if(duplicate){
            this.$root.snackError("This track is already in the hike")
          } else {
            data.track.timeModifier = 0;
            data.track.new = true;
            this.hike.tracks.push(data.track);
          }
        }
      },
      removeTrack(id){
        let tracks = this.hike.tracks;
        this.hike.tracks = [];
        tracks.forEach(track => {
          if(track.id != id){
            this.hike.tracks.push(track);
          }
        });
      },
      toggleDetails(){
        this.detailsDiv = !this.detailsDiv;
      },
      initAndClose(){
        this.hike = {};
        eventBus.$off('trackSelected', this.addTrack);
        eventBus.$off('removeTrack', this.removeTrack);
        eventBus.$emit('clearTrackSearch', {});
        this.editHikeDialog = false;
      },
      submitChanges(){
        eventBus.$emit('clearTrackSearch', {});
        if(this.$refs.editHikeForm.validate()){
          axios.patch(`api/hikes/${this.hike.id}`, {
            title: this.hike.title,
            term: this.hike.term,
            options: {handicap: this.handicap},
            classes: this.hikeClasses,
            tracks: this.hike.tracks
          })
          .then(resp => {
            eventBus.$emit('hikeUpdated', resp.data);
            this.initAndClose();
          })
          .catch(err => console.log(err));
        }
      },
      proceedDelete(){
        axios.delete(`api/hikes/${this.hike.id}`, {})
        .then(resp => {
          eventBus.$emit('hikeDeleted', this.hike.id);
          this.$root.snackSuccess("The hike has been deleted");
          this.initAndClose();
        })
        .catch(err => console.log(err));
      }
    }
  }
</script>

<style>
  .slide-fade-enter-active 
  .slide-fade-leave-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(-15px);
    opacity: 0;
  }
</style>
