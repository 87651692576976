<template>
    <v-dialog width="350" v-model="dialog">
        <template v-slot:activator="{ on }">
            <v-btn 
              color="success"
              :loading="fetchingChallenges"
              round
              :style="$vuetify.breakpoint.name == 'xs' ? '' : 'width:320px;'" 
              @click.prevent.stop="getNewCanvas"
            >
              Get a new canvas
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">
                Buy new canvas
            </v-card-title>
            <v-card-text>
                <div>
                    You have some unfinished work. To get a new canvas, you have to pay 5<v-icon small>radio_button_checked</v-icon> for each unrevealed pixel in your other canvases.
                </div>
                <div class="mt-4">
                    <v-btn large block color="success" :disabled="fetchingChallenges || !affordable" :loading="paying" @click="buyNewCanvas">
                        Pay {{ cost }} <v-icon class="ml-1">radio_button_checked</v-icon> 
                    </v-btn>
                    <div class="caption error--text text-xs-center" v-if="!affordable">
                        You do not have enough coins to purchase a new canvas
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn style="width:160px" color="error" flat :disabled="fetchingChallenges || paying" @click="dialog = false">
                    Cancel
                </v-btn>
                <v-btn style="width:160px" class="mx-2" color="primary" flat :disabled="fetchingChallenges || paying" to="/canvas" v-if="inHomePage">
                    See details <v-icon right>open_in_new</v-icon>
                </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: { totalUnrevealed: Number, inHomePage: Boolean },
    data: function () {
        return {
            cost: 0,
            dialog: false,
            fetchingChallenges: false,
            paying: false
        }
    },
    computed: {
        affordable() {
            return this.$root.user.coin_stack >= this.cost;
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        getNewCanvas() {
            this.fetchingChallenges = true;
            axios.get('/api/challenges/unfinishedCanvas', {})
                .then(resp => {
                    if (resp.data.totalUnrevealed == 0) {
                        eventBus.$emit('canvasPurchased', resp.data.canvas);
                    } else {
                        this.cost = resp.data.totalUnrevealed * 5;
                        this.dialog = true;
                        // this.buyNewCanvas();
                    }
                    this.fetchingChallenges = false;
                }).catch(err => console.log(err));
        },
        buyNewCanvas() {
            this.paying = true;
            axios.post('/api/challenges/buyNewCanvas', {})
                .then(resp => {
                    eventBus.$emit('coinStackUpdate', resp.data.coinStackUpdate);
                    eventBus.$emit('canvasPurchased', resp.data.canvas);
                    this.paying = false;
                    this.dialog = false;
                }).catch(err => console.log(err));
        },
    }
}
</script>
