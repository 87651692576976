<template>
  <v-layout class="mb-3" row wrap>
    <v-flex xs12 sm6 offset-sm3 md4 offset-md0 class='mb-3'>
      <v-layout>
        <div style='width:100px'>
          <v-img width='80px' :src="`/images/medal gold.png`"></v-img>
        </div>
        <div>
          <div style="display:flex;flex-wrap:wrap">
            <div style='min-width:100px;max-width:100px;' class='mx-2 mb-3' v-for="student in gold" :key="`gold-${student.user_id}`">
              <v-img min-width='80px' max-width='80px' :src='student.user_data.avatar' style='border-radius:50%;margin:0px 10px'/>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div class='two-lines subheading grey--text' :style='student.user_id == $root.user.id ? "font-weight:bold" : ""' v-on="on">
                    {{ student.user_data.name }}
                  </div>
                </template>
                <span>{{ student.user_data.name }}</span>
              </v-tooltip>
              <v-chip style='width:70px;margin:0px 15px;display:flex;justify-content:center' color='success' dark class='title' v-if='student.user_id == $root.user.id'>
                You
              </v-chip>
            </div>
          </div>
        </div>
      </v-layout>
    </v-flex>
    <v-flex sm3 hidden-xs-only hidden-md-and-up></v-flex>
    <v-flex xs12 sm6 md4 class='mb-3'>
      <v-layout>
        <div style='width:100px'>
          <v-img width='80px' :src="`/images/medal silver.png`"></v-img>
        </div>
        <div>
          <div style="display:flex;flex-wrap:wrap">
            <div style='min-width:100px;max-width:100px;' class='mx-2 mb-3' v-for="student in silver" :key="`silver-${student.user_id}`">
              <v-img min-width='80px' max-width='80px' :src='student.user_data.avatar' style='border-radius:50%;margin:0px 10px'/>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div class='two-lines subheading grey--text' v-on="on">
                    {{ student.user_data.name }}
                  </div>
                </template>
                <span>{{ student.user_data.name }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-layout>
    </v-flex>
    <v-flex xs12 sm6 md4>
      <v-layout>
        <div style='width:100px'>
          <v-img width='80px' :src="`/images/medal bronze.png`"></v-img>
        </div>
        <div>
          <div style="display:flex;flex-wrap:wrap">
            <div style='min-width:100px;max-width:100px;' class='mx-2 mb-3' v-for="student in bronze" :key="`bronze-${student.user_id}`">
              <v-img min-width='80px' max-width='80px' :src='student.user_data.avatar' style='border-radius:50%;margin:0px 10px'/>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div class='two-lines subheading grey--text' v-on="on">
                    {{ student.user_data.name }}
                  </div>
                </template>
                <span>{{ student.user_data.name }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    props : {medals: Array},
    data : function(){
      return {
        gold: [],
        silver: [],
        bronze: []
      }
    },
    watch:{
      medals: {
        immediate: true,
        deep: true,
        handler(val) {
          this.gold = [];
          this.silver = [];
          this.bronze = [];
          val.forEach(medal => {
            switch(medal.reward){
              case 'gold':
                this.gold.push(medal);
              break;
              case 'silver':
                this.silver.push(medal);
              break;
              case 'bronze':
                this.bronze.push(medal);
              break;
            }
          });
        }
      }
    }
  }
</script>

<style scoped>
  .two-lines {
    text-align: center;
    max-width: 100px;
    min-width: 100px;
    text-overflow:ellipsis;
     overflow:hidden;
     display: -webkit-box !important;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
     white-space: normal;
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
