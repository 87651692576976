<template>
    <div class="mb-3">
        <div style="width:fit-content;position:relative;">
            <challenge-canvas-display :imageBySection="canvas.image" :depth="canvas.depth" :revealed="canvas.revealed" v-if="canvas"/>
            <div style="position:absolute;top:0px;left:0px;width:324px;display:flex;flex-wrap:wrap;">
                <v-card 
                    flat hover ripple
                    color="transparent"
                    style="display:flex;justify-content:center;align-items:center;"
                    :width="dims.cardSize"
                    :height="dims.cardSize"
                    :style="`${canvas.revealed.sections.indexOf(`${sectionNb-1}`) == -1 ? '' : 'visibility:hidden'}`"
                    v-for="sectionNb in dims.totalNbSections"
                    :key="sectionNb"
                    @click="showDialog(sectionNb)"
                >
                    <v-icon :size="dims.iconSize">
                        {{ credit ? 'grid_view' : 'help_outline' }}
                    </v-icon>
                </v-card>
            </div>
            <Transition>
                <div 
                    class="completedOverlay"
                    v-if="canvas.completed && showCompletedOverlay"
                >
                    <div class="mt-2">
                        <v-img width="220px" style="margin:auto;" :src='`/images/canvas completed.png`'/>
                        <div class="text-xs-center display-1 primary--text" v-if="bonusSmiles > 0">
                            +{{ bonusSmiles }} <v-icon x-large color="primary">sentiment_satisfied_alt</v-icon>
                        </div>
                    </div>
                    <div style="display:flex;">
                        <v-spacer/>
                        <v-btn color="primary" style="width:150px" @click="showCompletedOverlay = false">
                        Yay !
                        </v-btn>
                    </div>
                </div>
            </Transition>
        </div>
        <challenge-dialog :section="activeSection" :sectionIndex="activeSectionNb" :revealed="canvas.revealed" :challenges="challenges"/>
        <reveal-pixels-dialog :section="activeSection" :sectionIndex="activeSectionNb" :revealed="canvas.revealed" :credit="credit"/>
    </div>
</template>
<script>
export default {
    props: { canvas: Object, challenges: Array, credit: Number },
    data: function () {
        return {
            activeSection: [],
            activeSectionNb: null,
            showCompletedOverlay: true,
            bonusSmiles: 0
        }
    },
    computed: {
        dims() {
            if (this.canvas && this.canvas.depth == 1) return { totalNbSections: 12, cardSize: 108, iconSize: 42 };
            if (this.canvas && this.canvas.depth == 2) return { totalNbSections: 48, cardSize: 54, iconSize: 28 };
            if (this.canvas && this.canvas.depth == 3) return { totalNbSections: 108, cardSize: 36, iconSize: 18 };

            return { xMax: 0, yMax: 0, cardSize: 0, iconSize: 0 };
        }
    },
    mounted() {
        eventBus.$on('canvasCompleted', this.updateBonusSmiles);
    },
    beforeDestroy() {
        eventBus.$off('canvasCompleted', this.updateBonusSmiles);
        clearInterval(this.refreshChallengesTimer);
    },
    methods: {
        showDialog(sectionNb) {
            this.activeSectionNb = sectionNb-1;
            this.activeSection = this.canvas.image[sectionNb-1];
            if(this.credit > 0){
                eventBus.$emit('showRevealPixelDialog', {});
            } else {
                eventBus.$emit('showChallengeDialog', {});
            }
        },
        updateBonusSmiles(nbSmiles){
            this.bonusSmiles = nbSmiles;
        }
    }
}
</script>

<style scoped>
    .completedOverlay {
        position:absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        background-color: rgba(255, 255, 255, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .v-enter-active,
    .v-leave-active {
        transition: all 0.5s ease-in;
    }

    .v-enter-from,
    .v-leave-to {
        height:0px;
        opacity: 0;
    }
</style>