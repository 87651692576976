<template>
    <div class="canvasWrapper elevation-2">
        <div
            :style="`width:${pixelSize * 9}px;height:${pixelSize * 9}px;display:flex;flex-wrap:wrap;`" 
            v-for="(section, index1) in imageBySection" 
            :key="`section-${index1}`"
        >
            <challenge-section-display :section="section" :sectionIndex="index1" :revealed="revealed" :pixelSize="pixelSize"/>
        </div>
    </div>
</template>
<script>
export default {
    props: { imageBySection: Array, revealed: Object, depth: Number },
    data: function () {
        return {
            //data here
        }
    },
    computed:{
        pixelSize(){
            return 12/this.depth;
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {

    }
}
</script>

<style scoped>
    .canvasWrapper {
        min-width: 324px;
        max-width: 324px;
        min-height: 432px;
        max-height: 432px;
        display: flex;
        flex-wrap: wrap;
        border-radius: 5px;
        overflow: hidden;
    }
    /* .section {
        min-width: 108px;
        max-width: 108px;
        min-height: 108px;
        max-height: 108px;
        display: flex;flex-wrap: wrap;
    }
    .subsection {
        min-width: 36px;
        max-width: 36px;
        min-height: 36px;
        max-height: 36px;
        display: flex;
        flex-wrap: wrap;
    } */
</style>
