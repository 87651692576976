<template>
    <div style="display:flex;flex-wrap:wrap;width:100%">
        <div 
            :style="`width:${pixelSize * 3}px;height:${pixelSize * 3}px;display: flex;flex-wrap: wrap;`"
            v-for="(subsection, subIndex) in section" 
            :key="`subsection-${subIndex}`"
        >
            <div
                :style="`background:#${revealPixel(sectionIndex, subIndex, pixelIndex) ? pixel : 'dddddd;border: 1px solid #bbbbbb'};width:${pixelSize}px;height:${pixelSize}px;`"
                v-for="(pixel, pixelIndex) in subsection" 
                :key="`pixel-${pixelIndex}`"
            />
        </div>
    </div>
</template>
<script>
export default {
    props: { section: Array, sectionIndex: Number, revealed: Object, pixelSize: Number },
    data: function () {
        return {
            //data here
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        revealPixel(sectionNb, subsectionNb, pixelNb) {
            if (this.revealed.sections.indexOf(`${sectionNb}`) >= 0) return true;
            if (this.revealed.subsections.indexOf(`${sectionNb}-${subsectionNb}`) >= 0) return true;
            if (this.revealed.pixels.indexOf(`${sectionNb}-${subsectionNb}-${pixelNb}`) >= 0) return true;

            return false;
        }
    }
}
</script>
