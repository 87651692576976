<template>
    <v-container>
        <v-layout row wrap class="text-xs-left">
            <v-flex xs12 class="mb-3" style="display:flex;justify-content:space-between;align-items:center;">
                <span style="display:flex;">
                    <span>
                        <div class="headline">
                            {{ school.full_name }}
                        </div>
                        <div class="grey--text">
                            {{ school.short_name }}
                        </div>
                    </span>
                </span>
                <span>
                    <v-menu>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon>more_vert</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-tile @click="editSchool(school)">
                                <v-list-tile-avatar>
                                    <v-icon color="primary">edit</v-icon>
                                </v-list-tile-avatar>
                                <v-list-tile-title>
                                    Edit school name
                                </v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile @click="inviteDialog = true">
                                <v-list-tile-avatar>
                                    <v-icon color="primary">person_add</v-icon>
                                </v-list-tile-avatar>
                                <v-list-tile-title>
                                    Invite teacher
                                </v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile @click="addClassDialog = true">
                                <v-list-tile-avatar>
                                    <v-icon color="primary">group</v-icon>
                                </v-list-tile-avatar>
                                <v-list-tile-title>
                                    Add class
                                </v-list-tile-title>
                            </v-list-tile>
                            <v-divider/>
                            <v-list-tile @click="archiveMultipleDialog = true">
                                <v-list-tile-avatar>
                                    <v-icon color="error">delete</v-icon>
                                </v-list-tile-avatar>
                                <v-list-tile-title>
                                    Archive multiple classes
                                </v-list-tile-title>
                            </v-list-tile>
                        </v-list>
                    </v-menu>
                    <v-dialog width="450px" v-model="addClassDialog">
                        <v-card>
                            <v-card-title class="headline">
                                Add a class
                            </v-card-title>
                            <v-card-text>
                                <v-text-field label="Class name" v-model="newClassName" :rules="nameRules" autofocus clearable validate-on-blur/>
                                <v-select hide-details outline label="Teacher" v-model="newClassTeacher" :items="teachersOptions" />
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" flat style="width:140px" :disabled='addClassLoading' @click="addClassDialog = false">
                                    Cancel
                                </v-btn>
                                <v-btn color="success" style="width:140px" :loading='addClassLoading' @click="confirmAddClass">
                                    Add Class
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog width="650px" v-model="inviteDialog">
                        <v-card>
                            <v-progress-linear indeterminate style="position:absolute;top:-14px;left:0px;" v-if="invitingTeacher"/>
                            <v-card-title class="headline">
                                Invite a teacher
                            </v-card-title>
                            <v-card-text>
                                <div class="subheading grey--text">
                                    Invites sent
                                </div>
                                <div class="mb-3">
                                    <v-chip close v-for="invite in school.invites" :key="invite.id" @input="cancelInvite(invite)">
                                        {{ invite.user.name }}
                                    </v-chip>
                                </div>
                                <div class="subheading grey--text">
                                        New invites
                                    </div>
                                <v-text-field label="Name or email" append-icon="search" :loading="searching" v-model="teacherName" autofocus clearable @keyup="userTyping"/>
                                <v-checkbox :label="`Restrict to your domain (${teacherDomain})`" v-model="restrictDomain"/>
                                <div style="display:flex;flex-wrap:wrap;justify-content:space-between;" class="mt-2">
                                    <span class="twoLineChip elevation-3 pa-2 mb-2" v-for="teacher in teachersSearched" :key="teacher.id">
                                        <span style="width:250px;max-width:250px;">
                                            <div>
                                                {{ teacher.name }}
                                            </div>
                                            <div class="grey--text">
                                                {{ teacher.email }}
                                            </div>
                                        </span>
                                        <v-btn icon :disabled="invitingTeacher" @click="inviteTeacher(teacher)">
                                            <v-icon>add</v-icon>
                                        </v-btn>
                                    </span>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn flat color='primary' @click="inviteDialog = false">Close</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog width="850" v-model="archiveMultipleDialog">
                        <v-card>
                            <v-card-title>
                                Archive multiple classes
                            </v-card-title>
                            <v-card-text>
                                <v-data-table 
                                    v-model="classesSelected"
                                    :headers="classesHeaders"
                                    :items="classesItems" 
                                    item-key="id" 
                                    select-all
                                    :rows-per-page-items='[10,25,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
                                >
                                    <template v-slot:items="props">
                                    <td>
                                        <v-checkbox v-model="props.selected" primary hide-details/>
                                    </td>
                                    <td>{{ props.item.name }}</td>
                                    <td>{{ props.item.teacher }}</td>
                                    <td class="text-xs-center">{{ props.item.nbStudents }}</td>
                                    <td class="text-xs-center">{{ props.item.created | formatDate }}</td>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" flat style="width:160px" :disabled='addClassLoading' @click="archiveMultipleDialog = false">
                                    Cancel
                                </v-btn>
                                <v-btn color="error" style="width:160px" :loading='addClassLoading' @click="confirmArchiveMultipleClasses">
                                    Archive Classes
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </span>
            </v-flex>
            <v-flex xs12>
                <v-tabs icons-and-text>
                    <v-tab href="#classes">
                        Teachers
                        <v-icon>person</v-icon>
                    </v-tab>
                    <v-tab href="#marathons">
                        Marathons
                        <v-icon>emoji_events</v-icon>
                    </v-tab>
                    <v-tab-item value="classes">
                        <v-card flat class="pa-2">
                            <v-expansion-panel>
                                <v-expansion-panel-content v-for="teacher in teachers" :key="teacher.id">
                                    <template v-slot:header>
                                        <v-layout row wrap>
                                            <v-flex xs5 class="headline d-flex align-center">
                                                <v-img style='border-radius:50%;margin:2px;' max-width='30px' :src='teacher.images.avatar'/>
                                                <span class="ml-1">
                                                    {{ teacher.name }}
                                                </span>
                                            </v-flex>
                                            <v-flex xs2 class="grey--text" style="display:flex;justify-content:center;white-space:nowrap;">
                                                {{ teacher.divisions.length }} class<span v-if="teacher.divisions.length > 1">es</span>
                                            </v-flex>
                                            <v-flex xs5 class="text-xs-right" v-if="teacher.isAdmin">
                                                <v-icon color="success">verified_user</v-icon>
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                    <v-card>
                                        <v-card-text style="display:flex;flex-wrap:wrap;">
                                            <v-card width="250px" class="ma-2" v-for="division in teacher.divisions" :key="division.id">
                                                <v-card-title class="headline" style="display:flex;justify-content:space-between;align-items:center;">
                                                    <span>
                                                        {{ division.name }}
                                                    </span>
                                                    <span class="grey--text title">
                                                        {{ division.code }}
                                                    </span>
                                                </v-card-title>
                                                <v-card-text style="display:flex;justify-content:space-between;align-items:center;">
                                                    <span class="title">
                                                         <v-icon class="mr-2">group</v-icon>{{ division.students.length }}
                                                    </span>
                                                    <span class="title" v-if="division.invites.length > 0">
                                                        <v-icon class="mr-2">person_add</v-icon>{{ division.invites.length }}
                                                    </span>
                                                    <div>
                                                        <v-menu top left>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn icon v-on="on">
                                                                    <v-icon>more_vert</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <v-list-tile @click="showArchiveClassDialog(division)">
                                                                    <v-list-tile-avatar>
                                                                        <v-icon color="error">delete</v-icon>
                                                                    </v-list-tile-avatar>
                                                                    <v-list-tile-title>
                                                                        Archive class
                                                                    </v-list-tile-title>
                                                                </v-list-tile>
                                                                <v-list-tile @click="showRemoveClassDialog(division)">
                                                                    <v-list-tile-avatar>
                                                                        <v-icon color="error">remove_circle_outline</v-icon>
                                                                    </v-list-tile-avatar>
                                                                    <v-list-tile-title>
                                                                        Remove from school
                                                                    </v-list-tile-title>
                                                                </v-list-tile>
                                                                <v-divider/>
                                                                <v-list-tile @click="seeDivisionDetails(division)">
                                                                    <v-list-tile-avatar>
                                                                        <v-icon color="success">visibility</v-icon>
                                                                    </v-list-tile-avatar>
                                                                    <v-list-tile-title>
                                                                        See details
                                                                    </v-list-tile-title>
                                                                </v-list-tile>
                                                            </v-list>
                                                        </v-menu>
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-card-text>
                                        <v-card-actions style="display:flex;justify-content:space-around;flex-wrap:wrap;">
                                            <v-btn outline color="success" :loading="adminLoading" class="mx-0 my-1" style="width:190px" @click="makeAdmin(teacher, false)" v-if="teacher.isAdmin">
                                                Remove admin <v-icon right>verified_user</v-icon>
                                            </v-btn>
                                            <v-btn color="success" :loading="adminLoading" class="mx-0 my-1" style="width:190px" @click="makeAdmin(teacher, true)" v-else>
                                                Make admin <v-icon right>verified_user</v-icon>
                                            </v-btn>
                                            <v-btn color="primary" :disabled="adminLoading" class="mx-0 my-1" style="width:190px" @click="seeTeacherClasses(teacher)">
                                                Other classes <v-icon right>group</v-icon>
                                            </v-btn>
                                            <v-btn color="error" :disabled="adminLoading" class="mx-0 my-1" style="width:190px" @click="prepareRemoveTeacher(teacher)">
                                                Remove teacher<v-icon right>remove_circle_outline</v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-card>
                        <v-dialog width="350" v-model="removeTeacherDialog">
                            <v-card>
                                <v-card-title class="headline">
                                    Remove {{ focusTeacher.name }} from this school ?
                                </v-card-title>
                                <v-card-text>
                                    <p>
                                        Are you sure you want to remove this teacher from your school ?
                                    </p>
                                    <p>
                                        This will also remove all of her/his classes from the school.
                                    </p>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" flat style="width:140px" :disabled='removeTeacherLoading' @click="closeRemoveDialog">
                                        Cancel
                                    </v-btn>
                                    <v-btn color="error" style="width:140px" :loading='removeTeacherLoading' @click="confirmRemoveTeacher">
                                        Remove teacher
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog width="350" v-model="removeClassDialog">
                            <v-card>
                                <v-card-title class="headline">
                                    Remove {{ focusClass.name }} from this school ?
                                </v-card-title>
                                <v-card-text>
                                    Are you sure you want to remove this class from your school ?
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" flat style="width:140px" :disabled='classLoading' @click="removeClassDialog = false">
                                        Cancel
                                    </v-btn>
                                    <v-btn color="error" style="width:140px" :loading='classLoading' @click="confirmRemoveClass">
                                        Remove class
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog width="350" v-model="archiveClassDialog">
                                <v-card>
                                    <v-card-title class="headline">
                                        Archive {{ focusClass.name }} ?
                                    </v-card-title>
                                    <v-card-text>
                                        Are you sure you want to archive this class ?
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" flat style="width:140px" :disabled='classLoading' @click="archiveClassDialog = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn color="error" style="width:140px" :loading='classLoading' @click="confirmArchiveClass">
                                            Archive class
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        <v-dialog width="565" v-model="otherClassesDialog">
                            <v-card>
                                <v-progress-linear indeterminate style="position:absolute;top:-15px;left:0px;" v-if="fetchingOtherClasses"/>
                                <v-card-title class="headline">
                                    {{ focusTeacher.name }}'s classes
                                </v-card-title>
                                <v-card-text style="display:flex;flex-wrap:wrap">
                                    <div class="subheading grey--text text-xs-center" style="width:100%;" v-if="otherClasses.length == 0">
                                        -- Nothing to show here --
                                    </div>
                                    <v-card width="250px" class="ma-2" v-for="division in otherClasses" :key="division.id">
                                        <v-card-title class="headline">
                                            {{ division.name }}
                                        </v-card-title>
                                        <v-card-text style="display:flex;justify-content:space-between;align-items:center;">
                                            <span class="title">
                                                <v-icon class="mr-2">group</v-icon>{{ division.students.length }}
                                            </span>
                                            <span class="title" v-if="division.invites.length > 0">
                                                <v-icon class="mr-2">person_add</v-icon>{{ division.invites.length }}
                                            </span>
                                            <v-btn icon :disabled="fetchingOtherClasses" @click="addDivision(division)">
                                                <v-icon color="primary">add</v-icon>
                                            </v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" flat style="width:140px" @click="otherClassesDialog=false">
                                        Close
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-tab-item>
                    <v-tab-item value="marathons">
                        <v-card flat class="pa-2">
                            <v-expansion-panel>
                                    <v-expansion-panel-content v-for="marathon in $root.classesData.marathons" :key="marathon.id">
                                        <template v-slot:header>
                                            <v-layout row wrap>
                                                <v-flex xs5 class="headline">
                                                    {{ marathon.title }}
                                                </v-flex>
                                                <v-flex xs2 :class="marathon.status == 'active' ? 'font-weight-bold success--text' : 'grey--text'" style="display:flex;justify-content:center;white-space:nowrap;">
                                                    {{ marathon.status }}
                                                </v-flex>
                                                <v-flex xs5>
                                                    
                                                </v-flex>
                                            </v-layout>
                                        </template>
                                        <v-card class="pa-2">
                                            <div class="grey--text headline mb-2">
                                                Classes involved
                                                <v-btn icon @click="prepareAddClassToMarathon(marathon)">
                                                    <v-icon color="primary">add_circle_outline</v-icon>
                                                </v-btn>
                                            </div>
                                            <div style="display:flex;flex-wrap:wrap;">
                                                <v-card width="250" class="pa-2 ma-1 text-xs-left" v-for="division in marathon.classes" :key="division.id">
                                                    <div class="title">
                                                        {{ division.displayName }}
                                                    </div>
                                                    <div class="grey--text caption">
                                                            {{ division.teacher.name }}
                                                        </div>
                                                    <div style="display:flex;justify-content:space-between;align-items:baseline;">
                                                        <span class="title">
                                                            <v-icon class="ml-2 mr-1">group</v-icon>{{ division.nbStudents }}
                                                        </span>
                                                        <v-btn icon class="mb-0" @click="prepareRemoveClassFromMarathon(division, marathon)">
                                                            <v-icon :color="division.schoolId == school.id ? 'error' : 'grey'">remove_circle_outline</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-card>
                                            </div>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-flex>
        </v-layout>
        <v-dialog width="450" v-model="removeClassFromMarathonDialog">
            <v-card>
                <v-card-title class="headline">
                    Remove {{ focusClass.name }} from {{ focusMarathon.title }} ?
                </v-card-title>
                <v-card-text>
                    <p>
                        Are you sure you want to remove this class form the marathon ?
                    </p>
                    <p>
                        The students will not be able to compete anymore or see their past performances.
                    </p>
                    <v-checkbox label="Yes I'm sure" v-model="removeClassFromMarathonSecurity"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat style="width:140px" :disabled='classLoading' @click="removeClassFromMarathonDialog=false; removeClassFromMarathonSecurity=false;">
                        Cancel
                    </v-btn>
                    <v-btn color="error" style="width:140px" :disabled="!removeClassFromMarathonSecurity" :loading='classLoading' @click="confirmRemoveClassFromMarathon">
                        Remove Class
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog width="600" v-model="addClassToMarathonDialog">
            <v-card>
                <v-progress-linear indeterminate style="position:absolute;top:-15px;left:0px;" v-if="classLoading"/>
                <v-card-title class="headline">
                    Add classes to {{ focusMarathon.title }}
                </v-card-title>
                <v-card-text style="display:flex;flex-wrap:wrap">
                    <div class="subheading grey--text text-xs-center" style="width:100%;" v-if="availableClasses.length == 0">
                        -- Nothing to show here --
                    </div>
                    <v-card class="ma-2" width="250" v-for="division in availableClasses" :key="division.id">
                        <v-card-title style="display:block" class="pb-0">
                            <div class="headline">
                                {{ division.name }}
                            </div>
                            <div class="grey--text">
                                {{ division.teacherName }}
                            </div>
                        </v-card-title>
                        <v-card-text class="pt-0" style="display:flex;justify-content:space-between;align-items:center;">
                            <span class="title">
                                <v-icon class="mr-2">group</v-icon>{{ division.students.length }}
                            </span>
                            <span class="title" v-if="division.invites.length > 0">
                                <v-icon class="mr-2">person_add</v-icon>{{ division.invites.length }}
                            </span>
                            <v-btn icon :disabled="classLoading" @click="addClassMarathon(division)">
                                <v-icon color="primary">add</v-icon>
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" flat style="width:140px" @click="addClassToMarathonDialog = false">
                            Close
                        </v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>
        <school-class-dialog :school="school"/>
        <edit-school-dialog />
    </v-container>
</template>
<script>
import moment from 'moment-timezone';

export default {
    props: {},
    data: function () {
        return {
            school: {teachers: []},
            teacherName: '',
            teacherDomain: '',
            restrictDomain: true,
            teachersSearched: [],
            invitingTeacher: false,
            inviteDialog: false,
            otherClassesDialog: false,
            otherClasses: [],
            fetchingOtherClasses: false,
            removeTeacherDialog: false,
            removeTeacherLoading: false,
            removeClassDialog: false,
            archiveMultipleDialog: false,
            classLoading: false,
            removeClassFromMarathonDialog: false,
            removeClassFromMarathonSecurity: false,
            archiveClassDialog: false,
            addClassToMarathonDialog: false,
            availableClasses: [],
            focusTeacher: {},
            focusClass: {},
            focusMarathon: {},
            searching: false,
            typingTimer: null,
            addClassDialog: false,
            addClassLoading: false,
            newClassName: '',
            newClassTeacher: '',
            adminLoading: false,
            nameRules: [
                v => !!v || 'You must choose a name',
                v => (v && v.length >= 3) || 'The class name must be at least 3 characters long'
            ],
            classesHeaders:[
                { text: 'Name', align: 'center', value: 'name' },
                { text: 'teacher', align: 'center', value: 'teacher' },
                { text: 'Nb students', align: 'center', value: 'nbStudents' },
                { text: 'Created', align: 'center', value: 'created' }
            ],
            classesSelected: []
        }
    },
    computed: {
        teachers() {
            return this.school.teachers;
        },
        teachersOptions(){
            return this.school.teachers.map(t => ({text: t.name, value: t.id}));
        },
        classesItems(){
            const items = [];
            this.school.teachers.forEach(teacher => {
                teacher.divisions.forEach(division => {
                    items.push({
                        id: division.id,
                        name: division.name,
                        teacher: teacher.name,
                        nbStudents: division.students.length,
                        created: division.created_at
                    });
                });
            });

            return items;
        }
    },
    filters: {
        formatDate(val) {
            return moment.tz(val, 'UTC').clone().tz(moment.tz.guess()).fromNow();
        }
    },
    created() {
        eventBus.$emit('loading', { command: 'clear' });
        eventBus.$emit('loading', { command: 'start', name: 'single-schools-page' });
        this.teacherDomain = '@'+this.$root.user.email.split('@')[1];
        axios.get(`/api/schools/${this.$route.params.id}`, {})
        .then(resp => {
            this.school = resp.data;
            eventBus.$emit('loading', { command: 'finish', name: 'single-schools-page' });
        }).catch(err => console.log(err));
    },
    mounted() {
        eventBus.$on('updateSchool', this.updateSchool);
    },
    beforeDestroy() {
        eventBus.$off('updateSchool', this.updateSchool);
    },
    methods: {
        prepareAddClassToMarathon(marathon){
            this.availableClasses = [];
            this.school.teachers.forEach(teacher => {
                teacher.divisions.forEach(cl => {
                    if(!marathon.classes.some(cls => cls.id == cl.id)){
                        cl.teacherName = teacher.name;
                        this.availableClasses.push(cl);
                    }
                });
            });
            this.focusMarathon = marathon;
            this.addClassToMarathonDialog = true;
        },
        addClassMarathon(division){
            this.classLoading = true;
            axios.post(`/api/schools/${this.school.id}/marathons/${this.focusMarathon.id}/classes/${division.id}`, {})
            .then(resp => {
                let temp = this.$root.classesData.marathons;
                this.availableClasses = this.availableClasses.filter(cl => cl.id != division.id);
                this.$root.classesData.marathons = [];
                temp.forEach(marathon => {
                    if(marathon.id == resp.data.id){
                        this.$root.classesData.marathons.push(resp.data);
                    } else {
                        this.$root.classesData.marathons.push(marathon);
                    }
                });
                this.classLoading = false;
            }).catch(err => console.log(err.response));
        },
        prepareRemoveClassFromMarathon(division, marathon){
            if(division.schoolId == this.school.id){
                this.focusClass = division;
                this.focusMarathon = marathon;
                this.removeClassFromMarathonDialog = true;
            } else {
                this.$root.snackWarning("Cannot remove non-school class here");
            }
        },
        confirmRemoveClassFromMarathon(){
            this.classLoading = true;
            axios.delete(`/api/schools/${this.school.id}/marathons/${this.focusMarathon.id}/classes/${this.focusClass.id}`, {})
            .then(resp => {
                let temp = this.$root.classesData.marathons;
                this.$root.classesData.marathons = [];
                temp.forEach(marathon => {
                    if (marathon.id == resp.data.id) {
                        this.$root.classesData.marathons.push(resp.data);
                    } else {
                        this.$root.classesData.marathons.push(marathon);
                    }
                });
                this.removeClassFromMarathonSecurity = false;
                this.removeClassFromMarathonDialog = false;
                this.classLoading = false;
            }).catch(err => console.log(err.response));
        },
        makeAdmin(teacher, adminStatus){
            this.adminLoading = true;
            axios.post(`/api/schools/${this.school.id}/teachers/${teacher.id}/admin`, {adminStatus})
            .then(resp => {
                this.school = resp.data;
                this.adminLoading = false;
            }).catch(err => console.log(err.response));
        },
        showRemoveClassDialog(division){
            this.focusClass = division;
            this.removeClassDialog = true;
        },
        confirmRemoveClass(){
            this.classLoading = true;
            axios.delete(`/api/schools/${this.school.id}/classes/${this.focusClass.id}`)
            .then(resp => {
                this.school = resp.data;
                this.$root.snackSuccess("Class removed");
                this.classLoading = false;
                this.removeClassDialog = false;
            }).catch(err => console.log(err.response));
        },
        showArchiveClassDialog(division){
            this.focusClass = division;
            this.archiveClassDialog = true;
        },
        confirmArchiveClass(){
            this.classLoading = true;
            axios.put(`/api/schools/${this.school.id}/classes/${this.focusClass.id}`)
            .then(resp => {
                this.school = resp.data;
                this.$root.snackSuccess("Class archived");
                this.classLoading = false;
                this.archiveClassDialog = false;
            }).catch(err => console.log(err.response));
        },
        seeDivisionDetails(division){
            eventBus.$emit('seeSchoolClass', division);
        },
        editSchool(school) {
            eventBus.$emit('showEditSchoolDialog', school);
        },
        updateSchool(school){
            this.school = school;
        },
        userTyping() {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(this.searchTeachers, 500);
        },
        searchTeachers(){
            if(this.teacherName.length >= 3){
                this.searching = true;
                axios.get(`/api/schools/${this.school.id}/searchTeachers/${this.teacherName}/${this.restrictDomain ? 1 : 0}`)
                .then(resp => {
                    this.teachersSearched = resp.data;
                    this.searching = false;
                }).catch(err =>{
                    this.searching = false;
                    this.$root.snackError(err.response.data.message);
                    console.log(err.response);
                });
            }
        },
        inviteTeacher(teacher){
            this.invitingTeacher = true;
            axios.post(`/api/schools/${this.school.id}/invite/${teacher.id}`)
            .then(resp => {
                this.$root.snackSuccess('Invite sent !');
                this.teachersSearched = this.teachersSearched.filter(t => t.id != teacher.id);
                this.school.invites = resp.data;
                this.invitingTeacher = false;
            }).catch(err => {
                this.searching = false;
                if (err.response.data.message){
                    this.$root.snackError(err.response.data.message);
                }
                console.log(err.response);
                this.invitingTeacher = false;
            });
        },
        cancelInvite(invite){
            axios.delete(`/api/schools/${this.school.id}/invite/${invite.user.id}`)
            .then(resp => {
                this.$root.snackSuccess('Invite removed');
                this.school.invites = resp.data;
            }).catch(err => {
                this.searching = false;
                if (err.response.data.message) {
                    this.$root.snackError(err.response.data.message);
                }
                console.log(err.response);
                this.invitingTeacher = false;
            });
        },
        prepareRemoveTeacher(teacher){
            this.focusTeacher = teacher;
            this.removeTeacherDialog = true;
        },
        confirmRemoveTeacher(){
            this.removeTeacherLoading = true;
            axios.delete(`/api/schools/${this.school.id}/teachers/${this.focusTeacher.id}`)
            .then(resp => {
                this.school = resp.data;
                this.removeTeacherLoading = false;
                this.removeTeacherDialog = false;
            }).catch(err => console.log(err.response));
        },
        closeRemoveDialog(){
            this.removeTeacherDialog = false;
            this.focusTeacher = {name: ''};
        },
        seeTeacherClasses(teacher){
            this.otherClassesDialog = true;
            this.fetchingOtherClasses = true;
            this.focusTeacher = teacher;
            axios.get(`/api/schools/${this.school.id}/teachers/${this.focusTeacher.id}/otherClasses`)
            .then(resp => {
                this.otherClasses = resp.data;
                this.fetchingOtherClasses = false;
            }).catch(err => console.log(err.response));
        },
        addDivision(division){
            this.fetchingOtherClasses = true;
            axios.post(`/api/schools/${this.school.id}/classes/${division.id}`)
            .then(resp => {
                this.otherClasses = this.otherClasses.filter(cl => cl.id !=division.id);
                this.school = resp.data;
                this.fetchingOtherClasses = false;
            }).catch(err => console.log(err.response));
        },
        removeDivision(division) {
            axios.delete(`/api/schools/${this.school.id}/classes/${division.id}`)
            .then(resp => {
                console.log(resp.data);
                this.school = resp.data;
            }).catch(err => console.log(err.response));
        },
        closeOtherClassesDialog() {
            this.otherClassesDialog = false;
            this.otherClasses = [];
            this.focusTeacher = null;
        },
        confirmAddClass(){
            this.addClassLoading = true;
            axios.post(`/api/schools/${this.school.id}/classes`, {name: this.newClassName, teacher: this.newClassTeacher})
            .then(resp => {
                this.school = resp.data;
                this.$root.snackSuccess("Class created");
                this.addClassLoading = false;
                this.addClassDialog = false;
            }).catch(err => console.log(err.response));
        },
        confirmArchiveMultipleClasses(){
            this.classLoading = true;
            axios.put(`/api/schools/${this.school.id}/multiple`, {classes: this.classesSelected })
            .then(resp => {
                this.school = resp.data;
                this.$root.snackSuccess("Classes archived");
                this.classLoading = false;
                this.archiveMultipleDialog = false;
            }).catch(err => console.log(err.response));
        }
    }
}
</script>

<style scoped>
    .twoLineChip {
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 300px;
        max-width: 300px;
    }
</style>