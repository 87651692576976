<template>
    <v-card max-width="600px" class="text-xs-left" :style="canvas.open ? 'border:2px solid #4CAF50' : ''">
        <v-card-title 
            style="display:block;" class="primary lighten-2 white--text"
        >
            <div class="display-1 font-weight-light" :style="canvas.completed ? '' : `user-select:none;color:transparent;text-shadow:0 0 ${10 * (1 - progress / 100) ** 0.5}px rgba(255, 255, 255,0.5)`">
                {{ canvas.options.title }}
            </div>
            <div class="caption" :style="canvas.completed ? '' : `user-select:none;color:transparent;text-shadow:0 0 ${10 * (1 - progress / 100) ** 0.5}px rgba(255, 255, 255,0.5)`">
                {{ canvas.options.from }} - {{ canvas.options.to }}
            </div>
        </v-card-title>
        <v-card-text style="display:flex;">
            <div 
                style="flex:1;" class="pr-1" 
                :style="canvas.completed ? '' : `user-select:none;color:transparent;text-shadow:0 0 ${10 * (1 - progress / 100) ** 0.5}px rgba(0,0,0,0.5)`"
                v-html="canvas.options.description"
            />
            <div style="width:100px;">
                <v-img style="image-rendering: pixelated;border-radius:5px;" :src="canvas.orgImageUrl" v-if="canvas.completed"/>
                <v-progress-circular
                    :rotate="-90"
                    :size="100"
                    :width="20"
                    :value="progress"
                    color="primary"
                    v-else
                >
                    {{ progress }}%
                </v-progress-circular>
            </div>
        </v-card-text>
        <v-card-actions>
            <span/>
            <v-btn block small :ripple="false" color="success" v-if="canvas.completed">
                COMPLETED <v-icon small right>check_circle</v-icon>
            </v-btn>
            <v-btn 
                block
                small
                class="mr-2"
                color="primary"
                :disabled="selecting != 0 || !!canvas.open"
                :loading="selecting == canvas.id"
                @click="selectCanvas"
                v-else
            >
                <span v-if="canvas.open">
                    Selected
                </span>
                <span v-else>
                    Select <v-icon small right>done</v-icon>
                </span>
            </v-btn>
            <v-btn block small class="ml-2" :href="canvas.options.url" target="_blank" v-if="canvas.completed">
                Read more <v-icon right small>open_in_new</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props: { canvas: Object, selecting: Number },
    data: function () {
        return {
            //data here
        }
    },
    computed: {
        progress() {
            return Math.round(100 * this.canvas.revealedPixels / this.canvas.totalPixels)
        }
    },
    mounted() {
        
    },
    beforeDestroy() {

    },
    methods: {
        selectCanvas() {
            eventBus.$emit('selectCanvas', this.canvas);
        }
    }
}
</script>