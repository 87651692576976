<template>
  <v-expansion-panel-content :readonly='!track.open' :style='track.open ? "" : "background:#eeeeee"'>
    <template v-slot:header>
      <div style='position:absolute;top:0;height:100%;width:1px' :style="{background:strip, left:`${index}px`}" v-for="(strip, index) in strips" :key="`strip-${index}`"></div>
      <v-layout row wrap>
        <v-flex xs4 :class='titleFormat'>
          {{ track.title }}
        </v-flex>
        <v-flex xs4 :class='titleFormat' style='display:flex;align-items:center;justify-content:center'>
          {{ track.sectionStars }}/{{ maxStars }}
          <v-icon size='20' color="green" class='mx-1'>stars</v-icon>
        </v-flex>
        <v-flex xs4 class='d-flex justify-center'>
          <v-spacer/>
          <v-tooltip top content-class='subheading'>
            <template v-slot:activator="{ on }">
              <span style='display:flex;align-items:center' v-on="on">
                <v-icon :color='`${track.maxExtraTime > 0 && !loading ? "primary" : "grey lighten-2"}`'>timer</v-icon>
                <span v-if="track.maxExtraTime > 0" :class='titleFormat'>
                  +{{ track.maxExtraTime }}s
                </span>
              </span>
            </template>
            <span>
              <span v-if="track.maxExtraTime > 0">
                +{{ track.maxExtraTime }}s extra time allowed !
              </span>
              <span v-else>
                No extra time allowed
              </span>
            </span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:actions>
      <v-icon :color="loading ? 'grey' : 'success'" v-if='track.open'>arrow_drop_down_circle</v-icon>
      <v-icon :color="loading ? 'grey' : 'error'" v-else>cancel</v-icon>
    </template>
    <v-card>
      <div style='position:absolute;top:0;height:100%;width:1px' :style="{background:strip, left:`${index}px`}" v-for="(strip, index) in strips" :key="`strip-${index}`"></div>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12 class="mb-2">
            {{ track.text_description }}
          </v-flex>
          <v-flex xs12 class="grey--text subheading">
            Tricks <span class='font-weight-bold' v-if='track.timeModifier > 0'>(+{{ track.timeModifier }}s extra time allowed)</span>
          </v-flex>
          <v-flex v-if="track.tricks.length == 0" class="grey--text text--lighten-2 headline text-xs-center" style="width:100%">No tricks to play here</v-flex>
          <v-flex xs12 style='display:flex;flex-wrap:wrap'>
            <trick-card v-for="trick in track.tricks" :key="`trick-${trick.id}-${trick.sectionStars}`" :trick="trick" :sectionPerfs='true'></trick-card>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-expansion-panel-content>
</template>
<script>
  export default {
    props : { track: Object, openPanel: Boolean, loading: Boolean },
    data : function(){
      return {
        strips: [],
        panelOpen: false,
        maxStars: 0
      }
    },
    computed:{
      titleFormat(){
        return this.loading ? "grey--text text--lighten-2" : this.openPanel ? "font-weight-bold subheading" : "";
      }
    },
    mounted() {
      this.makeStrips();
      this.maxStars = this.track.tricks.length*15;
    },
    methods: {
      makeStrips(){
        this.strips = [];
        this.track.tricks.forEach(trick => this.strips.push(this.$root.trickColors[trick.type]));
      }
    }
  }
</script>
