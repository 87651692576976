<template>
    <div class="tile white--text" :class="{ 'elevation-8': isActive, 'tile-flipped': isFlipped }">
        <div class="tile-inner">
            <div class="tile-front" style="background: #808A9F;">
                {{ value }}
            </div>
            <div class="tile-back" :style="`background:${bgColor}`">
                {{ value }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: { status: String, value: String, delay: Number },
    data: function () {
        return {
            isFlipped: false
        }
    },
    computed: {
        bgColor() {
            let color = '#808A9F';
            switch (this.status) {
                case 'placed':
                    color = '#00CC66';
                    break;
                case 'misplaced':
                    color = '#F0A202';
                    break;
                case 'absent':
                    color = '#232E21';
                    break;
            }
            return color;
        },
        isActive() {
            return this.status == 'active';
        }
    },
    mounted(){
        if (this.status != 'neutral' && this.status != 'active') {
            this.isFlipped = true;
        }
    },
    watch: {
        status(val){
            if(val != 'neutral' && val != 'active'){
                setTimeout(() => this.isFlipped = true, this.delay);
            }
        }
    }
}
</script>
<style scoped>
.tile {
    width: 30px;
    height: 35px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    background-color: transparent;
    perspective: 1000px;
}

.tile-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    animation-delay: 500ms;
    transform-style: preserve-3d;
}

.tile-front,
.tile-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.tile-back {
    transform: rotateY(180deg);
}

.tile-flipped .tile-inner {
    transform: rotateY(180deg);
}
</style>
