<template>
  <v-dialog v-model="addStudentsDialog"  max-width="500px" width="500px" scrollable  @keydown.esc="addStudentsDialog=false">
    <v-btn slot="activator" fab dark color="primary" fixed bottom right><v-icon style='display:inline-flex' dark>add</v-icon></v-btn>
    <v-card>
      <div style="width:100%;text-align:right;margin-bottom:-20px">
        <v-btn class="ma-0 pa-0" icon @click="addStudentsDialog=false">
          <v-icon>close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="headline justify-center">
        Search for students
      </v-card-title>
      <v-card-text>
        <v-layout wrap>
          <v-flex xs12>
            <v-text-field label="Name" append-icon="search" v-model="searchedName" autofocus clearable @keyup="userTyping"></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-checkbox v-model="restrict" :label="`Only students with same email extention (${emailExtention})`" @change="searchUsers"></v-checkbox>
          </v-flex>
          <v-flex xs12>
            <span class="grey--text subheading">search results</span>
          </v-flex>
          <template v-if="studentsFound">
            <v-flex xs12 v-for="student in selectedStudents" :key="student.id">
              <v-card class='pl-2 justify-center mb-3'>
                <v-layout>
                  <v-flex class="subheading" grow align-self-center>
                    {{ student.name }} <span class="grey--text">({{ student.email }})</span>
                  </v-flex>
                  <v-flex class="text-xs-right" align-self-center>
                    <v-btn fab small outline color="primary" @click="addToClass(student.id)">
                      <v-icon style='display:inline-flex' dark>add</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </template>
          <template v-else>
            <v-flex xs12 class="text-xs-center grey--text text--lighten-2 py-5">No matching student</v-flex>
          </template>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props: {'classId': Number, 'classname': String, 'teacheremail': String, 'classcode': String},
    data : function(){
      return {
        addStudentsDialog: false,
        searchedName: '',
        searchValid: true,
        typingTimer: null,
        selectedStudents: [],
        studentsFound: false,
        restrict: false
      }
    },
    computed: {
      emailExtention: function(){
        return '@'+this.teacheremail.split('@')[1]
      }
    },
    methods : {
      userTyping(){
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this.searchUsers, 500);
      },
      searchUsers(){
        let vm = this;
        if (vm.searchedName.length > 1){
          axios.get(`/api/classes/${vm.classId}/students/${vm.searchedName}/${vm.restrict}`, {})
          .then(function (response) {
            vm.selectedStudents = response.data;
            vm.studentsFound = !_.isEmpty(vm.selectedStudents); // TODO allow for pagination if more than 5 students can be returned
          })
          .catch(function (error) {
            // TODO catch errors
          });
        }
      },
      addToClass(studentId){
        let vm = this;
        axios.post(`/api/classes/${vm.classId}/students/${studentId}`, {})
        .then(function (response) {
          let selectedStudents = vm.selectedStudents;
          _.remove(selectedStudents, function(el){
            return el.id == studentId;
          });
          if (selectedStudents.length == 0){
            vm.studentsFound = false;
          } else {
            vm.selectedStudents = [];
            _.forEach(selectedStudents, function(el){
              vm.selectedStudents.push(el);
            })
          }
          eventBus.$emit('studentAdded', studentId);
        })
        .catch(function (error) {
          // TODO catch errors
        });
      }
    }
  }
</script>
