<template>
  <v-data-table :headers="phaseHeaders" :items="phaseItems" hide-actions :search="search" v-if="phaseHeaders.length && show">
    <template slot="headerCell" slot-scope="props">
      <v-tooltip top v-if='props.header.fullText'>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            {{ props.header.text }}
          </span>
        </template>
        <span>
          {{ props.header.fullText }}
        </span>
      </v-tooltip>
      <span v-else>
        {{ props.header.text }}
      </span>
    </template>
    <template v-slot:items="props">
      <td style="width:200px;" class="pl-2 pr-0 py-0" v-if='props.item.properName'>{{ props.item.properName.name }}</td>
      <td style="width:200px;" class="pl-2 pr-0 py-0 grey--text" v-else>{{ props.item.name }}</td>
      <td
        :style="race.cellColor"
        class="text-xs-center subheading pa-0"
        :class="race.score == 0 ? 'grey--text text--lighten-1' : ''"
        v-for="race in props.item.races"
        :key="race.id"
      >
        {{ race.score }}
      </td>
      <td class="subheading text-xs-center pa-0" :class="props.item.hike == 0 ? 'grey--text text--lighten-1' : ''">{{ props.item.hike }}</td>
      <td class="font-weight-bold title text-xs-center pa-0">{{ props.item.totalScore }}</td>
    </template>
  </v-data-table>
</template>
<script>
  export default {
    props : { data: Object, search: String, show: Boolean },
    data : function(){
      return {
        phaseHeaders: [],
        phaseItems: [],
        cellColors: {gold: 'rgba(255, 215, 0, 0.4)', silver: 'rgba(192, 192, 192, 0.5)', bronze: 'rgba(80, 50, 20, 0.3)', finished: 'rgba(76, 175, 80, 0.15)'}
      }
    },
    mounted() {

    },
    watch:{
      data(val){
        if(this.show){
          let phaseDetails = val.perfs;
          this.phaseHeaders = [{text: 'Name', align: 'center', value: 'lastName'}];
          phaseDetails[0].details.races.forEach( (race, index) => {
            let colTitle = race.title.length < 15 ? race.title : race.title.substr(0, 6)+'...'+race.title.substr(-5, 5);
            this.phaseHeaders.push({text: colTitle, fullText: race.title, align: 'center', value: `details.race[${index}].phaseScore.score`, class: 'pa-0 text-truncate'});
            this.nbOfTricks++;
          });
          this.phaseHeaders.push({text: 'Hike', align: 'center', value: 'details.hikeScore', class: 'px-1'});
          this.phaseHeaders.push({text: 'Total', align: 'center', value: 'totalScore', class: 'px-1'});
          this.phaseItems = [];
          phaseDetails.forEach(detail => {
            let row = {name: detail.student.name, properName: detail.student.properName, lastName: detail.student.lastName, userId: detail.student.id};
            let races = [];
            detail.details.races.forEach(race => {
              let cellColor = race.phaseScore.reward == null ? '' : `background:${this.cellColors[race.phaseScore.reward]}`
              races.push({id: race.id, score: race.phaseScore.score, cellColor});;
            });
            row['races'] = races;
            row['hike'] = detail.details.hikeScore;
            row['totalScore'] = detail.totalScore;
            this.phaseItems.push(row);
          });
        }
      }
    },
    methods: {

    }
  }
</script>
