<template>
    <v-container class='mb-5' style="display:flex;flex-wrap:wrap;">
        <v-card width="350px" class="text-xs-left ma-1" hover v-for="error in errors" :key="error.id">
            <v-card-title>
                <span class="subheading text--truncate">
                    {{ error.data.message }}
                </span>
            </v-card-title>
            <v-card-text>
                <b>File : </b>{{ error.data.fileName }}
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn icon :loading="error.id == deleting" :disabled="deleting > 0">
                    <v-icon color="error" @click="deleteError(error.id)">delete_forever</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
export default {
    props: {},
    data: function () {
        return {
            errors: [],
            deleting: 0
        }
    },
    created() {
        eventBus.$emit('loading', { command: 'clear' });
        eventBus.$emit('loading', { command: 'start', name: 'error-page' });
    },
    mounted() {
        axios.get('/api/errors', {})
        .then(resp => {
            this.errors = resp.data;
            eventBus.$emit('loading', { command: 'finish', name: 'error-page' });
        }).catch(err => console.log(err));
    },
    beforeDestroy() {

    },
    methods: {
        deleteError(id){
            this.deleting = id;
            axios.delete(`/api/errors/${id}`, {})
            .then(resp => {
                console.log(resp.data);
                this.errors = this.errors.filter(e => e.id != id);
                this.deleting = 0;
            }).catch(err => console.log(err.response));
        }
    }
}
</script>
