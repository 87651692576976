<template>
    <v-tabs color="transparent" v-model="tabs" class='mb-3'>
        <v-tab v-for="(term, index) in terms" :key="index" ripple>
            {{ term.name }}
        </v-tab>
        <v-tab-item v-for="(term, index) in terms" :key="index">
            <v-card color="transparent" flat class='pa-2'>
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <div class="headline grey--text">
                            Score
                        </div>
                        <div class="display-2 font-weight-bold text-xs-center">
                            {{ term.score }}pt<span v-if="term.score > 1">s</span>
                        </div>
                    </v-flex>
                    <v-flex xs12 sm6 v-if="term.rules">
                        <div class="headline grey--text">
                            Rules
                        </div>
                        <div style="display:flex;">
                            <div class="pr-4">
                                <div class='mb-1'>
                                    Hike stars <v-icon small color='green'>stars</v-icon> : {{ term.rules.hikeStars }} pt<span v-if='term.rules.hikeStars > 1'>s</span>
                                </div>
                                <div class='mb-1'>
                                    Race stars <v-icon small color='orange'>star</v-icon> : {{ term.rules.raceStars }} pt<span v-if='term.rules.raceStars > 1'>s</span>
                                </div>
                                <div class='mb-1'>
                                    Race finished <v-icon small>directions_run</v-icon> : {{ term.rules.raceFinished }} pt<span v-if='term.rules.raceFinished > 1'>s</span>
                                </div>
                            </div>
                            <div style="flex:1">
                                <div class='mb-1'>
                                    Bronze medal <v-icon small :color='$root.medalColors.bronze'>radio_button_checked</v-icon> : {{ term.rules.bronze }} pt<span v-if='term.rules.bronze > 1'>s</span>
                                </div>
                                <div class='mb-1'>
                                    Silver medal <v-icon small :color='$root.medalColors.silver'>radio_button_checked</v-icon> : {{ term.rules.silver }} pt<span v-if='term.rules.silver > 1'>s</span>
                                </div>
                                <div>
                                    Gold medal <v-icon small :color='$root.medalColors.gold'>radio_button_checked</v-icon> : {{ term.rules.gold }} pt<span v-if='term.rules.gold > 1'>s</span>
                                </div>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
                <div class="headline grey--text mt-3">
                    Races
                </div>
                <div>
                    <race-expandable-card 
                        v-for="race in term.races"
                        :key="race.id"
                        :race="race"
                    />
                </div>
                <div class="headline grey--text mt-3">
                    Hikes
                </div>
                <div>
                    <hike-perfs-panel 
                        v-for="hike in term.hikes"
                        :key="hike.id"
                        :data='hike.progress.studentsPerfs[0]'
                        :trackTitles='hike.progress.hike.trackTitles'
                        :hikeTitle='hike.title'
                    />
                </div>
            </v-card>
        </v-tab-item>
    </v-tabs>
</template>
<script>
export default {
    props: { terms: Array },
    data: function () {
        return {
            tabs: 0
        }
    },
    mounted() {
        const activeTerm = this.terms.find(t => t.active)
        this.tabs = activeTerm ? activeTerm.nb - 1 : 0;
    },
    beforeDestroy() {

    },
    methods: {

    }
}
</script>
