<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <template v-slot:activator="{ on }">
            <v-list-tile v-on="on">
                <v-list-tile-avatar><v-icon>list</v-icon></v-list-tile-avatar>
                <v-list-tile-content>Terms</v-list-tile-content>
            </v-list-tile>
        </template>
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>
                    Terms for {{ className }}
                </v-toolbar-title>
                <v-spacer/>
                <v-toolbar-items>
                  <v-btn icon dark @click="dialog=false">
                    <v-icon>close</v-icon>
                  </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <term-edit-card 
                    v-for="(term, index) in terms" 
                    :key="term.id"
                    :term="term" 
                    :termIndex="index" 
                    :classId="classId"
                    :deletable="index == terms.length - 1 && (!term.races || term.races.length == 0) && (!term.hikes || term.hikes.length == 0)"
                />
                <div style="display:flex;justify-content:space-between;">
                    <v-btn color="primary" :loading="loading" :disabled="saving" @click="addTerm">
                        Add term <v-icon right>add</v-icon>
                    </v-btn>
                    <v-btn color="success" :loading="saving" :disabled="loading" @click="save">
                        Save changes <v-icon right>save</v-icon>
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: { terms: Array, className: String, classId: Number },
    data: function() {
        return {
            dialog: false,
            loading: false,
            saving: false
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        addTerm(){
            eventBus.$emit('addTerm', {});
        },
        save(){
            this.saving = true;
            const terms = this.terms.map(t => ({name: t.name, rules: t.rules, nb: t.nb, active: t.active}));
            axios.patch(`/api/classes/${this.classId}/terms`, {terms})
            .then(resp => {
                eventBus.$emit('termsUpdated', resp.data.terms);
                this.saving = false;
                this.$root.snackSuccess('Terms updated');
            }).catch(err => console.log(err.response));
        }
    }
}
</script>
