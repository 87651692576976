<template>
    <v-container class='pa-0' grid-list-lg>
        <div class="my-2" style="display:flex;align-items:center;position:relative;">
            <div style="flex:1;height:1px;" :style="`background-color:${$vuetify.theme.primary}`"/>
            <div class="px-1 primary--text">Term {{ termIndex + 1 }}</div>
            <div style="flex:1;height:1px;" :style="`background-color:${$vuetify.theme.primary}`"/>
            <!-- <v-btn color="primary" icon style="position:absolute;right:20px;" @click="termUp" disabled v-if="termIndex > 0">
                <v-icon dark>swap_vert</v-icon>
            </v-btn> -->
        </div>
        <v-card flat class="pa-2">
            <v-layout row wrap>
                <v-flex xs12 sm8>
                    <!-- <v-text-field v-model="term.name" label="Name -- Disabled for now" disabled style="flex:1" class="mr-2"/> -->
                    <v-layout row wrap>
                        <v-flex xs6 sm4>
                            <v-text-field label="Hike stars" v-model="term.rules.hikeStars" type="number" min="0"/>
                        </v-flex>
                        <v-flex xs6 sm4>
                            <v-text-field label="Race stars" v-model="term.rules.raceStars" type="number" min="0"/>
                        </v-flex>
                        <v-flex xs6 sm4>
                            <v-text-field label="Race finished" v-model="term.rules.raceFinished" type="number" min="0" @change="checkOrder"/>
                        </v-flex>
                        <v-flex xs6 sm4>
                            <v-text-field label="Bronze medal" v-model="term.rules.bronze" type="number" min="0" @change="checkOrder"/>
                        </v-flex>
                        <v-flex xs6 sm4>
                            <v-text-field label="Silver medal" v-model="term.rules.silver" type="number" min="0" @change="checkOrder"/>
                        </v-flex>
                        <v-flex xs6 sm4>
                            <v-text-field label="Gold medal" v-model="term.rules.gold" type="number" min="0" @change="checkOrder"/>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 sm4 class="pl-2" style="display:flex;flex-direction:column;align-items:center;">
                    <div class="display-1 grey--text text--darken-2 mb-4" style="display:flex;justify-content: space-around;width:100%;">
                        <span class="pr-2">
                            {{ term.races.length }}
                            <v-icon x-large>directions_run</v-icon>
                        </span>
                        <span class="pl-2">
                            {{ term.hikes.length }}
                            <v-icon x-large>directions_walk</v-icon>
                        </span>
                    </div>
                    <div style="flex:1;display:flex;justify-content:center;align-items:center;">
                        <v-switch hide-details style="margin:auto" v-model="term.active" label="Active" @change="deactivateTerms(term.nb)"/>
                    </div>
                </v-flex>
                <v-flex xs12 class="pt-0" style="display:flex;justify-content:flex-end;">
                    <v-btn color="error" flat @click="deleteTerm" v-if="deletable">
                        Delete term
                    </v-btn>
                    <v-dialog v-model="recalcDialog" width="500" v-else>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" flat v-on="on">
                                Recalculate scores
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="headline">
                                Recalculating students scores for {{ term.name }}
                            </v-card-title>
                            <v-card-text>
                                This will save changes to the rules and recalculate the score for this term for all students in the class.<br/>
                                <b>This action cannot be undone.</b> Are you sure you want to proceed ?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn :disabled='loading' color="error" style='width:180px' flat @click="recalcDialog = false">Cancel</v-btn>
                                <v-btn :loading='loading' color="primary" style='width:180px' @click="recalculate">Confirm</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</template>
<script>
export default {
    props: { term: Object, termIndex: Number, classId: Number, deletable: Boolean },
    data: function () {
        return {
            recalcDialog: false,
            deleteDialog: false,
            loading: false,
            rules: {
                required: value => !!value || 'Required.',
            }
        }
    },
    mounted() {
        
    },
    beforeDestroy() {

    },
    methods: {
        recalculate(){
            this.loading = true;
            axios.patch(`/api/classes/${this.classId}/terms/recalculate`, { term: {nb: this.term.nb, rules: this.term.rules} })
            .then(resp => {
                eventBus.$emit('termsUpdated', resp.data);
                this.$root.snackSuccess('Scores recalculated');
                this.loading = false;
                this.recalcDialog = false;
            }).catch(err => console.log(err));
        },
        deleteTerm(){
            eventBus.$emit('deleteTerm', this.term.nb);
        },
        termUp(){
            eventBus.$emit('termUp', this.term.nb);
        },
        deactivateTerms(nb){
            eventBus.$emit('deactivateTerms', nb);
        },
        checkOrder(){
            this.term.rules.bronze = Math.max(this.term.rules.bronze, this.term.rules.raceFinished);
            this.term.rules.silver = Math.max(this.term.rules.bronze, this.term.rules.silver);
            this.term.rules.gold = Math.max(this.term.rules.gold, this.term.rules.silver);
        }
    }
}
</script>
