<template>
  <v-card width="100%" flat class="pa-1 text-xs-left">
    <v-layout row wrap>
      <v-flex xs12>
        <v-select :items="commandSelectValues" label="Command" v-model="command" @input="userTyping"></v-select>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap v-for='(singleFormula, index) in formulas' :key='`formula-${index}`'>
          <v-flex xs12>
            <v-layout row wrap v-if="command == 'expand'">
              <v-flex xs12 sm9>
                <v-text-field :label="`Formula ${index + 1}`" v-model="formulas[index]" :rules="formulaRules" @input="userTyping" clearable validate-on-blur></v-text-field>
              </v-flex>
              <v-flex xs12 sm3>
                <v-select :items="[0,1,2,3]" label="Starting degree" v-model="startingDegree"></v-select>
              </v-flex>
              <v-flex xs12>
                <v-layout>
                  <v-flex shrink v-for="(coeff, index2) in answers[index]" :key='`coeff-${index}-${index2}`' v-if="command == 'expand'">
                    <v-layout row wrap>
                      <v-flex xs10 class="py-0">
                        <v-text-field v-model="answers[index][index2]" style="width:100px;" class="pa-0 ma-0 centered-input" @input="userTyping">
                          <template v-slot:append>
                            <span v-html="powersOfX[index2]"></span>
                          </template>
                        </v-text-field>
                      </v-flex>
                      <v-flex xs2 v-if="index2 < 3" v-html="plus"></v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout row wrap v-if="command == 'factor'">
              <v-flex xs12>
                <v-text-field :label="`Formula ${index + 1}`" v-model="formulas[index]" :rules="formulaRules" @input="userTyping" clearable validate-on-blur></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-layout row wrap>
                  <v-flex v-html="open" class="headline pr-0" style="display:flex;align-items:center;justify-content:flex-end"></v-flex>
                  <v-flex class="px-0">
                    <v-text-field v-model="answers[index][0]" style="width:100px;" class="pa-0 ma-0 centered-input" @input="userTyping">
                      <template v-slot:append>
                        <span v-html="powersOfX[2]"></span>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex v-html="plus" class="headline px-0" style="display:flex;align-items:center;justify-content:center"></v-flex>
                  <v-flex class="px-0">
                    <v-text-field v-model="answers[index][1]" style="width:100px;" class="pa-0 ma-0 centered-input" @input="userTyping"></v-text-field>
                  </v-flex>
                  <v-flex v-html="close" class="headline px-0" style="display:flex;align-items:center;justify-content:center"></v-flex>
                  <v-flex v-html="open" class="headline px-0" style="display:flex;align-items:center;justify-content:center"></v-flex>
                  <v-flex class="px-0">
                    <v-text-field v-model="answers[index][2]" style="width:100px;" class="pa-0 ma-0 centered-input" @input="userTyping">
                      <template v-slot:append>
                        <span v-html="powersOfX[2]"></span>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex v-html="plus" class="headline px-0" style="display:flex;align-items:center;justify-content:center"></v-flex>
                  <v-flex class="px-0">
                    <v-text-field v-model="answers[index][3]" style="width:100px;" class="pa-0 ma-0 centered-input" @input="userTyping"></v-text-field>
                  </v-flex>
                  <v-flex v-html="close" class="headline px-0" style="display:flex;align-items:center;justify-content:flex-start"></v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout row wrap v-if="command == 'equation'">
              <v-flex xs12>
                <v-text-field :label="`Formula ${index + 1}`" v-model="formulas[index]" :rules="formulaRules" @input="userTyping" clearable validate-on-blur></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-layout row wrap v-if="command == 'equation'">
                  <v-flex xs3 v-for="i in 3" :key='`solution-${index}-${i}`'>
                    <v-text-field :label="`Solution ${i}`" v-model="answers[index][i-1]" @input="userTyping" v-if="i <= nbOfSolutions[index]" validate-on-blur></v-text-field>
                  </v-flex>
                  <v-flex xs3>
                    <v-select :items="[1,2,3]" label="Nb of solutions" v-model="nbOfSolutions[index]" @input="userTyping"></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-flex class="text-xs-right">
      <v-btn flat icon color="primary" @click="removeFormula" :disabled="formulas.length <= 1">
        <v-icon dark>remove</v-icon>
      </v-btn>
      <v-btn flat icon color="primary" @click="addFormula" :disabled="formulas.length >= 4">
        <v-icon dark>add</v-icon>
      </v-btn>
    </v-flex>
    <v-card-text class="text-xs-left">
      <p class="grey--text caption ma-0 pa-0">Variables</p>
      <v-layout row wrap v-for="(variable, index) in variables" :key='`variable-${index}`'>
        <v-flex xs4 class="text-xs-center">
          <span class="headline font-weight-bold font-italic">{{ variable.name }}</span>
          <v-select solo flat hide-details :items="variableItems" v-model="variable.type" @input="userTyping"></v-select>
        </v-flex>
        <v-flex xs8 v-if='variable.type == "enum"'>
          <v-text-field label="Values (separate by a comma ',')" v-model="variable.values" @input="userTyping" @keyup='formatEnumValues(variable.name)'></v-text-field>
        </v-flex>
        <v-flex xs8 v-if='variable.type == "depend"'>
          <v-text-field label="Formula (depending on previous variables)" v-model="variable.formula" @input="userTyping"></v-text-field>
        </v-flex>
        <v-flex xs8 v-if='variable.type == "grad"'>
          <v-layout row wrap>
            <v-flex xs4>
              <v-text-field label="min" v-model="variable.min" type="number" :min="-1000" :max="1000" @input="userTyping"></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field label="max" v-model="variable.max" type="number" :min="-1000" :max="1000" @input="userTyping"></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field label="step" v-model="variable.step" type="number" :min="0.01" :max="100" :step="0.01" @input="userTyping"></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-flex xs12 class="text-xs-right">
      <v-btn flat icon color="primary" @click="removeVariable" :disabled="variables.length <= 1">
        <v-icon dark>remove</v-icon>
      </v-btn>
      <v-btn flat icon color="primary" @click="addVariable" :disabled="variables.length >= 8">
        <v-icon dark>add</v-icon>
      </v-btn>
    </v-flex>
  </v-card>
</template>

<script>
  import katex from 'katex';

  export default {
    props : {
      formula: Object
    },
    data : function(){
      return {
        formulas: [],
        answers: [],
        variables: [],
        command: 'expand',
        nbOfSolutions: [],
        startingDegree: 1,
        variableItems: [
          {value: 'grad', text: 'Gradual'}, {value: 'enum', text: 'Enumarate'}, {value: 'depend', text: 'Dependant'}
        ],
        commandSelectValues: [
          {value: 'expand', text: 'Expand'}, {value: 'factor', text: 'Factor'}, {value: 'equation', text: 'Equation'}
        ],
        answerSelectValues: [
          '.(.x+.)', '.x(.x+.)', '(.x+.)(.x+.)'
        ],
        answerType: '',
        answerDegree: '',
        powersOfX: [
          katex.renderToString("x^3"),
          katex.renderToString("x^2"),
          katex.renderToString("x"),
          ''
        ],
        plus: katex.renderToString("+"),
        open: katex.renderToString("("),
        close: katex.renderToString(")"),
        typingTimer: null,
        formulaRules: [
          v => !!v || 'You must enter a formula',
        ]
      }
    },
    mounted(){
      this.formulas = this.formula.formulas;
      this.answers = this.formula.answers;
      this.variables = this.formula.variables;
      this.command = this.formula.command;
      this.nbOfSolutions = this.formula.nbOfSolutions || [1];
      this.startingDegree = this.formula.startingDegree;
    },
    methods: {
      addFormula(){
        if (this.formulas.length < 4){
          this.formulas.push('');
          this.answers.push([0, 0, 0, 0]);
          this.nbOfSolutions.push(1);
          this.emitFormula();
        }
      },
      removeFormula(){
        if (this.formulas.length > 1){
          this.formulas.pop();
          this.answers.pop();
          this.emitFormula();
        }
      },
      addVariable(){
        if (this.variables.length < 20){
          let nextChar = String.fromCharCode(this.variables[this.variables.length-1].name.charCodeAt(0) + 1);
          this.variables.push({name: nextChar, min: 0, max: 10, step: 1, type: 'grad', values: ''});
          this.emitFormula();
        }
      },
      removeVariable(){
        if (this.variables.length > 1){
          this.variables.pop();
          this.emitFormula();
        }
      },
      formatEnumValues(name){
        this.variables.forEach( variable => {
          if(variable.name == name){
            variable.values = variable.values.replace(/[^\d,.]/g, '');
          }
        });
      },
      emitFormula(){
        if(this.command == 'equation'){
          this.formulas.forEach(formula => {
            if(formula.indexOf('=') < 0){
              this.$root.snackError('An equation must have an equal sign');
              return false;
            }
          });
        }
        this.answers.forEach((answer, index) => {
          answer.forEach((item, index2) => {
            if(item == '' || item == null){
              this.answers[index][index2] = "0";
            }
          });
        });
        let newFormula = {
          formulas: this.formulas,
          variables: this.variables,
          nbOfSolutions: this.nbOfSolutions,
          answers: this.answers,
          command: this.command
        };
        eventBus.$emit('formulaUpdated', newFormula);
      },
      userTyping(){
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this.emitFormula, 500);
      }
    }
  }
</script>

<style scoped>
  .centered-input >>> input {
    text-align: center
  }
</style>
