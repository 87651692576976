<template>
  <div ref='wrapper' style='font-family:"KBSticktoIt"' :style='isTiny ? "font-size:48px" : "font-size:96px"'>
    <div v-if='isSmall'>
      <div style='margin-right:120px'>
        Sam's
      </div>
      <div style='color:grey;max-width:100%' :style="margins">Tricks</div>
    </div>
    <div v-else>
      Sam's <span style='color:grey'>Tricks</span>
    </div>
  </div>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        isSmall: false,
        isTiny: false
      }
    },
    computed: {
        margins(){
          return this.isTiny ? 'margin-right:-40px;margin-top:-20px;' : 'margin-right:-80px;margin-top:-50px;';
        }
    //   isSmall(){
    //     return this.$refs.wrapper && this.$refs.wrapper.clientWidth < 600;
    //   },
    //   isTiny() {
    //     return this.$refs.wrapper && this.$refs.wrapper.clientWidth < 400;
    //   }
    },
    mounted() {
      this.isSmall = this.$refs.wrapper.clientWidth < 600;
      this.isTiny = this.$refs.wrapper.clientWidth < 400;
      window.addEventListener("resize", this.updateDisplay);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.updateDisplay);
    },
    methods: {
      updateDisplay(){
        this.isSmall = this.$refs.wrapper.clientWidth < 600;
        this.isTiny = this.$refs.wrapper.clientWidth < 400;
      }
    }
  }
</script>
