<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs12 style='display:flex;justify-content:flex-end'>
        <class-practice-panel class='mb-2' :classes='userDivisions' v-if='$root.status == "teacher"'/>
        <div :style='`overflow-x:hidden;width:${practicePanelIsClosed ? "290px" : "75px"};transition: all .5s`'>
          <v-menu offset-y transition="slide-y-transition" v-model='marathonMenu'>
            <template v-slot:activator="{ on }">
              <v-btn large style='width:220px;height:58px;margin-top:0px' :round='!marathonMenu' v-on="on" v-if='practicePanelIsClosed'>
                Marathons
                <v-icon right>emoji_events</v-icon>
              </v-btn>
              <v-btn fab v-on="on" :style='`border-radius:${marathonMenu ? "0%" : "50%"}`' v-else>
                <v-icon medium style='display:inline-flex'>emoji_events</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile v-for='marathon in userMarathons' :key='marathon.id' @click="seeMarathon(marathon.id)">
                <v-list-tile-avatar>
                  <v-icon :color='statusColor(marathon.status)'>emoji_events</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-title style='display:flex;justify-content:space-between'>
                  <span class='title mr-5'>
                    {{ marathon.title }}
                  </span>
                  <span class='caption grey--text ml-5'>
                    {{ marathon.attribution }}
                  </span>
                </v-list-tile-title>
              </v-list-tile>
              <v-list-tile style='display:flex;justify-content:center' class='grey--text' v-if='$root.status != "teacher" && userMarathons.length == 0'>
                <v-list-tile-title>No active marathon at the moment</v-list-tile-title>
              </v-list-tile>
              <v-divider v-if='$root.status == "teacher"'/>
              <v-list-tile @click="findMarathonDialog = true" v-if='$root.status == "teacher"'>
                <v-list-tile-avatar><v-icon>exit_to_app</v-icon></v-list-tile-avatar>
                <v-list-tile-title>Join existing marathon</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="createMarathonDialog = true" v-if='$root.status == "teacher"'>
                <v-list-tile-avatar><v-icon>add</v-icon></v-list-tile-avatar>
                <v-list-tile-title>Create new marathon</v-list-tile-title>
              </v-list-tile>
              <v-divider/>
              <v-list-tile @click="goAllMarathons">
                <v-list-tile-avatar><v-icon>list</v-icon></v-list-tile-avatar>
                <v-list-tile-title>See all marathon</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>
      </v-flex>
    </v-layout>
    <v-layout row wrap class='fillHeight'>
      <v-flex xs12 md6 lg4 v-for="division in userDivisions" :key="division.id" class="justify-center mb-2">
        <class-card :division="division"/>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 class='mb-3'>
        <archived-classes v-if="$root.status == 'teacher'"/>
      </v-flex>
    </v-layout>
    <add-class-fab v-if="$root.status == 'teacher'"></add-class-fab>
    <join-class-fab :divisionInvites='divisionInvites' v-else/>
    <v-dialog :persistent='archiveClassLoading' v-model="archiveClassDialog" width="320px">
      <v-card>
        <v-card-title class="headline">Archive {{ archivedClassName }}</v-card-title>
        <v-card-text>Are you sure you want to archive this class ? <br/> It, along with all its races and hikes, will be hidden until you take it out of the archive.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" style='width:120px' :disabled='archiveClassLoading' flat @click="archiveClassDialog=false">No</v-btn>
          <v-btn color="error" style='width:120px' :loading='archiveClassLoading' @click="proceedArchive()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model='createMarathonDialog' width="400px">
      <v-card>
        <v-card-title class='headline' style='display:flex;justify-content:space-between'>
          <span>
            Create a marathon
          </span>
          <v-btn icon @click='marathonDetails = !marathonDetails'>
            <v-icon color='grey' v-if='marathonDetails'>cancel</v-icon>
            <v-icon color='grey' v-else>help</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div :style='`height:${marathonDetails ? "75px" : "0px"};overflow-y:hidden;transition:all .3s`'>
            <b>Marathons</b> are designed to be "school wide" competitions. When you create a marathon, you can organise races and hikes amongst classes of different teachers.
          </div>
          <v-text-field label="Title" v-model="marathonTitle" :rules="titleRules" autofocus clearable validate-on-blur/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="error" :disabled='marathonLoading' style="width:140px" flat @click="createMarathonDialog=false">Cancel</v-btn>
          <v-btn color="primary" :loading='marathonLoading' style="width:140px"  @click="submitNewMarathon">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model='findMarathonDialog' width="400px">
      <v-window vertical v-model='findOrJoin'>
        <v-window-item :key='0'>
          <v-card>
            <v-card-title class='headline' style='display:flex;justify-content:space-between;'>
              <span>
                Find a marathon
              </span>
              <v-btn icon @click='marathonDetails = !marathonDetails'>
                <v-icon color='grey' v-if='marathonDetails'>cancel</v-icon>
                <v-icon color='grey' v-else>help</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div :style='`height:${marathonDetails ? "75px" : "0px"};overflow-y:hidden;transition:all .3s`'>
                <b>Marathons</b> are designed to be "school wide" competitions. When you create a marathon, you can organise races and hikes amongst classes of different teachers.
              </div>
              <v-text-field label="Code" v-model="marathonCode" mask="NNNNNN" autofocus clearable validate-on-blur @keyup='capitalizeCode'/>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn color="error" :disabled='marathonLoading' style="width:140px" flat @click="cancelJoinMarathon">Cancel</v-btn>
              <v-btn color="primary" :loading='marathonLoading' style="width:140px"  @click="findMarathon">Find</v-btn>
            </v-card-actions>
          </v-card>
        </v-window-item>
        <v-window-item :key='1'>
          <v-card>
            <v-card-title>
              <v-layout row wrap>
                <v-flex xs12>
                  <div class='headline'>
                    Join {{ marathon.title }}
                  </div>
                  <div class='grey--text' style='display:flex;justify-content:space-between'>
                    <span>
                      {{ marathon.author.name }}'s marathon
                    </span>
                    <span>
                      {{ marathon.divisions.length }} class<span class='mr-1' v-if='marathon.divisions.length > 1'>es</span> enrolled
                    </span>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <div class='title grey--text'>
                Joining classes
              </div>
              <div style='display:flex;justify-content:space-between;flex-wrap:wrap' v-for='division in userDivisions' :key='division.id'>
                <v-checkbox
                  v-model='marathonClasses'
                  :disabled='disabledMarathonClasses.includes(division.id)'
                  :label='division.name'
                  :value='division.id'
                />
                <span style='max-width:80px' v-if='marathonClasses.includes(division.id)'>
                  <v-text-field :disabled='disabledMarathonClasses.includes(division.id)' v-model='division.nbStudents' type='number' min='1' max='250' :rules='nbStudentsRules' label="Nb of students" />
                </span>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn color="error" :disabled='marathonLoading' style="width:140px" flat @click="cancelJoinMarathon">Cancel</v-btn>
              <v-btn color="primary" :loading='marathonLoading' style="width:140px"  @click="joinMarathon">Join</v-btn>
            </v-card-actions>
          </v-card>
        </v-window-item>
      </v-window>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    props : {},
    data : function(){
      return {
        dataLoaded: false,
        // userMarathons: [],
        archiveClassDialog: false,
        practicePanelIsClosed: true,
        archivedClassName: '',
        archivedClassId: '',
        marathonMenu: false,
        marathonDetails: false,
        marathonTitle: '',
        marathonLoading: false,
        createMarathonDialog: false,
        findMarathonDialog: false,
        marathonCode: '',
        marathon: {author: {}, divisions: []},
        marathonClasses: [],
        disabledMarathonClasses: [],
        divisionInvites: [],
        findOrJoin: 0,
        role: this.$root.status,
        archiveClassLoading: false,
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 5) || 'The trick title must be at least 5 characters long'
        ],
        nbStudentsRules: [
          v => !isNaN(v) || 'Pick a valid nb of students',
          v => v > 0 || 'Pick a valid nb of students',
          v => v < 251 || 'Pick a valid nb of students'
        ]
      }
    },
    computed: {
      userDivisions(){
        return this.$root.classesData.divisions;
      },
      userMarathons(){
        return this.$root.classesData.marathons;
      }
    },
    created() {
      eventBus.$emit('loading', {command: 'clear'});
    },
    mounted() {
      eventBus.$emit('loading', {command: 'start', name: 'classes-page'});
      if(this.$root.classesDataLoaded){
        this.initData();
      }
      eventBus.$on('updateClassesData', this.initData);
      eventBus.$on('removeInvite', this.removeInvite);
      eventBus.$on('classPracticePanelOpen', this.toggleMarathonButtonVisibility);
      eventBus.$on('prepareDivisionArchive', this.prepareArchive);
    },
    beforeDestroy(){
      eventBus.$off('updateClassesData', this.initData);
      eventBus.$off('removeInvite', this.removeInvite);
      eventBus.$off('classPracticePanelOpen', this.toggleMarathonButtonVisibility);
      eventBus.$off('prepareDivisionArchive', this.prepareArchive);
    },
    methods : {
      initData(){
        this.divisionInvites = this.$root.classesData.divisionInvites;
        // this.userDivisions = this.$root.classesData.divisions;
        // this.userMarathons = this.$root.classesData.marathons;
        this.archiveClassLoading = false;
        this.archiveClassDialog = false;
        eventBus.$emit('loading', {command: 'finish', name: 'classes-page'});
      },
      removeInvite(invite){
        this.divisionInvites = this.divisionInvites.filter(i => i.id != invite.id);
      },
      toggleMarathonButtonVisibility(val){
        this.practicePanelIsClosed = !val;
      },
      submitNewMarathon(){
        this.marathonLoading = true;
        if(this.marathonTitle.length >= 5){
          axios.post('/api/marathons', {title: this.marathonTitle})
          .then(resp => {
            eventBus.$emit('refreshClassesData', {});
            this.marathonLoading = false;
            this.$router.push(`/marathons/${resp.data.id}`);
          }).catch(err => console.log(err));
        }
      },
      seeMarathon(id){
        this.$router.push(`/marathons/${id}`);
      },
      goAllMarathons(){
        this.$router.push(`/marathons-all`);
      },
      capitalizeCode(){
        this.marathonCode = this.marathonCode.toUpperCase();
      },
      statusColor(status){
        if(status == 'draft') return 'grey';
        if(status == 'active') return 'success';
        return 'black';
      },
      findMarathon(){
        axios.get(`/api/marathons/find/${this.marathonCode}`)
        .then(resp => {
          this.marathon = resp.data;
          this.findOrJoin = 1;
          resp.data.divisions.forEach(division => {
            if(this.userDivisions.filter(cl => cl.id == division.id)){
              this.marathonClasses.push(division.id);
              this.disabledMarathonClasses.push(division.id);
            }
          });
          this.userDivisions.forEach(div => {
            div.nbStudents = div.stats.studentCount;
          });
        }).catch(err => {
          console.log(err)
          this.$root.snackError(err.response.data.error);
        });
      },
      joinMarathon(){
        let newClasses = [];
        this.marathonClasses.filter(clId => !this.disabledMarathonClasses.includes(clId)).forEach(clId => {
          let currentClass = this.userDivisions.find(c => c.id == clId);
          newClasses.push({id: currentClass.id, name: currentClass.name, nbStudents: currentClass.nbStudents});
        });
        if(newClasses.length > 0){
          this.marathonLoading = true;
          axios.post(`/api/marathons/${this.marathon.id}/divisions/join`, {divisions: newClasses})
          .then(resp => {
            this.$router.push(`/marathons/${resp.data.id}`);
            this.marathonLoading = false;
          }).catch(err => console.log(err));
        } else {
          this.$root.snackWarning("No new classes selected");
        }
      },
      cancelJoinMarathon(){
        this.findMarathonDialog = false;
        this.marathon = {author: {}, divisions: []};
        this.findOrJoin = 0;
        this.marathonCode = '';
        this.marathonClasses = [];
      },
      prepareArchive(division){
        this.archivedClassName = division.name;
        this.archivedClassId = division.id;
        this.archiveClassDialog = true;
      },
      proceedArchive(){
        let vm = this
        this.archiveClassLoading = true;
        axios.put('/api/classes/'+this.archivedClassId, {})
        .then(function (response) {
          eventBus.$emit('refreshClassesData', {});
        })
        .catch(function (error) {
          vm.archivedClassName = '';
          vm.archivedClassId = 0;
          vm.archiveClassDialog = false;
        });
      }
    }
  }
</script>

<style>
  .fillHeight {
    min-height:calc(100vh - 230px);
  }
  .slideFade-enter-active {
    animation: slideFade .5s;
  }
  .slideFade-leave-active {
    animation: slideFade .3s reverse;
  }
  @keyframes slideFade {
    0% {
      transform: translateX(0px);
      opacity: 1;
    }
    100% {
      transform: translateX(50px);
      opacity: 0;
    }
  }
  .fadeUp-leave-active {
    animation: fadeUp 2s;
  }
  @keyframes fadeUp {
    0% {
      height: 80px;
    }
    100% {
      height: 0px;
    }
  }
</style>
