<template>
  <v-dialog v-model="studentDialog" fullscreen hide-overlay transition="slide-x-transition" @keydown.esc="closeStudentDialog">
    <v-card>
      <v-toolbar fixed dark color="primary">
        <v-img max-width='60px' style='border-radius:50%;margin:2px;' :src='student.images.avatar' v-if='student.images && !loading'/>
        <v-toolbar-title v-if='!loading'>
          <div v-if='student.properName && $root.status == "teacher"'>
            {{ student.properName.name }}
          </div>
          <div v-else>{{ student.name }}</div>
          <div class='grey--text text--lighten-1 subheading text-truncate'>
            {{ student.email }}
          </div>
        </v-toolbar-title>
        <span v-if='$vuetify.breakpoint.name != "xs" && !loading'>
          <span class='headline font-weight-medium ml-4 mr-2'>
            {{ student.smile_count }}
            <v-icon medium color="white">sentiment_satisfied_alt</v-icon>
          </span>
          <span class='headline font-weight-medium'>
            {{ student.star_count }}
            <v-icon medium color="orange">star</v-icon>
          </span>
        </span>
         <v-spacer></v-spacer>
         <v-btn icon dark @click="closeStudentDialog">
           <v-icon>close</v-icon>
         </v-btn>
      </v-toolbar>
      <v-progress-linear indeterminate class='ma-0' style='position:fixed;top:64px;z-index:10' v-if='loading'></v-progress-linear>

      <v-card-text class='mt-5'>
        <v-layout row wrap class='pa-2'>
          <v-flex xs12 class="headline grey--text text-xs-left" style='display:flex;justify-content:space-between'>
            Latest tricks
          </v-flex>
          <v-flex xs12 v-if='perfs.latest.length == 0' class='grey--text text--lighten-2'>
            No tricks tried
          </v-flex>
          <v-flex xs12 v-else>
            <v-card class="pa-2 ma-2 text-xs-center" style="display:inline-block" width="200" v-for="(perf, index) in perfs.latest" :key="`lastPerf-${index}`">
              <div class="subheading text-truncate">{{ perf.trick.title }}</div>
              <div><b>{{ perf.score }}</b> points</div>
              <v-rating v-model="perf.rating" size="12" color="orange" background-color="orange lighten-3" readonly></v-rating>
              <div class='grey--text caption'>{{ perf.ago }}</div>
            </v-card>
          </v-flex>
          <v-flex xs12 md6>
            <v-layout row wrap>
              <v-flex xs12 class="headline grey--text text-xs-left">
                Recommended tricks
              </v-flex>
              <v-flex xs12 v-if='student.affinities.length == 0' class='grey--text text--lighten-2 headline font-weight-thin text-xs-center pt-3'>
                None
              </v-flex>
              <v-flex xs12>
                <v-chip v-for="(trick, index) in student.affinities" :key="`affinity-${trick.id}-${index}`">
                  <v-avatar class='font-weight-bold white--text' :color='$root.trickColors[trick.type]'> {{ trick.type | initial }}</v-avatar>
                  {{ trick.title }}
                </v-chip>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 md6>
            <v-layout row wrap>
              <v-flex xs12 class="headline grey--text text-xs-left">
                Active challenges
              </v-flex>
              <v-flex xs12 v-if='student.activeChallenges.length == 0' class='grey--text text--lighten-2 headline font-weight-thin text-xs-center pt-3'>
                None
              </v-flex>
              <v-flex xs12>
                <v-chip v-for="(challenge, index) in student.activeChallenges" color='blue' dark :key="`challenge-${index}`">
                  {{ challenge.track.title }}
                </v-chip>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 class="mt-4">
            <v-select :items="perfTimeUnits" :loading="timeUnitLoading" :disabled="timeUnitLoading" v-model="timeUnitSelected" label="Performances" outline @change="perfTimeUnitChange"></v-select>
          </v-flex>
          <v-flex xs12 md6>
            <graph-perfsOverTime></graph-perfsOverTime>
          </v-flex>
          <v-flex xs12 md6>
            <graph-challengesOverTime></graph-challengesOverTime>
          </v-flex>
          <v-flex xs12 class="mt-4" v-if='!isTeacher'>
            <v-select hide-details :items="raceDivisions" :loading="raceLoading" :disabled="raceLoading" v-model="raceTermSelected" label="Races" outline @change="raceTermChange"></v-select>
          </v-flex>
          <v-flex xs12>
            <graph-racesPerfs v-if='!isTeacher'></graph-racesPerfs>
          </v-flex>
          <v-flex xs12 class="mt-4" v-if='!isTeacher'>
            <v-select hide-details :items="hikeItems" :loading="hikeLoading" :disabled="hikeLoading" v-model="hikeSelected" label="Hikes" outline @change="hikeTermChange"></v-select>
          </v-flex>
          <v-flex xs12>
            <graph-hikesPerfs class='mt-2' v-if='!isTeacher'></graph-hikesPerfs>
          </v-flex>
          <v-flex xs12 class="headline grey--text mt-3 text-xs-left">
            Tracks star count
          </v-flex>
          <v-flex xs12 :style='`min-height:${trackStarCountDivHeight}px`'>
            <graph-tracksStarCount></graph-tracksStarCount>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import Chart from 'chart.js'

  export default {
    props : {},
    data : function(){
      return {
        studentDialog: false,
        student: {affinities: [], activeChallenges: []},
        userId: 0,
        isTeacher: true,
        perfs: {latest: []},
        loading: false,
        timeUnitLoading: false,
        timeUnitSelected: {text: 'Last 12 weeks', value: 'weeks'},
        perfTimeUnits: [
          {text: 'Last 12 days', value: 'days'},
          {text: 'Last 24 days', value: 'bidays'},
          {text: 'Last 12 weeks', value: 'weeks'},
          {text: 'Last 12 months', value: 'months'}
        ],
        raceDivisions: [],
        raceTermSelected: null,
        raceLoading: false,
        hikeItems: [],
        hikeSelected: null,
        hikeLoading: false
      }
    },
    computed: {
      trackStarCountDivHeight(){
        switch(this.$vuetify.breakpoint.name){
          case 'xs':
            return 9000;
          break;
          case 'sm':
            return 4000;
          break;
          case 'md':
            return 2400;
          break;
          default:
            return 1800;
          break;
        }
      }
    },
    filters: {
      initial(val){
        return val.charAt(0).toUpperCase();
      }
    },
    mounted() {
      eventBus.$on('showStats', this.initData);
      eventBus.$on('racesLoading', value => this.raceLoading = value);
    },
    beforeDestroy(){
      eventBus.$off('showStudentDetails', this.initData);
    },
    methods: {
      closeStudentDialog(){
        this.student = {affinities: [], activeChallenges: []};
        this.perfs = {latest: []};
        this.studentDialog = false;
        eventBus.$emit('tracksPolars', 0);
      },
      initData(data){
        this.studentDialog = true;
        this.loading = true;
        this.userId = data.userId;
        this.raceLoading = true;
        this.isTeacher = data.isTeacher;
        let vm = this;
        axios.get(`/api/stats/${data.userId}/${data.classId}`, {})
        .then(function (response) {
          vm.student = response.data.student;
          // console.log(vm.student)
          vm.perfs = response.data.perfs;
          vm.hikeItems = response.data.hikes.items;
          vm.hikeSelected = response.data.hikes.selected;
          vm.raceDivisions = response.data.divisions;
          vm.raceTermSelected = response.data.selectedDivision;
          eventBus.$emit('perfsOverTime', response.data.perfs.last12weeks);
          eventBus.$emit('challengesOverTime', response.data.perfs.last12weeksChallenges);
          eventBus.$emit('racesPerfs', {userId: data.userId, divTerm: response.data.selectedDivision});
          eventBus.$emit('hikesPerfs', {userId: data.userId, hikeId: response.data.hikes.selected});
          eventBus.$emit('tracksPolars', data.userId);
          vm.loading = false;
          vm.raceLoading = false;
        })
        .catch(error => console.log(error));
      },
      perfTimeUnitChange(){
        this.timeUnitLoading = true;
        let vm = this;
        axios.get(`/api/stats/${this.userId}/perfhistory/${this.timeUnitSelected}`, {})
        .then(response => {
          this.timeUnitLoading = false;
          eventBus.$emit('perfsOverTime', response.data.perfs);
          eventBus.$emit('challengesOverTime', response.data.challenges);
        })
        .catch(error => console.log(error));
      },
      raceTermChange(){
        eventBus.$emit('racesPerfs', {userId: this.userId, divTerm: this.raceTermSelected});
      },
      hikeTermChange(){
        eventBus.$emit('hikesPerfs', {userId: this.userId, hikeId: this.hikeSelected});
      }
    }
  }
</script>
