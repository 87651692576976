<template>
  <v-hover>
    <v-card
      :color='collectible.public ? "#f5f5f5" : "grey lighten-2"'
      slot-scope="{ hover }"
      hover
      width='200px' height='380px'
      style='position:relative'
      @click='editMe'
    >
      <div style='max-height:200px;min-height:200px;display:flex;align-items:center;position:relative'>
        <v-img :style='collectible.type=="avatar" ? "border-radius:50%" : ""' max-height='200px' contain :src='collectible.url'/>
        <v-chip dark small color='success' style='position:absolute;left:0px;bottom:-17px;' v-if='collectible.public'>Public</v-chip>
        <v-chip dark small color='warning' style='position:absolute;left:0px;bottom:-17px;' v-else>Private</v-chip>
      </div>
      <v-layout class='px-3 pt-3 pb-1 text-xs-left' row wrap>
        <v-flex xs12 class='headline pa-0' style='min-height:64px;max-height:64px;overflow-y:hidden;overflow-wrap:break-word'>
          {{ collectible.title }}
        </v-flex>
        <v-flex xs12 class='subheading grey--text pa-0' style='display:flex;justify-content:space-between'>
          <span>
            {{ formatedType.name }}
          </span>
          <span>
            <v-icon>{{ formatedType.icon }}</v-icon>
          </span>
        </v-flex>
        <v-flex xs12 class='subheading'>
          <b>Requires :</b> {{ collectible.stars }} <v-icon color="orange" class='ma-0' style='vertical-align:text-bottom;font-size:24px'>star</v-icon>
        </v-flex>
        <v-flex xs12 class='title' style='display:flex;justify-content:space-evenly'>
          <span>
            {{ collectible.smiles }} <v-icon color="primary" class='ma-0' style='vertical-align:text-bottom;font-size:24px'>sentiment_satisfied_alt</v-icon>
          </span>
          <span>
            {{ collectible.coins }} <v-icon class='ma-0' style='vertical-align:text-bottom;font-size:24px'>radio_button_checked</v-icon>
          </span>
        </v-flex>
      </v-layout>
      <v-dialog v-model="deleteDialog" width="350">
        <v-card>
          <v-card-title class='headline'>Delete {{ collectible.title }}</v-card-title>
          <v-card-text>
            Are you sure you want to delete this collectible ?
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" :disabled='loading' style='width:120px' flat @click="deleteDialog = false">Cancel</v-btn>
            <v-btn color="error" :loading='loading' style='width:120px' @click="deleteCollectible">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn icon color='error' drak style='position:absolute;top:0px;right:0px' v-if='hover && collectible.deletable && !collectible.public' @click.stop='prepareDelete'>
        <v-icon dark>delete_forever</v-icon>
      </v-btn>
      <v-btn icon dark :color='collectible.public ? "warning" : "blue lighten-1"' :loading='lockLoading' drak style='position:absolute;top:0px;left:0px' v-if='hover && collectible.url != "/images/pending.png"' @click.stop='togglePublic'>
        <v-icon dark v-if='collectible.public'>lock_open</v-icon>
        <v-icon dark v-else>lock</v-icon>
      </v-btn>
    </v-card>
  </v-hover>
</template>
<script>
  export default {
    props : { collectible: Object },
    data : function(){
      return {
        deleteDialog: false,
        loading: false,
        lockLoading: false,
      }
    },
    computed: {
      formatedType() {
        switch (this.collectible.type) {
          case 'avatar':
            return {name: 'Avatar', icon: 'account_circle'}
            break;
          case 'correct':
            return {name: 'Correct answer', icon: 'done'}
            break;
          case 'incorrect':
            return {name: 'Incorrect answer', icon: 'close'}
            break;
          case 'pending':
            return {name: 'Pending answer', icon: 'help'}
            break;
          case 'upcoming':
            return {name: 'Upcoming race', icon: 'timer'}
            break;
          case 'active':
            return {name: 'Active race', icon: 'directions_run'}
            break;
          case 'finished':
            return {name: 'Finished race', icon: 'emoji_people'}
            break;
        }
      },
    },
    mounted() {

    },
    methods: {
      editMe(){
        eventBus.$emit('editCollectible', this.collectible);
      },
      togglePublic(){
        this.lockLoading = true;
        axios.patch(`/api/collectibles/${this.collectible.id}/public`)
        .then(resp => {
          eventBus.$emit('collectibleEdited', resp.data);
          this.lockLoading = false;
        }).catch(err => console.log(err));
      },
      prepareDelete(){
        this.deleteDialog = true;
      },
      deleteCollectible(){
        axios.delete(`/api/collectibles/${this.collectible.id}`)
        .then(resp => {
          eventBus.$emit('removeCollectible', resp.data);
        }).catch(err => console.log(err));
      }
    }
  }
</script>
