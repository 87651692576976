<template>
  <div style='display:flex;' class='mt-3'>
    <div
      class='px-1'
      :style='`height:${maxHeight}px`'
      style='width:20%;display:flex;flex-direction:column;justify-content:flex-end;align-items:center'
      v-for='item in formatedItems' :key='item.id'
    >
      <span class='text-xs-center px-1' style='width:100%;display:flex;flex-direction:column;align-items:center' v-if='item.displayOnTop'>
        <v-img :src='item.avatar' width='80%' max-width='80px' style='border-radius:50%;'/>
        <v-chip color='success' dark class='title' v-if='item.id == $root.user.id && students'>
          You
        </v-chip>
        <div
          class='font-weight-bold'
          :class='bigName ? "title" : ""'
          style='max-height:42px;overflow-y:hidden;overflow-x:hidden;overflow-wrap:break-word;max-width:100%;'
          v-if='showNames'
        >
          {{ item.name }}
        </div>
        <div style='overflow-wrap:break-word;max-width:100%;' class='grey--text text--darken-2'>
          {{ item.className }}
        </div>
      </span>
      <v-hover>
        <v-card
          slot-scope="{ hover }"
          hover
          class='text-xs-center px-1'
          width='100%' :height='item.divHeight'
          :color='item.color'
          style='display:flex;align-items:center;justify-content:center;'
        >
          <span style='width:100%;position:absolute;top:-20px;left:0px;background:#FAFAFA;' class='text-xs-center grey--text font-weight-bold' v-if='hover'>
            {{ item.totalScore }} pts
          </span>
          <span style='width:100%;display:flex;flex-direction:column;align-items:center' v-if='!item.displayOnTop'>
            <v-img :src='item.avatar' width='80%' max-width='80px' style='border-radius:50%;'/>
            <v-chip color='success' dark class='title' v-if='item.id == $root.user.id && students'>
              You
            </v-chip>
            <div class='font-weight-bold' :class='bigName ? "title" : ""' style='overflow-x:hidden;overflow-y:hidden;overflow-wrap:break-word;max-width:100%' v-else-if='showNames'>
              {{ item.name }}
            </div>
            <div class='grey--text text--darken-2' style='overflow-wrap:break-word;max-width:100%;' v-if='item.className'>
              {{ item.className }}
            </div>
          </span>
          <transition name='fade'>
            <div v-if='hover' style='position:absolute;top:0;left:0;width:100%;height:100%;display:flex;flex-direction:column'>
              <div
                :style='`flex:${portion.value};background:${portion.bgColor}`'
                style='display:flex;align-items:center;justify-content:center'
                v-for='(portion, index) in item.portions' :key='`portion-${index}`'
                >
                <v-icon :color='portion.iconColor' v-if='item.divHeight*portion.value >= 24'>
                  {{ portion.icon }}
                </v-icon>
              </div>
              <div style='position:absolute;left:0px;bottom:-77px;height:77px;padding-top:5px;z-index:5;white-space:nowrap'>
                <div 
                  style='display:flex;align-items:center;'
                  class='caption'
                  v-for='(portion, index) in item.portions'
                  :key='`legend-${index}`'
                  v-show='portion.value > 0 && displayLegends'
                >
                  <v-icon :color='portion.iconColor'>
                    {{ portion.icon }}
                  </v-icon>
                  {{ portion.name }}
                </div>
              </div>
            </div>
          </transition>
        </v-card>
      </v-hover>
    </div>
  </div>
</template>
<script>
  export default {
    props : { items: Array, bigName: Boolean, students: Boolean, maxHeight: Number, showNames: Boolean, displayLegends: {type: Boolean, default: true} },
    data : function(){
      return {

      }
    },
    computed: {
      formatedItems(){
        if(this.items == null ) return [];
        let arr = Object.values(this.items).sort((a, b) => a.totalScore < b.totalScore ? 1 : -1);
        while (arr.length < 5) {
          arr.push({ totalScore: 0, divHeight: 0 });
        }
        let maxScore = arr[0].totalScore;
        let order = [3, 1, 0, 2, 4];
        let returnArray = [];
        order.forEach(i => {
          let divHeight = this.maxHeight * arr[i].totalScore / maxScore;
          let color = 'rgb(200, 200, 255)';
          if (i == 0) color = this.$root.medalColors.gold;
          if (i == 1) color = this.$root.medalColors.silver;
          if (i == 2) color = this.$root.medalColors.bronze;
          let displayOnTop = arr[i].name && arr[i].avatar && divHeight < 90 || !arr[i].name && arr[i].avatar && divHeight < 70 || divHeight < 40
          returnArray.push({ ...arr[i], divHeight, color, displayOnTop });
        });

        return returnArray;
      }
    },
    methods: {

    }
  }
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

</style>
