
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import './global.css';

window.Vue = require('vue');

// import VueAnimated from '@codekraft-studio/vue-animated'
// Vue.use(VueAnimated);

/**
*
* Vue Events bus
*
*/

window.eventBus = new Vue();

/**
* Vuetify
*/
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify);

/**
* Vue router
*/
import VueRouter from 'vue-router';

Vue.use(VueRouter);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('login-form', require('./components/login-form.vue').default);


//import Routes
import routes from './routes';


const app = new Vue({
  el: '#root',
  // errorCaptured: function (err) {
  //   axios.post('/api/jserror', {message: err.message, stack: err.stack})
  //   .then(resp => console.log(resp.data))
  //   .catch(err => console.log(err.response));
  //   return true;
  // },
  data:{
    pageTitle: '',
    status: 'guest',
    user: {email: '', images: {correct: '/images/tick.png', incorrect: '/images/cross.png', pending: '/images/qmark.png'}},
    toolbarColors: { activeSet: [{ position: 0, color: '#f5f5f5' }], maxNbColors: 1, availableColors: []},
    schools: [],
    adminSchools: [],
    classesData: {availablePhases: [], divisions: [], marathons: []},
    classesDataLoaded: false,
    classesEvents: [],
    announcements: [],
    overlay: '',
    trickId: 0,
    trackId: 0,
    sessionId: 0,
    trick: {},
    tricks: {},
    // challengeTricks: [],
    // challengeTitle: '',
    timeModifier: 0,
    options: {},
    focusedTrick: null,
    snackbar: {show: false, color: 'success', timeout: 3000, text: "It's working !"},
    loading: [],
    medalColors: {gold: '#F7D100', silver: '#DEDEDE', bronze: '#f79456', finished: '#1976D2'},
    medalGradients: {gold: 'background:linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);'},
    trickColors: {classic: '#C7CEEA', fraction: '#B5EAD7', algebra: '#FFB7B2', power: '#FFDAC1'},
    historyBot: 5,
    perfVerification: {}
  },
  created(){
    window.addEventListener('scroll', _.throttle(this.historyDisplay, 200));
    eventBus.$on('deleteAnnouncement', (id) => {
      this.announcements = this.announcements.filter(a => a.id != id);
    });
    eventBus.$on('tryTrick', (data) => {
      this.sessionId = Date.now();
      this.trickId = data.id;
      this.timeModifier = data.timeModifier;
      this.trickOverlay = 'try-a-trick';
    });
    // eventBus.$on('takeChallenge', (data) => {
    //   this.challengeTricks = data.tricks;
    //   this.trickOverlay = 'take-a-challenge';
    //   this.trickId = data.id;
    //   this.trackId = data.trackId;
    //   this.challengeTitle = data.challengeTitle;
    //   this.options = data.options;
    // });
    eventBus.$on('startPractice', (data) => {
      this.tricks = data.tricks;
      this.trickOverlay = 'class-practice';
      this.trickId = 99999;
      this.options = data.options;
    });
    eventBus.$on('loading', (el) => {
      switch(el.command){
        case 'clear':
          this.loading = [];
        break;
        case 'start':
          this.loading.push(el.name);
        break;
        case 'finish':
          if(this.loading.indexOf(el.name) > -1){
            this.loading.splice(this.loading.indexOf(el.name),1);
          }
        break;
      }
    });
    eventBus.$on('rootPerfVerification', val => this.perfVerification = val);
    eventBus.$on('divisionsProgress', progress => {
      progress.forEach(p => {
        const cls = this.classesData.divisions.find(cl => cl.id == p.id);
        console.log(p, cls);
        cls.terms = p.terms;
      });
    });
  },
  methods: {
    snackIt(snack){
      if(snack.text.length > 50){
        snack.text = snack.text.substring(snack.text, 47)+"..."
      }
      this.snackbar = {show: true, color: snack.color, timeout: snack.timeout, text: snack.text};
    },
    snackSuccess(text){
      this.snackIt({color: "success", timeout: 6000, text: text});
    },
    snackError(text){
      this.snackIt({color: "error", timeout: 3000, text: text});
    },
    snackWarning(text){
      this.snackIt({color: "warning", timeout: 3000, text: text});
    },
    snackInfo(text){
      this.snackIt({color: "info", timeout: 4000, text: text});
    },
    scoreToRating(score){
      if(isNaN(score)) return 0;
      if(score > 7000) return 5;
      if(score > 5500) return 4;
      if(score > 4000) return 3;
      if(score > 3000) return 2;
      if(score > 2000) return 1;
      return 0;
    },
    formatRunningTime(time){
      if(isNaN(time)) return '--';
      if(time == null) return '--';
      if(time < 60) return `${time}s`;
      if(time < 3600){
        let minutes = Math.floor(time/60);
        let seconds = Math.floor(time % 60);
        if(seconds < 10){
          return `${minutes}m0${seconds}s`;
        } else {
          return `${minutes}m${seconds}s`;
        }
      }
      if(time < 3600*24){
        let hours = Math.floor(time/3600);
        let minutes = Math.floor((time % 3600)/60);
        if(minutes < 10){
          return `${hours}h0${minutes}m`;
        } else {
          return `${hours}h${minutes}m`;
        }
      }
      let days = Math.floor(time/(3600*24));
      let hours = Math.floor((time % (3600*24))/3600);
      if(hours < 10){
        return `${days}d0${hours}h`;
      } else {
        return `${days}d${hours}h`;
      }
    },
    historyDisplay(){
      this.historyBot = (window.innerHeight + window.scrollY) >= document.body.offsetHeight-50 ? 48 : 0;
    },
    removeAccents(str){
        return str ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : '';
    }
  },
  router: new VueRouter(
    {
        mode: 'history',
        routes: routes.routes // TODO remove titles
    }
  )
});
