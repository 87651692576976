<template>
  <v-menu :close-on-content-click='false' offset-y left transition="scale-transition" origin="top right">
    <template v-slot:activator="{ on }">
      <div style='position:relative'>
        <div style='width:55px;display:flex;justify-content:center'>
          <v-progress-circular indeterminate :width="3" color='grey' v-if='loading'/>
          <div class='hoverClick' v-else>
            <img :src="$root.user.images.avatar" width='100%' v-on='on' @click='touched'>
          </div>
        </div>
        <v-card class='badge' flat :color='badgeDisplay.color' v-if='badgeDisplay.display && !loading'>
          {{ items.length < 10 ? items.length : "+" }}
        </v-card>
        <v-icon color='success' class='vbadge' v-if='!loading && perfVerification && (perfVerification.id || perfVerification.code)'>verified_user</v-icon>
      </div>
    </template>
    <div>
      <div>
        <verification-center :perfVerification='perfVerification' :verificationRequest='verificationRequest[0]'/>
        <verification-dialog/>
      </div>
      <!-- <div v-if='items.length == 0' class='grey--text text--lighten-2 title' style='width:350px;height:50px;display:flex;justify-content:center;align-items:center;background:white'>
        No new notification
      </div> -->
      <div>
        <v-card style='display:flex;justify-content:space-between;align-items:center;' class='pa-2 subheading grey--text' width='350px'>
          <span class="pl-2">
            Announcements
          </span>
          <span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-on="on" @click='showAllAnnouncements'>
                  <v-icon color='grey'>visibility</v-icon>
                </v-btn>
              </template>
              <span>Show announcements</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-on="on" @click='goToAnnouncementsPage'>
                  <v-icon color='grey'>open_in_new</v-icon>
                </v-btn>
              </template>
              <span>See announcements page</span>
            </v-tooltip>
          </span>
        </v-card>
        <v-card class='pa-2' width='350px'>
          <div class="subheading grey--text pl-2">
            Classes
          </div>
          <div class="px-3 subheading">
            <div class="mb-2" v-for="(cl, index) in classesScoresData" :key="cl.id">
              <v-divider class="mb-2" v-if="index != 0"/>
              <div style="display:flex;justify-content:space-between;align-items:center;" v-if="cl.activeTermNb">
                <div>
                  {{ cl.name }} (Term {{ cl.activeTermNb }})
                </div>
                <div>
                  {{ cl.score }}pts
                </div>
              </div>
              <div style="display:flex;" v-else>
                <div>
                  {{ cl.name }}
                </div>
                <div style="flex:1" class="grey--text text-xs-center">
                  -- No active term --
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <div style='margin-top:1px solid grey'>
        <v-hover v-for='item in items' :key='`${item.type}-${item.id}`'>
          <v-card slot-scope="{ hover }"  class='pa-2' width='350px' :color="hover ? 'grey lighten-4' : 'white'">
            <v-layout row wrap>
              <v-flex xs2 style='display:flex;align-items:center;justify-content:center'>
                <v-icon v-if='item.type=="challenge"'>sentiment_satisfied_alt</v-icon>
                <v-icon v-if='item.type=="race"'>directions_run</v-icon>
                <v-icon v-if='item.type=="hike"'>directions_walk</v-icon>
                <v-icon v-if='item.type=="verificationRequest"'>verified_user</v-icon>
              </v-flex>
              <v-flex xs10>
                <div style='display:flex;justify-content:space-between;align-items:flex-start'>
                  <span class='grey--text caption' v-if='item.type=="challenge"'>
                    Urgent challenge
                  </span>
                  <span class='grey--text caption' v-if='item.type=="race"'>
                    Active race
                  </span>
                  <span class='grey--text caption' v-if='item.type=="hike"'>
                    Open hike
                  </span>
                  <span class='grey--text caption' v-if='item.type=="verificationRequest"'>
                    Verification request
                  </span>
                  <v-card flat class='dot' :color='dotColor(item.expiryInSeconds)'/>
                </div>
                <div class='font-weight-bold subheading text-truncate'>
                  {{ item.title }}
                </div>
              </v-flex>
            </v-layout>
            <div v-if='hover && item.type=="challenge"'>
              <div style='text-align:center' class='grey--text text--darken-2 pa-1'>
                <i>Expires in <b>{{ item.expiryInSeconds | formatExpiry }}</b></i>
              </div>
            </div>
            <div v-if='hover && item.type=="race"'>
              <div style='text-align:center' class='grey--text text--darken-2 pa-1'>
                <i>Ends in <b>{{ item.expiryInSeconds | formatExpiry }}</b></i>
              </div>
              <v-btn block round flat to='/races'>
                Go to races page
                <v-icon style='display:inline-flex' class='ml-2'>directions_run</v-icon>
                <v-icon style='display:inline-flex' >chevron_right</v-icon>
              </v-btn>
            </div>
            <div v-if='hover && item.type=="hike"'>
              <div style='text-align:center' class='grey--text text--darken-2 pa-1'>
                <i><b>{{ item.openSections.length }}</b></i> section<span v-if="item.openSections.length>1">s</span> open
              </div>
              <v-btn block round flat to='/hikes'>
                Go to hikes page
                <v-icon style='display:inline-flex' class='ml-2'>directions_walk</v-icon>
                <v-icon style='display:inline-flex'>chevron_right</v-icon>
              </v-btn>
            </div>
            <div v-if='hover && item.type=="verificationRequest"'>
              <div style='display:flex'>
                <v-btn block round color='error' flat @click='answerVerificationRequest(false, item.id)'>
                  Reject
                  <v-icon style='display:inline-flex' class='ml-2'>cancel</v-icon>
                </v-btn>
                <v-btn block round color='success' flat @click='answerVerificationRequest(true, item.id)'>
                  Accept
                  <v-icon style='display:inline-flex' class='ml-2'>done</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-hover>
      </div>
    </div>
  </v-menu>
</template>
<script>
  import moment from 'moment-timezone';
  import Echo from 'laravel-echo';

  export default {
    props : {},
    data : function(){
      return {
        activeWindow: 0,
        badgeMsg: '1',
        items: [],
        challengeLoading: false,
        initial: true,
        loading: false,
        racePerfUpdate: [],
        perfVerification: {classes: [], code: null, expiry: 0},
        expiryTimer: null,
        verifyTimer: null,
        verifying: false,
        verificationRequest: [{}]
      }
    },
    computed:{
      badgeDisplay() {
        if(this.items.length == 0) return {display: false, color: 'grey'};
        if(this.items.some(n => n.expiryInSeconds != null & n.expiryInSeconds/3600 < 12 && n.new)) return {display: true, color: 'error'};
        if(this.items.some(n => n.new)) return {display: true, color: 'primary'};
        return {display: true, color: 'grey'};
      },
      classesScoresData(){
        return this.$root.classesData.divisions.map(cl => {
          const activeTerm = cl.terms.find(term => term.active);
          return {
            id: cl.id,
            name: cl.name,
            activeTermNb: activeTerm ? activeTerm.nb : null,
            score: activeTerm ? activeTerm.score : 0
          }
        });
      }
    },
    watch:{
      perfVerification(val){
        eventBus.$emit('rootPerfVerification', val);
      }
    },
    mounted(){
      this.loading = true;
      window.Pusher = require('pusher-js');
      window.Echo = new Echo({
          broadcaster: 'pusher',
          key: process.env.MIX_PUSHER_APP_KEY,
          cluster: process.env.MIX_PUSHER_APP_CLUSTER,
          encrypted: true
      });
      // window.Echo.connector.socket.on('error', (err) => {
      //   if (err.code === 419) {
      //     // Reconnect to the server
      //     echo.connector.connect();
      //   }
      // });
      window.Echo.private(`user.${this.$root.user.id}`).listen('perfVerificationUpdate', e => {
        this.updateVerifying(e.perfVerification);
      });
      window.Echo.private(`user.${this.$root.user.id}`).listen('verificationRequested', e => {
        this.handleVerificationRequest(e.verificationRequest);
      });
      this.refreshClassesData();
      eventBus.$on('refreshClassesData', this.refreshClassesData);
      eventBus.$on('updateVerifying', this.updateVerifying);
      eventBus.$on('verificationRequested', verificationRequest => this.verificationRequest = [verificationRequest]);
    },
    filters: {
      formatExpiry(time) {
        if(time == null) return '';
        return moment.duration(time,'seconds').humanize();
      }
    },
    methods: {
      refreshClassesData(){
        this.loading = true;
        axios.get('/api/classes', {})
        .then(resp => {
          this.$root.schools = resp.data.schools;
          this.$root.toolbarColors = resp.data.toolbarColors;
          eventBus.$emit('toolbarColorsUpdated', {});
          resp.data.schools.forEach(school => {
            if(school.pivot.admin && !this.$root.adminSchools.some(s => s.id == school.id)){
              this.$root.adminSchools.push(school);
            }
          });
          this.$root.announcements = resp.data.announcements;
          resp.data.notifications.forEach(n => {
            if(n.type == 'hike' || n.type == 'race') {
              if(!this.$root.classesEvents.find(el => el.type == n.type && el.id == n.id)){
                this.$root.classesEvents.unshift(n);
              }
            } else {
              this.addNotification(n, this.initial)
            }
          });
          this.initial = false;
          this.updateVerifying(resp.data.perfVerification);
          if(this.$root.status == 'teacher'){
            resp.data.verificationRequest.forEach(verificationRequest => {
              this.handleVerificationRequest(verificationRequest)
              this.addNotification(verificationRequest, this.initial)
            });
          } else if(resp.data.verificationRequest.length != 0){
            this.verificationRequest = resp.data.verificationRequest;
          }
          this.$root.classesData = resp.data;
          this.$root.classesDataLoaded = true;
          eventBus.$emit('updateClassesData', resp.data);
          resp.data.divisions.forEach(division => {
            window.Echo.private(`division.${division.id}`).listen('divisionActivity', e => {
              this.handleEvent(e);
            });
          });
          this.loading = false;
        }).catch(err => console.log(err));
      },
      answerVerificationRequest(decision, id){
        this.requestSent = true;
        axios.post(`/api/verify/${id}/${decision ? "grant" : "reject"}`, {})
        .then(resp => {
          if(decision){
            this.$root.snackSuccess("Verification confirmed");
            if(this.verifyTimer == null){
              this.checkVerificationStatus();
            }
            clearTimeout(this.verifyTimer);
            this.verifyTimer = setTimeout(this.checkVerificationStatus, 500);
          } else {
            this.$root.snackInfo("Request rejected");
          }
          this.items = this.items.filter(o => o.type != 'verificationRequest' || o.id != id);
          this.requestSent = false;
        }).catch(err => {
          this.$root.snackError("Something went wrong...");
          this.items = this.items.filter(o => o.type != 'verificationRequest' || o.id != id);
          console.log(err)
        });
      },
      handleVerificationRequest(verificationRequest){
        this.verificationRequest = verificationRequest;
        if(this.$root.status == 'teacher'){
          if(verificationRequest.beingDeleted){
            this.items = this.items.filter(o => o.type != 'verificationRequest' || o.id != verificationRequest.id);
          } else {
            this.items.unshift(verificationRequest);
          }
        } else if(verificationRequest.beingDeleted){
          this.checkVerificationStatus();
        }
      },
      updateVerifying(data){
        if(data && (Object.keys(data).length == 0 || data.beingDeleted)){
          if(this.verifyTimer == null){
            this.checkVerificationStatus();
          }
          clearTimeout(this.verifyTimer);
          this.verifyTimer = setTimeout(this.checkVerificationStatus, 500);
        } else {
          this.perfVerification = data;
        }
        if(this.perfVerification.expiry > 0){
          clearInterval(this.expiryTimer);
          this.expiryTimer = setInterval(()=> {
            this.perfVerification.expiry--;
            if(this.perfVerification.expiry <= 0){
              clearInterval(this.expiryTimer);
              this.checkVerificationStatus();
            }
          }, 1000);
        } else {
          clearInterval(this.expiryTimer);
        }
      },
      checkVerificationStatus(){
        axios.get(`/api/verify/check`, {})
        .then(resp => {
          this.updateVerifying(resp.data);
        }).catch(err => console.log(err));
      },
      handleEvent(e){
        switch (e.type) {
          case 'term':
            axios.get(`/api/classes/${e.divisionId}/terms`, {})
            .then(resp => {
              const division = this.$root.classesData.divisions.find(d => d.id == e.divisionId);
              division.terms = resp.data;
              // eventBus.$emit('newTerms', { id: e.divisionId, terms: resp.data });
            }).catch(err => console.log(err));
          break;
          case 'perf':
            axios.get(`/api/races/${e.raceId}/formatedProgress`, {})
            .then(resp => {
              eventBus.$emit('racePerf', {id: e.raceId, progress: resp.data});
            })
            .catch(err => console.log(err));
          break;
          case 'status':
            eventBus.$emit('raceStatus', e.raceId);
          break;
          case 'deleted':
            eventBus.$emit('raceDeleted', e.raceId);
          break;
        }
      },
      addNotification(notif, initial){
        let sameTypeNotifs = this.items.filter(o => o.type == notif.type);
        if(!sameTypeNotifs.some(n => n.id == notif.id)){
          if(initial){
            this.items.push(notif);
          } else {
            this.items.unshift(notif);
          }
        }
      },
      dotColor(time) {
        if(time == null) return 'rgba(0,0,0,0)';
        if(time/3600 < 12) return 'error';
        return 'primary';
      },
      touched(){
        this.items.forEach(item => item.new = false);
      },
      showAllAnnouncements(){
        eventBus.$emit('showAnnouncements', {});
      },
      goToAnnouncementsPage(){
        this.$router.push('/announcements');
      }
    }
  }
</script>

<style scoped>
  .badge {
    position:absolute;
    top:-3px;
    right:-3px;
    width:15px;
    height:15px;
    border-radius: 50%;
    font-size:12px;
    color: white;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .dot {
    width:8px;
    height:8px;
    border-radius: 50%;
    background-color: red;
  }
  .vbadge {
    position: absolute;
    bottom: -10px;
    right: -5px;
    transform: scale(.8);
    background: white;
    border-radius: 50%;
  }
  .hoverClick {
    cursor:pointer;
    width:45px;
    border-radius: 50%;
    overflow: hidden;
    display:flex;
  }
  .hoverClick:hover {
    width: 55px;
  }
  .hoverClick:active {
    width: 50px;
  }

</style>
