<template>
  <v-dialog v-model='handicapDialog' width='750px'>
    <v-card>
      <v-progress-linear height='3' indeterminate style='position:absolute;top:0' class='ma-0' v-if='loading'/>
      <v-card-title class='headline pb-0 justify-space-between'>
        <div>
          Individual time adjustments
        </div>
        <v-btn @click='calculateClassHandicap'>
          Calculate
          <v-icon right>rotate_left</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model='activeClass'>
          <v-tab v-for='singleClass in classesDetails' :key='singleClass.id'>
            {{ singleClass.name }}
            <v-card flat color='primary' class='badge mx-1' v-if='singleClass.isDirty'/>
          </v-tab>
          <v-tab-item v-for='singleClass in classesDetails' :key='singleClass.id'>
            <v-layout row wrap>
              <v-card :color='cardColor(student.handicap)' class='pa-1 ma-1' style='min-width:150px' v-for='student in singleClass.students' :key='student.id'>
                <div class='text-xs-center'>
                  {{ student. name }}
                </div>
                <v-edit-dialog :return-value.sync="student.handicap" large lazy @save='makeDirty(singleClass.id)'>
                <div class='pb-2 subheading font-weight-bold'>{{ student.handicap }}s</div>
                  <template v-slot:input>
                    <v-text-field v-model="student.handicap" type='number' min='0' max='45' label="Adjustment" single-line counter autofocus/>
                  </template>
                </v-edit-dialog>
              </v-card>
              <div class='overlay' v-if='loading'/>
            </v-layout>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn :disabled='loading' color="error" style='width:200px' flat @click="handicapDialog=false">Cancel</v-btn>
        <v-btn :disabled='loading' color="primary" style='width:200px' @click="submitHandicaps">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props : { classes: Array },
    data : function(){
      return {
        handicapDialog: false,
        loading: false,
        classesDetails: [],
        activeClass: null
      }
    },
    mounted() {
      eventBus.$on('showHandicapDialog', () => {
        this.handicapDialog = true;
        this.loading = true;
        axios.get(`/api/classes/classesHandicap/${this.classes.join()}`, {})
        .then(resp => {
          this.classesDetails = resp.data;
          this.activeClass = resp.data[0].id;
          this.loading = false;
        })
        .catch(err => console.log(err));
      });
    },
    methods: {
      makeDirty(id){
        let temp = this.classesDetails;
        this.classesDetails = [];
        temp.forEach(cl => {
          if(cl.id == id){
            cl.isDirty = true;
          }
          this.classesDetails.push(cl);
        });
      },
      cardColor(h){
        if(h == 0) return 'white';
        if(h >= 20) return 'primary lighten-3';
        if(h >= 10) return 'primary lighten-4';
        if(h >= 5) return 'primary lighten-5';
      },
      calculateClassHandicap(){
        this.loading = true;
        let classId = this.classesDetails[this.activeClass].id
        axios.get(`/api/classes/${classId}/classHandicap`, {})
        .then(resp => {
          let temp = this.classesDetails;
          this.classesDetails = [];
          temp.forEach(cl => {
            if(cl.id == classId){
              cl.students = resp.data;
              cl.isDirty = true;
            }
            this.classesDetails.push(cl);
          })
          this.loading = false;
        })
        .catch(err => console.log(err))
      },
      submitHandicaps(){
        this.loading = true;
        let classes = [];
        this.classesDetails.forEach(cl => {
          if(cl.isDirty){
            classes.push(cl);
          }
        });
        axios.post(`/api/classes/classesHandicap`, { classes })
        .then(resp => {
          this.$root.snackSuccess('Time adjustments applied')
          this.loading = false;
          this.handicapDialog = false;
        })
        .catch(err => console.log(err.message))
      }
    }
  }
</script>

<style scoped>
  .overlay {
    position: absolute;
    width: 100%;
    height:100%;
    background: rgba(255, 255, 255, 0.5);
  }
  .badge {
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }
</style>
