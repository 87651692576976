<template>
  <v-expansion-panel v-model='mainPanel'>
     <v-expansion-panel-content>
       <template v-slot:header>
         <div class='headline'>Class practice</div>
       </template>
       <v-card>
         <v-card-text>
           <v-container grid-list-lg class='pa-0'>
             <v-layout row wrap>
               <v-flex xs12 md8>
                 <v-card min-height='190'>
                   <v-tabs v-model='mainTabs'>
                     <v-tab href="#recommendations">Suggestions</v-tab>
                     <v-tab href="#search">Search</v-tab>
                     <v-tab-item value='recommendations'>
                       <v-card flat class='pa-2'>
                         <v-select
                           :items="recommendationsOptions"
                           label="Select a class"
                           outline
                           :loading="loadingRecommendations"
                           v-model='selectedClass'
                           @input='switchRecommendations'
                           >
                         </v-select>
                         <tracks-and-tricks-display :tracks='recommendedTracks' :tricks='recommendedTricks'></tracks-and-tricks-display>
                       </v-card>
                     </v-tab-item>
                     <v-tab-item value='search'>
                       <v-card flat class='pa-2'>
                         <v-text-field
                           label="Search for tricks or tracks"
                           append-icon="search"
                           v-model="searchedTitle"
                           outline clearable
                           @click:clear="clearTricksAndTracks"
                           @keyup="userTyping"
                           @keydown.enter="goSearch"
                           :loading='loadingTricks || loadingTracks'
                         >
                         </v-text-field>
                       <tracks-and-tricks-display :tracks='searchedTracks' :tricks='searchedTricks'></tracks-and-tricks-display>
                     </v-card>
                   </v-tab-item>
                 </v-tabs>
                </v-card>
                 <v-flex xs12 class='px-0'>
                  <v-card class='px-2'>
                    <v-card-title class='title grey--text font-weight-thin pb-0'>
                      Options
                    </v-card-title>
                    <v-card-text>
                      <v-layout row wrap>
                        <v-flex xs12 sm4 class='py-0 pr-3'>
                          <v-text-field type='number' min='1' max='10' v-model='options.nbOfQuestions' label="Nb of questions" @blur='checkNbQ'></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm4 class='py-0 pr-3' style='display:flex;align-items:center'>
                          <v-checkbox class='mt-0' label="Allow trick repetition" v-if='options.nbOfQuestions > selectedTricks.length' input-value="true" disabled></v-checkbox>
                          <v-checkbox class='mt-0' v-model="options.trickRepetition" label="Allow trick repetition" v-else></v-checkbox>
                        </v-flex>
                        <v-flex xs12 sm4 class='py-0' style='display:flex;align-items:center'>
                          <v-checkbox class='mt-0' v-model="options.secondSet" label="Make 2 sets of questions"></v-checkbox>
                        </v-flex>
                        <v-flex xs12 sm6 class='py-0 pr-3'>
                          <v-select hide-details :items='["One by one", "All at once"]' v-model='options.displayQuestions' label='Display questions'></v-select>
                        </v-flex>
                        <v-flex xs12 sm6 class='py-0' style='display:flex;align-items:center'>
                          <v-checkbox class='mt-0' v-model="options.autoScroll" label="Scroll down automatically" v-if='options.displayQuestions == "All at once"'></v-checkbox>
                        </v-flex>
                        <v-flex xs6 class='py-0' v-if='options.displayQuestions=="One by one" && orgStudents.length > 0'>
                          <v-checkbox class='pt-3' v-model="options.showStudent" label="Pick a student for each question"/>
                        </v-flex>
                        <v-flex xs6 style='display:flex;align-items:center' class='py-0' v-if='options.displayQuestions=="One by one" && orgStudents.length > 0'>
                          <span>
                            students of <b>{{ recommendationsOptions.find(el => el.value == selectedClass).text }}</b>
                          </span>
                          <v-select class='ml-2 pt-3' style='display:inline;max-width:120px' v-model="students" :items="orgStudents" multiple>
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index === 0">
                                <span>{{ students.length }}</span>&nbsp student<span v-if='students.length > 1'>s</span>
                              </v-chip>
                            </template>
                          </v-select>
                          <v-btn icon @click='restoreStudents'>
                            <v-icon>refresh</v-icon>
                          </v-btn>
                        </v-flex>
                        <v-flex xs12 sm6 class='pr-3' style='display:flex;align-items:center'>
                          <v-checkbox class='mt-0' v-model="options.autoStart" label="Start automatically"></v-checkbox>
                        </v-flex>
                        <v-flex xs12 sm6 style='display:flex;align-items:center'>
                          <v-checkbox class='mt-0' v-model="options.autoEnd" label="Show correction automatically"></v-checkbox>
                        </v-flex>
                        <v-flex xs12 class='py-1'>
                          <v-slider v-model="options.extraTime" label='Extra time per question' :min='-15' :max='30' always-dirty thumb-label="always">
                            <template v-slot:thumb-label="props">
                              <span>
                                {{ props.value >= 0 ? `+${props.value}s` : `${props.value}s` }}
                              </span>
                            </template>
                            <template v-slot:append>
                              <v-icon @click="options.extraTime = 0">
                                settings_backup_restore
                              </v-icon>
                            </template>
                          </v-slider>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
               </v-flex>
               <v-flex xs12 md4>
                 <v-card height='100%' max-height='885px'>
                   <v-btn icon :ripple='false' @click='clearSelected' style='position:absolute;right:0px' v-if='selectedTricks.length > 0'>
                     <v-icon color='red'>delete_forever</v-icon>
                   </v-btn>
                   <v-card-title class='title grey--text font-weight-thin'>
                     <span>
                       Selected tricks
                     </span>
                     <v-spacer></v-spacer>
                   </v-card-title>
                   <v-card-text class='px-1'>
                     <v-layout row wrap>
                       <v-flex xs12 class='py-0 px-1' v-for="(trick, index) in selectedTricks" :key="`affinity-${trick.id}-${index}`">
                         <v-chip @click='focusThisTrick(trick);'>
                           <v-avatar style='cursor:pointer' class='font-weight-bold white--text' :color='$root.trickColors[trick.type]'> {{ trick.type | initial }}</v-avatar>
                           <span style='cursor:pointer' class='pr-4' v-if="['xs', 'sm', 'md'].includes($vuetify.breakpoint.name)">
                             {{ trick.title | truncateTitleSm }}
                           </span>
                           <span style='cursor:pointer' class='pr-4' v-else>
                             {{ trick.title | truncateTitleLg }}
                           </span>
                           <v-btn icon style='position:absolute;right:-8px;top:-8px' @click.stop='removeFromSelected(trick)'>
                             <v-icon color='red'>remove</v-icon>
                           </v-btn>
                         </v-chip>
                         <span class='caption grey--text'>
                           ({{ timeForQ(trick) }}s)
                         </span>
                       </v-flex>
                     </v-layout>
                   </v-card-text>
                   <v-card-actions>
                     <v-btn block color='primary' @click='startPractice' v-if='selectedTricks.length > 0'>
                       Start !
                     </v-btn>
                   </v-card-actions>
                 </v-card>
               </v-flex>
             </v-layout>
           </v-container>
         </v-card-text>
       </v-card>
     </v-expansion-panel-content>
   </v-expansion-panel>
</template>
<script>
  export default {
    props : { classes: Array },
    data : function(){
      return {
        mainPanel: 1,
        mainTabs: 'recommendations',
        selectedTricks: [],
        options: {
          nbOfQuestions: 5,
          trickRepetition: true,
          secondSet: false,
          autoStart: false,
          autoEnd: false,
          autoScroll: true,
          showStudent: false,
          extraTime: 0,
          displayQuestions: 'One by one'
        },
        searchedTitle: '',
        searchedTricks: [],
        searchedTracks: [],
        recommendations: [],
        selectedClass: null,
        loadingRecommendations: false,
        loadingTricks: false,
        loadingTracks: false,
        recommendationsOptions: [],
        recommendedTricks: [],
        recommendedTracks: [],
        students: [],
        orgStudents: []
      }
    },
    mounted() {
      eventBus.$on('addTrick', this.addToSelected);
      eventBus.$on('grilledStudents', this.removeStudents);

    },
    beforeDestroy(){
      eventBus.$off('addTrick', this.addToSelected);
      eventBus.$off('grilledStudents', this.removeStudents);
    },
    watch: {
      mainPanel: {
        handler(val){
          if(val === 0){
            eventBus.$emit('classPracticePanelOpen', true);
          } else {
            eventBus.$emit('classPracticePanelOpen', false);
          }
        }
      },
      classes: {
        deep: true,
        immediate: true,
        handler(val){
          this.recommendationsOptions = [];
          val.forEach(cl => {
            this.recommendationsOptions.push({text: cl.name, value: cl.id});
          });
        }
      }
    },
    filters: {
      initial(val){
        return val.charAt(0).toUpperCase();
      },
      truncateTitleSm(value){
        if(value.length < 26){
          return value;
        } else {
          return value.substr(0,12)+'...'+value.substr(value.length-10,value.length-1);
        }
      } ,
      truncateTitleLg(value){
        if(value.length < 41){
          return value;
        } else {
          return value.substr(0,24)+'...'+value.substr(value.length-16,value.length-1);
        }
      }
    },
    methods: {
      restoreStudents(){
        this.students = this.orgStudents.map(s => s.value);
      },
      removeStudents(list){
        this.students = this.students.filter(s => list.filter(s2 => s2.value == s).length == 0);
      },
      userTyping(){
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this.searchTricks, 750);
      },
      goSearch(){
        clearTimeout(this.typingTimer);
        this.searchTricks();
      },
      searchTricks(){
        if(this.searchedTitle == ''){
          this.initTricks();
        } else {
          this.loadingTricks = true;
          let vm = this;
          axios.get(`/api/tricks/search/${this.searchedTitle}`, {})
          .then(function (response) {
            if(response.data.status == 'OK'){
              vm.searchedTricks = response.data.tricks;
            } else {
              vm.$root.snackError('Too many tricks. Be more specific.');
            }
            vm.loadingTricks = false;
          })
          .catch(function (error) {
            console.log(error);
          });
          this.loadingTracks = true;
          axios.get(`/api/tracks/search/${this.searchedTitle}`, {})
          .then(function (response) {
            vm.searchedTracks = response.data;
            vm.loadingTracks = false;
          })
          .catch(function (error) {
            console.log(error);
          });
        }
      },
      clearTricksAndTracks(){
        this.searchedTricks = [];
        this.searchedTracks = [];
      },
      addToSelected(trick){
        if(this.selectedTricks.length < 20){
          if(!_.find(this.selectedTricks, t => t.id == trick.id)){
            this.selectedTricks.push(trick);
          } else {
            this.$root.snackError("This trick is already selected");
          }
        } else {
          this.$root.snackError("Maximum tricks reached");
        }
      },
      removeFromSelected(trick){
        let tempArray = [...this.selectedTricks];
        setTimeout(()=> {
          this.selectedTricks = [];
          tempArray.forEach(t => {
            if(t.id != trick.id){
              this.selectedTricks.push(t);
            }
          });
        }, 1);
      },
      clearSelected(){
        this.selectedTricks = [];
      },
      focusThisTrick(trick){
        eventBus.$emit('focusTrick', trick);
      },
      switchRecommendations(){
        this.loadingRecommendations = true;
        axios.get(`/api/classes/${this.selectedClass}/recommendations`, {})
        .then(response => {
          this.recommendedTracks = Object.values(response.data.tracks);
          this.recommendedTricks = Object.values(response.data.tricks);
          this.students = response.data.students.map(s => s.value);
          this.orgStudents = response.data.students;
          this.loadingRecommendations = false;
        })
        .catch(err => console.log(err))
      },
      timeForQ(trick){
        let options = typeof trick.options == 'string' ? JSON.parse(trick.options) : trick.options;
        return Math.max(3, Math.floor(options.time/3 + this.options.extraTime));
      },
      checkNbQ(){
        if(this.options.nbOfQuestions > 10){
          this.options.nbOfQuestions = 10;
        }
      },
      startPractice(){
        if(this.options.nbOfQuestions > this.selectedTricks.length){
          this.options.trickRepetition = true;
        }
        let tricks = [];
        let counter = 0;
        let nbTricks = 0;
        while(nbTricks < this.options.nbOfQuestions){
          counter++;
          let newTrick = this.selectedTricks[Math.floor(Math.random()*this.selectedTricks.length)];
          let trickInArray = _.find(tricks, o => o.id == newTrick.id);
          if(trickInArray == undefined){
            newTrick.nb = 1;
            tricks.push(newTrick);
            nbTricks++;
          } else if (this.options.trickRepetition || counter > 1000){
            trickInArray.nb++;
            nbTricks++;
          }
        }
        this.options.students = this.orgStudents.filter(s => this.students.indexOf(s.value) >= 0).sort((a, b) => 0.5 - Math.random());
        eventBus.$emit('startPractice', {tricks, options: this.options});
      }
    }
  }
</script>
