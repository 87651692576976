<template>
  <v-layout row wrap>
    <v-flex xs12 md8>
      <v-layout style='display:flex;flex-wrap:wrap' :style='["xs", "sm"].includes(this.$vuetify.breakpoint.name) ? "flex-direction:column-reverse" : "flex-direction:row"'>
        <v-flex xs12 md6 v-if="bestPerfs[1]" class="text-xs-center" :class="marginTwo">
          <perf-display :hike='hike' :perf="{place: 2, score: bestPerfs[1].score, date: bestPerfs[1].created_at, new: bestPerfs[1].new}"/>
        </v-flex>
        <v-flex xs12 md6 v-if="bestPerfs[0]" class="text-xs-center">
          <perf-display :hike='hike' :perf="{place: 1, score: bestPerfs[0].score, date: bestPerfs[0].created_at, new: bestPerfs[0].new}"/>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 md4 v-if="bestPerfs[2]" class="text-xs-center" :class="marginThree">
      <perf-display :hike='hike' :perf="{place: 3, score: bestPerfs[2].score, date: bestPerfs[2].created_at, new: bestPerfs[2].new}"/>
    </v-flex>
  </v-layout>
</template>
<script>
  import moment from 'moment-timezone';

  export default {
    props : { bestPerfs: Array, hike: {type: Boolean, defaul: false} },
    data : function(){
      return {

      }
    },
    computed: {
      marginTwo(){
        return ["xs", "sm"].includes(this.$vuetify.breakpoint.name) ? "" : 'mt-3';
      },
      marginThree(){
        return ["xs", "sm"].includes(this.$vuetify.breakpoint.name) ? "" : 'mt-4';
      }
    },
    mounted(){

    }
  }
</script>
