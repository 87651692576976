<template>
  <v-dialog v-model="newTrackDialog" @keydown.esc="cancelNewTrack" @keydown.enter.prevent="submitNewTrack" max-width="500px">
    <v-btn slot="activator" fab dark color="primary" fixed bottom right><v-icon dark style='display:inline-flex'>add</v-icon></v-btn>
    <v-card>
      <v-card-title>
        <span class="headline">Make a new track</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-form ref="newTrackForm" v-model="valid" lazy-validation>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field label="Name" v-model="trackTitle" :rules="titleRules" autofocus clearable validate-on-blur></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea label="Track description" v-model="trackDescription" :rules="descrRules" :counter="255" class="mb-2"></v-textarea>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" style="width:140px" flat @click="cancelNewTrack">Cancel</v-btn>
        <v-btn color="primary" style="width:140px" @click="submitNewTrack">Create Track</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    data : function(){
      return {
        newTrackDialog: false,
        valid: false,
        trackTitle: '',
        trackDescription: '',
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 5) || 'The track title must be at least 5 characters long'
        ],
        descrRules: [
          v => (v && v.length <= 255) || 'The description must be less 255 characters long'
        ]
      }
    },
    methods : {
      cancelNewTrack(){
        this.$refs.newTrackForm.reset()
        this.newTrackDialog = false;
      },
      submitNewTrack(){
        if(this.$refs.newTrackForm.validate()){
          let vm = this;
          axios.post('/api/tracks', {
            title: vm.trackTitle,
            description: vm.trackDescription
          })
          .then(function (response) {
            eventBus.$emit('trackAdded', response.data);
            vm.trackTitle = '';
            vm.trackDescription = '';
            vm.newTrackDialog = false;
            vm.$root.snackSuccess("Track created !");
          })
          .catch(function (error) {
            console.log(error)
            vm.$root.snackError("There was an error while creating the track."); // TODO test that
          });
        }
      }
    }
  }
</script>
