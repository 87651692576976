<template>
  <div class='wrapper' :style='`padding: ${sizes.py}px ${sizes.px}px;border-radius:${sizes.bradius}px`' onclick="window.open('https://www.buymeacoffee.com/samscoffee', '_blank');">
    <v-img src="/images/Support Sam.png" :min-width="`${sizes.imgWidth}px`" :max-width="`${sizes.imgWidth}px`"/>
  </div>
</template>
<script>
  export default {
    props : { lg: Boolean },
    data : function(){
      return {
        //data here
      }
    },
    computed:{
      sizes(){
        return this.lg ? {px: 50, py: 20, imgWidth: 150, bradius: 20} : {px: 45, py: 5, imgWidth: 100, bradius: 15};
      }
    },
    mounted() {

    },
    methods: {

    }
  }
</script>

<style scoped>
  .wrapper {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all .5s;
    /* border-radius: 20px;
    padding: 20px 50px; */
    max-width: 275px;
    display:flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #272727;
    box-shadow: -5px -5px 5px rgba(92, 92, 92, 0.1),
                10px 10px 10px rgba(0, 0, 0, 0.4),
                inset -5px -5px 5px rgba(82, 82, 82, 0.2),
                inset 10px 10px 10px rgba(0, 0, 0, 0.4);
  }
  .wrapper:hover {
    background: #161616
  }
  .wrapper:hover:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    width: 40px;
    height: 100%;
    background: rgba(255,255,255, 0.3);
    transform: skewX(-30deg);
    animation-name: slide;
    animation-duration: .5s;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.5) 92%,
        rgba(255, 255, 255, 0.0) 100%
      );
  }
  @keyframes slide {
    0% {
      left: -100px;
      top: 0;
    }
    100% {
      left: 150%;
      top: 0;
    }
  }
</style>
