<template>
  <v-card width='100%' max-width='400px' class="pa-2">
    <v-layout row wrap>
      <v-layout row wrap fill-height>
        <v-flex xs3 v-for="(padrow, index) in padrows" :key="`padrow-${index}`">
          <v-layout column fill-height>
            <v-flex v-for="padkey in padrow" :key="padkey.digit" style="height:80px">
              <v-card ripple hover height="100%" width="100%" class="display-1 text-xs-center" @click="updateResult(padkey.digit, false)">
                <div style='display:flex;justify-content:center;align-items:center;height:100%' v-if="padkey.icon">
                  <v-icon>{{ padkey.icon }}</v-icon>
                </div>
                <div style='display:flex;justify-content:center;align-items:center;height:100%' v-else>
                  {{ padkey.digit }}
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-card>
</template>

<script>
var basicCodes = [
  {code : 109, char : '+/-'},
  {code : 107, char : '+/-'},
  {code : 189, char : '+/-'},
  {code : 187, char : '+/-'},
  {code : 8, char : 'backsp'},
  {code : 106, char : 'backsp'},
  {code : 111, char : 'clear'},
  {code : 13, char : 'submit'},
  {code : 37, char : 'go-left'},
  {code : 38, char : 'go-left'},
  {code : 39, char : 'go-right'},
  {code : 40, char : 'go-right' },
  {code : 81, char : 'go-left'},
  {code : 87, char : 'go-right'},
  {code : 32, char : 'cycle'},
]
for (var i=0; i<10 ; i++){
  basicCodes.push({code : 96+i, char : i});
  basicCodes.push({code : 48+i, char : i});
}

export default{
  data: function(){
    return {
      result : '',
      keycodes : basicCodes,
      padrows: [
        [{digit: '7'}, {digit: '4'}, {digit: '1'}, {digit: '0'}],
        [{digit: '8'}, {digit: '5'}, {digit: '2'}, {icon: 'arrow_back', digit: 'go-left'}],
        [{digit: '9'}, {digit: '6'}, {digit: '3'}, {icon: 'arrow_forward', digit:'go-right'}],
        [{icon: 'backspace', digit:'backsp'}, {icon: 'cancel', digit:'clear'}, {digit:'+/-'}, {icon: 'keyboard_return', digit:'submit'}]
      ],
    }
  },
  methods : {
    updateResult(event, fromKeyboard = true){
      if(fromKeyboard){
        event.preventDefault();
        if(_.find(this.keycodes, {code: event.keyCode})) {
          let pressedKeyCode = _.find(this.keycodes, {code: event.keyCode})
          eventBus.$emit('resultUpdate', pressedKeyCode.char);
        }
      } else {
        eventBus.$emit('resultUpdate', event);
      }
    }
  },
  computed : {
    formatedResult : function (){
      if (this.result.indexOf('.') != -1) {
        return addSpaces(this.result.split('.')[0])+"."+addSpaces(this.result.split('.')[1], false)
      } else if (this.result.indexOf('/') != -1) {
        return addSpaces(this.result.split('/')[0])+"/"+addSpaces(this.result.split('/')[1])
      } else {
        return addSpaces(this.result)
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.updateResult);
  },
  beforeDestroy(){
    window.removeEventListener('keydown', this.updateResult);
  }
}
</script>
