<template>
  <v-layout row wrap>
    <v-flex xs12 class='text-xs-center' style='min-height:350px;display:flex;justify-content:center;align-items:center' v-if='loading'>
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </v-flex>
    <v-flex xs12 md6>
      <canvas id='graphRaces' class='ma-2'></canvas>
    </v-flex>
    <v-flex xs12 md6 class='mt-3' v-if='!loading'>
      <medal-rack :rewardsCount="rewardsCount"></medal-rack>
    </v-flex>
  </v-layout>

</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        loading: false,
        rewardsCount: {},
        ctx: {},
        chart: {}
      }
    },
    mounted() {
      this.ctx = document.getElementById('graphRaces');
      this.chart = new Chart(this.ctx, {});
      eventBus.$on('racesPerfs', this.updateGraph);
    },
    beforeDestroy(){
      eventBus.$off('racesPerfs', this.updateGraph);
    },
    methods: {
      updateGraph(data){
        this.racesStats = [];
        if(data.userId > 0){
          this.loading = true;
          eventBus.$emit('racesLoading', true);
          let vm = this;
          axios.get(`/api/stats/${data.userId}/races/${data.divTerm.id}/${data.divTerm.term}`, {})
          .then(response => {
            vm.chart.destroy();
            vm.rewardsCount = response.data.rewardsCount;
            let racesStudentPerfs = [];
            let racesStudentRewards = [];
            let rewardBarColor = [];
            let racesTitles = [];
            let toolTipsText = [];
            response.data.races.forEach(race => {
              let newPerf, newReward, newColor, tooltip0Text, tooltip1Text;
              if(race.studentProgress){
                newPerf = race.studentProgress.nbOfStars/race.studentProgress.totalStars;
                tooltip0Text = `${race.studentProgress.nbOfStars}/${race.studentProgress.totalStars}`;
                switch(race.studentReward){
                  case 'gold':
                    newReward = 1;
                    newColor = 'rgba(255, 215, 0, 0.5)';
                    tooltip1Text = 'Gold';
                  break;
                  case 'silver':
                    newReward = 1;
                    newColor = 'rgba(192, 192, 192, 0.5)';
                    tooltip1Text = 'Silver';
                  break;
                  case 'bronze':
                    newReward = 1;
                    newColor = 'rgba(80, 50, 20, 0.5)';
                    tooltip1Text = 'Bronze';
                  break;
                  case 'finished':
                    newReward = 1;
                    newColor = 'rgba(76, 175, 80, 0.5)';
                    tooltip1Text = 'Finished';
                  break;
                  default:
                    newReward = 1;
                    newColor = 'rgba(255, 255, 255, 0.2)';
                    tooltip1Text = 'No reward';
                  break;
                }
              } else {
                newPerf = 0;
                tooltip0Text = 'No performance'
                tooltip0Text = 'No reward'
                newReward = 0;
                newColor = 'white';
              }
              racesStudentPerfs.push(newPerf);
              racesStudentRewards.push(newReward);
              rewardBarColor.push(newColor);
              racesTitles.push(race.title);
              toolTipsText.push([tooltip0Text, tooltip1Text]);
            });
            vm.ctx = document.getElementById('graphRaces');

            vm.chart = new Chart(vm.ctx, {
              type: 'bar',
              data: {
                labels: racesTitles,
                datasets: [
                  {
                    data: racesStudentPerfs,
                    type: 'line',
                    label: 'Races performances',
                    borderColor: 'rgba(99, 132, 255, 1)',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    tooltip: 'Youpi'
                  },
                  {
                    data: racesStudentRewards,
                    label: 'Races reward',
                    barPercentage: 1.25,
                    backgroundColor: rewardBarColor,
                    borderColor: rewardBarColor
                  },
                ]
              },
              options: {
                scales: {
                  yAxes: [{ticks: {beginAtZero: true}}]
                },
                legend: {display: false},
                tooltips: {
                  callbacks: {
                    label: (tooltipItem, data) => {
                      return toolTipsText[tooltipItem.index][tooltipItem.datasetIndex];
                    }
                  }
                }
              }
            });
            vm.loading = false;
            eventBus.$emit('racesLoading', false);
          })
          .catch(err => console.log(err));
        }
      }
    }
  }
</script>
