<template>
  <transition name='fly'>
    <span :key='text'>{{ text }}</span>
  </transition>
</template>
<script>
  export default {
    props : { text: [String, Number] },
  }
</script>

<style scoped>
  .fly-enter-active {
    animation: fly-in .5s;
  }
  .fly-leave-active {
    animation: fly-out .5s;
    position: absolute;
  }
  @keyframes fly-in {
    0% {
      transform: translateY(30px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fly-out {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-30px);
      opacity: 0;
    }
  }
</style>
