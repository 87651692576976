<template>
  <v-layout row wrap>
    <v-flex xs12 style='display:flex;justify-content:flex-end'>
      <v-text-field v-model="search" style='max-width:80%;width:460px;' append-icon="search" label="Trick title" clearable></v-text-field>
    </v-flex>
    <v-data-table
    :headers="starHeaders"
    :items="formatedPerfs"
    :search='search'
    :rows-per-page-items = "[25, 50, 100]"
    disable-initial-sort
    style='width:100%'
    >
      <template v-slot:items="props">
        <tr @click="props.expanded = !props.expanded">
          <td style='width:30px'>
            <v-tooltip top v-if='props.item.verifier != null'>
              <template v-slot:activator="{ on }">
                <v-icon v-on='on' color='success'>verified_user</v-icon>
              </template>
              <span>Verified by {{ props.item.verifier.name }}</span>
            </v-tooltip>
          </td>
          <td class='title pl-0'>{{ props.item.trickTitle }}</td>
          <td class="text-xs-center">{{ props.item.timeModifier | timeModifDisplay }}</td>
          <td class="text-xs-center">{{ props.item.score }}</td>
          <td class="text-xs-center title">
            <span v-if='props.item.increasedStarCountBy > 0'>
              <v-tooltip top :key='`tt-${props.item.id}`'>
                <template v-slot:activator="{ on }">
                  <span class='font-weight-bold orange--text' v-on="on">{{ props.item.rating }}<v-icon color='orange'>star</v-icon></span>
                </template>
                <span><b>Star Count Perf</b><br>Added {{ props.item.increasedStarCountBy }}<v-icon color='orange' small>star</v-icon> to your star count</span>
              </v-tooltip>
            </span>
            <span v-else>
              {{ props.item.rating }}<v-icon>star</v-icon>
            </span>
          </td>
          <td class="text-xs-center">{{ props.item.date | formatDate}}</td>
          <td class="text-xs-center">
            <span v-if="props.item.rhProgress">
              <v-tooltip top v-for="(race, index) in props.item.rhProgress" :key='`${race.type}-${race.id}`'>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" v-if='race.type == "race"'>
                    directions_run
                  </v-icon>
                  <v-icon v-on="on" v-else>
                    directions_walk
                  </v-icon>
                </template>
                <span>{{ race.title }}</span>
              </v-tooltip>
            </span>
            <span v-else>
              -
            </span>
          </td>
        </tr>
      </template>
      <template v-slot:expand="props">
        <v-card flat class='mb-3'>
          <answers-breakdown :attempt='props.item.attempt' :orgTrick='props.item.trick' v-if='props.item.attempt'></answers-breakdown>
          <div v-else class='grey--text text--lighten-2 title text-xs-center pa-4'>
            No details available
          </div>
        </v-card>
      </template>
      <template slot="no-data">
        <template v-if="empty">
          <v-layout style='justify-content:center' class='headline grey--text'>
            loading...
          </v-layout>
        </template>
        <template v-else>
          <v-layout style='justify-content:center' class='headline grey--text text--lighten-2'>
            No data available <v-icon color='grey lighten-2' class='ml-1' medium>sentiment_dissatisfied</v-icon>
          </v-layout>
        </template>
      </template>
    </v-data-table>
    <v-flex xs12 class='text-xs-right' v-if='loading'>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-flex>
  </v-layout>
</template>
<script>
  import moment from 'moment-timezone';

  export default {
    props : { perfs: Array, loading: Boolean, empty: Boolean },
    data : function(){
      return {
        search: '',
        starHeaders: [
          {text: '', width: '30px', align: 'center', value: 'verifier.id'},
          {text: 'Trick title', align: 'center', value: 'trickTitle'},
          {text: 'Time modifier', align: 'center', value: 'timeModifier'},
          {text: 'Score', align: 'center', value: 'score'},
          {text: 'Rating', align: 'center', value: 'rating'},
          {text: 'Date', align: 'center', value: 'date'},
          {text: 'Races and Hikes', align: 'center', value: 'rhProgress'}
        ],
        attempt: {}
      }
    },
    computed: {
      formatedPerfs(){
        let formatedPerfs = [];
        this.perfs.forEach(perf => {
          let racesAndHikes = [];
          perf.races.forEach(race => racesAndHikes.push({type: 'race', ...race}));
          perf.hikes.forEach(hike => racesAndHikes.push({type: 'hike', ...hike}));
          formatedPerfs.push({
            id: perf.id,
            verifier: perf.verifier,
            trickTitle: perf.trick.title,
            timeModifier: perf.timeModifier,
            score: perf.score,
            rating: this.$root.scoreToRating(perf.score),
            increasedStarCountBy: perf.increasedStarCountBy,
            date: perf.created_at,
            rhProgress: racesAndHikes,
            hikeProgress: perf.hikes,
            attempt: perf.attempt,
            trick: perf.trick,
            expanded: false
          });
        });

        return formatedPerfs;
      }
    },
    filters:{
      formatDate(date){
        return moment.tz(date, 'UTC').clone().tz(moment.tz.guess()).format('Do MMMM YYYY hh:mm A');
      },
      timeModifDisplay(time){
        return time == 0 ? '-' : `+${time}s`;
      }
    },
    mounted() {

    },
    methods: {

    }
  }
</script>
