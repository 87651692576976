<template>
  <v-dialog v-model="editRaceDialog"  fullscreen hide-overlay transition="slide-x-transition" @keydown.esc="initAndClose">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Race details</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip label color='black' dark v-if='racePhaseId > 0'>Marathon race</v-chip>
        <v-btn icon dark @click="initAndClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="py-3 px-5">
        <v-form ref="addRaceForm" v-model="valid" lazy-validation>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-text-field label="Title" v-model="title" :rules="titleRules" autofocus clearable validate-on-blur></v-text-field>
                <v-layout row wrap v-if='racePhaseId == 0'>
                  <v-flex xs10 md8 lg9 class='pr-2'>
                    <v-select v-model="raceClasses" :items="classes" chips deletable-chips label="Classes" multiple/>
                  </v-flex>
                  <v-flex xs2 md4 lg3>
                    <v-select height='42px' v-model="raceTerm" :items="[1,2,3,4,5,6,7,8,9,10]" label="Term"/>
                  </v-flex>
                </v-layout>
                <!-- <div v-if='racePhaseId == 0'>
                </div> -->
                <div v-else>
                  <v-select class='py-2' outline v-model="racePhaseId" :items="availablePhases" label="Phase"/>
                </div>
                <v-layout row wrap>
                  <v-flex xs12 class="caption grey--text">Options</v-flex>
                  <v-flex xs4 d-flex align-center>Stars to validate a trick</v-flex>
                  <v-flex xs2 class="display-1 font-weight-bold" d-flex align-center justify-center>
                    {{ options.trickValidation }}
                  </v-flex>
                  <v-flex xs6>
                    <v-slider v-model="options.trickValidation" min="1" max="5">
                      <template slot='prepend'>
                        <v-icon @click="options.trickValidation--">remove</v-icon>
                      </template>
                      <template slot='append'>
                        <v-icon @click="options.trickValidation++">add</v-icon>
                      </template>
                    </v-slider>
                  </v-flex>
                  <v-flex xs4 d-flex align-center>Tricks to finish the race</v-flex>
                  <v-flex xs2 class="display-1 font-weight-bold" d-flex align-center justify-center>
                    {{ options.trackValidation }}
                  </v-flex>
                  <v-flex xs6>
                    <v-slider v-model="options.trackValidation" min="1" :max="nbOfTricks">
                      <template slot='prepend'>
                        <v-icon @click="options.trackValidation--">remove</v-icon>
                      </template>
                      <template slot='append'>
                        <v-icon @click="options.trackValidation++">add</v-icon>
                      </template>
                    </v-slider>
                  </v-flex>
                  <v-flex xs4 d-flex align-center>Maximum extra time</v-flex>
                  <v-flex xs2 class="display-1 font-weight-bold" d-flex align-center justify-center>
                    +{{ options.timeModifier }}s
                  </v-flex>
                  <v-flex xs6>
                    <v-slider v-model="options.timeModifier" min="0" max="45">
                      <template slot='prepend'>
                        <v-icon @click="options.timeModifier--">remove</v-icon>
                      </template>
                      <template slot='append'>
                        <v-icon @click="options.timeModifier++">add</v-icon>
                      </template>
                    </v-slider>
                  </v-flex>
                  <v-flex xs12 style='display:flex;align-items:baseline;position:relative;'>
                    <span>
                      Individual extra time
                    </span>
                    <v-btn icon @click="showHandicapDialog" style='align-self:center'>
                      <v-icon color='primary'>help</v-icon>
                    </v-btn>
                    <v-select
                      solo dense hide-details
                      v-model="options.handicap"
                      :items="[{text: 'None', value: 0}, {text: '25%', value: '0.25'}, {text: '50%', value: '0.5'}, {text: '75%', value: '0.75'}, {text: '100%', value: '1'}]"
                      label="Apply"
                    />
                    <!-- <multiclass-handicap-dialog :classes='raceClasses'/> -->
                    <Transition name="slide-fade">
                      <v-card class='pa-3' width='250px' style='position:absolute;left:175px;top:-45px;background:white;z-index:10;cursor:pointer' v-if='showHandicapInfo' @click="showHandicapInfo=false">
                          <v-btn icon small @click="showHandicapInfo=false" style="position:absolute;top:0px;right:0px;">
                            <v-icon small color="red">close</v-icon>
                          </v-btn>
                          <div class='subheading grey--text'>Individual Extra Time</div>
                          <div>
                            You can allow students to use extra time on races trick. This helps students who are struggling to still be able to participate in the race.
                            <br/>
                            <span class='caption'>
                              To attribute the extra time allowance, navigate to the class' page and edit the "extra time column" in the class' table.
                            </span>
                          </div>
                        </v-card>
                    </Transition>
                  </v-flex>
                  <v-flex xs12 style='display:flex;align-items:center;position:relative' class='mt-2'>
                    <span class='pr-2'>
                      Performance verification
                    </span>
                    <v-btn icon @click="showVerificationInfo = !showVerificationInfo" style='align-self:center'>
                      <v-icon color='primary'>help</v-icon>
                    </v-btn>
                    <v-switch hide-details class='mt-0 pt-0' v-model='perfVerificationSwitch' :label='perfVerificationSwitch ? "Required" : "Optional"'/>
                    <Transition name="slide-fade">
                      <v-card class='pa-3' width='250px' style='position:absolute;left:205px;top:-45px;background:white;z-index:10;cursor:pointer' v-if='showVerificationInfo' @click='showVerificationInfo=false'>
                        <v-btn icon small @click="showVerificationInfo=false" style="position:absolute;top:0px;right:0px;">
                            <v-icon small color="red">close</v-icon>
                          </v-btn>
                        <div class='subheading grey--text'>Performance verification</div>
                        <div>
                          If required, students performances will have to be verified by you<span v-if='racePhaseId != 0'> or the marathon organiser</span>.
                          <br/>
                          <span class='caption'>
                            Verfication functionnalities can be found in the event menu (close this modal and click on your avatar at the top of the page).
                          </span>
                        </div>
                      </v-card>
                    </Transition>
                  </v-flex>
                  <v-flex xs12>
                    <v-expansion-panel v-if='perfVerificationSwitch'>
                      <v-expansion-panel-content>
                        <template v-slot:header>
                          <div class='caption grey--text' style='position:absolute;top:0px'>
                            Authorised verifiers
                          </div>
                          <div class='mt-2'>
                            <v-chip>You</v-chip>
                            <v-chip v-for='verifier in verifiers' :key='verifier.id'>
                              {{ verifier.name }}
                              <v-btn style='margin-right:-25px' small class='ml-2 mr-0' icon @click.stop='removeverifier(verifier)'>
                                <v-icon v-ripple>remove</v-icon>
                              </v-btn>
                            </v-chip>
                          </div>
                        </template>
                        <v-card class='px-3 py-1'>
                          <v-text-field
                            v-model='searchTeacher'
                            hide-details
                            :loading='searchLoading'
                            :disabled='searchLoading'
                            label='Search for teachers'
                            @keyup='typingSearch'
                          />
                          <v-checkbox
                            class='mt-0'
                            v-model='restrictSearch'
                            :disabled='searchLoading'
                            :label='`Restrict to your domain (${teacherDomain})`'
                            @change='typingSearch'
                          />
                          <div style='min-height:100px;max-height:80px;overflow-y:auto;'>
                            <v-chip v-for='teacher in teachersFound' :key='teacher.id'>
                              {{ teacher.name }}
                              <v-btn style='margin-right:-25px' small class='ml-2 mr-0' icon @click='addverifier(teacher)'>
                                <v-icon v-ripple>add</v-icon>
                              </v-btn>
                            </v-chip>
                          </div>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-flex>
                  <!-- <v-flex xs12 v-if='perfVerificationSwitch'>
                    <v-combobox v-model="verifiers" :items="teachers" auto-select-first chips deletable-chips multiple label="Verifiers"/>
                  </v-flex> -->
                  <v-flex xs12 class="caption grey--text mt-2">Medals</v-flex>
                  <v-layout row wrap>
                    <v-flex xs2>
                      <v-text-field v-model="options.gold" type="number" label="Gold" min="1" max="10"></v-text-field>
                    </v-flex>
                    <v-flex xs2></v-flex>
                    <v-flex xs2>
                      <v-text-field v-model="options.silver" type="number" label="Silver" min="1" max="10"></v-text-field>
                    </v-flex>
                    <v-flex xs2></v-flex>
                    <v-flex xs2>
                      <v-text-field v-model="options.bronze" type="number" label="Bronze" min="1" max="10"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm5>
                      <v-select v-model="options.rewardRule" :items="[{text: 'Fastest get gold', value: 'fastest'}, {text: 'First get gold', value: 'first'}]" label="Reward attribution"></v-select>
                    </v-flex>
                    <v-flex hidden-xs-only sm7 class='grey--text font-weight-thin pl-2' style='display:flex;align-items:center'>
                      {{ rewardRuleDescription }}
                    </v-flex>
                    <v-flex xs12>
                      <v-slider :thumb-size="24" thumb-label :label='`Skip penalty ${options.skipPenalty}%`' min='0' max='100' v-model='options.skipPenalty' v-if='options.rewardRule == "fastest"'/>
                    </v-flex>
                  </v-layout>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md5>
                    <v-menu v-model="menuStart" :close-on-content-click="false" :nudge-right="40" lazy transition="scale-transition" offset-y full-width min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :value="startFormated" label="Start date" append-icon="event" :rules="startRules" readonly clearable v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="start" @input="menuStart=false" :first-day-of-week="1"></v-date-picker>
                    </v-menu>
                    <v-text-field type='time' v-model="startTime" label="Start time" append-icon="access_time" :rules="startRules"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md2></v-flex>
                  <v-flex xs12 md5>
                    <v-menu v-model="menuFinish" :close-on-content-click="false" :nudge-right="40" lazy transition="scale-transition" offset-y full-width min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :value="finishFormated" label="Finish date" append-icon="event" :rules="finishRules" readonly clearable v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="finish" @input="menuFinish=false" :first-day-of-week="1"></v-date-picker>
                    </v-menu>
                    <v-text-field type='time' v-model="finishTime" label="Finish time" append-icon="access_time" :rules="finishRules"></v-text-field>
                    <!-- </v-menu> -->
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 md6>
                <track-search></track-search>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-5">
        <v-spacer></v-spacer>
        <v-btn color="error" style='width:200px' flat @click="initAndClose">Cancel</v-btn>
        <v-btn color="success" style='width:200px' @click="raceSubmit">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
  import moment from 'moment-timezone';

  function initialState(){
    return {
      editRaceDialog: false,
      raceId: null,
      title: '',
      valid: true,
      raceClasses: [],
      raceTerm: 1,
      racePhaseId: 0,
      track: {},
      options: {},
      SelectedTrackId: 0,
      nbOfTricks: 0,
      menuStart: false,
      menuStartTime: false,
      start: '',
      startTime: '',
      menuFinish: false,
      menuFinishTime: false,
      finish: '',
      finishTime: '',
      showVerificationInfo: false,
      showHandicapInfo: false,
      perfVerificationSwitch: false,
      searchTeacher: '',
      searchTimer: '',
      restrictSearch: false,
      searchLoading: false,
      teachersFound: [],
      verifiers: [],
      titleRules: [
        v => !!v || 'You must choose a title',
        v => (v && v.length >= 3) || 'The race title must be at least 3 characters long'
      ],
      startRules: [
        v => !!v || 'You must choose a start date and time'
      ],
      finishRules: [
        v => !!v || 'You must choose a fninsh date and time'
      ]
    }
  }
  export default {
    props:{ classes: Array, availablePhases: {type: Array, default: []} },
    data : function(){
      return initialState();
    },
    computed: {
      teacherDomain(){
        return `@${this.$root.user.email.split('@')[1]}`;
      },
      startFormated () {
        return this.start ? moment(this.start).format('Do MMMM YYYY') : '';
      },
      finishFormated () {
        return this.finish ? moment(this.finish).format('Do MMMM YYYY') : '';
      },
      rewardRuleDescription(){
        return this.options.rewardRule == 'fastest' ? 'Shortest running time gets best reward' : 'Earliest finish time gets best reward'
      },
      verifiersId(){
        return [this.$root.user.id, ...this.verifiers.map(o => o.id)];
      }
    },
    filters: {
      truncate(value){
        if(value.length < 80){
          return value;
        } else {
          return value.substr(0,77)+'...'
        }
      }
    },
    mounted() {
      let vm = this;
      eventBus.$on('editRace', function(race){
        vm.editRaceDialog = true;
        vm.raceId = race.id;
        vm.title = race.title;
        vm.start = race.start;
        vm.startTime = moment.tz(race.start, 'UTC').clone().tz(moment.tz.guess()).format('HH:mm');
        vm.raceTerm = race.term;
        vm.track = race.track;
        vm.trackId = race.track.id;
        vm.nbOfTricks = race.track.tricks.length;
        vm.options = JSON.parse(race.options);
        vm.finish = race.finish;
        vm.finishTime = moment.tz(race.finish, 'UTC').clone().tz(moment.tz.guess()).format('HH:mm');
        vm.raceClasses = [];
        vm.racePhaseId = race.phase_id == null ? 0 : race.phase_id;
        vm.perfVerificationSwitch = vm.options.verificationRequired.length > 0;
        vm.verifiers = vm.options.verificationRequired.filter(o => o.id != vm.$root.user.id);
        if(race.divisions.length){
          race.divisions.forEach(function(cl){
            vm.raceClasses.push(cl.id);
          });
        }
      });

      eventBus.$on('trackSelected', function(data){
        vm.trackId = data.id;
        vm.nbOfTricks = data.nbOfTricks;
        vm.options.trackValidation = Math.max(0, data.nbOfTricks - 2);
      });
    },
    methods : {
      typingSearch(){
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(this.proceedSearch, 500);
      },
      proceedSearch(){
        if(this.searchTeacher.length > 3){
          this.searchLoading = true;
          axios.get(`/api/races/verifiers/${this.searchTeacher}/${this.restrictSearch}/${JSON.stringify(this.verifiersId)}`, {})
          .then(resp => {
            this.teachersFound = resp.data;
            this.searchLoading = false;
          }).catch(err => console.log(err));
        }
      },
      addverifier(teacher){
        this.teachersFound = this.teachersFound.filter(o => o.id != teacher.id);
        if(!this.verifiers.some(o => o.id == teacher.id)){
          this.verifiers.push(teacher);
        }
      },
      removeverifier(teacher){
        this.verifiers = this.verifiers.filter(o => o.id != teacher.id);
        if(!this.teachersFound.some(o => o.id == teacher.id)){
          this.teachersFound.unshift(teacher);
        }
      },
      showHandicapDialog(){
        this.showHandicapInfo = !this.showHandicapInfo;
      },
      raceSubmit(){
        if(moment(this.start).isAfter(this.finish, 'day')){
          this.$root.snackError('The start date must be before the finish date');
          return;
        }

        let newStart = moment(this.start.split(' ')[0]+' '+this.startTime+':00').tz(moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm');
        let newFinish = moment(this.finish.split(' ')[0]+' '+this.finishTime+':00').tz(moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm');
        this.options.verificationRequired = this.perfVerificationSwitch ? [{id: this.$root.user.id, name: this.$root.user.name}, ...this.verifiers] : [];

        let vm = this;
        if(this.$refs.addRaceForm.validate() && this.trackId > 0){ // TODO give feedback if trackId == 0
          axios.patch(`/api/races/${this.raceId}`, {
            title: this.title,
            track: this.trackId,
            term: this.raceTerm,
            classes: this.raceClasses,
            phaseId: this.racePhaseId,
            options: JSON.stringify(this.options),
            start: newStart,
            finish: newFinish
          })
          .then(function (response) {
            eventBus.$emit('raceUpdated', response.data);
            vm.initAndClose();
            vm.$root.snackSuccess('Race updated.');
          })
          .catch(function (error) {
            if(error.response.data.errors.finish){
              vm.$root.snackError('The finish date can\'t be in the past');
            } else {
              vm.$root.snackError('Error ! Your race could not be updated');
            }
            console.log(error.response.data.errors);
          });
        } else {
          this.$root.snackError('Invalid data submitted');
        }
      },
      initAndClose(){
        this.editRaceDialog = false;
        this.$refs.addRaceForm.reset();
        Object.assign(this.$data, initialState());
        eventBus.$emit('raceUpdateCanceled', {});
      }
    }
  }
</script>

<style>
  .slide-fade-enter-active 
  .slide-fade-leave-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(-15px);
    opacity: 0;
  }
</style>
