<template>


</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        hikes: []
      }
    },
    mounted() {
      axios.get(`/api/classes/${vm.classId}/hikes` , {})
      .then(resp => {
        this.hikes = resp.data
      })
      .catch(err => console.log(err));
    },
    methods: {

    }
  }
</script>
