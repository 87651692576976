<template>
  <div>
    <div class='mt-3' style='display:flex;align-items:center'>
      <div class='headline grey--text'>Leaderboard</div>
      <v-select hide-details :loading='loading' :disabled="loading" :items="focusItems" v-model='focus' label="Focus" solo class='ml-3' @change='focusChange'/>
    </div>
    <div class='my-3' style='position:relative' v-if='focus != null'>
      <v-tabs color='#FAFAFA' v-model='activeTab'>
        <v-tab :key='0'>Students</v-tab>
        <v-tab :key='1'>Classes</v-tab>
      </v-tabs>
      <v-tabs-items v-model='activeTab'>
        <v-tab-item :key='0'>
          <v-layout row wrap class='mb-3'>
            <v-flex xs12 md8 class='mb-5'>
              <leaderboard-bargraph :items='students' :bigName='false' :students='true' :maxHeight='barGraphHeight' :showNames='showNames'/>
            </v-flex>
            <v-flex xs12 md4 class='px-1'>
              <student-marathon-perfs :you='you' :showing='showing' v-if='you'/>
              <teacher-marathon-students-perfs :students='teacher.students' :showNames='showNames' v-else/>
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item :key='1'>
          <v-layout row wrap>
            <v-flex xs12 md8 class='mb-4'>
              <leaderboard-bargraph :items='classes' :bigName='true' :students='false' :maxHeight='barGraphHeight' :showNames='true'/>
            </v-flex>
            <v-flex xs12 md4>
              <v-select class='mt-1' hide-details solo dense :loading='loading' :items="teacherClassesSelectItems" v-model='selectedClass' label="Class" @change='classChange' v-if='$root.status == "teacher"'/>
              <class-marathon-perfs :classData='classData' :showNames='showNames' :displayHeader='$root.status == "student"' v-if='classData'/>
            </v-flex>
          </v-layout>
        </v-tab-item>
      </v-tabs-items>
      <span style='position:absolute;top:-9px;right:0px;display:flex;align-items:center' v-if='marathon.editable'>
        <v-btn icon @click='showLeaderboardEditDialog'>
          <v-icon>edit</v-icon>
        </v-btn>
        <v-switch
          v-model="showNames"
          :label="$vuetify.breakpoint.name == 'xs' ? '' : 'Show Names'"
          v-if='$root.status == "teacher"'
        />
      </span>
    </div>
    <marathon-edit-leaderboard-dialog :students='students'/>
  </div>
</template>
<script>
  export default {
    props : { marathon: Object, activePhaseId: Number },
    data : function(){
      return {
        loading: false,
        activeTab: 0,
        focus: null,
        students: [],
        teacher: {students: [], classes: []},
        teacherClassesSelectItems: [],
        selectedClass: null,
        classes: [],
        classData: {},
        you: null,
        showing: 'none',
        barGraphHeight: 350,
        showNames: true,
      }
    },
    mounted(){
      eventBus.$on('editLeaderboardNames', this.editLeaderboardNames);
      if(this.activePhaseId){
        this.focus = `phase-${this.activePhaseId}`;
        this.focusChange();
      }
    },
    computed:{
      focusItems(){
        let items = [{header: this.marathon.title}, {text: 'Overall leaders', value: 'global'}];
        this.marathon.phases.forEach(ph => {
          items.push({divider: true});
          items.push({header: `${ph.title} phase`});
          items.push({text: `${ph.title} phase total`, value: `phase-${ph.id}`});
          ph.races.forEach(race => {
            items.push({text: `race : ${race.title}`, value: `race-${ph.id}-${race.id}`});
          });
        });

        return items;
      }
    },
    methods: {
      showLeaderboardEditDialog(){
        eventBus.$emit('showLeaderboardEditDialog', {});
      },
      editLeaderboardNames(changes){
        changes.forEach(st => {
          let student = this.students.find(o => o.id == st.id);
          if(student && st.name.length > 2){
            student.name = st.name;
          }
        })
      },
      classChange(){
        this.classData = this.teacher.classes.find(cl => cl.id == this.selectedClass);
      },
      focusChange(){
        let input = this.focus.split('-');
        this.showing = 'none';
        let path
        switch(input[0]){
          case 'global':
            path = `/api/marathons/${this.marathon.id}/leaderboard`;
            this.showing = 'global';
          break;
          case 'phase':
            path = `/api/marathons/${this.marathon.id}/phases/${input[1]}/leaderboard`;
            this.showing = 'phase';
          break;
          case 'race':
            path = `/api/marathons/${this.marathon.id}/phases/${input[1]}/races/${input[2]}/leaderboard`;
            this.showing = 'race';
          break;
        }
        this.loading = true;
        axios.get(path)
        .then(resp => {
          this.students = this.formatResponse(Object.values(resp.data.students), true);
          this.classes = this.formatResponse(Object.values(resp.data.divisions), false);
          this.teacher.students = Object.values(resp.data.myStudents);
          // this.teacher.classes = Object.values(resp.data.myDivisions);
          this.you = resp.data.you;
          this.teacher.classes = resp.data.myDivisions;
          this.teacherClassesSelectItems = resp.data.myDivisions.length == 0 ? [] : resp.data.myDivisions.map(d => ({text: d.name, value: d.id}));
          this.classData = resp.data.myDivisions[0];
          this.selectedClass = this.classData ? this.classData.id : null;
          this.loading = false;
        }).catch(err => console.log(err));
      },
      formatResponse(uarr, students){
        let arr = uarr.sort((a,b) => a.totalScore < b.totalScore ? 1 : -1);
        while(arr.length < 5){
          arr.push({totalScore: 0, divHeight: 0});
        }
        let maxScore = arr[0].totalScore;
        let order = [3,1,0,2,4];
        let returnArray = [];
        order.forEach(i => {
          let divHeight = arr[i].totalScore/maxScore;
          // let divHeight = this.barGraphHeight * arr[i].totalScore / maxScore;
          let color = 'rgb(200, 200, 255)';
          if(i == 0) color = this.$root.medalColors.gold;
          if(i == 1) color = this.$root.medalColors.silver;
          if(i == 2) color = this.$root.medalColors.bronze;
          let displayOnTop = arr[i].name && arr[i].avatar && divHeight < 90 || !arr[i].name && arr[i].avatar && divHeight < 70 || divHeight < 40
          returnArray.push({...arr[i], divHeight, color, displayOnTop});
        });

        return returnArray;
      }
    }
  }
</script>
