<template>
  <v-container>
    <v-layout row wrap>
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            label="Search for a title"
            append-icon="search"
            v-model="searchedTitle"
            outline clearable
            @keyup="userTyping"
            @click:clear="initTricks"
            @keydown.enter="goSearch"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 style='display:flex;justify-content:flex-start;flex-wrap:wrap'>
          <trick-card v-for="trick in tricks" :key="trick.id" :trick="trick"></trick-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if='displayPagination'>
        <v-flex xs12 class='text-xs-center mt-2'>
          <v-pagination v-model="page" :length="TotalNbPages" :total-visible="7"></v-pagination>
        </v-flex>
      </v-layout>

      <add-trick-fab v-if="$root.status == 'teacher'"></add-trick-fab>
      <edit-trick-dialog v-if="$root.status == 'teacher'"></edit-trick-dialog>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    props : [],
    data : function(){
      return {
        tricks : [],
        focusedTrick: null,
        focusedTrickSpacer: 0,
        searchedTitle: '',
        focusDialog: false,
        page: 1,
        TotalNbPages: 1,
        displayPagination: true
      }
    },
    created(){
      eventBus.$emit('loading', {command: 'clear'});
      eventBus.$emit('loading', {command: 'start', name: 'tricks-page'});
    },
    mounted() {
      let vm = this;
      this.initTricks();
      eventBus.$emit('loading', {command: 'finish', name: 'tricks-page'});
      eventBus.$on('trickAdded', (newTrick) => {
        this.tricks.unshift(newTrick);
      });
      eventBus.$on('trickUpdated', (newTrick) => {
        this.focusedTrick = null;
        let tempArray = this.tricks ;
        let vm = this
        this.tricks = [];
        tempArray.forEach(function(o){
          if (o.id == newTrick.id){
            vm.tricks.push(newTrick);
          } else {
            vm.tricks.push(o);
          }
        });
      });
      eventBus.$on('trickFocused', (id) => {
        if (this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm'){
          this.focusDialog = true;
        }
        this.focusedTrick = _.find(this.tricks, function(o){
          return o.id == id;
        });
      });
      eventBus.$on('trickArchived', (id) => {
        this.focusedTrick = null;
        let tempArray = this.tricks;
        let vm = this;
        this.tricks = [];
        tempArray.forEach(function(trick){
          if(trick.id != id){
            vm.tricks.push(trick);
          }
        });
      });
    },
    watch: {
      page: {
        handler(val){
          eventBus.$emit('loading', {command: 'start', name: 'tricks-new-page'});
          let vm = this;
          axios.get(`/api/tricks/page/${val}`, {})
          .then(function (response) {
            vm.TotalNbPages = response.data.nbPages;
            vm.tricks = response.data.tricks;
            eventBus.$emit('loading', {command: 'finish', name: 'tricks-new-page'});
          })
          .catch(function (error) {
            console.log(error); // TODO fix this
          });
        }
      }
    },
    methods : {
      userTyping(){
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this.searchTricks, 750);
      },
      goSearch(){
        clearTimeout(this.typingTimer);
        this.searchTricks();
      },
      searchTricks(){
        if(this.searchedTitle == ''){
          this.initTricks();
        } else {
          eventBus.$emit('loading', {command: 'start', name: 'tricks-search'});
          let vm = this;
          axios.get(`/api/tricks/search/${this.searchedTitle}`, {})
          .then(function (response) {
            if(response.data.status == 'OK'){
              vm.displayPagination = false;
              vm.tricks = response.data.tricks;
            } else {
              vm.$root.snackError('Too many tricks. Be more specific.');
            }
            eventBus.$emit('loading', {command: 'finish', name: 'tricks-search'});
          })
          .catch(function (error) {
            console.log(error);
          });
        }
      },
      initTricks(){
        let vm = this;
        eventBus.$emit('loading', {command: 'start', name: 'init-tricks'});
        axios.get('/api/tricks/page/1', {})
        .then(function (response) {
          vm.page = 1;
          vm.TotalNbPages = response.data.nbPages;
          vm.tricks = response.data.tricks;
          vm.displayPagination = true;
          eventBus.$emit('loading', {command: 'finish', name: 'init-tricks'});
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }
  }
</script>
