<template>
  <div
    style='border-radius:50%'
    :style='`width:${size}px;height:${size}px;${filling}`'
  />
</template>
<script>
  export default {
    props : { reward: String, raceClosed: {type: Boolean, default: false}, size: [Number, String] },
    data : function(){
      return {
        //data here
      }
    },
    computed:{
      filling(){
        if(this.reward){
          return `background:${this.$root.medalColors[this.reward]}`;
        }

        return `box-shadow: inset 0 0 5px ${this.raceClosed ? "#a2a6ad" : "#6693f4"}`;
      }
    },
    mounted() {

    },
    methods: {

    }
  }
</script>
