<template>
  <div>
    <div class='pa-1'>
      <span class='headline grey--text'>
        Race finished
      </span>
    </div>
    <v-layout>
      <v-flex xs8 class='pa-1 subheading'>
        <div>
          You finished the race<br/><b>{{ announcement.race.title }}</b>
        </div>
        <div class='my-2 samfont-bigtitle text-xs-center'>
          Well done !
        </div>
        <div v-if='announcement.race.reward != "finished"'>
          You earned a <b>{{ announcement.race.reward }} medal</b> !
        </div>
      </v-flex>
      <v-flex xs1/>
      <v-flex xs3 class='px-2'>
        <v-img :src='`/images/medal ${announcement.race.reward}.png`'/>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
  export default {
    props : { announcement: Object }
  }
</script>
