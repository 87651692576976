<template>
  <v-hover>
    <v-card hover slot-scope="{ hover }" height="415px" width="300px" style="margin:auto;position:relative;" router-link
      :to="`./single-class/${division.code}`">
      <v-layout fill-height column>
        <v-flex shrink>
          <v-img src="/images/Class logo.png"></v-img>
        </v-flex>
        <v-card-title class='py-2'>
          <span class="headline">{{ division.name }}</span>
          <v-spacer></v-spacer>
          <span class=" subheading grey--text darken-3" v-if="$root.status == 'teacher'">{{ division.code }}</span>
        </v-card-title>
        <v-card-text class="text-xs-left py-0">
          <v-layout row wrap>
            <v-flex xs12 class="grey--text subheading">
              Class stats
            </v-flex>
            <v-flex xs4 class="headline" style="display:flex;justify-content:center;align-items:center;">
              <lgnb-display :nb='division.stats.starCount' />
              <v-icon style="margin-top:-2px;">star</v-icon>
            </v-flex>
            <v-flex xs4 class="headline" style="display:flex;justify-content:center;align-items:center;">
              <lgnb-display :nb='division.stats.smileCount' />
              <v-icon style="margin-top:-2px;">sentiment_satisfied_alt</v-icon>
            </v-flex>
            <v-flex xs4 class="headline" style="display:flex;justify-content:center;align-items:center;">
              <lgnb-display :nb='division.stats.studentCount' />
              <v-icon style="margin-top:-2px;">person</v-icon>
            </v-flex>
          </v-layout>
          <div>
            <div class="mt-1" style="display:flex;justify-content:space-between;align-items:center;">
              <span class="grey--text subheading">
                <span v-if="$root.status == 'teacher'">
                  Class average
                </span>
                <span v-else>
                  Your score
                </span>
              </span>
              <v-btn icon @click.prevent.stop="showHelp = !showHelp" v-if="activeTerm">
                <v-icon color="grey">help</v-icon>
              </v-btn>
            </div>
            <div class="text-xs-center display-1 font-weight-bold" style="margin-top:-15px" v-if="activeTerm">
              <span class="ml-1">{{ activeTerm.score }}</span> pt<span v-if="activeTerm.score > 1">s</span>
            </div>
            <div class="text-xs-center display-1 grey--text mt-4" v-else>
              <v-icon color="grey lighten-2" x-large>sentiment_neutral</v-icon>
            </div>
            <div class="text-xs-center grey--text caption" v-if="activeTerm">
              {{ activeTerm.name }}
            </div>
          </div>
          <div class="grey--text subheading mt-3">
            All terms
          </div>
        </v-card-text>
        <div>
          <div style="display:flex;">
            <div style="width:30px;display:flex;justify-content:center;align-items:center;">
              <v-btn icon small @click.stop.prevent="goLeft" v-show="shift > 0">
                <v-icon small>chevron_left</v-icon>
              </v-btn>
            </div>
            <div style="overflow-x:hidden;min-width:240px;max-width:240px;">
              <div style="display:flex;transition:all .5s" :style='`transform:translateX(-${shift * 240}px)`'>
                <div style="min-width:80px;max-width:80px;" class="text-xs-center mb-1" v-for="term in division.terms"
                  :key="term.nb">
                  <div class="subheading grey--text font-weight-bold">
                    T{{ term.nb }}
                  </div>
                  <div class="subheading" style="white-space:nowrap;">
                    <lgnb-display :nb='term.score' style="margin-right:1px" />
                    pt<span v-if="term.score > 1">s</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="width:30px;display:flex;justify-content:center;align-items:center;">
              <v-btn icon small @click.stop.prevent="goRight"
                v-show="division.terms && division.terms.length > 3*(shift+1)">
                <v-icon small>chevron_right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div style="background-color: white;position:absolute;top:5px;right:5px;border-radius:28px;"
          v-if="$root.status == 'teacher' && hover">
          <v-btn class="ma-0" color="error" outline round small @click.prevent.stop="prepareArchive">
            Archive
            <v-icon style="margin-left:2px" small dark>remove_circle_outline</v-icon>
          </v-btn>
        </div>
      </v-layout>
      <div class="rulesOverlay">
        <Transition name="slide-fade">
          <v-card width="220" class="text-xs-left" v-if="showHelp" @click.prevent.stop="showHelp = false">
            <v-card-title>
              <div class="headline">
                Class points
              </div>
              <div class="caption">
                Points are earnt by participating in hikes and races throughout the term.
              </div>
            </v-card-title>
            <v-card-text class="pt-0" v-if="activeTerm">
              <div class="grey--text subheading">
                Rules for {{ activeTerm.name }}
              </div>
              <div>
                <div class='mb-1'>
                  Hike stars <v-icon small color='green'>stars</v-icon> : {{ activeTerm.rules.hikeStars }} pt<span
                    v-if='activeTerm.rules.hikeStars > 1'>s</span>
                </div>
                <div class='mb-1'>
                  Race stars <v-icon small color='orange'>star</v-icon> : {{ activeTerm.rules.raceStars }} pt<span
                    v-if='activeTerm.rules.raceStars > 1'>s</span>
                </div>
                <div class='mb-1'>
                  Race finished <v-icon small>directions_run</v-icon> : {{ activeTerm.rules.raceFinished }} pt<span
                    v-if='activeTerm.rules.raceFinished > 1'>s</span>
                </div>
                <div class='mb-1'>
                  Bronze medal <v-icon small :color='$root.medalColors.bronze'>radio_button_checked</v-icon> : {{
                  activeTerm.rules.bronze }} pt<span v-if='activeTerm.rules.bronze > 1'>s</span>
                </div>
                <div class='mb-1'>
                  Silver medal <v-icon small :color='$root.medalColors.silver'>radio_button_checked</v-icon> : {{
                  activeTerm.rules.silver }} pt<span v-if='activeTerm.rules.silver > 1'>s</span>
                </div>
                <div>
                  Gold medal <v-icon small :color='$root.medalColors.gold'>radio_button_checked</v-icon> : {{
                  activeTerm.rules.gold }} pt<span v-if='activeTerm.rules.gold > 1'>s</span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </Transition>
      </div>
    </v-card>
  </v-hover>
</template>
<script>
  export default {
    props : { division: Object },
    data : function(){
      return {
        shift: 0,
        showHelp: false
      }
    },
    computed: {
      activeTerm() {
        return this.division.terms.find(t => t.active);
      }
    },
    mounted() {

    },
    created() {
    },
    methods : {
      prepareArchive(){
        eventBus.$emit('prepareDivisionArchive', this.division);
      },
      goLeft() {
        this.shift = Math.max(0, this.shift - 1);
      },
      goRight() {
        this.shift = Math.min(Math.floor(this.division.terms.length/3), this.shift + 1);
      }
    }
  }
</script>
<style scoped>
  .rulesOverlay {
    position: absolute;
    top: 90px;
    left: 15px;
  }
  .slide-fade-enter-active {
  animation: slide-fade 0.5s ease-out;
  }
  .slide-fade-leave-active {
    animation: slide-fade 0.5s reverse ease-in;
  }
  @keyframes slide-fade {
    0% {
      opacity: 0;
      transform: translate(-50px,0px);
    }
    100% {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }
</style>
