<template>
    <div>
        <div class='samfont-bigtitle mb-2'>
            About Sam
        </div>
        <div class="subheading">
            Hi there ! If you want to learn more about Sam’s origins, you’re in the right place.<br/>
            First of all, allow me to introduce myself : I’m a mathematics teacher with a passion for coding. Making this app
            allowed me to use my passion to serve my job. Neat right ?<br/>
            I learned to code following tutorials online and have been developing web apps to practice what I learnt. Sam’s Tricks
            is my second large scale application. I started coding it in April 2019, tested it with my students and improved it a
            lot (thanks partly to lockdowns) in 2020 and 2021. It was finally released in September 2022. I keep working on it
            regularly, but I consider the app to be fully ready at this point.

        </div>
        <div class="my-2 samfont-bigtitle">
            Sam's FAQ
        </div>
        <v-expansion-panel>
            <v-expansion-panel-content v-for="(faq, index) in faqs" :key="index">
                <template v-slot:header>
                    <div class="font-weight-bold">{{ faq.q }}</div>
                </template>
                <v-card>
                    <v-card-text v-html="faq.a"/>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </div>
</template>

<script>
export default {
    props: {},
    data: function () {
        return {
            faqs: [
                {
                    q: 'How can I make the best of Sam’s Tricks ?',
                    a: `Obviously, you can use this app however you like !<br/>
                        That being said, I think you’d make the most progress by spending 10-15 minutes on it every day : Take the challenges, try a couple of tricks and you’re good. Your star count and smile count will rise gradually, allowing you to purchase collectibles and get a nice and shiny personalised Sam’s Tricks.<br/>
                        Also, regular practice is the best way to improve your mental calculation skills (which, after all, is the whole point of this app)`
                },
                {
                    q: 'I’m a teacher, how can I get started using Sam’s Tricks with my students ?',
                    a: `Sam’s Tricks was designed to be used in the classroom. To make the most of it, I would advise you to first familiarise yourself with the different tracks available. Select a few of them to set up a hike for your students. Show them the site and ask them to work on the hike on their own time.<br/>
                        Once your students have been introduced to the app, take half an hour of your class time every now and then (ideally every other week) to organise a race on one of the tracks from your hike. Be sure to set individual extra time to students who are struggling so that they don’t lose hope and abandon straight away.<br/>
                        If you feel brave, organise a marathon within your school and have classes compete with one another. I have been doing this for a couple of years in my school and the students are loving it !`
                },
                {
                    q: 'I am not sure of what to do, can I get help ?',
                    a: `Of course ! You can check out <a style="text-decoration:none;font-weight:bold;" href="https://www.youtube.com/channel/UCRb3VdGRwNmTuPgqcrIKaRA" target="_blank" rel="noopener noreferrer">Sam’s Tricks youtube channel</a>. It contains a few guides in English and French. I am currently working on updating and translating all the guides, so keep an eye out for new videos. If you’d like to ask specific questions, please feel free send me a message (link in the footer). I’d be happy to help you out any way I can.<br/> If you work at a school and would like to have a presentation for the maths team, I’d be happy to do that. We can do an online meet or you could have me fly to your place in business class. Whichever suits your budget better.`
                },
                {
                    q: 'Is Sam’s Tricks really entirely free ?',
                    a: `Absolutely ! The whole app is there for you to use for free.<br/> At this point, I usually get the follow up question : Then what are you getting out of this ?<br/>
                        Two things. First of all : satisfaction. I take great pride in knowing that something I coded is used every day by people all over the world.<br/>
                        Secondly : recognition. This is something I can put on my resume if I ever need to find a new job. So I’m not getting any money, but I get my ego boosted and I might, potentially, get a new exciting job one day. Worth it.<br/>
                        Obviously, There are still money matters : I did pay an artist to design the Sam character and I am paying every month for the web server. If you’d like to help me with that, you can <a style="text-decoration:none;font-weight:bold;" href="https://www.buymeacoffee.com/samscoffee" target="_blank" rel="noopener noreferrer">buy me a coffee</a>. It would be greatly appreciated !`
                },
            ]
        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>
