<template>
  <v-hover style="position:relative">
    <v-card :color='collectible.owned ? "#f5f5f5" : "grey lighten-2"' hover width='200px' slot-scope="{ hover }">
      <div class="pa-1" style='max-height:200px;min-height:200px;display:flex;align-items:center;position:relative'>
        <v-img :style='collectible.type=="avatar" ? "border-radius:50%" : ""' max-height='200px' contain :src='collectible.url'/>
        <v-chip dark small color='success' style='position:absolute;left:0px;bottom:-17px;' v-if='collectible.owned'>Owned</v-chip>
        <v-chip dark small color='primary' style='position:absolute;left:0px;bottom:-17px;' v-else-if='affordable'>Can purchase</v-chip>
        <v-chip dark small color='grey' style='position:absolute;left:0px;bottom:-17px;' v-else>Can't afford</v-chip>
      </div>
      <v-layout class='pa-3 text-xs-left' row wrap>
        <v-flex xs12 class='title pa-0' style='min-height:44px;max-height:44px;overflow-y:hidden;overflow-wrap:break-word'>
          {{ collectible.title }}
        </v-flex>
        <v-flex xs12 class='subheading grey--text pa-0' style='display:flex;justify-content:space-between'>
          <span>
            {{ formatedType.name }}
          </span>
          <span>
            <v-icon>{{ formatedType.icon }}</v-icon>
          </span>
        </v-flex>
        <v-flex xs12 class='subheading'>
          <b>Requires :</b> <span class='title' :class='collectible.affordable.stars ? "" : "error--text font-weight-bold"'>{{ collectible.stars }}</span> <v-icon color="orange" class='ma-0' style='vertical-align:text-bottom;font-size:24px'>star</v-icon>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 style='display:flex;justify-content:space-evenly;border-top:1px solid black;position:relative' class='my-2 pt-3 title'>
          <div style='position:absolute;top:-17px;left:15px;' :style='`background-color:${collectible.owned ? "#f5f5f5" : "#e0e0e0"}`'>
            <v-chip outline color='black' class='ma-0 font-weight-bold'>
              Price
            </v-chip>
          </div>
          <span class='mt-1' :class='collectible.affordable.smiles ? "" : "error--text font-weight-bold"'>
            {{ collectible.smiles }} <v-icon color="primary" class='ma-0' style='vertical-align:text-bottom;font-size:24px'>sentiment_satisfied_alt</v-icon>
          </span>
          <span class='mt-1' :class='collectible.affordable.coins ? "" : "error--text font-weight-bold"'>
            {{ collectible.coins }} <v-icon class='ma-0' style='vertical-align:text-bottom;font-size:24px'>radio_button_checked</v-icon>
          </span>
        </v-flex>
        <v-flex class="px-2" v-if="closable">
          <v-btn flat block round color="error" @click="closeCard">
            Close
          </v-btn>
        </v-flex>
      </v-layout>
      <div style='position:absolute;top:2px;right:2px;' class='mx-1' v-if='hover'>
        <v-btn color='primary' class='ma-0' round style='width:80px;' @click='openBuyDialog' v-if='affordable && !collectible.owned'>
          Buy
        </v-btn>
        <!-- <v-btn color='success' class='ma-0 font-weight-bold' round small style='width:80px;' @click='openSelectDialog' v-if='collectible.owned && !collectible.selected'>
          Select
        </v-btn> -->
      </div>
      <!-- <transition name="bounce">
        <v-btn icon class='ma-0' style='position:absolute;top:1px;left:0px' v-if='hover && collectible.selected' @click='openDeselectDialog'>
          <v-icon color='error' large>cancel</v-icon>
        </v-btn>
      </transition>
      <v-chip dark color='success' style='position:absolute;top:0px;right:0px;overflow-x:hidden;display:flex;justify-content:flex-end;' :style='`width:${hover ? "92px" : "32px"}`' v-if='collectible.selected'>
        <span style='margin-right:-4px;'>
          <span style='transition: all .4s' :style='`opacity:${hover ? "1" : "0"}`'>Selected</span>
          <v-icon small dark>done</v-icon>
        </span>
      </v-chip> -->
      <v-dialog min-width='350px' max-width='650px' v-model='buyDialog'>
        <v-card max-width='650px'>
          <v-layout row wrap>
            <v-flex xs12 sm6 lg4 style="margin:auto;" class="pl-1">
              <v-img :style='collectible.type=="avatar" ? "border-radius:50%" : ""' max-height='300px' contain :src='collectible.url'/>
            </v-flex>
            <v-flex xs12 sm6 lg8 class='pa-2' style='display:flex;flex-direction:column'>
              <div class='headline'>
                Purchase collectible
              </div>
              <div class='mb-3'>
                <div class='subheading' style='display:flex;justify-content:space-between'>
                  <span>
                    {{ collectible.title }}
                  </span>
                  <span>
                    {{ formatedType.name }}
                    <v-icon>{{ formatedType.icon }}</v-icon>
                  </span>
                  <span/>
                </div>
                <div class='caption grey--text'>
                  {{ formatedType.details }}
                </div>
              </div>
              <div class='title mb-3'>
                <b>Requires :</b> {{ collectible.stars }} <v-icon color="orange" class='ma-0' style='vertical-align:text-bottom;font-size:24px'>star</v-icon> <v-icon color='success' class='ml-3'>done</v-icon>
              </div>
              <v-layout row wrap style='max-width:300px;border-top:1px solid black' class='pt-2'>
                <v-flex xs4></v-flex>
                <v-flex xs4 class='text-xs-right'>
                  <v-icon color="primary" class='mr-2' medium>sentiment_satisfied_alt</v-icon>
                </v-flex>
                <v-flex xs4 class='text-xs-right'>
                  <v-icon class='mr-2' medium>radio_button_checked</v-icon>
                </v-flex>
                <v-flex xs4 class='title primary--text' style='white-space:nowrap;'>
                  Your funds
                </v-flex>
                <v-flex xs4 class='subheading primary--text text-xs-right'>
                  {{ $root.user.smile_count }}
                </v-flex>
                <v-flex xs4 class='subheading primary--text text-xs-right'>
                  {{ $root.user.coin_stack }}
                </v-flex>
                <v-flex xs4 class='title error--text'>
                  Price
                </v-flex>
                <v-flex xs4 class='subheading error--text text-xs-right'>
                  -{{ collectible.smiles }}
                </v-flex>
                <v-flex xs4 class='subheading error--text text-xs-right'>
                  -{{ collectible.coins }}
                </v-flex>
                <v-flex xs4 class='title font-weight-bold'>
                  Remaining
                </v-flex>
                <v-flex xs4 class='subheading font-weight-bold text-xs-right'>
                  {{ $root.user.smile_count - collectible.smiles }}
                </v-flex>
                <v-flex xs4 class='subheading font-weight-bold text-xs-right'>
                  {{ $root.user.coin_stack - collectible.coins }}
                </v-flex>
              </v-layout>
              <v-spacer/>
              <div style='display:flex;justify-content:flex-end;align-items:flex-end;'>
                <v-btn color="error" :disabled='loading' style='width:150px' flat @click="buyDialog = false">Cancel</v-btn>
                <v-btn color="primary" :loading='loading' style='width:150px' @click="submitBuy">Buy</v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <!-- <div class="ownedOvelay" v-if="collectible.owned || owned">
        <h3>OWNED</h3>
      </div> -->
    </v-card>
  </v-hover>
</template>
<script>
  export default {
    props : { collectible: Object, collectionId: {type: Number, default: 0}, closable: {type: Boolean, default: false} },
    data : function(){
      return {
        buyDialog: false,
        loading: false,
        selectDialog: false,
        deselectDialog: false,
        owned: false
      }
    },
    computed: {
      affordable(){
        return this.collectible.affordable.stars && this.collectible.affordable.smiles && this.collectible.affordable.coins;
      },
      formatedType() {
        switch (this.collectible.type) {
          case 'avatar':
            return {
              name: 'Avatar',
              icon: 'account_circle',
              details: 'This collectible can be used to replace your avatar.'
            }
            break;
          case 'correct':
            return {
              name: 'Correct answer',
              icon: 'done',
              details: 'This collectible can be used to replace the correct answer image during a trick.'
            }
            break;
          case 'incorrect':
            return {
              name: 'Incorrect answer',
              icon: 'close',
              details: 'This collectible can be used to replace the incorrect answer image during a trick.'
            }
            break;
          case 'pending':
            return {
              name: 'Pending answer',
              icon: 'help',
              details: 'This collectible can be used to replace the pending answer image during a trick.'
            }
            break;
          case 'upcoming':
            return {
              name: 'Upcoming race',
              icon: 'timer',
              details: 'This collectible can be used to replace the image on a race card for an upcoming race.'
            }
            break;
          case 'active':
            return {
              name: 'Active race',
              icon: 'directions_run',
              details: 'This collectible can be used to replace the image on a race card for an active race.'
            }
            break;
          case 'finished':
            return {
              name: 'Finished race',
              icon: 'emoji_people',
              details: 'This collectible can be used to replace the image on a race card for a race you have finished.'
            }
            break;
        }
      },
    },
    mounted() {

    },
    methods: {
      openBuyDialog(){
        this.buyDialog = true;
      },
      openSelectDialog(){
        this.selectDialog = true;
      },
      openDeselectDialog(){
        this.deselectDialog = true;
      },
      submitBuy(){
        this.loading = true;
        axios.post(`/api/collectibles/${this.collectible.id}/purchase`, {collectionId: this.collectionId})
        .then(resp => {
          if(resp.data.collection){
            eventBus.$emit('avatarPurchased', resp.data.collection);
          } else {
            eventBus.$emit('collectibleEdited', resp.data.collectible);
          }
          eventBus.$emit('smileCountUpdate', resp.data.fundsLost.smiles);
          eventBus.$emit('coinStackUpdate', resp.data.fundsLost.coins);
          this.owned = true;
          this.loading = false;
          this.buyDialog = false;
        }).catch(err => {
          console.log(err);
          this.$root.snackError(err.response.data.error);
        });
      },
      closeCard(){
        eventBus.$emit('closeCollectibleCard', {});
      }
    }
  }
</script>

<style scoped>
  /* .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .3s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .ownedOvelay {
    position:absolute;
    left:0px;
    top:0px;
    z-index:10;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display:flex;
    justify-content: center;
    align-items: center;
    
  }
  .ownedOvelay h3 {
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 5px;
    transform: rotate(-30deg);
  } */
</style>
