<template>
    <v-container>
        <v-layout row wrap>
            <v-flex xs12 style="display:flex;">
                <v-checkbox v-model="filterDraft" label="Unpublished only"/>
                <v-spacer/>
                <v-btn color="primary" :loading="creating" flat @click="moreRiddles">
                    10 more riddles
                    <v-icon right>playlist_add</v-icon>
                </v-btn>
                <v-dialog v-model="newRiddleDialog" width="380">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" flat v-on="on">
                            New riddle 
                            <v-icon right>add</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="headline">
                            Create a new riddle
                        </v-card-title>
                        <v-card-text>
                            <v-text-field label="Formula" v-model="formula" :counter="8"/>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn color="error" :disabled='loading' style='width:140px' flat @click="newRiddleDialog = false">Cancel</v-btn>
                            <v-btn color="primary" :loading='loading' style='width:140px' @click="createRiddle">Create</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-flex>
            <v-flex xs12 style="display:flex;flex-wrap:wrap;" v-if="filterDraft">
                <riddle-card class="mr-2 mb-2" :riddle="riddle" v-for="riddle in draftRiddles" :key="riddle.id"/>
            </v-flex>
            <v-flex xs12 style="display:flex;flex-wrap:wrap;" v-else>
                <riddle-card class="mr-2 mb-2" :riddle="riddle" v-for="riddle in riddles" :key="riddle.id"/>
            </v-flex>
        </v-layout>
        <v-dialog v-model="deleteRiddleDialog" width="350" v-if="focusedRiddle">
            <v-card>
                <v-card-title class="headline">
                    Are you sure ?
                </v-card-title>
                <v-card-text>
                    Do you want to delete this riddle permanently ?
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" style="width:120px" :disabled='loading' flat @click="deleteRiddleDialog = false">Cancel</v-btn>
                    <v-btn color="error" style="width:120px" :loading='loading' @click="proceedDelete">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- <edit-riddle-dialog/> -->
    </v-container>
</template>
<script>
export default {
    props: {},
    data: function () {
        return {
            riddles: [],
            newRiddleDialog: false,
            editRiddleDialog: false,
            deleteRiddleDialog: false,
            filterDraft: false,
            loading: false,
            creating: false,
            formula: '',
            editedFormula: '',
            formulaRules: [
                v => !!v || 'You must enter a formula',
                v => (v && v.length == 8) || 'The formula must be exactly 8 characters long'
            ],
            focusedRiddle: null
        }
    },
    computed: {
        draftRiddles(){
            return this.riddles.filter(r => r.published_on == null);
        }
    },
    created(){
        eventBus.$emit('loading', { command: 'clear' });
        eventBus.$emit('loading', { command: 'start', name: 'riddles-page' });
        this.initRiddles();
    },
    mounted() {
        eventBus.$on('deleteRiddle', this.deleteRiddle);
        eventBus.$on('updateRiddle', this.updateRiddle);
    },
    beforeDestroy() {
        eventBus.$off('deleteRiddle', this.deleteRiddle);
        eventBus.$off('updateRiddle', this.updateRiddle);
    },
    methods: {
        initRiddles(){
            axios.get('/api/admin/riddles', {})
            .then(resp => {
                this.riddles = resp.data;
                eventBus.$emit('loading', { command: 'finish', name: 'riddles-page' });
            }).catch(err => console.log(err));
        },
        createRiddle(){
            if(this.formula.length == 8){
                this.loading = true;
                axios.post('/api/admin/riddles', {formula: this.formula})
                .then(resp => {
                    this.riddles.unshift(resp.data);
                    this.loading = false;
                    this.newRiddleDialog = false;
                    this.formula = '';
                    this.$root.snackSuccess('Riddle created');
                }).catch(err => {
                    console.log(err);
                    console.log(err.response);
                    this.$root.snackError(err.response.data.message);
                    this.loading = false;
                });
            } else {
                this.$root.snackWarning('The formula has to be exactly 8 characters long');
            }
        },
        updateRiddle(riddle){
            this.riddles = this.riddles.map(r => r.id == riddle.id ? riddle : r);
        },
        deleteRiddle(riddle) {
            this.focusedRiddle = riddle;
            this.deleteRiddleDialog = true;
        },
        proceedDelete(){
            this.loading = true;
            axios.delete(`/api/admin/riddles/${this.focusedRiddle.id}`, {})
            .then(resp => {
                this.riddles = this.riddles.filter(r => r.id != this.focusedRiddle.id);
                this.focusedRiddle = null;
                this.deleteRiddleDialog = false;
                this.loading = false;
                this.$root.snackInfo('Riddle deleted');
            }).catch(err => console.log(err));
        },
        moreRiddles(){
            this.creating = true;
            axios.post('/api/admin/riddles/create', {})
            .then(resp => {
                this.riddles = resp.data.concat(this.riddles);
                this.$root.snackSuccess('Riddles created');
                this.creating = false;
            }).catch(err => console.log(err));
        }
    }
}
</script>
