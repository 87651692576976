<template>
  <v-layout row wrap>
    <v-flex xs12 class="grey--text subheading">
      Class stats
    </v-flex>
    <v-flex xs6 class="font-weight-bold headline text-xs-center">
      <lgnb-display :nb='stats.starCount'/>
      <v-icon>star</v-icon>
    </v-flex>
    <v-flex xs6 class="font-weight-bold headline text-xs-center">
      <lgnb-display :nb='stats.smileCount'/>
      <v-icon>sentiment_satisfied_alt</v-icon>
    </v-flex>
    <!-- <v-flex xs6 class="mb-3">
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <div class="font-weight-bold headline">{{ stats.studentCount }}<br><v-icon v-on="on">person</v-icon></div>
        </template>
        <span>Students</span>
      </v-tooltip>
    </v-flex>
    <v-flex xs6>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <div class="font-weight-bold headline">{{ stats.raceCount }}<br><v-icon v-on="on">directions_run</v-icon></div>
        </template>
        <span>Active races</span>
      </v-tooltip>
    </v-flex>
    <v-flex xs6>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <div class="font-weight-bold headline"><lgnb-display :nb='stats.starCount'/><br><v-icon v-on="on">star</v-icon></div>
        </template>
        <span>Total star count</span>
      </v-tooltip>
    </v-flex>
    <v-flex xs6>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <div class="font-weight-bold headline"><lgnb-display :nb='stats.smileCount'/><br><v-icon v-on="on">sentiment_satisfied_alt</v-icon></div>
        </template>
        <span>Total smile count</span>
      </v-tooltip>
    </v-flex> -->
  </v-layout>
</template>

<script>
  export default {
    props : { stats: Object}
  }
</script>

vcomp
