<template>
  <v-chip label :color="difficultyColor" text-color="white" v-if='size == "standard"'>
    {{ difficulty }}
  </v-chip>
  <div style='width:8px;height:8px;border-radius:50%;' :style='`background-color:${difficultyColor}`' v-else></div>

  <!-- <v-tooltip top v-else>
      <template v-slot:activator="{ on }">
        <div style='width:8px;height:8px;border-radius:50%;' :style='`background-color:${difficultyColor}`' v-on="on"></div>
      </template>
      <span>{{ difficulty }}</span>
    </v-tooltip> -->
</template>
<script>
  export default {
    props : {
      difficulty : String,
      size : {type: String, default: 'standard'}},
    data : function(){
      return {
        colors: {
          'very easy': 'grey',
          'easy': 'green',
          'good': 'blue',
          'difficult': 'orange',
          'very difficult': 'red',
        }
      }
    },
    computed: {
      difficultyColor(){
        return this.colors[this.difficulty];
      }
    }
  }
</script>
