<template>
  <transition name='merge'>
    <span :key='text'>{{ text }}</span>
  </transition>
</template>
<script>
  export default {
    props : { text: [String, Number] },
  }
</script>

<style scoped>
  .merge-enter-active {
    animation: merge-in .5s;
  }
  .merge-leave-active {
    animation: merge-in .5s reverse;
    position: absolute;
  }
  @keyframes merge-in {
    0% {
      transform: scale(0.2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
</style>
