<template>
  <v-card flat class='pa-2'>
    <v-layout>
      <span>
        <v-card class='qnb body-2 my-1' dark color='primary' v-for='index in 10' :key='index'>
          {{ index }}
        </v-card>
      </span>
      <span>
        <div
          v-for='(question, index) in questions' :key='index'
          style='font-size:16px;height:30px;display:flex;align-items:center;'
          class='ma-1'
          v-html='question.formated'
        />
      </span>
      <span style='flex:1'>
        <div class='ma-1' style='display:flex;position:relative;width:100%' v-for='(answer, index) in answers' :key='index'>
          <v-card
            dark flat
            height='30px'
            class='pr-2'
            style='display:flex;align-items:center;justify-content:flex-end'
            :style='`flex:${answer.score}`'
            color='primary'
            v-if='answer.score > 0'
          >
            {{ answer.score }}
          </v-card>
          <v-card dark flat height='30px' color='primary' style='opacity:0.2' :style='`flex:${1000-answer.score}`'/>
          <v-hover
            style='position:absolute;top:3px;overflow:visible;'
            :style='`left:${Math.round(ans.score/10)}%;margin-left:${10-Math.round(ans.score/100)}px`'
            v-for='ans in answer.answers' :key='ans.score'
          >
          <div style='padding-top:2px;' slot-scope="{ hover }" v-if='ans.isCorrection'>
            <span style='font-size:16px;' v-html='ans.html' />
          </div>
          <div style='max-width:24px;' slot-scope="{ hover }" v-else>
            <v-tooltip :nudge-bottom='8' top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" :color='ans.score == 0 ? "grey" : "success"' style='cursor:pointer' :style='`opacity:${hover ? 1 : 0.8};`' v-if='ans.correct'>check_circle</v-icon>
                <v-icon v-on="on" color='error' style='cursor:pointer' :style='`opacity:${hover ? 1 : 0.5};`' v-else>cancel</v-icon>
              </template>
              <span v-html='ans.html'/>
            </v-tooltip>
          </div>
          </v-hover>
        </div>
      </span>
    </v-layout>
  </v-card>
</template>
<script>
  import trick from '../../tricks';

  export default {
    props : { attempt: Object, orgTrick: Object },
    data : function(){
      return {
        trick: {},
      }
    },
    watch: {
      orgTrick:{
        handler: function(val){
          if(val.formula != undefined && val.options != undefined){
            let formula = typeof val.formula === 'object' && val.formula !== null ? val.formula : JSON.parse(val.formula);
            let options = typeof val.options === 'object' && val.options !== null ? val.options : JSON.parse(val.options);
            this.trick = new trick(val.title, val.image_description, val.text_description, val.type, formula, options);
          }
        },
        immediate: true
      }
    },
    computed:{
      questions(){
        return this.attempt.questions ? this.trick.displayQuestions(10, JSON.parse(this.attempt.questions)) : [];
      },
      answers(){
        if(this.attempt.answers == undefined) return [];
        let answers = JSON.parse(this.attempt.answers);
        answers.forEach(answer => {
          answer.score = 0;
          answer.answers.forEach(ans => {
            ans.html = ans.value == null ? '--' : this.trick.formatAnswer(ans.value, ans.isCorrection);
            if(ans.correct){
              answer.score = ans.score;
            }
          });
        });

        return answers;
      }
    },
    methods: {

    }
  }
</script>

<style scoped>
  .qnb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
