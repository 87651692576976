<template>
  <v-container fill-height>
    <v-layout row wrap class='text-xs-center' align-center>
      <v-flex xs12>
        <v-flex class="display-2 font-weight-thin mb-4">Oops, 404</v-flex>
        <v-btn large :ripple="false" color="primary" dark @click='goHome'>Go home</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data : function(){
    return {
      redirectUrl: '/app/home'
    }
  },
  created() {
    if(this.$root.status == 'guest'){
      this.redirectUrl = '/';
    }
  },
  methods:{
    goHome(){
      window.location.replace('/');
    }
  }
}
</script>
