<template>
  <v-hover>
    <v-card slot-scope="{ hover }">
      <v-data-table hide-headers hide-actions :items="hike.tracks" no-data-text='No track added yet'>
        <template v-slot:items="props">
          <td class='subheading'>{{ props.item.title }}</td>
          <td class='px-0'>
            <division-toggle
            :hikeId='hike.id'
            :trackId='props.item.id'
            :divisionId='0'
            :openInit='props.item.openClasses.length > 0'
            :marathonId='hike.marathon_id'
            :editable='editable'
            />
          </td>
        </template>
      </v-data-table>
      <v-card-actions :style='hover ? "max-height:64px" : "max-height:0px"' style='transition: all .3s;overflow-y:hidden' class='py-0'>
        <div style='display:flex;justify-content:flex-end;width:100%' class='py-2'>
          <v-btn round flat to='/hikes'>
            Go to hikes page
            <v-icon style='display:inline-flex' class='ml-2'>directions_walk</v-icon>
            <v-icon style='display:inline-flex'>chevron_right</v-icon>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>
<script>
  export default {
    props : { hike: Object, editable: Boolean },
    data : function(){
      return {

      }
    },
    mounted() {

    },
    methods: {

    }
  }
</script>
