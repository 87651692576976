<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs12 class='mb-2' style='display:flex;justify-content:space-between;align-items:center'>
        <span>
          <span style='display:flex;align-items:flex-start'>
            <v-menu bottom right offset-y>
              <template v-slot:activator="{ on }">
                <v-btn large icon v-on="on">
                  <v-icon large>expand_more</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-tile @click='goBackToClasses'>
                  <v-list-tile-avatar>
                    <v-icon>chevron_left</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title class='title'>
                    Back to classes
                  </v-list-tile-title>
                </v-list-tile>
                <v-divider/>
                <v-list-tile v-for='marathon in userMarathons' :key='marathon.id' @click="gotoMarathon(marathon.id)">
                  <v-list-tile-avatar>
                    <v-icon :color='statusColor(marathon.status)'>emoji_events</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title style='display:flex;justify-content:space-between'>
                    <span class='title mr-5'>
                      {{ marathon.title }}
                    </span>
                    <span class='caption grey--text ml-5'>
                      {{ marathon.attribution }}
                    </span>
                  </v-list-tile-title>
                </v-list-tile>
                <v-divider />
                <v-list-tile @click='goAllMarathons'>
                  <v-list-tile-avatar>
                    <v-icon>list</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title class='title'>
                    See all marathons
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
            <div class='text-xs-left display-1 mt-2'>
                {{ marathon.title }}
            </div>
          </span>
        </span>
        <span class='title grey--text font-weight-thin' style='margin-left:-40px' v-if='marathon.editable'>
          {{ marathon.code }}
        </span>
        <span style='display:flex'>
          <v-card class='text-uppercase' width='150px' height='50px' style='display:flex;align-items:center;justify-content:center' :color='currentStatusColor' dark>
            {{ marathon.status }}
          </v-card>
          <v-menu offset-y v-if='marathon.editable'>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile @click="editMarathonDialog = true">
                <v-list-tile-avatar><v-icon color="primary">edit</v-icon></v-list-tile-avatar>
                <v-list-tile-title>Edit marathon</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="unlaunchMarathon" v-if='["active", "finished"].includes(marathon.status)'>
                <v-list-tile-avatar><v-icon color="warning">unpublished</v-icon></v-list-tile-avatar>
                <v-list-tile-title>Unpublish marathon</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="launchMarathon" v-if='["draft", "finished"].includes(marathon.status)'>
                <v-list-tile-avatar><v-icon color="success">emoji_events</v-icon></v-list-tile-avatar>
                <v-list-tile-title>Launch marathon</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="terminateMarathon" v-if='["active", "draft"].includes(marathon.status)'>
                <v-list-tile-avatar><v-icon color="info">outlined_flag</v-icon></v-list-tile-avatar>
                <v-list-tile-title>Terminate marathon</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </span>
      </v-flex>
      <v-flex xs12 class="text-xs-left">
        <div style="max-width:45%;margin-left:60px;margin-top:-20px" class="grey--text">
          {{ marathon.description }}
        </div>
      </v-flex>
      <v-flex xs12>
        <!-- <marathon-timeline :phase="activePhaseData"/> -->
      </v-flex>
      <v-flex xs12 sm12 md8 class='text-xs-left'>
        <div style='display:flex;justify-content:space-between;align-items:center' class='mt-2'>
          <span class='headline grey--text my-1' style='display:flex;align-items:center'>
            Classes
          </span>
          <span v-if='$root.status == "teacher"'>
            <v-btn class='my-0' small @click='editClassesDialog = true'>
              Edit classes
              <v-icon small right style='display:inline-flex'>edit</v-icon>
            </v-btn>
            <v-btn class='my-0' small @click='goSeeClasses'>
              See classes results
              <v-icon small right style='display:inline-flex'>chevron_right</v-icon>
            </v-btn>
          </span>
        </div>
        <div>
          <v-chip
            v-for='division in marathon.divisions' :key='division.id'
            :color='divisions.find(cl => cl.id == division.id) ? "primary" : "grey lighten-2"'
            :dark='divisions.find(cl => cl.id == division.id) != undefined'
          >
            {{ division.name }}
          </v-chip>
        </div>
        <marathon-leaderboard :marathon='marathon' :activePhaseId="activePhaseId" :key='marathon.id'/>
      </v-flex>
      <v-flex xs12 md4 class='px-2 text-xs-left'>
        <div class='mb-3 mt-2'>
          <div class='headline grey--text pa-2' style="margin-top:-4px">
            Hike
          </div>
          <marathon-hike-card :hike='hike' :editable='marathon.editable'/>
        </div>
        <div style='display:flex;justify-content:flex-start;align-items:baseline;flex-wrap:wrap'>
          <span class='headline grey--text mb-1 px-2'>
            Phases
          </span>
          <v-btn icon dark small color='primary' @click='addPhaseDialog = true' v-if='marathon.editable'>
            <v-icon small dark style='display:inline-flex'>add</v-icon>
          </v-btn>
        </div>
        <phase-card
          v-for='phase in marathon.phases' :key='phase.id'
          :phase='phase'
          :editable='marathon.editable'
          :loading='phasesLoading'
          :last='phase.order == marathon.phases.length'
          :activable='marathon.status == "active"'
          :availablePhases='marathon.phases'
        />
        <v-btn block flat color='primary' @click='goRanking' v-if='marathon.editable'>
          See global ranking
          <v-icon>chevron_right</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-dialog width='400px' v-model='addPhaseDialog'>
      <v-card>
        <v-card-title class='headline'>
          Add a phase
        </v-card-title>
        <v-card-text>
          <v-text-field label="Title" v-model="newPhase.title" :rules="titleRules" autofocus clearable validate-on-blur/>
          <v-text-field label="Description (optional)" v-model="newPhase.description" clearable/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="error" :disabled='loading' style="width:140px" flat @click="addPhaseDialog=false">Cancel</v-btn>
          <v-btn color="primary" :loading='loading' style="width:140px"  @click="addPhase">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model='editMarathonDialog' width="400px">
      <v-card>
        <v-card-title class='headline' style='display:flex;justify-content:space-between'>
          Edit title and description
        </v-card-title>
        <v-card-text>
          <v-text-field label="Title" v-model="marathon.title" :rules="titleRules" autofocus clearable validate-on-blur/>
          <v-text-field label="Description" maxlength="200" v-model="marathon.description" autofocus clearable validate-on-blur/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="error" :disabled='loading' style="width:140px" flat @click="editMarathonDialog=false">Cancel</v-btn>
          <v-btn color="primary" :loading='loading' style="width:140px"  @click="editMarathon">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width='650px' v-model='editClassesDialog'>
      <v-card>
        <v-card-title class='headline'>
          Edit classes
        </v-card-title>
        <v-card-text>
          <div class='title grey--text' v-if='marathon.editable'>Your classes</div>
          <v-data-table :headers='editClassesHeaders' :items='divisions'>
            <template v-slot:items="props">
              <td>
                <v-checkbox class='mt-3 pt-1' v-model='marathonClasses' :value='props.item.id'/>
              </td>
              <td>{{ props.item.name }}</td>
              <td>
                <v-edit-dialog :return-value.sync="props.item.displayName">
                   {{ props.item.displayName }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.displayName"
                      label="Edit"
                      single-line
                      counter
                    />
                  </template>
                </v-edit-dialog>
              </td>
              <td class='text-xs-center'>{{ props.item.actualNbStudents }}</td>
              <td class='text-xs-center'>
                <v-edit-dialog :return-value.sync="props.item.nbStudents">
                  <span :class='props.item.actualNbStudents > props.item.nbStudents ? "error--text font-weight-bold" : ""'>
                    {{ props.item.nbStudents }}
                  </span>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.nbStudents"
                      type='nunber'
                      min=1 max=100
                      label="Edit"
                      single-line
                      counter
                    />
                  </template>
                </v-edit-dialog>
              </td>
            </template>
          </v-data-table>
          <div class='title grey--text mt-2' v-if='marathon.editable'>Other teacher's classes</div>
          <div style='display:flex;flex-wrap:wrap;' v-if='marathon.editable'>
            <v-data-table :headers='editClassesHeaders' :items='otherTeachersClasses'>
              <template v-slot:items="props">
                <td>
                  <v-checkbox class='mt-3 pt-1' v-model='marathonOtherClasses' :value='props.item.id'/>
                </td>
                <td>{{ props.item.name }}</td>
                <td>
                  <v-edit-dialog :return-value.sync="props.item.pivot.display_name">
                     {{ props.item.pivot.display_name }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.pivot.display_name"
                        label="Edit"
                        single-line
                        counter
                      />
                    </template>
                  </v-edit-dialog>
                </td>
                <td class='text-xs-center'>{{ props.item.actualNbStudents }}</td>
                <td class='text-xs-center'>
                  <v-edit-dialog :return-value.sync="props.item.pivot.nb_students">
                    <span :class='props.item.actualNbStudents > props.item.pivot.nb_students ? "error--text font-weight-bold" : ""'>
                      {{ props.item.pivot.nb_students }}
                    </span>
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.pivot.nb_students"
                        type='nunber'
                        min=1 max=100
                        label="Edit"
                        single-line
                        counter
                      />
                    </template>
                  </v-edit-dialog>
                </td>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="error" :disabled='loading' style="width:140px" flat @click="cancelEditClasses">Cancel</v-btn>
          <v-btn color="primary" :loading='loading' style="width:140px"  @click="submitEditClasses">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-race-dialog :classes='[]' :availablePhases='marathon.phases' v-if="$root.status == 'teacher'"/>
    <!-- <race-perfs-dialog/> -->
    <add-race-fab :classes='[]' :showButton="false" :availablePhases='marathon.phases' v-if="$root.status == 'teacher'"/>
    <div class='loadingOverlay' v-if='loadingOverlay'/>
  </v-container>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        marathon: {phases: []},
        hike: {},
        divisions: [],
        marathonClasses: [],
        marathonOtherClasses: [],
        otherTeachersClasses: [],
        activePhaseId: null,
        // activePhaseData: null,
        currentLeaderboard: null,
        editClassesDialog: false,
        addPhaseDialog: false,
        editMarathonDialog: false,
        newPhase: {title: '', description: ''},
        loading: false,
        phasesLoading: false,
        // checkpointsLoading: false,
        // leaderboardLoading: false,
        loadingOverlay: false,
        editClassesHeaders: [
          {text: 'Join', value: '', sortable: false},
          {text: 'Name', value: 'name'},
          {text: 'Display Name', value: 'pivot.display_name'},
          {text: 'Nb students', value: 'actualNbStudents'},
          {text: 'Nb declared', value: 'pivot.nb_students'}
        ],
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 5) || 'The title must be at least 5 characters long'
        ],
        nbStudentsRules: [
          v => !isNaN(v) || 'Pick a valid nb of students',
          v => v > 0 || 'Pick a valid nb of students',
          v => v < 251 || 'Pick a valid nb of students'
        ]
      }
    },
    computed: {
      currentStatusColor(){
        if(this.marathon.status == 'draft') return 'grey';
        if(this.marathon.status == 'active') return 'success';
        if(this.marathon.status == 'finsihed') return 'primary';
      },
      userMarathons(){
        return this.$root.classesData.marathons.filter(m => m.id != this.marathon.id);
      }
    },
    created(){
      this.initData();
    },
    mounted() {
      eventBus.$on('phaseActivation', this.phaseActivation);
      eventBus.$on('phaseMove', this.phaseMove);
      eventBus.$on('phaseEdit', this.phaseEdit);
      eventBus.$on('deletePhase', this.deletePhase);
    },
    beforeDestroy(){
      eventBus.$off('phaseActivation', this.phaseActivation);
      eventBus.$off('phaseMove', this.phaseMove);
      eventBus.$off('phaseEdit', this.phaseEdit);
      eventBus.$off('deletePhase', this.deletePhase);
    },
    methods: {
      initData(){
        eventBus.$emit('loading', {command: 'clear'});
        eventBus.$emit('loading', {command: 'start', name: 'marathon'});
        this.loadingOverlay = true;
        axios.get(`/api/marathons/${this.$route.params.id}`, {})
        .then(resp => {
          this.marathon = resp.data.marathon;
          this.hike = resp.data.marathon.hike;
          this.marathon.phases.forEach(ph => {
            ph.rules = JSON.parse(ph.rules);
            if(ph.active){
              this.activePhaseId = ph.id;
            }
          })
          // if(this.activePhaseId){
            // this.checkpointsLoading = true;
            // this.leaderboardLoading = true;
            // axios.get(`/api/marathons/${this.$route.params.id}/phases/${this.activePhaseId}/checkpoints`, {})
            //   .then(response => {
            //     this.activePhaseData = resp.data;
            //     console.log(response.data);
            //     this.checkpointsLoading = false;
            //   }).catch(error => console.log(error));
            // axios.get(`/api/marathons/${this.$route.params.id}/phases/${this.activePhaseId}/leaderboard`, {})
            //   .then(response => {
            //     this.currentLeaderboard = resp.data;
            //     console.log(response.data);
            //     this.leaderboardLoading = false;
            //   }).catch(error => console.log(error));
          // }
          this.divisions = resp.data.divisions;
          this.marathonClasses = resp.data.marathonClasses;
          this.otherTeachersClasses = this.marathon.divisions.filter(cl => this.divisions.find(o => o.id == cl.id) == undefined);
          this.marathonOtherClasses = [];
          this.otherTeachersClasses.forEach(o => this.marathonOtherClasses.push(o.id));
          this.loadingOverlay = false;
          eventBus.$emit('loading', {command: 'finish', name: 'marathon'});
        }).catch(err => console.log(err));
      },
      gotoMarathon(id){
        this.$router.push(`/marathons/${id}`);
        this.initData();
      },
      goBackToClasses(){
        this.$router.push('/classes');
      },
      goAllMarathons() {
        this.$router.push(`/marathons-all`);
      },
      statusColor(status){
        if(status == 'draft') return 'grey';
        if(status == 'active') return 'success';
        return 'black';
      },
      editMarathon(){
        this.loading = true;
        axios.patch(`/api/marathons/${this.marathon.id}`, {title : this.marathon.title, description: this.marathon.description})
        .then(resp => {
          this.loading = false;
          this.editMarathonDialog = false;
          this.$root.snackSuccess("Marathon updated");
        }).catch(err => console.log(err));
      },
      goSeeClasses(){
        this.$router.push(`/marathons/${this.$route.params.id}/classes`);
      },
      goRanking(){
        this.$router.push(`/marathons/${this.$route.params.id}/ranking`);
      },
      submitTitleAndDescription(){
        this.loading = true;
        axios.patch(`/api/marathons/${this.marathon.id}/titleAndDescription`, {title : this.marathon.title, description: this.marathon.description})
        .then(resp => {
          this.loading = false;
          this.editTitleAndDescriptionDialog = false;
          this.$root.snackSuccess("Title and description updated");
        }).catch(err => console.log(err));
      },
      cancelEditClasses(){
        this.editClassesDialog = false;
      },
      submitEditClasses(){
        this.loading = true;
        let classes = this.divisions.map(cl => ({id: cl.id, nbStudents: cl.nbStudents, displayName: cl.displayName, selected: this.marathonClasses.includes(cl.id)}));
        let otherClasses = this.otherTeachersClasses.map(cl => ({id: cl.id, nbStudents: cl.pivot.nb_students, displayName: cl.pivot.display_name, selected: this.marathonOtherClasses.includes(cl.id)}));
        axios.post(`/api/marathons/${this.marathon.id}/divisions/edit`, {divisions: [...classes, ...otherClasses]})
        .then(resp => {
          this.marathon.divisions = resp.data;
          this.otherTeachersClasses = this.marathon.divisions.filter(cl => this.divisions.find(o => o.id == cl.id) == undefined);
          this.marathonOtherClasses = [];
          this.otherTeachersClasses.forEach(o => this.marathonOtherClasses.push(o.id));
          this.loading = false;
          this.editClassesDialog = false;
        }).catch(err => console.log(err));
      },
      launchMarathon(){
        this.loading = true;
        axios.post(`/api/marathons/${this.marathon.id}/launch`, {})
        .then(resp => {
          this.marathon.status = 'active';
          this.loading = false;
          eventBus.$emit('refreshClassesData', {});
        }).catch(err => console.log(err));
      },
      unlaunchMarathon(){
        this.loading = true;
        axios.post(`/api/marathons/${this.marathon.id}/unlaunch`, {})
        .then(resp => {
          this.marathon.status = 'draft';
          this.marathon.phases.forEach(p => p.active = false);
          this.loading = false;
          eventBus.$emit('refreshClassesData', {});
        }).catch(err => console.log(err));
      },
      terminateMarathon(){
        this.loading = true;
        axios.post(`/api/marathons/${this.marathon.id}/terminate`, {})
        .then(resp => {
          this.marathon.status = 'finished';
          this.marathon.phases.forEach(p => p.active = false);
          this.loading = false;
          eventBus.$emit('refreshClassesData', {});
        }).catch(err => console.log(err));
      },
      addPhase(){
        this.loading = true;
        this.phasesLoading = true;
        axios.post(`/api/marathons/${this.marathon.id}/addPhase`, this.newPhase)
        .then(resp => {
          this.marathon.phases.push(resp.data);
          this.loading = false;
          this.addPhaseDialog = false;
          this.phasesLoading = false;
          eventBus.$emit('refreshClassesData', {});
        }).catch(err => console.log(err));
      },
      phaseActivation({id, activation}){
        this.phasesLoading = true;
        axios.post(`/api/marathons/${this.marathon.id}/phases/${id}/activation`, {activation})
        .then(resp => {
          this.marathon.phases.forEach(p => {
            p.active = activation && p.id == id;
          });
          this.phasesLoading = false;
          eventBus.$emit('refreshClassesData', {});
        }).catch(err => {
          this.$root.snackError(err.response.data.error);
          this.phasesLoading = false;
          console.log(err)
        });
      },
      phaseMove({id, direction}){
        this.phasesLoading = true;
        axios.post(`/api/marathons/${this.marathon.id}/phases/${id}/move`, {direction})
        .then(resp => {
          this.marathon.phases = resp.data;
          this.phasesLoading = false;
        }).catch(err => console.log(err));
      },
      phaseEdit(data){
        this.phasesLoading = true;
        axios.patch(`/api/marathons/${this.marathon.id}/phases/${data.id}`,
        {
          title: data.title,
          description: data.description,
          rules: data.rules
        })
        .then(resp => {
          let phase = this.marathon.phases.find(o => o.id == data.id);
          phase = resp.data;
          this.$root.snackSuccess('Phase edited');
          this.phasesLoading = false;
        }).catch(err => console.log(err));
      },
      deletePhase(phaseId){
        axios.post(`/api/marathons/${this.marathon.id}/phases/${phaseId}/delete`)
        .then(resp => {
          this.marathon.phases = this.marathon.phases.filter(p => p.id != phaseId);
          eventBus.$emit('refreshClassesData', {});
        }).catch(err => console.log(err));
      }
    }
  }
</script>

<style scoped>
  .loadingOverlay {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(255,255,255,0.8);
  }
</style>
