<template>
  <v-layout class='px-2 pt-2' row wrap>
    <v-flex xs12 class='headline grey--text' v-if='displayHeader'>
      Your class
    </v-flex>
    <v-flex xs12 class='samfont-display mt-2 text-xs-center text-truncate'>
      {{ $root.removeAccents(classData.name) }}
    </v-flex>
    <v-flex xs12 class='display-1 font-weight-bold text-xs-center'>
      {{ classScore }}
    </v-flex>
    <v-flex xs12 class='caption grey--text font-italic text-xs-center'>
      (avg score)
    </v-flex>
    <v-flex xs12 class='mt-3 mb-2 grey--text subheading'>
      Leading students
    </v-flex>
    <v-flex xs12 style='display:flex;justify-content:space-around'>
      <span style='display:flex;flex-direction:column;align-items:flex-start;justify-content:flex-start;max-width:60px;' class='ma-1' v-for='student in bestStudents' :key='student.id'>
        <v-img width='60px' max-height='60px' style='border-radius:50%' :src='student.avatar'/>
        <div class='containedName text-xs-center' v-if='student.name && showNames'>{{ student.name }}</div>
        <div class='font-weight-bold text-xs-center'>{{ student.totalScore }} pts</div>
        <v-chip color='success' dark class='subheading' v-if='student.id == $root.user.id'>
          You
        </v-chip>
      </span>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    props : { classData: [Object, Array], displayHeader: Boolean, showNames: Boolean},
    data : function(){
      return {
        //data here
      }
    },
    computed: {
      bestStudents(){
        return this.classData.bestStudents ? Object.values(this.classData.bestStudents).sort((a,b) => a.totalScore < b.totalScore ? 1 : -1) : [];
      },
      classScore(){
        if(this.classData.score){
          if(this.classData.score > 1){
            return `${this.classData.score} pts`;
          }
          return `${this.classData.score} pt`
        }
        return `0 pt` ;
      }
    },
    mounted() {

    },
    methods: {

    }
  }
</script>

<style scoped>
.containedName {
  max-height:42px;
  min-height:42px;
  overflow-y:hidden;
  overflow-wrap:break-word;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
</style>
