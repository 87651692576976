import { render, staticRenderFns } from "./hike-card-student.vue?vue&type=template&id=ebffe446&scoped=true&"
import script from "./hike-card-student.vue?vue&type=script&lang=js&"
export * from "./hike-card-student.vue?vue&type=script&lang=js&"
import style0 from "./hike-card-student.vue?vue&type=style&index=0&id=ebffe446&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebffe446",
  null
  
)

export default component.exports