<template>
  <v-container fluid class='pa-0' style='background:#00111A;color:#74838C;'>
    <v-container class='pa-0'>
      <v-layout class='pt-1' style='align-items:center' row wrap>
        <v-flex sm2 hidden-xs-only style='display:flex;justify-content:center' class='py-2'>
          <a class='footerLink' @click='sendAMessage'>Send a message</a>
        </v-flex>
        <v-flex xs4 sm2 d-flex justify-center class='py-2'>
          <a class='footerLink' @click='goAbout'>About Sam</a>
        </v-flex>
        <v-flex xs4 d-flex justify-center class='py-1'>
          <!-- <span class='samfont-title'>Sam's Tricks</span> -->
          <support-button/>
        </v-flex>
        <v-flex xs4 sm2 d-flex justify-center class='py-2'>
          <a class='footerLink' target="_blank" href="https://inktopiaillustration.wixsite.com/portfolio">Inktopia illustration</a>
        </v-flex>
        <v-flex sm2 hidden-xs-only style='display:flex;justify-content:center' class='py-2'>
          <images-credit-dialog/>
        </v-flex>
      </v-layout>
    </v-container>
    <leave-a-message :displayButton='false'/>
  </v-container>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        //data here
      }
    },
    mounted() {

    },
    methods: {
      sendAMessage(){
        eventBus.$emit('sendMessageToSam', {});
      },
      goAbout(){
        this.$router.push('/about');
      }
    }
  }
</script>

<style scoped>
  a {
    text-decoration: none;
    font-weight: bold;
    color: #74838C;
  }
  a:hover{
    color: #A7B6BF
  }
</style>
