<template>
<v-layout row wrap style='position:relative'>
  <v-data-table disable-initial-sort style='width:100%' :headers="headers" :items="history" :rows-per-page-items='[10, 20, {"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'>
    <template v-slot:items="props">
      <tr @click="props.expanded = !props.expanded && canExpend(props.item)">
        <td>{{ props.item.trickTitle }}</td>
        <td class="text-xs-center">
          <span class='grey--text' v-if='props.item.current'>
            In progress
          </span>
          <span :class='props.item.timeSpent == null ? "grey--text" : "error--text"' v-else-if='props.item.score == null'>
            Abandoned
          </span>
          <span v-else>
            {{ props.item.score }}
          </span>
        </td>
        <td class="text-xs-center headline font-weight-thin">
          <v-icon color='grey' medium v-if='props.item.current'>help</v-icon>
          <v-icon :color='props.item.timeSpent == null ? "grey" : "error"' medium v-else-if='props.item.score == null'>cancel</v-icon>
          <span v-else>
            {{ $root.scoreToRating(props.item.score) }} <v-icon medium color="orange">star</v-icon>
          </span>
        </td>
        <td class="text-xs-center">
          <v-icon color='grey' medium v-if='props.item.current'>help</v-icon>
          <span v-else>
            <v-icon color='grey' medium v-if='props.item.validation.alreadyTrick && props.item.score != null'>done</v-icon>
            <v-icon :color='props.item.validation.alreadyRace ? "grey" : "success"' medium v-if='props.item.validation.trick'>check_circle</v-icon>
            <v-icon :color='props.item.timeSpent == null ? "grey" : "error"' medium v-if='props.item.score == null'>cancel</v-icon>
            <v-icon color='success' medium v-if='props.item.validation.race'>directions_run</v-icon>
          </span>
        </td>
        <td class="text-xs-center">
          <span class="grey--text" v-if='props.item.current'>--</span>
          <span
            v-else
            :class='{
              "error--text": props.item.score == null && props.item.timeSpent != null,
              "grey--text": (props.item.score == null && props.item.timeSpent == null) || props.item.validation.alreadyRace || props.item.validation.alreadyTrick
              }'
            >
              {{ props.item.timeSpent | formatTime }}
            </span>
        </td>
        <td class="text-xs-center grey--text">
          <span v-if='props.item.current'>--</span>
          <span v-else>
            {{ props.item.totalTime | formatTime }}
          </span>
        </td>
      </tr>
    </template>
    <template v-slot:expand="props">
      <v-card flat class='text-xs-center'>
        <v-btn color="error" outline style='width:200px' @click='removePenalty(props.item.id, props.item.race_id)'>Remove penalty</v-btn>
      </v-card>
    </template>
  </v-data-table>
  <div style='position:absolute;top:0px;left:0px;width:100%;height:100%;background:rgba(255,255,255,0.3)' v-if='loading'/>
</v-layout>
</template>
<script>
  export default {
    props : { history: Array, status: String, loading: Boolean, canRemovePenalty: Boolean },
    data : function(){
      return {
        headers: [
          {text: 'Trick title', value: 'trickTitle'},
          {text: 'Score', value: 'score', align: 'center'},
          {text: 'Rating', value: 'score', align: 'center'},
          {text: 'Race progress', sortable: false, value: 'validation.trick', align: 'center'},
          {text: 'Time on trick', value: 'timeSpent', align: 'center'},
          {text: 'Total time', value: 'totalTime', align: 'center'}
        ]
      }
    },
    filters: {
      formatTime(time){
        if(isNaN(time)) return '--';
        if(time == null) return '--';
        if(time < 60) return `${time}s`;
        let minutes = Math.floor(time/60);
        let seconds = Math.floor(time % 60);
        if(seconds < 10){
          return `${minutes}m0${seconds}s`;
        } else {
          return `${minutes}m${seconds}s`;
        }
      }
    },
    methods: {
      canExpend(item){
        return this.canRemovePenalty && item.score == null && !item.validation.alreadyRace && !item.validation.alreadyTrick && this.status == 'active' && !item.current;
      },
      removePenalty(attemptId, raceId) {
        axios.delete(`/api/races/${raceId}/attempt/${attemptId}`, {})
        .then(resp => {
          eventBus.$emit('removeAttempt', {id: resp.data.id});
          this.$root.snackSuccess("Penalty removed");
        })
        .catch(err => {
          if(err.response.status == 403){
            this.$root.snackError('You cannot remove this penalty');
          } else {
            this.$root.snackError(err.response.data);
          }
          console.log(err.response);
        });
      }
    }
  }
</script>
