<template>
  <v-card
    :width='expandId == track.id ? "100%" : "45%"'
    :height='expandId == track.id ? "auto" : "50px"'
    style='overflow:hidden'
    class='ma-1'
  >
    <div style='position:absolute;top:0;height:100%;width:1px' :style="{background:strip, left:`${index}px`}" v-for="(strip, index) in strips" :key="`strip-${index}`"></div>
    <difficulty-display :difficulty='track.relative_difficulty' :size='expandId == track.id ? "standard" : "small"' style='position:absolute;right:5px;top:5px'></difficulty-display>
    <v-card-title class='py-2' :class='expandId == track.id ? "title" : "subheading"' style='cursor:pointer;' @click='toggleCard()'>
      <v-layout row wrap>
        <v-flex xs12 class='text-truncate' :class='expandId == track.id ? "title pb-1" : "subheading pb-0"'>
          {{ track.title }}
        </v-flex>
        <v-flex xs12 class='grey--text caption pt-0'>
          {{ sourceMsg[track.source] }}
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-chip v-for="(trick, index) in track.tricks" :key="`affinity-${trick.id}-${index}`" @click='focusThisTrick(trick);'>
        <v-avatar style='cursor:pointer'  class='font-weight-bold white--text' :color='$root.trickColors[trick.type]'> {{ trick.type | initial }}</v-avatar>
        <span style='cursor:pointer'  class='pr-4'>
          {{ trick.title }}
        </span>
        <v-btn icon style='position:absolute;right:-8px;top:-8px' @click.stop='addToSelected(trick)'>
          <v-icon color='primary'>add</v-icon>
        </v-btn>
      </v-chip>
    </v-card-text>
    <v-card-actions style='display:flex;justify-content:flex-end'>
      <v-btn color='primary' @click='addAllToSelected'>
        Select all tricks
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  export default {
    props : { track: Object, expandId: Number },
    data : function(){
      return {
        strips: [],
        sourceMsg: {
          closed : 'From past race',
          studentPerf: 'Based on students performances',
          upcoming: 'Upcoming race track',
          active: 'Active race track',
          hike: 'Open hike section'
        }
      }
    },
    filters: {
      initial(val){
        return val.charAt(0).toUpperCase();
      }
    },
    mounted() {
      this.strips = [];
      this.track.tricks.forEach(trick => this.strips.push(this.$root.trickColors[trick.type]));
    },
    methods: {
      toggleCard(){
        eventBus.$emit('toggleCard', this.track.id);
      },
      focusThisTrick(trick){
        eventBus.$emit('focusTrick', trick);
      },
      addToSelected(trick){
        eventBus.$emit('addTrick', trick);
      },
      addAllToSelected(){
        this.track.tricks.forEach(trick => eventBus.$emit('addTrick', trick));
      }
    }
  }
</script>
