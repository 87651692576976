<template>
  <v-dialog v-model="racePerfsDialog"  fullscreen hide-overlay transition="slide-x-transition" @keydown.esc="initAndClose">
    <v-card>
      <v-toolbar fixed dark color="primary" dense>
        <v-toolbar-title>{{ race.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-card color='success' class='white--text font-weight-bold text-xs-center pa-2' v-if='race.status == "active"'>
          Race open
        </v-card>
        <v-card color='grey' class='white--text font-weight-bold text-xs-center pa-2' v-if='race.status == "closed"'>
          Race closed
        </v-card>
        <v-btn icon dark @click="initAndClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class='pa-0'>
        <v-container grid-list-lg class='pa-1' style='margin-top:40px'>
          <perfs-head
            :race='race'
            :options='options'
            :raceOpen='race.status == "active"'
          />
          <v-layout row wrap>
            <v-flex xs12 class='mb-2' v-if='race.status == "closed"'>
              <v-layout row wrap>
                <v-flex xs12 class="grey--text title pb-1">
                  Medalists
                </v-flex>
                <v-flex xs12>
                  <medalists-recap :medals="race.formatedProgress.topRewards"/>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class='mb-2' v-else>
              <v-layout row wrap>
                <v-flex xs12 class="grey--text">
                  <span class='title'>
                    Front-runners
                  </span>
                  <span class='caption font-italic' v-if='options.rewardRule == "fastest"'>
                    (Medals will be awarded at the end of the race based on running time.)
                  </span>
                  <span class='caption font-italic' v-else>
                    (Medals will be awarded at the end of the race based on finish timestpamp.)
                  </span>
                </v-flex>
                <v-flex xs12>
                  <front-runners :runners="race.formatedProgress.topRewards" :rule='options.rewardRule'></front-runners>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="grey--text title pb-1">
              Best performances
            </v-flex>
            <v-flex xs12 class='mb-2'>
              <best-runners :runners='race.formatedProgress.highScores' :maxStars='race.formatedProgress.progress.tricks.length*5'/>
            </v-flex>
            <v-flex xs12 class="grey--text title pb-1">
              Tricks star count (all runners)
            </v-flex>
            <v-flex xs12 style='display:flex;justify-content:flex-start;flex-wrap:wrap;' class='mb-2'>
              <v-tooltip top v-for='trick in race.formatedProgress.progress.tricks' :key='`trick-${trick.id}`'>
                <v-card slot="activator" width="140" class='ma-1' >
                  <v-flex class='subheading font-weight-regular text-xs-center pb-0 text-no-wrap text-truncate'>
                    {{ trick.title }}
                  </v-flex>
                  <v-flex class='title font-weight-bold pt-0' style="white-space:nowrap;display:flex;justify-content:center;align-items:center">
                    <span>
                      <text-merge :text='trick.stars'/>
                    </span>
                    <v-icon medium color="orange">star</v-icon>
                  </v-flex>
                </v-card>
                <span>{{ trick.title }}</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs12 v-if='$root.status == "student"'>
              <div class="grey--text title pb-1">
                Performance history (individual)
              </div>
              <race-perfs-history :history='thisStudentHistory'/>
            </v-flex>
            <v-flex xs12 v-else>
              <v-tabs v-model='historyTabs' right @change='studentSelected = null'>
                  <div class="grey--text title" style='flex:1;display:flex;align-items:center'>
                    Performance history (individual)
                  </div>
                  <v-tab class='px-3' v-for='cl in studentsByClass' :key='cl.id'>{{ cl.name }}</v-tab>
                  <v-tab-item v-for='cl in studentsByClass' :key='cl.id'>
                    <div style='display:flex;align-items:baseline'>
                      <v-select
                        :items="cl.students"
                        :loading="historySelectLoading"
                        :disabled="historySelectLoading"
                        v-model="studentSelected"
                        :label="`Students (${cl.name})`"
                        outline
                        @change="getStudentHistory"
                      />
                      <v-btn large flat icon class='ml-2' :disabled='studentSelected == null || historySelectLoading' @click="getStudentHistory">
                        <v-icon x-large>refresh</v-icon>
                      </v-btn>
                    </div>
                  </v-tab-item>
              </v-tabs>
              <race-perfs-history
                v-if='studentSelected'
                :history='studentHistory'
                :loading='historySelectLoading'
                :status='race.status'
                :canRemovePenalty='$root.user.id == race.author_id'
              />
              <v-layout style='height:150px' v-else></v-layout>
            </v-flex>
          </v-layout>
          <v-layout style='height:50px'></v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props: { race: Object, options: Object, racePerfsDialog: Boolean, thisStudentHistory: Array },
    data : function(){
      return {
        fetchingData: false,
        forceUpdateCounter: 0,
        studentHistory: [],
        historySelectLoading: false,
        studentsByClass: [],
        currentClassStudents: [],
        historyTabs: null,
        studentSelected: null,
      }
    },
    computed:{
      medalRight(){
        if(this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm'){
          return '2%';
        } else {
          return '10%';
        }
      },
      students(){
        let students = [];
        this.race.divisions.forEach(cl => {
          let classRef = _.find(this.$root.classesData.divisions, o => o.id == cl.id);
          if(classRef){
            students.push({header: cl.name});
            classRef.students.forEach(st => students.push({value: st.id, text: st.name}));
            students.push({divider: true});
          }
        });
        return students;
      }
    },
    created() {
      eventBus.$on('removeAttempt', data => {
        let temp = this.studentHistory;
        this.studentHistory = [];
        let timeToRemove = 0;
        temp.forEach(h => {
          if(h.id == data.id){
            timeToRemove = h.timeSpent;
          } else {
            h.totalTime = h.totalTime - timeToRemove;
            this.studentHistory.push(h);
          }
        })
      });
    },
    mounted(){
      this.historySelectLoading = true;
      axios.get(`/api/races/${this.race.id}/classes`, {})
      .then(resp => {
        this.studentsByClass = resp.data;
        this.historySelectLoading = false;
      }).catch(err => console.log(err));

    },
    methods : {
      initAndClose(){
        this.studentSelected = null;
        eventBus.$emit('closePerfsDialog', this.race.id);
      },
      getStudentHistory(){
        this.historySelectLoading = true;
        axios.get(`/api/races/${this.race.id}/history/${this.studentSelected}`, {})
        .then(resp => {
          this.studentHistory = resp.data;
          this.historySelectLoading = false;
        })
        .catch(err => console.log(err));
      }
    }
  }
</script>
