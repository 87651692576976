<template>
  <v-flex class='py-0' style='display:flex;align-items:center;justify-content:flex-start'>
    <v-card
      min-height='45px' max-height='45px'
      :min-width='barWidth':max-width='barWidth'
      style='overflow:hidden;display:flex;justify-content:flex-end;align-items:center;'
      flat hover
      :color='barColors[reward]'
      class="pa-2 mr-2 subheading font-weight-bold smooth"
    >
    <v-img max-width='40px' :src='avatar' class='mr-2' style='border-radius:50%'/>
    <span class='text-truncate'>
      {{ name }}
    </span>
    <v-spacer></v-spacer>
    <span class="title font-weight-thin" style='white-space:nowrap;overflow:hidden'>
      <text-countup :text='stars'></text-countup>/{{max}} <v-icon color="orange">star</v-icon>
    </span>
    </v-card>
  </v-flex>

</template>
<script>
  export default {
    props : { stars: Number, max: Number, name: String, avatar: String, reward: String },
    data: function(){
      return {
        barColors: {
          gold: 'rgba(255, 215, 0, 0.5)',
          silver: 'rgba(192, 192, 192, 0.6)',
          bronze: 'rgba(80, 50, 20, 0.5)',
          finished: 'rgba(76, 175, 80, 0.25)',
          null: 'rgba(36, 60, 215, 0.25)'
        }
      }
    },
    computed:{
      barWidth(){
        return `${Math.floor(100*this.stars/this.max)}%`
      }
    }
  }
</script>

<style>
  .smooth {
    transition: all 1s
  }
</style>
