<template>
  <v-dialog v-model='creditDialog' width='550px'>
    <template v-slot:activator="{ on }">
      <a class='footerLink' v-on="on">Other credits</a>
    </template>
    <v-card>
      <v-layout row wrap>
        <v-flex xs6>
        <v-card-title class='samfont-bigtitle'>
          Images credit
        </v-card-title>
        <v-card-text class='pt-0 subheading dialogLink'>
            <div>
              The character of Sam was designed and drawn by
              <div class='text-xs-center headline'>
                <a target="_blank" href="https://inktopiaillustration.wixsite.com/portfolio">Cassandre Balbar</a>.
              </div>
            </div>
            <div class='mt-2'>
              Additionnal images (<a target="_blank" href="https://www.freepik.com/">Freepik</a>)
              <ul>
                <li><a target="_blank" href="https://www.freepik.com/brgfx">brgfx</a></li>
                <li><a target="_blank" href="http://www.freepik.com/macrovector">macrovector</a></li>
                <li><a target="_blank" href="http://www.freepik.com/alicia_mb">alicia_mb</a></li>
                <li><a target="_blank" href='https://www.freepik.com/starline'>starline</a></li>
                <li><a target="_blank" href='https://www.freepik.com/gstudioimagen'>gstudioimagen</a></li>
                <li><a target="_blank" href='https://www.freepik.com/pikisuperstar'>pikisuperstar</a></li>
                <li><a target="_blank" href='https://www.freepik.com/mamewmy'>mamewmy</a></li>
                <li><a target="_blank" href='https://www.freepik.com/rawpixel-com'>rawpixel.com</a></li>
                <li><a target="_blank" href="https://www.flaticon.com/authors/smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></li>
              </ul>
            </div>
          </v-card-text>
        </v-flex>
        <v-flex xs6>
          <v-img src="/images/Welcome/Sam credits.png" width="100%"/>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>

</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        creditDialog: false
      }
    },
    mounted() {

    },
    methods: {

    }
  }
</script>

<style scoped>
  a.footerLink {
    text-decoration: none;
    font-weight: bold;
    color: #74838C;
  }
  a.footerLink:hover{
    color: #A7B6BF
  }
  .dialogLink a{
    text-decoration: none;
    font-weight: bold;
  }
  .dialogLink a:hover{
    text-decoration: underline;
  }

</style>
