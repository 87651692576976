<template>
  <v-card hover
      @click="expand"
      style="display:inline-block;vertical-align:top;overflow:hidden;"
      :style="`width:${width}px;height:${height}px;border-radius:${bradius}px;`"
      class="pa-2 ma-1"
    >
    <v-layout row wrap>
      <v-flex xs12 style='display:flex;justify-content:space-between;align-items:baseline'>
        <span :class='expanded ? "title font-weight-bold" : "subheading"' class='text-truncate' style='max-width:150px'>
          {{ race.title }}
        </span>
        <v-card :color='statusDisplay.bgColor' class='white--text font-weight-bold' :class='expanded ? "large" : "small"'>
          <span v-if='expanded'>
            {{ statusDisplay.text }}
          </span>
        </v-card>
      </v-flex>
      <v-flex xs12 class='py-1' v-if='expanded'>
        <div style="position:absolute;right:10px;top:40px;" v-if="expanded">
          <v-img
            v-if="race.studentProgress != null && race.studentProgress.reward != null"
            :src="`/images/medal ${race.studentProgress.reward}.png`"
            width="50px">
          </v-img>
          <!-- <v-tooltip top v-if='race.status == "active" || race.status == "upcoming"'>
            <v-btn slot="activator" flat style='width:80px;margin-right:-10px' to="/races" :ripple='false' color='grey'>
              <v-icon style='display:inline-flex'>directions_run</v-icon>
              <v-icon style='display:inline-flex'>chevron_right</v-icon>
            </v-btn>
            <span>Go to races page</span>
          </v-tooltip> -->
        </div>
        <div style='position:absolute;bottom:-5px;right:-5px'>
          <race-perfs-btn :raceId='race.id' :alwaysReloadRace='race.status == "active"'/>
        </div>
        <v-layout row wrap>
          <v-flex xs9 style='display:flex;justify-content:space-between;align-items:center'>
            <span class='title grey--text'>
              Track
            </span>
            <span class='text-xs-center headline font-weight-bold'>
              {{ totalStars }}/{{ maxStars }}
              <v-icon medium color="orange">star</v-icon>
            </span>
          </v-flex>
          <v-flex xs3></v-flex>
          <v-flex xs12 v-if='track.title'>
            {{ track.title }}
          </v-flex>
          <v-flex xs12 v-else>
            <v-progress-linear style='width:200px' :indeterminate="true"/>
          </v-flex>
          <v-flex xs10>
            <v-tooltip top v-for='(trick, i) in trickDivs' :key='trick.id'>
             <template v-slot:activator="{ on }">
               <span v-on="on">
                 <v-chip label style='width:40px' small :color="trick.validated ? 'success' : 'primary'"/>
               </span>
             </template>
             <span class='text-xs-center'>
               <div class='font-weight-bold'>{{ trick.title }}</div>
               <div>
                 <span v-if='!trick.validated'>not </span>
                 <span>validated</span>
               </div>
             </span>
           </v-tooltip>
          </v-flex>
          <v-flex xs2></v-flex>
          <v-flex xs12 class='grey--text caption text-xs-center'>
              <i>{{ formatedTimespan }}</i>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
  import moment from 'moment-timezone';


  export default {
    props : { race: Object },
    data : function(){
      return {
        width: '180',
        height: '35',
        bradius: '8',
        expanded: false,
        statusDisplay: {},
        stats: {},
        options: {},
        trickDivs: [],
        perfsDialog: false,
        totalStars: 0,
        maxStars: 0,
        track: {tricks: []}
      }
    },
    computed: {
      formatedTimespan(){
        if(this.race.status == 'upcoming'){
          return `Starts in ${moment.duration(this.race.timespan.starts_in_seconds,'seconds').humanize()}`;
        } else if(this.race.status == 'active'){
          return `Ends in ${moment.duration(this.race.timespan.ends_in_seconds,'seconds').humanize()}`;

        } else {
          return `Ended ${moment.duration(this.race.timespan.ends_in_seconds,'seconds').humanize()} ago`;
        }
      },
    },
    mounted() {
      if(this.race.status == 'active'){
        this.statusDisplay = {text: 'Open', bgColor: 'green'};
      } else if(this.race.status == 'upcoming'){
        this.statusDisplay = {text: 'Upcoming', bgColor: 'green lighten-3'};
      } else {
        if(this.race.studentProgress && this.race.studentProgress.reward){
          let reward = this.race.studentProgress.reward;
          this.statusDisplay = {text: reward[0].toUpperCase()+reward.slice(1), bgColor: this.$root.medalColors[reward]};
        } else {
          this.statusDisplay = {text: 'Closed', bgColor: 'grey darken-1'};
        }
      }
      this.trickDivs = [];
      if(this.race.studentProgress){
        this.race.studentProgress.tricks = JSON.parse(this.race.studentProgress.tricks)
        this.race.studentProgress.tricks.forEach(trick => {
          this.totalStars += trick.stars;
          this.trickDivs.push({id: trick.id, title: trick.title, validated: trick.validated})
        });
        this.maxStars = this.race.studentProgress.tricks.length*5;
      }
      eventBus.$on('expandRace', (id) => {
        if(this.race.id != id){
          this.width = '180';
          this.height = '35';
          this.bradius = '8';
          this.expanded = false;
        }
      });
      eventBus.$on('closePerfsDialog', this.closePerfsDialog);
    },
    beforeDestroy(){
      eventBus.$off('closePerfsDialog', this.closePerfsDialog);
    },
    methods: {
      closePerfsDialog(){
        this.perfsDialog = false;
      },
      expand(){
        if(this.expanded){
          this.width = '180';
          this.height = '35';
          this.bradius = '8';
          this.expanded = false;
        } else {
          eventBus.$emit('expandRace', this.race.id);
          this.width = '350';
          this.height = '175';
          this.bradius = '2';
          this.expanded = true;
          if(!this.track.title){
            axios.get(`/api/tracks/${this.race.track_id}` , {})
            .then(resp => {
              this.track = resp.data;
              this.maxStars = this.track.tricks.length*5;
              if(this.trickDivs.length == 0) {
                this.track.tricks.forEach(trick => this.trickDivs.push({id: trick.id, title: trick.title, validated: false}))
              }
            })
            .catch(err => console.log(err));
          }
        }
      }
    }
  }
</script>

<style scoped>
.small {
  width: 15px;
  height: 15px;
  border-radius: 50%
}
.large {
  width: 75px;
  height: 25px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
