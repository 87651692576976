<template>

</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        //data here
      }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {

    }
  }
</script>
