<template>
    <v-card width="200" height="330" class="ma-2 pa-2 text-xs-left" :style="canvas.open ? 'border:2px solid #4CAF50' : ''">
        <div v-if="canvas.completed">
            <div class="title mb-2 text-truncate">
                {{ canvas.options.title }}
            </div>
            <div>
                <v-img style="image-rendering: pixelated;" :src="canvas.orgImageUrl"/>
            </div>
            <v-btn block small :ripple="false" color="success" v-if="canvas.completed">
                COMPLETED <v-icon small right>check_circle</v-icon>
            </v-btn>
        </div>
        <div v-else>
            <div 
                class="title mb-3 text-truncate"
                style="user-select:none;"
                :style="canvas.completed ? '' : `color:transparent;text-shadow:0 0 ${10 * (1 - progress / 100) ** 0.5}px rgba(0,0,0,0.5)`"
            >
                {{ canvas.options.title }}
            </div>
            <div class="my-2" style="display:flex;flex-direction:column;justify-content:center;align-items:center;">
                <div class="grey--text mb-1">
                    Progress :
                </div>
                <v-progress-circular
                    :rotate="-90"
                    :size="150"
                    :width="30"
                    :value="progress"
                    color="primary"
                >
                    {{ progress }}%
                </v-progress-circular>
                <div class="grey--text mt-3">
                    Last update :
                </div>
                <div class="subheading text-xs-center mb-1">
                    {{ canvas.lastUpdated }}
                </div>
            </div>
            <div>
                <v-btn 
                    block
                    small
                    color="primary"
                    :disabled="selecting != 0 || !!canvas.open"
                    :loading="selecting == canvas.id"
                    @click="selectCanvas"
                >
                    <span v-if="canvas.open">
                        Selected
                    </span>
                    <span v-else>
                        Select <v-icon small right>done</v-icon>
                    </span>
                </v-btn>
            </div>
        </div>
    </v-card>
</template>
<script>
export default {
    props: { canvas: Object, selecting: Number },
    data: function () {
        return {

        }
    },
    computed:{
        progress(){
            return Math.round(100 * this.canvas.revealedPixels / this.canvas.totalPixels)
        }
    },
    mounted() {
        
    },
    beforeDestroy() {

    },
    methods: {
        getImage(){
            eventBus.$emit('getCanvasImage', this.canvas);
        },
        selectCanvas(){
            eventBus.$emit('selectCanvas', this.canvas);
        }
    }
}
</script>

<style scoped>
    .progressTile {
        width: 10%;
        aspect-ratio: 1 / 1;
        border: 1px solid #bbbbbb;
        background: #dddddd;
    }
    .doneTile {
        background: #4abd4e;
    }
</style>
