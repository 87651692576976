<template>
  <v-data-table :headers="marathonHeaders" :items="marathonItems" hide-actions :search="search" v-if="marathonHeaders.length && show">
    <template slot="headerCell" slot-scope="props">
      <v-tooltip top v-if='props.header.fullText'>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            {{ props.header.text }}
          </span>
        </template>
        <span>
          {{ props.header.fullText }}
        </span>
      </v-tooltip>
      <span v-else>
        {{ props.header.text }}
      </span>
    </template>
    <template v-slot:items="props">
      <td style="width:200px;" class="pl-2 pr-0 py-0" v-if='props.item.properName'>{{ props.item.properName.name }}</td>
      <td style="width:200px;" class="pl-2 pr-0 py-0 grey--text" v-else>{{ props.item.name }}</td>
      <td
        class="text-xs-center subheading pa-0"
        :class="phase.score == 0 ? 'grey--text text--lighten-1' : ''"
        v-for="phase in props.item.phases"
        :key="phase.id"
      >
        {{ phase.score }}
      </td>
      <td class="font-weight-bold title text-xs-center pa-0">{{ props.item.totalScore }}</td>
    </template>
  </v-data-table>
</template>
<script>
  export default {
    props : { data: Object, search: String, show: Boolean },
    data : function(){
      return {
        marathonHeaders: [],
        marathonItems: [],
      }
    },
    mounted() {

    },
    watch:{
      data(val){
        if(this.show){
          let marathonDetails = val.perfs;
          this.marathonHeaders = [{text: 'Name', align: 'center', value: 'lastName'}];
          marathonDetails[0].phases.forEach((phase, index) => {
            let colTitle = phase.title.length < 15 ? phase.title : phase.title.substr(0, 6)+'...'+phase.title.substr(-5, 5);
            this.marathonHeaders.push({text: colTitle, fullText: phase.title, align: 'center', value: `phase[${index}].phaseScore.score`, class: 'pa-0 text-truncate'});
            this.nbOfTricks++;
          });
          this.marathonHeaders.push({text: 'Total', align: 'center', value: 'totalScore', class: 'px-1'});
          this.marathonItems = [];
          marathonDetails.forEach(detail => {
            let row = {name: detail.student.name, properName: detail.student.properName, lastName: detail.student.lastName, userId: detail.student.id};
            let phases = [];
            detail.phases.forEach(phase => {
              phases.push({id: phase.id, score: phase.totalScore});;
            });
            row['phases'] = phases;
            row['totalScore'] = detail.totalScore;
            this.marathonItems.push(row);
          });
        }
      }
    },
    methods: {

    }
  }
</script>
