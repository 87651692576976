<template>
    <div>
        <div class='pa-1'>
            <span class='headline grey--text'>
                Marathon checkpoint
            </span>
        </div>
        <div class='pa-1 subheading'>
            <b>{{ announcement.marathonTitle }}</b> - at the closing of {{ announcement.raceTitle }}
        </div>
        <div>
            <v-tabs>
                <div style="display:flex;justify-content:space-between;align-items:center;width:100%">
                    <span>
                        <v-tab href="#students-tab">
                            Students
                        </v-tab>
                        <v-tab href="#classes-tab">
                            Classes
                        </v-tab>
                    </span>
                    <v-btn icon @click="zoomDialog = true" v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)">
                        <v-icon>zoom_in</v-icon>
                    </v-btn>
                </div>
                <v-tab-item value="students-tab">
                    <leaderboard-bargraph
                    :items='Object.values(this.announcement.leaderboard.students)'
                    :bigName='false'
                    :students='true'
                    :maxHeight='250'
                    :displayLegends="false"
                    :showNames='true'
                />
                </v-tab-item>
                <v-tab-item value="classes-tab">
                    <leaderboard-bargraph 
                        :items='Object.values(this.announcement.leaderboard.divisions)'
                        :bigName='false'
                        :students='false'
                        :maxHeight='250'
                        :displayLegends="false"
                        :showNames='true'
                    />
                </v-tab-item>
            </v-tabs>
        </div>
        <v-dialog v-model="zoomDialog" width="1100px">
            <v-card>
                <v-btn icon style="position:absolute;top:0px;right:0px" @click="zoomDialog = false">
                    <v-icon color="error">close</v-icon>
                </v-btn>
                <v-card-title style="display:block">
                    <div class="headline">
                        {{ announcement.marathonTitle }} checkpoint
                    </div>
                    <div class="grey--text">
                        At the closing of {{ announcement.raceTitle }}
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-layout row wrap>
                        <v-flex xs6 class="pa-2">
                            <leaderboard-bargraph 
                                :items='Object.values(this.announcement.leaderboard.students)'
                                :bigName='false' :students='true'
                                :maxHeight='350'
                                :displayLegends="true" 
                                :showNames='true'
                            />
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <leaderboard-bargraph 
                                :items='Object.values(this.announcement.leaderboard.divisions)'
                                :bigName='false' 
                                :students='false' 
                                :maxHeight='350'
                                :displayLegends="true" 
                                :showNames='true'
                            />
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <div style="height:70px"/>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        props: { announcement: Object },
        data: function () {
            return {
                zoomDialog: false
            }
        }
    }
</script>
