<template>
  <div v-if='rule=="fastest" && finishPlace == null' class='fastest pale font-weight-bold' :class='[runningTime < 60 ? "headline" : "subheading"]'>
    <span style='margin-top:20px;margin-left:24px'>
      {{ formatedRunningTime }}
    </span>
  </div>
  <div v-else-if='rule=="fastest"' class='fastest grey--text text--darken-1 font-weight-bold' :class='[runningTime < 60 ? "headline" : "subheading"]'>
    <span style='margin-top:20px;margin-left:24px'>
      {{ formatedRunningTime }}
    </span>
  </div>
  <div v-else-if="finishPlace != null" class='first white--text'>
    <v-layout row wrap style='margin-top:-35px'>
      <v-flex xs12 pa-0 class='headline font-weight-bold text-xs-center'>
        {{ finishPlace }}<sup class='subheading'>{{ placeSup }}</sup>
      </v-flex>
      <v-flex xs12 pa-0 d-flex justify-center style='margin-top:-5px'>
        place
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
  export default {
    props : { rule: String, runningTime: Number, finishPlace: Number},
    data : function(){
      return {
        //data here
      }
    },
    computed:{
      placeSup(){
        if(this.finishPlace > 10 && this.finishPlace < 14){
          return 'th';
        }
        switch(this.finishPlace.toString().slice(-1)){
          case '1':
            return 'st';
          break;
          case '2':
            return 'nd';
          break;
          case '3':
            return 'rd';
          break;
          default:
            return 'th';
          break;
        }
      },
      formatedRunningTime(){
        return this.$root.formatRunningTime(this.runningTime)
      }
    },
    // filters: {
    //   formatRunningTime(time){
    //     if(isNaN(time)) return '--';
    //     if(time == null) return '--';
    //     if(time < 60) return `${time}s`;
    //     if(time < 3600){
    //       let minutes = Math.floor(time/60);
    //       let seconds = Math.floor(time % 60);
    //       if(seconds < 10){
    //         return `${minutes}m0${seconds}s`;
    //       } else {
    //         return `${minutes}m${seconds}s`;
    //       }
    //     }
    //     if(time < 3600*24){
    //       let hours = Math.floor(time/3600);
    //       let minutes = Math.floor((time % 3600)/60);
    //       if(minutes < 10){
    //         return `${hours}h0${minutes}m`;
    //       } else {
    //         return `${hours}h${minutes}m`;
    //       }
    //     }
    //     let days = Math.floor(time/(3600*24));
    //     let hours = Math.floor((time % (3600*24))/3600);
    //     if(hours < 10){
    //       return `${days}d0${hours}h`;
    //     } else {
    //       return `${days}d${hours}h`;
    //     }
    //   }
    // }
  }
</script>

<style scoped>
  .fastest {
    min-height:110px;
    min-width:110px;
    max-height:110px;
    max-width:110px;
    display:flex;
    justify-content:center;
    align-items:center;
    background-image: url("/images/stopwatch.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .pale {
    opacity: 0.4;
  }
  .first {
    min-height:120px;
    min-width:120px;
    max-height:120px;
    max-width:120px;
    display:flex;
    justify-content:center;
    align-items:center;
    background-image: url("/images/place ribbon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
