<template>
  <v-card v-if="loading" height="250px" style='display:flex;justify-content:center;align-items:center'>
    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
  </v-card>
  <v-card v-else>
    <div style='position:absolute;top:0px;left:0px;width:100%'>
      <v-progress-linear :value='perfs.totals.percent' class='ma-0' height='8' color='success'></v-progress-linear>
    </div>
    <v-card-text>
      <v-layout row wrap v-for='(section, index) in perfs.sections' :key='`section-${index}`'>
        <v-flex xs12 class='subheading grey--text'>
          {{  trackTitles[index]  }}
        </v-flex>
        <v-flex xs12 style='display:flex;justify-content:space-between;flex-wrap:wrap' class='mb-3'>
          <v-tooltip top v-for='trick in section.tricksProgress' :key='trick.id'>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-progress-circular
                :size='30' :width='8' :rotate="-90"
                :value="trick.percent"
                :color="hikeDiagramColor(trick.percent)"
                />
              </span>
            </template>
            <span>
              <b>{{ trick.title }}</b> - {{ trick.stars }}/15 <v-icon small color='success'>stars</v-icon>
            </span>
          </v-tooltip>
        </v-flex>
        <span></span>
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        loading: false,
        perfs: {totals: {}},
        trackTitles: []
      }
    },
    mounted() {
      eventBus.$on('hikesPerfs', this.updateGraph);
    },
    beforeDestroy(){
      eventBus.$off('hikesPerfs', this.updateGraph);
    },
    methods: {
      updateGraph(data){
        this.perfs = {totals: {}};
        this.trackTitles = [];
        this.loading = true;
        axios.get(`/api/stats/${data.userId}/hikes/${data.hikeId}`, {})
        .then(resp => {
          this.perfs = resp.data.studentsPerfs[0];
          this.trackTitles = resp.data.hike.trackTitles;
          this.loading = false;
        })
        .catch(err => console.log(err));
      },
      hikeDiagramColor(val){
        if(val <= 25) return 'error';
        if(val <= 50) return 'warning';
        if(val <= 75) return 'primary';
        return 'success';
      },
    }
  }
</script>
