<template>
  <span>
    <span v-for='(char, index) in output' :key='`char-${index}`' :style='char.style'>{{ char.item }}</span>
  </span>
</template>
<script>
  export default {
    props : { nb: [Number, String], tolerance: {type: [Number, String], default: 4} },
    data : function(){
      return {
        //data here
      }
    },
    computed: {
      output(){
        if(this.nb){
          let nbArr = this.nb.toString().split('');
          const suffix = ['', 'K', 'M', 'G', 'T'];
          let index = 0;
          while(nbArr.length > this.tolerance){
            nbArr.pop();
            nbArr.pop();
            nbArr.pop();
            index++;
          }
          let output = index == 0 ? [] : [{item: suffix[index], style: 'margin-left:.05em'}];
          nbArr.reverse().forEach((item, index) => output.push({item, style: index%3 == 2 ? 'margin-left:.05em' : ''}));
          output = output.reverse();
          return output
        }
        return [{item: '0', style:''}];
      }
    },
    mounted() {

    },
    methods: {

    }
  }
</script>
