<template>
  <v-dialog v-model="focusTrickDialog" @keydown.esc="focusTrickDialog = false" width="90%" max-width="400px">
    <v-card style="overflow-x:hidden;margin:auto">
      <div style="position:absolute;top:15px;right:15px;width:60px;height:85px">
        <v-img src="/images/trick logo.png" width="60px" class='trickLogo' ></v-img>
        <div :style="`position:absolute;top:40px;right:30px;box-shadow:0 0 40px 20px ${$root.trickColors[trick.type]}`"></div>
        <div :style='`width:100%;position:absolute;bottom:10px;text-align:center;color:${$root.trickColors[trick.type]};font-family:cursive`'>{{ trick.type }}</div>
      </div>
      <v-btn flat icon color="error" style="position:absolute;top:-5px;right:-5px" @click="focusTrickDialog = false">
        <v-icon>close</v-icon>
      </v-btn>
      <v-layout row wrap>
        <v-flex xs4>
          <difficulty-display :difficulty='trick.relative_difficulty' size='standard'></difficulty-display>
        </v-flex>
        <v-flex xs4 class='title' style="display:flex;align-items:center;">
          <v-tooltip top v-if="timeModifier == 0">
            <template v-slot:activator="{ on }">
              <span v-on="on" class='headline' :class='{"font-weight-bold": (starCount == 15) , "font-weight-light": (starCount < 15)}' style="display:flex;align-items:center;">
                {{ starCount }}/15
                <v-icon medium color="orange">star</v-icon>
              </span>
            </template>
            <span v-if="starCount == 15">Max star count on this trick, congrats !</span>
            <span v-if="starCount < 15">Perform on this trick to add to your star count</span>
          </v-tooltip>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on }">
              <span v-on="on" class='headline' :class='{"font-weight-bold": (starCount == 15), "font-weight-light": (starCount < 15), "grey--text": (starCount < 15)}' style="display:flex;align-items:center;">
                +{{ starCount }}
                <v-icon medium :color="starCount == 15 ? 'orange' : 'grey'">star</v-icon>
              </span>
            </template>
            <span v-if="starCount == 15">Max star count on this trick, congrats !</span>
            <span v-if="starCount < 15">Performances with extra time do not add to your star count</span>
          </v-tooltip>
        </v-flex>
        <v-flex xs4 style="display:flex;align-items:center;" >
          <v-tooltip top v-if="racesInfo.length > 0">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color='raceColor' medium>directions_run</v-icon>
            </template>
            <span v-html='racesInfo'></span>
          </v-tooltip>
        </v-flex>
      </v-layout>
      <v-card-title class="display-1 px-1 pb-1 pt-0" style="display:flex;justify-content:center">
        <v-layout row wrap>
          <v-flex xs10 class='text-xs-center' style='min-height:123px;display:flex;flex-direction:column'>
            <v-spacer/>
            <span>
              {{ trick.title }}
            </span>
            <v-spacer/>
            <!-- <v-rating v-model="trick.rating" size="28" color="orange" background-color="orange lighten-3" readonly></v-rating> -->
          </v-flex>
          <v-btn flat icon @click='toggleWindows' style='position:absolute;right:0px;top:125px' :style="{transform: 'rotate('+displayDetails/2+'turn)'}">
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </v-layout>
      </v-card-title>
      <v-card flat class="mb-1">
        <v-window style="min-height:400px;max-height:400px;" v-model='displayDetails'>
          <v-window-item :key="0">
            <v-img :src="imgsrc"></v-img>
          </v-window-item>
          <v-window-item :key="1">
            <v-card flat max-height="400px" min-height="400px" class="px-3 py-1" style='overflow-y:auto;overflow-x:hidden'>
              <v-flex class="grey--text caption ma-0 pa-0">Description</v-flex>
              <v-flex class="text-xs-left mb-3" v-html="trick.text_description"></v-flex>
              <v-layout row wrap>
                <v-flex xs6>
                  <v-flex class="grey--text caption ma-0 pa-0">Formulas</v-flex>
                  <v-flex v-html="formatedFormula.formulas" class="mb-2 pa-0"></v-flex>
                </v-flex>
                <v-flex xs6>
                  <v-flex class="grey--text caption ma-0 pa-0">Variables</v-flex>
                  <v-flex v-html="formatedFormula.variables" class="mb-2 pa-0"></v-flex>
                </v-flex>
              </v-layout>
              <v-flex class="caption grey--text">Examples</v-flex>
              <v-layout row wrap v-if="examples.length == 6">
                <v-flex style='flex:1' class='ma-2 subheading' v-for="(example, index) in this.examples" :key="`ex-${index}`">
                  <div style='white-space:nowrap' class='text-xs-left'>
                    <span v-html="example.computation"></span> <span class="grey--text" v-html="example.result"></span>
                  </div>
                </v-flex>
              </v-layout>
              <v-flex class='mb-3'></v-flex>
              <v-flex class="grey--text caption ma-0 pa-0">Author</v-flex>
              <v-flex class="text-xs-left">{{ authorName }}</v-flex>
            </v-card>
          </v-window-item>
        </v-window>
      </v-card>
      <v-layout style='display:flex' v-if="trick.editable">
        <v-flex style='display:flex;align-items:center;flex:1'>
          <try-trick-button :trickId="trick.id" :type="trick.type" :withEdit="true" class='ml-2'></try-trick-button>
        </v-flex>
        <v-flex style='flex:0' class="pa-0 mx-0">
          <v-btn color="primary" fab small dark  @click="editTrick">
            <v-icon style='display:inline-flex'>edit</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-else>
        <v-flex xs12>
          <try-trick-button :trickId="trick.id" :type="trick.type" :withEdit="false" class='mx-2'></try-trick-button>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>
<script>
  import trickHelper from '../../tricks';

  export default { // TODO vue perfect scrollbar
    data : function(){
      return {
        trick : {user_races: []},
        authorName: '',
        trickHelper : {},
        starCount: 0,
        timeModifier: 0,
        formatedFormula : {},
        examples : '',
        imgsrc: '',
        displayDetails: 0,
        details: {tracks: [], stars: 0, tries: 0},
        archiveDialog: false,
        focusTrickDialog: false,
        raceColor: 'green',
      }
    },
    computed:{
      racesInfo(){
        if(this.trick.user_races.length == 0) return '';
        let infoString = this.trick.user_races.length > 1 ? 'Active races :' : 'Active race :';
        let activeRacesCount = 0;
        this.trick.user_races.forEach(race => {
          let options = JSON.parse(race.options);
          if(this.timeModifier > options.timeModifier){
            infoString += `<br /><s><b>${race.title} :</b> ${options.trickValidation} <span class='subheading'>&#9733;</span> to validate</s> <b>(+${options.timeModifier}s max)</b>`;
          } else {
            infoString += `<br /><b>${race.title} :</b> ${options.trickValidation} <span class='subheading'>&#9733;</span> to validate (+${options.timeModifier}s max)`;
            activeRacesCount++;
          }
          if(activeRacesCount > 0){
            this.raceColor = 'green';
          } else {
            this.raceColor = 'grey';
          }
        });

        return infoString;
      }
    },
    mounted() {
      eventBus.$on('focusTrick', trick => {
        this.displayDetails = 0;
        eventBus.$emit('initTimeModifier', {});
        this.trick = trick;
        this.trick.options = typeof this.trick.options == 'string' ? JSON.parse(this.trick.options) : this.trick.options;
        this.trickHelper = new trickHelper(this.trick.title, this.trick.image_description, this.trick.text_description.split('\n').join('<br>'), this.trick.type, JSON.parse(this.trick.formula), this.trick.options);
        let displays = this.trickHelper.displayFormula();
        this.formatedFormula.formulas = displays.formulas
        this.formatedFormula.variables = displays.variables
        this.examples = this.trickHelper.displayExamples();
        this.authorName = trick.author.name;
        this.starCount = 0;
        trick.star_count_perfs.forEach(perf => {
          this.starCount += this.$root.scoreToRating(perf.score);
        });


        if(this.trick.image_description){
          this.description_tabs = 'image-tab';
          this.imgsrc = this.trick.image_description;
        } else {
          this.description_tabs = 'text-tab';
          this.imgsrc = '';
        }
        this.focusTrickDialog = true;
      });

      eventBus.$on('timeModifierUpdated', timeModifier => {
        this.timeModifier = timeModifier
      });
      eventBus.$on('trickFinished', data => {
        this.focusTrickDialog = false;
      });
      eventBus.$on('trickUpdated', data => {
        this.focusTrickDialog = false;
      });
    },
    methods : {
      editTrick(){
        eventBus.$emit('editTrick', this.trick);
      },
      toggleWindows(){
        this.displayDetails = (this.displayDetails + 1) % 2;
      }
    }
  }
</script>
