<template>
  <v-container class='pt-1'>
    <v-layout row wrap>
      <v-flex xs12 class='text-xs-left'>
        <v-btn flat small @click='goBack'>
          <v-icon small left style='display:inline-flex'>chevron_left</v-icon>
          Back to marathon page
        </v-btn>
      </v-flex>
      <v-flex xs12 class='mb-2' style='display:flex;'>
        <v-select hide-details :loading='loading' :disabled='loading || smallLoading' :items="focusItems" v-model='focus' label="Focus" solo @change='focusChange'/>
        <v-btn class='ml-2' large icon :loading='smallLoading' @click='focusChange' :style="showing == 'race' || loading ? 'visibility:hidden' : ''">
          <v-icon large>refresh</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs12>
        <v-tabs v-model='activeClass'>
          <v-tab v-for='cl in classes' :key='cl.id'>
            {{ cl.name }}
          </v-tab>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Find student"
            clearable
            style='position:absolute;top:-2px;right:5px;width:400px;'
          />
          <v-tabs-items v-model='activeClass'>
            <v-tab-item v-for='cl in classes' :key='cl.id'>
              <class-race-table :data='displayedData.find(c => c.id == cl.id)' :search='search' :show='showing == "race"'/>
              <class-phase-table :data='displayedData.find(c => c.id == cl.id)' :search='search' :show='showing == "phase"'/>
              <class-marathon-table :data='displayedData.find(c => c.id == cl.id)' :search='search' :show='showing == "marathon"'/>
            </v-tab-item>
            <div style='position:absolute;top:0;width:100%;height:100%;background:rgba(255,255,255,0.6)' v-if='showOverlay'/>
          </v-tabs-items>
        </v-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        loading: false,
        smallLoading: false,
        showOverlay: false,
        marathon: {phases: []},
        data: {marathon: {}, phases: [], races: []},
        displayedData: [],
        focusItems: [],
        focus: null,
        showing: 'none',
        search: '',
        activeClass: null,
        classes: []
      }
    },
    mounted() {
      eventBus.$emit('loading', {command: 'clear'});
      eventBus.$emit('loading', {command: 'start', name: 'marathonClasses'});
      eventBus.$on('racePerf', this.handleRacePerf);
      axios.get(`/api/marathons/${this.$route.params.id}`, {})
      .then(resp => {
        this.marathon = resp.data.marathon;
        let items = [{header: this.marathon.title}, {text: 'Overall leaders', value: 'global'}];
        this.marathon.phases.forEach(ph => {
          items.push({divider: true});
          items.push({header: `${ph.title} phase`});
          items.push({text: `${ph.title} phase total`, value: `phase-${ph.id}`});
          ph.races.forEach(race => {
            if(race.status != 'draft'){
              items.push({text: `race : ${race.title}`, value: `race-${ph.id}-${race.id}`});
            }
          });
        });
        this.focusItems = items;
        this.classes = resp.data.divisions.filter(div => resp.data.marathonClasses.includes(div.id));
        eventBus.$emit('loading', {command: 'finish', name: 'marathonClasses'});
      }).catch(err => console.log(err));
    },
    beforeDestroy() {
      eventBus.$off('racePerf', this.handleRacePerf);
    },
    methods: {
      goBack(){
        this.$router.push(`/marathons/${this.$route.params.id}`);
      },
      focusChange(){
        let input = this.focus.split('-');
        let path
        switch(input[0]){
          case 'global':
            this.showing = 'marathon';
            path = `/api/marathons/${this.marathon.id}/classes`;
            if(!Object.values(this.data.marathon).length){
              this.showing = 'none';
              this.loading = true;
            } else {
              this.smallLoading = true;
              this.showOverlay = true;
              setTimeout(() => this.showOverlay = false, 300);
            }
          break;
          case 'phase':
            this.showing = 'phase';
            path = `/api/marathons/${this.marathon.id}/phases/${input[1]}/classes`;
            if(!this.data.phases.find(r => r.id == input[1])){
              this.showing = 'none';
              this.loading = true;
              this.data.phases.push({id: input[1], divisions: []});
            } else {
              this.smallLoading = true;
              this.showOverlay = true;
              setTimeout(() => this.showOverlay = false, 300);
            }
          break;
          case 'race':
            this.showing = 'race';
            path = `/api/marathons/${this.marathon.id}/phases/${input[1]}/races/${input[2]}/classes`;
            if(!this.data.races.find(r => r.id == input[2])){
              this.showing = 'none';
              this.loading = true;
              this.data.races.push({id: input[2], divisions: []});
            } else {
              this.smallLoading = true;
              this.showOverlay = true;
              setTimeout(() => this.showOverlay = false, 300);
            }
            this.displayedData = this.data.races.find(r => r.id == input[2]).divisions;
          break;
        }
        axios.get(path)
        .then(resp => {
          switch(input[0]){
            case 'global':
              this.showing = 'marathon';
              this.displayedData = resp.data.divisions;
              this.data.marathon = resp.data;
            break;
            case 'phase':
            this.displayedData = resp.data.divisions;
            this.showing = 'phase';
            this.data.phases = this.data.phases.filter(r => r.id != resp.data.id);
            this.data.phases.push(resp.data);
            break;
            case 'race':
              this.displayedData = resp.data.divisions;
              this.showing = 'race';
              this.data.races = this.data.races.filter(r => r.id != resp.data.id);
              this.data.races.push(resp.data);
            break;
          }

          this.loading = false;
          this.smallLoading = false;
        }).catch(err => console.log(err));
      },
      handleRacePerf(e){
        let input = this.focus.split('-');
        if (this.showing == 'race' && e.id == input[2]){
          axios.get(`/api/marathons/${this.marathon.id}/phases/${input[1]}/races/${input[2]}/classes`)
          .then(resp => {
            // console.log(resp.data);
            this.displayedData = resp.data.divisions;
            this.showing = 'race';
            this.data.races = this.data.races.filter(r => r.id != resp.data.id);
            this.data.races.push(resp.data);
          }).catch(err => console.log(err));
        }
      }
    }
  }
</script>
