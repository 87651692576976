<template>
  <div>
    <div style='display:flex;align-items:flex-end;' class='headline grey--text text-xs-left'>
      <span>
        Fix Hikes Progress
      </span>
      <span style='display:flex;' class='mx-3'>
        <v-text-field hide-details :disabled='fixingHikesProgress' style='width:75px;' class='mx-1 my-0' label="First" type='number' v-model="firstHike"/>
        <v-text-field hide-details :disabled='fixingHikesProgress' style='width:75px' class='mx-1 my-0' label="Last" type='number' v-model="lastHike"/>
      </span>
      <v-dialog width='400px' v-model='confirmFixHikesProgressDialog'>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on">
            Fix Progress
          </v-btn>
        </template>
        <v-card>
          <v-card-title class='headline'>
            Fix progress for {{ lastHike - firstHike + 1 }} hikes ?
          </v-card-title>
          <v-card-text>
            Are you sure you want to do this ? It might take a while and cause major damage...
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color='primary' flat style='width:150px' @click='confirmFixHikesProgressDialog = false'>
              Cancel
            </v-btn>
            <v-btn color='error' style='width:150px' :loading='fixingHikesProgress' @click='fixHikesProgress'>
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn icon color='error' @click='fixingHikesProgress = false' v-if='fixingHikesProgress'>
        <v-icon dark>cancel</v-icon>
      </v-btn>
    </div>
    <div class='text-xs-left'>
      <v-chip
        :color='hikeStatusColor(hike.status)'
        dark
        class='font-weight-bold'
        v-for='hike in hikes' :key='hikes.id'
        >
        {{ hike.title }} - {{ hike.status }}
      </v-chip>
    </div>
  </div>
</template>
<script>
  export default {
    props : {},
    data : function(){
      return {
        firstHike: 1,
        lastHike: 10,
        fixingHikesProgress: false,
        confirmFixHikesProgressDialog: false,
        currentHike: null,
        hikes: []
      }
    },
    methods: {
      fixHikesProgress(){
        this.confirmFixHikesProgressDialog = false;
        this.fixingHikesProgress = true;
        if(this.currentHike === null){
          this.hikes = [];
          this.currentHike = this.firstHike;
        }
        axios.post(`/api/admin/fixHikeProgress/${this.currentHike}`, {})
        .then(resp => {
          console.log(resp.data);
          this.hikes.push(resp.data);
          if(this.currentHike < this.lastHike || !this.fixingHikesProgress){
            this.currentHike++;
            this.fixHikesProgress();
          } else {
            this.currentHike = null;
            this.fixingHikesProgress = false;
            this.$root.snackSuccess("All done :-)")
          }
        }).catch(err => console.log(err));
      },
      hikeStatusColor(status){
        if(status > 0) return "success";
        if(status == 0) return "primary"
        return "grey darken-3";
      }
    }
  }
</script>
