<template>
  <v-card class='py-3' style='margin:auto' max-width='1137px' :class='backgroundClass'>
    <div style='position:absolute;top:5px;width:100%;text-align:center' class='grey--text caption'>
      <i>{{ statusDisplay.hint }} {{ formatedTimespan }}</i>
    </div>
    <div style='position:absolute;right:0px;height:280px' v-if='$vuetify.breakpoint.name != "xs"'>
      <v-img :src='imgUrl' width='250px' />
      <!-- <div
        style='position:absolute;top:0px;left:0px;width:100%;height:100%;background:rgba(232,232,232,0.8);display:flex;justify-content:center;align-items:center'
        v-if='status == "draft"'
      >
        <span style='font-family:Piko;font-size:84px;color:grey;transform:rotate(-30deg);'>
          DRAFT
        </span>
      </div> -->
      <div v-if='race.marathon && (race.marathon.marathon.status != "active" || !race.marathon.phase.active)'
        style='position:absolute;top:0px;left:0px;width:100%;height:100%;display:flex;justify-content:center;align-items:center'
        :style='`background:${statusBackground}`' />

      <!-- </div> -->
    </div>
    <temp-ranking v-if='$root.status == "student"' :rule='options.rewardRule'
      :runningTime="race.studentProgress.runningTime" :finishPlace='race.studentProgress.finishPlace'
      :style="`position:absolute;top:${$vuetify.breakpoint.name == 'xs' ? '30px' : '290px'};right:${$vuetify.breakpoint.name == 'xs' ? '10px' : '80px'}`" />
    <v-card v-if="$vuetify.breakpoint.name == 'xs'" style="width:100%;position:absolute;top:0"
      :color="statusDisplay.labelColor"
      :class="`${statusDisplay.labelTextColor}--text font-weight-bold text-xs-center pa-1`">
      {{ statusDisplay.label }}
    </v-card>
    <v-card-text class="text-xs-left">
      <v-layout row wrap fill-height>
        <v-flex xs12 md4 lg6>
          <v-layout row wrap>
            <v-flex xs12 class='text-no-wrap text-truncate display-1'>
              {{ race.title }}
            </v-flex>
            <v-flex xs12 class="font-weight-bold display-1 text-xs-center mb-2"
              style="white-space:nowrap;display:flex;justify-content:center" v-if='$root.status == "teacher"'>
              <text-fly :text='allStudentsStars'></text-fly>
              <v-icon x-large color="orange">star</v-icon>
            </v-flex>
            <v-flex xs12 class="font-weight-bold display-1 text-xs-center mb-2"
              v-if="$root.status == 'student' && ['active', 'upcoming'].includes(race.status)" style="white-space:nowrap">
              {{ race.studentProgress.nbStars }} / {{ race.track.tricks.length*5 }}
              <v-icon x-large color="orange">star</v-icon>
            </v-flex>
            <v-flex xs12>
              <div class="grey--text subheading">Track</div>
              <div class="headline font-weight-medium">{{ race.track.title }}</div>
              <div class="body-2 mb-2">{{ race.track.text_description }}</div>
            </v-flex>
            <v-flex xs12 v-if='$root.status == "teacher" && race.phase_id == null'>
              <v-layout row wrap>
                <v-flex xs12 class="grey--text subheading">Classes (term {{ race.term }})</v-flex>
                <v-flex xs12 class="mb-3" v-if="race.divisions && race.divisions.length && !showOverlay">
                  <v-chip v-for="cls in race.divisions" :key="cls.id">{{ cls.name }}</v-chip>
                </v-flex>
                <v-flex class="grey--text subheading text--lighten-2 mb-3" v-else>
                  No class is currently taking this race
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 v-if='race.phase_id != null'>
              <v-layout row wrap>
                <v-flex xs12 class="grey--text subheading">Marathon</v-flex>
                <v-flex xs12 class="subheading">
                  <span class='font-weight-bold'>{{ race.marathon.marathon.title }}</span>
                  <span class='ml-1'>({{ race.marathon.phase.title }} <span class='error--text font-weight-bold'
                      v-if='race.marathon.marathon.status != "active" || !race.marathon.phase.active'>-
                      INACTIVE</span>)</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 md4>
          <div class="grey--text subheading">Rules</div>
          <race-rules :options="options" :race='race' />
        </v-flex>
        <v-flex md4 lg2 />
        <!-- <v-flex xs12 md10 v-if='$root.status == "teacher"'>
          <trick-card-expandable
            v-for="trick in race.track.tricks"
            :key="`${race.id}-${trick.id}`"
            :trick="trick"
            :showRating="false"
            >
          </trick-card-expandable>
        </v-flex> -->
        <v-flex xs12 md10>
          <v-layout row wrap>
            <trick-card-mini v-for="trick in race.studentProgress.tricks" :key="trick.id" :trick='trick'
              :options='options' :validation='options.trickValidation' :active='race.status == "active"' />
          </v-layout>
        </v-flex>
        <div style='position:absolute;bottom:5px;width:100%;text-align:center' class="text-xs-left text-sm-center"
          v-if="$vuetify.breakpoint.name != 'xs'">
          <span class="grey--text">From</span>
          <span>{{ race.start | formatDate }}</span>
          <span>&#10230;</span>
          <span class="grey--text">To</span>
          <span>{{ race.finish | formatDate }}</span>
        </div>
      </v-layout>
    </v-card-text>
    <v-speed-dial v-model="actionMenu" style='position:absolute;bottom:5px;right:5px'
      transition="slide-y-reverse-transition" v-if='$root.status == "teacher"'>
      <template v-slot:activator>
        <v-btn v-model="actionMenu" color="primary" dark fab>
          <v-icon style='display:flex'>directions_run</v-icon>
          <v-icon style='display:flex'>close</v-icon>
        </v-btn>
      </template>
      <v-btn v-for='(button, index) in actionButtons' :key='`action-${index}`' fab dark small :color='button.color'
        class='ml-0 mb-1' :ripple='false' @click="handle_function_call(button.method)">
        <v-icon style='display:inline-flex'>{{ button.icon }}</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-btn fab dark small color='success' style='position:absolute;bottom:5px;right:5px'
      v-if="$root.status == 'student' && race.status == 'active'" @click="seeRacePerfs">
      <v-icon style='display:inline-flex'>visibility</v-icon>
    </v-btn>
    <v-dialog v-model="lockDialog" width="320">
      <v-card>
        <v-card-title class="headline">Lock {{ race.title }}</v-card-title>
        <v-card-text>Are you sure you want to lock this race ?<br>You won't be able to edit some of the details once the
          race is locked.</v-card-text>
        <v-card-actions>
          <v-btn color="error" flat block @click="lockDialog=false">
            No
          </v-btn>
          <v-btn color="success" block @click="lockThisRace(true)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="unlockDialog" width="320">
      <v-card>
        <v-card-title class="headline">Unlock {{ race.title }}</v-card-title>
        <v-card-text>Are you sure you want to unlock this race ?<br>Students won't be able to participate in this race
          until it is locked.</v-card-text>
        <v-card-actions>
          <v-btn color="error" flat block @click="unlockDialog=false">
            No
          </v-btn>
          <v-btn color="success" block @click="lockThisRace(false)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="340">
      <v-card>
        <v-card-title class="headline">Delete {{ race.title }}</v-card-title>
        <v-card-text>Are you sure you want to delete this race ?<br>This will also delete all students
          performances.</v-card-text>
        <v-card-actions>
          <v-btn color="primary" flat block @click="deleteDialog=false">
            No
          </v-btn>
          <v-btn color="error" block @click="deleteThisRace">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-locked-race-dialog :raceId='race.id' :classes='classes' :availablePhases='availablePhases' />
    <v-btn small icon style='position:absolute;top:-5px;left:-5px' @click='showOverlay=true'
      v-if='!["xs","sm"].includes($vuetify.breakpoint.name)'>
      <v-icon color='primary'>help</v-icon>
    </v-btn>
    <div class="marathonTagWrapper" v-if='race.phase_id != null'>
      <div class='marathonTag'>
        Marathon race
      </div>
    </div>
    <transition name='expandRounded' v-if='!["xs","sm"].includes($vuetify.breakpoint.name)'>
      <div class='overlay' v-if='showOverlay' />
    </transition>
    <transition name='fade' v-if='!["xs","sm"].includes($vuetify.breakpoint.name)'>
      <div class='insideOverlay text-xs-left headline' v-if='showOverlay'>
        <div style='position:absolute;top:70px;left:95px;display:flex;align-items:baseline;'>
          <v-img width='65px'
            style='position:absolute;transform:rotate(140deg) scaleX(-1);margin-left:-50px;margin-top:-10px;'
            src="/images/overlays/arrow race card overlay.png" />
          <div style='font-family:Piko;' class='hintCard elevation-3'>
            <div style='font-size:48px;'>
              This is a race !
            </div>
            <div style='font-size:24px' class='hidden-md-and-down'>
              Races are competitive events where you have to<br />validate a set of tricks as fast as you can
            </div>
          </div>
        </div>
        <div style='position:absolute;top:10px;display:flex;'
          :style='`left:${$root.$vuetify.breakpoint.name=="md" ? "450px" : "720px"}`'>
          <v-img width='65px' style='position:absolute;top:15px;left:-50px;transform:rotate(30deg)'
            src="/images/overlays/arrow race card overlay.png" />
          <div style='font-family:Piko;font-size:48px' class='hintCard elevation-3'>
            These are the rules...
          </div>
        </div>
        <div style='position:absolute;top:220px;left:10px;'>
          <div style='font-family:Piko;font-size:48px;line-height:0.8' class='hintCard elevation-3'>
            These are the <br /> tricks to validate
          </div>
          <v-img width='65px' style='position:absolute;top:15px;right:-50px;transform:scaleX(-1) rotate(55deg)'
            src="/images/overlays/arrow race card overlay.png" />
        </div>
        <div style='position:absolute;top:80px;right:10px;font-family:Piko;font-size:24px;background:white'
          class='hidden-md-and-down hintCard elevation-3'>
          Get {{ options.trickValidation }} stars or more to validate a trick
          <br />
          <div class='text-xs-right' v-if='race.maxExtraTime == 0'>(no extra time)</div>
          <div class='text-xs-right' v-else>(+{{ race.maxExtraTime }}s max extra time)</div>
          <div class='mb-3'>
            Validate {{ options.trackValidation }} tricks to finish the race
          </div>
          <div v-if='options.rewardRule == "fastest"'>
            Medals go to the fastest runners
            <br />
            <span class='font-weight-bold'>Warning !</span> Abandoning a trick will result in a <span
              class='error--text font-weight-bold'>penalty</span>
          </div>
          <div v-else>
            Medals go to the first runners to finish the race
          </div>
        </div>
        <v-btn large :ripple='false' color='primary' style='width:250px;position:absolute;right:5px;bottom:5px'
          @click='showOverlay=false'>
          Got it !
        </v-btn>
      </div>
    </transition>
    <race-perfs-dialog :race='race' :options='options' :runningTime='race.studentProgress.runningTime'
      :thisStudentHistory='studentHistory' :racePerfsDialog='perfsDialog' />
  </v-card>
</template>
<script>
  import moment from 'moment-timezone';

  export default {
    props : { dbrace: Object, classes: Array, availablePhases: {type: Array, default: []} },
    data : function(){
      return {
        race: {},
        perfsDialog: false,
        statusDisplay: {},
        options: {},
        studentHistory: [],
        actionButtons: [],
        deleteDialog: false,
        lockDialog: false,
        unlockDialog: false,
        status: 'draft',
        actionMenu: false,
        backgroundClass: '',
        imgUrl: '',
        forceUpdateCounter: 0,
        secondsTillStart: 0,
        secondsSinceFinish: 0,
        timespanInterval: null,
        // runningTime: null,
        showOverlay: false
      }
    },
    computed: {
      statusBackground() {
        if(this.status == 'draft') return 'rgba(232,232,232,0.75)';
        if(this.status == 'upcoming') return 'rgba(245,245,245,0.75)';
        return 'rgba(255,255,255,0.75)';
      },
      allStudentsStars(){
        return this.race.formatedProgress.progress.tricks ?  _.sumBy(this.race.formatedProgress.progress.tricks, o => o.stars).toString() : '0';
      },
      formatedTimespan(){
        if(this.status == 'upcoming'){
          return moment.duration(this.secondsTillStart,'seconds').humanize();
        } else if(this.status == 'draft'){
          return '';
        } else {
          return moment.duration(this.secondsSinceFinish,'seconds').humanize();
        }
      },
      formatedStartTime(){
        if(this.secondsTillStart < 2){
          return 'a few seconds...'
        } else {
          return moment.duration(this.secondsTillStart,'seconds').humanize();
        }
      },
      formatedFinishTime(){
        if(this.secondsSinceFinish < 20){
          return 'a few seconds...'
        } else {
          return moment.duration(this.secondsSinceFinish,'seconds').humanize();
        }
      },
      // medalGap(){
      //   let nbMedals = parseInt(this.options.gold) + parseInt(this.options.silver) + parseInt(this.options.bronze);
      //   if(nbMedals > 30){
      //     return 8;
      //   } else if(nbMedals > 20){
      //     return 10;
      //   } else {
      //     return 20;
      //   }
      // }
    },
    filters:{
      formatDate(date){
        return moment.tz(date, 'UTC').clone().tz(moment.tz.guess()).format('Do MMMM YYYY HH:mm');
      },
      formatSeconds(time){
        return moment.duration(time,'seconds').humanize();
      }
    },
    watch: {
      status: {
        immediate: true,
        handler: function(){
          switch(this.status){
            case 'draft':
              this.backgroundClass = 'draft';
              this.imgUrl = '/images/race draft.png';
              this.statusDisplay = {label: 'Draft', labelColor: 'white', labelTextColor: 'grey',
                hint: 'Only you can see this draft. Lock the race to allow students to see it.'};
              this.actionButtons = [
                {icon: 'visibility', color: 'grey', method: 'cantSeeDraft'},
                {icon: 'edit', color: 'primary', method: 'editThisRace'},
                {icon: 'lock', color: 'warning', method: 'confirmLock'},
                {icon: 'delete_forever', color: 'error', method: 'confirmDelete'},
              ];
            break;
            case 'upcoming':
              this.backgroundClass = 'upcoming';
              this.imgUrl = this.$root.user.images.upcoming;
              if(this.$root.status == 'teacher'){
                this.statusDisplay = {
                  label: 'Upcoming',
                  labelColor: 'info',
                  labelTextColor: 'white',
                  hint: `Students can see this race is upcoming. It will start in `
                };
                this.actionButtons = [
                  {icon: 'visibility', color: 'grey', method: 'cantSeeUpcoming'},
                  {icon: 'edit', color: 'blue lighten-3', method: 'editLockedRace'},
                  {icon: 'lock_open', color: 'info', method: 'confirmUnlock'},
                  {icon: 'delete_forever', color: 'error', method: 'confirmDelete'},
                ];
              } else {
                this.statusDisplay = {
                  label: 'Upcoming',
                  labelColor: 'info',
                  labelTextColor: 'white',
                  hint: `Starts in `
                };
              }
            break;
            case 'active':
              this.backgroundClass = 'started';
              this.imgUrl = this.$root.user.images.active;
              if(this.$root.status == 'teacher'){
                this.statusDisplay = {
                  label: 'Started',
                  labelColor: 'success',
                  labelTextColor: 'white',
                  hint: `This race has started and can no longer be unlocked. It will end in `
                };
                this.actionButtons = [
                  {icon: 'visibility', color: 'success', method: 'seeRacePerfs'},
                  {icon: 'edit', color: 'blue lighten-3', method: 'editLockedRace'},
                  {icon: 'lock', color: 'grey', method: 'cantUnlockOpen'},
                  {icon: 'delete_forever', color: 'error', method: 'confirmDelete'},
                ];
              } else {
                if(this.race.studentProgress.reward){
                  this.backgroundClass = 'finished';
                  this.imgUrl = this.$root.user.images.finished;
                  this.statusDisplay = {
                    label: 'Finished',
                    labelColor: 'success',
                    labelTextColor: 'white',
                    hint: `Ends in `
                  };
                } else {
                  this.statusDisplay = {
                    label: 'Started',
                    labelColor: 'primary',
                    labelTextColor: 'white',
                    hint: `Ends in `
                  };
                }
              }
            break;
            case 'arrived' :
              this.backgroundClass = 'finished';
              this.imgUrl = this.$root.user.images.finished;
              this.statusDisplay = {
                label: 'Finished',
                labelColor: 'success',
                labelTextColor: 'white',
                hint: `Ends in `
              };
            break;
            case 'closed':
              this.statusDisplay = {label: 'Finished', labelColor: 'grey', labelTextColor: 'white',
                hint: `This race ended`};
                this.actionButtons = [
                  {icon: 'visibility', color: 'success', method: 'seeRacePerfs'},
                  {icon: 'edit', color: 'grey', method: 'cantEditLocked'},
                  {icon: 'lock', color: 'grey', method: 'cantUnlockFinished'},
                  {icon: 'delete_forever', color: 'error', method: 'confirmDelete'},
                ];
            break;
          }
        }
      }
    },
    created() {
      this.race = this.dbrace;
      this.options = JSON.parse(this.race.options);
      this.studentHistory = this.race.studentHistory;
      this.secondsTillStart = this.race.timespan.starts_in_seconds;
      this.secondsSinceFinish = this.race.timespan.ends_in_seconds;
    },
    mounted() {
      this.status = this.race.studentProgress.reward == 'finished' ? 'arrived' : this.race.status;
      // this.runningTime = this.dbrace.runningTime;
      let vm = this;
      eventBus.$on('raceUpdated', race => {
        if(race.id == vm.race.id){
          vm.race = race;
          vm.options = {};
          vm.options = JSON.parse(race.options);
          vm.secondsTillStart = race.timespan.starts_in_seconds;
          vm.secondsSinceFinish = race.timespan.ends_in_seconds;
        }
      });

      this.timespanInterval = setInterval(() => {
        this.secondsTillStart = this.secondsTillStart - 60;
        this.secondsSinceFinish = this.secondsSinceFinish - 60;
      }, 60000);

      eventBus.$on('trickFinished', (data) => {
        if(data.starCountUpdate > 0){
          this.race.studentProgress.tricks.forEach(trick => {
            if(data.id == trick.id){
              trick.star_count_perfs.push({score: data.score});
              if(trick.star_count_perfs.length > 3){
                trick.star_count_perfs.sort((a,b)=>(a.score > b.score) ? -1 : 1);
                trick.star_count_perfs.pop();
              }
            }
          });
        }
      });
      eventBus.$on('trickValidated', (data) => {
        if(data.raceId == this.race.id){
          let trick = _.find(this.race.studentProgress.tricks, {'id': data.id});
          if(trick){
            let newRating = this.$root.scoreToRating(data.score);
            if(newRating > trick.rating){
              this.race.studentProgress.nbStars += newRating - trick.rating;
              trick.rating = newRating;
            }
            // trick.rating = Math.max(trick.rating, this.$root.scoreToRating(data.score));
          }
        }
      });
      eventBus.$on('raceRunningTime', data => {
        if(data.id == this.race.id){
          this.race.studentProgress.runningTime = data.runningTime;
        }
      });
      eventBus.$on('raceHistory', data => {
        if(data.id == this.race.id){
          this.studentHistory = data.studentHistory;
        }
      });
      eventBus.$on('raceFinished', data => {
        if(data.id == this.race.id){
          this.race.studentProgress.reward = 'finished';
          this.race.studentProgress.finishPlace = data.finishPlace;
          this.status = 'arrived';
        }
      });
      eventBus.$on('activateRace', id => {
        if(this.race.id == id) this.status = 'active'
      });
      this.showOverlay = this.race.studentProgress.nbStars == 0 && this.$root.status == "student" && this.status == 'active';
      eventBus.$on('racePerf', this.checkRace);
      eventBus.$on('closePerfsDialog', this.closePerfsDialog);
    },
    beforeDestroy(){
      clearInterval(this.timespanInterval);
      this.timespanInterval = null;
      eventBus.$off('racePerf', this.checkRace);
      eventBus.$off('closePerfsDialog', this.closePerfsDialog);
    },
    methods : {
      // progressRecalc(){
      //   axios.get(`/api/races/${this.race.id}/recalcProgress`, {})
      //   .then(resp => console.log(resp.data))
      //   .catch(err => console.log(err));
      // },
      closePerfsDialog(){
        this.perfsDialog = false;
      },
      checkRace(e){
        if(e.id == this.race.id){
          this.race.formatedProgress = e.progress;
          // this.race.formatedProgress.tricks = JSON.parse(e.progress.tricks);
          // this.race.formatedProgress.user_data = JSON.parse(e.progress.user_data);
        }
      },
      handle_function_call(function_name) {
        this[function_name]()
      },
      cantSeeDraft(){
        this.$root.snackWarning('A draft has no performance');
      },
      cantSeeUpcoming(){
        this.$root.snackWarning('An upcoming race has no performance');
      },
      cantEditLocked(){
        this.$root.snackWarning('You cannot edit a locked race.');
      },
      cantUnlockOpen(){
        this.$root.snackWarning('You cannot unlock an ongoing race.');
      },
      cantUnlockFinished(){
        this.$root.snackWarning('You cannot unlock a finished race.');
      },
      confirmUnlock(){
        this.unlockDialog = true;
      },
      confirmLock(){
        this.lockDialog = true;
      },
      lockThisRace(val){
        let vm = this;
        axios.post(`/api/races/${this.race.id}/lock`, {value: val})
        .then(function(response){
          if(val){
            vm.$root.snackSuccess(`${vm.race.title} has been locked !`);
          } else {
            vm.$root.snackSuccess(`${vm.race.title} has been unlocked !`);
          }
          vm.lockDialog = false;
          vm.unlockDialog = false;
          vm.status = response.data;
          eventBus.$emit('raceStatus', vm.race.id);
        })
        .catch(function(error){
          if(error.response.data == 'Finish is past'){
            vm.$root.snackError('The finish date can\'t be in the past');
            vm.lockDialog = false;
          }
          console.log(error); // TODO fix this
        });
      },
      seeRacePerfs(){
        this.perfsDialog = true;
        // if(this.progress == null){
        //   this.$root.snackWarning('No performance yet.');
        // } else {
        //   eventBus.$emit('seeRacePerfs', this.race);
        // }
      },
      editThisRace(){
        eventBus.$emit('editRace', this.race);
      },
      editLockedRace(){
        eventBus.$emit('editLockedRace', this.race);

      },
      confirmDelete(){
        this.deleteDialog = true;
      },
      deleteThisRace(){
        let vm = this;
        axios.delete(`/api/races/${this.race.id}`, {})
        .then(function(response){
          vm.$root.snackSuccess(`${vm.race.title} has been deleted`);
          vm.deleteDialog = false;
          eventBus.$emit('raceDeleted', vm.race.id);
        })
        .catch(function(error){
          console.log(error); // TODO fix this
        });
      }
    }
  }
</script>

<style scoped>
  .marathonTagWrapper {
    position:absolute;
    top:0px;
    right:0px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 110px;
    height: 110px;
  }
  .marathonTag {
    background:black;
    color:white;
    position:absolute;
    top:-26px;
    right:-75px;
    padding: 5px 35px;
    transform: rotate(45deg);
    transform-origin: top left;
  }
  .draft{
    background-color: rgba(232,232,232,1);
  }
  .upcoming{
    background-color: rgba(245,245,245,1);
  }
  .overlay {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.65) 50%, rgba(255,255,255,1) 85%, rgba(255,255,255,1) 100%);
  }
  .insideOverlay {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
  }

  .expandRounded-enter-active {
    animation: expand-rounded .25s;
  }
  .expandRounded-leave-active {
    animation: expand-rounded .25s reverse;
  }
  @keyframes expand-rounded {
    0% {
      width: 0%;
      height: 0%;
      border-radius: 0% 25% 25% 10%;
    }
    25% {
      width: 10%;
      height: 10%;
      border-radius: 0% 25% 25% 10%;
    }
    95% {
      width: 100%;
      height: 100%;
      border-radius: 0% 10% 25% 0%;
    }
    100% {
      width: 100%;
      height: 100%;
      border-radius: 0%;
    }
  }

  .fade-enter-active {
    animation: fade .25s;
  }
  .fade-leave-active {
    animation: fade .25s reverse;
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .hintCard {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 16px 12px 16px;
  }
</style>
