<template>
  <v-container>
    <v-layout row wrap class='mb-4' style='max-width:1137px;margin:auto'>
      <v-flex xs12 v-if="races.length == 0" class="text-xs-center headline font-weight-thin grey--text text--lighten-2">
        No active race at the moment.
      </v-flex>
      <v-flex xs12 v-for="race in races" :key="`race-${race.id}`" class="mb-3">
        <race-card :dbrace="race" :classes='classes' :availablePhases='availablePhases'></race-card>
      </v-flex>
      <v-flex xs12 class='display-1 grey--text text-xs-left mt-3 mb-1' v-if="closedRaces.length > 0">
        Finished races
      </v-flex>
      <v-flex xs12 v-for="race in closedRaces" :key="`closedRace-${race.id}`" class="mb-3">
        <race-card-compact :race="race"></race-card-compact>
      </v-flex>
      <v-flex xs12 v-if='moreRaces' d-flex justify-center>
        <v-btn style='height:80px' :ripple='false' flat to='all-races'>
          See all races
          <v-icon right dark>chevron_right</v-icon>
        </v-btn>
      </v-flex>
      <edit-race-dialog :classes='classes' :availablePhases="availablePhases" />
      <add-race-fab :classes='classes' :availablePhases="availablePhases" v-if="$root.status == 'teacher'"/>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    props : [],
    data : function(){
      return {
        races: [],
        classes: [],
        closedRaces: [],
        availablePhases: [],
        moreRaces: false
      }
    },
    created() {
      eventBus.$emit('loading', {command: 'clear'});
      eventBus.$emit('loading', {command: 'start', name: 'races-page'});
      this.availablePhases = this.$root.classesData.availablePhases;
      this.classes = [];
      this.$root.classesData.divisions.forEach(cl => {
        this.classes.push({id: cl.id, name: cl.name, value: cl.id, text: cl.name});
      });
      eventBus.$on('updateClassesData', this.updateClassesData);
      let vm = this;
      axios.get('/api/races', {})
      .then(response => {
        vm.races = response.data.races;
        vm.closedRaces = response.data.closedRaces;
        if(vm.closedRaces.length > 5){
          vm.closedRaces.pop();
          vm.moreRaces = true;
        }

        eventBus.$emit('loading', {command: 'finish', name: 'races-page'});
      })
      .catch(function (error) {
        console.log(error) // TODO fix thiss
      });
      eventBus.$on('raceAdded', race => {
        this.races.unshift(race)
      });
      eventBus.$on('raceDeleted', (id) => {
        let tempArray = this.races;
        let vm = this;
        this.races = [];
        tempArray.forEach(function(race){
          if(race.id != id){
            vm.races.push(race);
          }
        });
        tempArray = this.closedRaces;
        this.closedRaces = [];
        tempArray.forEach(function(race){
          if(race.id != id){
            vm.closedRaces.push(race);
          }
        });
      });
      eventBus.$on('raceStatus', this.updateRacesStatus);
    },
    beforeDestroy(){
      eventBus.$off('raceStatus', this.updateRacesStatus);
    },
    methods : {
      updateClassesData(data){
        this.availablePhases = data.availablePhases;
        this.classes = [];
        data.divisions.forEach(cl => {
          this.classes.push({id: cl.id, name: cl.name, value: cl.id, text: cl.name});
        });
      },
      updateRacesStatus(id){
        axios.get(`/api/races/${id}`, {})
        .then(resp => {
          switch(resp.data.status){
            case 'draft':
              if(this.$root.status == 'student'){
                let tempArray = this.races;
                this.races = [];
                tempArray.forEach(race => {
                  if(race.id != resp.data.id){
                    this.races.push(race)
                  }
                });
              }
            break;
            case 'upcoming':
            if(this.$root.status == 'student'){
              if(!_.find(this.races, {'id': resp.data.id})){
                let race = resp.data;
                this.races.unshift(race);
              }
            }
            break;
            case 'active':
            let currentRace = _.find(this.races, {'id': resp.data.id})
            if(_.find(this.races, {'id': resp.data.id}) == undefined){
              let race = resp.data;
              this.races.unshift(race);
            } else {
              currentRace.status = 'active';
              eventBus.$emit('activateRace', resp.data.id);
            }
            break;
            case 'closed':
            let tempArray = this.races;
            this.races = [];
            tempArray.forEach(race => {
              if(race.id != id){
                this.races.push(race)
              }
            });
            this.closedRaces.unshift(resp.data);
            break;
          }
        }).catch(err => console.log(err));
      },
      newRace(){
        eventBus.$emit('newRace', {});
      }
    }
  }
</script>
