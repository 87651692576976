<template>
  <v-layout row wrap>
    <v-flex xs12 class='headline grey--text text--lighten-2 font-weight-thin text-xs-center' v-if='tracks.length == 0 && tricks.length == 0'>
      Nothing to see here <v-icon medium color='grey lighten-2'>face</v-icon>
    </v-flex>
    <v-flex xs12 class='title grey--text font-weight-thin pt-0' v-if='tracks.length > 0'>
      Tracks
    </v-flex>
    <v-flex xs12 style='display:flex;flex-wrap:wrap' v-if='tracks.length > 0'>
      <track-expandable-card v-for='track in tracks' :key='`track-${track.id}`' :track='track' :expandId='expandId' @click='toggleCard(track.id)'></track-expandable-card>
    </v-flex>
    <v-flex xs12 class='title grey--text font-weight-thin' v-if='tricks.length > 0'>
      Tricks
    </v-flex>
    <v-flex xs12 v-if='tricks.length > 0'>
      <v-layout row wrap>
        <v-chip v-for="(trick, index) in tricks" :key="`affinity-${trick.id}-${index}`" @click='focusThisTrick(trick);'>
          <v-avatar style='cursor:pointer'  class='font-weight-bold white--text' :color='$root.trickColors[trick.type]'> {{ trick.type | initial }}</v-avatar>
          <span style='cursor:pointer'  class='pr-4'>
            {{ trick.title }}
          </span>
          <v-btn icon style='position:absolute;right:-8px;top:-8px' @click.stop='addToSelected(trick)'>
            <v-icon color='primary'>add</v-icon>
          </v-btn>
        </v-chip>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    props : { tracks: Array, tricks: Array },
    data : function(){
      return {
        expandId: 0
      }
    },
    mounted() {
      eventBus.$on('toggleCard', this.toggleCard);
    },
    beforeDestroy(){
      eventBus.$off('toggleCard', this.toggleCard);
    },
    filters: {
      initial(val){
        return val.charAt(0).toUpperCase();
      }
    },
    methods: {
      focusThisTrick(trick){
        eventBus.$emit('focusTrick', trick);
      },
      addToSelected(trick){
        eventBus.$emit('addTrick', trick);
      },
      toggleCard(id){
        if(this.expandId == id){
          this.expandId = 0;
        } else {
          this.expandId = id;
        }
      }
    }
  }
</script>
