<template>
  <div style='position:fixed;top:75px;right:10px;width:400px;'>
    <transition name='fade-up'>
      <div style="display:flex;justify-content:flex-end" v-if='!hideHideAllAnnouncements'>
        <v-btn style='margin-left:75px' color='grey' outline round @click='hideAnnouncements'>
          hide all <v-icon right color='grey'>visibility_off</v-icon>
        </v-btn>
      </div>
    </transition>
    <transition-group name="slide-in" :style="{ '--total': announcements.length }">
      <v-card
        hover
        class='pa-2 my-1'
        width='400px'
        style='border-radius:20px;--i:0'
        v-if='showClassesEvents && $root.classesEvents.length > 0'
        key='classesEvents'
      >
        <v-btn icon @click='showClassesEvents = false' style='position:absolute;top:-5px;right:0px;'>
          <v-icon color='grey'>visibility_off</v-icon>
        </v-btn>
        <classes-events/>
      </v-card>
      <v-card
        hover
        class='pa-2 my-1'
        width='400px'
        style='border-radius:20px;'
        :style="{'--i': index+1}"
        v-for='(announcement, index) in announcements'
        :key='`${announcement.id}-${announcementForceRefresh}`'
        v-if='announcement.show'
      >
        <v-btn icon @click='announcementRead(announcement.id)' style='position:absolute;top:-5px;right:0px;'>
          <v-icon color='grey'>visibility_off</v-icon>
        </v-btn>
        <component :is='`announcement-${announcement.type}`' :announcement='announcement'/>
      </v-card>
    </transition-group>
  </div>
</template>
<script>
  export default {
    props : { },
    data : function(){
      return {
        announcements: [],
        showAnnouncements: true,
        showClassesEvents: true,
        announcementForceRefresh: 0,
      }
    },
    computed:{
      hideHideAllAnnouncements(){
        this.announcementForceRefresh;
        return (this.$root.classesEvents.length == 0 || !this.showClassesEvents) && this.announcements.every(a => !a.show);
      }
    },
    mounted() {
      eventBus.$on('updateClassesData', this.updateAnnouncements);
      eventBus.$on('showAnnouncements', this.showAllAnnouncements);
      eventBus.$on('deleteAnnouncement', this.deleteAnnouncement);
    },
    beforeDestroy(){
      eventBus.$off('updateClassesData', this.updateAnnouncements);
      eventBus.$off('showAnnouncements', this.showAllAnnouncements);
      eventBus.$off('deleteAnnouncement', this.deleteAnnouncement);
    },
    methods: {
      deleteAnnouncement(id) {
        this.announcements = this.announcements.filter(a => a.id != id);
      },
      updateAnnouncements(data){
        data.announcements.forEach(announcement => {
          if(!this.announcements.find(a => a.id == announcement.id)){
            announcement.show = true;
            this.announcements.push(announcement);
          }
        });
      },
      announcementRead(id){
        let temp = this.announcements;
        this.announcements = [];
        temp.forEach(a => {
          if(a.id == id){
            a.show = false;
          }
          this.announcements.push(a);
        })
        axios.patch(`/api/announcements/${id}/read`, {read: true})
        .then(resp => {
          // console.log(resp.data);
        }).catch(err => console.log(err));
      },
      showAllAnnouncements(){
        let temp = this.announcements;
        this.announcements = [];
        temp.forEach(a => {
          a.show = true;
          this.announcements.push(a);
        });
        this.showClassesEvents = true;
      },
      hideAnnouncements(){
        let ids = [];
        this.announcements.forEach(a => {
          a.show = false;
          ids.push(a.id);
        });
        this.showClassesEvents = false;
        this.announcementForceRefresh++;
        if(ids.length > 0){
          axios.post(`/api/announcements/readAll`, {ids})
          .then(resp => {
  
          }).catch(err => console.log(err));
        }
      }
    }
  }
</script>

<style scoped>
  .slide-in-leave-active {
    transition: transform .4s cubic-bezier(.5,0,.7,.4);
    transition-delay: calc( 0.2s * (var(--total) - 1 - var(--i)) );
  }

  .slide-in-enter-active {
    transition: transform .4s cubic-bezier(.2,.5,.1,1);
    transition-delay: calc( 0.2s * var(--i) );
  }

  .slide-in-enter { transform: translateX(400px); }
  .slide-in-leave-to { transform: translateX(400px); }


  .fade-up-enter-active {
    transition: all .4s ease;
  }
  .fade-up-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    transition-delay: .2s
  }
  .fade-up-enter, .fade-up-leave-to {
    transform: translateY(-50px);
    opacity: 0;
  }
</style>
