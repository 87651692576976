import { render, staticRenderFns } from "./medalists-recap.vue?vue&type=template&id=2c0fc844&scoped=true&"
import script from "./medalists-recap.vue?vue&type=script&lang=js&"
export * from "./medalists-recap.vue?vue&type=script&lang=js&"
import style0 from "./medalists-recap.vue?vue&type=style&index=0&id=2c0fc844&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c0fc844",
  null
  
)

export default component.exports