<template>
    <v-dialog v-model="dialog" width="850" persistent>
        <v-btn slot="activator" fab dark color="primary" fixed bottom right @click="initCanvas">
            <v-icon dark style='display:inline-flex'>
                add
            </v-icon>
        </v-btn>
        <v-card>
            <v-card-title class="headline">
                Challenge Canvas
            </v-card-title>
            <v-card-text>
                <v-layout row wrap>
                <div style="flex:1" class="pr-2">
                    <v-select outline label="Depth" :items="depths" v-model="canvas.depth"/>
                    <v-btn block color="primary" @click="pickFile" class="mb-2">
                        Choose a new image <v-icon right>image</v-icon>
                    </v-btn>
                    <input type="file" style="display:none" ref="image" accept="image/*" @change="onFilePicked">
                    <v-text-field v-model="canvas.options.title" label="Title"/>
                    <v-textarea v-model="canvas.options.description" label="Description"/>
                    <v-text-field v-model="canvas.date" label="Date"/>
                    <v-text-field v-model="canvas.options.url" label="Link URL"/>
                    <div style="display:flex;">
                        <v-text-field v-model="canvas.options.from" label="From" class="pr-1"/>
                        <v-text-field v-model="canvas.options.to" label="To" class="pl-1"/>
                    </div>
                </div>
                <div style="width:500px;max-width:500px;display:flex;justify-content:center;">
                    <challenge-canvas-display :imageBySection="canvas.image" :depth="canvas.depth" :revealed="revealed"/>
                </div>
            </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="error" :disabled='loading' style='width:200px' flat @click="dialog = false">Cancel</v-btn>
                <v-btn color="success" :loading='loading' style='width:200px' @click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {},
    data: function () {
        return {
            dialog: false,
            loading: false,
            canvas: { depth: 1, image: [], options: {} },
            depths: [{ text: 'One level (27x36)', value: 1 }, { text: 'Two levels (54x72)', value: 2 }, { text: 'Three levels (81x108)', value: 3 }],
            imageBySection: [],
            revealed: {sections: [], subsections: [], pixels: []},
            imageFile: null
        }
    },
    mounted() {
        for(let i = 0 ; i < 12*81 ; i++){
            this.revealed.sections.push(`${i}`);
        }
        eventBus.$on('editCanvasTemplate', this.showCanvasTemplate);
    },
    beforeDestroy() {
        eventBus.$off('editCanvasTemplate', this.showCanvasTemplate);
    },
    methods: {
        componentToHex(c) {
            let hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        },
        pickFile() {
            this.$refs.image.click();
        },
        onFilePicked(e) {
            this.canvas.image = [];
            const files = e.target.files;
            if (files[0] !== undefined) {
                const allowedExtension = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'];
                const type = files[0].type;
                if (allowedExtension.indexOf(type) > -1) {
                    const fr = new FileReader()
                    fr.onloadend = ev => {
                        let img = new Image();
                        img.src = fr.result;
                        this.imageFile = files[0];
                        img.onload = evt => {
                            const canvas = document.createElement('canvas');
                            const context = canvas.getContext('2d', { willReadFrequently: true });
                            context.drawImage(img, 0, 0);
                            let imageBySection = [];
                            for(let sectionNb = 0 ; sectionNb < 12*(this.canvas.depth**2) ; sectionNb++){
                                let section = [];
                                for(let subsectionNb = 0 ; subsectionNb < 9 ; subsectionNb++){
                                    let subsection = [];
                                    for (let pixelNb = 0; pixelNb < 9; pixelNb++) {
                                        let x = (sectionNb % (3*this.canvas.depth))*9 + (subsectionNb % 3)*3 + pixelNb % 3;
                                        let y = (Math.floor(sectionNb/(3*this.canvas.depth)))*9 + (Math.floor(subsectionNb/3))*3 + Math.floor(pixelNb/3);
                                        const data = context.getImageData(x, y, 1, 1).data;
                                        subsection.push(`${this.componentToHex(data[0])}${this.componentToHex(data[1])}${this.componentToHex(data[2])}`);
                                    }
                                    section.push(subsection);
                                }
                                imageBySection.push(section);
                            }
                            this.canvas.image = imageBySection;
                        }
                    }
                    fr.readAsDataURL(files[0]);
                }
            }
        },
        brCalculator(index, length) {
            if (index == 0) return 'border-radius: 5px 5px 0px 0px';
            if (index == length - 1) return 'border-radius: 0px 0px 5px 5px';

            return 'background: red';
        },
        initCanvas(){
            this.canvas = { depth: 1, image: [], options: {}, date: 0 };
        },
        showCanvasTemplate(canvas){
            this.canvas = canvas;
            this.dialog = true;
        },
        save(){
            this.loading = true;
            axios.post('/api/admin/canvasTemplate', this.canvas)
            .then(resp => {
                if(this.imageFile){
                    let image = new FormData();
                    image.append('image', this.imageFile);
                    axios.post(`/api/admin/canvasTemplate/${resp.data.id}/imageFile`, image)
                    .catch(err => console.log(err.response));
                }
                eventBus.$emit('canvasTemplateReturned', resp.data);
                this.loading = false;
                this.dialog = false;
                this.imageFile = '';
            }).catch(err => console.log(err.response));
        }
    }
}
</script>
