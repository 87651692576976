<template>
  <v-expansion-panel :value='panel' class='my-1'>
    <v-expansion-panel-content>
      <template v-slot:header>
        <div style='position:absolute;top:0px;left:0px;width:100%'>
          <v-progress-linear :value='data.totals.percent' class='ma-0' height='8' color='success'></v-progress-linear>
        </div>
        <v-layout row wrap class='mt-2'>
          <v-flex xs6 sm4 class='subheading' v-if='hikeTitle == ""'>
            {{ data.student.properName ? data.student.properName : data.student.name}}
          </v-flex>
          <v-flex xs6 sm4 class='subheading' v-else>
            {{ hikeTitle }}
          </v-flex>
          <v-flex sm4 class='hidden-xs-only text-xs-center subheading'>
            {{ data.totals.totalHikeStars }}
            <v-icon small color='success'>stars</v-icon>
          </v-flex>
          <v-flex xs6 sm4 class='text-xs-right subheading'>
            {{ data.totals.percent }}%
          </v-flex>
        </v-layout>
      </template>
      <v-card>
        <v-card-text>
          <v-layout row wrap v-for='(section, index) in data.sections' :key='`section-${index}`'>
            <v-flex xs12 class='subheading grey--text'>
              {{  trackTitles[index]  }}
            </v-flex>
            <v-flex xs12 style='display:flex;justify-content:space-between;flex-wrap:wrap' class='mb-3'>
              <v-tooltip top v-for='trick in section.tricksProgress' :key='trick.id'>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-progress-circular
                    :size='30' :width='8' :rotate="-90"
                    :value="trick.percent"
                    :color="hikeDiagramColor(trick.percent)"
                    />
                  </span>
                </template>
                <span>
                  <b>{{ trick.title }}</b> - {{ trick.stars }}/15 <v-icon small color='success'>stars</v-icon>
                </span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
  export default {
    props : { data: Object, trackTitles: Array, hikeTitle: {type: String, default: ''} },
    data : function(){
      return {
        panel: 1,
      }
    },
    mounted() {
      eventBus.$on('openHikePanels', val => {
        if(val){
          this.panel = 0;
        } else {
          this.panel = 1;
        }
      });
    },
    methods: {
      hikeDiagramColor(val){
        if(val <= 25) return 'error';
        if(val <= 50) return 'warning';
        if(val <= 75) return 'primary';
        return 'success';
      },
    }
  }
</script>
