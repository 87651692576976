<template>
    <div style='min-width:80px;height:80px;overflow-x:visible;position:relative;'>
      <v-hover>
        <v-card class='pa-1' style='display:flex;position:absolute;top:0px;height:80px' color='#FAFAFA' slot-scope="{ hover }" :style='hover ? "z-index:10;" : ""' flat hover>
          <v-img :style='collectible.type=="avatar" ? "border-radius:50%" : ""' max-width='70px' min-width='70px' max-height='70px' min-height='70px' contain :src='collectible.url'/>
          <div style='overflow-x:hidden;transition:all .2s' :style='hover ? "width:120px;opacity:1" : "width:0px;opacity:0;"'>
            <div style='display:flex;justify-content:flex-end'>
              <v-chip dark :color='statusFeedback.chipColor'>{{ statusFeedback.text }}</v-chip>
            </div>
            <div style='display:flex;justify-content:flex-end'>
              <v-btn class='ma-0' small flat :disabled='pinned' @click='pinMe'>
                Pin <v-icon right>arrow_right_alt</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-hover>
      <div style='position:absolute;top:5px;right:5px;width:8px;height:8px;border-radius:50%;' :style='`background:${statusFeedback.dotColor}`'/>
      <div style='position:absolute;top:0px;left:0px;width:100%;height:100%;background:rgba(255,255,255,0.8);' v-if='pinned'/>
    </div>
</template>
<script>
  export default {
    props : { collectible: Object },
    data : function(){
      return {
        pinned: false
      }
    },
    computed:{
      statusFeedback(){
        if(this.collectible.owned) return {text: 'Owned', dotColor: this.$vuetify.theme.success, chipColor: this.$vuetify.theme.success};
        if(Object.values(this.collectible.affordable).every(b => b)) return {text: 'Can afford', dotColor: this.$vuetify.theme.primary, chipColor: this.$vuetify.theme.primary};
        return {text: "Can't afford", dotColor: '#FAFAFA', chipColor: '#888888'};
      }
    },
    mounted(){
      eventBus.$on('unpinCollectibles', this.unpin);
    },
    beforeDestroy(){
      eventBus.$off('unpinCollectibles', this.unpin);
    },
    methods: {
      pinMe(){
        this.pinned = true;
        eventBus.$emit('pinCollectible', this.collectible);
      },
      unpin(){
        this.pinned = false;
      }
    }
  }
</script>
