<template>
  <v-layout row wrap>
    <v-flex xs12 class='text-xs-center' v-if='loading'>
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </v-flex>
    <v-flex xs12>
      <v-layout row wrap>
        <v-flex xs12 sm6 md4 lg3 v-for='(track, index) in tracks' class='text-xs-center' :key="`trackPolar-${index}-${track.id}`">
          {{ track.title }}
          <canvas :id='`graphTrack-${index}`' class='ma-2'></canvas>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
  export default {
    data : function(){
      return {
        loading: false,
        tracks: [],
        ctxs: [],
        charts: []
      }
    },
    mounted() {
      eventBus.$on('tracksPolars', this.updateGraph);
    },
    beforeDestroy(){
      eventBus.$off('tracksPolars', this.updateGraph);
    },
    methods: {
      updateGraph(userId){
        this.tracks = [];
        if(userId > 0){
          this.loading = true;
          let vm = this
          axios.get(`/api/stats/${userId}/track/starCount`, {})
          .then(response => {
            vm.tracks = response.data.tracks;
            vm.$nextTick(() => {
              vm.tracks.forEach((track, index) => {
                let ctx = document.getElementById(`graphTrack-${index}`);
                let trickStarCounts = [];
                let trickTitles = [];
                let sectorColors = [];

                track.starCount.tricks.forEach(trick => {
                  trickTitles.push(trick.title);
                  trickStarCounts.push(trick.starCount);
                  if(trick.starCount == 0){
                    sectorColors.push('black');
                  } else if(trick.starCount < 5){
                    sectorColors.push('rgb(255, 50, 50)');
                  } else if(trick.starCount < 10){
                    sectorColors.push('rgb(255, 205, 86)');
                  } else if(trick.starCount < 15){
                    sectorColors.push('rgb(54, 162, 235)');
                  } else {
                    sectorColors.push('rgb(99, 255, 99)');
                  }
                  new Chart(ctx, {
                    type: 'polarArea',
                    data: {
                      datasets: [{
                        data: trickStarCounts,
                        backgroundColor: sectorColors
                      }],
                      labels: trickTitles
                    },
                    options: {
                      legend: {display: false},
                      scale: {ticks: {min: -3, stepSize: 3, max: 15, display: false}},
                      events: []
                    }
                  });
                });
              });
            });
            vm.loading = false;
          }).catch(err => console.log(err));
        }
      }
    }
  }
</script>
