<template>
    <v-container>
        <v-layout row wrap>
            <v-data-table :rows-per-page-items="[25, 50, 100]" :headers="headers" :items="canvases" style="width:100%">
                <template v-slot:items="props">
                    <td>{{ props.item.options.title }}</td>
                    <td>{{ props.item.depth }}</td>
                    <td style="max-width:300px" class="text-truncate">{{ props.item.options.description }}</td>
                    <td style="max-width:150px" class="text-truncate">{{ props.item.options.url }}</td>
                    <td>{{ props.item.date }}</td>
                    <td>
                        <v-btn 
                            :disabled="deletingCanvasTemplate > 0 || gettingCanvasTemplate > 0"
                            :loading="gettingCanvasTemplate == props.item.id"
                            icon
                            @click="editCanvas(props.item.id)"
                        >
                            <v-icon color="success">edit</v-icon>
                        </v-btn>
                    </td>
                    <td>
                        <v-btn
                            :disabled="deletingCanvasTemplate > 0 || gettingCanvasTemplate > 0"
                            :loading="deletingCanvasTemplate == props.item.id"
                            icon
                            @click="prepareDeleteCanvas(props.item)"
                        >
                            <v-icon color="error">delete_forever</v-icon>
                        </v-btn>
                    </td>
                </template>
            </v-data-table>
            <v-dialog width="350" v-model="deleteDialog">
                <v-card>
                    <v-card-title class="headline">
                        Delete {{ focusedCanvas.options.title }} ?
                    </v-card-title>
                    <v-card-text>
                        Are you sure you want to delete this canvas template ?
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn color="primary" :disabled='deleteLoading' style='width:140px' flat @click="deleteDialog = false">Cancel</v-btn>
                        <v-btn color="error" :loading='deleteLoading' style='width:140px' @click="deleteCanvas">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <challengeCanvas-dialog/>
        </v-layout>
    </v-container>
</template>
<script>
    export default {
        props: {},
        data: function () {
            return {
                canvases: [],
                headers: [
                    { text: 'Title', value: 'options.title'},
                    { text: 'depth', value: 'depth' },
                    { text: 'description', value: 'options.description', width: '40%' },
                    { text: 'Link', value: 'options.url' },
                    { text: 'Date', value: 'date' },
                    { text: 'Edit', value: null, sortable: false, align: 'center' },
                    { text: 'Delete', value: null, sortable: false, align: 'center' }
                ],
                gettingCanvasTemplate: 0,
                deletingCanvasTemplate: 0,
                deleteDialog: false,
                deleteLoading: false,
                focusedCanvas: {options: {}}
            }
        },
        created() {
            eventBus.$emit('loading', { command: 'clear' });
        },
        mounted() {
            this.getChallengeCanvses();
            eventBus.$on('canvasTemplateReturned', this.processCanvasReturn);
        },
        beforeDestroy() {
            eventBus.$off('canvasTemplateReturned', this.processCanvasReturn);
        },
        methods: {
            getChallengeCanvses(){
                eventBus.$emit('loading', { command: 'start', name: 'admin' });
                axios.get('/api/admin/canvasTemplate', {})
                .then(resp => {
                    this.canvases = resp.data;
                    eventBus.$emit('loading', { command: 'finish', name: 'admin' });
                }).catch(err => console.log(err.response));
            },
            editCanvas(id) {
                this.gettingCanvasTemplate = id;
                axios.get(`/api/admin/canvasTemplate/${id}`, {})
                .then(resp => {
                    eventBus.$emit('editCanvasTemplate', resp.data);
                    this.gettingCanvasTemplate = 0;
                }).catch(err => console.log(err.response));
            },
            prepareDeleteCanvas(canvas){
                this.focusedCanvas = canvas;
                this.deleteDialog = true;
            },
            deleteCanvas(){
                this.deleteLoading = true;
                const id = this.focusedCanvas.id;
                axios.delete(`/api/admin/canvasTemplate/${id}`, {})
                .then(resp => {
                    this.canvases = this.canvases.filter(c => c.id != id);
                    this.deleteLoading = false;
                    this.deleteDialog = false;
                }).catch(err => console.log(err.response));
            },
            processCanvasReturn(canvasTemplate){
                // let matchFound = false;
                if(this.canvases.some(c => c.id == canvasTemplate.id)){
                    this.canvases = this.canvases.map(c => c.id == canvasTemplate.id ? canvasTemplate : c)
                } else {
                    this.canvases.unshift(canvasTemplate);
                }
            }
        }
    }
</script>