<template>
  <v-dialog v-model="newRaceDialog" @keydown.esc="cancelNewRace" @keydown.enter.prevent="submitNewRace" max-width="750px">
    <v-btn slot="activator" fab dark color="primary" fixed bottom right v-if="showButton"><v-icon dark style='display:inline-flex'>add</v-icon></v-btn>
    <v-card>
      <v-card-title class='px-3 pt-2 pb-0' style='display:flex;justify-content:space-between;align-items:center'>
        <span class="headline">Make a new race</span>
        <span style='display:flex;align-items:center'>
          <v-switch v-model='marathonRace' :readonly='Object.keys(lockedPhase).length != 0'/>
          <span class='subheading' :class='marathonRace ? "primary--text font-weight-bold" : "grey--text"'>Marathon race</span>
        </span>
      </v-card-title>
      <v-card-text class='pt-0'>
        <v-container class='pa-0' grid-list-md>
          <v-form ref="newRaceForm" v-model="valid" lazy-validation>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field label="Name" v-model="raceTitle" :rules="titleRules" autofocus clearable validate-on-blur></v-text-field>
              </v-flex>
              <v-flex xs12 style='max-height:120px;min-height:120px'>
                <v-window v-model='classMarathonWindow'>
                  <v-window-item :key='0'>
                    <v-layout row wrap>
                      <v-flex xs12 sm10>
                        <v-select v-model="raceClasses" :items="classes" chips deletable-chips label="Classes" multiple/>
                      </v-flex>
                      <v-flex xs12 sm2>
                        <v-select class='py-2' outline v-model="raceTerm" :items="[1,2,3,4,5,6,7,8,9,10]" label="Term"/>
                      </v-flex>
                    </v-layout>
                  </v-window-item>
                  <v-window-item :key='1'>
                    <v-select class='py-2' outline v-model="racePhaseId" :items="availablePhases" label="Phase"/>
                  </v-window-item>
                  <v-window-item :key='2'>
                    <v-select class='py-2' outline v-model="lockedPhase.id" :items="[{text: lockedPhase.title, value: lockedPhase.id}]" disabled label="Phase"/>
                  </v-window-item>
                </v-window>
              </v-flex>
              <v-flex xs12>
                <track-search></track-search>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" style="width:140px" flat @click="cancelNewRace">Cancel</v-btn>
        <v-btn color="primary" style="width:140px" @click="submitNewRace">Create Race</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment-timezone';

  export default {
    props: { classes: Array, showButton: {type: Boolean, default: true}, availablePhases: {type: Array, default: []} },
    data : function(){
      return {
        newRaceDialog: false,
        valid: false,
        raceTitle: '',
        raceClasses: [],
        marathonRace: false,
        racePhaseId: 0,
        lockedPhase: {},
        raceTerm: 1,
        seltectedTrackId: 0,
        titleRules: [
          v => !!v || 'You must choose a title',
          v => (v && v.length >= 5) || 'The race title must be at least 5 characters long'
        ]
      }
    },
    computed:{
      classMarathonWindow(){
        if(this.marathonRace && Object.keys(this.lockedPhase).length > 0) return 2;
        if(this.marathonRace) return 1;
        return 0;
      }
    },
    mounted() {
      eventBus.$on('newPhaseRace', this.phaseRace)
      eventBus.$on('trackSelected', (data) => {
        this.seltectedTrackId = data.id;
      });
      this.raceClasses = [];
    },
    beforeDestroy() {
      eventBus.$off('newPhaseRace', this.phaseRace)
    },
    methods : {
      phaseRace(phase){
        this.lockedPhase = phase;
        this.racePhaseId = phase.id;
        this.marathonRace = true;
        this.newRaceDialog = true;
      },
      cancelNewRace(){
        this.$refs.newRaceForm.reset()
        this.newRaceDialog = false;
      },
      submitNewRace(){
        if(this.marathonRace && this.racePhaseId == 0 && Object.keys(this.lockedPhase) == 0){
          this.$root.snackError("Select a phase");
          return false;
        }
        if(this.$refs.newRaceForm.validate() && this.seltectedTrackId > 0){ // TODO give feedback if trackId == 0
          let vm = this;
          axios.post('/api/races', {
            title: vm.raceTitle,
            track_id: vm.seltectedTrackId,
            classes: vm.raceClasses,
            marathonRace: vm.marathonRace,
            phaseId: Object.keys(vm.lockedPhase).length == 0 ? vm.racePhaseId : vm.lockedPhase.id,
            term: vm.raceTerm,
            author_tz: moment.tz.guess()
          })
          .then(function (response) {
            eventBus.$emit('raceAdded', response.data);
            vm.raceTitle = '';
            vm.raceClasses = [];
            vm.marathonRace = false;
            vm.phaseId = 0;
            vm.newRaceDialog = false;
            vm.$root.snackSuccess("Race created !");
            eventBus.$emit('editRace', response.data);
          })
          .catch(function (error) {
            console.log(error)
            vm.$root.snackError("There was an error while creating the race."); // TODO test that
          });
        } else {
          this.$root.snackError("Invalid data provided");
        }
      }
    }
  }
</script>
